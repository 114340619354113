<template>
  <div>
    <el-dialog width="322px" class="m-serviceProvider" :visible.sync="flag" :before-close="handleClose">
      <div class="serviceProvider">
        <div>{{ phone||'暂无服务商号码' }}</div>
        <el-button type="primary" @click="getDetermine()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    contactFlag: {
      type: Boolean,
      default: false
    },
    bussinessMobile: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      flag: false,
      phone: ''

    }
  },
  watch: {
    contactFlag: {
      handler(val) {
        this.flag = val
      },
      immediate: true
    },
    bussinessMobile: {
      handler(val) {
        if (val) {
          this.phone = this.bussinessMobile.substring(0, 3) + '-' + this.bussinessMobile.substring(3, 7) + '-' + this.bussinessMobile.substring(7, 11)
        }
      }
    }

  },
  methods: {
    handleClose(done) {
      this.$emit('changeContactFlag', 'false')
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done()
      //     this.$emit('changeContactFlag', 'false')
      //   })
      //   .catch(_ => {})
    },
    getDetermine() {
      this.$emit('changeContactFlag', 'false')
    }
  }
}
</script>

