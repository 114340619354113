
<template>
  <el-row class="info" :gutter="20">
    <el-col v-for="(item,index) in formJson" :key="index" :span="span" class="info_item">
      <div
        v-if="item.tag==='text'||item.tag==='number'||item.tag==='number'||item.tag==='datePicker'
          ||item.tag==='phone'||item.tag==='position'"
        class="flex mb24"
      >
        <div class="label">{{ item.label }}：</div>
        <div class="value">{{ item.value }}</div>
      </div>
      <div
        v-if="item.tag==='radio'"
        class="flex mb24"
      >
        <div class="label">{{ item.label }}：</div>
        <div class="value">{{ item | radioToLabel }}</div>
      </div>
      <div
        v-if="item.tag==='money'"
        class="flex mb24"
      >
        <div class="label">{{ item.label }}：</div>
        <div class="value">{{ moneyToDx }}</div>
      </div>
      <div v-if="item.tag==='uploadImg'" class="flex mb24">
        <div class="label">{{ item.label }}：</div>
        <div v-for="(it,ix) in item.value" :key="ix" class="value value_img">
          <!--          <img class="img" :src="it" alt="">-->
          <el-image
            class="img"
            :src="it"
            fit="cover"
            :preview-src-list="[it]"
            @click="handleClickItem"
          />
        </div>
      </div>
      <div v-if="item.tag==='checkbox'" class="flex mb24">
        <div class="label">{{ item.label }}：</div>
        <div v-for="(it,ix) in item.value" :key="ix" class="value value-checkbox">
          <div>{{ it }}</div>
        </div>
      </div>
      <div v-if="item.tag==='umBusUser'" class="flex mb24">
        <div class="label">{{ item.label }}：</div>
        <div class="value">
          <um-bus-user ref="userManager" v-model="item.value" :show-tab="false" :show-prefix-button="false" :type="2">
            <template #user="{data}">
              <span>{{ data.map(item=>item.name).join(' , ') }}</span>
            </template>
          </um-bus-user>
        </div>
      </div>
      <div v-if="item.tag==='umBusOrgan'" class="flex mb24">
        <div class="label">{{ item.label }}：</div>
        <div class="value">
          <um-bus-user ref="userManager" v-model="item.value" :show-tab="false" :show-prefix-button="false" :type="1">
            <template #user="{data}">
              <span>{{ data.map(item=>item.name).join(' , ') }}</span>
            </template>
          </um-bus-user>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { digitUppercase } from '@/utils'

export default {
  name: 'UmDetailForm',
  filters: {
    // 因为单选按钮有个其他选项，所以需要过滤器才能显示
    radioToLabel({ value, options }) {
      const item = options.find(i => i.value === value)
      if (item) {
        return item.label
      } else {
        return value
      }
    }
  },
  props: {
    span: {
      type: Number,
      default: 24
    },
    formJson: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      moneyToDx: ''
    }
  },

  watch: {
    formJson: {
      handler(val) {
        val.forEach(item => {
          if (item.tag === 'money') {
            this.moneyToDx = digitUppercase(Number(item.value))
          }
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleClickItem() {
      setTimeout(function() {
        const domImageMask = document.querySelector('.el-image-viewer__wrapper')
        if (!domImageMask) {
          return
        }
        domImageMask.addEventListener('click', (e) => {
          if (e.target.parentNode.className == 'el-image-viewer__actions__inner') {
            return // 如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector('.el-image-viewer__close').click()
        })
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
.value_img{
  display: flex;
  flex-wrap: wrap;
  .img{
    width: 40px;
    height: 40px;
    margin-right: 6px;
    margin-bottom: 6px;
  }
}
.value-checkbox{
  display: flex;
  flex-wrap: wrap;
  >div{
    margin-right: 6px;
    margin-bottom: 6px;
  }
}

.info{
  flex-wrap: wrap;
}
.info_item{
  // flex-wrap: wrap;
  // width:392px;
  // margin-right:20px;
  .label {
    color: #9FAABC;
    min-width: 70px;
    font-size: 14px;
  }
  .value {
    word-break: break-all;
    font-size: 14px;
    color: #6C7B96;
  }
}

</style>
