<template>
  <div>
    <el-dialog :visible.sync="flag" width="800px" :before-close="handleClose">
      <el-scrollbar style="height: 70vh">
        <um-url :url="argeeHtmlUrl" />
      </el-scrollbar>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      flag: false
    }
  },
  computed: {
    argeeHtmlUrl() {
      return location.origin + process.env.VUE_APP_BASE_URL + '/argee.html'
    }
  },
  watch: {
    dialogFlag: {
      handler(val) {
        this.flag = val
      },
      immediate: true
    }
  },
  methods: {
    handleClose(done) {
      this.$emit('agreementFlag', 'false')
    //   this.$confirm('确认关闭？')
    //     .then(_ => {
    //       done()
    //       this.$emit('agreementFlag', 'false')
    //     })
    //     .catch(_ => {})
    }
  }
}
</script>
