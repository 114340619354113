import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'

const maillistRouter = {
  path: '/maillist',
  component: Layout,
  meta: { level: 1 },
  redirect: to => {
    for (let i = 0; i < maillistRouter.children.length; i++) {
      const { path, meta } = maillistRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  children: [
    {
      path: '',
      name: 'Maillist',
      component: () => import('@/views/maillist'),
      meta: { title: '通讯录', cache: true, group: 'maillist', level: 2 }
    }

  ]
}
export default maillistRouter
