import request from '@/utils/request'
const baseURL = 'http://api.pm.youmatech.com/mock/797'

// 公告通知
export function getNotice(data) {
  return request({
    url: '/api/notice/query_notice_list',
    method: 'post',
    data
  })
}

// 审批
// 【列表】获取企业所有审批应用
export function enterpriseFlowGetAllFlowApp(params) {
  return request({
    url: '/api/v1/application/enterprise/flow/getAllFlowApp',
    method: 'get',
    params
  })
}
// 【列表】审批列表
export function enterpriseFlowRecordsGetApprovalRecords(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/records/getApprovalRecords',
    method: 'post',
    data
  })
}
// 【详情】获取审批记录信息
export function enterpriseFlowGetApprovalDetail(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/getApprovalDetail',
    method: 'post',
    data
  })
}
// 【详情】获取审批流程节点集合
export function enterpriseFlowGetApprovalNodes(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/getApprovalNodes',
    method: 'post',
    data
  })
}
// 【操作】撤回审批
export function enterpriseFlowWithdraw(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/withdraw',
    method: 'post',
    data
  })
}
// 【操作】驳回
export function enterpriseFlowOverrule(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/overrule',
    method: 'post',
    data
  })
}
// 【操作】同意
export function enterpriseFlowAgree(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/agree',
    method: 'post',
    data
  })
}
// 【操作】加签
export function enterpriseFlowBeforeAddSignTask(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/beforeAddSignTask',
    method: 'post',
    data
  })
}
// 【操作】获取可退回节点
export function enterpriseFlowGetReturnNodes(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/getReturnNodes',
    method: 'post',
    data
  })
}
// 【操作】退回
export function enterpriseFlowReturnNode(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/returnNode',
    method: 'post',
    data
  })
}
// 【操作】批量同意
export function batchConsent(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/batchAgree',
    method: 'post',
    data
  })
}

// 【应用/服务/智能硬件/解决方案/案例中心/资讯
export function isCollect(data) {
  return request({
    url: '/api/v1/application/collect/isCollect',
    method: 'post',
    data
  })
}
// 收藏 应用/服务/智能硬件/解决方案/案例中心
export function addCollect(data) {
  return request({
    url: '/api/v1/application/collect/add',
    method: 'post',
    data
  })
}
// 取消收藏 应用/服务/智能硬件/解决方案/案例中心
export function cancelCollect(data) {
  return request({
    url: '/api/v1/application/collect/cancel',
    method: 'post',
    data
  })
}
// 添加购物车
export function addShopping(data) {
  return request({
    url: '/api/v1/business/shopping/cart/add',
    method: 'post',
    data
  })
}
// 获取收藏/点赞数
export function like_num(data) {
  return request({
    url: '/api/v1/application/collect/like_num',
    method: 'post',
    data
  })
}
// 获取收藏/点赞数
export function isLike(data) {
  return request({
    url: '/api/v1/application/collect/isLike',
    method: 'post',
    data
  })
}
// 点赞
export function collectLike(data) {
  return request({
    url: '/api/v1/application/collect/like',
    method: 'post',
    data
  })
}

// app应用二维码
export function get_appLink_qrCode(params) {
  return request({
    url: '/api/v1/application/work_table/app_library/get_appLink_qrCode',
    method: 'get',
    params
  })
}
