<template>
  <div class="m-doorModel">
    <div class="top-back">
      <div class="title-back w60" @click="$router.replace('/administration')">返回</div>
    </div>

    <div class="doorModel-main">
      <el-form
        :model="searchParam"
        inline
      >
        <el-form-item label="项目名称">
          <um-bus-project
            ref="umbuspro"
            v-model="searchParam.busProjectUuid"
            style="width: 100%"
            placeholder="请选择项目"
          />
        </el-form-item>

        <el-form-item label="户型名称">
          <el-input
            v-model="searchParam.houseTypeName"
            placeholder="请输入户型"
            clearable
          />
        </el-form-item>

        <el-form-item class="fr">
          <!--          <div v-if="$refs.umbuspro&&!$refs.umbuspro.busProjectTreeData.length" class="f-room-button" @click="$router.push('/mainData/project')">-->
          <!--            <svg-icon icon-class="add_all" />-->
          <!--            <span>新增项目</span>-->
          <!--          </div>-->
          <div class="f-room-button" @click="getList"><svg-icon icon-class="search2" /><span>搜索</span></div>
          <div v-permission="['112040703']" class="f-room-button" @click="addVisible = true">
            <svg-icon icon-class="add_all" />
            <span>新增户型</span>
          </div>
        </el-form-item>
      </el-form>
      <NoProject v-if="$refs.umbuspro&&!$refs.umbuspro.busProjectTreeData.length" />
      <div v-else>
        <el-table
          v-loading="tableLoading"
          :data="searchData"
          row-key="xh"
          style="width: 100%"
        >
          <el-table-column
            prop="houseTypeName"
            label="户型名称"
            width="150"
          />
          <el-table-column
            prop="houseTypeStructure"
            label="户型结构"
            width="300"
          />
          <el-table-column
            prop="houseTypeBuildArea"
            label="建筑面积"
            width="150"
          />
          <el-table-column
            prop="houseTypeInnerArea"
            label="套内面积"
            width="150"
          />
          <el-table-column
            prop="houseTypeImageList"
            label="户型图"
            min-width="280"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.houseTypeImageList&&scope.row.houseTypeImageList.length&&scope.row.houseTypeImageList[0].imageHttpPath">
                <el-button
                  v-permission="['112040706']"
                  type="text"
                  @click="showImg(scope.row)"
                >
                  查看
                </el-button>
              </template>
              <template v-else>
                暂无数据
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="140"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                v-permission="['112040704']"
                type="text"
                @click="editHouse(scope.row.busHouseTypeUuid)"
              >
                编辑
              </el-button>
              <el-button
                v-permission="['112040705']"
                type="text"
                class="s-redColor"
                @click="del(scope.row.busHouseTypeUuid)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :total="searchTotal"
          :page.sync="searchParam.page.pageNum"
          :limit.sync="searchParam.page.pageSize"
          @pagination="getList('page')"
        />
      </div>
    </div>
    <addHouse
      :visible.sync="addVisible"
      :bus-project-id="searchParam.busProjectUuid"
      @getList="getList"
    />
    <editHouse
      :visible.sync="updateVisible"
      :bus-project-id="searchParam.busProjectUuid"
      :bus-house-type-uuid="busHouseTypeUuid"
      @getList="getList"
    />
    <el-dialog
      :visible.sync="showViewer"
      width="880px"
      title="查看户型图"
      append-to-body
      destroy-on-close
      @close="showIndex = 0"
    >
      <div class="text-center">
        <UmPreviewImg
          :url="imgs.houseTypeImageList[showIndex].imageHttpPath"
          :point="imgs.buildingPartVoList.filter(i=>imgs.houseTypeImageList[showIndex].imageFileUuid===i.imageFileUuid)"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer text-center"
      >
        <el-button v-show="imgs.houseTypeImageList.length>1" type="primary" @click="changeShow(1)">上一张</el-button>
        <el-button @click="showViewer = false">关闭</el-button>
        <el-button v-show="imgs.houseTypeImageList.length>1" type="primary" @click="changeShow(2)">下一张</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { house_type_del, house_type_list } from '@/api/mainData/doorModel'
import addHouse from './components/add'
import editHouse from './components/edit'
import UmPreviewImg from './components/umPreviewImg'
import NoProject from '@/views/mainData/components/noProject'
export default {
  name: 'MainDataDoorModel',
  components: { addHouse, editHouse, UmPreviewImg, NoProject },
  data() {
    return {
      appUuid: '',
      searchParam: {
        busProjectUuid: '',
        houseTypeName: '',
        page: {
          pageNum: 1,
          pageSize: 10
        }
      },
      showIndex: 0,
      busHouseTypeUuid: '',
      tableLoading: false,
      addVisible: false,
      updateVisible: false,
      showViewer: false,
      searchData: [],
      searchTotal: 0,
      imgs: {
        houseTypeImageList: [
          {
            imageHttpPath: ''
          }
        ],
        buildingPartVoList: []
      }
    }
  },
  watch: {
    'searchParam.busProjectUuid': {
      handler(val) {
        if (val) this.getList()
      }
    }
  },
  created() {
    // this.getList()
  },
  methods: {
    getList(state = '') {
      if (state !== 'page') {
        this.searchParam.page.pageNum = 1
      }
      this.tableLoading = true
      house_type_list({
        ...this.searchParam
      })
        .then(d => {
          this.tableLoading = false
          this.searchData = d.data.list
          this.searchTotal = d.data.page.total
        })
        .catch(e => {
          this.searchData = []
          this.searchTotal = 0
          this.tableLoading = false
        })
    },
    showImg(row) {
      this.showViewer = true
      this.imgs = row
    },
    // 编辑
    editHouse(val) {
      this.busHouseTypeUuid = val
      this.updateVisible = true
    },
    // 删除
    del(id) {
      const params = { busHouseTypeUuid: id }
      this.$confirm('是否确认删除?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          house_type_del(params).then(res => {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'success',
            message: '已取消删除'
          })
        })
    },
    changeShow(type) {
      const length = this.imgs.houseTypeImageList.length - 1
      if (type === 1) {
        if (this.showIndex === 0) {
          this.showIndex = length
        } else {
          this.showIndex -= 1
        }
      } else {
        if (this.showIndex === length) {
          this.showIndex = 0
        } else {
          this.showIndex += 1
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
