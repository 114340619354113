import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'
const administrationRouter = {
  path: '/administration',
  component: Layout,
  // redirect: '/mainData/home',
  meta: { level: 1, roles: ['11204'] },
  redirect: to => {
    for (let i = 0; i < administrationRouter.children.length; i++) {
      const { path, meta } = administrationRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  children: [
    {
      path: '',
      name: 'Administration',
      component: () => import('@/views/administration/index'),
      meta: { title: '管理', cache: true, group: 'administration', level: 2, roles: ['1120401'] }
    }

  ]
}
export default administrationRouter
