<template>
  <div>
    <div class="g-dashboardBox">
      <!-- 搜索框 -->
      <div class="flex mb20 dashboardBox_searchBox">
        <div class="searchBox_inp">
          <div ref="searchBox" class="searchBox_inp-unCheck" :class="flag === true ? 'searchBox_inp-check' : ''">
            <el-input
              v-model.trim="keyWord"
              placeholder="请输入关键词搜索"
              class="keyWord"
              maxlength="50"
              clearable
              @focus="getFocus(true)"
            />
            <div v-if="flag === true">
              <div class="searchBox-line" />
              <div class="searchBox-record">
                <div class="record-title">搜索记录</div>
                <div class="record-list wrap">
                  <div v-for="(item, index) in searchList" :key="index" class="record-item mb10" @click="getSearch(item)">
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="searchBox-record">
                <div class="record-title mt20">热门搜索</div>
                <div class="record-list">
                  <div v-for="(item, index) in hotList" :key="index" class="record-item" @click="getSearch(item)">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-button
          icon="el-icon-search"
          type="primary"
          class="ml20 searchBox_btn"
          size="small"
          @click="getSearch(keyWord)"
        >
          搜索
        </el-button>
      </div>
      <!--      頭部banner和公告通知-->
      <div class="dashboardBox_top">
        <el-row style="width: 100%">
          <el-col :span="12">
            <banner :banner-list="bannerList" style="margin-right: 10px" />
          </el-col>
          <el-col :span="12">
            <div class="top-r">
              <div class="flex-between top-r-t" @click="go_news">
                <div class="tz">公告通知</div>
                <div class="more">更多</div>
              </div>
              <ul v-if="noticeList && noticeList.length" class="top-r-ul">
                <li
                  v-for="(item, index) in noticeList"
                  :key="index"
                  class="top-r-li"
                  @click="go_newsInfo(item.noticeUuid)"
                >
                  <div class="about row_one">
                    <span v-if="item.readFlag != 1" class="read_icon" />
                    <span class="row_one">{{ item.noticeTitle }}</span>
                  </div>
                  <div class="date">{{ item.publishTime }}</div>
                </li>
              </ul>
              <div v-else class="m-noData m-noData_small">暂无数据</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div ref="dashboard_applicationbox" class="dashboard_applicationbox">
        <div>
          <!--      常见应用-->
          <div class="dashboardBox_content" @click="flag = false">
            <div class="flex-between">
              <div class="dashboardBox_content_title">常用应用</div>
            </div>
            <div v-permission="['1120204']" class="dashboardBox_content_edit-content" @click="editUse">
              <div class="mr-4 um-icon um-icon_work-edit" />
              编辑
            </div>
            <div class="dashboardBox_content_list">
              <div v-for="(item, index) in commonUseList" :key="index">
                <div class="content_list_item" @click="openUrl(item.pcJumpLink, item.appUuid, item.appScope)">
                  <div v-if="todoGroup[item.appUuid]" class="item-count">
                    {{ todoGroup[item.appUuid] }}
                  </div>
                  <img class="content_list_img f-bord-rad12" :src="item.appIcon">
                  <div class="content_list_title content_list_titleHover">
                    {{ item.appName }}
                  </div>
                </div>
              </div>
              <div v-permission="['1120203']" class="content_list_item" @click="editUse">
                <div class="um-icon um-icon_addUseImg iconImg" />
                <div class="content_list_title">添加应用</div>
              </div>
            </div>
          </div>
          <!--      全部應用-->
          <div class="dashboardBox_footer mt20" @click="flag = false">
            <div class="flex-between">
              <div class="title">全部应用</div>
              <div v-permission="['1120204']" class="app-manage all-manage" @click="manageAll">管理</div>
            </div>
            <div class="mb20">
              <el-scrollbar>
                <div v-if="allUseList && allUseList.length" ref="tabs" class="tabs">
                  <div
                    v-for="(item, index) in allUseList"
                    :key="index"
                    class="tab"
                    :class="{ active: tab === index }"
                    @click="chooseList(item, index)"
                  >
                    {{ item.appTypeName }}
                  </div>
                  <div
                    class="link-bar"
                    :style="`transform: translate3d(${offsetLeft + offsetWidth / 2
                    }px, 0px, 0px);`"
                  />
                </div>
              </el-scrollbar>
            </div>
            <div class="footer-content">
              <div v-for="(item2, index2) in appList" :key="index2">
                <div
                  class="item"
                  @click="
                    openUrl(item2.pcJumpLink, item2.appUuid, item2.appScope)
                  "
                >
                  <div v-if="todoGroup[item2.appUuid]" class="item-count">{{ todoGroup[item2.appUuid] }}</div>
                  <img class="img f-bord-rad12" :src="item2.appIcon">
                  <div class="title titleHover">{{ item2.appName }}</div>
                </div>
              </div>
              <div v-permission="['1120203']" class="content_list_item" @click="editCurrent(appList)">
                <div class="um-icon um-icon_addUseImg iconImg" />
                <div class="content_list_title">添加应用</div>
              </div>
            </div>
            <!--            <div v-else class="no-empty">-->
            <!--              <div class="um-icon um-icon_no-data" />-->
            <!--              <div class="mt20">暂无数据</div>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="m_informationbox">
          <div class="informationbox-title">
            <div class="title-name">我的待办</div>
            <div class="title-btn" @click="getAll()">
              查看全部
              <div class="btn-icon" />
            </div>
          </div>
          <el-tabs v-model="readState" style="width: 100%" @tab-click="gettodoPageList">
            <el-tab-pane label="待办" name="0" />
            <el-tab-pane label="已办" name="1" />
          </el-tabs>
          <div :key="readState" v-loading="todoloading" class="m_information_contenbox">
            <el-scrollbar style="height: 100%">
              <div v-if="todolist.length">
                <div
                  v-for="(item, index) in todolist"
                  :key="index"
                  class="m_information_item"
                >
                  <div
                    v-for="ite in item.todoList"
                    :key="ite.msgMessageUuid"
                    style="margin-bottom: 15px;"
                  >
                    <!--项目图标 和 时间 -->
                    <div class="m_information_item_time m-icon-time-box">
                      <div class="c-icon-name">
                        <img :src="ite.appIconUrl">
                        <span>{{ ite.appName }}</span>
                      </div>
                      <div class="c-icon-time">
                        {{ item.dailyDate || "-" }}
                      </div>
                    </div>
                    <!-- 待办内容 -->
                    <div
                      class="m_information_item_content m-content-box"
                      @click="gotoinfo(ite)"
                    >
                      <div class="m-title-name">
                        {{ !item.isOver? '待办' : '逾期待办' }}
                      </div>
                      <div class="m-item-title2">
                        {{ ite.title || "-" }}
                      </div>
                      <!-- 提示框 -->
                      <el-tooltip class="item" effect="dark" :content="ite.content" placement="top">
                        <div slot="content"> {{ ite.content }}</div>
                        <div class="row_1 m_information_item_content_shot m-item-content">
                          {{ ite.content || "-" }}
                        </div>
                      </el-tooltip>
                      <!-- 截止时间 -->
                      <div class="m_information_item_content_date">
                        <div class="m_date_time row_1" style="color:#ADB3BF">
                          截止时间:
                          <template v-if="ite.overTime">
                            {{ ite.overTime | parseTime("{y}-{m}-{d} {h}:{i}") }}
                          </template>
                          <template v-else>
                            --
                          </template>
                        </div>
                        <div v-if="ite.overdue" class="m_date_time_day">
                          逾期{{ ite.overdue || "0" }}天
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c_nodata">
                <noData />
              </div>
            </el-scrollbar>
          </div>
          <div v-if="todolist.length" class="m_pagination">
            <pagination
              :background="false"
              small
              :layout="'prev, pager,next'"
              :limit.sync="page.pageSize"
              :page.sync="page.pageNum"
              :total="tableTotal"
              @pagination="gettodoPageList('page')"
            />
          </div>
        </div>
      </div>
      <!--      添加常用应用右侧抽屉-->
      <el-drawer
        title="编辑常用应用"
        :visible.sync="editVisible"
        direction="rtl"
        :before-close="handleClose"
        append-to-body
        size="360px"
      >
        <div class="m-drawer">
          <div class="drawer-box">
            <el-scrollbar style="height: 100%">
              <p class="dr-title">常用应用</p>
              <div class="dr-cate">
                <div v-if="editCommonUseList && editCommonUseList.length" class="cate-box">
                  <div v-for="(item, index) in editCommonUseList" :key="index">
                    <div class="cate-item">
                      <div class="delete-icon" @click="deleteUse(item, index)" />
                      <img class="image f-bord-rad12" :src="item.appIcon">
                      <el-tooltip placement="top">
                        <p class="item-info row_1">{{ item.appName }}</p>
                        <div slot="content">{{ item.appName }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div v-else class="no-empty">
                  <div class="um-icon um-icon_no-smalldata" />
                  <div class="mt20 mb20">暂无数据</div>
                </div>
              </div>
              <div class="outside-box">
                <div class="dr-cate1">
                  <div v-for="(item, index) in allUseList" :key="index">
                    <div class="appTitleName">{{ item.appTypeName }}</div>
                    <div v-if="item.appList && item.appList.length" class="cate-box">
                      <div v-for="(item2, index2) in item.appList" :key="index2">
                        <div class="cate-item">
                          <img class="image f-bord-rad12" :src="item2.appIcon">
                          <el-tooltip placement="top">
                            <p class="item-info row_1 pointer">
                              {{ item2.appName }}
                            </p>
                            <div slot="content">{{ item2.appName }}</div>
                          </el-tooltip>
                          <div
                            class="dr-add"
                            :class="{
                              'gray-add': addFlag(item2.appUuid),
                            }"
                            @click="addCommonList(item2)"
                          >
                            {{ addFlag(item2.appUuid) ? "已添加" : "添加" }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="no-empty">
                      <div class="um-icon um-icon_no-smalldata" />
                      <div class="mt20 mb20">暂无数据</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="handle-box">
            <div class="left-sub" @click="editVisible = false">取消</div>
            <div class="right-sub" @click="saveCommonUse">确定</div>
          </div>
        </div>
      </el-drawer>

      <!--    全部分组管理-->
      <el-drawer
        title="分组管理"
        :visible.sync="manageVisible"
        direction="rtl"
        :before-close="handleCloseAll"
        append-to-body
        size="360px"
      >
        <div class="m-drawer">
          <div class="drawer-box">
            <el-scrollbar style="height: 100%">
              <div class="f-pd-lr20">
                <draggable :list="groupList">
                  <transition-group>
                    <div v-for="(item, index) in groupList" :key="index" class="group-name">
                      <div @click="queryApplication(item)">
                        {{ item.appTypeName }}
                        <span v-if="item.number" class="group-num">({{ item.number }})</span>
                      </div>
                      <div class="flex">
                        <div class="app-delete" @click="delGroup(item.appTypeCode)" />
                        <div class="app-query" @click="queryApplication(item)" />
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
            </el-scrollbar>
          </div>
          <div class="handle-box">
            <div class="left-sub add-box flex-center" @click="addGroupName">
              <div class="app-group" />
              新建分组
            </div>
            <div class="right-sub" @click="saveGroupSort">保存</div>
          </div>
        </div>
      </el-drawer>

      <!--    单应用管理-->
      <el-drawer
        :title="applicationTitle"
        :visible.sync="applicationVisible"
        direction="rtl"
        :before-close="handleCloseApp"
        append-to-body
        size="360px"
      >
        <div class="m-drawer">
          <div class="drawer-box">
            <el-scrollbar style="height: 100%">
              <div class="f-pd-lr20">
                <draggable :list="applicationList">
                  <transition-group>
                    <div v-for="(item, index) in applicationList" :key="index" class="group-name">
                      <div class="flex-column-center" @click="editApp(item)">
                        <img class="appli-icon" :src="item.appIcon">{{
                          item.appName
                        }}
                      </div>
                      <div class="flex">
                        <div class="app-delete" @click="delApp(item.appUuid)" />
                        <div class="app-set" @click="editApp(item)" />
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
            </el-scrollbar>
          </div>
          <div class="handle-box">
            <div class="right-sub" @click="saveAppSort">保存</div>
          </div>
        </div>
      </el-drawer>

      <!--     编辑当前分组下应用右侧抽屉-->
      <el-drawer
        title="添加应用"
        :visible.sync="editCurrentVisible"
        direction="rtl"
        :before-close="handleCloseEdit"
        append-to-body
        size="360px"
      >
        <div class="m-drawer">
          <div class="drawer-box">
            <el-scrollbar style="height: 100%">
              <p class="dr-title">已添加应用</p>
              <div class="dr-cate">
                <div v-if="currentUseList && currentUseList.length" class="cate-box">
                  <div v-for="(item, index) in currentUseList" :key="index">
                    <div class="cate-item">
                      <!--                      <div class="delete-icon" @click="deleteCurrent(item,index)" />-->
                      <img class="image f-bord-rad12" :src="item.appIcon">
                      <el-tooltip placement="top">
                        <p class="item-info row_1">{{ item.appName }}</p>
                        <div slot="content">{{ item.appName }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div v-else class="no-empty">
                  <div class="um-icon um-icon_no-smalldata" />
                  <div class="mt20 mb20">暂无数据</div>
                </div>
              </div>
              <div class="outside-box">
                <div class="dr-cate1">
                  <div v-for="(item, index) in otherAppList" :key="index">
                    <div class="appTitleName">{{ item.appTypeName }}</div>
                    <div v-if="item.appList && item.appList.length" class="cate-box">
                      <div v-for="(item2, index2) in item.appList" :key="index2">
                        <div class="cate-item">
                          <img class="image f-bord-rad12" :src="item2.appIcon">
                          <el-tooltip placement="top">
                            <p class="item-info row_1 pointer">
                              {{ item2.appName }}
                            </p>
                            <div slot="content">{{ item2.appName }}</div>
                          </el-tooltip>
                          <div
                            class="dr-add"
                            :class="{
                              'gray-add': addFlag(item2.appUuid),
                            }"
                            @click="addSelfApp(item2, index2)"
                          >
                            添加
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="no-empty">
                      <div class="um-icon um-icon_no-smalldata" />
                      <div class="mt20 mb20">暂无数据</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="handle-box" />
        </div>
      </el-drawer>
    </div>

    <!-- 新建分组 -->
    <el-dialog
      title="新建分组"
      :visible.sync="addVisible"
      width="566px"
      :body-style="{ padding: '0px' }"
      append-to-body
      destroy-on-close
      @close="$refs.addGroupForm.resetFields()"
    >
      <el-form ref="addGroupForm" :model="addGroupForm" label-width="70px" label-position="left" @submit.native.prevent>
        <el-form-item
          ref="appGroupName"
          prop="appGroupName"
          label="分组名称"
          :rules="[
            { required: true, message: '请输入分组名称', trigger: 'blur' },
          ]"
        >
          <el-input v-model="addGroupForm.appGroupName" maxlength="10" placeholder="请输入分组名称" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="addVisible = false">
          取 消
        </el-button>
        <el-button type="primary" style="width: 100px" @click="sureAdd">
          确定
        </el-button>
      </div>
    </el-dialog>

    <!-- 应用管理 -->
    <el-dialog
      title="应用管理"
      :visible.sync="appVisible"
      width="560px"
      :body-style="{ padding: '0px' }"
      append-to-body
      destroy-on-close
      @close="$refs.appForm.resetFields()"
    >
      <div class="flex-culumn-align text-center">
        <img :src="appIcon" class="app-manage-icon" alt="">
        <div class="app-manage-name">{{ appName }}</div>
      </div>
      <el-form ref="appForm" :model="appForm" label-width="70px" label-position="left" @submit.native.prevent>
        <el-form-item ref="usedRange" prop="usedRange" label="可见范围">
          <div class="flex">
            <um-bus-user
              ref="user"
              v-model="appForm.usedRange"
              :scope="1"
              :show-tab="false"
              :show-prefix-button="false"
              :type="3"
            >
              <template #user="{ data }">
                <div class="m_applicationconfig_box">
                  <div v-if="data.length" class="m_applicationconfig_useritem">
                    <span v-for="(item, index) in data" :key="index" class="m_applicationconfig_has">
                      {{ item.name }}
                      <span class="m_applicationconfig_del" @click="$refs.user.removeItem(index)" />
                    </span>
                    <span class="s-blue" @click="$refs.user.openModal()">
                      {{ data.length === 0 ? "设置" : "修改" }}
                    </span>
                  </div>
                  <div v-else class="pointer s-blue" @click="$refs.user.openModal()">
                    {{ data.length === 0 ? "设置" : "修改" }}
                  </div>
                </div>
              </template>
            </um-bus-user>
          </div>
        </el-form-item>
        <el-form-item ref="usedFlag" prop="usedFlag" label="应用状态">
          <el-switch v-model="appForm.usedFlag" :active-value="0" :inactive-value="1" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="appVisible = false">
          取 消
        </el-button>
        <el-button type="primary" style="width: 100px" @click="sureSaveApp">
          确定
        </el-button>
      </div>
    </el-dialog>

    <AppQrCode :isapp-uuid="isappUuid" :invitation.sync="invitation" @changevisible="changevisible" />
  </div>
</template>
<script>
import banner from '../cloudMarket/components/banner.vue'
import AppQrCode from '@/views/dashboard/components/appQrcode'
import { jumpUrl } from '@/utils/backlog'
import noData from '@/views/cloudMarket/components/noData.vue'
import draggable from 'vuedraggable'
import {
  getHotSearch,
  getNotice,
  getAllList,
  getUseList,
  saveFavoriteList,
  todoPageList,
  get_group_list,
  set_group_sort,
  create_group_app,
  delete_app_group,
  query_self_group_app,
  app_remove,
  set_app_sort,
  get_detail_app_group,
  save_detail_app_group,
  get_query_other_group_app,
  add_self_app
} from '@/api/workbench'
import { getBanner } from '@/api/cloudMarket'
import { deepClone } from '@/utils'
import { filter } from 'mathjs'
import { mapGetters } from 'vuex'
import { openUrl } from '@/utils/jump'
import microApp from '@micro-zoe/micro-app'
import { getGroupList } from '@/api/myToDo'
const searchData = null
export default {
  name: 'DashboardIndex',
  components: { banner, noData, AppQrCode, draggable },
  data() {
    return {
      jumpUrl,
      invitation: false,
      isappUuid: '',
      microApp,
      tableTotal: 0,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      todolist: [],
      todoloading: false,
      clientHeight: '100px',
      readState: '0',
      flag: false,
      keyWord: '',
      editVisible: false,
      manageVisible: false,
      editCurrentVisible: false,
      currentUseList: [],
      otherAppList: [],
      allType: 1,
      appTypeCode: '',
      groupList: [],
      addVisible: false,
      addGroupForm: {
        appGroupName: ''
      },
      applicationList: [],
      appGroupCode: '',
      applicationTitle: '',
      applicationVisible: false,
      appVisible: false,
      appUuid: '',
      appForm: {
        appCode: '',
        usedRange: [],
        usedFlag: ''
      },
      appName: '',
      appIcon: '',
      tab: 0,
      offsetWidth: 56,
      offsetLeft: 0,
      commonUseList: [],
      editCommonUseList: [],
      allUseList: [],
      appList: [],
      bannerList: [],
      noticeList: [],
      hotList: [],
      searchList: [],
      val: null,
      localSearchList: [],
      todoGroup: {}
    }
  },

  watch: {
    tab(val) {
      this.$nextTick((_) => {
        const $active = this.$refs.tabs.getElementsByClassName('active')
        if ($active && $active.length) {
          const offsetWidth = $active[0].offsetWidth
          const offsetLeft = $active[0].offsetLeft

          this.offsetWidth = offsetWidth
          this.offsetLeft = offsetLeft
        }
      })
    }
  },
  mounted() {
    try {
      const key = 'searchData_' + this.$store.getters.enterpriseUuid
      const redult = JSON.parse(window.localStorage.getItem(key))
      if (redult && Array.isArray(redult)) {
        this.searchList = redult
      } else {
        this.searchList = []
      }
    } catch {
      this.searchList = []
    }
  },
  updated() {
    // this.getelementheight()
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.getBanner()
    this.getNotice()
    this.getAlreadyList()
    this.getAllUseList()
    this.getHotSearchList()
    this.gettodoPageList()
    this.getGroup()
    document.body.addEventListener('click', this.bodyClickhandler)
  },
  activated() {
    this.getNotice()
    this.getGroup()
    this.gettodoPageList()
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.bodyClickhandler)
  },
  methods: {

    gotoinfo(val) {
      console.log(val, 'val+++++--------------')
      const url = val.pcRedirectUrl
      if (val.urlScope == 1 || (val.urlScope == 3 && !val.pcRedirectUrl)) {
        this.invitation = true
        this.isappUuid = val.appUuid
      } else {
        this.jumpUrl(val.pcRedirectUrl)
      }
    },

    // 查询应用角标
    getGroup() {
      const params = {
        appInfoFlag: false,
        sumFlag: false,
        sortFlag: false
      }
      const count = 0
      getGroupList(params).then((res) => {
        const map = {}
        res.data.forEach(item => {
          map[item.appUuid] = item.count > 99 ? '99+' : item.count
        })
        this.todoGroup = map
      })
    },
    // 管理全部应用
    manageAll() {
      console.log('11')
      this.manageVisible = true
      this.getGroupList()
    },
    queryApplication(item) {
      this.applicationVisible = true
      this.applicationTitle = item.appTypeName
      this.appGroupCode = item.appTypeCode
      this.getApplicationList(item.appTypeCode)
    },
    getApplicationList(appGroupCode) {
      const load = this.$load()
      query_self_group_app({ appGroupCode })
        .then((d) => {
          if (d.data[0].appList && d.data[0].appList.length) {
            const List = d.data[0].appList.filter((item) => {
              return !item.enabledFlag
            })
            this.applicationList = List || []
          } else {
            this.applicationList = []
          }
        })
        .finally((_) => {
          load.close()
        })
    },
    getGroupList() {
      const load = this.$load()
      get_group_list()
        .then((d) => {
          this.groupList = d.data || []
        })
        .finally((_) => {
          load.close()
        })
    },
    delGroup(appGroupCode) {
      console.log(appGroupCode + '111111111111')
      this.$confirm('移出分组后应用将自动归属到默认分组', '移出分组', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
        center: true
      })
        .then(() => {
          const load = this.$load()
          delete_app_group({
            appGroupCode
          })
            .then((d) => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getGroupList()
              this.getAlreadyList()
            })
            .finally((_) => {
              load.close()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          })
        })
    },
    // 保存分组
    saveGroupSort() {
      const load = this.$load()
      const codeList = this.groupList.map((item) => {
        return item.appTypeCode
      })
      set_group_sort({ codeList })
        .then((res) => {
          this.$message.success(res.msg)
          this.getAlreadyList()
          this.getAllUseList()
        })
        .finally((_) => {
          load.close()
        })
    },
    // 新建分组名称
    addGroupName() {
      this.addVisible = true
    },
    // 保存分组名称
    sureAdd() {
      this.$refs.addGroupForm.validate((valid) => {
        if (valid) {
          const load = this.$load()
          create_group_app({ ...this.addGroupForm })
            .then((res) => {
              this.$message.success(res.msg)
              this.addVisible = false
              this.getGroupList()
              load.close()
            })
            .catch((e) => {
              load.close()
              this.$errorHandle(e)
            })
        }
      })
    },
    // 保存应用
    saveAppSort() {
      const load = this.$load()
      const appList = this.applicationList.map((item) => {
        return item.appUuid
      })
      set_app_sort({ appList })
        .then((res) => {
          this.$message.success(res.msg)
          this.getApplicationList(this.appGroupCode)
        })
        .finally((_) => {
          load.close()
        })
    },

    // 应用编辑
    editApp(item) {
      this.appVisible = true
      this.appUuid = item.appUuid
      this.appName = item.appName
      this.appIcon = item.appIcon
      this.getAppInfo(item.appUuid)
    },
    // 获取应用详情
    getAppInfo(appCode) {
      const load = this.$load()
      get_detail_app_group({
        appCode
      })
        .then((res) => {
          this.appForm.usedRange = res.data.usedRange.values
          this.appForm.usedFlag = res.data.enabledFlag
        })
        .finally((_) => {
          load.close()
        })
    },
    sureSaveApp() {
      const load = this.$load()
      save_detail_app_group({
        ...this.appForm,
        appCode: this.appUuid
      })
        .then((res) => {
          this.$message.success(res.msg)
          this.getApplicationList(this.appGroupCode)
          this.getAllUseList()
          this.appVisible = false
          load.close()
        })
        .catch((e) => {
          load.close()
          this.$errorHandle(e)
        })
    },
    // 删除应用
    delApp(appUuid) {
      this.$confirm('删除应用后将自动归属到默认分组', '删除应用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      })
        .then(() => {
          const load = this.$load()
          app_remove({
            appUuid,
            appTypeCode: this.appGroupCode
          })
            .then((d) => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getApplicationList(this.appGroupCode)
              const appIndex = this.appList.findIndex((item) => {
                return item.appUuid == appUuid
              })
              this.appList.splice(appIndex, 1)
              this.getGroupList()
              this.getAlreadyList()
            })
            .finally((_) => {
              load.close()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          })
        })
    },

    editCurrent(appList) {
      this.editCurrentVisible = true
      this.currentUseList = appList
      this.getOtherAppList(this.appTypeCode)
    },
    getOtherAppList(appGroupCode) {
      const load = this.$load()
      get_query_other_group_app({
        appGroupCode: this.appTypeCode
      })
        .then((res) => {
          this.otherAppList = res.data // 获取非本组appList集合
          load.close()
        })
        .catch((e) => {
          load.close()
          this.$errorHandle(e)
        })
    },
    changevisible(val) {
      if (!val) {
        this.invitation = false
      } else {
        this.invitation = true
      }
    },
    gettodoPageList() {
      this.todoloading = true
      todoPageList({
        page: this.page,
        readState: this.readState,
        busUserUuid: this.userInfo.userUuid,
        appUuid: '',
        sortType: '',
        appInfoFlag: true
      })
        .then((d) => {
          console.log(d, '==========')
          this.todolist = d.data.list || []
          console.log(this.todolist, '==========')
          this.tableTotal = d.data.page.total
        })
        .finally((_) => {
          this.todoloading = false
        })
        .catch((e) => {
          this.todoloading = false
          this.$errorHandle(e)
        })
    },
    getelementheight() {
      const h = document.getElementsByClassName('dashboard_applicationbox')[0]
        .clientHeight
      this.clientHeight = h + 'px'
    },
    bodyClickhandler(event) {
      const _target = event.target
      if (this.$refs.searchBox) {
        if (this.$refs.searchBox.contains(_target)) {
          //
        } else {
          this.flag = false
        }
      }
    },
    handleClose() {
      this.editVisible = false
    },
    handleCloseAll() {
      this.manageVisible = false
    },
    handleCloseApp() {
      this.applicationVisible = false
    },
    handleCloseEdit() {
      this.editCurrentVisible = false
    },
    addFlag(val) {
      const includeUuidList = this.editCommonUseList.map((item) => {
        return item.appUuid
      })
      return includeUuidList.includes(val)
    },
    // 獲取热门搜索
    getHotSearchList() {
      getHotSearch().then((res) => {
        this.hotList = res.data
      })
    },
    // 獲取banner
    getBanner() {
      getBanner({ bannerNo: '200911001-P' }).then((res) => {
        this.bannerList = res.data
      })
    },

    // 獲取公告通知
    getNotice() {
      getNotice().then((res) => {
        this.noticeList = res.data
      })
    },
    // 切換全部應用
    chooseList(item, index) {
      console.log(item)
      console.log('当前item---' + item.appTypeCode)
      this.tab = index
      this.appList = item.appList
      this.appTypeCode = item.appTypeCode
    },
    deleteUse(item, index) {
      this.editCommonUseList.splice(index, 1)
      this.val = item.appUuid
    },
    // deleteCurrent(item, index) {
    //   this.currentUseList.splice(index, 1)
    //   this.delApp(item.appUuid)
    // },
    addCommonList(item) {
      if (this.addFlag(item.appUuid)) return false
      this.val = item.appUuid
      if (this.editCommonUseList.length >= 19) {
        this.$message.error('常见应用最多添加19个')
        console.log(this.editCommonUseList.length)
      } else {
        this.editCommonUseList.push(item)
      }
    },
    // 添加非本组应用至自身
    addSelfApp(item, index) {
      console.log(this.appTypeCode + '当前tab-----')
      if (this.addFlag(item.appUuid)) return false
      this.val = item.appUuid
      const load = this.$load()
      add_self_app({
        appTypeCodeTarget: this.appTypeCode,
        appTypeSourceCode: item.appTypeCode,
        appUuid: item.appUuid
      })
        .then((res) => {
          this.$message.success(res.msg)
          this.currentUseList.push(item)
          this.getOtherAppList(this.appTypeCode)
          load.close()
        })
        .catch((e) => {
          load.close()
          this.$errorHandle(e)
        })
    },
    // 获取常用应用列表
    getAlreadyList() {
      getUseList()
        .then((res) => {
          if (res.data.length > 0) {
            this.commonUseList = res.data[0].appList
            console.log(this.commonUseList)
          } else {
            this.commonUseList = []
          }
          console.log(this.commonUseList)
        })
        .catch((e) => { })
    },
    // 获取全部应用列表
    getAllUseList() {
      getAllList()
        .then((res) => {
          this.allUseList = res.data
          this.appList = res.data[0].appList
          this.appTypeCode = res.data[0].appTypeCode // 获取默认分组typeCode
        })
        .catch((e) => {
          this.$errorHandle(e)
        })
    },
    // 保存常用应用
    saveCommonUse() {
      const params = []
      this.editCommonUseList.forEach((item, index) => {
        params.push({
          appUuid: item.appUuid,
          sort: index
        })
      })
      saveFavoriteList(params)
        .then((res) => {
          this.$message.success(res.msg)
          this.getAlreadyList()
          this.editVisible = false
        })
        .catch((e) => { })
    },
    openUrl(url, appUuid, appScope) {
      if (appScope == 1) {
        this.isappUuid = appUuid
        this.invitation = true
      } else {
        openUrl(url, { appUuid })
      }
    },
    go_news() {
      this.$router.push({ path: '/notice', query: {}})
    },

    go_newsInfo(id) {
      this.getNotice()
      this.$router.push({
        path: '/notice/info',
        query: {
          noticeUuid: id
        }
      })
    },
    editUse() {
      this.editVisible = true
      this.editCommonUseList = deepClone(this.commonUseList)
    },
    getFocus(flag) {
      this.flag = flag
    },
    getSearch(item) {
      this.keyWord = item
      const key = 'searchData_' + this.$store.getters.enterpriseUuid
      // 存储
      if (this.keyWord || item) {
        this.searchList.unshift(item)
        this.searchList = [...new Set(this.searchList)].splice(0, 5)
        window.localStorage.setItem(key, JSON.stringify(this.searchList))
      }
      this.$router.push({
        path: '/dashboard/workSearch',
        query: { keyWord: item }
      })
      this.flag = false
      this.keyWord = ''
    },

    // 消息待办查看全部
    getAll() {
      this.$router.push({
        path: '/dashboard/myToDo',
        query: { busUserUuid: this.userInfo.userUuid }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.m_applicationconfig_box {
  display: flex;
  align-items: center;

  .m_applicationconfig_useritem {
    display: flex;
    max-width: 1200px;
    flex-wrap: wrap;
    align-items: baseline;

    .m_applicationconfig_has {
      display: inline-block;
      min-height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid #e4e8eb;
      padding: 0 12px;
      font-size: 14px;
      color: #31415f;
      margin-right: 16px;
      cursor: pointer;
      margin-bottom: 10px;
      vertical-align: middle;

      .m_applicationconfig_del {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: url("~@/assets/enterprise/icon-del-user.png") no-repeat;
        background-size: 100% 100%;
        margin-left: 10px;
      }
    }
  }

  .m_applicationconfig_config {
    display: inline-block;
    width: 80px;
    cursor: pointer;
    font-size: 14px;
    color: #3d80fc;
    line-height: 34px;
  }
}

.m-content-box {
  width: 256px;
  // height: 151px;
  // background: #F8FAFB;
  border-radius: 4px;
  // margin-bottom: 10px;
}

// 待办 已办
.m-icon-time-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  .c-icon-name {
    display: flex;
    align-items: center;

    img {
      width: 26px;
      height: 25px;
    }

    span {
      // width: 48px;
      height: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #31415F;
      line-height: 16px;
      margin-left: 7px;
    }
  }

  .c-icon-time {
    // width: 85px;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #31415F;
    line-height: 14px;
  }
}

.m-title-name {
  position: relative;
  // width: 56px;
  height: 14px;
  font-size: 14px;
  font-weight: 500;
  color: #31415F;
  line-height: 14px;
  padding-left: 10px;

  span {
    position: absolute;
    top: 1px;
    left: 0;
    width: 3px;
    height: 12px;
    background: #4C64FE;
    border-radius: 2px;
  }
}

.m-item-title2 {
  // height: 20px;
  max-height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #31415F;
  line-height: 20px;
  margin: 12px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2
}

.m-item-content {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #69748A;
  line-height: 12px;
}

// 提示框
.custom-tooltip-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tooltip-text {
  white-space: nowrap;
}
</style>
