import request from '@/utils/request'

// 获取分页列表
export function getList(data) {
  return request({
    url: '/api/v1/server-group/message/pc/todoPageList',
    method: 'post',
    data
  })
}

// 待办APP获取分组
export function getGroupList(data) {
  return request({
    url: '/api/v1/server-group/message/pc/todoAppGroup',
    method: 'post',
    data
  })
}
