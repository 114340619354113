<template>
  <div class="m_qr_code">
    <el-dialog
      :visible.sync="invitations"
      center
      :append-to-body="false"
    >
      <div style="position: relative;">
        <span class="m_qr_code_img">
          <span class="m_qr_code_img_top">
            <span class="margin_r_10" />
            {{ title }}
            <span class="margin_l_10" />
          </span>
          <span class="m_qr_code_img_box">
            <span class="m_qr_code_img_box_body">
              <el-image
                v-loading="qrloading"
                :src="qrCodeUrl"
                fit="fit"
                style="width: 100%; height: 100%"
              >
                <div slot="error" class="image-slot el-icon-refresh-right" @click="getcodeimg" />
              </el-image>
            </span>
          </span>

          <!--                <div v-clipboard:copy="InviteUrl" v-clipboard:success="successFn" class="copeurl">复制邀请链接</div>-->
          <a v-if="type==2" class="m_qr_code_url" :download="downLoad" :href="dwnherf" target="_blank">下载安装包到电脑</a>
        </span>
        <div class="m_qr_code_icon_close" @click="close" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get_Invite_users_qr_code, getDownloadQRCode } from '@/api/enterprise'
import { getLastVersion } from '@/api/common'
export default {
  name: 'Qrcode',
  components: {},
  props: {
    invitation: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '扫码快速加入企业'
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      qrloading: false,
      invitations: false,
      dwnherf: '',
      downLoad: '',
      qrCodeUrl: '' // 邀请二维码
    }
  },
  computed: {},
  watch: {
    invitation: {
      handler(val) {
        this.invitations = val
        if (this.type == 1) {
          this.qrCodeUrl = ''
          this.InviteUrl = ''
          this.invite()
        }
        if (this.type == 2) {
          this.qrCodeUrl = ''
          this.InviteUrl = ''
          this.getappcode()
          this.getLastVersions()
          // this.qrCodeUrl = require('@/assets/enterprise/download-app.png')
          // this.InviteUrl = 'https://www.pgyer.com/rnU7'
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    getcodeimg() {
      if (this.type == 1) {
        this.invite()
      }
      if (this.type == 2) {
        this.getappcode()
      }
    },
    getLastVersions() {
      getLastVersion().then(d => {
        console.log(d)
        this.dwnherf = d.data.android ? d.data.android.downloadUrl : ''
      })
    },
    // 邀请用户
    invite() {
      // this.invitation = true
      this.qrloading = true
      get_Invite_users_qr_code({
        organUuid: this.organUuid
      }).then(d => {
        this.qrCodeUrl = d.data.qrCodeUrl || ''
        this.InviteUrl = d.data.inviteUrl || ''
        //
      }).finally(_ => {
        this.qrloading = false
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    getappcode() {
      this.qrloading = true
      getDownloadQRCode().then(d => {
        console.log(d)
        this.qrCodeUrl = d.data || ''
        // this.InviteUrl = d.data.inviteUrl || ''
        //
      }).finally(_ => {
        this.qrloading = false
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    // 关闭邀请弹窗
    close() {
      this.invitations = false
      this.$emit('changevisible', false)
      this.qrCodeUrl = ''
      this.InviteUrl = ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
