<template>
  <div class="detailsBox m_maindata_projectadd">
    <div class="go_back_common">
      <div class="go_back_title" @click="goback">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="m_projectInfo_title">
      <div class="u_projectInfo_icon" />
      <div class="u_projectInfo_title">项目管理详情</div>
    </div>
    <el-form
      ref="addForm"
      :model="addForm"
      label-position="left"
      label-width="110px"
      :rules="addRules"
    >
      <div class="flex addForm">
        <div class="addForm_leftItem">
          <el-form-item
            ref="projectName"
            label="项目名称"
            prop="projectName"
          >
            <el-input
              v-model="addForm.projectName"
              placeholder="请输入项目名称"
              style="width: 100%"
              maxlength="50"
            />
          </el-form-item>
          <el-form-item
            ref="orgOrganId"
            label="所属组织"
            prop="orgOrganId"
          >
            <div class="flexss">
              <UmBusUser
                ref="choseorg"
                :key="1"
                v-model="addForm.orgOrganId"
                class="u_userItem"
                :type="1"
                :limit="1"
                :auth-type="1"
                title="选择所属组织"
                :organ-type-code-list="[1001502, 1001501]"
                :show-prefix-button="false"
                @change="orgChange"
              >
                <template #user="user">
                  <div
                    v-for="(item, index) in user.data"
                    :key="index"
                    style="display: inline-block; float: left"
                  >
                    <el-tag v-if="index==0" class="mr-10" @close="handleClose(item)">
                      {{ item.name }}
                    </el-tag>
                  </div>
                  <!--                  <el-tag v-if="user.data.length>1" class="mr-10">-->
                  <!--                    + {{ user.data.length-1 }}-->
                  <!--                  </el-tag>-->
                  <el-button type="text" @click="getOrg">
                    修改
                  </el-button>
                </template>
              </UmBusUser>
              <div />
            </div>
          </el-form-item>
          <el-form-item ref="projectCityCode" label="所在城市" prop="projectCityCode">
            <el-cascader
              v-model="addForm.projectCityCode"
              :options="cityOptions"
              style="width: 100%"
              :props="{
                value: 'areaCode',
                label: 'areaName',
              }"
            />
          </el-form-item>
          <el-form-item
            ref="address"
            label="项目地址"
            prop="address"
          >
            <el-row type="flex">
              <el-input
                v-model="addForm.address"
                class="m_projectadd_proinput"
                style="width: 100%"
                placeholder="请输入项目地址"
                maxlength="200"
                readonly
              />

              <div
                class="m_projectadd_probtn flex"
              >
                <i class="el-icon-circle-close u_probtn_delBtn" @click="delAddress()" />

                <div class="u_probtn_btn pointer" @click="openMap">
                  <svg-icon
                    icon-class="location"
                  />
                  从地图选
                </div>
              </div>
            </el-row>
          </el-form-item>
          <el-form-item
            ref="statusCode"
            label="项目施工状态"
            prop="statusCode"
          >
            <el-select v-model="addForm.statusCode" placeholder="请选择项目施工状态" style="width: 100%">
              <el-option
                v-for="item in statusCodeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            ref="areaCoverd"
            label="占地面积(㎡)"
            prop="areaCoverd"
          >
            <el-input
              v-model="addForm.areaCoverd"
              type="number"
              placeholder="请输入项目占地面积"
              style="width: 100%"
              maxlength="10"
              class="area_input"
            >
              <template slot="append">㎡</template>
            </el-input>
          </el-form-item>

          <el-form-item
            ref="plotRat"
            label="容积率(%)"
            prop="plotRat"
          >
            <el-input
              v-model="addForm.plotRat"
              placeholder="请输入项目容积率"
              style="width: 100%"
              maxlength="10"
            >
              <template slot="append">%</template>
            </el-input>
          </el-form-item>

          <el-form-item
            ref="saleCoverd"
            label="可售面积(㎡)"
            prop="saleCoverd"
          >
            <el-input
              v-model="addForm.saleCoverd"
              type="number"
              placeholder="请输入项目可售面积"
              style="width: 100%"
              maxlength="10"
              class="area_input"
            >
              <template slot="append">㎡</template>
            </el-input>
          </el-form-item>

          <el-form-item
            ref="parkingCount"
            label="车位总数"
            prop="parkingCount"
          >
            <el-input
              v-model.number="addForm.parkingCount"
              placeholder="请输入项目车位总数"
              style="width: 100%"
              maxlength="9"
            />
          </el-form-item>
        </div>
        <div class="addForm_rightItem">
          <el-form-item
            ref="projectShortName"
            label="项目简称"
            prop="projectShortName"
          >
            <el-input
              v-model="addForm.projectShortName"
              placeholder="请输入项目简称"
              style="width: 100%"
              maxlength="50"
            />
          </el-form-item>
          <el-form-item
            ref="leaderUserCode"
            label="项目负责人"
            prop="leaderUserCode"
          >
            <div class="flexss">
              <UmBusUser
                ref="chosepart"
                v-model="addForm.leaderUserCode"
                class="u_userItem"
                :type="2"
                :show-prefix-button="false"
                @change="userChange"
              >
                <template #user="user">
                  <div
                    v-for="(item, index) in user.data"
                    :key="index"
                    style="display: inline-block; float: left"
                  >
                    <el-tag v-if="index==0" class="mr-10" closable @close="handleClose(item)">
                      {{ item.name }}
                    </el-tag>
                  </div>
                  <el-tag v-if="user.data.length>1" class="mr-10">
                    + {{ user.data.length-1 }}
                  </el-tag>
                  <el-button type="text" @click="getRange">
                    修改
                  </el-button>
                </template>
              </UmBusUser>
              <div />
            </div>
          </el-form-item>
          <el-form-item
            ref="projectLatLngBaidu"
            label="地址经纬度"
            prop="projectLatLngBaidu"
          >
            <el-input
              v-model="addForm.projectLatLngBaidu"
              placeholder="请点击选择地图"
              style="width: 100%"
              readonly
            />
          </el-form-item>
          <el-form-item
            ref="saleStatus"
            label="项目销售状态"
            prop="saleStatus"
          >
            <el-select v-model="addForm.saleStatus" placeholder="请选择项目销售状态" style="width: 100%">
              <el-option
                v-for="item in saleStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            ref="buildCoverd"
            label="建筑面积(㎡)"
            prop="buildCoverd"
          >
            <el-input
              v-model="addForm.buildCoverd"
              type="number"
              placeholder="请输入项目建筑面积"
              style="width: 100%"
              maxlength="10"
              class="area_input"
            >
              <template slot="append">㎡</template>
            </el-input>
          </el-form-item>
          <el-form-item
            ref="greenRat"
            label="绿化覆盖率(%)"
            prop="greenRat"
          >
            <el-input
              v-model="addForm.greenRat"
              placeholder="请输入项目绿化覆盖率"
              style="width: 100%"
              maxlength="10"
            >
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item
            ref="saleRat"
            label="可售比(%)"
            prop="saleRat"
          >
            <el-input
              v-model="addForm.saleRat"
              placeholder="请输入项目可售比"
              style="width: 100%"
              maxlength="10"
            >
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item
            ref="houseCount"
            label="总户数"
            prop="houseCount"
          >
            <el-input
              v-model.number="addForm.houseCount"
              placeholder="请输入项目总户数"
              style="width: 100%"
              maxlength="9"
            />
          </el-form-item>
          <el-form-item
            ref="projectLabel"
            label="项目标签"
            prop="projectLabel"
          >
            <el-input
              v-model="addForm.projectLabel"
              placeholder="请输入项目标签"
              style="width: 100%"
              maxlength="50"
            />
          </el-form-item>
        </div>
      </div>
      <el-form-item
        ref="description"
        label="项目简介"
        prop="description"
      >
        <el-input
          v-model="addForm.description"
          type="textarea"
          placeholder="请输入项目简介"
          style="width: 100%"
          maxlength="200"
          :rows="4"
          show-word-limit
        />
      </el-form-item>
      <el-form-item ref="infoFileGroupUrl" label="项目图片" prop="infoFileGroupUrl">
        <um-upload-img v-model="addForm.infoFileGroupUrl" :size="5000" :limit="5" :simple="true" multiple type="img">
          <div class="m_projectadd_imgicon" />
        </um-upload-img>
      </el-form-item>
      <el-form-item v-if="$route.query.type!=1" ref="createUser" label="创建人/时间" prop="createUser">
        <div>
          {{ addForm.createUser }}
          <span v-if="addForm.createTime">{{ '/'+addForm.createTime }}</span>
        </div>
      </el-form-item>
      <el-form-item ref="projectPlanUrl" label="项目平面图" prop="projectPlanUrl">
        <um-upload-img v-model="addForm.projectPlanUrl" :size="5000" :limit="5" :simple="true" multiple type="img">
          <div class="imgicon" />
        </um-upload-img>
      </el-form-item>
      <el-form-item label="">
        <div class="m_umBtn  flex">
          <div class="u_umBtn_save pointer" @click="getSave()">保存</div>
          <div class="u_umBtn_cancel pointer" @click="goback()">取消</div>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      :visible.sync="mapVisible"
      width="840px"
      title="选择项目地址"
      append-to-body
    >
      <um-map v-model="activityLatLngBaidu" :readonly="false" />
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="mapSubmit">保 存</el-button>
        <el-button @click="mapVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addProject, editProject, getProjectDetail } from '@/api/mainData/project'
import { getAllArea, login } from '@/api/user'
import UmMap from '@/components/UmMap'
import { reverseGeocoding } from '@/utils'
import { isArray } from '@/utils/validate'
export default {
  name: 'MainDataProjectInfo',
  components: { UmMap },
  data() {
    var contChange = (rule, value, callback) => {
      const reg = /^100$|^(?:\d|[1-9]\d|1[0-4]\d)(?:\.5)?$/
      if (isNaN(Number(value)) && !reg.test(value)) {
        callback(new Error('请输入1~100之间的数字'))
      } else if (value && value < 1) {
        callback(new Error('请输入1~100之间的数字'))
      } else if (value && value > 100) {
        callback(new Error('请输入1~100之间的数字'))
      } else {
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      const reg = /^\d+$/
      if (isNaN(Number(value)) && !reg.test(value)) {
        callback(new Error('请输入数字'))
      } else {
        callback()
      }
    }
    var validate2Pass = (rule, value, callback) => {
      const reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
      if (isNaN(Number(value))) {
        callback(new Error('请输入数字'))
      } else if (value && !reg.test(value)) {
        callback(new Error('面积数值最多支持两位小数'))
      } else {
        callback()
      }
    }

    return {
      addForm: {
        orgOrganId: [],
        id: '',
        projectName: '',
        projectShortName: '',
        projectCityCode: '',
        address: '',
        projectLatLngBaidu: '',
        description: '',
        infoFileGroupUrl: [],
        leaderUserCode: [],
        // leaderOrgCode: [],
        leaderName: [],
        // leaderOrgName: [],
        lng: '',
        lat: '',
        statusCode: '',
        saleStatus: '',
        areaCoverd: null,
        buildCoverd: null,
        plotRat: null,
        greenRat: null,
        saleCoverd: null,
        saleRat: null,
        parkingCount: null,
        houseCount: null,
        projectLabel: '',
        projectPlanUrl: []
      },
      flag: true,
      addRules: {

        projectName: [
          { required: true, message: '必填项不能为空', trigger: 'change' },
          { max: 50, message: '最多50个字符', trigger: 'change' }
        ],
        leaderUserCode: [
          { required: false, message: '必填项不能为空', trigger: 'change' }
        ],
        projectCityCode: [
          { required: true, message: '必填项不能为空', trigger: 'change' }
        ],
        statusCode: [
          { required: true, message: '必填项不能为空', trigger: 'change' }
        ],
        saleStatus: [
          { required: true, message: '必填项不能为空', trigger: 'change' }
        ],
        projectShortName: [
          { max: 50, message: '最多50个字符', trigger: 'change' }
        ],
        projectLatLngBaidu: [
          { max: 50, message: '最多50个字符', trigger: 'change' }
        ],
        address: [
          { max: 200, message: '最多100个字符', trigger: 'change' }
        ],
        description: [
          { max: 200, message: '最多200个字符', trigger: 'change' }
        ],
        areaCoverd: [
          { validator: validate2Pass, trigger: 'blur' }
        ],
        buildCoverd: [
          { validator: validate2Pass, trigger: 'blur' }
        ],
        plotRat: [
          { validator: contChange, trigger: 'blur' }
        ],
        greenRat: [
          { validator: contChange, trigger: 'blur' }
        ],
        saleCoverd: [
          { validator: validate2Pass, trigger: 'blur' }
        ],
        saleRat: [
          { validator: contChange, trigger: 'blur' }
        ],
        parkingCount: [
          { validator: validatePass, trigger: 'blur' }

        ],
        houseCount: [
          { validator: validatePass, trigger: 'blur' }
        ],
        orgOrganId: [
          { required: true, message: '必填项不能为空', trigger: 'change' }
        ]

      },
      cityOptions: [],
      activityLatLngBaidu: {},
      activityAddress: '',
      mapVisible: false,
      erpList: [],
      bankLoading: false,
      bankList: [],
      statusCodeOptions: [
        { value: 100001, label: '未开工' },
        { value: 100002, label: '已开工' },
        { value: 100003, label: '停工中' },
        { value: 100004, label: '已竣工' },
        { value: 100005, label: '其他' }
      ],
      saleStatusOptions: [
        { value: 100011, label: '未开盘' },
        { value: 100012, label: '在售' },
        { value: 100013, label: '售罄' },
        { value: 100014, label: '其他' }
      ]
    }
  },
  mounted() {
    this.getAllArea()
    this.getInfo()
  },
  created() {
    if (this.$route.query.orgOrganId) {
      this.addForm.orgOrganId.push(this.$route.query.orgOrganId)
    }
  },
  methods: {
    getAllArea() {
      getAllArea().then(res => {
        this.cityOptions = res.data
      })
    },
    // 清除项目地址，经纬度
    delAddress() {
      this.addForm.address = ''
      this.addForm.projectLatLngBaidu = ''
      this.addForm.lng = ''
      this.addForm.lat = ''
    },
    getRange() {
      this.$refs.chosepart.openModal()
    },
    getOrg() {
      this.$refs.choseorg.openModal()
    },
    handleClose(item) {
      this.addForm.leaderUserCode.splice(this.addForm.leaderUserCode.indexOf(item.value), 1)
      this.addForm.leaderName.splice(this.addForm.leaderName.indexOf(item.name), 1)
    },
    handleClose1(item) {
      this.addForm.orgOrganId = []
    },
    userChange(val, val2) {
      this.addForm.leaderName = []
      if (this.addForm.leaderUserCode.length > 0) {
        this.flag = false
      } else {
        this.flag = true
      }
      val2.forEach(item => {
        this.addForm.leaderName.push(item.name)
      })
    },
    orgChange(val, val2) {
    },
    // 详情回显
    getInfo() {
      if (this.$route.query.type == 2) {
        getProjectDetail({ id: this.$route.query.id }).then(res => {
          this.addForm = res.data
          this.addForm.orgOrganId = [res.data.orgOrganId]
          let arr = []
          arr = res.data.projectCityCode.split(',')
          if (res.data.lat) {
            this.addForm.projectLatLngBaidu = res.data.lat + ',' + res.data.lng
          }
          this.addForm.projectCityCode = arr.map(item => {
            return Number(item)
          })
        })
      }
    },
    // 新增
    getSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.addForm.orgOrganId = this.addForm.orgOrganId[0]
          this.addForm.id = this.$route.query.id
          if (isArray(this.addForm.projectCityCode)) {
            this.addForm.projectCityCode = this.addForm.projectCityCode.join(',')
          }

          // const params = {
          //   orgOrganId: this.$route.query.orgOrganId,
          //   projectName: this.addForm.projectName,
          //   projectShortName: this.addForm.projectShortName,
          //   projectCityCode: this.addForm.projectCityCode.join(','), // code  ',' 号分割
          //   address: this.addForm.address,
          //   description: this.addForm.description,
          //   lng: this.addForm.lng,
          //   lat: this.addForm.lat,
          //   infoFileGroupUrl: this.addForm.infoFileGroupUrl,
          //   leaderUserCode: this.addForm.leaderUserCode,
          //   leaderName: this.addForm.leaderName,
          //   id: this.$route.query.id
          // }
          console.log(this.addForm)
          // console.log(params)
          const load = this.$load()
          if (this.$route.query.type === 1) {
            addProject(this.addForm).then(res => {
              this.$message.success('新增成功')
              load.close()
              this.$router.push({ path: '/mainData/project' })
            }).catch(e => {
              load.close()
              this.$errorHandle(e)
            })
          } else if (this.$route.query.type === 2) {
            editProject(this.addForm).then(res => {
              this.$message.success('保存成功')
              // this.getInfo()
              this.$router.replace({ path: '/mainData/project' })
              load.close()
            }).catch(e => {
              load.close()
              this.$errorHandle(e)
            })
          }
        }
      })
    },
    goback() {
      // this.$router.push({ path: '/mainData/project/index' })
      this.$router.replace('/mainData/project')
    },
    openMap() {
      // this.mapVisible = true
      this.$location({
        resultHandler: (position) => {
          this.activityAddress = position.formatted_address
          this.addForm.address = this.activityAddress
          this.addForm.projectLatLngBaidu = `${position.lat},${position.lng}`
          this.addForm.lng = `${position.lng}`
          this.addForm.lat = `${position.lat}`
        }
      })
    },
    async mapSubmit() {
      const latlng = this.activityLatLngBaidu
      if (!latlng.lat || !latlng.lng) {
        this.$message.info('请选择位置！')
        return false
      }
      try {
        this.addForm.lng = latlng.lng
        this.addForm.lat = latlng.lat
        const d2 = await reverseGeocoding(latlng.lng, latlng.lat)
        this.activityAddress = d2.result.formatted_address
      } catch (e) {
        this.$message.error('获取位置失败！')
        return false
      }
      this.addForm.address = this.activityAddress
      this.addForm.projectLatLngBaidu = `${latlng.lat},${latlng.lng}`
      this.mapVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .area_input .gp-input__inner::-webkit-outer-spin-button,
::v-deep .area_input .gp-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
</style>
