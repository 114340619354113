<template>
  <div v-loading="tableLoading" shadow="never" class="m_maindata_tender min_h_100">
    <div class="go_back_common">
      <div class="go_back_title" @click="$router.replace('/administration')">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <el-form :model="searchParam" :inline="true" @submit.native.prevent>
      <el-form-item label="项目名称">
        <UmBusProject
          ref="umbuspro"
          v-model="searchParam.busProjectUuid"
          placeholder="请选择项目"
        />
      </el-form-item>
      <el-form-item label="标段名称">
        <el-input v-model="searchParam.projectParaName" placeholder="请输入标段名称" clearable maxlength="50" />
      </el-form-item>
      <el-form-item class="fr">
        <!--        <el-button v-if="$refs.umbuspro&&!$refs.umbuspro.busProjectTreeData.length" class="f-room-button" type="primary" @click="$router.push('/mainData/project')">-->
        <!--          <svg-icon icon-class="add_all" />-->
        <!--          新增项目-->
        <!--        </el-button>-->
        <el-button type="primary" icon="el-icon-search" @click="getList">搜 索</el-button>
        <!--        <el-button icon="el-icon-circle-plus-outline" type="primary" @click="$router.push({path:'/mainData/tender/add',query:{busProjectUuid:searchParam.busProjectUuid}})">新增标段</el-button>-->
        <el-button v-permission="['112040505']" type="primary" @click="getAdd()">
          <svg-icon icon-class="add_all" />
          新增标段
        </el-button>
      </el-form-item>
    </el-form>
    <!--      </div>-->
    <NoProject v-if="$refs.umbuspro&&!$refs.umbuspro.busProjectTreeData.length" />
    <div v-else>
      <div class="m_tender_table">
        <el-table
          :data="searchData"
          stripe
          row-key="xh"
          style="width: 100%"
        >
          <el-table-column
            prop="xh"
            label="序号"
            width="80px"
          />
          <el-table-column
            prop="projectParaName"
            label="标段名称"
          >
            <template slot-scope="scope">
              <span><UmToolTip :content="scope.row.projectParaName" class="row_2" /></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="busOrganName"
            label="所属项目"
          >
            <template slot-scope="scope">
              <div style="color:#999;fon-size:12px;">
                <!--              {{ scope.row.busOrganName }}-->
                <UmToolTip :content="scope.row.busOrganName" class="row_2" />
              </div>
              <div>
                <!--              {{ scope.row.busProjectName }}-->
                <UmToolTip :content="scope.row.busProjectName" class="row_2" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="busBuildingName"
            label="楼栋范围"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="scope.row.busBuildingName==null || scope.row.busBuildingName.length==0">暂无数据</div>
              <template v-else>{{ scope.row.busBuildingName.join(',') }}</template>
            </template>
          </el-table-column>
          <el-table-column
            prop="createName"
            label="创建人/创建时间"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.createName }}</div>
              <div style="color:#999;fon-size:12px;">{{ scope.row.createTime }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="120px"
          >
            <template slot-scope="scope">
              <el-button v-permission="['112040504']" type="text" @click="getUpdate(scope.row)">编辑</el-button>
              <el-button v-permission="['112040503']" type="text" class="u_redColor" @click="removeItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="m_tender_paginations">
        <pagination
          :background="false"
          :total="searchTotal"
          :page.sync="searchParam.page.pageNum"
          :limit.sync="searchParam.page.pageSize"
          :layout="'total, sizes, prev, pager, next, jumper'"
          @pagination="getList('page')"
        />
      </div>
    </div>
  </div>
<!--  </div>-->
</template>

<script>
import { project_para_get_list, project_para_delete } from '@/api/mainData/tender.js'
import NoProject from '@/views/mainData/components/noProject'
export default {
  name: 'MainDataTender',
  components: { NoProject },
  props: {},
  data() {
    return {
      searchTotal: 0,
      searchParam: {
        busProjectUuid: '',
        projectParaName: '',
        page: {
          pageNum: 1,
          pageSize: 10
        }
      },
      searchData: [],
      tableLoading: false
    }
  },
  computed: {},
  watch: {
    'searchParam.busProjectUuid'(val) {
      this.getList()
    }
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.getList()
  },
  deactivated() {
    // 不是子路由的话做初始化
    if (this.$route.path.indexOf('/mainData/tender') === -1) {
      // this.searchParam.projectParaName = ''
      this.$store.dispatch('tagsView/delView', {
        name: 'MainDataTender',
        path: '/mainData/tender'
      })
    }
  },
  methods: {
    // 获取列表
    getList(state = '') {
      if (!this.searchParam.busProjectUuid) {
        return false
      }
      if (state !== 'page') {
        this.searchParam.page.pageNum = 1
      }
      this.tableLoading = true
      project_para_get_list({
        ...this.searchParam
      }).then(d => {
        this.tableLoading = false
        this.searchData = d.data.list
        this.searchTotal = d.data.page.total
      }).finally(_ => {
        this.tableLoading = false
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    // 新增标段
    getAdd() {
      this.$router.push({ path: '/mainData/tender/add', query: { busProjectUuid: this.searchParam.busProjectUuid }})
    },
    // 编辑
    getUpdate(row) {
      this.$router.push({ path: '/mainData/tender/update', query: { busProjectParaUuid: row.busProjectParaUuid }})
    },
    // 删除
    removeItem(row) {
      this.$confirm(`是否删除：${row.projectParaName}?`, '提示', {
        type: 'warning'
      }).then(() => {
        const load = this.$load()
        project_para_delete({
          busProjectParaUuid: row.busProjectParaUuid
        }).then(d => {
          this.$message.success('删除成功！')
          load.close()
          this.getList()
        }).catch(e => {
          load.close()
        })
      }).catch(() => {
        this.$message.info('已取消删除！')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
