<template>
  <!-- 硬件商城 -->
  <div class="g-cloudMarketBox">
    <div class="cloudMarket_leftBox">
      <div ref="searchBox" class="cloudMarket_leftBox_searchBox">
        <el-input v-model.trim="keyWord" maxlength="50" placeholder="搜索关键词" @focus="flag = true" @keyup.enter.native="getSearch()">
          <i slot="suffix" class="gp-input__icon el-icon-search pointer" @click="getSearch()" />
        </el-input>
        <div v-if="flag ===true" class="cloudMarket_leftBox_searchBox_searchRecords">
          <search :search-type="20039909" :search-list="hardwareSearchList" :tab-type="5" @words="words" />
        </div>
      </div>
      <div class="cloudMarket_leftBox_menuss">
        <el-menu
          :default-active="activeitem"
          class="gp-menu-vertical-demo"
          @select="handleSelect"
        >
          <div v-for="(item,i) in classificationList" :key="i">
            <el-menu-item :index="''+i" class="cloudMarket_leftBox_menuss_comoncolor">
              <span slot="title">
                <UmToolTip :content="item.classifyName" class="row_1" />
              </span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
    </div>
    <div class="cloudMarket_rightBox">
      <banner :banner-list="bannerList" />
      <div v-if="menuId==='0'" class="cardBox-minH" :class="SelectedHardwareList.length===0?'flex-center':''">
        <el-row v-if="SelectedHardwareList.length>0" :gutter="20">
          <el-col v-for="(item,i) in SelectedHardwareList" :key="i" :span="8">
            <hardwareList :type="5" :form="item" :tab-type="5" class="cardBox mb20" />
          </el-col>
        </el-row>
        <div v-else style="padding:20px 0;width:100%">
          <no-data />
        </div>
      </div>
      <div v-else class=" cardBox_paging cardBox-minH" :class="OtherHardwareList.length===0?'flex-center':''">
        <el-row v-if="OtherHardwareList.length>0" :gutter="20">
          <el-col v-for="(item,i) in OtherHardwareList" :key="i" :span="8">
            <hardwareList :type="5" :form="item" :tab-type="5" class="cardBox mb20" />
          </el-col>
        </el-row>
        <no-data v-else />
        <div v-if="total>0">
          <div class="cloudMarket_rightBox_pages">
            <pagination
              :total="total"
              :page.sync="page.pageNum"
              :page-sizes="[30, 60, 90, 120]"
              :limit.sync="page.pageSize"
              @pagination="getOtherHardware('page')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import search from '../components/search.vue'
import banner from '../components/banner.vue'
import noData from '../components/noData.vue'
import hardwareList from '../components/hardwareList.vue'
import { getBanner, getHardwareClassification, selectedHardware, getOtherHardware } from '@/api/cloudMarket'
export default {
  name: 'CloudMarketHardwareMall',
  components: { search, banner, noData, hardwareList },
  data() {
    return {
      activeitem: '0', // 侧边栏默认
      flag: false,
      bannerList: [],
      classificationList: [],
      SelectedHardwareList: [],
      typeId: null,
      menuId: '0',
      page: {
        pageSize: 30,
        pageNum: 1
      },
      total: 0,
      OtherHardwareList: [],
      keyWord: '',
      hardwareSearchList: [],
      hotList: []
    }
  },
  mounted() {
    this.getBanner()
    this.getApplicationMenu()
    this.getSelectedHardware()
    const key = 'hardwareSearch_' + this.$store.getters.enterpriseUuid
    try {
      const redult = JSON.parse(window.localStorage.getItem(key))
      if (redult && Array.isArray(redult)) {
        this.hardwareSearchList = redult
      } else {
        this.hardwareSearchList = []
      }
    } catch {
      this.hardwareSearchList = []
    }
  },
  created() {
    document.body.addEventListener('click', this.bodyClickhandler)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.bodyClickhandler)
  },
  methods: {
    // 点击搜索框关闭搜索框
    bodyClickhandler(event) {
      const _target = event.target
      if (this.$refs.searchBox) {
        if (this.$refs.searchBox.contains(_target)) {
          //
        } else {
          this.flag = false
        }
      }
    },
    words(val, hotList) {
      this.keyWord = val
      this.hotList = hotList
      this.getSearch()
    },
    getBanner() {
      getBanner({ bannerNo: '200912005-P' }).then(res => {
        this.bannerList = res.data
      })
    },
    // 获取应用菜单分类
    getApplicationMenu() {
      getHardwareClassification({ classifyName: '', classifyCode: 2003010106 }).then(res => {
        this.classificationList = res.data
        this.classificationList.unshift({
          id: null,
          classifyUuid: null,
          classifyName: '精选',
          classifyPhotoUrl: ''
        })
      })
    },
    // 精选硬件
    getSelectedHardware() {
      const load = this.$load()
      selectedHardware({ rankCode: 20039815 }).then(res => {
        load.close()
        this.SelectedHardwareList = []
        if (res.data.list) {
          res.data.list.forEach(item => {
            let info = {}
            info = {
              appUuid: item.spuUuid,
              appName: item.goodsTitle,
              appIconUrl: item.goodsCoverImg,
              appDescription: item.goodsDescription,
              appPrice: item.goodsPrice,
              saleNum: item.saleNum,
              visitNum: item.visitNum,
              specificationsCount: item.specificationsCount
            }
            this.SelectedHardwareList.push(info)
          })
        } else {
          this.SelectedHardwareList = []
        }
      }).catch(e => {
        load.close()
      })
    },
    // 获取其他
    getOtherHardware(state = '') {
      if (state !== 'page') {
        this.page = {
          pageSize: 30,
          pageNum: 1
        }
      }
      const params = {
        keywords: '',
        hwClassifyUuid: this.typeId,
        page: {
          pageSize: this.page.pageSize,
          pageNum: this.page.pageNum
        }
      }
      const load = this.$load()
      getOtherHardware(params).then(res => {
        this.OtherHardwareList = []
        res.data.list.forEach(item => {
          let info = {}
          info = {
            appUuid: item.spuUuid,
            appName: item.goodsTitle,
            appIconUrl: item.goodsCoverImg,
            appDescription: item.goodsDescription,
            appPrice: item.goodsPrice,
            saleNum: item.saleNum,
            visitNum: item.visitNum,
            specificationsCount: item.specificationsCount
          }
          this.OtherHardwareList.push(info)
        })
        this.total = res.data.page.total
        load.close()
      }).catch(e => {
        load.close()
      })
    },
    handleSelect(val) {
      this.menuId = val
      if (val === '0') {
        this.getSelectedHardware()
      } else {
        this.typeId = this.classificationList[val].classifyUuid
        this.getOtherHardware()
      }
    },
    getSearch() {
      if (this.keyWord) {
        // 存储
        const key = 'hardwareSearch_' + this.$store.getters.enterpriseUuid
        this.hardwareSearchList.unshift(this.keyWord)
        this.hardwareSearchList = [...new Set(this.hardwareSearchList)].splice(0, 5)
        window.localStorage.setItem(key, JSON.stringify(this.hardwareSearchList))
        this.$router.push({ path: '/cloudMarket/search', query: { keyWord: this.keyWord, hotList: this.hotList, tabType: 5 }})
        this.keyWord = ''
      } else {
        //
      }
    }
  }
}
</script>

