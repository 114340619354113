import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'

const activityRouter = {
  path: '/activity',
  component: Layout,
  meta: { title: '企业管理', icon: 'enterprise', iconActive: 'enterprise-active', level: 1, roles: ['11203'] },
  // redirect: '/enterprise/home',
  redirect: to => {
    for (let i = 0; i < activityRouter.children.length; i++) {
      const { path, meta } = activityRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  children: [
    // 活动
    {
      name: 'EnterpriseApplicationActivity',
      path: '/activity',
      component: () => import('@/views/activity'),
      meta: { title: '活动列表', group: 'enterpriseApp', level: 2, roles: ['1120303'], activeMenu: '/enterprise/application' }
    },
    {
      name: 'EnterpriseApplicationActivityAdd',
      path: '/activity/add',
      component: () => import('@/views/activity/add'),
      meta: { title: '活动新增', group: 'enterpriseApp', level: 3, roles: ['1120303'], activeMenu: '/enterprise/application' }
    },
    {
      name: 'EnterpriseApplicationActivityUpdate',
      path: '/activity/update',
      component: () => import('@/views/activity/update'),
      meta: { title: '活动编辑', group: 'enterpriseApp', level: 3, roles: ['1120303'], activeMenu: '/enterprise/application' }
    },
    {
      name: 'EnterpriseApplicationActivityInfo',
      path: '/activity/info',
      component: () => import('@/views/activity/info'),
      meta: { title: '活动详情', group: 'enterpriseApp', level: 3, roles: ['1120303'], activeMenu: '/enterprise/application' }
    }
  ]
}
export default activityRouter
