<template>
  <div class="m_enterprise_curseinfo">
    <div class="go_back_common">
      <div class="go_back_title" @click="back">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="m_curseinfo_formBody">
      <div class="flex-between">
        <div class="title-dh mb20 mt20">基本信息</div>
        <div>
          <el-button type="primary" class="m_curseinfo_btndelete" @click="del">
            <span class="m_curseinfo_deleaction" />
            删除课程
          </el-button>
          <el-button type="primary" class="m_curseinfo_btnedit mr20" @click="edit">编辑</el-button>
        </div>
      </div>
      <div class="m_curseinfo_info">
        <div class="m_curseinfo_info_item">
          <div class="m_curseinfo_label">课程图片：</div>
          <div class="m_curseinfo_value">
            <el-image
              style="width: 120px; height: 90px;border-radius: 4px"
              :src="info.curriculumIconUrl"
              fit="fit"
              :preview-src-list="[info.curriculumIconUrl]"
            />
          </div>
        </div>
        <div class="m_curseinfo_info_item">
          <div class="m_curseinfo_label">课程名称：</div>
          <div class="m_curseinfo_value">{{ info.curriculumName }}</div>
        </div>
        <div class="m_curseinfo_info_item">
          <div class="m_curseinfo_label">主讲人：</div>
          <div class="m_curseinfo_value">{{ info.curriculumSpeaker }}</div>
        </div>
        <div class="m_curseinfo_info_item">
          <div class="m_curseinfo_label">课程地点：</div>
          <div class="m_curseinfo_value">{{ info.curriculumAddress }}</div>
        </div>
        <div class="m_curseinfo_info_item">
          <div class="m_curseinfo_label">报名时间：</div>
          <div class="m_curseinfo_value">{{ info.applyStartTime | formatDate }} <span v-if="info.applyEndTime">~</span> {{ info.applyEndTime | formatDate }}</div>
        </div>
        <div class="m_curseinfo_info_item">
          <div class="m_curseinfo_label">课程时间：</div>
          <div class="m_curseinfo_value">
            {{ info.curriculumStartTime | formatDate }} <span v-if="info.curriculumEndTime">~</span>{{ info.curriculumEndTime | formatDate }}
          </div>
        </div>
        <div class="m_curseinfo_info_item">
          <div class="m_curseinfo_label">报名人数：</div>
          <div class="m_curseinfo_value">{{ info.signMaxNum }}</div>
        </div>
        <div class="m_curseinfo_info_item" style="align-items: flex-start">
          <div class="m_curseinfo_label">详细内容：</div>
          <div class="m_curseinfo_value" style="flex-grow: 1">
            <um-url :url="info.curriculumDescUrl" />
          </div>
        </div>
      </div>
    </div>
    <div class="title-dh mb20 mt20">报名用户({{ info.signUpNum }})</div>
    <div class="bm-usr">
      <UmBusUser :value="userUuids" :type="2" :show-prefix-button="false">
        <template #user="{data}">
          <span v-if="data&&data.length">
            <div class="bm-user-item-top">
              <div v-for="it in data" :key="it.id" class="bm-user-item-top-item">
                <div class="bm-user-item">
                  <img :src="it.icon" alt="">
                  <div class="name row_1">{{ it.name }}</div>
                </div>
              </div>
            </div>
          </span>
          <div v-else class="no-data">
            <span class="no-data-title">暂无数据</span>
          </div>
        </template>
      </UmBusUser>
    </div>
    <div style="padding-right: 20px">
      <pagination
        :background="false"
        :layout="'total, sizes, prev, pager, next, jumper'"
        :limit.sync="page.pageSize"
        :page.sync="page.pageNum"
        :total="tableTotal"
        @pagination="getUserList('page')"
      />
    </div>
  </div>
</template>

<script>
import {
  curriculumQuery_curriculum_info,
  curriculumGet_signup_num,
  curriculumDelete_curriculum
} from '@/api/enterprise'
import moment from 'moment'

export default {
  name: 'EnterpriseApplicationCourseInfo',
  filters: {
    formatDate(time) {
      if (time) return moment(time).format('YYYY-MM-DD HH:mm')
      return ''
    }
  },
  props: {},
  data() {
    return {
      curriculumUuid: '',
      info: {
        curriculumIconUrl: '',
        curriculumName: '',
        curriculumSpeaker: '',
        curriculumAddress: '',
        curriculumStartTime: '',
        curriculumEndTime: '',
        applyStartTime: '',
        applyEndTime: '',
        curriculumInfo: '',
        signMaxNum: '',
        signUpNum: ''
      },
      userList: [],
      page: {
        pageNum: 1,
        pageSize: 10
      },
      // total: 0,
      tableTotal: 0,
      userUuids: []
    }
  },
  created() {
    this.curriculumUuid = this.$route.query.curriculumUuid
    if (this.curriculumUuid) {
      this.getInfo()
      this.getUserList()
    }
  },
  mounted() { },
  methods: {
    getInfo() {
      curriculumQuery_curriculum_info({ curriculumUuid: this.curriculumUuid, page: this.page }).then(res => {
        this.info = res.data
        // this.total = res.data.total
      })
    },
    getUserList() {
      curriculumGet_signup_num({ curriculumUuid: this.curriculumUuid, page: this.page }).then(res => {
        // this.userList = res.data.list
        // this.userUuids = res.data.list
        const arr = []
        res.data.list.forEach(item => arr.push(item.userUuid))
        this.userUuids = arr
        this.tableTotal = res.data.page.total
      })
    },
    back() {
      this.$router.replace({
        path: '/course',
        query: {
          appUuid: this.$route.query.appUuid
        }
      })
    },
    del() {
      const h = this.$createElement
      this.$confirm('', {
        message: h('div', null, [
          h('p', { style: 'color:#31415F;fontSize: 16px;fontWeight: 500;' }, '是否确定删除？')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        customClass: 'message-dialog'
      }).then(() => {
        const load = this.$load()
        curriculumDelete_curriculum({ curriculumUuid: this.curriculumUuid }).then(res => {
          this.$message({ type: 'success', message: '删除成功!' })
          load.close()
          this.back()
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        // this.$message({ type: 'info', message: '操作失败' })
      })
    },
    edit() {
      this.$router.push({
        path: '/course/update',
        query: {
          curriculumUuid: this.curriculumUuid,
          appUuid: this.$route.query.appUuid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
