<template>
  <div class="m_mandata_noproject">
    <div class="m_mandata_noproject_pic" />
    <div class="m_mandata_noproject_txt">
      <div class="u_title1">暂无项目</div>
      <div class="u_title2">请添加项目或授权后重试</div>
      <div class="f-room-button u_moproject_btn" @click="$router.push('/mainData/project')">
        <svg-icon icon-class="add_all" />
        <span>新增项目</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoProject',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">

</style>
