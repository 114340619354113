import router from '@/router'

const state = {
  pathRoute: {}
}

const mutations = {
  // 修改页面path
  SET_PATH: (state, { name, path }) => {
    state.pathRoute[name] = path
  },
  REMOVE_PATH: (state, { name }) => {
    state.pathRoute[name] = null
  }
}

const actions = {
  /**
   * 跳转到指定微前端页面
   * @param commit
   * @param name 应用的名称 AppPkg1 AppPkg2
   * @param path 需要跳转的地址 可以不传
   * @param isPath 是否需要使用 path 进行跳转
   */
  jumpAPP({ commit }, { name, path, isPath = false }) {
    commit('SET_PATH', { name, path })
    if (name === 'main') {
      router.push(path) // 跳转平台  审批流  会议室  云市场详情
    } else {
      if (isPath) {
        router.push({ path: '/application/' + name }) // 跳转应用
      } else {
        router.push({ name: name }) // 跳转应用
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
