<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" :inline="true">
      <el-table
        ref="table"
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="busCheckItemId"
        default-expand-all
        :border="true"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        @select="select"
        @select-all="selectAll"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          label="全选"
          width="55"
        >
          <el-table-column
            type="selection"
            width="55"
          />
        </el-table-column>
        <el-table-column
          label="检查项"
          min-width="180"
          prop="checkItemName"
          show-overflow-tooltip
          align="left"
        />

        <el-table-column
          label="默认责任单位(单选)"
          width="180"
          align="center"
        >
          <el-table-column
            prop="busContractor"
            label="批量设置"
            align="center"
            width="180"
          >
            <template slot="header">
              <el-button type="text" @click="setCompany">批量设置责任单位</el-button>
            </template>
            <template slot-scope="scope">
              <template v-if="scope.row.itemDirectoryFlag == 1 || (scope.row.children && scope.row.children.length > 0)" />
              <template v-else-if="scope.row.busContractor==null ">
                未设置
              </template>
              <template v-else>
                <el-tag size="mini" style="margin-right: 4px" closable @close="closeTag(scope.row,'busContractor')">{{ scope.row.busContractor.contractorName }}</el-tag>
              </template>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="默认整改人(单选)"
          width="180"
          align="center"
        >
          <el-table-column
            prop="rectificationUser"
            label="批量设置"
            width="180"
            align="center"
          >
            <template slot="header">
              <el-button type="text" @click="editUser('rectificationUser',1,[1])">批量设置整改人</el-button>
            </template>
            <template slot-scope="scope">
              <template v-if="scope.row.itemDirectoryFlag == 1 || (scope.row.children && scope.row.children.length > 0)" />
              <template v-else-if="scope.row.rectificationUser==null ">
                未设置
              </template>
              <template v-else>
                <el-tag size="mini" style="margin-right: 4px" closable @close="closeTag(scope.row,'rectificationUser')">{{ scope.row.rectificationUser.userName }}</el-tag>
              </template>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="默认复验人(单选)"
          align="center"
        >
          <el-table-column
            prop="reinspectionUser"
            label="批量设置"
            align="center"
          >
            <template slot="header">
              <el-button type="text" @click="editUser('reinspectionUser',1)">批量设置复验人</el-button>
            </template>
            <template slot-scope="scope">
              <template v-if="scope.row.itemDirectoryFlag == 1 || (scope.row.children && scope.row.children.length > 0)" />
              <template v-else-if="scope.row.reinspectionUser==null ">
                未设置
              </template>
              <template v-else>
                <el-tag size="mini" style="margin-right: 4px" closable @close="closeTag(scope.row,'reinspectionUser')">{{ scope.row.reinspectionUser.userName }}</el-tag>
              </template>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="默认抄送人(多选)"
          align="center"
        >
          <el-table-column
            prop="copyUser"
            label="批量设置"
            align="center"
          >
            <template slot="header">
              <el-button type="text" @click="editUser('copyUser',0)">批量设置抄送人</el-button>
            </template>
            <template slot-scope="scope">
              <template v-if="scope.row.itemDirectoryFlag == 1 || (scope.row.children && scope.row.children.length > 0)" />
              <template v-else-if="scope.row.copyUser==null || scope.row.copyUser.length==0">
                未设置
              </template>
              <template v-else>
                <el-tag v-for="(item,index) in scope.row.copyUser" :key="index" closable size="mini" style="margin-right: 4px" @close="closeTag(scope.row,'copyUser',index)">{{ item.userName }}</el-tag>
              </template>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24" class="text-center">
          <el-button type="primary" @click="submit">保 存</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog title="选择责任单位" :visible.sync="visible" width="500px" @close="$refs['companyForm'].resetFields()">
      <el-form ref="companyForm" :model="companyForm" size="mini" label-width="100px" @submit.native.prevent>
        <el-form-item label="单位名称" prop="busContractor">
          <el-select
            v-model="companyForm.busContractor"
            placeholder="请选择"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in contractorData"
              :key="index"
              :label="item.contractorName"
              :value="item.busContractorId"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="addCompany">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils'
// import { busProjectParaContractorData, busProjectParaInspection, busProjectParaInspectionSubmit } from '@/api/projectPara'
export default {
  name: 'Code1',
  props: {
    busProjectParaId: {
      type: [String, Number],
      default: null
    },
    isAllowModify: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      busProjectId: '',
      form: {
        isAllowModify: 0,
        busProjectParaId: '',
        busCheckItemList: []
      },
      companyForm: {
        busContractor: null
      },
      rules: {},
      // tableData: [],
      tableData: [
        {
          busCheckItemId: 1836,
          checkItemName: '安全文明施工',
          parentId: 0,
          itemDirectoryFlag: 1,
          busContractor: null,
          rectificationUser: null,
          reinspectionUser: null,
          copyUser: [],
          children: [
            {
              busCheckItemId: 1839,
              checkItemName: '临时道路',
              parentId: 1836,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1838,
              checkItemName: '现场扬尘',
              parentId: 1836,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1837,
              checkItemName: '生活区',
              parentId: 1836,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            }
          ]
        },
        {
          busCheckItemId: 1002,
          checkItemName: '设计缺陷',
          parentId: 0,
          itemDirectoryFlag: 0,
          busContractor: null,
          rectificationUser: null,
          reinspectionUser: null,
          copyUser: [],
          children: []
        },
        {
          busCheckItemId: 1075,
          checkItemName: '安全（安装）',
          parentId: 0,
          itemDirectoryFlag: 1,
          busContractor: null,
          rectificationUser: null,
          reinspectionUser: null,
          copyUser: [],
          children: [
            {
              busCheckItemId: 1085,
              checkItemName: '其它',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1082,
              checkItemName: '用电档案',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1079,
              checkItemName: '配电箱、开关箱',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1076,
              checkItemName: '塔吊',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1084,
              checkItemName: '生活区环境卫生',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1081,
              checkItemName: '电器、装置',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1078,
              checkItemName: '接地与接零保护系统',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1083,
              checkItemName: '消防设施',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1080,
              checkItemName: '现场照明',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1077,
              checkItemName: '施工电梯',
              parentId: 1075,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            }
          ]
        },
        {
          busCheckItemId: 988,
          checkItemName: '安全（土建）',
          parentId: 0,
          itemDirectoryFlag: 1,
          busContractor: null,
          rectificationUser: null,
          reinspectionUser: null,
          copyUser: [],
          children: [
            {
              busCheckItemId: 991,
              checkItemName: '卸料平台',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1074,
              checkItemName: '其它',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1071,
              checkItemName: '工地大门道路交通',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1069,
              checkItemName: '洞口及临边防护',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 990,
              checkItemName: '脚手架吊篮',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1073,
              checkItemName: '施工现场厕所',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1070,
              checkItemName: '铭牌标识',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 992,
              checkItemName: '安全帽安全带',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1072,
              checkItemName: '平面布置',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 993,
              checkItemName: '安全通道及防护棚',
              parentId: 988,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            }
          ]
        },
        {
          busCheckItemId: 999,
          checkItemName: '质量缺陷',
          parentId: 0,
          itemDirectoryFlag: 0,
          busContractor: null,
          rectificationUser: null,
          reinspectionUser: null,
          copyUser: [],
          children: []
        },
        {
          busCheckItemId: 994,
          checkItemName: '质量（土建）',
          parentId: 0,
          itemDirectoryFlag: 1,
          busContractor: null,
          rectificationUser: null,
          reinspectionUser: null,
          copyUser: [],
          children: [
            {
              busCheckItemId: 1096,
              checkItemName: '其它',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1093,
              checkItemName: '地库划线工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1090,
              checkItemName: '单元门',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1087,
              checkItemName: '外墙保温工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1000,
              checkItemName: '屋面工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 996,
              checkItemName: '二次结构工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1095,
              checkItemName: '回填土工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1092,
              checkItemName: '石材幕墙工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1089,
              checkItemName: '入户门',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1086,
              checkItemName: '公共区域装饰工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 998,
              checkItemName: '门窗工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 995,
              checkItemName: '主体工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1094,
              checkItemName: '金刚砂地坪工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1091,
              checkItemName: '防火门',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1088,
              checkItemName: '外墙涂料工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1001,
              checkItemName: '防水工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 997,
              checkItemName: '粉刷工程',
              parentId: 994,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            }
          ]
        },
        {
          busCheckItemId: 1003,
          checkItemName: '园林景观',
          parentId: 0,
          itemDirectoryFlag: 1,
          busContractor: null,
          rectificationUser: null,
          reinspectionUser: null,
          copyUser: [],
          children: [
            {
              busCheckItemId: 1008,
              checkItemName: '交房区',
              parentId: 1003,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1005,
              checkItemName: '景观基层',
              parentId: 1003,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1007,
              checkItemName: '样板展示区',
              parentId: 1003,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1004,
              checkItemName: '土建',
              parentId: 1003,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1006,
              checkItemName: '绿化',
              parentId: 1003,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            }
          ]
        },
        {
          busCheckItemId: 1009,
          checkItemName: '质量（安装）',
          parentId: 0,
          itemDirectoryFlag: 1,
          busContractor: null,
          rectificationUser: null,
          reinspectionUser: null,
          copyUser: [],
          children: [
            {
              busCheckItemId: 1098,
              checkItemName: '其它',
              parentId: 1009,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1011,
              checkItemName: '电气设备工程',
              parentId: 1009,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1097,
              checkItemName: '地库安装工程',
              parentId: 1009,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1010,
              checkItemName: '水暖安装工程',
              parentId: 1009,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            },
            {
              busCheckItemId: 1012,
              checkItemName: '智能化工程',
              parentId: 1009,
              itemDirectoryFlag: 0,
              busContractor: null,
              rectificationUser: null,
              reinspectionUser: null,
              copyUser: [],
              children: []
            }
          ]
        }
      ],
      tableDataClone: [],
      // contractorData: [],// 默认单位
      contractorData: [
        {
          busContractorId: 120,
          contractorName: '广东合创工程总承包有限公司'
        },
        {
          busContractorId: 121,
          contractorName: '河北新隆基项目管理有限公司'
        },
        {
          busContractorId: 122,
          contractorName: '唐山市曹妃甸区艾氏建筑工程有限公司'
        },
        {
          busContractorId: 123,
          contractorName: '昌达兴盛（北京）建筑工程有限公司'
        },
        {
          busContractorId: 124,
          contractorName: '乐亭县鼎力基础工程有限公司'
        },
        {
          busContractorId: 125,
          contractorName: '河北益坤岩土工程新技术有限公司'
        },
        {
          busContractorId: 126,
          contractorName: '唐山恒大人防设备有限公司'
        },
        {
          busContractorId: 127,
          contractorName: '北京金地通检测技术有限公司'
        },
        {
          busContractorId: 128,
          contractorName: '唐山信德房产测绘工程有限公司'
        },
        {
          busContractorId: 129,
          contractorName: '广东合创集团有限公司                  '
        },
        {
          busContractorId: 130,
          contractorName: '浙江省岩土基础有限公司'
        },
        {
          busContractorId: 131,
          contractorName: '浙江欣捷建设有限公司'
        },
        {
          busContractorId: 132,
          contractorName: '浙江省地矿勘察院有限公司'
        },
        {
          busContractorId: 133,
          contractorName: '宁波哲阳工程管理有限公司'
        },
        {
          busContractorId: 134,
          contractorName: '浙江通杭建设工程有限公司'
        },
        {
          busContractorId: 135,
          contractorName: '宁波盈泰设备安装有限公司'
        },
        {
          busContractorId: 136,
          contractorName: '慈溪市开元城市建设配套工程有限公司'
        },
        {
          busContractorId: 137,
          contractorName: '上海群鼎体育场地工程有限公司'
        },
        {
          busContractorId: 138,
          contractorName: '广州翔实工程咨询有限公司'
        },
        {
          busContractorId: 139,
          contractorName: '广东伟明建设工程有限公司'
        },
        {
          busContractorId: 140,
          contractorName: '中山市火炬开发区岭杰土石方工程部'
        },
        {
          busContractorId: 141,
          contractorName: '广东佳业建设有限公司'
        },
        {
          busContractorId: 142,
          contractorName: '建材广州工程勘测院有限公司'
        },
        {
          busContractorId: 143,
          contractorName: '广州市穗升有害生物防治中心'
        },
        {
          busContractorId: 144,
          contractorName: '广东绘宇智能勘测科技有限公司'
        },
        {
          busContractorId: 145,
          contractorName: '中山市中盾人防工程有限公司'
        },
        {
          busContractorId: 146,
          contractorName: '广州市高唯空间装饰设计工程有限公司'
        },
        {
          busContractorId: 147,
          contractorName: '广州市泓立广告有限公司'
        },
        {
          busContractorId: 148,
          contractorName: '中山市安顺网络工程有限公司'
        },
        {
          busContractorId: 149,
          contractorName: '中山市卓安交通安全设施工程有限公司'
        },
        {
          busContractorId: 150,
          contractorName: '广州市岭创建筑工程有限公司'
        },
        {
          busContractorId: 151,
          contractorName: '江门市蓬江区建筑工程质量检测站'
        },
        {
          busContractorId: 152,
          contractorName: '安徽省永信人防工程建设监理有限公司广州分公司'
        },
        {
          busContractorId: 153,
          contractorName: '日立电梯（中国）有限公司'
        },
        {
          busContractorId: 154,
          contractorName: '日立电梯（中国）有限公司广州分公司'
        },
        {
          busContractorId: 155,
          contractorName: '江门公用工程有限公司'
        },
        {
          busContractorId: 156,
          contractorName: '广东建准检测技术有限公司'
        },
        {
          busContractorId: 157,
          contractorName: '江苏宏远建设集团有限公司'
        },
        {
          busContractorId: 158,
          contractorName: '天津市天立建筑工程监理公司'
        },
        {
          busContractorId: 159,
          contractorName: '天津高科宇通人防设备制造有限公司'
        },
        {
          busContractorId: 160,
          contractorName: '天津市森源建筑有限公司'
        },
        {
          busContractorId: 161,
          contractorName: '天津市嵘鑫盛有限公司 '
        },
        {
          busContractorId: 162,
          contractorName: '天津市营宸古建筑工程有限责任公司'
        },
        {
          busContractorId: 163,
          contractorName: '天津日立电梯工程有限公司'
        },
        {
          busContractorId: 164,
          contractorName: '天津市地质工程勘察院'
        },
        {
          busContractorId: 176,
          contractorName: '福建惠东建筑工程有限公司'
        },
        {
          busContractorId: 177,
          contractorName: '广东粤建工程项目管理有限公司惠州分公司'
        },
        {
          busContractorId: 178,
          contractorName: '白蚁防治施工'
        },
        {
          busContractorId: 179,
          contractorName: '粤港金融科技泛光照明'
        },
        {
          busContractorId: 180,
          contractorName: '广东嘉源建设工程有限公司'
        },
        {
          busContractorId: 181,
          contractorName: '临时道路工程'
        },
        {
          busContractorId: 182,
          contractorName: '基坑支护及降水工程'
        },
        {
          busContractorId: 186,
          contractorName: '样板间土建及精装修工程'
        },
        {
          busContractorId: 187,
          contractorName: '基坑支护及降水'
        },
        {
          busContractorId: 188,
          contractorName: '土石方工程'
        },
        {
          busContractorId: 189,
          contractorName: '人防监理'
        },
        {
          busContractorId: 190,
          contractorName: '沉降观测'
        },
        {
          busContractorId: 191,
          contractorName: '中化明达（福建)地质勘测有限公司'
        },
        {
          busContractorId: 192,
          contractorName: '浙江省地矿勘查院有限公司'
        },
        {
          busContractorId: 193,
          contractorName: '浙江上建防护科技有限公司'
        },
        {
          busContractorId: 194,
          contractorName: '康力电梯股份有限公司'
        },
        {
          busContractorId: 195,
          contractorName: '浙江东亿工程管理有限公司'
        },
        {
          busContractorId: 205,
          contractorName: '桩基检测'
        },
        {
          busContractorId: 206,
          contractorName: '华帝股份有限公司'
        },
        {
          busContractorId: 451,
          contractorName: '广东财贸建设工程顾问有限公司'
        },
        {
          busContractorId: 452,
          contractorName: '作废'
        },
        {
          busContractorId: 453,
          contractorName: '联建建设工程有限公司'
        },
        {
          busContractorId: 454,
          contractorName: '中国有色金属昆明院地勘'
        },
        {
          busContractorId: 455,
          contractorName: '江西鲲鹏装饰设计工程有限公司'
        },
        {
          busContractorId: 456,
          contractorName: '广东省东元信实业有限公司'
        },
        {
          busContractorId: 457,
          contractorName: '广东东森检测技术有限公司'
        },
        {
          busContractorId: 458,
          contractorName: '东莞市千熙装饰设计工程有限公司'
        },
        {
          busContractorId: 459,
          contractorName: '广东韵城建设工程有限公司'
        },
        {
          busContractorId: 460,
          contractorName: '东莞市粤建监理工程有限公司惠州分公司'
        },
        {
          busContractorId: 461,
          contractorName: '惠州市赛洋实业有限公司'
        },
        {
          busContractorId: 462,
          contractorName: '建勘勘测有限公司'
        },
        {
          busContractorId: 463,
          contractorName: '中国有色金属工业昆明勘查研究设计院有限公司'
        },
        {
          busContractorId: 464,
          contractorName: '深圳市中建基础工程有限公司'
        },
        {
          busContractorId: 465,
          contractorName: '广东韩江工程总承包有限公司'
        },
        {
          busContractorId: 466,
          contractorName: '深圳市建力建设监理有限公司'
        },
        {
          busContractorId: 467,
          contractorName: '佛山市房建集团有限公司'
        },
        {
          busContractorId: 469,
          contractorName: '广东金筑工程管理有限公司'
        },
        {
          busContractorId: 470,
          contractorName: '浙江文华建设项目管理有限公司'
        },
        {
          busContractorId: 471,
          contractorName: '中国建筑西南戡察设计研究院有限公司'
        },
        {
          busContractorId: 472,
          contractorName: '浙江中博地质工程有限公司'
        },
        {
          busContractorId: 473,
          contractorName: '浙江云鼎建设有限公司'
        },
        {
          busContractorId: 474,
          contractorName: '浙江浙城工程质量检测有限公司'
        },
        {
          busContractorId: 475,
          contractorName: '上海地矿工程勘察有限公司'
        },
        {
          busContractorId: 476,
          contractorName: '杭州弘顺人防设备有限公司'
        },
        {
          busContractorId: 478,
          contractorName: '杭州华峰地基基础工程有限公司'
        },
        {
          busContractorId: 479,
          contractorName: '上海一建建设有限公司'
        },
        {
          busContractorId: 480,
          contractorName: '浙江大华建设集团有限公司'
        },
        {
          busContractorId: 482,
          contractorName: '杭州同启门窗有限公司'
        },
        {
          busContractorId: 483,
          contractorName: '日立电梯（中国）有限公司杭州工程有限公司'
        },
        {
          busContractorId: 488,
          contractorName: '天津塘沽海洋高新技术开发区工程监理有限公司'
        },
        {
          busContractorId: 490,
          contractorName: '江西中捷建设工程有限公司'
        },
        {
          busContractorId: 491,
          contractorName: '天津市天立建筑建筑工程监理公司'
        },
        {
          busContractorId: 492,
          contractorName: '天津市防雷技术中心第三分中心'
        },
        {
          busContractorId: 493,
          contractorName: '杭州西奥电梯有限公司'
        },
        {
          busContractorId: 494,
          contractorName: '天津中建建筑技术发展有限公司'
        },
        {
          busContractorId: 495,
          contractorName: '天津帝雅建筑工程有限公司'
        },
        {
          busContractorId: 496,
          contractorName: '天津新兴检测技术有限公司'
        },
        {
          busContractorId: 497,
          contractorName: '天津立坤祥金属制品有限公司'
        },
        {
          busContractorId: 516,
          contractorName: '惠州大亚湾天之宏白蚁防治有限公司'
        },
        {
          busContractorId: 518,
          contractorName: '昆山鑫正项目管理有限公司'
        },
        {
          busContractorId: 520,
          contractorName: '昆山市建设工程质量检测中心'
        },
        {
          busContractorId: 521,
          contractorName: '江苏美杰欣建设发展有限公司'
        },
        {
          busContractorId: 524,
          contractorName: '天津众和恒泰人防工程防护设备有限公司'
        },
        {
          busContractorId: 525,
          contractorName: '广东粤建工程项目管理有限公司'
        },
        {
          busContractorId: 526,
          contractorName: '惠东县城市建筑工程总公司'
        },
        {
          busContractorId: 527,
          contractorName: '惠州市建筑工程有限公司'
        },
        {
          busContractorId: 528,
          contractorName: '中国有色金属工业昆明勘察设计研究院有限公司'
        },
        {
          busContractorId: 529,
          contractorName: '惠州市人防防护设备有限公司'
        },
        {
          busContractorId: 530,
          contractorName: '广东省工程勘察院'
        },
        {
          busContractorId: 531,
          contractorName: '广东省惠州地质工程勘察院'
        },
        {
          busContractorId: 538,
          contractorName: '浙江欣捷建设工程有限公司'
        },
        {
          busContractorId: 540,
          contractorName: '宁波恒超建设工程有限公司'
        },
        {
          busContractorId: 541,
          contractorName: '宁波市建工检测有限公司'
        },
        {
          busContractorId: 542,
          contractorName: '上海蓝天房屋装饰工程有限公司'
        },
        {
          busContractorId: 543,
          contractorName: '中铁建设集团有限公司'
        },
        {
          busContractorId: 544,
          contractorName: '南通市中南建工设备安装有限公司'
        },
        {
          busContractorId: 545,
          contractorName: '上海广程照明器具有限公司'
        },
        {
          busContractorId: 546,
          contractorName: '江苏顶好装饰工程有限公司'
        },
        {
          busContractorId: 547,
          contractorName: '江苏中岩岩土工程有限公司'
        },
        {
          busContractorId: 548,
          contractorName: '江苏自勤建设发展有限公司'
        },
        {
          busContractorId: 549,
          contractorName: '上海雯联机电有限公司'
        },
        {
          busContractorId: 550,
          contractorName: '苏州新坐标标识工程有限公司'
        },
        {
          busContractorId: 551,
          contractorName: '江苏苏力电工程建设有限公司'
        },
        {
          busContractorId: 552,
          contractorName: '上海明祺建设工程有限公司'
        },
        {
          busContractorId: 553,
          contractorName: '上海安宁消防工程有限公司'
        },
        {
          busContractorId: 554,
          contractorName: '南京汇泓网络科技有限责任公司苏州分公司'
        },
        {
          busContractorId: 555,
          contractorName: '苏州鹿峰建设工程有限公司'
        },
        {
          busContractorId: 556,
          contractorName: '江苏乾城智慧城市科技有限公司'
        },
        {
          busContractorId: 557,
          contractorName: '超宇集团有限公司'
        },
        {
          busContractorId: 558,
          contractorName: '广东珠江工程总承包有限公司'
        },
        {
          busContractorId: 559,
          contractorName: '江苏地基工程有限公司'
        },
        {
          busContractorId: 560,
          contractorName: '苏州中岩勘测有限公司'
        },
        {
          busContractorId: 561,
          contractorName: '江苏华实市政建设集团有限公司 '
        },
        {
          busContractorId: 562,
          contractorName: '苏州市科岩岩土工程技术服务有限公司'
        },
        {
          busContractorId: 563,
          contractorName: '宜兴市人防设备厂有限公司'
        },
        {
          busContractorId: 564,
          contractorName: '江苏兴盛工程咨询监理有限公司'
        },
        {
          busContractorId: 565,
          contractorName: '江苏苏力电工程有限公司'
        },
        {
          busContractorId: 566,
          contractorName: '浙江康正工程管理有限公司'
        },
        {
          busContractorId: 567,
          contractorName: '慈溪市城西建筑工程有限公司'
        },
        {
          busContractorId: 568,
          contractorName: '宁波挚诚检测有限公司'
        },
        {
          busContractorId: 569,
          contractorName: '宁波杭州湾华润燃气有限公司'
        },
        {
          busContractorId: 570,
          contractorName: '上海正欧涂料有限有限公司'
        },
        {
          busContractorId: 571,
          contractorName: '浙江华咨结构设计事务所有限公司'
        },
        {
          busContractorId: 572,
          contractorName: '江苏春阳幕墙门窗股份有限公司'
        },
        {
          busContractorId: 573,
          contractorName: '上海睦腾智能科技有限公司'
        },
        {
          busContractorId: 574,
          contractorName: '联通系统集成有限公司浙江省分公司'
        },
        {
          busContractorId: 575,
          contractorName: '上海三胜康恩得电梯有限公司'
        },
        {
          busContractorId: 576,
          contractorName: '广州市领创建筑工程有限公司'
        },
        {
          busContractorId: 577,
          contractorName: '广州高唯空间装饰设计工程有限公司'
        },
        {
          busContractorId: 578,
          contractorName: '东莞市海力峰设计装饰工程有限公司'
        },
        {
          busContractorId: 579,
          contractorName: '广州新恒基消防工程有限公司'
        },
        {
          busContractorId: 580,
          contractorName: '中山市长江电气安装工程有限公司'
        },
        {
          busContractorId: 581,
          contractorName: '广东天科建设工程有限公司'
        },
        {
          busContractorId: 582,
          contractorName: '广东洪源建设有限公司'
        },
        {
          busContractorId: 583,
          contractorName: '深圳市艺越建设集团有限公司'
        },
        {
          busContractorId: 584,
          contractorName: '广东皓峰机电设备有限公司'
        },
        {
          busContractorId: 585,
          contractorName: '中山港华燃气有限公司'
        },
        {
          busContractorId: 586,
          contractorName: '广东省广播电视网络股份有限公司中山分公司'
        },
        {
          busContractorId: 587,
          contractorName: '广州市吉华勘测股份有限公司'
        },
        {
          busContractorId: 588,
          contractorName: '中山市检通建设工程质量检测有限公司 '
        },
        {
          busContractorId: 589,
          contractorName: '珠海市岱华装饰材料有限公司'
        },
        {
          busContractorId: 590,
          contractorName: '中山公用水务有限公司'
        },
        {
          busContractorId: 591,
          contractorName: '广州盛启康发电机有限公司'
        },
        {
          busContractorId: 592,
          contractorName: '广西基础勘察工程有限责任公司'
        },
        {
          busContractorId: 593,
          contractorName: '浙江华特装饰股份有限公司'
        },
        {
          busContractorId: 594,
          contractorName: '杭州老板电器股份有限公司 '
        },
        {
          busContractorId: 595,
          contractorName: '佛山市精弘泰金属标牌有限公司'
        },
        {
          busContractorId: 596,
          contractorName: '中山市华志建设工程有限公司'
        },
        {
          busContractorId: 597,
          contractorName: '广东广晟通信技术有限公司'
        },
        {
          busContractorId: 598,
          contractorName: '广东新典雅实业有限公司'
        },
        {
          busContractorId: 599,
          contractorName: '广东正茂工程管理有限公司'
        },
        {
          busContractorId: 600,
          contractorName: '浩兴（广东）建设发展有限公司'
        },
        {
          busContractorId: 601,
          contractorName: '中煤江南建设发展有限公司花都分公司'
        },
        {
          busContractorId: 602,
          contractorName: '广州学初建筑工程有限公司'
        },
        {
          busContractorId: 603,
          contractorName: '广州市稳健工程检测有限公司'
        },
        {
          busContractorId: 604,
          contractorName: '广州市赛特检测有限公司'
        },
        {
          busContractorId: 605,
          contractorName: '广东珠江建设工程监理有限公司'
        },
        {
          busContractorId: 606,
          contractorName: '广东省珠海工程勘察院'
        },
        {
          busContractorId: 607,
          contractorName: '化州市悍马劳务服务有限公司'
        },
        {
          busContractorId: 608,
          contractorName: '广东城市建设集团有限公司'
        },
        {
          busContractorId: 609,
          contractorName: '广州番禺新奥燃气有限公司'
        },
        {
          busContractorId: 610,
          contractorName: '广东省东莞地质工程勘察院'
        },
        {
          busContractorId: 611,
          contractorName: '广东金裕建设工程有限公司'
        },
        {
          busContractorId: 612,
          contractorName: '广州市地平线岩土工程有限公司'
        },
        {
          busContractorId: 613,
          contractorName: '广州友宗装饰工程有限公司'
        },
        {
          busContractorId: 614,
          contractorName: '揭阳方圆石材有限公司'
        },
        {
          busContractorId: 615,
          contractorName: '广州市众帮水电工程有限公司'
        },
        {
          busContractorId: 616,
          contractorName: '广州增城正源建设工程质量检测中心'
        },
        {
          busContractorId: 617,
          contractorName: '深圳中翰建设工程有限公司'
        },
        {
          busContractorId: 618,
          contractorName: '衡一建设集团有限公司'
        },
        {
          busContractorId: 619,
          contractorName: '长春建工勘测规划设计有限公司广州分公司'
        },
        {
          busContractorId: 620,
          contractorName: '上海正欧涂料有限公司'
        },
        {
          busContractorId: 621,
          contractorName: '本溪普天防雷检测有限公司广东分公司'
        },
        {
          busContractorId: 622,
          contractorName: '广州市稳建工程检测有限公司'
        },
        {
          busContractorId: 623,
          contractorName: '广东省建筑工程监理有限公司'
        },
        {
          busContractorId: 624,
          contractorName: '广东德臻消防机电工程有限公司'
        },
        {
          busContractorId: 625,
          contractorName: '广州增电电力建设投资集团有限公司'
        },
        {
          busContractorId: 626,
          contractorName: '广州市天达建筑工程有限公司'
        },
        {
          busContractorId: 627,
          contractorName: '广东益安人防工程科技有限公司'
        },
        {
          busContractorId: 628,
          contractorName: '广州丰源园林景观有限公司'
        },
        {
          busContractorId: 629,
          contractorName: '武汉地质勘察基础工程有限公司'
        },
        {
          busContractorId: 630,
          contractorName: '广州铝安装饰工程有限公司'
        },
        {
          busContractorId: 631,
          contractorName: '东莞一建建设工程有限公司'
        },
        {
          busContractorId: 632,
          contractorName: '广州市点和幕墙装饰工程有限公司'
        },
        {
          busContractorId: 633,
          contractorName: '广州市信卓土石方工程有限公司'
        },
        {
          busContractorId: 634,
          contractorName: '广州彦丰装饰工程有限公司'
        },
        {
          busContractorId: 635,
          contractorName: '广东腾龙建设工程有限公司'
        },
        {
          busContractorId: 636,
          contractorName: '广西建工集团基础建设有限公司'
        },
        {
          busContractorId: 637,
          contractorName: '广东省天科人防防护设备工程有限公司'
        },
        {
          busContractorId: 638,
          contractorName: '广州市英加迪智能科技有限公司'
        },
        {
          busContractorId: 639,
          contractorName: '广州番禺新奥煤气有限公司'
        },
        {
          busContractorId: 640,
          contractorName: '广西华业建筑工程有限公司'
        },
        {
          busContractorId: 641,
          contractorName: '广州市雅景园林绿化工程有限公司'
        },
        {
          busContractorId: 642,
          contractorName: '广东尚锦装饰工程有限公司'
        },
        {
          busContractorId: 643,
          contractorName: '深圳市艺越建筑集团有限公司 '
        },
        {
          busContractorId: 644,
          contractorName: '深圳市世展建设有限公司'
        },
        {
          busContractorId: 645,
          contractorName: '长沙嘉华建筑工程有限公司'
        },
        {
          busContractorId: 646,
          contractorName: '和邦盛世家居股份有限公司'
        },
        {
          busContractorId: 647,
          contractorName: '深圳市万德建设集团股份有限公司'
        },
        {
          busContractorId: 648,
          contractorName: '广东荣凯建设工程有限公司'
        },
        {
          busContractorId: 649,
          contractorName: '广州市穗升白蚁防治中心'
        },
        {
          busContractorId: 650,
          contractorName: '广州信卓土石方工程有限公司'
        },
        {
          busContractorId: 651,
          contractorName: '江门市博雅清洁服务有限公司'
        },
        {
          busContractorId: 652,
          contractorName: '广州市协东升广告策划有限公司'
        },
        {
          busContractorId: 653,
          contractorName: '广州绿迪环保咨询有限公司'
        },
        {
          busContractorId: 654,
          contractorName: '广东电白二建集团有限公司'
        },
        {
          busContractorId: 655,
          contractorName: '广东省天科人防防护工程有限公司'
        },
        {
          busContractorId: 656,
          contractorName: '中山市信友装饰工程有限公司'
        },
        {
          busContractorId: 657,
          contractorName: '迅达（中国）电梯有限公司广州分公司'
        },
        {
          busContractorId: 658,
          contractorName: '山西省工业设备安装集团有限公司'
        },
        {
          busContractorId: 659,
          contractorName: '广州宇盾实业有限公司'
        },
        {
          busContractorId: 660,
          contractorName: '深圳南利装饰集团股份公司'
        },
        {
          busContractorId: 661,
          contractorName: '广东力信电建工程有限公司'
        },
        {
          busContractorId: 662,
          contractorName: '华诚博远建筑工程有限公司'
        },
        {
          busContractorId: 663,
          contractorName: '深圳市中滢园林环境建设工程有限公司'
        },
        {
          busContractorId: 664,
          contractorName: '益阳金牛幕墙有限公司'
        },
        {
          busContractorId: 665,
          contractorName: '东莞市华锋幕墙装饰工程有限公司'
        },
        {
          busContractorId: 666,
          contractorName: '深圳市鑫彩光电有限公司'
        },
        {
          busContractorId: 667,
          contractorName: '天津瑞克特机电工程有限公司'
        },
        {
          busContractorId: 668,
          contractorName: '北京江森自控有限公司'
        },
        {
          busContractorId: 669,
          contractorName: '广东扬光照明科技有限公司'
        },
        {
          busContractorId: 670,
          contractorName: '荆州哲昌体育防水工程有限公司'
        },
        {
          busContractorId: 671,
          contractorName: '石家庄建设集团有限公司'
        },
        {
          busContractorId: 672,
          contractorName: '广州市政技术开发公司'
        },
        {
          busContractorId: 673,
          contractorName: '广州盈达设备安装有限公司'
        },
        {
          busContractorId: 674,
          contractorName: '广东水科院勘测设计院'
        },
        {
          busContractorId: 675,
          contractorName: '广东营造工程管理有限公司'
        },
        {
          busContractorId: 676,
          contractorName: '中国邮政集团有限公司佛山市南海区分公司'
        },
        {
          busContractorId: 677,
          contractorName: '辽宁仁凯工程有限公司'
        },
        {
          busContractorId: 678,
          contractorName: '湖南一建园林景观有限公司'
        },
        {
          busContractorId: 679,
          contractorName: '迅达（中国）电梯有限公司'
        },
        {
          busContractorId: 680,
          contractorName: '迅达（中国）电梯有限公司佛山分公司'
        },
        {
          busContractorId: 681,
          contractorName: '广州启康发电机有限公司'
        },
        {
          busContractorId: 682,
          contractorName: '广东省广播电视网络股份有限公司佛山南海分公司'
        },
        {
          busContractorId: 683,
          contractorName: '佛山市南海燃气发展有限公司'
        },
        {
          busContractorId: 684,
          contractorName: '广东创粤建设有限公司'
        },
        {
          busContractorId: 685,
          contractorName: '广东华运人防科技工程有限公司'
        },
        {
          busContractorId: 686,
          contractorName: '广东艺洋建设有限公司'
        },
        {
          busContractorId: 687,
          contractorName: '珠海经济特区中建机电消防工程有限公司'
        },
        {
          busContractorId: 688,
          contractorName: '广东泰盈建设工程有限公司'
        },
        {
          busContractorId: 689,
          contractorName: '佛山市南海区建筑工程质量检测站'
        },
        {
          busContractorId: 690,
          contractorName: '核工业衡阳第二地质工程勘察院广州分院'
        },
        {
          busContractorId: 691,
          contractorName: '浙江中垚建设有限公司'
        },
        {
          busContractorId: 692,
          contractorName: '广东淘家建筑装饰工程有限公司'
        },
        {
          busContractorId: 693,
          contractorName: '广东齐创建设有限公司惠州分公司'
        },
        {
          busContractorId: 694,
          contractorName: '惠州市好日子装饰设计工程有限公司'
        },
        {
          busContractorId: 695,
          contractorName: '广东华通装饰工程股份有限公司'
        },
        {
          busContractorId: 696,
          contractorName: '日立电梯（中国）有限公司惠州分公司'
        },
        {
          busContractorId: 697,
          contractorName: '深圳市新达基建设有限公司'
        },
        {
          busContractorId: 698,
          contractorName: '深圳市臻景生态园林有限公司'
        },
        {
          busContractorId: 699,
          contractorName: '惠州市市政动迁建设总公司'
        },
        {
          busContractorId: 700,
          contractorName: '惠州市金卓越实业有限公司'
        },
        {
          busContractorId: 701,
          contractorName: '惠州市新科联白蚁防治有限公司'
        },
        {
          busContractorId: 702,
          contractorName: '广州彩之源实业有限公司'
        },
        {
          busContractorId: 703,
          contractorName: '惠州市气象公共安全技术支持中心'
        },
        {
          busContractorId: 704,
          contractorName: '惠州市惠城区中邮五金加工工程部'
        },
        {
          busContractorId: 705,
          contractorName: '惠州华谊电力工程有限公司'
        },
        {
          busContractorId: 706,
          contractorName: '广东省广播电视网络股份有限公司惠州分公司'
        },
        {
          busContractorId: 707,
          contractorName: '广东省惠州市质量计量监督检验所'
        },
        {
          busContractorId: 708,
          contractorName: '惠州市城市燃气发展有限公司'
        },
        {
          busContractorId: 709,
          contractorName: '浙江安华消防安全工程有限公司'
        },
        {
          busContractorId: 710,
          contractorName: '海光环境建设集团有限公司'
        },
        {
          busContractorId: 711,
          contractorName: '浙江省通信产业服务有限公司宁波市分公司'
        },
        {
          busContractorId: 712,
          contractorName: '上海乾翔门窗幕墙有限公司 '
        },
        {
          busContractorId: 713,
          contractorName: '上海地矿勘察院有限公司'
        },
        {
          busContractorId: 714,
          contractorName: '宁波益圣丰诚基础建设有限公司'
        },
        {
          busContractorId: 715,
          contractorName: '杭州市坤博岩土工程科技有限公司'
        },
        {
          busContractorId: 716,
          contractorName: '中和华丰建设有限公司'
        },
        {
          busContractorId: 717,
          contractorName: '上海同百智能门窗科技股份有限公司'
        },
        {
          busContractorId: 718,
          contractorName: '上海方立建筑装饰有限公司'
        },
        {
          busContractorId: 719,
          contractorName: '宁波挚城检测有限公司'
        },
        {
          busContractorId: 720,
          contractorName: '上海盛稷幕墙装饰工程有限公司'
        },
        {
          busContractorId: 721,
          contractorName: '浙江迈新科技股份有限公司'
        },
        {
          busContractorId: 722,
          contractorName: '浙江经童市政设施有限公司'
        },
        {
          busContractorId: 723,
          contractorName: '上海立杰建设工程有限公司'
        },
        {
          busContractorId: 724,
          contractorName: '浙江华咨结构设计事务所有限公司华咨'
        },
        {
          busContractorId: 725,
          contractorName: '深圳市粤大明智慧照明科技有限公司'
        },
        {
          busContractorId: 726,
          contractorName: '上海明唐消防工程设备有限公司'
        },
        {
          busContractorId: 727,
          contractorName: '浙江力通机电工程有限公司'
        },
        {
          busContractorId: 728,
          contractorName: '浙江中竹园艺贸易有限公司'
        },
        {
          busContractorId: 729,
          contractorName: '上海雯联机电工程有限公司'
        },
        {
          busContractorId: 730,
          contractorName: '上海京藤建设工程（集团）有限公司 '
        },
        {
          busContractorId: 731,
          contractorName: '杭州城北电气承装公司'
        },
        {
          busContractorId: 732,
          contractorName: '浙江明安电气成套设备有限公司'
        },
        {
          busContractorId: 733,
          contractorName: '浙江宁工检测科技有限公司'
        },
        {
          busContractorId: 734,
          contractorName: '特来电新能源有限公司'
        },
        {
          busContractorId: 735,
          contractorName: '上海泰固建筑工程有限公司'
        },
        {
          busContractorId: 736,
          contractorName: '浙江山力得新材料科技有限公司'
        },
        {
          busContractorId: 737,
          contractorName: '山东力诺瑞特新能源有限公司'
        },
        {
          busContractorId: 738,
          contractorName: '上海安质建设工程有限公司'
        },
        {
          busContractorId: 739,
          contractorName: '太阳雨集团有限公司'
        },
        {
          busContractorId: 740,
          contractorName: '江苏沪港装饰有限公司'
        },
        {
          busContractorId: 741,
          contractorName: '上海盛稷幕墙装饰工程有公司'
        },
        {
          busContractorId: 742,
          contractorName: '深圳市晶宫设计装饰工程有限公司'
        },
        {
          busContractorId: 743,
          contractorName: '浙江海丰建设有限公司'
        },
        {
          busContractorId: 744,
          contractorName: '宁波市中诺新能源有限公司 '
        },
        {
          busContractorId: 745,
          contractorName: '上海裕聚幕墙装饰工程有限公司'
        },
        {
          busContractorId: 746,
          contractorName: '上海园林（集团）有限公司'
        },
        {
          busContractorId: 747,
          contractorName: '浙江省华咨结构设计事务所有限公司'
        },
        {
          busContractorId: 748,
          contractorName: '上海大符消防设备有限公司'
        },
        {
          busContractorId: 749,
          contractorName: '上海城北电气承装公司'
        },
        {
          busContractorId: 750,
          contractorName: '宁波杭州湾新区广播电视站'
        },
        {
          busContractorId: 777,
          contractorName: '福建名城建工有限公司'
        },
        {
          busContractorId: 781,
          contractorName: '广东合创总承包有限公司'
        },
        {
          busContractorId: 797,
          contractorName: '河北宇安消防工程有限公司石家庄桥西区分公司'
        },
        {
          busContractorId: 798,
          contractorName: '河北新益测绘服务有限公司'
        },
        {
          busContractorId: 799,
          contractorName: '北京永达信工程造价咨询有限公司'
        },
        {
          busContractorId: 800,
          contractorName: '天津市瑞通建筑材料有限公司'
        },
        {
          busContractorId: 801,
          contractorName: '唐山曹妃甸区佳凯建筑工程有限公司'
        },
        {
          busContractorId: 802,
          contractorName: '唐山阳洋市政园林建筑工程有限公司'
        },
        {
          busContractorId: 803,
          contractorName: '唐山曹妃甸区鑫海星市政园林绿化工程有限公司'
        },
        {
          busContractorId: 804,
          contractorName: '‭河北大力岩土工程有限公司'
        },
        {
          busContractorId: 805,
          contractorName: '唐山荣源电器有限公司'
        },
        {
          busContractorId: 806,
          contractorName: '廊坊市泰信达工程项目管理有限公司'
        },
        {
          busContractorId: 807,
          contractorName: '霸州市兴盛建筑工程有限公司'
        },
        {
          busContractorId: 808,
          contractorName: '荣盛建设工程有限公司'
        },
        {
          busContractorId: 809,
          contractorName: '霸州市润达测绘有限公司'
        },
        {
          busContractorId: 810,
          contractorName: '廊坊市天虹电梯有限公司'
        },
        {
          busContractorId: 811,
          contractorName: '青岛兆龙门窗幕墙系统有限公司'
        },
        {
          busContractorId: 812,
          contractorName: '河北豫龙建工集团有限公司'
        },
        {
          busContractorId: 813,
          contractorName: '廊坊市宇恒岩土工程有限公司'
        },
        {
          busContractorId: 814,
          contractorName: '霸州市坤宇钻井工程有限公司'
        },
        {
          busContractorId: 815,
          contractorName: '霸州市胜利顺达燃气有限公司'
        },
        {
          busContractorId: 816,
          contractorName: '霸州市华旭热力有限公司'
        },
        {
          busContractorId: 818,
          contractorName: '河北科博锐程电力工程有限公司'
        },
        {
          busContractorId: 819,
          contractorName: '廊坊市城市建设勘察院'
        },
        {
          busContractorId: 820,
          contractorName: '霸州市新昊建设工程材料检测有限公司'
        },
        {
          busContractorId: 821,
          contractorName: '乐亭县鼎力基础有限公司'
        },
        {
          busContractorId: 823,
          contractorName: '大厂回族自治县建达工程咨询有限公司'
        },
        {
          busContractorId: 824,
          contractorName: '河北汇强基础工程有限公司'
        },
        {
          busContractorId: 825,
          contractorName: '北京振冲工程股份有限公司'
        },
        {
          busContractorId: 826,
          contractorName: '河北大地数字信息技术有限公司'
        },
        {
          busContractorId: 827,
          contractorName: '深圳市建筑装饰集团有限公司'
        },
        {
          busContractorId: 828,
          contractorName: '北京百林特科技发展有限公司'
        },
        {
          busContractorId: 829,
          contractorName: '北京天川科技发展有限公司'
        },
        {
          busContractorId: 830,
          contractorName: '北京鑫富园林绿化有限公司'
        },
        {
          busContractorId: 831,
          contractorName: '北京中科国宏检测技术有限公司'
        },
        {
          busContractorId: 832,
          contractorName: '京诚泰达（北京）建设有限公司'
        },
        {
          busContractorId: 833,
          contractorName: '大厂回族自治县筑诚建设工程质量检测有限公司'
        },
        {
          busContractorId: 834,
          contractorName: '河北中色华冠岩土工程有限公司'
        },
        {
          busContractorId: 835,
          contractorName: '大连旅顺建设监理有限公司'
        },
        {
          busContractorId: 836,
          contractorName: '大连铁山建筑工程有限公司'
        },
        {
          busContractorId: 837,
          contractorName: '中亿丰建设集团股份有限公司'
        },
        {
          busContractorId: 838,
          contractorName: '大连泛华建设管理咨询有限公司'
        },
        {
          busContractorId: 839,
          contractorName: '中土大地国际建筑设计有限公司'
        },
        {
          busContractorId: 840,
          contractorName: '鑫电润科电力建设工程有限公司'
        },
        {
          busContractorId: 841,
          contractorName: '廊坊市鑫宇工程检测有限公司'
        },
        {
          busContractorId: 842,
          contractorName: '北京万象伟业建筑工程有限公司'
        },
        {
          busContractorId: 843,
          contractorName: '廊坊市垚宇鑫运输有限公司'
        },
        {
          busContractorId: 844,
          contractorName: '科兴华盛河北工程项目管理咨询有限公司'
        },
        {
          busContractorId: 845,
          contractorName: '河北天璞基础工程有限公司'
        },
        {
          busContractorId: 846,
          contractorName: '北京绿博豪景园林景观工程有限公司'
        },
        {
          busContractorId: 847,
          contractorName: '四川明成日盛建筑装饰工程有限公司'
        },
        {
          busContractorId: 848,
          contractorName: '中科同昌信息科技有限公司'
        },
        {
          busContractorId: 849,
          contractorName: '广东澄轩建设工程有限公司'
        },
        {
          busContractorId: 850,
          contractorName: '山西晋盛电力工程有限公司'
        },
        {
          busContractorId: 851,
          contractorName: '太原供电公司'
        },
        {
          busContractorId: 852,
          contractorName: '太原有线电视网络技术有限公司'
        },
        {
          busContractorId: 853,
          contractorName: '江苏中南建筑产业集团有限责任公司'
        },
        {
          busContractorId: 854,
          contractorName: '山西鑫康钢结构有限公司'
        },
        {
          busContractorId: 855,
          contractorName: '山西奥凯建筑工程有限公司'
        },
        {
          busContractorId: 856,
          contractorName: '山东鑫磊建设有限公司'
        },
        {
          busContractorId: 857,
          contractorName: '太原市民防实业有限公司'
        },
        {
          busContractorId: 858,
          contractorName: '山西家豪测绘有限公司'
        },
        {
          busContractorId: 859,
          contractorName: '太原市辉海岩土工程勘察检测有限公司'
        },
        {
          busContractorId: 860,
          contractorName: '太原大森文化传播有限公司'
        },
        {
          busContractorId: 861,
          contractorName: '北京富地勘察测绘有限公司太原分公司'
        },
        {
          busContractorId: 862,
          contractorName: '山西安然电子设计有限公司'
        },
        {
          busContractorId: 863,
          contractorName: '山东军辉建设集团有限公司'
        },
        {
          busContractorId: 864,
          contractorName: '濮阳市华通建设有限公司'
        },
        {
          busContractorId: 865,
          contractorName: '天普新能源科技有限公司'
        },
        {
          busContractorId: 866,
          contractorName: '北京晨洲装饰工程设计有限公司'
        },
        {
          busContractorId: 867,
          contractorName: '盛世正华（北京）广告有限公司'
        },
        {
          busContractorId: 868,
          contractorName: '日立电梯（山西）有限公司'
        },
        {
          busContractorId: 869,
          contractorName: '山西省煤炭建设监理有限公司'
        },
        {
          busContractorId: 870,
          contractorName: '山西忠众运输有限公司'
        },
        {
          busContractorId: 871,
          contractorName: '太原市第一建筑工程集团有限公司'
        },
        {
          busContractorId: 872,
          contractorName: '山西春晖勘察设计检测研究院有限公司'
        },
        {
          busContractorId: 873,
          contractorName: '中岩辉海有限公司'
        },
        {
          busContractorId: 891,
          contractorName: '廊坊越兴防护设备有限公司'
        },
        {
          busContractorId: 892,
          contractorName: ' 北京绿胜天建设工程有限公司'
        },
        {
          busContractorId: 893,
          contractorName: '冀北中原岩土工程有限公司'
        },
        {
          busContractorId: 912,
          contractorName: '廊坊市朗汇机电设备有限公司'
        },
        {
          busContractorId: 923,
          contractorName: '上海勘察设计研究院（集团）有限公司'
        },
        {
          busContractorId: 924,
          contractorName: '昆山市建峰市政工程有限公司'
        },
        {
          busContractorId: 925,
          contractorName: '苏州铭能电力建设工程有限公司'
        },
        {
          busContractorId: 926,
          contractorName: '太仓市金驰市政园林工程有限公司'
        },
        {
          busContractorId: 927,
          contractorName: '江苏湖滨园林建设有限公司'
        },
        {
          busContractorId: 928,
          contractorName: '上海海志建设工程有限公司'
        },
        {
          busContractorId: 929,
          contractorName: '太仓市铭程工程质量检测有限公司'
        },
        {
          busContractorId: 930,
          contractorName: '苏州凯达市政景观建设有限公司'
        },
        {
          busContractorId: 931,
          contractorName: '上海高诚智能科技有限公司'
        },
        {
          busContractorId: 932,
          contractorName: '上海昀升送变电工程有限公司'
        },
        {
          busContractorId: 933,
          contractorName: '太仓市给排水安装工程有限公司'
        },
        {
          busContractorId: 934,
          contractorName: '常州市帘享遮阳科技有限公司'
        },
        {
          busContractorId: 935,
          contractorName: '上海沪湘建筑装饰工程有限公司'
        },
        {
          busContractorId: 936,
          contractorName: '苏州顺创管道工程有限公司'
        },
        {
          busContractorId: 937,
          contractorName: '中国电信股份有限公司太仓分公司'
        },
        {
          busContractorId: 938,
          contractorName: '太仓市广电网络有限公司'
        },
        {
          busContractorId: 939,
          contractorName: '江苏统一安装集团有限公司'
        },
        {
          busContractorId: 940,
          contractorName: '上海安品智能科技有限公司'
        },
        {
          busContractorId: 941,
          contractorName: '惠州市神伟白蚁防治有限公司博罗分公司'
        },
        {
          busContractorId: 942,
          contractorName: '山西冶金岩土工程勘察有限公司'
        },
        {
          busContractorId: 943,
          contractorName: '惠州市鑫三合建筑工程有限公司'
        },
        {
          busContractorId: 944,
          contractorName: '广东省七0三地质工程公司'
        },
        {
          busContractorId: 946,
          contractorName: '深圳市沣港华消防实业发展有限公司惠州分公司'
        },
        {
          busContractorId: 948,
          contractorName: '深圳洪涛集团股份有限公司'
        },
        {
          busContractorId: 949,
          contractorName: '宝德（天津）安防科技股份有限公司'
        },
        {
          busContractorId: 950,
          contractorName: '惠州市建设集团建筑工程有限公司'
        },
        {
          busContractorId: 951,
          contractorName: '天津信兴检测技术有限公司'
        },
        {
          busContractorId: 952,
          contractorName: '天津市塘沽海洋高新技术开发区工程监理有限公司'
        },
        {
          busContractorId: 953,
          contractorName: '北京筑品艺装建筑工程有限公司天津分公司'
        },
        {
          busContractorId: 954,
          contractorName: '惠州市惠城区建设工程检测中心'
        },
        {
          busContractorId: 955,
          contractorName: '惠州市燃气发展有限公司'
        },
        {
          busContractorId: 956,
          contractorName: '惠州市真全交通设施工程有限公司'
        },
        {
          busContractorId: 957,
          contractorName: '汕头市粤东工程勘察院惠州分院'
        },
        {
          busContractorId: 958,
          contractorName: '广州威能机电有限公司'
        },
        {
          busContractorId: 959,
          contractorName: '广东鸿烨实业有限公司'
        },
        {
          busContractorId: 960,
          contractorName: '惠州市建设工程质量检测中心'
        },
        {
          busContractorId: 961,
          contractorName: '福建省八方建筑工程有限公司'
        },
        {
          busContractorId: 966,
          contractorName: '惠州市新邦水电工程有限公司'
        },
        {
          busContractorId: 967,
          contractorName: '山西安然电子消防设计有限公司'
        },
        {
          busContractorId: 968,
          contractorName: '山西太水市政工程有限公司'
        },
        {
          busContractorId: 969,
          contractorName: '康力电梯集团股份有限公司'
        },
        {
          busContractorId: 970,
          contractorName: '辽宁盛世天都人防设备制造有限公司'
        },
        {
          busContractorId: 971,
          contractorName: '大连万衡检测有限公司'
        },
        {
          busContractorId: 972,
          contractorName: '东北岩土工程勘察总公司'
        },
        {
          busContractorId: 973,
          contractorName: '上海一建建筑装饰有限公司'
        },
        {
          busContractorId: 974,
          contractorName: '大连中晟市政工程有限公司'
        },
        {
          busContractorId: 975,
          contractorName: '浙江中博地质有限公司'
        },
        {
          busContractorId: 976,
          contractorName: '广东珠江总承包有限公司'
        },
        {
          busContractorId: 977,
          contractorName: '合生锦城房地产有限公司'
        },
        {
          busContractorId: 978,
          contractorName: '浙江省岩土基础公司'
        },
        {
          busContractorId: 988,
          contractorName: '宁波龙元盛宏生态建设工程有限公司'
        },
        {
          busContractorId: 989,
          contractorName: '北京新咨元工程咨询有限公司'
        },
        {
          busContractorId: 990,
          contractorName: '北京博睿丰工程咨询有限公司第一分公司'
        },
        {
          busContractorId: 991,
          contractorName: '惠州市辉鑫发展有限公司'
        },
        {
          busContractorId: 992,
          contractorName: '惠州市伟华有害生物防治有限公司'
        },
        {
          busContractorId: 993,
          contractorName: '天津市利华消防工程有限公司'
        },
        {
          busContractorId: 994,
          contractorName: '北京德茂润宏装饰工程有限公司'
        },
        {
          busContractorId: 1009,
          contractorName: '东莞市爱奥尼装饰工程有限公司'
        },
        {
          busContractorId: 1010,
          contractorName: '深圳市湛艺建设集团有限公司'
        },
        {
          busContractorId: 1011,
          contractorName: ' 广东鹏德建设工程有限公司'
        },
        {
          busContractorId: 1017,
          contractorName: '上海鸿栋幕墙装饰有限公司'
        },
        {
          busContractorId: 1020,
          contractorName: '中和华丰建设有限责任公司'
        },
        {
          busContractorId: 1021,
          contractorName: '中建二局'
        },
        {
          busContractorId: 1023,
          contractorName: '宁波擎宇建设有限公司'
        },
        {
          busContractorId: 1024,
          contractorName: '深圳市骏基建设工程有限公司'
        },
        {
          busContractorId: 1025,
          contractorName: '广东腾锋电力建设有限公司'
        },
        {
          busContractorId: 1026,
          contractorName: '中山市华茂机电工程有限公司'
        },
        {
          busContractorId: 1027,
          contractorName: '惠州港兴实业有限公司'
        },
        {
          busContractorId: 1028,
          contractorName: '江苏创盛建设工程有限公司'
        },
        {
          busContractorId: 1029,
          contractorName: '太仓市创新建筑技术研究所有限公司'
        },
        {
          busContractorId: 1030,
          contractorName: '上海欣海园林工程有限公司'
        },
        {
          busContractorId: 1031,
          contractorName: '中长建设工程有限公司'
        },
        {
          busContractorId: 1032,
          contractorName: '宁波中允园林建设有限公司'
        },
        {
          busContractorId: 1033,
          contractorName: '江门地质工程勘察院'
        },
        {
          busContractorId: 1034,
          contractorName: '上海柘元建筑劳务有限公司'
        },
        {
          busContractorId: 1035,
          contractorName: '华诚博远工程咨询有限公司'
        },
        {
          busContractorId: 1036,
          contractorName: '深圳市鸿建建设有限公司'
        },
        {
          busContractorId: 1037,
          contractorName: '上海旭博建筑装饰工程有限公司'
        },
        {
          busContractorId: 1047,
          contractorName: '天津程旭建筑工程有限公司'
        },
        {
          busContractorId: 1048,
          contractorName: '天津朗源电力工程有限公司'
        },
        {
          busContractorId: 1049,
          contractorName: '天津市盛隆通达电力工程有限公司'
        },
        {
          busContractorId: 1050,
          contractorName: '天津市广邮金属制品有限公司'
        },
        {
          busContractorId: 1053,
          contractorName: '广东精装壹线装饰工程有限公司'
        },
        {
          busContractorId: 1056,
          contractorName: '广东云筑建筑劳务有限公司'
        },
        {
          busContractorId: 1057,
          contractorName: '上海中深建筑装饰工程有限公司'
        },
        {
          busContractorId: 1058,
          contractorName: '安徽三乐建设工程有限公司'
        },
        {
          busContractorId: 1060,
          contractorName: '大熊面馆'
        },
        {
          busContractorId: 1071,
          contractorName: '劳务承建商666'
        },
        {
          busContractorId: 1072,
          contractorName: '建材公司'
        },
        {
          busContractorId: 1321,
          contractorName: '世家承建商'
        },
        {
          busContractorId: 1322,
          contractorName: '世家监理单位'
        },
        {
          busContractorId: 1324,
          contractorName: '施工单位'
        },
        {
          busContractorId: 1325,
          contractorName: 'sp承建商（施工单位）'
        },
        {
          busContractorId: 1326,
          contractorName: 'sp承建商（监理单位）'
        },
        {
          busContractorId: 1327,
          contractorName: '监理单位（1）'
        },
        {
          busContractorId: 1328,
          contractorName: '世家建设单位'
        },
        {
          busContractorId: 1348,
          contractorName: '北京天盛佳境环境监测评价有限公司'
        },
        {
          busContractorId: 1349,
          contractorName: '履约考核承建商'
        },
        {
          busContractorId: 1350,
          contractorName: '履约考核承建商2'
        },
        {
          busContractorId: 1351,
          contractorName: '1215上线测试承建商'
        },
        {
          busContractorId: 1359,
          contractorName: '菜包承建商'
        },
        {
          busContractorId: 1360,
          contractorName: '实测实量承建商'
        },
        {
          busContractorId: 1361,
          contractorName: 'L履约监理单位'
        },
        {
          busContractorId: 1362,
          contractorName: '曹妃甸劳务公司'
        },
        {
          busContractorId: 1365,
          contractorName: '全球通劳务公司'
        },
        {
          busContractorId: 1366,
          contractorName: '上海世家建筑公司'
        },
        {
          busContractorId: 1374,
          contractorName: '电子签章承建商'
        },
        {
          busContractorId: 1376,
          contractorName: '广州恒捷贸易有限公司'
        },
        {
          busContractorId: 1379,
          contractorName: '承建商测试-zgw'
        },
        {
          busContractorId: 1380,
          contractorName: '天力测试'
        },
        {
          busContractorId: 1381,
          contractorName: '电子签章测试'
        },
        {
          busContractorId: 1382,
          contractorName: '承建商电子签章测试-zgw'
        },
        {
          busContractorId: 1384,
          contractorName: '深圳金泰建筑'
        },
        {
          busContractorId: 1386,
          contractorName: '1111111111111'
        },
        {
          busContractorId: 1395,
          contractorName: '123'
        },
        {
          busContractorId: 1396,
          contractorName: '测试实测实量施工单位'
        },
        {
          busContractorId: 1398,
          contractorName: '测试承建商001'
        },
        {
          busContractorId: 1400,
          contractorName: '北京电子认证有限公司'
        },
        {
          busContractorId: 1401,
          contractorName: '广州汇兴混凝土有限公司'
        },
        {
          busContractorId: 1402,
          contractorName: '上海储都建筑劳务有限公司'
        },
        {
          busContractorId: 1403,
          contractorName: 'test1'
        },
        {
          busContractorId: 1404,
          contractorName: 'test2'
        },
        {
          busContractorId: 1405,
          contractorName: 'test3'
        },
        {
          busContractorId: 1409,
          contractorName: '阳光建筑'
        },
        {
          busContractorId: 1415,
          contractorName: '测试材料验收监理单位'
        },
        {
          busContractorId: 1416,
          contractorName: '上海连凯建材有限责任公司'
        },
        {
          busContractorId: 1417,
          contractorName: '三方巡检专用巡检承建商'
        },
        {
          busContractorId: 1418,
          contractorName: '安徽第一监理公司'
        },
        {
          busContractorId: 1422,
          contractorName: '测试第三方巡检单位测试'
        },
        {
          busContractorId: 1423,
          contractorName: 'zctest4'
        },
        {
          busContractorId: 1536,
          contractorName: '广东鑫和基础工程有限公司'
        },
        {
          busContractorId: 1537,
          contractorName: '测试报表功能承建商'
        },
        {
          busContractorId: 1544,
          contractorName: 'HNCJS01'
        },
        {
          busContractorId: 1545,
          contractorName: '华南监理单位'
        },
        {
          busContractorId: 1548,
          contractorName: '上海世家建设'
        },
        {
          busContractorId: 1549,
          contractorName: '迁移项目'
        },
        {
          busContractorId: 1559,
          contractorName: '演示帐号专用'
        },
        {
          busContractorId: 1561,
          contractorName: '测试承建商2'
        },
        {
          busContractorId: 1568,
          contractorName: 'ZC材料供应商'
        },
        {
          busContractorId: 1584,
          contractorName: '北京风力有限公司'
        },
        {
          busContractorId: 1604,
          contractorName: '润城集团'
        },
        {
          busContractorId: 1607,
          contractorName: '广东南光建筑工程有限公司'
        },
        {
          busContractorId: 1608,
          contractorName: '广东扬迈建设有限公司'
        },
        {
          busContractorId: 1609,
          contractorName: '大熊建筑公司'
        },
        {
          busContractorId: 1610,
          contractorName: '大熊监理公司'
        },
        {
          busContractorId: 1611,
          contractorName: '大熊材料进货商'
        },
        {
          busContractorId: 1612,
          contractorName: '大熊001'
        },
        {
          busContractorId: 1613,
          contractorName: '大熊002'
        },
        {
          busContractorId: 1614,
          contractorName: '大熊003'
        },
        {
          busContractorId: 1615,
          contractorName: '大熊004'
        },
        {
          busContractorId: 1616,
          contractorName: '大熊005'
        },
        {
          busContractorId: 1617,
          contractorName: '大熊006'
        },
        {
          busContractorId: 1618,
          contractorName: '大熊007'
        },
        {
          busContractorId: 1619,
          contractorName: '熊厨001'
        },
        {
          busContractorId: 1620,
          contractorName: '熊厨002'
        },
        {
          busContractorId: 1621,
          contractorName: '熊厨003'
        },
        {
          busContractorId: 1622,
          contractorName: '测试施工方01'
        },
        {
          busContractorId: 1706,
          contractorName: '测试认证承建商002'
        },
        {
          busContractorId: 1707,
          contractorName: '润城集团001'
        },
        {
          busContractorId: 1708,
          contractorName: '测试 测试'
        },
        {
          busContractorId: 1711,
          contractorName: '上海龙永环保科技有限公司'
        },
        {
          busContractorId: 1712,
          contractorName: '广东合创工程总承包有限公司北京分公司'
        },
        {
          busContractorId: 1713,
          contractorName: '测试供应商全称3'
        },
        {
          busContractorId: 1715,
          contractorName: '小王的承建商'
        },
        {
          busContractorId: 1716,
          contractorName: '小王的承建商2'
        },
        {
          busContractorId: 1717,
          contractorName: ' 法大大'
        }
      ],
      visible: false
    }
  },
  watch: {
    isAllowModify: {
      handler(val) {
        this.form.isAllowModify = val
      },
      immediate: true
    }
  },
  mounted() {
    this.getContractorData()
    this.getInfo()
  },
  methods: {
    getContractorData() {
      // busProjectParaContractorData().then(d => {
      //   this.contractorData = d.data
      // })
    },
    getInfo() {
      // busProjectParaInspection({ busProjectParaId: this.busProjectParaId }).then(d => {
      //   this.form.isAllowModify = d.data.isAllowModify
      //   this.busProjectId = d.data.busProjectId
      //   this.tableData = d.data.busCheckItemList
      //   this.tableDataClone = deepClone(d.data.busCheckItemList)
      //   console.log(d.data.busCheckItemList)
      //   console.log(this.tableDataClone)
      // })
    },
    select(selection, row) {
      // 判断当前行是否选中
      // 不需要判断 id, 因为引用地址相同
      const selected = selection.some(item => item === row)
      // row.selected = selected
      // 处理所有子级
      this.selectChildren(row, selected)
      this.selectParent(row, selected)
    },
    selectAll(selection) {
      const isSelect = this.tableData.some(item => selection.includes(item))
      if (isSelect) {
        selection.forEach(item => {
          this.selectChildren(item, isSelect)
        })
      } else {
        this.tableData.forEach(item => {
          this.selectChildren(item, isSelect)
        })
      }
    },
    selectChildren(row, selected) {
      if (row.children && Array.isArray(row.children)) {
        row.children.forEach(item => {
          this.toggleSelection(item, selected)
          this.selectChildren(item, selected)
        })
      }
    },
    toggleSelection(row, select) {
      // row.selected = select
      row && this.$nextTick(() => {
        this.$refs.table && this.$refs.table.toggleRowSelection(row, select)
      })
    },
    selectParent(row) {
      const parentId = row.parentId
      if (!parentId) return
      const parent_row = this.findPid(this.tableData, parentId)
      const selection = this.$refs.table.selection // 从el-table 拿到对象

      const selected = parent_row.children.every(item => {
        return selection.includes(item)
      })

      this.toggleSelection(parent_row, selected)
      if (parent_row.parentId) {
        this.selectParent(parent_row)
      }
    },
    findPid(list, pid) {
      let result = null
      function deep(list, pid) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].busCheckItemId === pid) {
            result = list[i]
          }
          if (list[i].children) {
            deep(list[i].children, pid)
          }
        }
      }
      deep(list, pid)

      return result
    },
    handleSelectionChange() {},
    click() {},
    editUser(name = '', limit = 0, organNature = [0, 1]) {
      const selection = this.$refs.table.selection
      if (selection.length === 0) {
        this.$message.error('请选择检查项！')
        return false
      }
      if (name === '') return false
      this.$selectUser({
        searchTip: '请输入用户姓名',
        title: '选择成员',
        value: [], // 用来回显使用
        limit: limit,
        type: 2,
        authType: 0,
        userCountFlag: 0,
        // organNature,
        // projectId: this.busProjectId,
        changeHandler: (result, list) => {
          console.log(result, list)
          const value = list.map(item => {
            return {
              // busUserId: item.busUserId,
              busUserId: item.id,
              // userName: item.userName
              userName: item.name
            }
          })

          if (value.length === 0) {
            if (limit === 1) {
              this.setUser(name, null)
            } else {
              this.setUser(name, [])
            }
          } else {
            if (limit === 1) {
              this.setUser(name, value[0])
            } else {
              this.setUser(name, value)
            }
          }
        }
      })
    },
    setUser(name, value, list) {
      if (!list) {
        list = this.$refs.table.selection
      }
      list.forEach(item => {
        if (item.itemDirectoryFlag == 1 || (item.children && item.children.length > 0)) { // 判断是否文件夹
          // return false
        } else {
          // item[name] = deepClone(value)
          if (typeof value !== 'object') {
            item[name] = value // 基础类型直接赋值
          } else {
            item[name] = deepClone(value)
          }
        }
        if (item.children && item.children.length > 0) {
          this.setUser(name, value, item.children)
        }
      })
    },
    submit() {
      // 遍历拿到子节点
      const list = []
      function deep(listItem) {
        listItem.forEach(item => {
          if (item.itemDirectoryFlag == 1 || (item.children && item.children.length > 0)) {
            //
            item.children && deep(item.children)
          } else {
            list.push(item)
          }
        })
      }
      deep(deepClone(this.tableData))
      const busCheckItemList = list.map(item => {
        return {
          busCheckItemId: item.busCheckItemId,
          busContractor: item.busContractor ? item.busContractor.busContractorId : null,
          rectificationUser: item.rectificationUser ? item.rectificationUser.busUserId : null,
          reinspectionUser: item.reinspectionUser ? item.reinspectionUser.busUserId : null,
          copyUser: item.copyUser ? item.copyUser.map(item => item.busUserId) : []
        }
      })
      console.log(busCheckItemList, 'busCheckItemList')
      console.log(this.form.isAllowModify, 'this.form.isAllowModify')
      // const load = this.$load()
      // busProjectParaInspectionSubmit({
      //   isAllowModify: this.form.isAllowModify,
      //   busProjectParaId: this.busProjectParaId,
      //   busProjectId: this.busProjectId,
      //   busCheckItemList
      // }).then(d => {
      //   load.close()
      //   this.$message.success('提交成功！')
      //   this.getInfo() // 初始化数据
      // }).catch(e => {
      //   load.close()
      //   this.$errorHandle(e)
      // })
    },
    setCompany() {
      const selection = this.$refs.table.selection
      if (selection.length === 0) {
        this.$message.error('请选择检查项！')
        return false
      }
      this.visible = true
    },
    addCompany() {
      const value = this.contractorData.filter(item => item.busContractorId === this.companyForm.busContractor)
      if (value.length > 0) {
        this.setUser('busContractor', value[0])
      } else {
        this.setUser('busContractor', null)
      }
      this.visible = false
    },
    closeTag(row, key, index = 0) {
      if (Array.isArray(row[key])) {
        row[key].splice(index, 1)
      } else {
        row[key] = null
      }
    },
    check() {
      return JSON.stringify(this.tableData) === JSON.stringify(this.tableDataClone)
    }
  }
}
</script>

<style scoped>

</style>
