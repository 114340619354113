<template>
  <transition name="gp-zoom-in-center">
    <div v-if="visible" class="create-cantainer">
      <div class="left-con">
        <div class="top">
          <el-input
            v-model="searchName"
            class="search"
            placeholder="搜索"
            prefix-icon="el-icon-search"
            clearable
            @input="searchList"
          />
        </div>
        <div class="person-list">
          <el-scrollbar style="height: 100%">
            <div class="peroson-con">
              <div v-for="(item,index) in fileterList " :key="index" class="person-item">
                <div
                  class="check"
                  :class="{ active: checkedList.includes(item)}"
                  @click="checkPerson(item)"
                />
                <div class="author">
                  <img src="@/assets/im/author.png" alt="">
                </div>
                <div class="name row-one">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>

      <div class="right-con">
        <div class="top">
          <div class="title">发起群聊</div>
          <div class="tip">已选择{{ checkedList.length || 0 }}个联系人</div>
        </div>
        <div class="main">
          <el-scrollbar style="height: 100%">
            <div class="check-main">
              <div v-if="checkedList.length" class="check-list clearfix">
                <div v-for="(item,index) in checkedList" :key="index" class="check-item">
                  <div class="author">
                    <div class="img">
                      <img src="@/assets/im/author.png" alt="">
                      <div class="close" @click="delPerosn(index)" />
                    </div>
                    <div class="name row-one">{{ item.name || '-' }}</div>
                  </div>
                </div>
              </div>
              <div v-else style="text-align: center;height: 390px;line-height:390px;color: #999;font-size: 14px">
                请选择联系人
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="bottom" style="text-align: center">
          <el-button @click="close">取消</el-button>
          <el-button type="primary">创建</el-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CreateCantainer',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchName: '',
      list: [],
      fileterList: [],
      checkedList: [] // 选中联系人组
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /**
     * 获取联系人列表
     */
    getList() {
      const list = [
        { id: 1, name: '朝阳1' },
        { id: 2, name: '朝阳2' },
        { id: 3, name: '朝阳3' },
        { id: 4, name: '朝阳4' }
      ]
      this.list = list
      this.fileterList = list
    },
    /**
     * 选中事件
     * @params person 选中联系人
     */
    checkPerson(person) {
      // 查询是否已经勾选过
      const index = this.checkedList.findIndex(item => item.id == person.id)
      if (index !== -1) {
        // 存在,则取消勾选
        this.checkedList.splice(index, 1)
      } else {
        this.checkedList.push(person)
      }
    },
    /**
     * 删除选中的某个联系人
     */
    delPerosn(index) {
      this.checkedList.splice(index, 1)
    },
    /**
     * 查询搜索联系人
     */
    searchList() {
      if (!this.searchName) {
        this.fileterList = this.list
        return
      }
      const list = this.list.filter(item => item.name.includes(this.searchName))
      this.fileterList = list
    },
    /**
     * 关闭弹窗
     */
    close() {
      this.$emit('update:visible', false)
      this.checkedList = []
    }
  }
}
</script>

<style scoped lang="scss">
.create-cantainer {
  z-index: 99;
  width: 600px;
  height: 516px;
  border-radius: 6px;
  overflow: hidden;
  background: #eff1f4;
  box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.30);
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  margin-top: -258px;
  display: flex;
  .left-con {
    width: 280px;
    height: 100%;
    background: #fff;
    .top {
      text-align: center;
      padding: 16px 0;
      .search {
        width: 256px;
      }
    }
    .person-list {
      width: 100%;
      height: 448px;
      .peroson-con {
        width: 100%;
        height: 3000px;
        padding: 0 12px;
        .person-item {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          .check {
            width: 20px;
            height: 20px;
            margin-right: 14px;
            cursor: pointer;
            background: url("~@/assets/im/check.png") center no-repeat;
            background-size: 100% 100%;
          }
          .active {
            background: url("~@/assets/im/check-active.png") center no-repeat;
            background-size: 100% 100%;
          }
          .author {
            border-radius: 6px;
            overflow: hidden;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          .name {
            max-width: 150px;
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            color: #31415f;
            line-height: 20px;
          }
        }
      }
    }
  }

  .right-con {
    width: 320px;
    height: 100%;
    padding: 23px 20px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
      .title {
        font-size: 16px;
        font-weight: 600;
        text-align: right;
        color: #31415f;
        line-height: 22px;
      }
      .tip {
        font-size: 12px;
        font-weight: 400;
        text-align: right;
        color: #909db6;
        line-height: 17px;
      }
    }
    .main {
      width: 100%;
      height: 390px;
      margin-bottom: 10px;
      .check-main {
        width: 100%;
        height: 3000px;
        padding: 10px 20px;
        .check-list {
          width: 100%;
          .check-item {
            float: left;
            width: 33.3%;
            margin-bottom: 20px;
            text-align: center;
            .author {
              width: 100%;
              .img {
                display: inline-block;
                width: 40px;
                height: 40px;
                margin-bottom: 8px;
                position: relative;
                > img {
                  width: 100%;
                  height: 100%;
                  user-select: none;
                }
                .close {
                  cursor: pointer;
                  width: 14px;
                  height: 14px;
                  background: url("~@/assets/im/close.png") center no-repeat;
                  background-size: 100% 100%;
                  position: absolute;
                  right: -7px;
                  top: -7px;
                  z-index: 99;
                }
              }
              .name {
                width: 100%;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                color: #31415f;
                line-height: 17px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
::v-deep .el-scrollbar__wrap{
  overflow-x: hidden;
}
::v-deep .el-input__inner {
  border: none;
  width: 100%;
  height: 36px;
  background: #f6f7fa;
  border-radius: 6px;
  &::-webkit-input-placeholder{
    color:#919EB6;
    font-size:12px;
  }
}
::v-deep .el-input__prefix {
  .el-input__icon {
    color:#919EB6;
    font-size:12px;
    //margin-top: 2px;
    &:after {
      vertical-align: bottom;
    }
  }
}
</style>
