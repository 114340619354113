<template>
  <div>
    <div class="avatar-wrap">
      <div class="b-avatar">
        <el-dropdown ref="personinfos" trigger="click">
          <span class="gp-dropdown-link">
            <el-image
              style="width: 42px; height: 42px;border-radius: 50%"
              :src="avatar"
              fit="fit"
            >
              <div slot="error" class="image-slot">
                <div class="peoplenodata" />
              </div>
            </el-image>
          </span>
          <el-dropdown-menu slot="dropdown">
            <div class="dropavatar">
              <div class="personinfo">
                <div class="heades">
                  <el-image
                    style="width: 100%; height: 100%;border-radius: 3px"
                    :src="avatar"
                    fit="fit"
                  >
                    <div slot="error" class="image-slot">
                      <!--                      <i class="el-icon-user-solid" />-->
                      <div class="peoplenodata" />
                    </div>
                  </el-image>
                </div>
                <div class="infos">
                  <div class="infoname rows">{{ name || '-' }}</div>
                  <div class="infoduty rows">{{ userPosition || '暂无职务' }}</div>
                </div>
              </div>
              <div class="lines" />
              <div class="flex-between">
                <div class="loginout">
                  <div class="officonKey" />
                  <div class="lout" @click="editCode">修改密码</div>
                </div>
                <div class="loginout">
                  <div class="officon" />
                  <div class="lout" @click="loginout">退出登录</div>
                </div>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="slide-wrap">
      <div class="m_side_line" />
      <div
        v-for="(item,index) in routes_role"
        :key="index"
        class="slide-item"
        :class="{active: activeMenu(item.path)}"
        @click="$router.push(item.path)"
      >
        <el-badge v-if="item.path == '/im'" :class="(totalUnread > 99 ? 'more99' : '')||(totalUnread >0 ?'':'none')" :value="totalUnread" :max="99" class="item">
          <div class="icon">
            <svg-icon :icon-class="activeMenu(item.path) ? item.iconActive :item.icon" />
          </div>
        </el-badge>
        <template v-else>
          <div class="icon">
            <svg-icon :icon-class="activeMenu(item.path) ? item.iconActive :item.icon" />
          </div>
        </template>
        <div class="title">{{ item.title }}</div>
      </div>
    </div>

    <div class="setting-wrap">
      <div class="settings">
        <!--        切换组织-->
        <el-dropdown ref="dropdown" trigger="click" placement="top-end" @visible-change="getTeam">
          <!--          <span class="gp-dropdown-link">-->
          <!--            <div class="setting" />-->
          <!--          </span>-->
          <div class="logo">
            <!--        <el-image-->
            <!--          style="width: 100%; height: 100%;text-align: center"-->
            <!--          :src="enterpriseLogoImg"-->
            <!--          fit="fit"-->
            <!--        />-->
            <!--        <el-avatar-->
            <!--          shape="square"-->
            <!--          :size="26"-->
            <!--          :src="enterpriseLogoImg"-->
            <!--          icon="el-icon-user-solid"-->
            <!--        />-->
            <el-image
              style="width: 100%; height: 100%;border-radius: 50%"
              :src="enterpriseLogoImg"
              fit="fit"
            >
              <div slot="error" class="image-slot">
                <!--                            <i class="el-icon-picture-outline" />-->
                <div class="compoangnodata compoangnodata1" />
              </div>
            </el-image>
          </div>
          <el-dropdown-menu slot="dropdown" v-loading="choseloading" style="background-color: #F5F7FA;box-shadow: 0px 0px 6px 0px rgba(49,65,95,0.2);">
            <div class="chosebox">
              <!--              <div class="choseenterprise">您当前拥有以下企业</div>-->
              <!--              <div class="changeitems">-->
              <!--                <div class="choseicon" />-->
              <!--                <div class="chosetitle">点击切换企业</div>-->
              <!--              </div>-->
              <el-scrollbar style="height: 85%">
                <div class="enterprise-wrap">
                  <div v-for="(item,index) in unenterpriseList" :key="index" class="enterprise-item" :class="{ccontent_checked:enterpriseUuid==item.enterpriseUuid}" @click="showTips(item)">
                    <div class="cleft">
                      <div class="cimg">
                        <el-badge :class="(item.unRead > 99 ? 'more99' : '')||(item.unRead >0 ?'':'none')" :value="item.unRead" :max="99" class="item">
                          <el-image
                            style="width: 30px; height: 30px;border-radius: 8px"
                            :src="item.enterpriseIcon"
                            fit="fit"
                          >
                            <div slot="error" class="image-slot">
                              <div class="compoangnodata" />
                            </div>
                          </el-image>
                        </el-badge>
                      </div>
                      <div>
                        <div style="display: flex">
                          <div class="companyname rows" :class="{companyname1:item.enterpriseAuthenticationCode != 1155524,ccontent_checked_color:enterpriseUuid==item.enterpriseUuid}">
                            <!--                            {{ item.enterpriseName }}-->
                            <UmToolTip :content="item.enterpriseName" class="row_1" />
                          </div>
                          <div v-if="item.enterpriseAuthenticationCode == 1155524" class="f-auth">
                            <div class="icon-auth" />
                            <!--                            <span>已认证</span>-->
                          </div>
                        </div>
                        <div class="positions rows">
                          <span v-if="item.joinTypeCode == 1" class="m_join_type_yz">待验证</span>
                          <span v-if="item.joinTypeCode == 2" class="m_join_type_sh">待审核</span>
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="righticon" />-->
                  </div>
                  <div v-for="item in enterpriseList" :key="item.enterpriseUuid" class="enterprise-item" :class="{ccontent_checked:enterpriseUuid==item.enterpriseUuid}" @click="choseitem(item)">
                    <div class="cleft">
                      <div class="cimg">
                        <el-badge :class="(item.unRead > 99 ? 'more99' : '')||(item.unRead >0 ?'':'none')" :value="item.unRead" :max="99" class="item">
                          <el-image
                            style="width: 30px; height: 30px;border-radius: 8px"
                            :src="item.enterpriseIcon"
                            fit="fit"
                          >
                            <div slot="error" class="image-slot">
                              <div class="compoangnodata" />
                            </div>
                          </el-image>
                        </el-badge>
                      </div>
                      <div>
                        <div style="display: flex">
                          <div class="companyname rows" :class="{companyname1:item.enterpriseAuthenticationCode != 1155524,ccontent_checked_color:enterpriseUuid==item.enterpriseUuid}">
                            <!--                            {{ item.enterpriseName }}-->
                            <UmToolTip :content="item.enterpriseName" class="row_1" />
                          </div>
                          <div v-if="item.enterpriseAuthenticationCode == 1155524" class="f-auth">
                            <div class="icon-auth" />
                            <!--                            <span>已认证</span>-->
                          </div>
                        </div>
                        <div class="positions rows">{{ item.userPosition || '-' }}</div>
                      </div>
                      <div v-if="item.defaultFlag" :class="{enterprise_item_mr:item.defaultFlag}">默认</div>
                    </div>
                    <!--                    <div class="righticon" />-->
                  </div>
                </div>
              </el-scrollbar>
              <el-button class="enterprise-item-footer" type="text" @click="setMr">设置默认企业</el-button>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <JoinTeamDialog :choseitem="choseitems" :invitationaction.sync="showjointem" @changejointeamdialog="changejointeamdialog" />
    <!--  请验证手机好-->
    <el-dialog title="请验证手机号" width="440px" :visible.sync="dialogVisible" @close="reset">
      <el-form ref="phoneForm" :model="phoneForm" :rules="phoneForm">
        <el-form-item prop="userMobile">
          <el-input v-model="phoneForm.userMobile" placeholder="请输输入手机号" />
        </el-form-item>
        <el-form-item prop="smsCode">
          <el-input
            ref="smsCode"
            v-model="phoneForm.smsCode"
            class="smInput"
            type="text"
            :clearable="false"
            placeholder="请输入短信验证码"
            name="smsCode"
            tabindex="2"
            autocomplete="off"
            maxlength="6"
            @keyup.enter.native="handleLogin"
          />
          <div class="smBox">
            <span>|</span>
            <el-button
              type="text"
              :disabled="flagd"
              style="width: 75px"
              @click="getCaptchad"
            >
              {{ contentd }}
            </el-button>
          </div>
        </el-form-item>
        <div class="shadow">
          <el-button
            :loading="loading"
            type="primary"
            style="width:100%;margin-top:10px;"
            :disabled="disabled"
            @click.native.prevent="handleLogin"
          >
            下一步
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <!--  修改密码-->
    <el-dialog title="请输入新的登录密码" width="440px" :visible.sync="dialogFormVisible" @close="$refs.form.resetFields()">
      <el-form ref="pwdForm" :model="form" :rules="rules">
        <el-form-item prop="password">
          <el-input v-model="form.password" type="password" placeholder="请设置登录密码" autocomplete="off" />
        </el-form-item>
        <el-form-item prop="checkPassword">
          <el-input v-model="form.checkPassword" type="password" placeholder="再次输入登录密码" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 90%" @click="onSubmit('pwdForm')">完成设置</el-button>
      </div>
    </el-dialog>

    <!--  设置默认企业-->
    <el-dialog title="设置默认企业" width="480px" :visible.sync="mrVisible" @close="mrReset">
      <div v-for="item in enterpriseList" :key="item.enterpriseUuid" class="mr-item" :class="{mr_checked:chooseId==item.enterpriseUuid}" @click="choseMr(item)">
        <div class="mr-item-left">
          <el-image
            style="width: 30px; height: 30px;border-radius: 8px"
            :src="item.enterpriseIcon"
            fit="fit"
          >
            <div slot="error" class="image-slot">
              <div class="compoangnodata" />
            </div>
          </el-image>
          <div class="mr-item-company" :class="{companyname1:item.enterpriseAuthenticationCode != 1155524}">
            <UmToolTip :content="item.enterpriseName" class="row_1" />
          </div>
        </div>
        <div class="mr-item-right" :class="{mr_checked_text:chooseId==item.enterpriseUuid}">
          <div class="mr_noChecked_radio" :class="{mr_checked_radio:chooseId==item.enterpriseUuid}" /> 默认企业
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="mrReset">取消</el-button>
        <el-button type="primary" @click="mrSubmit">确认设置</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  enterpriseList,
  message_unread_num,
  switchEnterprise,
  change_password_submit,
  verify_change_password,
  change_password,
  getPublicKey,
  enterpriseDefaultFlag
} from '@/api/user'
import imSdk from '@um/im'
import JSEncrypt from 'jsencrypt'
import JoinTeamDialog from '@/layout/components/Sidebar/joinTeamDialog'

export default {
  components: { JoinTeamDialog },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error('请输入短信验证码'))
      } else {
        callback()
      }
    }
    return {
      publicKey: '',
      msgcode: '',
      showjointem: false,
      choseitems: {},
      choseloading: false,
      enterpriseList: [],
      unenterpriseList: [],
      totalUnread: 0,
      routes: [
        // { path: '/im', title: '消息', icon: 'msg', iconActive: 'msg-active' },
        { path: '/im', title: '消息', icon: 'xiaoxi', iconActive: 'msg-active' },
        // { path: '/dashboard', title: '工作台', icon: 'dashboard', iconActive: 'dashboard-active', role: '11202' },
        { path: '/dashboard', title: '工作台', icon: 'gongzhuotai', iconActive: 'gongzhuotai-active', role: '11202' },
        { path: '/maillist', title: '通讯录', icon: 'tonxunlu', iconActive: 'tonxunlu-active', role: '' },
        // { path: '/enterprise', title: '企业管理', icon: 'enterprise', iconActive: 'enterprise-active', role: '11203' },
        // { path: '/mainData', title: '主数据', icon: 'mainData', iconActive: 'mainData-active', role: '11204' },
        { path: '/administration', title: '管理', icon: 'guanli', iconActive: 'guanli-active', role: '11204' }
      ],
      rules: {
        password: [
          { required: true, message: '请设置登录密码', trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, message: '再次输入登录密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      rulesForm: {
        smsCode: [{ required: true, trigger: 'change', validator: validatePassword }],
        userMobile: [{ required: true, trigger: 'change', validator: validateUsername }]
      },
      dialogVisible: false, // 验证码弹框
      dialogFormVisible: false, // 修改密码弹框
      loading: false,
      flagd: false,
      contentd: '发送验证码',
      totalTimed: 60, // 倒计时时间
      phoneForm: {
        smsCode: '', // 验证码
        userMobile: '' // 用户手机号
      },
      form: {
        smsCode: '',
        password: '',
        checkPassword: ''
      },
      mrVisible: false,
      chooseId: null,
      chooseData: {}
    }
  },
  computed: {
    ...mapGetters(['avatar', 'name', 'userPosition', 'enterpriseUuid', 'enterpriseLogoImg', 'roles']),
    routes_role() {
      return this.routes.filter(item => {
        return !item.role || this.roles.includes(item.role)
      })
    },
    disabled() {
      return (!this.phoneForm.smsCode && !this.phoneForm.userMobile)
    }
  },
  created() {
    imSdk.bus.on('session:totalUnread', this.getTotalUnread)
  },
  beforeDestroy() {
    imSdk.bus.off('session:totalUnread', this.getTotalUnread)
  },
  methods: {
    showTips(val) {
      if (val.joinTypeCode == 1) {
        this.showjointem = true
        this.choseitems = val
      } else {
        this.showjointem = false
        this.$message({
          type: 'info',
          message: '正在等待企业后台审核中'
        })
      }
    },
    changejointeamdialog(val) {
      this.showjointem = val
    },
    // 判断是否选中
    activeMenu(item) {
      const route = this.$route
      const { meta, path } = route
      if (meta && meta.activeMenu) {
        return meta.activeMenu.indexOf(item) === 0
      }
      return path.indexOf(item) === 0
    },
    getTotalUnread(totalUnread) {
      this.totalUnread = totalUnread
    },
    // 获取企业列表
    getTeam() {
      this.choseloading = true
      enterpriseList().then(res => {
        this.enterpriseList = res.data.myList || []
        this.unenterpriseList = res.data.joinList || []
        const enterpriseImList = this.enterpriseList.map(item => item.userImAccountId)
        message_unread_num({
          userAccountIdList: enterpriseImList
        }).then(res => {
          console.log(res, 123)
          const list = res.data || []
          this.enterpriseList.forEach(item => {
            const needEnterprise = list.find(item2 => item2.userAccountId == item.userImAccountId)
            needEnterprise && (this.$set(item, 'unRead', needEnterprise.unreadNum))
          })
        })
      }).finally(_ => {
        this.choseloading = false
      })
    },
    choseitem(item) {
      if (this.enterpriseUuid == item.enterpriseUuid) return
      this.choseloading = true
      switchEnterprise({
        enterpriseUuid: item.enterpriseUuid
      }).then(res => {
        this.$message.success('正在切换到：' + item.enterpriseName)
        this.$refs.dropdown.hide()
        // 清除业务数据
        this.$store.dispatch('user/removeAllCache')
        setTimeout(() => {
          // window.location.reload()
          console.log(window.location)
          console.log(process.env.VUE_APP_BASE_URL)
          console.log(process.env.NODE_ENV)
          window.location.replace(location.origin + process.env.VUE_APP_BASE_URL + (process.env.NODE_ENV == 'development' ? '' : process.env.NODE_ENV == 'production' ? '' : '/'))
        }, 1500)
      }).finally(_ => {
        this.choseloading = false
      })
    },
    loginout() {
      // this.$refs.personinfos.hide()
      this.$confirm('是否确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('user/logout').then(res => {
          // location.href = location.origin + process.env.VUE_APP_BASE_URL + `/login?redirect=${this.$route.fullPath}`
          this.$router.push(`/login?redirect=${this.$route.fullPath}`)
          this.$message({
            type: 'success',
            message: '退出成功!'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消退出'
        })
      })
    },
    // 点击修改密码
    editCode() {
      this.dialogVisible = true
    },
    reset() {
      this.$refs.phoneForm.resetFields()
      this.phoneForm.userMobile = ''
    },
    // 点击发送验证码
    getCaptchad() {
      this.flagd = true
      this.contentd = this.totalTimed + 's'
      const clock = window.setInterval(() => {
        this.totalTimed--
        this.contentd = this.totalTimed + 's'
        if (this.totalTimed < 0) {
          window.clearInterval(clock)
          this.contentd = '发送验证码'
          this.totalTimed = 60
          this.flagd = false
        }
      }, 1000)
      change_password({}).then(res => {
        this.$message.success('短信验证码获取成功，请注意查收！')
      }).catch(e => {
        this.$errorHandle(e)
        this.getLoginValiteImg()
        window.clearInterval(clock)
        this.contentd = '发送验证码'
        this.totalTimed = 60
        this.flagd = false
      })
    },
    // 点击下一步
    handleLogin() {
      this.getPublicKey()
      this.$refs.phoneForm.validate(valid => {
        if (valid) {
          this.loading = true
          verify_change_password({ smsCode: this.phoneForm.smsCode }).then(d => {
            console.log(d)
            this.$message.success('验证成功')
            this.form.smsCode = this.phoneForm.smsCode
            this.dialogVisible = false
            this.dialogFormVisible = true
          }).finally(_ => {
            this.dialogVisible = false
          }).catch(e => {
            this.$errorHandle(e)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取公钥
    getPublicKey() {
      getPublicKey({}).then(res => {
        this.publicKey = res.data
        this.msgcode = res.code
      }
      ).finally(d => {
      }).catch(e => {
        if (e.code == 3020) {
          this.publicKey = e.data
        }
        this.msgcode = e.code
      })
    },

    // 点击完成
    onSubmit(formName) {
      console.log('加密-----')
      this.encryptor = new JSEncrypt()
      this.encryptor.setPublicKey('-----BEGIN PUBLIC KEY-----' + this.publicKey + '-----END PUBLIC KEY-----')
      const password = this.encryptor.encrypt(this.form.password)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          change_password_submit({
            password,
            smsCode: this.form.smsCode
          }).then(d => {
            this.$message.success('修改成功！')
            this.dialogFormVisible = false
          }).catch(e => {
            this.$errorHandle(e)
          })
        } else {
          this.$message.error('请正确填写表单！')
          return false
        }
      })
    },
    setMr() {
      this.mrVisible = true
      const enterpriseImList = this.enterpriseList.filter(item => item.defaultFlag)
      this.chooseId = enterpriseImList[0].enterpriseUuid
      this.$refs.dropdown.visible = false
    },
    choseMr(item) {
      console.log(item)
      this.chooseData = item
      this.chooseId = item.enterpriseUuid
    },
    mrReset() {
      this.mrVisible = false
      this.chooseId = null
    },
    mrSubmit() {
      enterpriseDefaultFlag({ enterpriseUuid: this.chooseId, defaultFlag: 1 }).then(res => {
        this.mrVisible = false
      })
      // this.choseitem(this.chooseData)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-badge__content{
  line-height: 16px;
}
.none {
  ::v-deep {
    .el-badge__content.is-fixed {
    display: none;
    }
  }
}
.more99 {
  ::v-deep {
    .el-badge__content.is-fixed {
      right: 18px;
    }
  }
}
::v-deep {
  .el-badge__content.is-fixed {
    top: 2px;
  }
}
.peoplenodata{
  width: 42px;
  height: 42px;
  border-radius: 3px;
  background: url("~@/assets/enterprise/peoplenodata.png") no-repeat center;
  background-size: 100% 100%;
}
.compoangnodata{
  width: 30px;
  height: 30px;
  background: url("~@/assets/enterprise/companynodata.png") no-repeat center;
  background-size: 100% 100%;
}
.compoangnodata1{
  width: 42px;
  height: 42px;
}
.avatar-wrap {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  .b-avatar {
    width: 42px;
    height: 42px;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
    transition: all .2s linear;
    &:hover{
      transform: scale(1.2);
    }
  }

}
.slide-wrap {
  width: 100%;
  flex: 1;
  overflow: hidden;
  @at-root .slide-item {
    //width: 100%;
    //height: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 54px;
    height: 54px;
    cursor: pointer;
    padding: 8px 0px 10px;
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    //color: #31415F;
    color: rgba(255, 255, 255, 0.8000);
    line-height: 12px;
    .icon {
      font-size: 26px;
      margin-bottom: 4px;
    }
    & + & {
      //margin-top: 16px;
      margin-top: 22px;
    }
    &.active {
      //background: url("~@/assets/common/slide-active.png") no-repeat center;
      //background-size: 100% 100%;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      //color: #3D80FC;
      margin-left: auto;
      margin-right: auto;
      line-height: 12px;
      //width: 54px;
      //height: 54px;
      //padding: 0 6px;
      background: rgba(14,16,32,0.8);
      box-shadow: inset 0px 1px 4px 0px rgba(0,0,0,0.27);
      border-radius: 4px;
    }
  }
  @at-root .m_side_line{
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    width: 36px;
    height: 1px;
    background: rgba(255,255,255,0.2);
  }
}
.setting-wrap {
  margin-bottom: 40px;
  .settings {
    width: 42px;
    height: 42px;
    //background: url("~@/assets/common/setting.png") no-repeat center;
    //background-size: 100% 100%;
    margin: 0 auto;
    cursor: pointer;
    //margin-bottom: 24px;
  }
  .setting {
    width: 26px;
    height: 26px;
    background: url("~@/assets/common/setting.png") no-repeat center;
    background-size: 100% 100%;
    transition: all 0.2s linear;
    &:hover {
      transform: scale(1.2);
    }
    //margin: 0 auto;
    //cursor: pointer;
    //margin-bottom: 24px;
  }
  .logo {
    width: 42px;
    height: 42px;
    //background: url("~@/assets/common/logo-default.png") no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 11px;
    overflow: hidden;
    outline: 6px solid transparent;
    transition: all 500ms;
    &:hover{
      outline: 6px solid #343959;
    }
  }
}
</style>
<style lang="scss">
.chosebox{
  width: 280px;
  height: 220px;
  background: #F5F7FA;
  .choseenterprise{
    margin: 26px 0 12px 34px;
    color: #31415F;
    font-size: 22px;
    font-weight: 500;
  }
  .changeitems{
    display: flex;
    margin: 0 0 26px 34px;
    .choseicon{
      width: 16px;
      height: 16px;
      background: url("~@/assets/login/qiehuan.png") no-repeat 0 0 / 16px 16px;
    }
    .chosetitle{
      margin-left: 8px;
      color: #3D80FC;
      font-size: 14px;
    }
  }
  .enterprise-item-footer{
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    bottom: 10px;
    display: flex;
    align-items: center;
    &:after{
      position: absolute;
      right: -14px;
      content: '';
      background: url("~@/assets/images/icon-r-mr@2x.png") no-repeat;
      background-size: contain;
      width: 12px;
      height: 12px;
    }
  }
  .enterprise-item {
    position: relative;
    margin: 0 auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin: 0 24px;
    width: 264px;
    height: 46px;
    //background: rgba(228, 232, 235, 0.4);
    //border-radius: 10px;
    //margin-bottom: 20px;
    //transition: all 0.2s linear;
    .cleft {
      display: flex;
      align-items: center;
      .cimg {
        flex-shrink: 0;
        margin: 0 4px 0 4px;
        width: 35px;
        height: 35px;
      }
      .companyname {
        color: #31415F;
        max-width: 180px;
        font-size: 14px;
        font-weight: 600;
        margin-left: 12px;
      }
      .companyname1 {
        max-width: 180px;
      }
      .positions{
        //margin-top: 6px;
        margin-left: 12px;
        color: #69748A;
        font-size: 12px;
        max-width: 180px;
        .m_join_type_yz{
          color: #FF6161;
        }
        .m_join_type_sh{
          color: #F69952;
        }
      }
      .rows {
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .cicon {
        margin-left: 4px;
        width: 16px;
        height: 16px;
        background: url("~@/assets/login/moren.png") no-repeat 0 0 / 16px 16px;
      }
      .enterprise_item_mr{
        position: absolute;
        right: 8px;
        width: 16px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: rgba(33,174,110,0.20);
        border-radius: 6px 0px 0px 6px;
        color: #21AE6E;
        font-size: 12px;
        transform: scale(0.83);
      }
    }
    .righticon{
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background: url("~@/assets/login/more.png") no-repeat 0 0 / 20px 20px;
    }
  }

  .ccontent_checked{
    background: rgba(76,100,254,0.10);
    border-radius: 10px;
  }
  .emptycontent{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 340px;
    height: 300px;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden!important;
    overflow-y: scroll!important;
  }
  .ccontent_checked_color{
    color: #4C64FE !important;
    p{
      color: #4C64FE !important;
    }
  }
}
.dropavatar{
  box-sizing: content-box;
  //width: 336px;
  //height: 120px;
  padding:10px 20px;
  .personinfo{
    display: flex;
    align-items: center;
    height: 42px;
    //margin: 33px 0 0 34px;
    .heades{
      width: 42px;
      height: 42px;
      border-radius: 3px;
    }
    .infos{
      margin-left: 14px;
      display: flex;
      flex-direction: column;
      .infoname{
        max-width: 200px;
        color: #31415F;
        font-size: 15px;
        margin-bottom: 10px;
      }
      .infoduty{
        max-width: 200px;
        color:#909DB6 ;
        font-size: 13px;
        text-align: left;
      }
      .rows {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .lines{
    margin: 20px auto;
    width: 268px;
    border-top: 1px solid #ADB3BF;
  }
  .loginout{
    cursor: pointer;
    //margin: 20px 0 0 34px;
    display: flex;
    align-items: center;
    .officon{
      width: 14px;
      height: 14px;
      background: url("~@/assets/login/tuichu.png") no-repeat 0 0 / 14px 14px;
    }
    .officonKey{
      width: 14px;
      height: 14px;
      background: url("~@/assets/login/key_edit.png") no-repeat 0 0 / 14px 14px;
    }
    .lout{
      margin-left: 8px;
      color: #3D80FC;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
 .el-popper[x-placement^=bottom]{
   border-radius: 10px;
}
.el-popper[x-placement^=top]{
  border-radius: 10px;
}
.phoneBox{
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
  font-size: 20px;
}
.smInput{
  position: relative;
}
.smBox {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  > span {
    color: #4B6CF9;
    margin-right: 13px;
  }
  ::v-deep .el-button--text{
    color: #3D80FC;
    font-size: 14px;
  }
}
.mr-item{
  width: 400px;
  height: 56px;
  background: #F7F8F9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 16px;
  cursor: pointer;
  box-sizing: border-box;
  .mr-item-left{
    display: flex;
    align-items: center;
    .mr-item-company{
      color: #31415F;
      max-width: 180px;
      font-size: 14px;
      font-weight: 600;
      margin-left: 12px;
    }
    .companyname1 {
      max-width: 180px;
    }
  }
  .mr-item-right{
    color: #6C7B96;
    font-size: 14px;
    display: flex;
    align-items: center;
    .mr_noChecked_radio{
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icon-nochoose-radio.svg") no-repeat;
      background-size: 100% 100%;
      margin-right: 4px;
    }
    .mr_checked_radio{
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icon-choose-radio.svg") no-repeat;
      background-size: 100% 100%;
      margin-right: 4px;
    }
  }
  .mr_checked_text{
    color: #4C64FE;
  }
}
.mr_checked{
  box-sizing: border-box;
  border: 1px solid rgba(76,100,254,1);
  background: #E6E9FF;
}
</style>
