<template>
  <div class="min_h_100 m-maindatapart infoData">
    <div class="go_back_common">
      <div class="go_back_title" @click="$router.replace('/administration')">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div>
      <el-form :model="searchParam" :inline="true" class="searchParam">
        <el-form-item label="项目名称" class="form-inp">
          <UmBusProject
            v-model="searchParam.busProjectUuid"
            style="width:100%"
            placeholder="请选择项目"
          />
        </el-form-item>
        <!--        <div class="fr">-->
        <!--          <el-button type="primary" icon="el-icon-search" @click="getList">搜 索</el-button>-->
        <!--        </div>-->
      </el-form>
    </div>
    <el-radio-group v-model="tab" class="mb20">
      <el-radio-button label="publicHome">自定义公区</el-radio-button>
      <el-radio-button label="home">房间部位</el-radio-button>
    </el-radio-group>
    <div v-show="tab==='publicHome'" class="fr margin-t-20">
      <el-button v-permission="['112040803']" icon="el-icon-circle-plus-outline" type="primary" @click="appendParent">新增自定义公区</el-button>
    </div>
    <div v-show="tab==='home'" class="fr margin-t-20">
      <el-button v-permission="['112040809']" icon="el-icon-circle-plus-outline" type="primary" @click="append">新增部位</el-button>
    </div>
    <component :is="tab" ref="component" :bus-project-uuid="searchParam.busProjectUuid" :introduce-visible="introduceVisible" />
  </div>
</template>
<script>
import publicHome from './publicHome'
import home from './home'
export default {
  name: 'MainDataPosition',
  components: { publicHome, home },
  props: {},
  data() {
    return {
      searchParam: {
        busProjectUuid: ''
      },
      tab: 'publicHome',
      introduceVisible: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    appendParent() {
      this.$refs.component.appendParent()
    },
    append() {
      this.$refs.component.append()
    },
    getList() {
      this.$refs.component.changeList()
    }
  }
}
</script>

