import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'

const meetingRouter = {
  path: '/meeting',
  component: Layout,
  meta: { title: '企业管理', icon: 'enterprise', iconActive: 'enterprise-active', level: 1, roles: ['11203'] },
  // redirect: '/enterprise/home',
  redirect: to => {
    for (let i = 0; i < meetingRouter.children.length; i++) {
      const { path, meta } = meetingRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  children: [
    // 会议室
    {
      name: 'EnterpriseApplicationMeeting',
      path: '/meeting',
      component: () => import('@/views/meeting'),
      meta: { title: '会议室列表', group: 'enterpriseApp', level: 2, roles: ['1120303'], activeMenu: '/enterprise/application' }
    },
    {
      name: 'EnterpriseApplicationMeetingAdd',
      path: '/meeting/add',
      component: () => import('@/views/meeting/add'),
      meta: { title: '会议室新增', group: 'enterpriseApp', level: 3, roles: ['1120303'], activeMenu: '/enterprise/application' }
    },
    {
      name: 'EnterpriseApplicationMeetingUpdate',
      path: '/meeting/update',
      component: () => import('@/views/meeting/add'),
      meta: { title: '会议室编辑', group: 'enterpriseApp', level: 3, roles: ['1120303'], activeMenu: '/enterprise/application' }
    },
    {
      name: 'EnterpriseApplicationMeetingInfo',
      path: '/meeting/info',
      component: () => import('@/views/meeting/info'),
      meta: { title: '会议室详情', group: 'enterpriseApp', level: 3, roles: ['1120303'], activeMenu: '/enterprise/application' }
    }

  ]
}
export default meetingRouter
