<template>
  <div class="m-carousel">
    <el-carousel :interval="5000" arrow="never" trigger="click">
      <el-carousel-item v-for="(item,i) in bannerList" :key="i" class="text-center">
        <el-image
          class="pointer"
          style="height: 100%;border-radius: 12px;"
          :src="item.file.fileHttpPath"
          fit="fit"
          @click="getJump(item)"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  props: {
    bannerList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
    getJump(item) {
      if (item.redirect) {
        if (item.jumpMode == 1) {
          window.open(item.redirect)
        } else if (item.jumpMode == 2) {
          return false
        } else if (item.jumpMode == 3) {
          // 应用详情
          this.$router.push({ path: '/cloudMarket/info', query: { uuid: item.redirect, dataType: 10239001 }})
        } else if (item.jumpMode == 4) {
          // 服务详情
          this.$router.push({ path: '/cloudMarket/info', query: { uuid: item.redirect, dataType: 10239002 }})
        } else if (item.jumpMode == 5) {
          // 硬件详情
          this.$router.push({ path: '/cloudMarket/info', query: { uuid: item.redirect, dataType: 10239003 }})
        }
      }
    }
  }
}
</script>

