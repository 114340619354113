<template>
  <div class="m-room">
    <!--    <UmApproveEle :app-uuid="appUuid" />-->
    <div class="top-back">
      <div class="title-back w60" @click="$router.replace('/administration')">返回</div>
    </div>
    <div class="room-main">
      <div class="main_leftBox">
        <div class="leftBox_project">
          <div class="u-projectLabel">项目名称：</div>
          <div class="u-projectContent">
            <um-bus-project ref="umbuspro" v-model="busProjectId" />
          </div>
        </div>

        <div class="leftBox_building">
          <div class="building_filter">
            <el-input v-model="filterWord" placeholder="按楼栋名称过滤" clearable @input="building_filter_handler" />
          </div>
          <div v-if="filterBuildingList&&filterBuildingList.length" class="building_list">
            <div
              v-for="(item,index) in filterBuildingList"
              :key="index"
              class="u-item row_one"
              :class="{active: active_building == index}"
              @click="selectIdClick(index)"
            >
              {{ item.buildingName || '-' }}
            </div>
          </div>
          <div v-else style="font-size: 14px;color: #999;text-align: center;line-height: 200px">暂无数据</div>
        </div>
      </div>

      <div class="main_rightBox">
        <transition name="gp-fade-in-linear" mode="out-in">
          <div v-if="tab== 1" class="m-room-view">
            <!--            <div v-if="$refs.umbuspro&&!$refs.umbuspro.busProjectTreeData.length" class="f-room-button" @click="$router.push('/mainData/project')">-->
            <!--              <svg-icon icon-class="add_all" />-->
            <!--              <span>新增项目</span>-->
            <!--            </div>-->
            <template v-if="buildingList&&buildingList.length">
              <div class="mb20 flex-between">
                <div v-permission="['112040609']" class="m-huxing">
                  <el-switch v-model="swtich_huxing" active-text="显示户型" />
                </div>
                <div class="m-buttons">
                  <u-export :key="exportkey" ref="export" title="导出" style="margin-right: 20px" @leave="exportkey++" />
                  <div v-permission="['112040606']" class="f-room-button" @click="exportUser">
                    <svg-icon icon-class="room_daochu" />
                    <span>导出</span>
                  </div>
                  <div v-permission="['112040604']" class="f-room-button" @click="editRoomHandler">
                    <svg-icon icon-class="room_bianji" />
                    <span>编辑房间</span>
                  </div>
                  <div v-if="busBuildingRoom.length == 0" v-permission="['112040603']" class="f-room-button" @click="initRoom">
                    <!--                    <svg-icon icon-class="bjfw" />-->
                    <svg-icon icon-class="room_scfj" />
                    <span>生成房间</span>
                  </div>
                  <div v-else v-permission="['112040609']" class="f-room-button" @click="initRoom"><span>重新生成房间</span></div>
                  <div v-permission="['112040607']" class="f-room-button" @click="setFloorType"><span>设置楼层属性</span></div>
                  <div v-permission="['112040608']" class="f-room-button" @click="download"><span>下载导入模板</span></div>
                  <!--                  <u-import-->
                  <!--                    title="导入数据"-->
                  <!--                    style="margin-left: 10px"-->
                  <!--                    :is-config="isConfig"-->
                  <!--                    :is-config-data="isConfigData"-->
                  <!--                    :project-id="busProjectId"-->
                  <!--                    :import-button="{-->
                  <!--                      name: '导入数据',-->
                  <!--                      apiName: importExcel,-->
                  <!--                      formData: {-->
                  <!--                        progressParamData: {-->
                  <!--                          busBuildingId: busBuildingId,-->
                  <!--                        },-->
                  <!--                        progressBusinessCode: 1004107-->
                  <!--                      },-->
                  <!--                    }"-->
                  <!--                    @importFinish="importFinish"-->
                  <!--                  />-->
                  <div v-permission="['112040605']" class="f-room-button" @click="importHandler">
                    <!--                    <svg-icon icon-class="bjfw" />-->
                    <svg-icon icon-class="room_daochu" />
                    <span>导入</span>
                  </div>
                </div>
              </div>

              <div class="m-table">
                <list
                  :type="swtich_huxing"
                  :bus-building-unit="busBuildingUnit"
                  :bus-building-room="busBuildingRoom"
                  :bus-building-floor="busBuildingFloor"
                  :bus-building-door="busBuildingDoor"
                />
              </div>
            </template>
            <template v-else>
              <NoProject v-if="$refs.umbuspro&&!$refs.umbuspro.busProjectTreeData.length" />
              <div v-else class="f-noData">暂无数据</div>
            </template>
          </div>

          <template v-if="tab== 2">
            <initRoom
              :bus-building-door="busBuildingDoor"
              :bus-building-floor="busBuildingFloor"
              :bus-building-unit="busBuildingUnit"
              :bus-building-room="busBuildingRoom"
              :bus-project-id="busProjectId"
              :bus-building-id="busBuildingId"
              @cancel="close"
            />
          </template>

          <template v-if="tab== 3">
            <editRoom
              :bus-building-door="busBuildingDoor"
              :bus-building-floor="busBuildingFloor"
              :bus-building-unit="busBuildingUnit"
              :bus-building-room="busBuildingRoom"
              :bus-project-id="busProjectId"
              :bus-building-id="busBuildingId"
              @cancel="close"
            />
          </template>
        </transition>
      </div>

      <el-dialog title="设置楼层属性" center width="518" :visible.sync="setFloorVisible">
        <el-table :data="busBuildingFloorList">
          <el-table-column
            prop="floorName"
            label="楼层名称"
            width="200"
          />
          <el-table-column
            label="楼层属性"
          >
            <template #default="{ row }">
              <el-radio-group v-model="row.floorType">
                <el-radio :label="1">地上</el-radio>
                <el-radio :label="0">地下</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center" style="margin-top: 20px;">
          <el-button type="primary" plain @click="setFloorVisible = false">取消</el-button>
          <el-button type="primary" @click="submit_setFloorType">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import editRoom from './components/editRoom'
import initRoom from './components/initRoom'
import list from './components/list'
import { get_floor_type, get_room_list, query_building_list, submit_house_type } from '@/api/mainData/room'
import { exportExcel, importExcel } from '@/api/common'
import NoProject from '@/views/mainData/components/noProject'
export default {
  name: 'MainDataRoom',
  components: { list, initRoom, editRoom, NoProject },
  data() {
    return {
      exportkey: 1,
      importExcel,
      appUuid: '',
      filterWord: '',
      swtich_huxing: false, // 是否显示户型
      active_building: 0, // 当前选中楼栋标识
      buildingList: [], // 楼栋列表
      busBuildingUnit: [],
      busBuildingRoom: [],
      busBuildingFloor: [],
      busBuildingDoor: [],
      busProjectId: '',
      busBuildingId: '',
      tab: 1,
      isConfig: false,
      isConfigData: false,
      setFloorVisible: false,
      busBuildingFloorList: [] // 设置楼层属性list
    }
  },
  computed: {
    filterBuildingList() {
      if (!Array.isArray(this.buildingList)) return []
      const text = this.filterWord.trim()
      console.log(text)
      if (text) {
        console.log(this.buildingList)
        return this.buildingList.filter(item => item.buildingName.includes(text))
      } else {
        return this.buildingList
      }
    }
  },
  watch: {
    busProjectId: {
      handler(val) {
        if (val) {
          this.getList()
          // this.config()
        }
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    download() {
      const a = document.createElement('a')
      a.download = '房间导入模板'
      a.href = '/resources/import/房间导入模板.xlsx'
      a.click()
    },
    importHandler() {
      this.$import({
        limit: 1, // 上传限制
        size: 2000, // 文件大小 kb
        progressParamData: {
          busBuildingId: this.busBuildingId
        },
        progressBusinessCode: 1004107,
        accept: '.xls,.xlsx'
      }).then(() => {
        // 导入成功之后可以刷新页面
        this.getRoom()
      }).catch((e) => {
        // 导入失败的话可以有错误提示
      })
    },
    // 导入完成
    importFinish() {
      this.getRoom()
    },
    config() {
      // apiUmbasicProjectRoomTypeGetBusConfigByProjectId({ projectId: this.busProjectId, code: 101 }).then(res => {
      //   if (res.data.data == '1') {
      //     this.isConfig = true
      //     // this.$message.error('请关闭项目的提交需要审核配置')
      //   } else {
      //     this.isConfig = false
      //   }
      // })
    },
    // 导出
    exportUser() {
      const load = this.$load()
      exportExcel({
        progressParamData: {
          busBuildingId: this.busBuildingId
        },
        excelPath: '',
        progressBusinessCode: 1004106
      }).then(d => {
        load.close()
        console.log('this.$refs.export', this.$refs.export)
        this.$refs.export.operateProgressId = d.data.progressId
        // this.$refs.export.startProgress()
      }).catch(e => {
        load.close()
        this.errorHandle(e)
      })
    },
    building_filter_handler(val) {
      console.log(this.b)
      return this.b
    },
    selectIdClick(index) {
      console.log(index)
      this.active_building = index
      this.busBuildingId = this.filterBuildingList[this.active_building].busBuildingId
      // this.close()
      this.getRoom()
    },
    // 获取楼栋
    getList() {
      if (!this.busProjectId) return false
      query_building_list({
        busProjectUuid: this.busProjectId,
        mineDateType: 1
      })
        .then(d => {
          // const list = d.data || []
          // list.forEach(item => {
          //   item.busBuildingId = item.buildingUuid
          // })
          this.buildingList = d.data
          this.active_building = 0
          this.busBuildingUnit = []
          this.busBuildingRoom = []
          this.busBuildingFloor = []
          this.busBuildingDoor = []
          if (this.buildingList.length > 0) {
            this.close()
            this.busBuildingId = this.buildingList[this.active_building].busBuildingId
            this.getRoom()
          }
        })
        .catch(e => {
          this.$errorHandle(e)
        })
    },
    /**
     * 查询楼层属性，打开弹窗
     */
    setFloorType() {
      const load = this.$load()
      get_floor_type({
        busBuildingId: this.busBuildingId
      }).then(res => {
        this.busBuildingFloorList = res.data
        this.setFloorVisible = true
      }).finally(_ => {
        load.close()
      })
    },
    /**
     * 设置楼层属性
     */
    submit_setFloorType() {
      const load = this.$load()
      submit_house_type(
        this.busBuildingFloorList
      ).then(res => {
        this.$alert('设置成功！', '提示', {
          type: 'success',
          showClose: false
        }).then((d) => {
          this.setFloorVisible = false
        })
      }).finally(_ => {
        load.close()
      })
    },
    close() {
      this.busBuildingUnit = []
      this.busBuildingRoom = []
      this.busBuildingFloor = []
      this.busBuildingDoor = []
      this.tab = 1
      this.getRoom()
    },
    initRoom() {
      this.busBuildingUnit = []
      this.busBuildingRoom = []
      this.busBuildingFloor = []
      this.busBuildingDoor = []
      this.tab = 2
      this.steps = 0
    },
    editRoomHandler() {
      if (!this.busProjectId) return false
      if (this.busBuildingRoom && this.busBuildingRoom.length == 0) {
        this.$message.error('请先生成房间')
        return false
      }
      const load = this.$load()
      get_room_list({
        busBuildingId: this.busBuildingId
      }).then(d => {
        this.busBuildingUnit = d.data.busBuildingUnit.map(item => {
          item.deleteFlag = 0
          return item
        })
        this.busBuildingRoom = d.data.busBuildingRoom.map(item => {
          item.deleteFlag = 0
          return item
        })
        this.busBuildingFloor = d.data.busBuildingFloor.map(item => {
          item.deleteFlag = 0
          return item
        })
        this.busBuildingDoor = d.data.busBuildingDoor.map(item => {
          item.deleteFlag = 0
          return item
        })
        load.close()
        this.tab = 3
        this.steps = 0
      })
      //   }
      // }).catch(e => {
      //   this.$errorHandle(e)
      // })
    },
    // 获取房间
    getRoom() {
      if (!this.busBuildingId) return
      get_room_list({
        busBuildingId: this.busBuildingId
      })
        .then(d => {
          this.busBuildingUnit = d.data.busBuildingUnit
          this.busBuildingRoom = d.data.busBuildingRoom
          this.busBuildingFloor = d.data.busBuildingFloor
          this.busBuildingDoor = d.data.busBuildingDoor
          if (this.busBuildingRoom.length == 0) {
            this.isConfigData = true
          } else {
            this.isConfigData = false
          }
        })
        .catch(e => {
          this.$errorHandle(e)
        })
    }
  }
}
</script>

<style scoped lang="scss">

</style>

