<template>
  <div class="detailsBox m_maindata_project">
    <div class="go_back_common">
      <div class="go_back_title" @click="goback">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <el-row type="flex">
      <el-col class="m_project_menu">
        <div class="u_menu_title">
          组织架构
        </div>
        <div class="u_menu_search">
          <el-input v-model="filterText" clearable placeholder="请输入组织名称搜索" maxlength="50">
            <i slot="prefix" class="gp-input__icon el-icon-search" />
          </el-input>
        </div>
        <el-tree
          ref="tree"
          :data="treeData"
          :props="defaultProps"
          node-key="organUuid"
          highlight-current
          :filter-node-method="filterNode"
          style="margin-top: 10px; margin-bottom: 55px"
          :default-expanded-keys="idArr"
          @current-change="currentTreeChange"
        >
          <span slot-scope="{ node, data }" class="m_maindata_treenode">
            <div class="u_tree_label ">
              <div class="u_tree_label_name" :class="{color1:node.level==1}">
                <UmToolTip :content="data.organName" class="row_1" />
              </div>
              <div class="u_tree_label_tag">
                <template v-if="data.organTypeCode==1001502">公司</template>
                <template v-if="data.organTypeCode==1001503">部门</template>
                <template v-if="data.organTypeCode==1001504">岗位</template>
              </div>
            </div>
          </span>
        </el-tree>
      </el-col>
      <el-col class="m_project_content">
        <div v-permission="['112040303']" class="u_content_btn pointer" @click="addProject()">
          <svg-icon icon-class="add_all" />
          新增项目
        </div>
        <div class="u_content_title" style="display:flex">
          <div style="display: inline-block; width: 300px" class="margin-r-20">
            <div class="flexStart alignCenter">
              <span style="margin-right: 10px">项目名称</span>
            </div>
          </div>
          <div class="m_maindata_titleright">
            <span style="width:150px">
              <span class="dib" style="margin-left: 30px">项目负责人</span>
            </span>
            <span
              style="width:150px "
              class="text-right "
            >所在城市</span>
            <span
              style="width:80px; padding-left:20px; "
              class="text-right "
            >状态</span>
            <span
              style="width: 200px; margin-right:30px"
              class="text-right "
            >操作</span>
          </div>
        </div>
        <div class="m_maindata_content_subject">
          <template
            v-if="!busOrganObj.organUuid || busOrganObj.organType == 1"
          >
            <p class="text-center m_maindata_content_noprojet">请选择公司</p>
          </template>
          <template v-else>
            <el-tree
              v-if="projectTreeData&&projectTreeData.length>0"
              ref="projectTree"
              class="m_project_customtree"
              :data="projectTreeData"
              :props="projectTreeProps"
              node-key="busProjectId"
              highlight-current
              style="margin-top: 10px"
            >
              <span slot-scope="{ node, data }" class="u_customtree_nodes" style="display:flex">
                <div>
                  <el-tag
                    size="mini"
                    :style="{
                      color: data.projectTypeCode==0?'#3D80FC':' #F69952',
                      background: data.projectTypeCode==0?'#D8E6FE':'#FDEBDC',
                      borderColor: data.projectTypeCode==0?'#D8E6FE':'#FDEBDC',
                    }"
                  >{{ PROJECT_TYPE[data.projectTypeCode] }}</el-tag>
                  <div class="dib row-one mr-20 ml-10 " style=" width: 240px;">
                    <UmToolTip :content="node.label" class="row_1" />
                  </div>
                </div>
                <div class="m_maindata_titleright ">
                  <span
                    style="
                      display: inline-block;
                      width: 120px;
                      float: left;
                      line-height: 32px;
                      padding-right:20px
                    "
                  >
                    <!-- {{ data.leaderName }} -->
                    <UmToolTip :content="data.leaderName&&data.leaderName.length>0?data.leaderName.join(','):''" class="row_1" />
                  </span>
                  <span
                    style="
                      width: 150px;
                      display: flex;
                      align-items: center;
                      text-align: left;
                      white-space: normal;
                    "
                    class="text-center"
                  >
                    <!--                    {{ data.projectCityStr }}-->
                    <UmToolTip s :content="data.projectCityStr" class="row_1" />
                  </span>
                  <span
                    style="
                      width: 80px;
                      display: flex;
                      align-items: center;
                      white-space: normal;
                      padding-left:20px;
                    "
                    :style="{ color:data.projectEnable ===0?'#ff6161':'#67C23A'}"
                    class="text-center"
                  >{{ data.projectEnable===0?"禁用":'启用' }}</span>
                  <span
                    style="width: 200px; display: inline-block;margin-right:20px"
                    class="text-right"
                  >
                    <el-button
                      v-if="data.projectTypeCode == 0"
                      v-permission="['112040307']"
                      type="text"
                      style="color:#F69952"
                      @click.stop="appendItem(node, data)"
                    >新增分期</el-button>
                    <el-button
                      v-if="data.projectTypeCode == 0"
                      v-permission="['112040306']"
                      type="text"
                      @click.stop="infoItem(node, data)"
                    >编辑</el-button>
                    <el-button
                      v-if="data.projectTypeCode == 1"
                      v-permission="['112040307']"
                      type="text"
                      @click.stop="editItem(node, data)"
                    >编辑</el-button>
                    <el-button
                      v-if=" data.projectEnable===1"
                      v-permission="['112040305']"
                      type="text"
                      style="color:#FF6161"
                      @click.stop="disableItem(node, data,0)"
                    >禁用</el-button>
                    <el-button
                      v-if=" data.projectEnable===0"
                      v-permission="['112040305']"
                      type="text"
                      style="color:#67C23A"
                      @click.stop="disableItem(node, data,1)"
                    >启用</el-button>
                    <el-button
                      v-permission="['112040304']"
                      type="text"
                      style="color:#FF6161"
                      @click.stop="removeItem(node, data)"
                    >删除</el-button>
                  </span>
                </div>
              </span>
            </el-tree>
            <div v-else class="m-noData">
              <div class="m_maindata_bottomtitle">暂无数据</div>
            </div>
          </template>
        </div>

        <pagination
          v-if="projectTreeData"
          :background="false"
          :layout="'total, sizes, prev, pager, next, jumper'"
          :limit.sync="page.pageSize"
          :page.sync="page.pageNum"
          :total="tableTotal"
          @pagination="getList('page')"
        />
      </el-col>
    </el-row>

    <el-dialog
      :title="title"
      :visible.sync="addStageVisible"
      width="518px"
      @close="$refs.addStageForm.resetFields(),cleartable"
    >
      <el-form ref="addStageForm" :model="addStageForm" label-width="90px" label-position="left">
        <el-form-item
          ref="projectName"
          label="分期名称"
          prop="projectName"
          :rules="[
            { required: true, message: '必填项不能为空', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="addStageForm.projectName"
            placeholder="请输入分期名称"
            style="width: 100%"
            maxlength="50"
          />
        </el-form-item>
      </el-form>
      <div class="m_umBtn  flex-center">
        <div class="u_umBtn_cancel pointer" @click="addStageVisible = false">取消</div>
        <div class="u_umBtn_save pointer" @click="addStageSubmit">保存</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOrganizationList, getProjectList, addProjectPhases, delProject, editProjectPhases, enableProject } from '@/api/mainData/project'
export default {
  name: 'MainDataProject',
  // 组织架构-组织架构类型

  data() {
    const ORGAN_TYPE = {
      '1': '集团',
      '2': '公司',
      '3': '部门',
      '4': '岗位'
    }
    // 项目-项目类型
    const PROJECT_TYPE = {
      '0': '项目',
      '1': '分期'

    }
    return {
      title: '',
      ORGAN_TYPE,
      PROJECT_TYPE,
      filterText: '',
      treeData: [],
      idArr: [],
      defaultProps: {
        children: 'children',
        label: 'organName'
      },
      organUuid: null,
      busOrganObj: {},
      projectTreeData: [],
      projectTreeProps: {
        children: 'children',
        label: 'projectName'
      },
      addVisible: false,
      addStageVisible: false,
      addStageForm: {
        projectParentId: null,
        projectName: '',
        id: ''
      },
      page: {
        pageNum: 1,
        pageSize: 10
      },
      tableTotal: 0
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    addVisible(val) {
      if (!val) {
        this.assignTree()
      }
    }
  },
  created() {
    this.getTree()
  },
  mounted() {

  },
  activated() {
    this.getList()
  },
  deactivated() {
    // 不是子路由的话做初始化
    if (this.$route.path.indexOf('/mainData/project') === -1) {
      console.log(11111)
      this.$store.dispatch('tagsView/delView', {
        name: 'MainDataProject',
        path: '/mainData/project'
      })
      localStorage.removeItem('busOrganObj')
    }
  },
  methods: {
    goback() {
      this.$router.replace('/administration')
    },
    // 获取组织架构
    getTree(row) {
      console.log(121212)
      getOrganizationList({ typeCodeList: [1001502, 1001501] }).then((d) => {
        this.treeData = d.data
        this.treeData.forEach((item) => {
          this.idArr.push(item.organUuid)
        })
        this.$nextTick(() => {
          // localStorage.setItem('busOrganObj', JSON.stringify(this.busOrganObj))
          if (localStorage.getItem('busOrganObj')) {
            this.busOrganObj = JSON.parse(localStorage.getItem('busOrganObj'))
            this.$refs.tree.setCurrentNode(this.busOrganObj)
          } else {
            this.$refs.tree.setCurrentNode(d.data[0])
            this.busOrganObj = d.data[0]
            this.busOrganObj.label = d.data[0].organFullName
          }
          this.getList()
        })
      })
    },
    currentTreeChange(row, node) {
      console.log(row)
      this.busOrganObj = row
      localStorage.setItem('busOrganObj', JSON.stringify(this.busOrganObj))
      this.busOrganObj.label = row.organFullName
      this.getList()
    },
    // 新增项目
    addProject() {
      console.log(this.busOrganObj)
      if (this.busOrganObj.organType == 1001503) {
        this.$message.info('请选择公司!')
        return false
      }
      this.$router.push({ path: '/mainData/project/add', query: { type: 1, orgOrganId: this.busOrganObj.organUuid }})
    },

    filterNode(value, data) {
      if (!value) return true
      return data.organFullName.indexOf(value) !== -1 // todo 根据name 筛选  根据全名搜索 便于用户选择
    },
    assignTree() {
      const params = {
        authFlag: true
      }
    },
    getList(state = '') {
      if (state !== 'page') {
        this.page.pageNum = 1
      }
      this.projectTreeData = []
      const load = this.$load()
      console.log(7777)
      if (this.busOrganObj.organType == 1) return false
      if (!this.busOrganObj.organUuid) return false
      console.log(8888)
      getProjectList({
        orgUuid: this.busOrganObj.organUuid,
        page: {
          pageSize: this.page.pageSize,
          pageNum: this.page.pageNum
        }
      })
        .then((d) => {
          this.projectTreeData = d.data.list
          this.tableTotal = d.data.page.total
          load.close()
        })
        .catch((e) => {
          this.$errorHandle(e)
          load.close()
        })
    },

    exportFile() {
      if (!this.busOrganObj.organUuid || this.busOrganObj.organType == 1) {
        this.$message.info('请选择公司!')
      } else {
        //
      }
    },
    // 提交新增分期
    addStageSubmit() {
      this.$refs.addStageForm.validate((valid) => {
        if (valid) {
          if (!this.addStageForm.id) {
            addProjectPhases(this.addStageForm).then(res => {
              this.$message.success('新增成功')
              this.addStageVisible = false
              this.getList()
            }).catch(e => {
              this.$errorHandle(e)
            })
          } else {
            editProjectPhases(this.addStageForm).then(res => {
              this.$message.success('编辑成功')
              this.addStageVisible = false
              this.getList()
            }).catch(e => {
              this.$errorHandle(e)
            })
          }
        }
      })
    },
    infoItem(node, data) {
      this.$router.push({
        path: '/mainData/project/info',
        query: { type: 2, orgOrganId: this.busOrganObj.organUuid, id: data.id }
      })
    },
    // 新增分期
    appendItem(node, data) {
      console.log(data)
      this.cleartable()
      this.title = '新增分期'
      this.addStageVisible = true
      this.addStageForm.projectParentId = data.id
    },
    // 编辑分期
    editItem(node, data) {
      this.cleartable()
      this.title = '编辑分期'
      this.addStageVisible = true
      this.addStageForm.projectName = data.projectName
      this.addStageForm.id = data.id
      this.addStageForm.projectParentId = data.projectParentId
    },

    removeItem(node, data) {
      this.$confirm(`是否删除：${data.projectName}?`, '提示', {
        type: 'warning'
      })
        .then(() => {
          const load = this.$load()
          delProject({ projectUuid: data.projectUuid }).then(res => {
            this.$message.success('删除成功')
            this.getList()
            this.cleartable()
            load.close()
          }).catch(e => {
            this.$errorHandle(e)
            load.close()
          })
        })
        .catch(() => {
          this.$message.info('已取消删除！')
        })
    },
    // 禁用项目
    disableItem(node, data, enable) {
      this.$confirm(`是否${data.projectEnable === 0 ? '启用' : '禁用'}：${data.projectName}?`, '提示', {
        type: 'warning'
      })
        .then(() => {
          const load = this.$load()
          enableProject({ enable: enable, projectUuid: data.projectUuid }).then(res => {
            this.$message.success('操作成功')
            this.getList()
            load.close()
          }).catch(e => {
            this.$errorHandle(e)
            load.close()
          })
        })
        .catch(() => {
          this.$message.info('已取消！')
        })
    },
    cleartable() {
      this.addStageForm.projectParentId = null
      this.addStageForm.projectName = ''
      this.addStageForm.id = ''
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
