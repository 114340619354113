<template>
  <div class="m_maillist">
    <div class="m_maillist_lside">
      <div
        v-for="item in tabList"
        :key="item.keys"
        class="u_lside_item"
        :class="activetab==item.keys?'u_lside_item_active':''"
        @click="activetab=item.keys"
      >
        <span v-if="activetab==item.keys" class="u_lside_item_l" />
        {{ item.values }}
      </div>
    </div>
    <div class="m_maillist_rside">
      <div v-if="activetab == 0" class="m-content1">
        <div class="m-left">
          <organTree @change="treeCurrentChange" />
        </div>
        <div class="m-right">
          <div v-if="tree_current" class="m-top">
            <div class="m-t-left row_one">
              {{ tree_current.organFullName || '-' }}
            </div>
            <div class="m-t-right">
              <!--              <div v-if="!inputFlag" class="u-search el-icon-search" @click="inputFlag = true" />-->
              <!--              <transition name="el-zoom-in-center">-->
              <el-input v-model="keyword" clearable placeholder="请输入人员名称搜索" maxlength="50" prefix-icon="el-icon-search" @keyup.native.enter="getList" />
              <!--                            </transition>-->
            </div>
          </div>
          <div class="m-content">
            <userList
              :show-status="true"
              :user-list="tableData"
              @send="sendMsg"
            />
          </div>
          <pagination
            style="margin: 20px 20px;"
            :background="false"
            :limit.sync="page.pageSize"
            :page.sync="page.pageNum"
            :total="tableTotal"
            @pagination="() => getList(false)"
          />
        </div>
      </div>
      <div v-if="activetab == 1" class="m-content2">
        <userList :user-list="firendList" @send="(val) => sendMsg2(1, val)" />
      </div>

      <div v-if="activetab == 2" class="m-content2">
        <userList :type="2" :user-list="groupList" @send="val => sendMsg2(2, val)" />
      </div>
    </div>
  </div>
</template>

<script>
import userList from '@/views/maillist/components/userList'
import organTree from '@/views/maillist/components/organTree'
import { get_organ_user_page, im_friends_list, im_group_list, query_organ_user_info } from '@/api/enterprise'
export default {
  name: 'Maillist',
  components: { organTree, userList },
  props: {},
  data() {
    return {
      tabList: [
        {
          keys: 0,
          values: '组织架构'
        },
        {
          keys: 1,
          values: '我的好友'
        },
        {
          keys: 2,
          values: '我的群组'
        }
      ],
      activetab: 0,
      tableData: [],
      tree_current: {},
      keyword: '',
      inputFlag: false,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      tableTotal: 0,
      firendList: [],
      groupList: []
    }
  },
  computed: {},
  watch: {
    activetab: {
      handler(val) {
        val == 1 && this.getFirends()
        val == 2 && this.getGroupList()
      }
    }
  },
  created() {

  },
  mounted() {},
  methods: {
    treeCurrentChange(d) {
      this.tree_current = d || null
      if (d) {
        this.getList()
      } else {
        this.tableData = []
        this.tableTotal = 0
      }
    },
    async sendMsg(item) {
      console.log('item', item)
      const load = this.$load()
      try {
        const res = await query_organ_user_info({
          userUuid: item.userUuid
        })
        const { imAccountId } = res.data || {}
        await this.$imSdk.YX.concactCustomer(imAccountId, 'p2p')
        this.$router.push('/im')
      } finally {
        load.close()
      }
    },
    sendMsg2(type, { imAccountId, teamId }) {
      this.$imSdk.YX.concactCustomer(type == 1 ? imAccountId : teamId, type == 1 ? 'p2p' : 'team').then(res => {
        this.$router.push('/im')
      })
    },
    getFirends() {
      const load = this.$load()
      im_friends_list().then(res => {
        const list = res.data || []
        this.firendList = list.reduce((prev, current) => {
          return prev.concat(current.groupChildren || [])
        }, [])
      }).finally(_ => {
        load.close()
      })
    },
    getGroupList() {
      const load = this.$load()
      im_group_list().then(res => {
        this.groupList = res.data || []
      }).finally(_ => {
        load.close()
      })
    },
    getList(reset = true) {
      reset && (this.page.pageNum = 1)
      this.tableData = []
      const load = this.$load()
      get_organ_user_page({
        page: this.page,
        keyword: this.keyword,
        organUuid: this.tree_current.organUuid
      }).then(res => {
        this.tableTotal = res.data.page.total
        this.tableData = res.data.list
      }).catch(e => {
        this.$errorHandle(e)
      }).finally(_ => {
        load.close()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.m_maillist{
  height: 100%;
  width: 100%;
  display: flex;
  .m_maillist_lside{
    flex-shrink: 0;
    width: 200px;
    height: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    overflow: hidden;
    .u_lside_item{
      position: relative;
      cursor: pointer;
      padding: 20px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      font-weight: 500;
      color: #31415F;
      border-bottom: 1px solid #F0F3F5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .u_lside_item_l{
        width: 4px;
        height: 56px;
        position: absolute;
        left: 0;
        top:0;
        background-color: #4C64FE;
      }
      .u_tag_num{
        color: #FFFFFF;
        height: 16px;
        width: 16px;
        font-size: 12px;
        border-radius: 8px;
        line-height: 16px;
        text-align: center;
        background-color: #FD5050;
      }
    }
    .u_lside_item_active{
      background-color: #EDEFFE;
      color: #4C64FE;
    }
  }
  .m_maillist_rside{
    margin-left: 12px;
    flex: 1;
    border-radius: 12px;
    overflow: hidden;
    background: #fff;
    .m-content1 {
      width: 100%;
      height: 100%;
      display: flex;
      .m-left {
        width: 300px;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 2px 0px 5px 1px rgba(229,229,229,0.4);
        border-radius: 12px 0px 0px 12px;
      }
      .m-right {
        flex: 1;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .m-top {
          padding: 0 20px;
          display: flex;
          align-items: center;
          height: 56px;
          border-bottom: 1px solid #F0F3F5;
          .m-t-left {
            flex: 1;
            flex-shrink: 0;
            font-size: 14px;
            font-weight: 500;
            color: #31415F;
            line-height: 16px;
            margin-right: 20px;
          }
          .m-t-right {
            .u-search {
              width: 18px;
              height: 18px;
              color: #B1B7C3;
              cursor: pointer;
            }
          }
        }
        .m-content {
          flex: 1;
          overflow: hidden;
        }
      }
    }
    .m-content2 {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
