import request from '@/utils/request'

// 分页获取购物车列表
export function shoppingCartList(data) {
  return request({
    url: '/api/v1/business/shopping/cart/list',
    method: 'post',
    data
  })
}

// 加入购物车
export function addShoppingCart(data) {
  return request({
    url: '/api/v1/business/shopping/cart/add',
    method: 'post',
    data
  })
}

// 收藏
export function collection(data) {
  return request({
    url: '/api/v1/application/collect/add',
    method: 'post',
    data
  })
}

// 取消收藏
export function cancelFavorites(data) {
  return request({
    url: '/api/v1/application/collect/cancel',
    method: 'post',
    data
  })
}
// 删除购物车商品
export function delCartItem(data) {
  return request({
    url: '/api/v1/business/shopping/cart/delete',
    method: 'post',
    data
  })
}

// 确认订单
export function confirmOrder(data) {
  return request({
    url: '/api/v1/business/shopping/cart/confirm/order',
    method: 'post',
    data
  })
}

// 提交订单
export function submitOrder(data) {
  return request({
    url: '/api/v1/order/shopping/cart/submit',
    method: 'post',
    data
  })
}

// 修改购物车
export function updateShopping(data) {
  return request({
    url: '/api/v1/business/shopping/cart/update',
    method: 'post',
    data
  })
}

// 批量加入收藏
export function batchCollection(data) {
  return request({
    url: '/api/v1/application/collect/add/batch',
    method: 'post',
    data
  })
}

// 批量删除购物车
export function batchDelCart(data) {
  return request({
    url: '/api/v1/business/shopping/cart/delete/batch',
    method: 'post',
    data
  })
}
