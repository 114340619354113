<!-- eslint-disable vue/no-template-shadow-->
<template>
  <div class="um-uploadImg">
    <el-upload
      v-if="type=='avatar'"
      ref="files"
      :disabled="disabled"
      name="files"
      class="avatar-uploader"
      :action="uploadUrl"
      :accept="accept"
      :multiple="false"
      :show-file-list="false"
      list-type="picture"
      :file-list="fileList"
      :auto-upload="autoUpload"
      :before-upload="beforeUpload"
      :on-change="handleChange"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-exceed="handleExceed"
      :headers="{
        Authorization:token
      }"
    >
      <slot>
        <i class="el-icon-plus avatar-uploader-icon" />
      </slot>
      <img v-if="fileList.length" :src="fileList[0].fileFullPath||fileList[0].url" class="avatar">
    </el-upload>

    <el-upload
      v-else
      ref="files"
      :disabled="disabled"
      name="files"
      :action="uploadUrl"
      :accept="accept"
      :multiple="multiple"
      :limit="limit"
      :file-list="fileList"
      list-type="picture-card"
      :auto-upload="autoUpload"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-change="handleChange"
      :on-error="handleError"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-exceed="handleExceed"
      :class="{
        noUpload:limit!==0 && fileList.length>=limit
      }"
      :headers="{
        Authorization:token
      }"
    >
      <template slot="file" slot-scope="{file}">
        <slot name="file" :file="file">
          <img
            v-if="file.status !== 'uploading' && ['picture-card', 'picture'].indexOf(listType) > -1"
            class="gp-upload-list__item-thumbnail"
            :src="file.fileFullPath||file.url"
            alt
          >
          <a class="gp-upload-list__item-name" @click="handleClick(file)">
            <i class="el-icon-document" />
            {{ file.name }}
          </a>
          <label class="gp-upload-list__item-status-label">
            <i
              :class="{
                'el-icon-upload-success': true,
                'el-icon-circle-check': listType === 'text',
                'el-icon-check': ['picture-card', 'picture'].indexOf(listType) > -1
              }"
            />
          </label>
          <i v-if="!disabled" class="el-icon-close" @click="handleRemove (file)" />
          <i v-if="!disabled" class="el-icon-close-tip">按 delete 键可删除</i>
          <!--因为close按钮只在li:focus的时候 display, li blur后就不存在了，所以键盘导航时永远无法 focus到 close按钮上-->
          <el-progress
            v-if="file.status === 'uploading'"
            :type="listType === 'picture-card' ? 'circle' : 'line'"
            :stroke-width="listType === 'picture-card' ? 6 : 2"
            :percentage="parsePercentage(file.percentage)"
          />
          <span v-if="listType === 'picture-card'" class="gp-upload-list__item-actions">
            <span
              v-if="handlePictureCardPreview && listType === 'picture-card'"
              class="gp-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in" />
            </span>
            <span v-if="!disabled" class="gp-upload-list__item-delete" @click="handleRemove (file)">
              <i class="el-icon-delete" />
            </span>
          </span>
        </slot>
      </template>
      <i slot="default" class="el-icon-plus" />
      <p
        v-if="!construcitonTip"
        style="font-size: 12px;line-height: 1.4;color:#999;margin:4px 0;"
      >
        {{ limit |filterLimit }},{{ size |filterSize }}
      </p>
    </el-upload>
    <el-dialog title="图片预览" :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt>
    </el-dialog>
    <el-dialog title="图片裁剪" :visible.sync="cropperVisible" append-to-body destroy-on-close>
      <div style="position: relative;width: 100%;text-align: center;">
        <img ref="cropper" width="100%" :src="file.url" alt style="max-height: 400px;">
      </div>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" :loading="cropperLoading" @click="cropperSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'

import { getToken } from 'qifuyun-pc/src/utils/auth'
import emitter from 'qifuyun-pc/src/mixins/emitter'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
// import { Progress, Upload, Dialog, Button } from '@um/element-ui'
export default {
  name: 'MyUmUploadImg',
  // components: { [Progress.name]: Progress, [Upload.name]: Upload, [Dialog.name]: Dialog, [Button.name]: Button },
  filters: {
    filterLimit(val) {
      if (val == 0) {
        return '不限制数量'
      }
      return `最多${val}张`
    },
    filterSize(val) {
      if (val === 0) {
        return '不限制大小'
      }
      if (val < 1000) {
        return `单张大小限制${val}KB`
      }
      if (val < 1000 * 1000) {
        return `单张大小限制${val / 1000}M`
      }
      return '服务器受不了啊！'
    }
  },
  mixins: [emitter],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 支持剪切
    isCropper: {
      type: Boolean,
      default: false
    },
    // 剪切额外配置
    cropperOptions: {
      type: Object,
      default: () => {}
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    construcitonTip: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object, String],
      default: null
    },
    type: {
      type: String,
      default: 'img' // avatar
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 10000
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 简单模式
    simple: {
      type: Boolean,
      default: false
    },
    validateEvent: {
      type: Boolean,
      default: true
    },
    canvasOptions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      token: getToken(),
      accept: '.png,.jpg,.jpeg,.gif', // .xls,.xlsx
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [], // 文件列表
      // files: [],
      file: {}, // 当前选中的文件
      cropperVisible: false,
      cropper: null,
      source: null, // 取消接口方法
      cropperLoading: false // 裁剪上传loading
    }
  },
  computed: {
    uploadUrl() {
      // const baseURL = process.env.VUE_APP_BASE_URL
      const baseURL = process.env.VUE_APP_BASE_API
      if (/\/$/.test(baseURL)) {
        return baseURL + 'api/v1/file_manage/file/upload'
      } else {
        return baseURL + '/api/v1/file_manage/file/upload'
      }
    },
    autoUpload() {
      // 处理是否自动上传
      // 如果应用了裁剪属性，那就不会自动上传
      return !this.isCropper
    }
  },
  watch: {
    fileList(val) {
      console.log('fileList修改了', val)
      if (val === this.value || val[0] === this.value) {
        return false
      }
      if (val === '' || val === null || val === undefined || (Array.isArray(val) && !val.length)) {
        if (!this.value || (Array.isArray(this.value) && !this.value.length)) return false
        // 值为空的时候就不再提交事件
        this.$emit('change', null)
        this.dispatch('ElFormItem', 'el.form.change', [null])
        return false
      }
      const list = val.map((item) => { // 回显过来的数据 没有response 变相修改了fileList
        if (item.response) {
          item.url = item.response.data.filePath
          item.fileFullPath = item.response.data.fileFullPath
        }
        return { ...item }
      })
      // 简单模式处理
      if (this.simple) {
        if (this.limit === 1) {
          // 单选
          const result = list.length ? list[0].url : null
          if (result === this.value) return false
          this.$emit('change', result)
          this.dispatch('ElFormItem', 'el.form.change', [result])
        } else {
          // 多选
          const result2 = list.map((item) => item.url)

          // todo  value 为null
          if (this.value === null && result2.length === 0) return false

          if (this.value === null && result2.length !== 0) {
            this.$emit('change', result2)
            this.dispatch('ElFormItem', 'el.form.change', result2)
            return false
          }

          if (result2.length === this.value.length) {
            const like = result2.every((item, index) => item === this.value[index])
            if (like) return false
          }

          this.$emit('change', result2)
          this.dispatch('ElFormItem', 'el.form.change', result2)
        }
        return false
      }
      // 非简易模式，采用对象关联
      if (this.limit === 1) {
        // 单选
        const result = list.length ? list[0] : null
        this.$emit('change', result)
        this.dispatch('ElFormItem', 'el.form.change', [result])
      } else {
        // 多选
        if (this.value !== this.fileList) {
          this.$emit('change', list)
          this.dispatch('ElFormItem', 'el.form.change', list)
        }
      }
    },
    value: {
      handler(val) {
        console.log('val修改', val)
        if (val === '' || val === null || val === undefined || (Array.isArray(val) && !val.length)) {
          this.fileList = []
          return false
        }
        // 开启简单模式 只需要传入URL地址即可
        if (this.simple) {
          if (this.limit === 1) {
            // 单选
            if (this.fileList.length === 0 || val !== this.fileList[0].url) {
              // this.fileList[0] = { name: '', url: val }
              this.fileList = [{ name: val, url: val }]
            }
          } else {
            const like = this.fileList.every((item, index) => item.url === val[index])
            if (!like || !this.fileList.length) {
              this.fileList = val.map((item, index) => {
                return { name: index, url: item }
              })
            }
          }
          return false
        }
        // 非简易模式 采取关联
        if (this.limit === 1) {
          if (this.fileList.length === 0 || this.fileList[0].url !== val.url) {
            this.fileList = [val]
          }
        } else {
          if (this.fileList !== val) {
            this.fileList = val
          }
        }
      },
      immediate: true
    },
    cropperVisible(val) {
      // 取消请求
      if (!val) {
        this.source && this.source.cancel('用户取消!')
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 剪切上传
    cropperSubmit() {
      // 拿到canvas对象
      this.cropper.getCroppedCanvas({
        ...this.canvasOptions
      }).toBlob((blob) => {
        const formData = new FormData()
        // Pass the image file name as the third parameter if necessary.
        formData.append('files', blob, this.file.name)
        // 缺少一个全局loading
        this.cropperLoading = true
        // 生成取消请求函数
        const CancelToken = axios.CancelToken
        this.source = CancelToken.source()
        axios({
          url: this.uploadUrl,
          method: 'post',
          data: formData,
          cancelToken: this.source.token,
          headers: {
            Authorization: this.token
          },
          onUploadProgress: progressEvent => {
            // 进度条处理方法
          }
        }).then(d => {
          if (d.status === 200 && d.data.code === 3001) {
            const { fileName, fileFullPath, filePath } = d.data.data
            const file = {
              name: fileName,
              url: filePath,
              fileFullPath: fileFullPath
            }

            if (this.limit === 1) {
              this.fileList = [file] // 直接赋值
            } else {
              this.fileList.push(file)
            }
          } else {
            this.$message.error(d.data.msg)
          }
          // 关闭的时候直接销毁
          this.cropper.destroy()
          this.cropperVisible = false
          this.cropperLoading = false
          // 处理上传
        }).catch(e => {
          // 上传失败处理
          this.$message.error('上传失败!')
          this.cropperLoading = false
        })
      }/*, 'image/png' */)
    },
    // 监听文件变化
    /**
     *
     * @param file 当前上传的文件
     * @param fileList 所有上传的文件
     */
    handleChange(file, fileList) {
      if (this.isCropper) {
        const index = fileList.findIndex(item => item === file)
        fileList.splice(index, 1)
        this.file = file
        this.cropperVisible = true
        this.$nextTick(() => {
          this.cropper = new Cropper(this.$refs.cropper, {
            viewMode: 1,
            ...this.cropperOptions
          })
        })
      }
    },
    parsePercentage(val) {
      return parseInt(val, 10)
    },
    handleClick(file) {
      this.handlePictureCardPreview && this.handlePictureCardPreview(file)
    },
    handleRemove(file) {
      this.fileList.forEach((item, index) => {
        if (item.url === file.url) {
          this.fileList.splice(index, 1) // 删除
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.fileFullPath || file.url
      this.dialogVisible = true
    },

    beforeUpload(file) {
      const isJPG = file.type && file.type.indexOf('image') !== -1
      if (!isJPG) {
        this.$message.error('只能上传图片格式!')
        return false
      }
      if (this.size === 0) {
        return true
      }
      const isSize = file.size / 1024 < this.size
      if (!isSize) {
        this.$message.error('上传的文件大小不能超过10M!')
      }
      return isSize
    },
    handleSuccess(response, file, fileList) {
      if (response.code !== 3001) {
        this.$message.error(response.msg)
        // 上传失败之后移除图片
        this.fileList.splice(this.fileList.indexOf(file), 1)
        return false
      }
      if (this.limit === 1) {
        this.fileList = [file] // 直接赋值
      } else {
        this.fileList = fileList // 直接赋值
      }
      // 抛出上传成功的列表以及文件
      this.$emit('success', file, fileList)
    },
    handleError(err, file, fileList) {
      console.log(err, file, fileList)
      this.$message.error('上传失败!')
    },
    handleExceed(files, fileList) {
      // fileList.splice(0, 1, files[0])
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    }
  }
}
</script>
<style lang="scss">
.um-uploadImg {
  display: inline-block;
  & + & {
    margin-left: 20px;
  }
}
.avatar-uploader {
  line-height: 1;
  /*overflow: hidden;*/
  position: relative;
  font-size: 0;
  .avatar {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%,-50%);
    display: block;
    z-index: 2;
  }
  img{
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
<style lang="scss"  scoped>
::v-deep .noUpload .el-upload {
  display: none;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /*width: 100%;*/
  /*height: 100%;*/
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep
.el-upload-list--picture-card
.el-upload-list__item-actions
span
+ span {
  margin-left: 5px;
}
::v-deep .el-upload--picture-card {
  margin-bottom: 16px;
}
</style>
