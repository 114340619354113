/**
 * 全量枚举code值
 * 该字段会自动从接口读取
 * @type {{}}
 */
export const ENUM_CODE = new Map()

/**
 * 搜索条件集合
 * @type {{}}
 */
export const ENUM_PARAMS_CODE = new Map()
