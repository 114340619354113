<template>
  <div class="g-approve">
    <div class="radio-group">
      <div
        v-for="(item,index) in list"
        :key="index"
        :class="value===item.value?'active':''"
        class="radio-button"
        @click="value=item.value"
      >
        {{ item.label }}
      </div>
    </div>
    <div v-if="value===-1" :class="approveList.length===0?'':'list'" class="mt30">
      <div v-for="(item,index) in approveList" :key="index" class="item" @click="jumpApproveEle(item)">
        <div class="img border-r-6 flex-shrink-0">
          <img :src="item.appIcon" alt="">
        </div>
        <div class="title row_1">{{ item.appName }}</div>
      </div>
      <div v-if="approveList.length===0" class="m-noData centerData">
        暂无数据
      </div>
    </div>
    <el-form v-if="value!==-1" :inline="true" :model="searchForm" class="demo-form-inline mt30">
      <el-form-item label="审批搜索">
        <el-input v-model="searchForm.keyword" placeholder="请输入审批名称" />
      </el-form-item>
      <el-form-item label="时间筛选">
        <el-date-picker
          v-model="applyTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-button v-if="value==0" style="float: right;" type="primary" @click="getBatchProcessing()">批量处理</el-button>
      <el-button type="primary" style="float: right;margin-right:20px" icon="el-icon-search" @click="getApproveLcList">搜 索</el-button>
    </el-form>
    <template v-if="value!==-1">
      <el-table
        :data="tableData"
        stripe
        height="calc(100% - 170px)"
        style="width: 100%;"
      >
        <el-table-column label="审批名称" prop="recordName" :formatter="$formatterTable" />
        <el-table-column label="申请人" prop="userName" :formatter="$formatterTable" />
        <el-table-column label="发起时间" prop="startTime" :formatter="$formatterTable" />
<!--        <el-table-column label="完成时间" prop="endTime" :formatter="$formatterTable" />-->
        <el-table-column label="状态" prop="recordStatusCode">
          <template slot-scope="scope">
            <span
              :style="{
                color:statusColor(scope.row.recordStatusCode)
              }"
            >
              {{ scope.row.recordStatusCode | status }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="open(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <pagination
      v-if="value!==-1&&tableData.length"
      style="padding: 0 20px"
      :background="false"
      :layout="'total, sizes, prev, pager, next, jumper'"
      :limit.sync="searchForm.page.pageSize"
      :page.sync="searchForm.page.pageNum"
      :total="tableTotal"
      @pagination="getApproveLcList('page',1)"
    />
    <!-- 批量处理 -->
    <el-dialog
      :visible.sync="checkFlag"
      title="批量处理"
      width="827px"
      :append-to-body="false"
      @close="clearSelection()"
    >
      <el-form ref="handleSearchForm" :model="handleSearchForm" inline class="m-handleSearchForm">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="审批类型" prop="appUuid">
              <el-select v-model="handleSearchForm.appUuid" clearable @change="getApproveLcList('page',2)">
                <el-option v-for="(item,i) in approvalTypeList" :key="i" :value="item.appUuid" :label="item.flowName" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请人" prop="approveUserUuid">
              <div class="handleSearchForm-sponsor flex-between pointer mb8" style="padding:0px 12px" @click.stop="getRange" @mouseenter="getHover()" @mouseleave="getLeave()">
                <div v-if="approveUserList.length==0" class="sponsor-tip">请选择申请人</div>
                <div class="flex-between sponsor-check">
                  <UmBusUser
                    ref="chosepart"
                    v-model="approveUserList"
                    :show-tab="false"
                    :show-prefix-button="false"
                    :type="2"
                    :limit="1"
                    title="选择申请人"
                    @change="change($event)"
                  >
                    <template #user="{data}">
                      <span v-if="approveUserList.length>0" class="m_approveconfig_username">{{ data.map(item=>item.name).join(' , ') }}</span>
                    </template>
                  </UmBusUser>
                  <div class="pointer ml-10 " @click="getRange()">
                    <i class="el-icon-caret-bottom pointer s-gray" :class="!hideFlag?'s-show':'s-hide'" />
                    <i class="el-icon-circle-close pointer s-gray s-hide" :class="hideFlag?'s-show':'s-hide'" @click.stop="getDel()" />
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="发起时间">
              <el-date-picker
                v-model="originatingTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="getApproveLcList('page',2)"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        ref="multiTable"
        :data="checkTable"
        stripe
        style="width: 100%;"
        :row-key="getRowKeys"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="65"
        />
        <el-table-column label="审批名称" prop="recordName" :formatter="$formatterTable" />
        <el-table-column label="申请人" prop="userName" :formatter="$formatterTable" />
        <el-table-column label="发起时间" prop="startTime" :formatter="$formatterTable" />
      </el-table>
      <pagination
        :background="false"
        :layout="'total, sizes, prev, pager, next, jumper'"
        :limit.sync="handleSearchForm.page.pageSize"
        :page.sync="handleSearchForm.page.pageNum"
        :total="tableTotal"
        @pagination="getApproveLcList('page',2)"
      />
      <div class="text-center mt30">
        <el-button type="primary" @click="getBatchConsent()">
          全部同意({{ multipleSelection.length||0 }})
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  enterpriseFlowGetAllFlowApp,
  enterpriseFlowRecordsGetApprovalRecords,
  batchConsent
}
from '@/api/dashboard'
import { enterprisFlowGetAppFlow } from '@/api/enterprise'
const STATUS_COLOR = {
  1: '#3D80FC',
  2: '#36C35A',
  3: '#FF6161',
  4: '#9FAABC'
}
export default {
  name: 'Approve',

  filters: {
    status(val) {
      let name = ''
      switch (val) {
        case 1:
          name = '审核中'
          break
        case 2:
          name = '审批同意'
          break
        case 3:
          name = '审批驳回'
          break
        case 4:
          name = '审批撤销'
          break
      }
      return name
    }
  },
  data() {
    return {
      approveUserList: [],
      STATUS_COLOR,
      list: [
        { label: '发起审批', value: -1 },
        { label: '待审批', value: 0 },
        { label: '已处理', value: 1 },
        { label: '抄送我的', value: 2 },
        { label: '已提交', value: 3 }
      ],
      tableTotal: 0,
      searchForm: {
        keyword: '',
        applyStartTime: null,
        applyEndTime: null,
        queryType: null,
        approveUserUuid: '',
        appUuid: '',
        page: {
          pageNum: 1,
          pageSize: 10
        }
      },
      // isShow: true,
      visibleAdd: false,
      visibleReturn: false,
      visible: false,
      value: -1,
      approveList: [],
      tableData: [],
      lists: [],
      drawerInfo: {
        operateCodeList: [],
        formInfo: {
          formJson: []
        }
      },
      appFlowRecordUuid: '',
      taskId: '',
      applyTime: [],
      checkFlag: false,

      batchConsentList: [],
      checkTable: [],
      multipleSelection: [],
      originatingTime: [],
      handleSearchForm: {
        keyword: '',
        applyStartTime: null,
        applyEndTime: null,
        appUuid: '',
        approveUserUuid: '',
        queryType: null,
        page: {
          pageNum: 1,
          pageSize: 10
        }
      },
      approvalTypeList: [],
      hideFlag: false

    }
  },
  watch: {
    applyTime(val) {
      if (val) {
        this.searchForm.applyStartTime = val[0]
        this.searchForm.applyEndTime = val[1]
      } else {
        this.searchForm.applyStartTime = null
        this.searchForm.applyEndTime = null
      }
    },
    originatingTime(val) {
      if (val) {
        this.handleSearchForm.applyStartTime = val[0]
        this.handleSearchForm.applyEndTime = val[1]
      } else {
        this.handleSearchForm.applyStartTime = null
        this.handleSearchForm.applyEndTime = null
      }
    },

    value(val) {
      if (val !== -1) {
        this.getApproveLcList()
      }
    }
  },
  created() {
    this.getApproveList()
  },

  methods: {
    getHover() {
      if (this.approveUserList.length > 0) {
        this.hideFlag = true
      } else {
        this.hideFlag = false
      }
    },
    getLeave() {
      if (this.approveUserList.length > 0) {
        this.hideFlag = false
      } else {
        this.hideFlag = false
      }
    },
    // 删除申请人
    getDel() {
      this.approveUserList = []
    },
    statusColor(val) {
      let color = ''
      switch (val) {
        case 1:
          color = '#3D80FC'
          break
        case 2:
          color = '#36C35A'
          break
        case 3:
          color = '#FF6161'
          break
        case 4:
          color = '#9FAABC'
          break
      }
      return color
    },
    // 批量处理
    getBatchProcessing() {
      this.checkFlag = true
      this.getApproveLcList('page', 2)
      enterprisFlowGetAppFlow().then(res => {
        this.approvalTypeList = res.data
      })
    },
    // 发起审批列表
    getApproveList() {
      const load = this.$load()
      enterpriseFlowGetAllFlowApp({}).then(res => {
        this.approveList = res.data
        load.close()
      }).catch(e => {
        load.close()
      })
    },
    // 待审批---》已提交列表
    getApproveLcList(state = '', type) {
      if (state !== 'page') {
        this.searchForm.page.pageNum = 1
        this.handleSearchForm.page.pageNum = 1
      }
      const load = this.$load()
      let params = {
        ...this.searchForm,
        queryType: this.value
      }
      if (type == 2) {
        params = {
          ...this.handleSearchForm,
          queryType: this.value

        }
      }

      enterpriseFlowRecordsGetApprovalRecords(params).then(res => {
        load.close()
        if (type == 2) {
          this.checkTable = res.data.list
          this.tableData = res.data.list
        } else {
          this.tableData = res.data.list
        }
        // this.tableData = res.data.list
        this.tableTotal = res.data.page.total
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    open(item) {
      this.appFlowRecordUuid = item.appFlowRecordUuid
      this.taskId = item.taskId
      this.$router.push({ path: '/dashboard/approve/info', query: { appFlowRecordUuid: this.appFlowRecordUuid, taskId: this.taskId }})
    },
    jumpApproveEle(item) {
      // this.isShow = false
      this.$router.push({
        path: '/dashboard/approve/add',
        query: { appUuid: item.appUuid, appName: item.appName, appIcon: item.appIcon }
      })
    },
    // 批量同意
    getBatchConsent() {
      batchConsent(this.batchConsentList).then(res => {
        this.$message.success('操作成功')
        this.getApproveLcList('page', 2)
        this.checkFlag = false
        this.clearSelection()
      })
    },
    clearSelection() {
      this.$nextTick(() => {
        this.$refs.multiTable.clearSelection()
      })
      this.handleSearchForm.page = {
        pageNum: 1,
        pageSize: 10
      }
      this.$refs.handleSearchForm.resetFields()
      this.originatingTime = []
      this.approveUserList = []
    },
    // 选中的审批
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.batchConsentList = val.map(item => {
        return {
          files: [],
          taskId: item.taskId,
          approvalRemark: '',
          appFlowRecordUuid: item.appFlowRecordUuid
        }
      })
    },
    getRowKeys(row) {
      return row.taskId
    },
    getRange() {
      this.$refs.chosepart.openModal()
    },
    change(val, i) {
      this.handleSearchForm.approveUserUuid = val[0] || ''
      this.getApproveLcList('page', 2)
    }

  }
}
</script>

