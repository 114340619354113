<template>
  <EnterpriseLayout>
    <div class="m_enterprise_home">
      <div class="m_enterprise_home_infobox">
        <div class="m_person_info">
          <div class="m_person_info_img">
            <el-image
              style="width: 100%; height: 100%;border-radius: 3px"
              :src="avatar"
              fit="fit"
            >
              <div slot="error" class="image-slot">
                <!--                <i class="el-icon-user-solid" />-->
                <div class="m_person_info_imgnodata" />
              </div>
            </el-image>
          </div>
          <div class="m_person_info_duty">
            <div class="m_person_info_name row_1">{{ name||'-' }}，你好！</div>
            <div v-if="personinfo.adminFlag||personinfo.founderFlag" class="u_dutys row_1">
              你是企业的 <span v-if="personinfo.adminFlag">管理者</span>
              <span v-if="personinfo.founderFlag"><span v-if="personinfo.adminFlag">/</span>创建者</span>
            </div>
          </div>
        </div>
        <div class="m_enterprise_info">
          <div class="m_enterprise_numbers ">
            <span class="u_numbers_digital">{{ personinfo.orgUserNumber||'0' }}</span>
            <span class="u_numbers_title">企业人数</span>
          </div>
          <div class="m_separation" />
          <div class="m_enterprise_numbers">
            <span class="u_numbers_digital">{{ personinfo.orgOrganNumber||'0' }}</span>
            <span class="u_numbers_title">组织数</span>
          </div>
        </div>
        <div class="m_notification">
          <div class="m_notification_infotop">
            <span class="m_notification_infoicons" />
            <span class="m_notification_infotitle">消息通知</span>
          </div>
          <div v-if="!infolist.length" class="m_notification_lines" />
          <div v-for="(item,index) in infolist" :key="index">
            <div class="m_notification_infobody" @click="checkinfo(item)">
              <div class="m_notification_infotxt">
                有新用户申请加入企业，赶快去处理吧！
              </div>
              <div class="m_notification_infobtn" />
            </div>
            <div class="m_notification_lines" />
          </div>
        </div>
      </div>
      <div class="m_enterprise_home_rightbox">
        <div class="m_enterprise_contents">
          <div class="m_enterprise_rtop">
            <div class="m_enterprise_centop">
              <span class="m_enterprise_topicon" />
              <span class="m_enterprise_toptitle">快速上手优客云</span>
            </div>
            <div class="m_enterprise_home_step">
              <el-steps :active="4" align-center>
                <el-step title="下载优客云APP" icon="el-icon-edit">
                  <template #icon>
                    <div class="m_enterpris_esteps" />
                  </template>
                  <template #title>
                    <div class="m_enterprise_steps_text">下载优客云APP</div>
                  </template>
                  <template #description>
                    <div class="m_enterprise_steps_description">
                      <span class="stepstexts" @click="downapp">立即下载</span>
                    </div>
                  </template>
                </el-step>
                <el-step title="完善通讯录" icon="el-icon-edit">
                  <template #icon>
                    <div class="m_enterpris_esteps" />
                  </template>
                  <template #title>
                    <div class="m_enterprise_steps_text">完善通讯录</div>
                  </template>
                  <template #description>
                    <div class="m_enterprise_steps_description">
                      <span class="stepstexts" @click="()=>{$router.push('/enterprise/address')}">立即去完善</span>
                    </div>
                  </template>
                </el-step>
                <el-step title="邀请用户加入" icon="el-icon-edit">
                  <template #icon>
                    <div class="m_enterpris_esteps" />
                  </template>
                  <template #title>
                    <div class="m_enterprise_steps_text">邀请用户加入</div>
                  </template>
                  <template #description>
                    <div class="m_enterprise_steps_description">
                      <span class="stepstexts" @click="invitations">立即邀请</span>
                    </div>
                  </template>
                </el-step>
                <el-step title="使用企业应用" icon="el-icon-edit">
                  <template #icon>
                    <div class="m_enterpris_esteps" />
                  </template>
                  <template #title>
                    <div class="m_enterprise_steps_text">使用企业应用</div>
                  </template>
                  <template #description>
                    <div class="m_enterprise_steps_description">
                      <span class="stepstexts" @click="()=>{$router.push('/cloudMarket')}">进入应用市场</span>
                    </div>
                  </template>
                </el-step>
              </el-steps>
            </div>
          </div>
          <div class="m_enterprise_rtop">
            <div class="m_enterprise_centop">
              <span class="m_enterprise_topicon" />
              <span class="m_enterprise_toptitle">常用入口</span>
            </div>
            <div class="m_enterprise_commonenter">
              <div v-permission="['112030103']" class="m_enterprise_contentbox" @click="()=>{$router.push('/enterprise/address/add')}">
                <div class="m_enterprise_enterimg" />
                <div class="m_enterprise_entertxt">添加用户</div>
              </div>
              <div v-if="false" class="m_enterprise_contentbox">
                <div class="m_enterprise_enterimg m_enterprise_enterimg1" />
                <div class="m_enterprise_entertxt">批量导入通讯录</div>
              </div>
              <div v-permission="['112030104']" class="m_enterprise_contentbox" @click="()=>{$router.push('/enterprise/notice/add')}">
                <div class="m_enterprise_enterimg m_enterprise_enterimg2" />
                <div class="m_enterprise_entertxt">发布公告</div>
              </div>
            </div>
          </div>
        </div>
        <div class="m_enterprise_infos">
          <el-drawer
            title="加入企业"
            :visible.sync="infovisible"
            direction="rtl"
            size="30%"
            @close="$refs.ruleForm.resetFields()"
          >
            <div slot="title"><span class="m_enterprise_dheader">加入企业</span></div>
            <div class="m_enterprise_contents">
              <div v-loading="drawerloading" class="m_enterprise_centercontent">
                <!--                <el-scrollbar style="height: 100%">-->
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm">
                  <el-form-item label="" prop="userName">
                    <div class="m_enterprise_values">
                      <div slot="label">姓名：</div>
                      <div class="m_enterpriser_rtxt">{{ ruleForm.nickName||'-' }}</div>
                    </div>
                  </el-form-item>
                  <el-form-item label="" prop="userMobile">
                    <div class="m_enterprise_values">
                      <div slot="label">手机号：</div>
                      <div class="m_enterpriser_rtxt">{{ ruleForm.userMobile||'-' }}</div>
                    </div>
                  </el-form-item>
                  <el-form-item label="用户角色" prop="orgRoleId" style="border-bottom: 1px dashed #909DB6;padding-bottom: 20px;">
                    <el-cascader
                      v-model="ruleForm.orgRoleId"
                      placeholder="请选择角色"
                      :options="classifyList"
                      filterable
                      :show-all-levels="false"
                      :props="{
                        children: 'roleList',
                        label: 'roleName',
                        value: 'orgRoleId',
                        emitPath: false
                      }"
                    />
                  </el-form-item>
                  <el-form-item label="部门" prop="departmentList" style="border-bottom: 1px dashed #909DB6;padding-bottom: 20px">
                    <span class="m_enterpriser_parment">
                      <UmBusUser ref="chosepart" v-model="ruleForm.departmentList" :type="1" title="选择成员所在部门" :show-prefix-button="false" @result="resultChange">
                        <template #user="{data}">
                          <span v-if="data&&data.length" class="itemcontent">
                            <span v-for="item in data" :key="item.id" class="m_enterpriser_texts" @mouseover="mouseover(item.id)" @mouseout="mouseout">
                              <div class="m_enterpriser_mains" :class="{m_enterpriser_itemactive:item.id==maincode,m_enterpriser_mainss:item.id==choseid}" @click="setmain(item)" />
                              {{ item.name }}
                              <span class="m_enterpriser_delicon" :class="{m_enterpriser_delicons:item.id==choseid}" @click="delmain(item)" />
                            </span>
                            <span class="m_enterpriser_xiugai" @click="changpart">修改</span>
                          </span>
                          <span v-else>
                            <span class="m_enterpriser_weishezhi">未设置</span>
                            <span class="m_enterpriser_xiugai" @click="changpart">修改</span>
                          </span>

                        </template>
                      </UmBusUser>
                    </span>
                  </el-form-item>
                  <el-form-item label="部门负责人" prop="departmentHead" style="border-bottom:1px dashed #909DB6;padding-bottom: 20px">
                    <span v-if="departmentHead.length">
                      <el-checkbox-group v-model="ruleForm.departmentHead">
                        <el-checkbox v-for="item in departmentHead" :key="item.id" :label="item.id">
                          {{ item.name }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </span>
                    <span v-else style="border-radius: 4px;border: 1px solid #E4E8EB;color: #31415F;padding: 7px 10px">未设置</span>
                  </el-form-item>
                  <el-form-item label="" prop="userPosition">
                    <!--                <el-input v-model="ruleForm.userName" placeholder="请填写姓名" maxlength="10" />-->
                    <div class="m_enterprise_values">
                      <div slot="label">职务：</div>
                      <div class="m_enterpriser_rtxt m_enterpriser_txt1">
                        <el-input v-model="ruleForm.userPosition" placeholder="请填写职务" maxlength="10" />
                      </div>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class=" m_enterprise_drawbtn">
              <div class="m_enterprise_btncancel" @click="submitForm">拒绝申请</div>
              <div class="m_enterprise_btnagree" @click="saveForm">确认添加</div>
            </div>
          </el-drawer>
        </div>
      </div>
      <Qrcode :invitation.sync="invitation" :title="title" :type="type" @changevisible="changevisible" />
    </div>
  </EnterpriseLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  get_front_page_data,
  // get_Invite_users_qr_code,
  get_pending_audit_user_list,
  get_pending_audit_user_info,
  get_org_role_by_org_business_id,
  audit_submit
} from '@/api/enterprise'
import Qrcode from '@/views/enterprise/address/components/qrcode'
export default {
  name: 'EnterpriseHome',
  components: { Qrcode },
  props: {},
  data() {
    return {
      drawerloading: false, // 右弹窗loading
      infolist: [], // 左侧消息列表
      classifyList: [], // 角色列表
      puUserUuid: '', // 待审核人员uuid
      qrloading: false,
      qrCodeUrl: '', // 邀请二维码
      InviteUrl: '', // 邀请链接
      title: '扫码快速加入企业',
      type: 1,
      downLoad: '',
      infovisible: false,
      // codeApi: null,
      parameter: {},
      departmentHead: [],
      maincode: '', // 主部门
      choseid: null,
      invitation: false,
      invitation1: false,
      personinfo: {
        adminFlag: false, // 是否管理员
        founderFlag: false, // 是否创建者
        orgUserNumber: '',
        orgOrganNumber: ''
      },
      ruleForm: {
        nickName: '', // 用户姓名
        userMobile: '', // 用户手机号
        departmentHead: [], // 部门负责人
        departmentList: [], // 所属组织
        orgRoleId: '', // 用户角色
        userPosition: '' // 用户职务
      },
      rules: {
        orgRoleId: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        departmentList: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    'ruleForm.departmentList': {
      handler(val) {
        if (val.length) {
          this.maincode = val[0]
        }
      },
      deep: true
    }
  },
  created() {
    this.get_front_page_datas()
    this.get_pending_audit_user_lists()
    this.get_classify_tree()
  },
  computed: {
    ...mapGetters([
      'permission_routes',
      'avatar',
      'name',
      'userPosition',
      'enterpriseUuid',
      'enterpriseName',
      'enterpriseLogoImg'
    ])
  },
  mounted() {},
  methods: {
    changevisible(val) {
      if (!val) {
        this.invitation = false
      } else {
        this.invitation = true
      }
    },
    // 获取角色树
    get_classify_tree() {
      get_org_role_by_org_business_id().then(res => {
        this.classifyList = res.data.list
      })
    },
    get_front_page_datas() {
      const load = this.$load()
      get_front_page_data().then(d => {
        this.personinfo = d.data
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    // 获取消息通知列表
    get_pending_audit_user_lists() {
      get_pending_audit_user_list().then(d => {
        this.infolist = d.data
      }).finally(_ => {

      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    resultChange(val) {
      this.departmentHead = val
    },
    changpart() {
      this.$refs.chosepart.openModal()
    },
    checkinfo(val) {
      this.infovisible = true
      this.puUserUuid = val
      this.get_pending_audit_user_infos()
    },
    // 获取申请详情
    get_pending_audit_user_infos() {
      this.drawerloading = true
      get_pending_audit_user_info({
        puUserUuid: this.puUserUuid
      }).then(d => {
        this.ruleForm.nickName = d.data.nickName
        this.ruleForm.userMobile = d.data.userMobile
      }).finally(_ => {
        this.drawerloading = false
      }).catch(e => {
        this.drawerloading = false
        this.$errorHandle(e)
      })
    },
    downapp() {
      this.type = 2
      this.title = '手机扫码下载APP'
      this.invitation = true
      // this.qrCodeUrl = require('@/assets/enterprise/download-app.png')
      // this.InviteUrl = 'https://www.pgyer.com/rnU7'
    },
    invitations() {
      this.type = 1
      this.title = '扫码快速加入企业'
      this.invitation = true
      // this.codeApi = get_Invite_users_qr_code
      // this.invite()
    },
    // 拒绝申请
    submitForm() {
      this.drawerloading = true
      audit_submit({
        auditFlag: false,
        puUserUuid: this.puUserUuid
      }).then(d => {
        this.infovisible = false
        this.get_pending_audit_user_lists()
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).finally(_ => {
        this.drawerloading = false
      }).catch(e => {
        this.drawerloading = false
        this.$errorHandle(e)
      })
    },
    // 确认添加
    saveForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.drawerloading = true
          audit_submit({
            auditFlag: true,
            puUserUuid: this.puUserUuid,
            userPosition: this.ruleForm.userPosition,
            departmentList: this.ruleForm.departmentList,
            departmentHead: this.ruleForm.departmentHead,
            orgRoleId: this.ruleForm.orgRoleId,
            primaryOrganUuid: this.maincode
          }).then(d => {
            this.infovisible = false
            this.get_pending_audit_user_lists()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.get_front_page_datas()
          }).finally(_ => {
            this.drawerloading = false
          }).catch(e => {
            this.drawerloading = false
            this.$errorHandle(e)
          })
        }
      })
    },
    // 设置主部门
    setmain(val) {
      this.maincode = val.id
    },
    delmain(val) {
      // if (this.maincode == val.id) {
      const index = this.ruleForm.departmentList.findIndex(item => item == val.id)
      if (index >= 0) {
        this.ruleForm.departmentList.splice(index, 1)
      }
      // this.maincode = ''
      // this.choseid = ''
      // }
    },
    mouseover(id) {
      this.choseid = id
    },
    mouseout() {
      this.choseid = null
    },
    // 邀请用户
    // invite() {
    //   // this.invitation = true
    //   // if (!this.parameter.length) return
    //   this.qrloading = true
    //   get_Invite_users_qr_code({
    //     organUuid: this.enterpriseUuid
    //   }).then(d => {
    //     this.qrCodeUrl = d.data.qrCodeUrl || ''
    //     this.InviteUrl = d.data.inviteUrl || ''
    //     //
    //   }).finally(_ => {
    //     this.qrloading = false
    //   })
    // },
    // 复制链接提示
    successFn() {
      this.$message.success('复制成功')
    },
    // 关闭邀请弹窗
    close() {
      // this.$emit('update:invitation', false)
      this.invitation = false
      this.qrCodeUrl = ''
      this.InviteUrl = ''
      this.get_pending_audit_user_lists()
    }

  }
}
</script>

<style lang="scss" scoped></style>
