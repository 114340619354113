<template>
  <div class="m-cloudMarket">
    <div class="m-cloudMarket_top">
      <div ref="tabs" class="tabs">
        <div class="tab" :class="{active:tab===1}" @click="()=>tab=1">为你推荐</div>
        <div class="tab" :class="{active:tab===2}" @click="()=>tab=2">应用中心</div>
        <div class="tab" :class="{active:tab===3}" @click="()=>tab=3">解决方案</div>
        <div class="tab" :class="{active:tab===4}" @click="()=>tab=4">服务中心</div>
        <!--        <div class="tab" :class="{active:tab===5}" @click="()=>tab=5">硬件商城</div>-->
        <div class="tab" :class="{active:tab===5}" @click="()=>tab=5">商品商城</div>
        <div class="link-bar" :style="`transform: translate3d(${offsetLeft+offsetWidth/2}px, 0px, 0px);`" />
      </div>
      <div class="u-cart pointer" @click="getShopping()">
        <!--        <svg-icon icon-class="shoppingCart" style="font-size:16px;margin-right:2px" />-->
        <!--        购物车(0)-->
        <ShopCarMarket />
      </div>
    </div>
    <div class="m-cloudMarket_market">
      <div v-if="tab===1">
        <recommend />
      </div>
      <div v-if="tab===2">
        <applicationCenter />
      </div>
      <div v-if="tab===3">
        <solution />
      </div>
      <div v-if="tab===4">
        <serviceCentre />
      </div>
      <div v-if="tab===5">
        <hardwareMall />
      </div>
    </div>
  </div>
</template>

<script>
import applicationCenter from './applicationCenter'
import solution from './solution'
import serviceCentre from './serviceCentre'
import hardwareMall from './hardwareMall'
import recommend from './recommend'

export default {
  name: 'CloudMarket',
  components: { applicationCenter, solution, serviceCentre, hardwareMall, recommend },
  data() {
    return {
      tab: 1, // 头部栏默认
      offsetWidth: 56,
      offsetLeft: 0
      // offsetLeft: 106
    }
  },
  computed: {},
  watch: {
    tab(val) {
      this.$nextTick(_ => {
        const $active = this.$refs.tabs.getElementsByClassName('active')
        if ($active && $active.length) {
          const offsetWidth = $active[0].offsetWidth
          const offsetLeft = $active[0].offsetLeft
          this.offsetWidth = offsetWidth
          this.offsetLeft = offsetLeft
        }
      })
    }

  },
  created() {},
  mounted() {
    this.tab = this.$route.query.tabType * 1 || 1
    console.log(this.tab)
  },
  methods: {
    getShopping() {
      this.$router.push({ path: '/cloudMarket/shoppingCart' })
    }
  }
}
</script>

