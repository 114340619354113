import Vue from 'vue'
import { PopupManager } from '@um/element-ui/lib/utils/popup'
import UImport from './src/main'
const ImportConstructor = Vue.extend(UImport) // 直接将Vue组件作为Vue.extend的参数

let instance
const instances = []
let seed = 1

const ImportFile = (options) => {
  options = options || {}
  const id = 'import_' + seed++
  instance = new ImportConstructor({
    data: {
      ...options
    }
  }) // 实例化
  instance.id = id
  instance.$mount()
  document.body.appendChild(instance.$el)
  // instance.visible = true
  instance.$el.style.zIndex = PopupManager.nextZIndex()
  instances.push(instance)
  // instance.$on('change'
  // return instance
  return new Promise((resolve, reject) => {
    instance.$on('success', val => {
      resolve(val)
    })
    instance.$on('fail', val => {
      reject(val)
    })
  })
}

export default {
  install(Vue) {
    Vue.prototype.$import = ImportFile
  }
}
