<template>
  <div class="register">
    <div
      v-if="step==1"
      class="back"
      @click="backlogin"
    >
      <span class="backicon" /><span>返回登录</span>
    </div>
    <div
      v-else-if="step!=1"
      class="back textRight"
      @click="getLogOut"
    >
      <span>退出登录</span>
    </div>
    <div class="title">
      <div class="bigtitle">{{ step==3?'请完善个人信息！':'欢迎注册优客云' }}</div>
      <div class="smalltitle">{{ step==3?'请输入真实姓名': step==1?'请验证手机号':'请设置登录密码' }}</div>
    </div>
    <div
      v-if="step==1"
      class="form"
    >
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        autocomplete="on"
        label-position="left"
      >
        <el-form-item prop="userMobile">
          <div class="logintel">
            <el-input
              ref="userMobile"
              v-model="loginForm.userMobile"
              placeholder="请输入手机号"
              name="userMobile"
              type="text"
              tabindex="1"
              maxlength="11"
              autocomplete="on"
              clearable
            />
          </div>
        </el-form-item>

        <el-form-item prop="imgCode">
          <el-input
            ref="imgCode"
            v-model="loginForm.imgCode"
            placeholder="请输入图形验证码"
            name="imgCode"
            tabindex="2"
            maxlength="4"
            autocomplete="off"
            :clearable="false"
            @keyup.enter.native="getSubmitRegister"
          >
            <template slot="suffix">
              <div class="imgstyle">
                <img
                  :src="imgCode"
                  width="110"
                  height="42"
                  alt=""
                  @click="getLoginValiteImg"
                >
              </div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item prop="smsCode">
          <el-input
            ref="smsCode"
            v-model="loginForm.smsCode"
            type="text"
            :clearable="false"
            placeholder="请输入短信验证码"
            name="smsCode"
            tabindex="2"
            autocomplete="off"
            maxlength="6"
            @keyup.enter.native="getSubmitRegister"
          />
          <div class="verificationcode">
            <span>|</span>
            <el-button
              type="text"
              :disabled="flagd"
              style="width: 75px"
              @click="getCaptchad"
            >
              {{ contentd }}
            </el-button>
          </div>
        </el-form-item>
        <div class="shadow">
          <!-- <el-button
            :loading="loading"
            type="primary"
            style="width:100%;margin-top:10px;"
            :disabled="disabled"
            @click.native.prevent="handleLogin"
          >
            下一步
          </el-button> -->
          <div class="xieyi">
            <el-checkbox v-model="checked" />
            勾选同意<span class="btext" @click="agreeVisible = true">《优客云用户协议》</span>
          </div>
          <div class="zhucebtn">
            <el-button
              type="primary"
              class="shadow-button"
              @click="getSubmitRegister"
            >
              立即注册
            </el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div
      v-else-if="step==2"
      class="inpassword"
    >
      <el-form
        ref="secretForm"
        :model="secretForm"
        :rules="secretFormRules"
        class="login-form"
        autocomplete="on"
        label-position="left"
      >
        <el-tooltip
          v-model="capsTooltip"
          content="大写已开启"
          placement="right"
          manual
        >
          <el-form-item prop="password">
            <el-input
              ref="password"
              v-model="secretForm.password"
              type="password"
              placeholder="请输入登录密码"
              name="password"
              tabindex="2"
              autocomplete="off"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
            />
          </el-form-item>
        </el-tooltip>
        <el-tooltip
          v-model="capsTooltip1"
          content="大写已开启"
          placement="right"
          manual
        >
          <el-form-item>
            <el-input
              ref="password"
              v-model="secretForm.password1"
              type="password"
              placeholder="请再次输入登录密码"
              name="password"
              tabindex="2"
              autocomplete="off"
              @keyup.native="checkCapslock1"
              @blur="capsTooltip1 = false"
            />
            <span
              v-if="nosame"
              class="el-icon-warning errortext"
            >两次密码不一致</span>
          </el-form-item>
        </el-tooltip>
      </el-form>

      <div class="zhucebtn">
        <el-button
          type="primary"
          class="shadow-button"
          @click="getSetPassword"
        >
          完成设置
        </el-button>
      </div>
    </div>
    <div
      v-else
      class="realname"
    >
      <div class="realform">
        <el-form
          ref="relnameForm"
          :model="relnameForm"
          :rules="relnameFormRules"
          class="login-form"
          autocomplete="on"
          label-position="left"
        >
          <el-form-item prop="name">
            <el-input
              v-model="relnameForm.name"
              placeholder="请输入真实姓名"
              name="name"
              tabindex="2"
              autocomplete="off"
              maxlength="15"
              clearable
              @enter.native.prevent="goInfo"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="realbtn">
        <el-button
          type="primary"
          class="shadow-button"
          @click="goInfo"
        >
          立即进入
        </el-button>
      </div>
    </div>
    <el-dialog title="优客云用户协议" :visible.sync="agreeVisible" top="5vh" width="900px">
      <div style="height: 78vh">
        <el-scrollbar style="height: 100%;overflow-x: hidden">
          <um-url :url="argeeHtmlUrl" />
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  register_message_code, identify_code, register_submit, completeInfo, submitRegister,
  setPassword, getInfo, getPublicKey
} from '@/api/user'
import JSEncrypt from 'jsencrypt'
export default {
  name: 'Register',
  components: {},
  props: {
    registerForm: {
      type: Object,
      default: () => ({})
    },
    publicKey: {
      type: String,
      default: ''
    },
    msgcode: {
      type: [String, Number],
      default: ''
    }

  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error('请输入短信验证码'))
      } else {
        callback()
      }
    }
    const vPassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码不小于6位数'))
      } else {
        callback()
      }
    }

    // const validateName = (rule, value, callback) => {
    //   const reg = /^[\u2E80-\u9FFF]+$/// Unicode编码中的汉字范围
    //   if (value && value.length > 0) {
    //     if (!reg.test(value)) {
    //       callback(new Error('姓名输入不合法'))
    //     } else {
    //       callback()
    //     }
    //   } else if (value.length == 0) {
    //     callback()
    //   } else {
    //     callback(new Error('姓名输入不合法'))
    //   }
    // }
    const validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写姓名'))
      } else if (!/^[\u4E00-\u9FA5A-Za-z0-9·]+$/.test(value)) {
        callback(new Error('姓名包含了·以外的特殊字符!'))
      } else {
        callback()
      }
    }
    return {
      checked: false, // 同意用户协议
      capsTooltip: false,
      capsTooltip1: false,
      step: 1, // 注册步骤
      changeTitle: '请输入真实姓名',
      loginForm: {
        userMobile: '',
        smsCode: '',
        imgCode: '',
        imgUuid: ''
      },
      secretForm: {
        password: '',
        password1: ''
      },
      relnameForm: {
        name: ''
      },
      loginRules: {
        userMobile: [{ required: true, trigger: 'change', validator: validateUsername }],
        smsCode: [{ required: true, trigger: 'change', validator: validatePassword }],
        imgCode: [{ required: true, trigger: 'change', message: '请输入验证码' }]
      },
      secretFormRules: {
        password: [{ required: true, trigger: 'change', validator: vPassword }],
        password1: [{ required: true, trigger: 'change', validator: vPassword }]
      },
      relnameFormRules: {
        name: [{ required: true, trigger: 'change', message: '请输入真实姓名' },
          { required: true, trigger: 'change', validator: validateName }]
      },
      loading: false,
      redirect: undefined,
      otherQuery: {},
      imgCode: null, // 验证码
      flagd: false,
      contentd: '发送验证码',
      totalTimed: 60,
      agreeVisible: false,
      userMobile: ''
    }
  },
  computed: {
    argeeHtmlUrl() {
      return location.origin + process.env.VUE_APP_BASE_URL + '/argee.html'
    },
    nosame() {
      return this.secretForm.password !== this.secretForm.password1
    },
    disabled() {
      return !(this.loginForm.userMobile && this.loginForm.smsCode && this.loginForm.imgCode)
    }
  },
  watch: {
    // 'secretForm.password1': {
    //   handler(val) {
    //     if (val != this.secretForm.password) {
    //       this.nosame = true
    //     } else {
    //       this.nosame = false
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  mounted() {
    if (this.loginForm.userMobile === '') {
      if (this.$refs.userMobile) {
        this.$refs.userMobile.focus()
      }
    }
    // 获取图形验证码
    this.getLoginValiteImg()
    // 获取公钥
    // this.getPublicKey()
  },
  methods: {
    openAgree() {

    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    checkCapslock1(e) {
      const { key } = e
      this.capsTooltip1 = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    // 返回登录
    async backlogin() {
      await this.$store.dispatch('user/logout')
      this.$emit('login')
    },
    // 退出登录
    async getLogOut() {
      await this.$store.dispatch('user/logout')
      this.$emit('login')
      this.step = 1
    },
    /**
     * 获取图形验证码
     */
    getLoginValiteImg() {
      identify_code().then(res => {
        const { imgCode, imgUuid } = res.data
        this.imgCode = imgCode
        this.loginForm.imgUuid = imgUuid
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          // this.loading = true
          this.changeTitle = '请设置登录密码'
          this.step = 2
        //   this.$store.dispatch('user/login', { userInfo: this.loginForm, type: 'tel', method: sms_login })
        //     .then(() => {
        //       this.$router.push({ path: this.redirect || '/dashboard', query: this.otherQuery })
        //       this.loading = false
        //     })
        //     .catch(() => {
        //       this.getLoginValiteImg()
        //       this.loading = false
        //     })
        // } else {
        //
        //   return false
        }
      })
    },
    // 获取短信验证码
    getCaptchad() {
      this.flagd = true
      // this.contentd = this.totalTimed + 's后重新发送'
      this.contentd = this.totalTimed + 's'
      const clock = window.setInterval(() => {
        this.totalTimed--
        // this.contentd = this.totalTimed + 's后重新发送'
        this.contentd = this.totalTimed + 's'
        if (this.totalTimed < 0) {
          window.clearInterval(clock)
          // this.contentd = '重新发送验证码'
          this.contentd = '发送验证码'
          this.totalTimed = 60
          this.flagd = false
        }
      }, 1000)
      register_message_code({
        userMobile: this.loginForm.userMobile,
        imgCode: this.loginForm.imgCode,
        imgUuid: this.loginForm.imgUuid
      }).then(res => {
        this.$message.success('短信验证码获取成功，请注意查收！')
      }).catch(e => {
        // this.$errorHandle(e)
        this.getLoginValiteImg()
        window.clearInterval(clock)
        // this.contentd = '重新发送验证码'
        this.contentd = '发送验证码'
        this.totalTimed = 60
        this.flagd = false
      })
    },
    // 注册页面提交注册
    getSubmitRegister() {
      if (this.nosame) return
      if (!this.checked) return this.$message.warning('请勾选优客云用户协议')
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          submitRegister({ smsCode: this.loginForm.smsCode, userMobile: this.loginForm.userMobile }).then(res => {
            const { token } = res.data
            this.$store.commit('user/SET_TOKEN', token)
            this.$message.success('注册成功')
            this.step = 2
          }).catch(e => {
            this.getLoginValiteImg()
          })
        }
      })
    },
    // 获取公钥
    // getPublicKey() {
    //   getPublicKey({}).then(res => {
    //     this.publicKey = res.data
    //   }
    //   )
    // },
    // 完成设置
    async  getSetPassword() {
      const res = await getInfo()
      this.$nextTick(() => {
        this.$refs.secretForm.validate(valid => {
          if (valid) {
            console.log(this.nosame)
            if (this.nosame) {
              this.$message.error('两次密码不一致，请重新设置')
            } else {
              this.encryptor = new JSEncrypt()
              this.encryptor.setPublicKey('-----BEGIN PUBLIC KEY-----' + this.publicKey + '-----END PUBLIC KEY-----')
              console.log(this.loginForm.userMobile)
              console.log(this.registerForm.userMobile)
              const password = this.encryptor.encrypt(this.secretForm.password)
              const userMobile = this.encryptor.encrypt(this.registerForm.userMobile)// 验证码登录账号
              const newUserMobile = this.encryptor.encrypt(this.loginForm.userMobile)// 注册账号
              setPassword({
                password: password,
                userMobile: userMobile || newUserMobile
              }).then(res => {
                this.$message.success('设置成功')
                this.step = 3
              })
            }
          }
        })
      })
    },
    // 立即进入
    goInfo() {
      this.$refs.relnameForm.validate(valid => {
        if (valid) {
          // this.loading = true
          completeInfo({
            userName: this.relnameForm.name
          }).then(res => {
            this.$emit('registerSuccess')
            this.$refs.relnameForm.resetFields()
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.register{
  .back{
    display: flex;
    align-content: center;
    cursor: pointer;
    color:#3D80FC ;
    font-size: 14px;
    &.textRight{
      width: 100%;
      display: block;
      text-align: right;
    }
    .backicon{
      width: 16px;
      height: 16px;
      background: url("~@/assets/login/back.png") no-repeat 0 0 / 16px 16px;
    }
  }

  .title{
    margin: 40px auto 30px;
    width: 176px;
    text-align: center;
    .bigtitle{
      color: #31415F;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }
    .smalltitle{
      margin-top: 10px;
      text-align: center;
      color: #6C7B96;
      font-size: 14px;

    }
  }
  .form{
    $bg:#fff;
    $dark_gray:#889aa4;
    $light_gray:#000;
    ::v-deep .el-input__inner {
  height: 50px;
  line-height: 50px;
}
::v-deep .el-button--primary {
  height: 46px;
  background: linear-gradient(45deg, #4069F4 0%, #5F86F8 100%);
  border-radius: 8px;
  &:hover {
    opacity: 0.7;
  }
  &.is-disabled{
    opacity: 0.5;
  }
}
.show-pwd {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
.verificationcode {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  > span {
    color: #4B6CF9;
    margin-right: 13px;
  }
  ::v-deep .el-button--text{
    color: #3D80FC;
    font-size: 14px;
  }
}
.shadow{
  ::v-deep .el-button{
    box-shadow: 0px 5px 10px 2px #B1D2FF;
    border-radius: 6px;
  }
}
.imgstyle{
  img{
    cursor: pointer;
    vertical-align: bottom;
    background: rgba(228, 232, 235, 0.5);
    border-radius: 4px;
    margin: 4px 0 4px 0;
  }
}
::v-deep .el-form-item{
  margin-bottom: 20px;
}
  //.logintel{
  //::v-deep .el-input__inner {
  //height: 46px;
  //line-height: 46px;
  //}
  //  }
  }
  .inpassword{
    ::v-deep .el-input__inner {
      height: 50px;
      line-height: 50px;
  }}
  ::v-deep .el-form-item{
  margin-bottom: 20px;
  .errortext{
    margin: 0 0 0 121px;
    width: 118px;
    color: #FF6161;
    font-size: 14px;
  }
}
.xieyi{
  color: #6C7B96;
  font-size: 14px;
  .btext{
    cursor: pointer;
    color: #3D80FC;
  }
  ::v-deep .el-checkbox__inner{
    border-radius: 100%;
  }
}
}
  .zhucebtn{
    margin: 16px 0 0 0;
  }
  .realname{
    .realform{
        ::v-deep .el-input__inner {
      height: 50px;
      line-height: 50px;
  }
    }
    .realbtn{
      margin-top: 30px;
    }
  }
</style>
