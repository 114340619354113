<template>
  <div class="m_enterprise_activityinfo detailsBox">
    <div class="go_back_common">
      <div class="go_back_title" @click="back">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="m_activityinfo_formBody">
      <div class="flex-between">
        <div class="title-dh mb20 mt20">基本信息</div>
        <div>
          <el-button type="primary" class="m_activityinfo_btndelete" @click="del">
            <span class="m_activityinfo_deleaction" />
            删除活动
          </el-button>
          <el-button type="primary" class="m_activityinfo_btnedit mr20" @click="edit">编辑</el-button>
        </div>
      </div>
      <div class="m_activityinfo_info">
        <div class="m_activityinfo_info-item">
          <div class="m_activityinfo_label">活动图片：</div>
          <div class="m_activityinfo_value">
            <el-image
              style="width: 120px; height: 90px;border-radius: 4px"
              :src="info.activityImageUrl"
              fit="fit"
              :preview-src-list="[info.activityImageUrl]"
            />
          </div>
        </div>
        <div class="m_activityinfo_info-item">
          <div class="m_activityinfo_label">活动名称：</div>
          <div class="m_activityinfo_value">{{ info.activityName }}</div>
        </div>
        <div class="m_activityinfo_info-item">
          <div class="m_activityinfo_label">活动地点：</div>
          <div class="m_activityinfo_value">{{ info.activityAddress }}</div>
        </div>
        <div class="m_activityinfo_info-item">
          <div class="m_activityinfo_label">报名时间：</div>
          <div class="m_activityinfo_value">{{ info.activitySignStartTime|parseTime('{y}-{m}-{d} {h}:{i}') }} ~ {{ info.activitySignEndTime|parseTime('{y}-{m}-{d} {h}:{i}') }}</div>
        </div>
        <div class="m_activityinfo_info-item">
          <div class="m_activityinfo_label">活动时间：</div>
          <div class="m_activityinfo_value">{{ info.activityStartTime|parseTime('{y}-{m}-{d} {h}:{i}') }} ~ {{ info.activityEndTime|parseTime('{y}-{m}-{d} {h}:{i}') }}</div>
        </div>
        <div class="m_activityinfo_info-item">
          <div class="m_activityinfo_label">报名人数：</div>
          <div class="m_activityinfo_value">{{ info.signUserMaxNum }}</div>
        </div>
        <div class="m_activityinfo_info-item" style="align-items: flex-start">
          <div class="m_activityinfo_label">详细内容：</div>
          <div class="m_activityinfo_value" style="flex-grow: 1">
            <um-url :url="info.activityContestUrl" />
          </div>
        </div>
      </div>
    </div>
    <div class="title-dh mb20 mt20">报名用户({{ info.signUserNum }})</div>
    <div class="m_activityinfo_user">
      <UmBusUser :value="userUuids" :type="2" :show-prefix-button="false">
        <template #user="{data}">
          <span v-if="data&&data.length">
            <div class="m_user_itemtop">
              <div v-for="it in data" :key="it.id" class="m_user_item">
                <div class="m_user_item_img">
                  <img :src="it.icon" alt="">
                  <div class="m_user_item_title row_1">{{ it.name }}</div>
                </div>
              </div>
            </div>
          </span>
          <div v-else class="m_user_nodata">
            <span class="m_user_nodata_title">暂无数据</span>
          </div>
        </template>
      </UmBusUser>
    </div>
    <div style="padding-right: 20px">
      <pagination
        :background="false"
        :layout="'total, sizes, prev, pager, next, jumper'"
        :limit.sync="page.pageSize"
        :page.sync="page.pageNum"
        :total="tableTotal"
        @pagination="getUserInfo('page')"
      />
    </div>
  </div>
</template>

<script>
import { activityDelete_activity, activityQuery_activity_info, activityQuery_sign_user } from '@/api/enterprise'

export default {
  name: 'EnterpriseApplicationActivityInfo',
  components: {},
  props: {},
  data() {
    return {
      activityUuid: '',
      info: {},
      detail: '',
      userUuids: [],
      tableTotal: 0,
      page: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.activityUuid = this.$route.query.activityUuid
    if (this.activityUuid) {
      this.getInfo()
      this.getUserInfo()
    }
  },
  mounted() {},
  methods: {
    getInfo() {
      activityQuery_activity_info({ activityUuid: this.activityUuid }).then(res => {
        this.info = res.data
      })
    },
    getUserInfo() {
      activityQuery_sign_user({ activityUuid: this.activityUuid, page: this.page }).then(d => {
        console.log('366999+++++++++')
        // this.userUuids = d.data.list
        const arr = []
        d.data.list.forEach(item => arr.push(item.userUuid))
        this.userUuids = arr
        this.tableTotal = d.data.page.total
      })
    },
    back() {
      this.$router.replace({ path: '/activity', query: { appUuid: this.$route.query.appUuid }})
    },
    del() {
      const h = this.$createElement
      this.$confirm('', {
        message: h('div', null, [
          h('p', { style: 'color:#31415F;fontSize: 16px;fontWeight: 500;' }, '是否确定删除？')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        customClass: 'message-dialog'
      }).then(() => {
        const load = this.$load()
        activityDelete_activity({ activityUuid: this.activityUuid }).then(res => {
          this.$message({ type: 'success', message: '删除成功!' })
          load.close()
          this.back()
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        // this.$message({ type: 'info', message: '取消操作' })
      })
    },
    edit() {
      this.$router.push({ path: '/activity/update', query: { activityUuid: this.activityUuid, appUuid: this.$route.query.appUuid }})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
