<template>
  <div class="g-order">
    <el-drawer
      title="确认订单信息"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="order_centerBox">
        <addressCheck v-if="addressFlag" :use-address-info="useAddressInfo" @getApply="getApply" />
        <div class="centerBox_label twoLabel flex-column-between " :class="addressFlag?'':'orderTopBorder'">
          <div class="flex-column-center">
            <div class="u-label">组织：</div>
            <!-- <div>{{ orderInfo.organName }}</div> -->
            <div>{{ $store.getters.enterpriseName }}</div>
          </div>
          <div class="flex-column-center">
            <div class="u-label">规格：</div>
            <div>{{ specificationName }}</div>
          </div>
          <div class="flex-column-center">
            <div class="u-label">价格：</div>
            <div class="flex-column-center"> <span class="money-icon">¥</span> <div class="u-money">{{ money }}</div>元</div>
          </div>
        </div>
        <div class="centerBox_num flex-between mt10 ">
          <div class="u-quantity">购买数量</div>
          <div>
            <el-input-number
              v-model="num"
              :precision="0"
              size="small"
              class="num"
              :min="1"
              style="width:82px"
              @change="handleChange"
            />
          </div>
        </div>
        <div class="flex-column-center mb10 mt30" style="margin-left: -4px;">
          <div class="flex-column-center"><img src="@/assets/dashboard/paymentMethod.png" alt=""></div>
          <div class="u-permission">获取权限</div>
        </div>
        <p class="u-jurisdiction">
          获取通讯录信息 <br>
          获取通讯录内用户信息 <br>
          获取个人姓名、头像信息 <br>
        </p>
      </div>
      <div class="order_bottomBox orderTwo">
        <div class="bottomBox_agreement">
          <el-checkbox v-model="flag" :label="true" class="radio">  <div class="ml10 s-grayColor">同意<a class="s-blueColor" @click="dialogFlag=true">优客云用户使用协议、隐私协议</a></div></el-checkbox>
        </div>
        <div class="bottomBox_btnBox mb10">
          <el-button class="u-btn" type="primary" @click="getBuyNow()">
            立即购买
          </el-button>
        </div>
      </div>
    </el-drawer>
    <to-be-paid ref="payment" :ordtype="2" :drawer-flag="drawerFlag" :platform-order-number="platformOrderNumber" :business-order-uuid="businessOrderUuid" :tab-type="tabType" @changeShow="changeShow" />
    <agreement :dialog-flag="dialogFlag" @agreementFlag="agreementFlag" />
  </div>
</template>
<script>
import ToBePaid from './toBePaid.vue'
import { getDefaultAddress } from '@/api/cloudMarket'
import { math } from '@/utils/math'
import agreement from './agreement.vue'
import addressCheck from './address.vue'
export default {
  name: 'ConfirmOrderTwo',
  components: { ToBePaid, agreement, addressCheck },
  props: {
    orderTwoFlag: {
      type: Boolean,
      default: false
    },
    addressFlag: {
      type: Boolean,
      default: false
    },
    orderInfo: {
      type: Object,
      default: () => {}
    },
    price: {
      type: [String, Number],
      default: 0
    },
    specificationName: {
      type: String,
      default: ''

    },
    businessOrderUuid: {
      type: String,
      default: ''
    },
    platformOrderNumber: {
      type: String,
      default: ''
    },
    tabType: {
      type: [String, Number],
      default: 1
    },
    // 库存
    stock: {
      type: [String, Number],
      default: 0
    }

  },
  data() {
    return {
      drawer: false,
      department: null,
      maincode: '',
      choseid: null,
      flag: false,
      // num: 0,
      num: 1,
      drawerFlag: false,
      addressList: [],
      useAddressInfo: {},
      money: 0,
      dialogFlag: false
    }
  },
  computed: {

  },

  watch: {
    orderTwoFlag: {
      handler(val) {
        this.drawer = val
      },
      immediate: true
    },
    platformOrderNumber: {
      handler(val) {
        // eslint-disable-next-line vue/no-mutating-props
        if (val) this.platformOrderNumber = val
      },
      immediate: true
    }
  },
  mounted() {
    // this.getDefaultAddress()
  },
  methods: {

    // 关闭抽屉
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.$emit('changeShow', 'false')
          this.flag = false
          this.num = 0
          this.money = 0
        })
        .catch(_ => {})
    },
    // 数量变化
    handleChange() {
      const a = Number(this.num)
      const b = Number(this.price)
      this.money = math.multiply(a, b)
      //   :max="tabType === 4 ? 999 : stock"
      if (this.tabType === 4 && this.num > 999) {
        this.$nextTick(() => {
          this.num = 999
          this.money = math.multiply(this.num, Number(this.price))
        })
        this.$message({
          message: '库存不足',
          type: 'warning'
        })
      }
      if (this.tabType !== 4 && this.num > this.stock) {
        this.$nextTick(() => {
          this.num = this.stock
          this.money = math.multiply(Number(this.num), Number(this.price))
        })
        this.$message({
          message: '库存不足',
          type: 'warning'
        })
      }
    },
    // 勾选协议
    getCheck() {
      this.flag = !(this.flag)
    },
    // 立即购买
    async getBuyNow() {
      console.log(this.flag)
      if (this.flag === true) { // 勾选协议
        if (this.addressFlag === true) {
          if (this.useAddressInfo && this.useAddressInfo.addressUuid) {
            if (this.num > 0) {
              const load = this.$load()
              try {
                await this.$parent.submitOrder(this.num, this.useAddressInfo.addressUuid)
              } catch {
                load.close()
                return false
              }
              this.drawerFlag = true
              this.$refs.payment.getQueryPaymentMethod()
              this.$refs.payment.getPayOrderInfo()
              load.close()
            } else {
              this.$message.error('购买数量不能为0')
            }
          } else {
            this.$message.error('请选择收货地址')
          }
        } else if (this.num > 0) {
          await this.$parent.submitOrder(this.num, this.useAddressInfo.addressUuid)
          this.drawerFlag = true
          this.$refs.payment.getQueryPaymentMethod()
          this.$refs.payment.getPayOrderInfo()
        } else {
          this.$message.error('购买数量不能为0')
        }
      } else {
        this.$message.error('请先勾选协议')
      }
    },
    changeShow(data) {
      if (data === 'false') {
        this.drawerFlag = false
      } else {
        this.drawerFlag = true
      }
    },
    // 默认地址
    getDefaultAddress() {
      getDefaultAddress().then(res => {
        this.useAddressInfo = res.data || {}
      })
    },
    // 使用地址
    getApply(item) {
      this.useAddressInfo = item
    },
    // 关闭协议
    agreementFlag(data) {
      if (data === 'false') {
        this.dialogFlag = false
      } else {
        this.dialogFlag = true
      }
    },
    // 关闭弹框
    getClose() {
      this.$emit('changeShow', 'false')
      this.flag = false
      this.num = 0
      this.money = 0
      this.$parent.getDetail()
    }
  }
}
</script>

