<template>
  <div class="m_enterprise_approveconfig detailsBox">
    <UmApproveEle :app-uuid="appUuid" />
    <div class="m_approveconfig_content">
      <el-scrollbar style="height: 100%">
        <el-button class="button_32" type="primary" @click="add"><span class="m_approveconfig_addicon" /> 创建审批</el-button>
        <div v-for="(item,index) in tableData" :key="index" class="m_approveconfig_table mt20">
          <div class="m_approveconfig_type">
            <img class="m_approveconfig_iconimg border-r-6" :src="item.flowIcon" alt="">
            <div class="m_approveconfig_title">{{ item.flowName }}</div>
          </div>
          <div class="m_approveconfig_user">
            <span>可见范围</span>
            <um-bus-user ref="userManager" v-model="item.flowUsePurview.values" :show-tab="false" :show-prefix-button="false" :type="3">
              <template #user="{data}">
                <span class="m_approveconfig_username">{{ data.map(item=>item.name).join(' , ') }}</span>
              </template>
            </um-bus-user>
          </div>
          <div class="m_approveconfig_date">
            <span>最后更新</span>
            <span>{{ item.updateTime }}</span>
          </div>
          <div class="m_approveconfig_config">
            <div class="m_approveconfig_copy" @click="copy(item)">复用</div>
            <div class="m_approveconfig_edit" @click="edit(item)">编辑</div>
            <div v-if="item.showDisable===0" :class="item.statusCode===0?'m_approveconfig_close':item.statusCode===1?'m_approveconfig_open':''" @click="closeOrOpen(item)">
              {{ item.statusCode===0?'停用':item.statusCode===1?'启用':'--' }}
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <el-dialog
      class="m_approveconfig_dialog"
      width="560px"
      :visible.sync="visible"
      title=""
      :append-to-body="false"
    >
      <div>
        <div class="m_dialog_choose_zdy" @click="$router.push('/flow/config')">
          <div class="m_dialog_zdy_add" />
          <div class="m_dialog_zdy_txt">自定义创建</div>
        </div>
        <div v-if="eleList&&eleList.length>0" class="m_dialog_contentchoose mt20 mb20">
          系统模板
        </div>
        <div class="m_dialog_list">
          <div v-for="(item,index) in eleList" :key="index" class="m_dialog_list_item" @click="$router.push({path:'/flow/config',query:{modelUuid:item.modelUuid}})">
            <img class="m_dialog_list_item_icon border-r-6" :src="item.modelIcon" alt="">
            <div class="m_dialog_title">{{ item.modelName }}</div>
          </div>
        </div>
        <div class="m_dialog_close" @click="close">关闭</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  enterprisFlowGetAppFlow,
  enterprisFlowEnableOrDisable,
  enterpriseFlowConfigCopyFlow,
  flowConfigSystemGetSystemTem, applicationEp_app_manageUpdate_disable_flag
} from '@/api/enterprise'
import { mapGetters } from 'vuex'
export default {
  name: 'EnterpriseApplicationConfig',
  data() {
    return {
      visible: false,
      user: [],
      appUuid: '',
      info: {},
      tableData: [],
      eleList: []
    }
  },

  created() {
    this.appUuid = this.$route.query.appUuid

    this.getList()
  },
  methods: {
    back() {
      this.$router.back()
    },
    getList() {
      const load = this.$load()
      enterprisFlowGetAppFlow({}).then(res => {
        load.close()
        this.tableData = res.data
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    add() {
      this.visible = true
      this.getConfigEle()
    },
    getConfigEle() {
      const load = this.$load()
      flowConfigSystemGetSystemTem({}).then(res => {
        this.eleList = res.data
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    edit(item) {
      this.$router.push({ path: '/flow/config', query: { appUuid: item.appUuid }})
    },
    copy(item) {
      const load = this.$load()
      enterpriseFlowConfigCopyFlow({ appUuid: item.appUuid }).then(res => {
        this.getList()
        this.$message({ type: 'success', message: '操作成功' })
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    close() {
      this.visible = false
    },
    closeOrOpen(item) {
      const title = item.statusCode === 0 ? '停用' : '启用'
      const disabledFlag = item.statusCode === 0 ? 1 : 0
      const h = this.$createElement
      this.$confirm('', {
        message: h('div', null, [
          h('p', { style: 'color:#31415F;fontSize: 16px;fontWeight: 500;' }, `是否确定${title}？`)
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        customClass: 'message-dialog'

      }).then(() => {
        const load = this.$load()
        applicationEp_app_manageUpdate_disable_flag({ appUuid: item.appUuid, disabledFlag: disabledFlag }).then(res => {
          this.$message({ type: 'success', message: `${title}成功!` })
          load.close()
          this.getList()
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message({ type: 'info', message: '取消操作' })
      })
    }

  }
}
</script>

<style scoped lang="scss">
</style>
