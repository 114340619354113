<template>
  <el-card shadow="never" class=" m_tender_addedit min_h_100 mar_auto_w1260">
    <div class="go_back_common">
      <div class="go_back_title" @click="$router.replace('/administration')">
        <span class="go_back_icon" />
        <span class="go_back_txt">标段管理</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <el-form
      ref="updateForm"
      :model="updateForm"
      :rules="rules"
      class="demo-ruleForm"
      @submit.native.prevent
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="项目名称" prop="busProjectUuid">{{ buildingForm.projectName||'-' }}</el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="标段名称" prop="projectParaName">
            <el-input
              v-model="updateForm.projectParaName"
              style="width:388px;"
              clearable
              maxlength="50"
              placeholder="请输入标段名称，最多50字"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" class="tender_scope">
          <el-form-item ref="busBuildingIdList" label="楼栋范围" prop="busBuildingIdList">
            <div class="m_tender_building">
              <el-button class="tender_scope_left" type="text" @click="visible=true"> 请选择</el-button>
              <div v-if="tagList.length" class="m_tender_scopebgc">
                <span v-for="(item,index) in tagList" :key="index" class="m_tender_scope_item">
                  <span class="m_tender_scope_itemname">{{ item.buildingName }}</span>
                  <span class="m_tender_scope_del el-icon-close" @click="closeTag(item.busBuildingId)" />
                </span>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="m_tender_scope_btn">
        <el-button type="primary" @click="submitForm('updateForm')">保 存</el-button>
        <!--        <el-button @click="resetForm('updateForm')">重 置</el-button>-->
      </div>
    </el-form>
    <el-dialog
      title="选择公区/楼栋"
      :visible.sync="visible"
      width="80%"
      class="m_tender_dialog"
      :append-to-body="false"
      @close="$refs.buildingForm.resetFields()"
    >
      <el-form ref="buildingForm" :model="buildingForm" label-width="120px">
        <el-form-item label="项目名称" prop="busProjectUuid">{{ buildingForm.projectName||'-' }}</el-form-item>
        <el-form-item label="可选公区/楼栋" prop="list">
          <div v-if="busBuildingList&&busBuildingList.length">
            <el-checkbox
              v-model="buildingForm.checkAll"
              :indeterminate="isIndeterminate"
              @change="handleCheckAllChange"
            >
              全选
            </el-checkbox>
          </div>
          <div v-if="busBuildingList&&busBuildingList.length" class="u_checkbox_bg">
            <el-checkbox-group
              v-model="buildingForm.list"
              @change="handleCheckedChange"
            >
              <el-checkbox
                v-for="(item,index) in busBuildingList"
                :key="index"
                :label="item.busBuildingId"
              >
                {{ item.buildingName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <span v-else>暂无数据</span>
        </el-form-item>
        <el-form-item label="其他标段占用">
          <el-checkbox-group disabled>
            <el-checkbox
              v-for="(item,index) in busBuildingListOff"
              :key="index"
              :label="item.busBuildingId"
            >
              {{ item.buildingName }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" plain @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="addBuilding">保 存</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { project_para_add_update, project_para_building, project_para_info } from '@/api/mainData/tender.js'
export default {
  name: 'MainDataTenderUpdate',
  // components: { tree },
  props: {},
  data() {
    return {
      busProjectUuid: '',
      busProjectParaUuid: '',
      updateForm: {
        busProjectParaUuid: '',
        projectParaName: '',
        busBuildingIdList: []
      },
      rules: {
        projectParaName: [
          { required: true, message: '请输入标段名称', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
          }
        ]
      },
      visible: false,
      buildingForm: {
        // 弹框数据
        busProjectUuid: '',
        projectName: '',
        checkAll: false,
        list: []
      },
      busBuildingList: [],
      busBuildingListOff: [],
      isIndeterminate: false
    }
  },
  computed: {
    tagList() {
      return this.busBuildingList.filter((item) => {
        console.log(item, 'item++++++++++++++++++')
        return this.updateForm.busBuildingIdList.some(
          (item2) => item2 === item.busBuildingId
        )
      })
    }
  },
  created() {
    // if (!this.$route.query.busProjectParaUuid) {
    //   this.$router.go(-1)
    //   return false
    // }
    this.busProjectParaUuid = this.$route.query.busProjectParaUuid
    this.updateForm.busProjectParaUuid = this.$route.query.busProjectParaUuid
    this.getInfo()
  },
  mounted() {},
  methods: {
    getInfo() {
      project_para_info({
        busProjectParaUuid: this.busProjectParaUuid
      }).then((d) => {
        const projectParaData = d.data.projectParaData
        const busBuildingIdList = d.data.projectParaData.busBuildingIdList

        this.updateForm.projectParaName = projectParaData.projectParaName
        this.busProjectUuid = projectParaData.busProjectUuid
        this.buildingForm.busProjectUuid = projectParaData.busProjectUuid
        this.buildingForm.busProjectName = projectParaData.busProjectName

        this.updateForm.busBuildingIdList = busBuildingIdList || []
        this.buildingForm.list = busBuildingIdList || []
        this.handleCheckedChange(busBuildingIdList) // 处理选中问题
        this.getBuilding()
      })
    },
    // 获取楼栋信息
    getBuilding() {
      project_para_building({
        busProjectUuid: this.busProjectUuid,
        busProjectParaUuid: this.busProjectParaUuid
      }).then((d) => {
        this.busBuildingList =
          d.data.busBuildingList === null
            ? null
            : d.data.busBuildingList.filter((item) => !item.readOnlyFlag)
        this.busBuildingListOff =
          d.data.busBuildingList === null
            ? null
            : d.data.busBuildingList.filter((item) => item.readOnlyFlag)
        this.buildingForm.busProjectUuid = d.data.busProjectUuid
        this.busProjectUuid = d.data.busProjectUuid
        this.buildingForm.projectName = d.data.projectName
        this.handleCheckedChange(this.buildingForm.list) // 处理选中问题
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const load = this.$load()
          project_para_add_update({
            ...this.updateForm,
            busProjectUuid: this.busProjectUuid
          })
            .then((d) => {
              load.close()
              this.$message.success('编辑成功')
              this.$router.push({ path: '/mainData/tender' })
            })
            .catch((e) => {
              load.close()
              this.$errorHandle(e)
            })
        } else {
          this.$message.error('请填写正确的表单！')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$refs.buildingForm.resetFields()
    },
    addBuilding() {
      this.updateForm.busBuildingIdList = this.buildingForm.list
      this.visible = false
    },
    handleCheckAllChange(val) {
      this.buildingForm.list = val
        ? this.busBuildingList.map((item) => item.busBuildingId)
        : []
      this.isIndeterminate = false
    },
    handleCheckedChange(value) {
      console.log(value, '+-+-+-+-+-+-')
      const checkedCount = value.length
      this.buildingForm.checkAll = checkedCount === this.busBuildingList.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.busBuildingList.length
    },
    closeTag(busBuildingId) {
      this.updateForm.busBuildingIdList = this.updateForm.busBuildingIdList.filter(
        (item) => {
          return item !== busBuildingId
        }
      )
      this.buildingForm.list = this.updateForm.busBuildingIdList.filter(
        (item) => {
          return item !== busBuildingId
        }
      )
      this.handleCheckedChange(this.updateForm.busBuildingIdList)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
