<template>
  <div class="login_tel">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <el-form-item prop="userMobile">
        <div class="logintel">
          <el-input
            ref="userMobile"
            v-model.trim="loginForm.userMobile"
            placeholder="请输入手机号"
            name="userMobile"
            type="text"
            tabindex="1"
            maxlength="11"
            autocomplete="on"
            clearable
          />
        </div>
      </el-form-item>

      <div style="margin: 10px 0">
        <el-form-item v-show="msgcodes == 3020" prop="imgCode">
          <el-input
            ref="imgCode"
            v-model.trim="loginForm.imgCode"
            placeholder="请输入图形验证码"
            name="imgCode"
            tabindex="2"
            :clearable="false"
            maxlength="4"
            autocomplete="off"
          >
            <template slot="suffix">
              <div class="imgstyle">
                <img
                  :src="imgCode"
                  width="110"
                  height="42"
                  alt=""
                  @click="getLoginValiteImg"
                >
              </div>
            </template>
          </el-input>
        </el-form-item>
      </div>

      <el-form-item prop="smsCode">
        <el-input
          key="passwordType"
          ref="smsCode"
          v-model.trim="loginForm.smsCode"
          type="text"
          placeholder="请输入短信验证码"
          name="smsCode"
          :clearable="false"
          tabindex="2"
          autocomplete="off"
          maxlength="6"
        />
        <div class="verificationcode">
          <span>|</span>
          <el-button
            type="text"
            :disabled="flagd"
            style="width: 75px"
            @click="getCaptchad"
          >
            {{ contentd }}
          </el-button>
        </div>
      </el-form-item>
      <div class="shadow">
        <el-button
          :loading="loading"
          type="primary"
          style="width:100%;margin-top:10px;"
          :disabled="disabled"
          @click.native.prevent="handleLogin"
        >
          登录
        </el-button>
      </div>
      <!--      <div class="ctop">-->
      <!--        <el-checkbox v-model="defaultFlag">七天免登陆</el-checkbox>-->
      <!--      </div>-->
      <!--      <div class="bottom_info" style="text-align: right;margin-top: 20px;">-->
      <!--        <el-button type="text" @click="$router.push('/register')">用户注册</el-button>-->
      <!--      </div>-->
    </el-form>
  </div>
</template>

<script>
import { sms_login, login_message_code, identify_code, intoEnterprise, getPublicKey } from '@/api/user'
import JSEncrypt from 'jsencrypt'
// import { deepClone } from 'qifuyun-pc/lib/utils/utils'
import { deepClone } from '@/utils'
export default {
  name: 'LoginTel',
  props: {
    publicKey: {
      type: String,
      default: ''
    },
    msgcode: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('请输入短信验证码'))
      } else {
        callback()
      }
    }
    return {
      // defaultFlag: true,
      msgcodes: '',
      loginForm: {
        userMobile: '',
        smsCode: '',
        imgCode: '',
        imgUuid: ''
      },
      loginRules: {
        userMobile: [{ required: true, trigger: 'change', validator: validateUsername }],
        smsCode: [{ required: true, trigger: 'change', validator: validatePassword }],
        imgCode: [{ required: false, trigger: 'change', message: '请输入验证码' }]
      },
      loading: false,
      redirect: undefined,
      otherQuery: {},
      imgCode: null, // 验证码
      flagd: false,
      contentd: '发送验证码',
      totalTimed: 60,
      encryptor: null
    }
  },
  computed: {
    disabled() {
      if (this.msgcodes == 3020) {
        return !(this.loginForm.userMobile && this.loginForm.smsCode && this.loginForm.imgCode)
      } else {
        return !(this.loginForm.userMobile && this.loginForm.smsCode)
      }
    }
  },
  watch: {
    msgcode: {
      handler(val) {
        console.log(val, 'val')
        this.msgcodes = val
      },
      immediate: true
    }
    // defaultFlag: {
    //   handler(val) {
    //     console.log(val, 'val')
    //     sessionStorage.setItem('setNoLogin', val)
    //   },
    //   immediate: true
    // }
  },
  created() {
    // this.msgcodes = this.msgcode
  },
  mounted() {
    if (this.loginForm.userMobile === '') {
      this.$refs.userMobile.focus()
    }
    // 获取图形验证码
    this.getLoginValiteImg()
    // 获取公钥
    // this.getPublicKey()
    // this.msgcodes = this.msgcode
  },
  methods: {
    /**
     * 获取图形验证码
     */
    getLoginValiteImg() {
      identify_code().then(res => {
        const { imgCode, imgUuid } = res.data
        this.imgCode = imgCode
        this.loginForm.imgUuid = imgUuid
      })
    },
    // 获取公钥
    // getPublicKey() {
    //   getPublicKey({}).then(res => {
    //     this.publicKey = res.data
    //     this.msgcode = res.code
    //   }
    //   ).finally(d => {

    //   }).catch(e => {
    //     this.msgcode = e.code
    //   })
    // },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          const loginForm = deepClone(this.loginForm)
          if (this.publicKey != '') {
            this.encryptor = new JSEncrypt()
            this.encryptor.setPublicKey('-----BEGIN PUBLIC KEY-----' + this.publicKey + '-----END PUBLIC KEY-----')
            loginForm.userMobile = this.encryptor.encrypt(this.loginForm.userMobile)
          } else {
            this.$message.error('获取验证失败，请刷新页面重试')
            return false
          }

          this.$store.dispatch('user/login', { userInfo: loginForm, type: 'tel', method: sms_login })
            .then((d) => {
              this.loading = false
              this.$store.dispatch('user/getStatus').then(res => {
                if (!res.accelerated) { // 未完善个人信息
                  this.$emit('perfectInfo', this.loginForm)
                  return
                }
                if (!res.enterpriseDefaultFlag) { // 未选择默认企业
                  this.$emit('choseEnterprise')
                  return
                }
                const load = this.$load()
                intoEnterprise({
                  enterpriseUuid: res.defaultEnterpriseUuid,
                  defaultFlag: 1
                }).then(res => {
                  this.$router.push({ path: this.redirect || '/im', query: this.otherQuery })
                }).catch(_ => {
                  this.$store.dispatch('user/resetToken')
                }).finally(_ => {
                  load.close()
                })
              })
            })
            .catch((e) => {
              if (e.code == 3020) {
                this.msgcodes = e.code
                this.$emit('changemsgcode', e.code)
              }
              this.getLoginValiteImg()
              this.loading = false
            })
        } else {
          return false
        }
      })
    },

    // 获取短信验证码
    getCaptchad() {
      if (!this.loginForm.userMobile) return this.$message.warning('请输入手机号')
      if (this.loginForm.userMobile.length < 11) return this.$message.warning('请输入正确的手机号')
      if (this.msgcodes == 3020) {
        if (!this.loginForm.imgCode) return this.$message.warning('请输入图形验证码')
      }
      this.flagd = true
      // this.contentd = this.totalTimed + 's后重新发送'
      this.contentd = this.totalTimed + 's'
      const clock = window.setInterval(() => {
        this.totalTimed--
        // this.contentd = this.totalTimed + 's后重新发送'
        this.contentd = this.totalTimed + 's'
        if (this.totalTimed < 0) {
          window.clearInterval(clock)
          // this.contentd = '重新发送验证码'
          this.contentd = '发送验证码'
          this.totalTimed = 60
          this.flagd = false
        }
      }, 1000)
      login_message_code({
        userMobile: this.loginForm.userMobile,
        imgCode: this.loginForm.imgCode,
        imgUuid: this.loginForm.imgUuid
      }).then(res => {
        this.$message.success('短信验证码获取成功，请注意查收！')
      }).catch(e => {
        // this.$errorHandle(e)
        // this.getLoginValiteImg()
        window.clearInterval(clock)
        // this.contentd = '重新发送验证码'
        this.contentd = '发送验证码'
        this.totalTimed = 60
        this.flagd = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
$bg:#fff;
$dark_gray:#889aa4;
$light_gray:#000;
::v-deep .el-input__inner {
  height: 50px;
  line-height: 50px;
}
::v-deep .el-button--primary {
  height: 46px;
  background: linear-gradient(45deg, #4069F4 0%, #5F86F8 100%);
  border-radius: 8px;
  &:hover {
    opacity: 0.7;
  }
  &.is-disabled{
    opacity: 0.5;
  }
}
.show-pwd {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
.verificationcode {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  > span {
    color: #4B6CF9;
    margin-right: 13px;
  }
  ::v-deep .el-button--text{
    color: #3D80FC;
    font-size: 14px;
  }
}
.shadow{
  ::v-deep .el-button{
    box-shadow: 0px 5px 10px 2px #B1D2FF;
    border-radius: 6px;
  }
}
.imgstyle{
  img{
    cursor: pointer;
    vertical-align: bottom;
    background: rgba(228, 232, 235, 0.5);
    border-radius: 4px;
    margin: 4px 0 4px 0;
  }
}
::v-deep .el-form-item{
  margin-bottom: 20px;
}
.ctop {
  margin-top:20px;
  ::v-deep .el-checkbox__inner {
    border-radius: 50% !important;
  }
  ::v-deep .el-checkbox__input.is-checked {
    border-color: #3D80FC !important;
  }
}
</style>
