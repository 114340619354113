<template>
  <div class="m-addHouseType">
    <el-dialog
      :visible.sync="addVisible"
      width="1000px"
      title="新增户型"
      :append-to-body="false"
      @close="$refs.addForm.resetFields()"
    >
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="90px"
        :rules="addRules"
        :disabled="drawVisible"
      >
        <el-form-item ref="busProjectId" label="所属项目" required>
          <um-bus-project v-model="busProjectIds" style="width: 100%" disabled />
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              ref="houseTypeName"
              label="户型名称"
              prop="houseTypeName"
            >
              <el-input
                v-model="addForm.houseTypeName"
                placeholder="请输入户型名称"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item ref="houseTypeStructure" label="户型结构" prop="houseTypeStructure">
              <el-input
                v-model="addForm.houseTypeStructure"
                placeholder="请输入户型结构"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              ref="houseTypeBuildArea"
              prop="houseTypeBuildArea"
              label="建筑面积"
            >
              <el-input-number
                v-model="addForm.houseTypeBuildArea"
                :min="0"
                :max="1000000"
                :precision="2"
                placeholder="请输入建筑面积"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              ref="houseTypeInnerArea"
              prop="houseTypeInnerArea"
              label="套内面积"
            >
              <el-input-number
                v-model="addForm.houseTypeInnerArea"
                :min="0"
                :max="1000000"
                :precision="2"
                placeholder="请输入套内面积"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              ref="constructPrice"
              prop="constructPrice"
              label="建筑单价"
            >
              <el-input-number
                v-model="addForm.constructPrice"
                :min="0"
                :max="100000000000"
                :precision="2"
                placeholder="请输入建筑单价"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              ref="constructPriceAmount"
              prop="constructPriceAmount"
              label="建筑总价"
            >
              <el-input-number
                v-model="addForm.constructPriceAmount"
                :min="0"
                :max="100000000000"
                :precision="3"
                placeholder="请输入建筑总价"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              ref="fitmentPrice"
              prop="fitmentPrice"
              label="装修单价"
            >
              <el-input-number
                v-model="addForm.fitmentPrice"
                :min="0"
                :max="100000000000"
                :precision="3"
                placeholder="请输入装修单价"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              ref="fitmentPriceAmount"
              prop="fitmentPriceAmount"
              label="装修总价"
            >
              <el-input-number
                v-model="addForm.fitmentPriceAmount"
                :min="0"
                :max="100000000000"
                :precision="3"
                placeholder="请输入装修总价"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="套内房间" prop="houseTypeRoomCount">
          <div class="m-addHouseType_roominner">
            <span
              v-for="item in roomcountlist"
              :key="item.values"
              class="u_roominner_title"
              :class="addForm.houseTypeRoomCount==item.values?'u_roominner_title_active':''"
              @click="addForm.houseTypeRoomCount=item.values"
            >{{ item.labels }}</span>
          </div>
        </el-form-item>
        <el-form-item label="部位" prop="buildingPartList">
          <el-row class="partTable">
            <el-checkbox-group v-model="addForm.buildingPartList">
              <el-checkbox
                v-for="item in buildingPartList"
                :key="item.busBuildingPartUuid"
                style="font-weight: 400"
                :disabled="cloneList.some(i => i == item.busBuildingPartUuid)"
                :label="item.busBuildingPartUuid"
              >
                {{ item.partName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-row>
        </el-form-item>
        <!--        <el-form-item label="装修标准" prop="decoration">-->
        <!--          <el-checkbox-group v-model="addForm.decoration">-->
        <!--            <el-checkbox-->
        <!--              style="font-weight: 400"-->
        <!--              :label="'1040661'"-->
        <!--              name="decoration"-->
        <!--            >-->
        <!--              精装-->
        <!--            </el-checkbox>-->
        <!--            <el-checkbox-->
        <!--              style="font-weight: 400"-->
        <!--              :label="'1040662'"-->
        <!--              name="decoration"-->
        <!--            >-->
        <!--              毛坯-->
        <!--            </el-checkbox>-->
        <!--          </el-checkbox-group>-->
        <!--        </el-form-item>-->
        <el-form-item
          v-if="true"
          ref="houseTypeImageList"
          label="户型图"
          prop="houseTypeImageList"
        >
          <myUmUploadImg
            ref="uploadImg"
            v-model="addForm.houseTypeImageList"
            :limit="5"
            :size="5000"
            type="img"
          >
            <template slot="file" slot-scope="{ file }">
              <div v-loading="file.status==='uploading'" style="font-size: 0; position: relative">
                <el-button
                  type="danger"
                  icon="el-icon-delete"

                  style="position: absolute; right: 0; top: 0; z-index: 2"
                  @click="deleteImg(file)"
                />
                <img
                  class="gp-upload-list__item-thumbnail"
                  :src="file.fileFullPath"
                  alt
                  style="width: 100%; height: 114px"
                >
                <el-button
                  type="text"
                  icon="el-icon-edit"

                  style="width: 100%"
                  @click="drawItem(file)"
                >
                  绘制部位
                </el-button>
              </div>
            </template>
          </myUmUploadImg>

          <!-- <button v-if="addForm.houseTypeImageList.length>0">绘制部位</button> -->
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSubmit">保 存</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="partVisible"
      width="400px"
      title="选择部位"
      append-to-body
      @close="$refs.partForm.resetFields()"
    >
      <el-form ref="partForm" :model="partForm" label-width="100px">
        <el-form-item
          ref="busBuildingPartUuid"
          prop="busBuildingPartUuid"
          label="选择部位"
          :rules="{
            required: true,
            message: '必填项不能为空',
            trigger: 'change',
          }"
        >
          <el-select v-model="partForm.busBuildingPartUuid" placeholder="请选择" clearable value-key="busBuildingPartUuid">
            <el-option
              v-for="item in partList"
              :key="item.busBuildingPartUuid"
              :label="item.partName"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="partVisible = false">取 消</el-button>
        <el-button type="primary" @click="partSubmit">保 存</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="drawVisible"
      width="880px"
      title="绘制部位"
      append-to-body
      fullscreen
    >
      <um-draw
        ref="draw"
        v-model="partForm.partList"
        :url="drawUrl"
        :building-part-list="buildingPartList"
        style="margin: auto"
        @path="changePath"
      />
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="drawVisible = false">取 消</el-button>
        <el-button type="primary" @click="drawSubmit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { building_part_list, house_type_insert } from '@/api/mainData/doorModel'
import myUmUploadImg from '@/views/mainData/doorModel/components/myUmUploadImg'
export default {
  name: 'MainDataDoorModelAdd',
  components: { myUmUploadImg },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后三位
      const tempVal = parseFloat(value).toFixed(3)
      return tempVal
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    // 项目id
    busProjectId: {
      type: [Number, String],
      default: null
    }
  },

  data() {
    return {
      roomcountlist: [
        { labels: '一室', values: '200001' },
        { labels: '二室', values: '200002' },
        { labels: '三室', values: '200003' },
        { labels: '四室', values: '200004' },
        { labels: '五室', values: '200005' },
        { labels: '五室以上', values: '200006' }
      ],
      busProjectIds: this.busProjectId,
      addForm: {
        houseTypeName: '',
        busHouseTypeId: '',
        houseTypeStructure: '',
        houseTypeRoomCount: '',
        constructPrice: '',
        constructPriceAmount: '',
        fitmentPrice: '',
        fitmentPriceAmount: '',
        houseTypeBuildArea: undefined,
        houseTypeInnerArea: undefined,
        buildingPartList: [],
        houseTypeImageList: []
      },
      buildingPartList: [],
      addRules: {
        houseTypeName: [
          { required: true, message: '必填项不能为空', trigger: 'change' },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
          }
        ],
        houseTypeRoomCount: [
          { required: true, message: '必填项不能为空', trigger: 'change' }
        ],
        houseTypeStructure: [
          { required: true, message: '必填项不能为空', trigger: 'change' },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
          }
        ],
        // houseTypeBuildArea: [
        //   { required: true, message: '必填项不能为空', trigger: 'change' }
        // ],
        // houseTypeInnerArea: [
        //   { required: true, message: '必填项不能为空', trigger: 'change' }
        // ],
        buildingPartList: [
          {
            type: 'array',
            required: true,
            message: '请至少选择一个部位',
            trigger: 'change'
          }
        ]
      },
      drawUrl: '',
      partVisible: false,
      PointText: null,
      partForm: {
        path: null,
        busBuildingPartUuid: null,
        partList: [],
        file: null
      },
      drawVisible: false
    }
  },
  computed: {
    cloneList() { // 用户已经选择 且被户型图引用的数据
      const buildingPartList = this.addForm.buildingPartList // 用户已经选择的
      const houseTypeImageList = this.addForm.houseTypeImageList // 户型图
      let _partList = []
      houseTypeImageList.forEach(i => {
        if (i.partList) {
          _partList = _partList.concat(i.partList)
        }
      })
      _partList = _partList.map(i => i.busBuildingPartUuid)
      return buildingPartList.filter(i => _partList.includes(i))
    },
    addVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    partList() {
      return this.buildingPartList.filter((item) => {
        return this.addForm.buildingPartList.includes(item.busBuildingPartUuid)
      })
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getPart(this.busProjectId)
      }
    },
    busProjectId: {
      handler(val) {
        this.getPart(this.busProjectId)
        this.busProjectIds = this.busProjectId
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // this.getPart(this.busProjectId)
  },
  mounted() {},
  methods: {
    drawSubmit() {
      const submit = this.$refs.draw.submit()
      const partList = []
      submit.path.forEach((item) => {
        console.log(item.segments)
        const json = JSON.parse(item.exportJSON())
        const segments = json[1].segments
        const data = json[1].data
        if (data && data.busBuildingPartUuid && segments) {
          partList.push({
            busBuildingPartUuid: data.busBuildingPartUuid,
            anchorPoint: segments
              .map((i) => {
                return i.map((j) => j * (submit.rate || 1)).join(',')
              })
              .join(';')
          })
        }
      })
      this.$set(this.partForm.file, 'partList', partList)
      this.drawVisible = false
    },
    partSubmit() {
      this.$refs.partForm.validate((valid) => {
        if (valid) {
          if (!this.partForm.path) {
            return false
          }
          this.partForm.path.data.busBuildingPartUuid = this.partForm.busBuildingPartUuid.busBuildingPartUuid
          this.partForm.path.text && this.partForm.path.text.remove()
          var text = new this.PointText(this.partForm.path.position)
          text.fillColor = '#000'
          text.fontSize = 14
          text.fontWeight = 700
          text.content = this.partForm.busBuildingPartUuid.partName
          text.justification = 'center'
          this.partForm.path.text = text
          this.partVisible = false
        } else {
          this.$message.error('请选择部位！')
          return false
        }
      })
    },
    changePath(path, PointText) {
      this.partVisible = true
      this.partForm.path = path
      this.PointText = PointText
    },
    drawItem(file) {
      // this.$set(file, 'dandan', 123)
      if (this.partList.length === 0) {
        return this.$message.error('请选择部位！')
      }
      let path = ''
      if (file.httpPath) {
        path = file.httpPath
      }
      if (file.response) {
        path = file.response.data.fileFullPath
      }
      if (path === '') {
        this.$message.error('请选择户型图！')
        return false
      }
      this.partForm.file = file
      this.partForm.partList = file.partList || []
      this.drawUrl = path
      this.drawVisible = true
    },
    deleteImg(file) {
      this.$refs.uploadImg.handleRemove(file)
    },
    // 获取部位
    getPart(val) {
      if (!this.busProjectId) {
        return false
      }
      const params = { busProjectUuid: val }
      building_part_list(params).then((d) => {
        const list = d.data
        list.forEach(item => {
          item.busBuildingPartUuid = item.partUuid
        })
        this.buildingPartList = list
      })
    },
    // 新增
    addSubmit() {
      this.$refs.addForm.clearValidate()
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const load = this.$load()
          const houseTypeImageList = []
          if (this.addForm.houseTypeImageList && this.addForm.houseTypeImageList.length) {
            this.addForm.houseTypeImageList.forEach(i => {
              houseTypeImageList.push({
                imageUrl: i.url,
                partList: i.partList || []
              })
            })
          }
          console.log('houseTypeImageList', houseTypeImageList)
          // debugger
          // const temBuildArea = this.addForm.houseTypeBuildArea.toFixed(3)
          // const temInnerArea = this.addForm.houseTypeInnerArea.toFixed(3)
          house_type_insert({
            busProjectUuid: this.busProjectId,
            houseTypeName: this.addForm.houseTypeName,
            houseTypeStructure: this.addForm.houseTypeStructure,
            houseTypeBuildArea: this.addForm.houseTypeBuildArea,
            houseTypeInnerArea: this.addForm.houseTypeInnerArea,
            buildingPartList: this.addForm.buildingPartList, // 既然只要ID 就不需要处理了
            houseTypeRoomCount: this.addForm.houseTypeRoomCount,
            constructPrice: this.addForm.constructPrice || '',
            constructPriceAmount: this.addForm.constructPriceAmount || '',
            fitmentPrice: this.addForm.fitmentPrice || '',
            fitmentPriceAmount: this.addForm.fitmentPriceAmount || '',
            houseTypeImageList
          })
            .then((d) => {
              load.close()
              this.$alert('添加成功！', '提示', {
                type: 'success',
                showClose: false
              }).then((d) => {
                this.$refs.addForm.resetFields()
                this.addVisible = false
                this.$emit('getList')
              })
            })
            .catch((e) => {
              load.close()
              this.$errorHandle(e)
            })
        } else {
          this.$message.error('请填写正确的表单！')
          return false
        }
      })
    }
  }
}
</script>
