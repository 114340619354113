import request from '@/utils/request'
const baseURL = 'http://api.pm.youmatech.com/mock/783'

// 获取标段列表
export function project_para_get_list(data) {
  return request({
    url: '/api/v1/data/project_para/get/list',
    method: 'post',
    // baseURL,
    data
  })
}

// 删除标段
export function project_para_delete(params) {
  return request({
    url: '/api/v1/data/project_para/delete',
    method: 'get',
    // baseURL,
    params
  })
}

// 新增/修改获取楼栋信息
export function project_para_building(params) {
  return request({
    url: '/api/v1/data/project_para/add/building',
    method: 'get',
    // baseURL,
    params
  })
}

// 获取标段详情
export function project_para_info(params) {
  return request({
    url: '/api/v1/data/project_para/get/info',
    method: 'get',
    // baseURL,
    params
  })
}

// 新增/编辑标段
export function project_para_add_update(data) {
  return request({
    url: '/api/v1/data/project_para/add_update/para',
    method: 'post',
    // baseURL,
    data
  })
}
