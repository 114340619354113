<template>
  <div class="c-organ-tree">
    <div class="m_org_search_box">
      <el-input v-model="filterText" clearable placeholder="请输入组织名称搜索" maxlength="50" prefix-icon="el-icon-search" />
    </div>
    <div class="m-tree-scroll">
      <el-scrollbar style="height: 100%">
        <el-tree
          ref="tree"
          :data="treeData"
          :default-expanded-keys="defaultExpandedKeys"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          :props="defaultProps"
          class="filter-tree"
          highlight-current
          node-key="organUuid"
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          @current-change="currentTreeChange"
          @node-drag-start="handleDragStart"
          @node-drag-enter="handleDragEnter"
          @node-drag-leave="handleDragLeave"
          @node-drag-over="handleDragOver"
          @node-drag-end="handleDragEnd"
          @node-drop="handleDrop"
          @node-click="nodeClick"
        >
          <template #default="{ node, data }">
            <div class="m_org_tree_node" :class="{m_node_disabled:data.readOnlyFlag==1}">
              <div class="m_org_tree_label ">
                <div class="m_org_tree_name" :class="{m_org_tree_color1:node.level==1}">
                  <UmToolTip :key="data.organName" :content="data.organName" />
                </div>
                <div class="m_org_tree_treenode">
                  <span class="m_org_tree_counts" :class="{m_org_tree_color1:node.level==1}">({{ data.personCount || 0 }})</span>
                  <div v-if="data.organTypeCode==1001502" class="m_org_tree_tag">公司</div>
                  <div v-if="data.organTypeCode==1001503" class="m_org_tree_tag">部门</div>
                  <div v-if="data.organTypeCode==1001504" class="m_org_tree_tag">岗位</div>
                </div>
              </div>
              <!--          <span-->
              <!--            v-if="node.isCurrent&&data.readOnlyFlag==0"-->
              <!--            v-permission="['112030201103','112030201104','112030201105']"-->
              <!--            class="m_org_tree_dropdow_more"-->
              <!--            @click.stop-->
              <!--          >-->
              <!--            <i class="el-icon-rank" style="font-size: 12px;margin-top: 2px" />-->
              <!--            <el-popover-->
              <!--              popper-class="m_enterpris_popover"-->
              <!--              placement="right-start"-->
              <!--              width="112"-->
              <!--              :visible-arrow="false"-->
              <!--              trigger="click"-->
              <!--            >-->
              <!--              <svg-icon slot="reference" icon-class="ellipsis" class-name="custom-class" @click="chose(data)" />-->
              <!--              <div class="m_enterpris_popover_edit-wrap">-->
              <!--                <div v-permission="['112030201103']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => addsondepartment( data)">-->
              <!--                  <span class="m_enterpris_popover_add-icon" />-->
              <!--                  <span>新建子部门</span>-->
              <!--                </div>-->
              <!--                <div v-permission="['112030201104']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => editdepartment(data)">-->
              <!--                  <span class="m_enterpris_popover_edit-icon" />-->
              <!--                  <span>编辑</span>-->
              <!--                </div>-->
              <!--                <div v-permission="['112030201105']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => deletedepartment(node, data)">-->
              <!--                  <span class="m_enterpris_popover_del-icon" />-->
              <!--                  <span>删除</span>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--            </el-popover>-->
              <!--          </span>-->
            </div>
          </template>
        </el-tree>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { get_organ_tree, get_organ_tree_sort } from '@/api/enterprise'
import { deepClone } from '@/utils'

export default {
  name: 'OrganTree',
  props: {
    nodeKey: {
      type: String,
      default: 'organUuid'
    }
  },
  data() {
    return {
      treeData: [],
      filterText: '',
      defaultExpandedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'organFullName',
        isLeaf: 'leafFlag'
      },
      treeBackUp: [],
      current: {}
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  created() {
    this.getTree()
  },
  methods: {
    nodeClick(a, b, c) {
      console.log(a, b, c, this.current)
    },
    getTree() {
      get_organ_tree({
        stepFlag: 0,
        parentUuid: ''
      }).then(d => {
        this.treeData = d.data
        this.dataclone = deepClone(d.data)
        this.defaultExpandedKeys = this.treeData.map(item => item.organUuid) // 默认展开第一级
        // 获取有权限的查看列表
        const currentData = this.findPermissionNode(this.treeData)
        if (currentData) {
          this.$nextTick(_ => {
            this.$refs.tree.setCurrentNode(currentData)
          })
          this.$emit('change', currentData)
        }
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    currentTreeChange(data, checked, indeterminate) {
      this.current = data
      this.$emit('change', data.readOnlyFlag == 1 ? null : data)
    },
    // 筛选树
    filterNode(value, data) {
      return value ? data.organFullName.indexOf(value) !== -1 : true
    },
    allowDrop(draggingNode, dropNode, type) {
      // 拖拽时判定目标节点能否被放置。type 参数有三种情况：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
      return type === 'inner' ? false : draggingNode.parent.key === dropNode.parent.key
    },
    allowDrag(draggingNode) {
      // return draggingNode.data.label.indexOf('三级 3-2-2') === -1
      return true // 判断节点能否被拖拽
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const load = this.$load()
      get_organ_tree_sort({
        opOrganUuid: draggingNode.key,
        toOrganUuid: dropNode.key,
        type: dropType === 'before' ? 1 : 2
      }).then(d => {
        load.close()
        this.$message.success('操作成功！')
        this.getTree()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
        this.treeData = deepClone(this.dataclone1)
      })
    },
    findPermissionNode(tree) {
      let node = null
      function deep(arr) {
        try {
          arr.forEach(item => {
            if (item.readOnlyFlag != 1) {
              node = item
              throw new Error()
            }
            deep(item.children)
          })
        } catch (e) {

        }
      }
      deep(tree)
      return node
    },
    handleDragStart(node, ev) {
      this.treeBackUp = deepClone(this.treeData)
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      // console.log('tree drag enter: ', dropNode.label)
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      // console.log('tree drag leave: ', dropNode.label)
    },
    handleDragOver(draggingNode, dropNode, ev) {
      // console.log('tree drag over: ', dropNode.label)
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      // console.log('tree drag end: ', dropNode && dropNode.label, dropType)
    }
  }
}
</script>

<style lang="scss">
.c-organ-tree {
  height: 100%;
  width: 100%;
  background: #fff;
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  .m_org_search_box {
    margin-bottom: 6px;
  }
  .m-tree-scroll {
    flex: 1;
    overflow: hidden;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
      .el-scrollbar__view {
        min-height: 100%;
      }
    .m_org_tree_node {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      padding-right: 12px;
      overflow: auto;
      min-width: 110px;
      .m_org_tree_label {
        display: flex;
        align-items: center;
        flex: 1;
        overflow: hidden;
        .m_org_tree_name {
          //max-width: 190px;
          font-size: 14px;
          color: #31415f;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .m_org_tree_treenode {
          display: flex;
          align-items: center;
          margin-left: 5px;
          .m_org_tree_counts {
            font-size: 14px;
            color: #31415f;
          }
        }
        .m_org_tree_color1 {
          font-weight: 600;
        }
        .m_org_tree_tag {
          height: 16px;
          padding: 0 4px;
          background: rgba(61, 128, 252, 0.1);
          border-radius: 2px;
          color: #909db6;
          font-size: 12px;
          margin-left: 4px;
          line-height: 16px;
        }
      }
    }
    .m_node_disabled {
      cursor: no-drop;
      opacity: 0.5;
    }
    .el-tree-node__content {
      height: 42px;
      line-height: 42px;
    }
  }
}
</style>
