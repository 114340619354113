<template>
  <div class="g-news">
    <el-card class="news-card" shadow="never">
      <div v-if="noticeList&&noticeList.length" class="cardBody">
        <div v-for="item in noticeList" :key="item.noticeUuid" class="cardBody_list" @click="go_detail(item.noticeUuid)">
          <div v-if="item.topFlag==1" class="u-zhiding um-icon um-icon_zhiding" />
          <div class="item_notice_title">
            <div class="noticeTitle">
              <svg-icon v-if="item.readFlag!=1" icon-class="newsDot" class="read_icon" />
              <span>{{ item. noticeTitle||'-' }}</span>
            </div>
            <div class="date">
              <div class="um-icon um-icon_date-icon mr8" />
              {{ item.publishTime||'-' }}
            </div>
          </div>
          <div class="row_1 mt14 describes">{{ item.describe||'-' }}</div>
        </div>
        <pagination
          :background="false"
          :layout="'total, sizes, prev, pager, next, jumper'"
          :limit.sync="page.pageSize"
          :page.sync="page.pageNum"
          :total="total"
          class="u-page"
          @pagination="getList('page')"
        />
      </div>

      <div v-else class="m-noData centerData">
        <div>暂无数据</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  query_notice_list
} from '@/api/enterprise'
export default {
  name: 'Notice',
  props: {},
  data() {
    return {
      noticeList: [],
      total: 0,
      page: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList(state = '') {
      if (state !== 'page') {
        this.page = {
          pageSize: 10,
          pageNum: 1
        }
      }
      const load = this.$load()
      query_notice_list({
        page: this.page
      }).then(d => {
        this.noticeList = d.data.list || []
        this.total = d.data.page.total
      }).finally(_ => {
        load.close()
      })
    },
    go_detail(id) {
      this.$router.push({
        path: '/notice/info',
        query: {
          noticeUuid: id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
