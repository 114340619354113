import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'
const mainDataRouter = {
  path: '/mainData',
  component: Layout,
  // redirect: '/mainData/home',
  meta: { level: 1, roles: ['11204'] },
  redirect: to => {
    for (let i = 0; i < mainDataRouter.children.length; i++) {
      const { path, meta } = mainDataRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  children: [
    {
      path: '/mainData/home',
      name: 'MainDataHome',
      component: () => import('@/views/mainData/home/index'),
      meta: { title: '主数据', cache: true, group: 'administration', level: 2, roles: ['1120401'] }
    },
    {
      path: '/mainData/project',
      component: () => import('@/views/mainData/project'),
      name: 'MainDataProject',
      meta: { title: '项目管理', cache: true, group: 'administration', level: 2, icon: 'mainData-xm', roles: ['1120403'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/project/info',
      component: () => import('@/views/mainData/project/info/index'),
      name: 'MainDataProjectInfo',
      meta: { title: '项目管理详情', group: 'administration', level: 3, roles: ['1120403'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/project/add',
      component: () => import('@/views/mainData/project/info/index'),
      name: 'MainDataProjectInfo',
      meta: { title: '项目管理新增', group: 'administration', level: 3, roles: ['1120403'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/building',
      component: () => import('@/views/mainData/building/index'),
      name: 'MainDataBuilding',
      meta: { title: '楼栋管理', cache: false, group: 'administration', icon: 'mainData-ld', level: 2, roles: ['1120404'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/tender',
      name: 'MainDataTender',
      component: () => import('@/views/mainData/tender'),
      meta: { title: '标段管理', cache: false, group: 'administration', level: 2, icon: 'mainData-bd', roles: ['1120405'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/tender/add',
      name: 'MainDataTenderAdd',
      component: () => import('@/views/mainData/tender/add'),
      meta: { title: '标段添加', cache: false, group: 'administration', level: 3, roles: ['1120405'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/tender/update',
      name: 'MainDataTenderUpdate',
      component: () => import('@/views/mainData/tender/update'),
      meta: { title: '标段编辑', cache: false, group: 'administration', level: 3, roles: ['1120405'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/tender/config',
      name: 'MainDataTenderConfig',
      component: () => import('@/views/mainData/tender/config'),
      meta: { title: '标段设置', cache: false, group: 'administration', level: 3, roles: ['1120405'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/room',
      name: 'MainDataRoom',
      component: () => import('@/views/mainData/room/index'),
      meta: { title: '房间管理', cache: false, group: 'administration', icon: 'mainData-fj', level: 2, roles: ['1120406'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/doorModel',
      name: 'MainDataDoorModel',
      component: () => import('@/views/mainData/doorModel/index'),
      meta: { title: '户型管理', cache: false, group: 'administration', icon: 'mainData-hx', level: 2, roles: ['1120407'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/position',
      name: 'MainDataPosition',
      component: () => import('@/views/mainData/position/index'),
      meta: { title: '部位管理', cache: false, group: 'administration', icon: 'mainData-bw', level: 2, roles: ['1120408'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/contractor',
      name: 'MainDataContractor',
      component: () => import('@/views/mainData/contractor/index'),
      meta: { title: '承建商管理', cache: true, group: 'administration', icon: 'mainData-cjs', level: 2, roles: ['1120409'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/contractor/add',
      name: 'MainDataContractorAdd',
      component: () => import('@/views/mainData/contractor/add/index'),
      meta: { title: '新增承建商', cache: false, group: 'administration', level: 3, roles: ['1120409'], activeMenu: '/administration' }
    },
    {
      path: '/mainData/contractor/info',
      name: 'MainDataContractorInfo',
      component: () => import('@/views/mainData/contractor/info/index'),
      meta: { title: '承建商详情', cache: false, group: 'administration', level: 3, roles: ['1120409'], activeMenu: '/administration' }
    }
  ]
}
export default mainDataRouter
