<template>
  <div v-if="closeFlag" v-loading="load" class="m-erweima flex-center">
    <div class="erweimaBox">
      <div class="ewm_title">—— 请使用微信扫码支付 ——</div>
      <div class="ewm_img">
        <img :src="ewmUrl" alt="" width="200px" height="200px">
        <div v-if="refreshFlag" class="u-mask flex-center" @click="getPayQrCode()">
          <img src="@/assets/dashboard/refresh.png" alt="" width="52px" height="52px" class="pointer">
        </div>
      </div>
      <div class="ewm_btn pointer" @click="getClose()">暂不付款</div>
    </div>
  </div>
</template>
<script>
import { getPayQrCode, getPayOrderInfo } from '@/api/cloudMarket'
export default {
  props: {
    closeVisible: {
      type: Boolean,
      default: false
    },
    businessOrderUuid: {
      type: [Number, String],
      default: ''
    },
    payment: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      closeFlag: false,
      ewmUrl: '',
      expirationTime: '',
      refreshFlag: false,
      load: false,
      orderStatusQrCode: null
    }
  },
  watch: {
    closeVisible: {
      handler(val) {
        this.closeFlag = val
      },
      immediate: true
    }

  },
  mounted() {
  },
  methods: {
    getClose() {
      this.$emit('changeShow', 'false')
      this.closeFlag = false
    },

    // 刷新二维码
    getRefreshPayQrCode() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.refreshFlag = true // 遮罩层
      }, 180000)
    },
    // 查询二维码
    getPayQrCode() {
      // const load = this.$load()
      this.load = true
      this.refreshFlag = false
      getPayQrCode({ businessOrderUuid: this.businessOrderUuid, payChannelCode: this.payment }).then(res => {
        this.ewmUrl = res.data.qrHttPath
        this.expirationTime = res.data.qrExpireTime
        this.orderStatusQrCode = res.data.orderStatusCode
        if (res.data.orderStatusCode == 1206502) {
          this.$emit('changeQrcode', this.orderStatusQrCode)
        }
        this.load = false
      }).catch(e => {
        this.load = false
        this.$errorHandle(e)
      })
      this.getRefreshPayQrCode()
    },
    // 查询订单状态
    getPayOrderInfo() {
      getPayOrderInfo({ businessOrderUuid: this.businessOrderUuid }).then(res => {
        this.money = res.data.orderAmount
        this.orderStatusCode = res.data.orderStatusCode
      }).catch(e => {
      })
    }
  }
}
</script>
