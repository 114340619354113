<template>
  <div class="draw">
    <canvas ref="canvas" @contextmenu.prevent />
    <img ref="img" :src="url" alt="" @load="load">
    <contextmenu ref="contextmenu" :theme="theme">
      <contextmenu-item :disabled="path&&!path.closed" @click="edit">设置</contextmenu-item>
      <contextmenu-item :disabled="!path || path.closed" @click="closed">闭合</contextmenu-item>
      <contextmenu-item @click="remove">删除</contextmenu-item>
    </contextmenu>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import {
  directive,
  Contextmenu,
  ContextmenuItem,
  ContextmenuSubmenu,
  ContextmenuGroup
} from 'v-contextmenu'
import 'v-contextmenu/dist/index.css'
import paper from 'paper'

const { Path, Point, PointText } = paper
const hitOptions = {
  segments: true,
  stroke: true,
  fill: true,
  tolerance: 5
}
const pathOptions = {
  strokeWidth: 1,
  fillColor: 'rgb(254, 87, 161,.3)',
  strokeColor: 'rgb(51, 136, 255)',
  strokeJoin: 'round',
  dashArray: [10, 6]
}

export default {
  name: 'UmDraw',
  components: {
    Contextmenu,
    ContextmenuItem,
    // eslint-disable-next-line vue/no-unused-components
    ContextmenuSubmenu,
    // eslint-disable-next-line vue/no-unused-components
    ContextmenuGroup
  },
  directives: {
    contextmenu: directive
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: ''
    },
    buildingPartList: { // 部位数据
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      theme: 'default', // default / bright / dark
      state: null, // 当前画布状态  add:绘制 ; edit:编辑 ;
      path: null, // 当前操作的路径
      paths: [], // 所有路径的集合
      segment: null, // 编辑模式下选中的点
      mouseCount: 0, // 鼠标绘制次数
      downPoint: null,
      clientWidth: 0,
      clientHeight: 0,
      naturalWidth: 0,
      naturalHeight: 0,
      tool: null,
      paper: null
    }
  },
  watch: {
    url: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            console.log('-----监听文件变化-----')
            this.clientWidth = this.$refs.img.clientWidth
            this.clientHeight = this.$refs.img.clientHeight
            this.naturalWidth = this.$refs.img.naturalWidth
            this.naturalHeight = this.$refs.img.naturalHeight
            this.init()
          })
        }
      },
      immediate: true
    }
  },
  created() {
  },
  mounted() {
    if (!this.url) {
      console.error('请输入正确的图片地址')
      return false
    }
    console.log('---------绘图组件初始化了！！！！------')
    window.paper = paper

    this.paper = new paper.PaperScope() // 初始化私有方法
    this.tool = new this.paper.Tool() // 初始化工具
    this.paper.setup(this.$refs.canvas) // 初始化paper.js
    this.paper.activate()
    this.paper.settings.handleSize = 10

    this.tool.minDistance = 10
    this.tool.onMouseDown = this.onMouseDown
    this.tool.onMouseDrag = this.onMouseDrag
    this.tool.onMouseMove = this.onMouseMove
    this.tool.onMouseUp = this.onMouseUp
    window.addEventListener('keydown', this.cancel)
  },
  beforeDestroy() {
    console.log('-----绘图组件销毁了！！！！---------')
    this.clear()
    this.paper.remove() // 移除画布
    window.removeEventListener('keydown', this.cancel)
  },
  methods: {
    clear() {
      console.log('-----清空画布------')
      this.paper.project.layers.forEach(item => {
        item.remove() // 清除每个图层
      })
    },
    load() {
      this.clientWidth = this.$refs.img.clientWidth
      this.clientHeight = this.$refs.img.clientHeight
      this.naturalWidth = this.$refs.img.naturalWidth
      this.naturalHeight = this.$refs.img.naturalHeight
      this.mouseCount = 0
    },
    init() {
      // 这里就比较诡异了，必须要清空画布～
      this.clear()
      this.mouseCount = 0
      this.paths = []
      this.segment = null
      // 初始化
      let rate = 1
      if (this.clientWidth >= this.naturalWidth) {
        // 按照正常比例返回
      } else {
        rate = (this.naturalWidth / this.clientWidth).toFixed(2)
      }
      if (this.value && this.value.length > 0) {
        this.value.forEach(item => {
          const busBuildingPartUuid = item.busBuildingPartUuid
          const partName = this.buildingPartList.filter(item => item.busBuildingPartUuid == busBuildingPartUuid)[0].partName
          const anchorPoint = item.anchorPoint.split(';')
          this.path = new Path(pathOptions)
          this.path.selected = true
          this.path.selectedColor = 'transparent'
          anchorPoint.forEach(point => {
            const x = point.split(',')[0] / rate
            const y = point.split(',')[1] / rate
            this.path.add(new Point(x, y))
          })

          const text = new PointText(this.path.position)
          text.fillColor = '#000'
          text.fontSize = 14
          text.fontWeight = 700
          text.content = partName
          text.justification = 'center'
          this.path.text = text
          this.path.data.busBuildingPartUuid = busBuildingPartUuid

          this.path.closePath()
          this.paths.push(this.path)
          // 添加到图层
          this.paper.project.activeLayer.addChild(this.path)
          this.paper.project.activeLayer.addChild(text)
          // 清空path
          this.path = null
        })
      }
    },
    onMouseMove(event) {
      // paper.project.activeLayer.selected = false
      // if (event.item) { event.item.selected = true }
    },
    onMouseDown(event) {
      event.stopPropagation()
      // if (event.downPoint.x > this.clientWidth || event.downPoint.y > this.clientHeight) {
      //   return false
      // }
      this.downPoint = event.downPoint
      if (event.event.button === 2) {
        // 右键不做处理
        const hitResult = this.hitResult(event)
        console.log(event.event.clientX, event.event.clientY)
        if (hitResult) {
          this.$refs.contextmenu.show({
            top: event.event.clientY,
            left: event.event.clientX
          })
        }
        return false
      }
      // 所有的操作都不存在重叠问题，所以先判断是否重叠
      const hitResult = this.hitResult(event)

      if (hitResult) {
        if (hitResult.type === 'segment' && this.path && !this.path.closed) {
          if (hitResult.segment.index === 0) {
            this.path && this.path.closePath()
            // 闭合多边形
            this.$emit('path', this.path, PointText)
            this.paths.push(this.path)
            this.path = null
            this.mouseCount = 0
            this.state = null
          }
          return false
        }
        // 有重叠
        if (hitResult.type === 'segment') {
          this.segment = hitResult.segment
          this.state = 'edit'
        } else if (hitResult.type === 'stroke') {
          // 不做处理
        }
        return false
      }
      // 判断编辑状态
      if (this.state === null || this.state === 'add') {
        this.state = 'add'
        if (!this.path) {
          this.path = new Path(pathOptions)
          this.path.selected = true
          this.path.selectedColor = 'transparent'
          this.mouseCount = 0 // 如果 path 为null 就初始化点击次数
        }

        this.path.add(event.point)
        this.mouseCount++
      }
    },
    onMouseDrag(event) {
      event.stopPropagation()
      // if (event.lastPoint.x > this.clientWidth || event.lastPoint.y > this.clientHeight) {
      //   return false
      // }
      const hitResult = this.hitResult(event)
      console.log('onMouseDrag', this.mouseCount, this.path, hitResult)
      if (this.state === 'edit') {
        // 编辑模式
        // if (hitResult && (hitResult.item !== this.segment.path)) {
        //   // 重叠区域不给编辑
        //   this.state = null
        //   this.segment = null
        //   return false
        // }
        this.segment.point = this.segment.point.add(event.delta) // 修改path
        // 修改文字 位置
        if (this.segment.path.text) {
          this.segment.path.text.point = this.segment.path.position
        }
        return false
      }
      if (this.path === null) {
        return false
      }
      const segments = this.path.segments // 当前路径的节点数
      // 绘制矩形
      if (this.state === 'add' && this.mouseCount === 1) {
        // if (this.paths.some(item => item.intersects(this.path))) {
        //   // 如果相交
        //   return false
        // }
        this.path && this.path.removeSegments()
        // this.path = null
        const result = event.point.subtract(event.downPoint)
        console.log(result)
        if (Math.abs(result.x) < 10 || Math.abs(result.y) < 10) {
          // this.path = null
          // this.mouseCount = 0
        } else {
          this.path = new Path.Rectangle({
            from: event.downPoint,
            to: event.point,
            ...pathOptions
          })
          this.path.selected = true
          this.path.selectedColor = 'transparent'
        }
        return false
      }
      // 绘制折线
      if (this.state === 'add') {
        if (this.paths.some(item => item.intersects(this.path))) {
          // 如果相交
          return false
        }
        this.path.removeSegment(this.path.lastSegment.index)
        this.path.lineTo(event.point)
      }
    },
    onMouseUp(event) {
      // if (event.point.x > this.clientWidth || event.point.y > this.clientHeight) {
      //   return false
      // }
      if (event.event.button === 2) {
        // 右键不做处理
        return false
      }
      event.stopPropagation()
      console.log('onMouseUp', this.path)
      if (this.path === null || this.path.segments.length === 0) {
        this.mouseCount = 0 // 清空鼠标次数
        this.state = null // 清空状态
        return false
      }
      const segments = this.path.segments // 当前路径的节点数
      if (this.state === 'add' && this.mouseCount === 1 && segments.length === 4) {
        this.paths.push(this.path)
        this.$emit('path', this.path, PointText)
        // 闭合矩形
        this.path = null
        this.mouseCount = 0
      }

      this.state = null // 清空状态
    },
    closed() {
      this.path && this.path.closePath()
      // 闭合多边形
      this.$emit('path', this.path, PointText)
      this.paths.push(this.path)
      this.path = null
      this.mouseCount = 0
      this.state = null
    },
    hitResult(event) {
      return this.paper.project.hitTest(event.point, hitOptions)
    },
    cancel(e) {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 90) {
        console.log('执行撤销命令！')
        if (this.path.lastSegment) {
          this.path.removeSegment(this.path.lastSegment.index)
        }
      }
    },
    remove(e, event) {
      // 文字还需要单独判断
      const index = []

      this.paths.forEach((item, i) => {
        if (item.hitTest(this.downPoint)) {
          item.text && item.text.remove()
          item.remove()
          index.push(i)
        }
      })
      this.paths = this.paths.filter((item, i) => !index.includes(i))
      // 当前正在绘制的path
      if (this.path && this.path.hitTest(this.downPoint)) {
        this.path.text && this.path.text.remove()
        this.path.remove()
        this.path = null
      }
      console.log(JSON.stringify(this.paths))
    },
    edit() {
      let path = null
      this.paths.forEach((item, i) => {
        if (item.hitTest(this.downPoint)) {
          path = item
        }
      })
      this.$emit('path', path, PointText)
    },
    submit() {
      const list = []
      let rate = 1
      if (this.clientWidth >= this.naturalWidth) {
        // 按照正常比例返回
      } else {
        rate = (this.naturalWidth / this.clientWidth).toFixed(2)
      }
      return {
        path: this.paths,
        rate
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.v-contextmenu{
  position: fixed;
}
.draw{
  position: relative;
  font-size: 0;
  width: 1200px;
  height: 900px;
  img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    user-select: none;
  }
  canvas{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    z-index:2;
  }
}
</style>
