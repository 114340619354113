<template>
  <div class="m-cloudMarkDetail">
    <div class="cloudMarkDetail_commodity commodity_solution">
      <div class="go_back_common">
        <div class="go_back_title" @click="back">
          <span class="go_back_icon" />
          <span class="go_back_txt">返回云市场首页</span>
        </div>
        <div class="go_back_lines" />
      </div>
      <div class="commodity_solutionContent ">
        <div class="solutionContent_text">
          <div class="flex-between">
            <div class="u-title mw-560  mb16">{{ detailInfo.title }}</div>
            <div class="z_index solutionContent_text_tool">
              <UmCollection v-model="collectionStatus" @change="collectHandler" />
              <UmLike :business-uuid="uuid" :business-type="2003010104" style="margin-left: 30px;" />
            </div>
          </div>
          <div v-if="detailInfo.introduction" class="introduction">
            {{ detailInfo.introduction }}
          </div>
          <div class="text_tags flex ">
            <el-tag v-for="(item,i) in detailInfo.industryCategoryUuidArr" :key="i" class="row_1 u-tag" size="mini">
              {{ item }}
            </el-tag>
          </div>
          <div>
            <div class="solutionContent_btn">
              <el-button type="primary" @click="getInstall()">
                <div>一键安装</div>
              </el-button>
            </div>
          </div>
        </div>
        <div class="commodity_solution" />
      </div>
    </div>
    <div class="cloudMarkDetail_product">
      <div class="product-title text-center">产品详情</div>
      <div class="flex-center " style="padding-top:10px">
        <UmEditInfo v-if="detailInfo.content" :url="detailInfo.content" @goInfo="jumpPath" />
        <no-data v-else class="mt40" style="width:100%" />
      </div>
    </div>
    <el-drawer
      :before-close="handleClose"
      :direction="direction"
      :visible.sync="drawer"
      title="确认订单信息"
      :destroy-on-close="true"
      size="660px"
    >
      <div class="u_solution_details_d">
        <div class="cloudMarkDetail_solutionOrder">
          <addressCheck v-if="!isonlyapplication" class="mb-20" :use-address-info="useAddressInfo" @getApply="getApply" />
          <div class="orderForm flex-column-between">
            <div class="flex">
              <div class="label">
                方案名称：
              </div>
              <div class="rightcontent">{{ title || '-' }}</div>
            </div>
            <div class="flex">
              <div class="label">
                组织：
              </div>
              <div class="rightcontent">{{ $store.getters.enterpriseName || '-' }}</div>
            </div>
            <div class="flex">
              <div class="label">
                商品数：
              </div>
              <div class="rightcontent">{{ quantity }}</div>
            </div>
          </div>
          <div class="flex-between mt10 orderTotalPrice">
            <div class="u-grayColor">总价</div>
            <div class="flex-column-center ">
              ¥
              <div class="u-money">{{ money || 0 }}</div>
              元
            </div>
          </div>
          <!-- 应用 -->
          <div v-for="(item,i) in orderList" :key="i" class="orderSolution mb20">
            <div v-if="i==orderList.findIndex(ite=> ite.appType===1)" class="u-applicationTitle">{{ orderType[item.appType] }}</div>
            <div v-if="i==orderList.findIndex(ite=> ite.appType===2)" class="u-applicationTitle">{{ orderType[item.appType] }}</div>
            <div v-if="i==orderList.findIndex(ite=> ite.appType===3)" class="u-applicationTitle">{{ orderType[item.appType] }}</div>
            <div class="orderSolution_box" @click="item.active = !item.active">
              <div
                class="u-check"
                :class="{
                  active: item.active,
                  box1: 1
                }"
              />
              <div class="flex box_topTitle">
                <div class="u-boxImg">
                  <img :src="item.appUrl" alt="" height="100%" width="100%">
                </div>
                <div class="topTitle_title flex-column-between">
                  <div class="flex title_top">
                    <UmToolTip :content="item.appName " class="row_one space" style="width:150px" />
                    <div class="applicationMoney">¥{{ item.money }}</div>
                  </div>
                  <div class="flex-between title_bottom">
                    <UmToolTip :content="item.categoryName" class="row_one" :style="item.appType!=1?'width:120px':'width:240px'" />
                    <div v-if="item.appType!=1" @click.stop>
                      <el-input-number
                        v-model="item.nums"
                        :precision="0"
                        :max="999"
                        :min="0"
                        class="num"
                        size="small"
                        style="width:82px"
                        @change="handleChange(item)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="box_check" @click.stop>
                <div v-if="item.appType===1" class="check_specifications flex-between pointer mb8" style="padding:0px 12px" @click.stop="getRange(i)">
                  <div>可见范围</div>
                  <div class="flex-column-center">
                    <UmBusUser
                      ref="chosepart"
                      v-model="item.department"
                      :default-root="true"
                      :show-prefix-button="false"
                      :type="1"
                      class="range"
                      title="选择可见范围"
                      @change="change($event, i)"
                    >
                      <template #user="{labelCustom}">
                        <span>  {{ item.organScopeUuids.length ? labelCustom : '请选择可见范围' }}</span>
                      </template>
                    </UmBusUser>
                    <div class="pointer ml-10 flex-center" @click="getRange(i)">
                      <i class="el-icon-arrow-down pointer" />
                    </div>
                  </div>
                </div>
                <div class="check_specifications flex-culumn-align pointer">
                  <div class="flex-between specifications_item">
                    <div>规格</div>
                    <div class="flex" @click="getSpecification(item)">
                      <div class="font-12" :style="{ color:item.specificationsName? '#31415f': '#9faabc' }">{{ item.specificationsName || '请选择' }}</div>
                      <i
                        v-if="item.flag===false"
                        class="el-icon-arrow-down pointer ml-10"
                      />
                      <i v-else class="el-icon-arrow-up ml-10" />
                    </div>
                  </div>
                  <div v-if="item.flag===true">
                    <el-row :gutter="7">
                      <!--                      <el-col v-for="(ite,j) in item.specifications" :key="j" :span="8">-->
                      <!--                        <div-->
                      <!--                          :class="item.tagId===j?'unCheckTag':'checkTag'"-->
                      <!--                          class="tags flex-center mb10 pointer"-->
                      <!--                          @click.stop="ite.isGray==0?getTags(item,j):getMessage()"-->
                      <!--                        >-->
                      <!--                          <UmToolTip :content="ite.name" class="row_1 text-center" />-->
                      <!--                        </div>-->
                      <!--                      </el-col>-->
                      <el-table :data="item.specifications">
                        <el-table-column fixed="left" width="50">
                          <template #default="scope">
                            <!-- label值要与el-table数据id实现绑定 -->
                            <el-radio v-model="item.specificationId" :disabled="scope.row.feesType===3" :label="scope.row.uuid" @change="handleRowChange(item,scope.$index,scope.row)">{{ "" }}</el-radio>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="规格名称"
                          prop="name"
                          min-width="120"
                          fixed="left"
                        />
                        <el-table-column
                          label="收费标准"
                          min-width="120"
                          prop="feesType"
                        >
                          <template slot-scope="scope">
                            {{ STATUS[scope.row.feesType]||'--' }}
                            <el-popover
                              v-if="scope.row.feesType===3"
                              placement="top"
                              title=""
                              trigger="hover"
                              content="当前规格为价格面议，无法在线购买！"
                            >
                              <i slot="reference" class="el-icon-warning-outline" />
                            </el-popover>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="当前价格"
                          min-width="100"
                          prop="guidePrice"
                        />
                      </el-table>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="bottomBox_agreement_detail">
            <el-checkbox v-model="flag" :label="true" class="radio" @click="getCheck">  <div class="s-grayColor">同意<a class="s-blueColor" @click="dialogFlag=true">优客云用户使用协议、隐私协议</a></div></el-checkbox>
          </div>
          <div class="bottom-btn">
            <el-button class="purchase" type="primary" @click="getPurchase()">一键购买</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 选择地址 -->

    <to-be-paid
      ref="payment"
      :drawer-flag="drawerFlag"
      :money="money"
      :ordtype="2"
      :ispay="2"
      :platform-order-number="platformOrderNumber"
      :business-order-uuid="businessOrderUuid"
      :tab-type="tabType"
      @changeShow="changeShow"
    />
    <agreement :dialog-flag="dialogFlag" @agreementFlag="agreementFlag" />
  </div>
</template>

<script>
import {
  getCloudMarketInfo,
  getDefaultAddress,
  getSolutionOrder,
  getPurchaseSolutionr
} from '@/api/cloudMarket'
import toBePaid from '../../components/toBePaid.vue'
import { math } from '@/utils/math.js'
import noData from '../../components/noData.vue'
import { deepClone } from '@/utils'
import { addCollect, cancelCollect, isCollect } from '@/api/dashboard'
import addressCheck from '../../components/address.vue'
import agreement from '@/views/cloudMarket/components/agreement'
import { jumpPath } from '@/utils/jump'
const STATUS = {
  1: '免费',
  2: '收费',
  3: '价格面议'
}
export default {
  name: 'CloudMarketSolutionDetails',
  components: { toBePaid, noData, addressCheck, agreement },

  data() {
    return {
      STATUS,
      jumpPath,
      isonlyapplication: false, // 是否只有应用
      drawer: false,
      direction: 'rtl',
      dialogFlag: false,
      department: [],
      flag: false,
      drawerFlag: false,
      detailInfo: {},
      addressList: [],
      useAddressInfo: {},
      orderList: [],
      orderType: {
        '1': '应用',
        '2': '服务',
        // '3': '智能硬件'
        '3': '商品'
      },
      title: '',
      organScopeUuids: [],
      platformOrderNumber: null,
      quantity: 0,
      tabType: 3,
      collectionStatus: false,
      uuid: '',
      businessOrderUuid: '',
      ggUuid: ''
    }
  },
  computed: {
    money() {
      let sum = 0
      this.orderList.forEach(item => {
        if (item.active) {
          const a = Number(sum)
          const b = Number(item.money)
          sum = math.add(a, b)
        }
      })
      return sum
    }
  },
  mounted() {
    this.getDetail()
    this.uuid = this.$route.query.uuid
  },
  methods: {
    getCheck() {
      this.flag = !this.flag
    },
    // 关闭协议
    agreementFlag(data) {
      if (data === 'false') {
        this.dialogFlag = false
      } else {
        this.dialogFlag = true
      }
    },
    collectHandler(status) {
      if (status) {
        addCollect({
          businessType: 2003010104,
          businessUuid: this.$route.query.uuid,
          businessName: this.detailInfo.title
        }).then(res => {
          this.$message.success('已收藏！')
        }).catch(e => {
          this.collectionStatus = !this.collectionStatus
        })
      } else {
        cancelCollect({
          businessType: 2003010104,
          businessUuid: this.$route.query.uuid,
          type: 1
        }).then(res => {
          this.$message.success('取消收藏！')
        }).catch(e => {
          this.collectionStatus = !this.collectionStatus
        })
      }
    },
    // 返回
    back() {
      this.$router.push({ path: '/cloudMarket', query: { tabType: this.$route.query.tabType }})
    },
    // 一键安装
    getInstall() {
      if (this.$store.getters.userInfo.adminFlag === true) {
        this.drawer = true
        this.getSolutionOrder()
      } else {
        this.$message.error('非管理员暂时无法安装，请联系管理员安装！')
      }
    },
    // 关闭订单信息
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {
        })
    },
    // 选择查看范围
    getRange(i) {
      this.$refs.chosepart[i].openModal()
    },

    getSpecification(item) {
      item.flag = !(item.flag)
    },
    // 右边栏服务规格
    getService() {
      this.serviceFlag = !(this.serviceFlag)
    },
    handleRowChange(item, j, row) {
      // this.ggUuid = row.uuid
      item.tagId = j
      item.specificationId = item.specifications[j].uuid
      item.specificationsName = item.specifications[j].name
      item.price = item.specifications[j].price
      item.money = item.specifications[j].price
      this.handleChange(item)
    },
    // 选中的规格
    getTags(item, j) {
      // item.tagId = j
      // item.specificationId = item.specifications[j].uuid
      // item.specificationsName = item.specifications[j].name
      // item.price = item.specifications[j].price
      // item.money = item.specifications[j].price
      // this.handleChange(item)
    },
    // 解决方案详情
    getDetail() {
      const load = this.$loading()
      getCloudMarketInfo({ uuid: this.$route.query.uuid }).then(res => {
        console.log(res.data.solutionInfo.introduction, 'introduction')
        this.detailInfo = res.data.solutionInfo
        this.collectionStatus = res.data.solutionInfo.collectFlag
        load.close()
      }).catch(e => {
        load.close()
      })
    },
    // 确认订单信息
    getSolutionOrder() {
      const load = this.$load()
      this.orderList = []
      getSolutionOrder({ solutionUuid: this.$route.query.uuid }).then(res => {
        res.data.orders.forEach(item => {
          // 对每个商品数据进行预处理
          item.flag = false
          item.specificationsName = ''
          item.tagId = null
          // item.active = false

          item.price = 0
          item.money = 0
          item.department = []
          item.organScopeUuids = []
          // 找出规格中没有禁用的第一个规格，默认回显
          const idx = item.specifications.findIndex(i => i.isGray === 0 && i.feesType !== 3)
          if (idx !== -1) {
            item.active = true
            this.handleRowChange(item, idx, item.specifications[idx])
          }else {
            item.active = false
          }
          // this.ggUuid = item.specifications[idx].uuid
        })
        this.title = res.data.title
        this.orderList = res.data.orders
        if (this.orderList && this.orderList.length) {
          const bolen1 = this.orderList.findIndex(item => item.appType == 2)
          const bolen2 = this.orderList.findIndex(item => item.appType == 3)
          if (bolen1 == -1 && bolen2 == -1) {
            this.isonlyapplication = true
          } else {
            this.isonlyapplication = false
            this.getDefaultAddress()
          }
        }
        this.quantity = res.data.orders.length
        // this.getDefaultAddress()
        load.close()
      }).catch(e => {
        console.log(1, e)
        // this.getDefaultAddress()
        load.close()
      })
    },
    // 默认地址
    getDefaultAddress() {
      getDefaultAddress().then(res => {
        this.useAddressInfo = res.data || []
        this.orderList.forEach(ite => {
          ite.addressUuid = res.data.addressUuid || ''
        })
      })
    },
    // 使用地址
    getApply(item) {
      this.useAddressInfo = item
      this.orderList.forEach(ite => {
        ite.addressUuid = item.addressUuid
      })
    },
    changeShow(data) {
      if (data === 'false') {
        this.drawerFlag = false
      } else {
        this.drawerFlag = true
      }
    },
    // 一键购买
    async getPurchase() {
      if (this.flag === false) {
        this.$message.error('请先勾选协议')
        return
      }
      const arr = []
      const orderList = deepClone(this.orderList)

      console.log(orderList,'orderList---')
      orderList.forEach(item => {
        item.specifications = item.specificationId
        if (item.active) {
          arr.push(item)
        }
      })
      if (arr.length === 0) {
        this.$message.error('请至少选择一项')
        // load.close()
      } else {
        const feesTypeFlag = arr.some((item) => {
          return !item.specifications
        })
        console.log(feesTypeFlag, 'feesTypeFlag')
        if (feesTypeFlag) {
          this.$message.warning('当前购买规格包含价格面议，不支持在线购买，请咨询销售人员！')
          return false
        }
        // 判断付款的订单中是否有应用
        const typeFlag = arr.some(item => {
          return item.appType == 1
        })
        if (typeFlag == true) {
          this.tabType = 3
        } else {
          this.tabType = 4
        }

        // 判断为应用且选择了可见范围
        const flag = arr.some(item => {
          return (item.appType === 1 && item.specificationId && item.organScopeUuids.length === 0)
        })

        if (flag === false) {
          const addressFlag = arr.every(item => {
            return item.addressUuid
          })
          // 是否有订单地址
          if (addressFlag === true || this.isonlyapplication) {
            const load = this.$loading()
            try {
              await getPurchaseSolutionr({ orders: arr }).then(res => {
                this.platformOrderNumber = res.data.orderUuid
                this.businessOrderUuid = res.data.businessOrderUuid
              })
            } catch {
              load.close()
              return false
            }

            this.drawerFlag = true
            this.$refs.payment.getQueryPaymentMethod()
            this.$refs.payment.getPayOrderInfo()
            load.close()
          } else {
            this.$message.error('请选择收货地址')
          }
        } else {
          this.$message.error('请选择可见范围')
        }
      }
    },
    change(val, i) {
      console.log('change', val)
      // this.$set(this.orderList[i], 'organScopeUuids', val)
      this.orderList[i].organScopeUuids = val
    },
    getMessage() {
      this.$message.error('该规格已售空，请选择其他规格')
    },
    // 数量变化
    handleChange(item) {
      const a = Number(item.nums)
      const b = Number(item.price)
      if (item.price) {
        item.money = math.multiply(a, b)
      }
      this.quantity = 0
      this.orderList.forEach(item => {
        this.quantity = this.quantity + item.nums
      })
    },
    getClose() {
      this.drawer = false
    }
  }
}
</script>
