<template>
  <EnterpriseLayout>
    <div class="m_enterprise_management">
      <div class="m_management_boxl">
        <div class="m_management_title">
          <span>角色组</span>
        </div>
        <div class="m_management_inputkey">
          <el-input v-model="filterText" placeholder="请输入角色组织搜索" clearable maxlength="50">
            <i slot="prefix" class="gp-input__icon el-icon-search" />
          </el-input>
          <span v-permission="['11203040203']" class="m_management_adds" @click="addrole" />
        </div>
        <div class="m_management_trees">
          <el-scrollbar style="height: 100%">
            <el-tree
              ref="tree"
              :default-expanded-keys="defaultExpandedKeys"
              class="filter-tree"
              :data="treeDate"
              :load="loadNode"
              :props="defaultProps"
              node-key="roleUuid"
              highlight-current
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              @current-change="currentTreeChange"
            >
              <span slot-scope="{ node, data }" class="custom-tree-node">
                <span class="m_management_treecurrent">
                  <span class="m_management_ltext">
                    <span class="m_management_rowname" :class="{m_management_color1:data.roleTypeCode==1003501}">
                      <svg-icon v-if="data.roleTypeCode==1003501" icon-class="role" />
                      <UmToolTip :content="data.roleName" class="row_1" />
                    </span>
                  </span>
                  <span v-if="node.isCurrent" style="width: 28px;position: absolute;right: 0;" class="m_org_tree_dropdow_more flex-center" @click.stop>
                    <el-popover
                      popper-class="m_enterpris_popover"
                      placement="right-start"
                      width="112"
                      :visible-arrow="false"
                      trigger="click"
                    >
                      <svg-icon v-if="data.roleTypeCode==1003501||data.readOnlyFlag!=1" slot="reference" v-permission="['11203040204','11203040207','11203040208']" icon-class="ellipsis" class-name="custom-class" @click="chose(data)" />
                      <div>
                        <div class="m_enterpris_popover_edit-wrap">
                          <div v-if="data.roleTypeCode==1003501" v-permission="['11203040204']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => addsondepartment( data)">
                            <span class="m_enterpris_popover_add-icon" />
                            <span>新建角色</span>
                          </div>
                          <div v-if="data.readOnlyFlag!=1" v-permission="['11203040207']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => editdepartment(data)">
                            <span class="m_enterpris_popover_edit-icon" />
                            <span>编辑</span>
                          </div>
                          <div v-if="data.readOnlyFlag!=1" v-permission="['11203040208']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => deletedepartment(node, data)">
                            <span class="m_enterpris_popover_del-icon" />
                            <span>删除</span>
                          </div>
                        </div>
                      </div>
                    </el-popover>
                  </span>
                </span>
              </span>
            </el-tree>
          </el-scrollbar>
        </div>
        <div>
          <el-dialog
            :title="daligotxt"
            :visible.sync="addVisible"
            width="522px"
            center
          >
            <el-form ref="addValidateForm" :model="addForm" label-width="95px" class="demo-ruleForm" @submit.native.prevent>
              <el-form-item
                label="角色组名称"
                prop="roleName"
                :rules="[
                  { required: true, message: '请输入角色组名称',trigger: 'change'}
                ]"
              >
                <el-input v-model="addForm.roleName" type="age" autocomplete="off" placeholder="请输入角色组名称" maxlength="20" clearable />
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <span><el-button @click="addresetForm">取 消</el-button></span>
              <span><el-button type="primary" @click="addsubmitForm">确 定</el-button></span>
            </span>
          </el-dialog>
          <el-dialog
            :title="daligotxt1"
            :visible.sync="addVisible1"
            width="522px"
            center
          >
            <span v-if="addoredit==1">
              <el-form ref="addForm1" :model="addForm1" :rules="addrules" label-width="95px" class="demo-ruleForm" @submit.native.prevent>
                <el-form-item label="角色名称" prop="roleName">
                  <el-input v-model="addForm1.roleName" placeholder="请输入角色名称" clearable maxlength="20" />
                </el-form-item>
              </el-form>
            </span>
            <span v-else>
              <el-form ref="editForm" :model="editForm" :rules="editrules" label-width="95px" class="demo-ruleForm" @submit.native.prevent>
                <el-form-item label="角色名称" prop="roleName">
                  <el-input v-model="editForm.roleName" placeholder="请输入角色名称" clearable maxlength="20" />
                </el-form-item>
              </el-form>
            </span>
            <span v-if="addoredit==1" slot="footer" class="dialog-footer">
              <span><el-button @click="quitadd">取 消</el-button></span>
              <span><el-button type="primary" @click="sureadd">确 定</el-button></span>
            </span>
            <span v-else slot="footer" class="dialog-footer">
              <span><el-button @click="quitedit">取 消</el-button></span>
              <span><el-button type="primary" @click="sureedit">确 定</el-button></span>
            </span>
          </el-dialog>
        </div>
      </div>
      <div class="m_management_boxr">
        <div v-if="management" class="m_management_rcontent">
          <div class="m_management_rtitle">{{ toptitle }}</div>
          <div class="m_management_rbutton">
            <div v-if="roleTypeCode!=1003501" class="m_management_btnl">
              <div class="m_management_add">
                <el-button v-if="readOnlyFlag!=1" type="primary" @click="authorityManagement">权限管理</el-button>
                <el-button v-permission="['11203040205']" type="primary" @click="addUser">
                  <span class="m_management_addicon" />
                  添加成员
                </el-button>
              </div>
            </div>
            <div class="m_management_btnr">
              <div class="m_management_join">
                <!--                <div class="m_management_delet">-->
                <!--                  <el-button type="danger">-->
                <!--                    <span class="m_management_del" />-->
                <!--                    删除角色组-->
                <!--                  </el-button>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
          <div class="mar_top20">
            <el-table
              stripe
              height="calc(100vh - 285px)"
              :data="tableData"
              style="width: 100%"
            >
              <!-- @selection-change="handleSelectionChange" -->
              <!-- <el-table-column
                type="selection"
                width="65"
              /> -->
              <el-table-column
                prop="xh"
                label="序号"
                width="80"
              />
              <el-table-column
                prop="userName"
                label="姓名"
                min-width="120"
              >
                <template slot-scope="scope">
                  <div style="display: flex;align-items: center">
                    <UmToolTip :content="scope.row.userName" class="row_2" />
                    <span v-if="scope.row.enterpriseFounderFlag==1" class="m_management_founder">创建人</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="userPosition"
                label="职务"
                min-width="120"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.userPosition||'-' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="userDepartment"
                label="部门"
                min-width="200"
              >
                <template slot-scope="scope">
                  <span><UmToolTip :content="scope.row.userDepartment" class="row_2" /></span>
                </template>
              </el-table-column>
              <el-table-column
                prop="tel"
                label="操作"
                width="120"
              >
                <template slot-scope="scope">
                  <div class="m_management_operationbtn">
                    <span v-if="scope.row.enterpriseFounderFlag!=1" v-permission="['11203040206']" class="m_management_shanchu" @click="delitem(scope.row)">删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="m_common_paginations">
            <pagination
              :background="false"
              :total="tableTotal"
              :page.sync="page.pageNum"
              :limit.sync="page.pageSize"
              :layout="'total, sizes, prev, pager, next, jumper'"
              @pagination="get_role_user_pages('page')"
            />
          </div>
        </div>
        <div v-if="!management" class="m_management_rcontent">
          <div class="change">
            <authoritymanagement :toptitle="toptitle" :role-uuid="roleUuid" @changemanagement="changemanagement" />
          </div>
        </div>
      </div>
    </div>
  </EnterpriseLayout>
</template>

<script>
import {
  get_role_tree,
  get_role_user_page,
  add_role,
  update_role,
  delete_role,
  delete_role_user,
  add_role_user
} from '@/api/enterprise'
import authoritymanagement from './authorityManagement'
export default {
  name: 'EnterpriseRole',
  components: { authoritymanagement },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('请输入短信验证码'))
      } else {
        callback()
      }
    }
    return {
      defaultExpandedKeys: [], // 默认展开数组
      roleUuid: '',
      chosenode: {},
      defaultProps: {
        children: 'children',
        label: 'roleName',
        isLeaf: 'leaf',
        disabled: (data, node) => { return data.readOnlyFlag }
      },
      treeDate: [],
      addPeople: null,
      imgurl: null,
      tableTotal: 0,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      management: true,
      filterText: '',
      roleTypeCode: '',
      readOnlyFlag: '',
      toptitle: '',
      // treeData: [],
      tableData: [],
      multipleSelection: [], // 选择的集合
      // isIndeterminate: false,
      checkAll: false,
      addVisible: false, // 创建角色组
      addVisible1: false, // 创建角色组
      // addpeople: false, // 添加角色组成员
      // pretendvisible: false, // 转让创建人
      addForm: { roleName: '', parentRoleUuid: '' },
      addForm1: { roleName: '', parentRoleUuid: '' },
      editForm: {
        roleName: '',
        roleUuid: ''
      },
      editrules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'change' }
        ]
      },
      addrules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'change' }
        ]
      },
      addoredit: 1, // 1 添加 2 编辑
      daligotxt: '提示',
      daligotxt1: '提示',
      smsFormRules: { smsCode: [{ required: true, trigger: 'change', validator: validatePassword }] }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  created() {
    this.get_role_tree()
  },
  methods: {
    // 筛选树
    filterNode(value, data) {
      if (!value) return true
      return data.roleName.indexOf(value) !== -1
    },
    // 获取树
    get_role_tree(row) {
      get_role_tree({
        keyword: ''
      }).then(d => {
        this.treeDate = d.data
        if (d.data) {
          this.toptitle = d.data[0].roleFullName
          this.roleTypeCode = d.data[0].roleTypeCode
          this.readOnlyFlag = d.data[0].readOnlyFlag
          this.roleUuid = d.data[0].roleUuid
          this.get_role_user_pages()
        }
        this.defaultExpandedKeys = d.data.map(item => item.roleUuid) // 默认展开第一级
        this.$nextTick(() => {
          if (row) {
            this.$refs.tree.setCurrentNode(row)
          } else {
            this.$refs.tree.setCurrentNode(d.data[0])
          }
        })
      }).finally(_ => {

      })
    },
    // 新建子部门
    addsondepartment(val) {
      this.chosenode = val
      this.addForm1.parentRoleUuid = val.roleUuid
      this.daligotxt1 = '创建角色'
      this.addoredit = 1
      this.addVisible1 = true
    },
    // 确认添加子部门
    sureadd() {
      this.$refs['addForm1'].validate((valid) => {
        if (valid) {
          add_role({ ...this.addForm1 }).then(d => {
            const data = d.data
            if (!data.children) {
              this.$set(data, 'children', [])
            }
            this.chosenode.children.push(data)
            // const newChild = d.data
            // this.chosenode.children.push(newChild)
            this.$message({
              type: 'success',
              message: '创建成功!'
            })
          }).finally(_ => {
            this.$refs.addForm1.resetFields()
            this.addVisible1 = false
          })
        } else {
          return false
        }
      })
    },
    // 取消添加子部门
    quitadd() {
      this.addVisible1 = false
    },
    // 编辑
    editdepartment(val) {
      this.editForm.roleName = val.roleName
      this.editForm.roleUuid = val.roleUuid
      this.daligotxt1 = '编辑角色'
      this.addVisible1 = true
      this.addoredit = 2
    },
    // 确认编辑
    sureedit() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          update_role({ ...this.editForm }).then(d => {
            Object.assign(this.chosenode, d.data)
            // this.get_role_tree()
          }).finally(_ => {
            this.$refs.editForm.resetFields()
            this.addVisible1 = false
          })
        } else {
          return false
        }
      })
    },
    // 取消编辑
    quitedit() {
      this.addVisible1 = false
    },
    // 左侧树删除
    deletedepartment(node, data) {
      this.$confirm(`${data.roleName}`, '是否确认删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
        center: true
      }).then(() => {
        delete_role({
          roleUuid: data.roleUuid
        }).then(d => {
          const parent = node.parent
          const children = parent.data.children || parent.data
          const index = children.findIndex(d => d.roleUuid === data.roleUuid)
          children.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.get_role_tree()
        }).finally(_ => {
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        })
      })
    },
    loadNode(node, resolve) {},
    currentTreeChange(data, checked, indeterminate) {
      this.roleUuid = data.roleUuid
      this.toptitle = data.roleFullName
      this.roleTypeCode = data.roleTypeCode
      this.readOnlyFlag = data.readOnlyFlag
      this.management = true
      this.get_role_user_pages()
    },
    get_role_user_pages() {
      if (this.roleTypeCode == 1003501) {
        this.tableData = []
        this.tableTotal = 0
        return
      }
      const load = this.$load()
      get_role_user_page({
        roleUuid: this.roleUuid,
        page: this.page
      }).then(d => {
        this.tableData = d.data.list
        this.page.pageNum = d.data.page.pageNum
        this.page.pageSize = d.data.page.pageSize
        this.tableTotal = d.data.page.total
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    changemanagement(val) {
      this.management = val
    },
    //  添加角色组
    addrole() {
      this.daligotxt = '创建角色组'
      this.addVisible = true
    },
    // 确认创建角色组
    addsubmitForm() {
      this.$refs.addValidateForm.validate((valid) => {
        if (valid) {
          add_role({ ...this.addForm }).then(d => {
            // const data = d.data
            // this.treeDate.push(data)
            const data = d.data
            if (!data.children) {
              this.$set(data, 'children', [])
            }
            this.treeDate.push(data)
            this.$message({
              type: 'success',
              message: '创建成功!'
            })
          }).finally(_ => {
            this.$refs.addValidateForm.resetFields()
            this.addVisible = false
          }).catch(e => {
            this.$errorHandle(e)
          })
        } else {
          return false
        }
      })
    },
    // 取消创建
    addresetForm() {
      this.$refs.addValidateForm.resetFields()
      this.addVisible = false
    },
    // 点击角色组
    choseitem(index, val) {
      this.toptitle = val
    },
    chose(val) {
      this.chosenode = val
    },
    // 添加成员
    addUser() {
      // this.addpeople = true
      this.$selectUser({
        searchTip: '请输入用户姓名',
        title: '添加角色组成员',
        value: [], // 用来回显使用
        limit: 0,
        type: 2,
        authType: 0,
        userCountFlag: 0,
        changeHandler: (result, showList) => {
          //  // key 值
          //  // 回显 的对象
          //
          if (result.length == 0) {
            this.$message({
              type: 'warning',
              message: '请选择人员!'
            })
            return false
          }
          add_role_user({
            roleUuid: this.roleUuid,
            userUuidList: result
          }).then(d => {
            this.$message({
              type: 'success',
              message: '添加成功!'
            })
            this.get_role_user_pages()
          }).finally(_ => {
          }).catch(e => {
            this.$errorHandle(e)
          })
        }
      })
    },
    // 确认添加成员
    addperson() {
      this.addpeople = false
    },
    // 删除成员
    delitem(data) {
      const username = data.userName
      this.$confirm(`${username}`, '是否确认删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'message-dialog',
        // type: 'warning',
        center: true
      }).then(() => {
        delete_role_user({
          roleUuid: this.roleUuid,
          userUuidList: [data.userUuid]
        }).then(d => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.get_role_user_pages()
        }).finally(_ => {
        })
      }).catch((e) => {
        this.$errorHandle(e)
        this.$message({
          type: 'info',
          message: '取消删除'
        })
      })
    },
    // 权限管理
    authorityManagement() {
      this.management = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }

  }
}
</script>

<style scoped lang="scss">
</style>
