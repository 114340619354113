<template>
  <div class="m_enterprise_activityadd detailsBox">
    <div class="go_back_common">
      <div class="go_back_title" @click="back">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="formBody">
      <div class="title-dh mb20 mt20">基本信息</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" label-width="100px">
        <el-form-item ref="activityImageUrl" label="活动图片" prop="activityImageUrl">
          <div class="m_activityadd_tips">
            <UmUploadImg v-model="ruleForm.activityImageUrl" :show-icon="false" custom-file :limit="1" is-cropper :cropper-options="cropperOptions">
              <template #extend>
                <div class="m_activityadd_tips2">
                  <div class="m_activityadd_imgicon" />
                </div>
              </template>
            </UmUploadImg>
          </div>
        </el-form-item>
        <el-form-item ref="activityName" label="活动名称" prop="activityName">
          <el-input v-model="ruleForm.activityName" style="width:40%" placeholder="请填写活动名称" maxlength="50" />
        </el-form-item>
        <el-form-item ref="activityAddress" label="活动地点" prop="activityAddress">
          <el-input v-model="ruleForm.activityAddress" style="width:40%" placeholder="请填写活动地点" maxlength="50" />
        </el-form-item>
        <el-form-item label="报名时间" prop="time2">
          <el-date-picker
            v-model="ruleForm.time2"
            type="datetimerange"
            style="width: 40%"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            @change="pickerChangeBm"
          />
        </el-form-item>
        <el-form-item label="活动时间" prop="time1">
          <el-date-picker
            v-model="ruleForm.time1"
            type="datetimerange"
            style="width: 40%"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            @change="pickerChangeHd"
          />
        </el-form-item>
        <el-form-item ref="signUserMaxNum" label="报名人数" prop="signUserMaxNum">
          <div class="m_activityadd_peopleNum">
            <span class="add ninus" :class="{add_disabled:ruleForm.signUserMaxNum<=1}" @click="minusNum">-</span>
            <div><el-input v-model="ruleForm.signUserMaxNum" style="width: 48px;" :clearable="false" @input="resetnum" /></div>
            <span class="add" :class="{add_disabled:ruleForm.signUserMaxNum>=9999}" @click="addNum">+</span>
          </div>
        </el-form-item>
        <el-form-item ref="activityContent" label="详细内容" prop="activityContent">
            <um-edit-pro v-model="ruleForm.activityContent"  :url="ruleForm.activityContestUrl"/>
          <!-- <Tinymce
            v-model="ruleForm.activityContent"
            :height="200"
            :url="ruleForm.activityContestUrl"
            :width="'98%'"
          /> -->
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" class="m_activityadd_btnsure" @click="submit">确认保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { activityUpdate_activity, activityQuery_activity_info } from '@/api/enterprise'

export default {
  name: 'EnterpriseApplicationActivityUpdate',
  props: {},
  data() {
    const validateTime = (rule, value, callback) => {
      console.log(value, 'value--------------')
      if ((!value) || (!value.length)) {
        callback(new Error('请选择报名时间段'))
      } else if (value && value.length) {
        const time1 = Date.parse(value[1])
        const time2 = Date.now()
        if (time1 < time2) {
          callback(new Error('报名结束时间不可早于当前时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateTime1 = (rule, value, callback) => {
      if ((!value) || (!value.length)) {
        callback(new Error('请选择活动时间段'))
      } else if (value && value.length) {
        const time1 = Date.parse(value[0])
        const time2 = Date.now()
        const time3 = Date.parse(value[1])
        const time4 = Date.parse(this.ruleForm.time2[1])
        if (time1 < time2) {
          callback(new Error('活动开始时间不可早于当前时间'))
        } else
        if (time3 < time4) {
          callback(new Error('活动结束时间不可早于报名结束时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        activityName: '',
        activityImageUrl: '',
        activityAddress: '',
        activityStartTime: null,
        activityEndTime: null,
        activitySignStartTime: null,
        activitySignEndTime: null,
        time1: [],
        time2: [],
        signUserMaxNum: 1,
        activityContent: ''
      },
      pickerOptionsBm: {
        // 新增时报名结束结束时间不可早于当前时间，课程报名结束时间不可早于课程报名开始时间，课程开始时间不可早于当前时间，课程结束时间不可早已课程开始时间，课程结束时间不可早于课程报名结束时间
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      pickerOptionsKc: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      rules: {
        activityImageUrl: [{ required: true, message: '请选择活动图片', trigger: 'change' }],
        activityName: [
          { required: true, message: '请填写活动名称', trigger: 'blur' }
        ],
        activityAddress: [
          { required: true, message: '请填写活动地址', trigger: 'change' }
        ],
        time1: [
          // { required: true, message: '请选择预定时间段', trigger: 'change' }
          { required: true, validator: validateTime1, trigger: 'change' }
        ],
        time2: [
          // { required: true, message: '请选择预定时间段', trigger: 'change' }
          { required: true, trigger: 'change', validator: validateTime }
        ],
        signUserMaxNum: [
          { required: true, message: '请选择人数', trigger: 'change' }
        ],
        activityContent: [
          { required: true, message: '请填写详细内容', trigger: 'change' }
        ]
      },
      cropperOptions: {
        aspectRatio: 4 / 3
      },
      activityUuid: ''
    }
  },
  watch: {
  },
  created() {
    this.activityUuid = this.$route.query.activityUuid
    if (this.activityUuid) {
      this.getInfo()
    }
  },
  mounted() {},
  methods: {
    getInfo() {
      activityQuery_activity_info({ activityUuid: this.activityUuid }).then(res => {
        // this.ruleForm = res.data
        this.ruleForm.activityName = res.data.activityName
        this.ruleForm.activityImageUrl = res.data.activityImageUrl
        this.ruleForm.activityAddress = res.data.activityAddress
        this.ruleForm.signUserMaxNum = res.data.signUserMaxNum
        this.ruleForm.activityContent = res.data.activityContent
        this.ruleForm.activityContestUrl = res.data.activityContestUrl
        this.ruleForm.time1 = [res.data.activityStartTime, res.data.activityEndTime]
        this.ruleForm.time2 = [res.data.activitySignStartTime, res.data.activitySignEndTime]
      })
    },
    pickerChangeHd(data) {
      console.log('123456')
      console.log(data, 'data')
      if (data && data.length) {
        this.ruleForm.activityStartTime = data[0]
        this.ruleForm.activityEndTime = data[1]
      } else {
        this.ruleForm.activityStartTime = null
        this.ruleForm.activityEndTime = null
      }
    },

    pickerChangeBm(date) {
      console.log(date, 'data------------------')
      if (date && date.length) {
        this.ruleForm.activitySignStartTime = date[0]
        this.ruleForm.activitySignEndTime = date[1]
      } else {
        this.ruleForm.activitySignStartTime = null
        this.ruleForm.activitySignEndTime = null
      }
    },
    back() {
      this.$router.replace({ path: '/activity', query: { appUuid: this.$route.query.appUuid }})
    },
    addNum() {
      if (this.ruleForm.signUserMaxNum < 9999) {
        this.ruleForm.signUserMaxNum = this.ruleForm.signUserMaxNum * 1 + 1
      }
    },
    resetnum(val) {
      if (val * 1 >= 9999) {
        this.ruleForm.signUserMaxNum = 9999
      }
      if (val * 1 <= 1) {
        this.ruleForm.signUserMaxNum = 1
      }
      if (!/^\d+$|^\d+[.]?\d+$/.test(val)) {
        this.ruleForm.signUserMaxNum = 1
      }
    },
    minusNum() {
      if (this.ruleForm.signUserMaxNum > 1) {
        this.ruleForm.signUserMaxNum = this.ruleForm.signUserMaxNum * 1 - 1
      }
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.pickerChangeHd(this.ruleForm.time1)
          this.pickerChangeBm(this.ruleForm.time2)
          const load = this.$load()
          try {
            const params = {
              // ...this.ruleForm
              activityName: this.ruleForm.activityName,
              activityImageUrl: this.ruleForm.activityImageUrl,
              activityAddress: this.ruleForm.activityAddress,
              activityStartTime: this.ruleForm.activityStartTime,
              activityEndTime: this.ruleForm.activityEndTime,
              activitySignStartTime: this.ruleForm.activitySignStartTime,
              activitySignEndTime: this.ruleForm.activitySignEndTime,
              signUserMaxNum: this.ruleForm.signUserMaxNum,
              activityContent: this.ruleForm.activityContent
            }
            await activityUpdate_activity({
              ...params,
              activityUuid: this.activityUuid
            })
            this.$alert('保存成功！', '提示', {
              type: 'success',
              showClose: false
            }).then(d => {
              this.back()
            })
          } catch (e) {
            this.$errorHandle(e)
          } finally {
            load.close()
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
