<template>
  <div class="m_enterprise_authoritymanagement">
    <div class="m_authoritymanagement_head" @click="back">
      <span class="m_authoritymanagement_headicon" />
      <span class="m_authoritymanagement_headtxt">返回</span>
    </div>
    <div class="m_authoritymanagement_lines" />
    <div class="m_authoritymanagement_rtitle">{{ toptitle }}</div>
    <div class="m_authoritymanagement_formbody">
      <el-scrollbar style="height: 90%">
        <el-radio-group v-model="tab" style="margin-bottom: 20px">
          <el-radio-button :label="1">功能点权限</el-radio-button>
          <el-radio-button :label="2">应用权限</el-radio-button>
        </el-radio-group>
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm" label-position="left">
          <el-form-item v-if="ruleForm.type==3" label="选择部门" prop="roleUuid">
            <span class="UmBusUser">
              <UmBusUser ref="UmBusUser" v-model="ruleForm.roleUuid" :type="1" title="选择部门" :show-prefix-button="false" @result="resultChange">
                <template #user="{data}">
                  <span v-if="data&&data.length">
                    <span v-for="item in data" :key="item.id">
                      <span>{{ item.name }}</span>
                    </span>
                  </span>
                  <span v-else>未设置</span>
                  <span @click="changperson">设置</span>
                </template>

              </UmBusUser>
            </span>
          </el-form-item>
          <div v-show="tab==1" class="m_authoritymanagement_jichuquanxian">
            <el-tree
              v-for="(item,index) in treeData"
              :ref="`tree_${index}`"
              :key="index"
              :data="[item]"
              show-checkbox
              node-key="sysModuleCode"
              :props="defaultProps"
              :default-checked-keys="checkList[index]"
              style="margin-top:10px"
              check-on-click-node
            />
          </div>
          <div v-show="tab==2" class="m_authoritymanagement_yingyongquanxian">
            <div class="m_authoritymanagement_checkbox">
              <el-checkbox v-model="checkAllFlag">全部 <span style="color: #909DB6">(选择全部后，新添加应用也将自动授予管理权限)</span></el-checkbox>
              <el-checkbox-group
                v-model="checkeds"
              >
                <el-checkbox v-for="item in appList" :key="item.appUuid" :label="item.appUuid" :disabled="checkAllFlag?true:false">{{ item.appName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="m_authoritymanagement_bottombtn">
            <el-form-item>
              <div class="m_authoritymanagement_btn">
                <el-button type="primary" @click="submitForm">保存</el-button>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </el-scrollbar>
    </div>
    <div class="dialog">
      <el-dialog
        title="请选择发布范围"
        :visible.sync="choseVisible"
        width="559px"
        center
      >
        <div class="content" />
        <span slot="footer" class="dialog-footer">
          <span class="queren"><el-button type="primary" @click="surechose">确 定</el-button></span>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { get_role_update_auth_info, update_role_auth_submit } from '@/api/enterprise'
export default {
  name: 'Authoritymanagement',
  components: {},
  props: {
    toptitle: {
      type: String,
      default: ''
    },
    roleUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tree_id: 0,
      tab: 1,
      checkList: [],
      departmentHead: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'sysModuleName',
        disabled: (data, node) => {
          return data.readOnly
        }
      },
      choseVisible: false,
      checked: false,
      checkAllFlag: false,
      checkeds: [], // 选择的应用权限
      appList: [], // 应用权限列表
      ruleForm: {
        type: '4',
        checkList: [],
        partname: [],
        roleUuid: []
      },
      rules: {
        roleUuid: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        checkList: [
          { required: true, message: '请选择基础权限范围', trigger: 'change' }
        ],
        partname: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    treeDataFilter() {
      if (this.treeData.length > 0) {
        return this.treeData[this.tree_id].children
      } else {
        return []
      }
    }
  },
  watch: {
    roleUuid: {
      handler(val) {
        if (val) {
          this.get_user_update_infos()
        }
      },
      immediate: true
    },
    checkList: {
      handler(val) {
        if (val) {
          this.ruleForm.checkList = this.checkList
        }
      },
      immediate: true
    }
  },
  created() {
    // this.get_user_update_infos()
  },
  mounted() {},
  methods: {
    resultChange(val) {
      this.departmentHead = val
    },
    get_user_update_infos() {
      const load = this.$load()
      get_role_update_auth_info({
        roleUuid: this.roleUuid
      }).then(d => {
        // this.appList = d.data.roleAppManageAuth.appList
        this.appList = d.data.roleAppManageAuth.appList
        // this.$nextTick(_ => {
        this.treeData = d.data.roleModuleTree
        // if (Array.isArray(d.data.roleModuleTree)) {
        //   this.treeData = d.data.roleModuleTree.map(item => {
        //     return [].concat(item)
        //   })
        // }
        // })
        this.ruleForm.roleUuid = d.data.roleOrganAuth.organUuidList || []
        const flag = d.data.roleAppManageAuth.checkAllFlag
        if (flag == 1) {
          this.checkAllFlag = true
        } else {
          this.checkAllFlag = false
        }
        // this.ruleForm.type = d.data.roleOrganAuth.type
        const arr = []
        d.data.roleAppManageAuth.appList.forEach(item => {
          if (item.checked == 1) {
            arr.push(item.appUuid)
          }
        })
        this.checkeds = arr

        this.init()
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    init() {
      const list = []
      this.checkList = []
      function deep(list, newList) {
        list.forEach(item => {
          if (item.children && item.children.length > 0) {
            deep(item.children, newList)
          } else {
            if (item.checked) {
              newList.push(item.sysModuleCode)
            }
          }
        })
      }
      this.treeData.forEach(item => {
        const child = []
        if (item.children === undefined) {
          //
        } else {
          deep(item.children, child)
        }
        list.push(child)
      })
      this.checkList = list
    },
    back() {
      this.$emit('changemanagement', true)
    },
    submitForm() {
      const length = this.treeData.length
      const list = []
      for (let i = 0; i < length; i++) {
        let child = []
        child = child.concat(this.$refs[`tree_${i}`][0].getCheckedNodes().filter(item => {
          return !item.readOnly
        }))

        child = child.concat(this.$refs[`tree_${i}`][0].getHalfCheckedNodes().filter(item => {
          return !item.readOnly
        }))
        child = child.map(item => item.sysModuleCode)

        if (child.length > 0 && !this.treeData[i].readOnly) {
          child.push(this.treeData[i].sysModuleCode)
        }
        list.push(...child)
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const load = this.$load()
          update_role_auth_submit({
            roleUuid: this.roleUuid,
            roleOrganAuth: {
              // type: this.ruleForm.type,
              type: 4,
              organUuidList: this.ruleForm.roleUuid || []
            },
            roleModuleAuth: list,
            roleAppManageAuth: {
              checkAllFlag: this.checkAllFlag ? 1 : 0,
              appUuidList: this.checkeds
            }
          }).then(d => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          }).finally(_ => {
            load.close()
            this.$emit('changemanagement', true)
          }).catch(e => {
            this.$errorHandle(e)
            load.close()
          })
        } else {
          return false
        }
      })
    },
    changepart() {
      this.choseVisible = true
    },
    surechose() {
      this.choseVisible = false
    },
    changperson() {
      this.$refs.UmBusUser.openModal()
    }
    // 基础权限

  }
}
</script>

<style lang="scss" scoped>
</style>
