
<template>
  <div class="m_enterprise_organizationinfo">
    <!--  ------------------------勿删-------------------------  -->

    <!--    <el-form-->
    <!--      ref="searchForm"-->
    <!--      :model="searchForm"-->
    <!--      :inline="true"-->
    <!--    >-->
    <!--      <el-form-item label="全局授权" prop="check">-->
    <!--        <el-switch-->
    <!--          v-model="searchForm.check"-->
    <!--        />-->
    <!--      </el-form-item>-->
    <!--    </el-form>-->
    <el-row type="flex">
      <!--      <el-col :span="4">-->
      <!--        <el-card-->
      <!--          class="box-card"-->
      <!--          shadow="never"-->
      <!--          :body-style="{-->
      <!--            height: '100%',-->
      <!--            overflow: 'auto',-->
      <!--            padding: '20px 10px 0px',-->
      <!--          }"-->
      <!--        >-->
      <!--          <div class="list">-->
      <!--            <div-->
      <!--              v-for="(item, index) in list"-->
      <!--              :key="index"-->
      <!--              :class="{ on: selectId == item.id }"-->
      <!--              @click="selectIdClick(item.id)"-->
      <!--            >{{ item.name }}</div>-->
      <!--          </div>-->
      <!--        </el-card>-->
      <!--      </el-col>-->
      <!--  ----------------------------------------------------------------------    -->
      <el-col :span="24">
        <el-card
          class="m_organizationinfo_card"
          shadow="never"
          :body-style="{
            height: '100%',
            overflow: 'auto',
            padding: '20px 20px 55px',
          }"
        >
          <div v-permission="['112030201109']" class="fr">
            <el-button type="primary" @click="addVisible">添加</el-button>
          </div>
          <el-tree
            ref="trees"
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            node-key="organId"
            highlight-current
            style="margin-top: 40px;margin-bottom: 55px;"
            :default-expanded-keys="idArr"
            @current-change="currentTreeChanges"
          >
            <span slot-scope="{ node, data }" class="m_organizationinfo_treenode" :class="{m_organizationinfo_isOnly:data.readOnlyFlag==1}">
              <span class="m_organizationinfo_item">
                <span class="m_part_name" :class="{m_organizationinfo_tagisOnly:data.readOnlyFlag==1}">
                  <span v-if="data.organTypeCode==1001502">公司</span>
                  <span v-if="data.organTypeCode==1001503">部门</span>
                  <span v-if="data.organTypeCode==1001504">岗位</span>
                </span>
                <span class="dib" style="font-size: 14px;">
                  {{ node.label }}
                </span>
              </span>
              <span class="m_organizationinfo_treenode_edit" :class="{ on: node.isCurrent&&data.readOnlyFlag!==1 }">
                <el-tooltip
                  effect="dark"
                  content="删除"
                  placement="top"
                >
                  <!--                   v-if="node.level > 1"-->
                  <el-button type="text" size="mini" @click.stop="() => remove(node, data)">
                    <i class="el-icon-delete" />
                  </el-button>
                </el-tooltip>
              </span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      :visible="visible"
      title="添加权限"
      width="800px"
      destroy-on-close
      @close="$refs.add.resetFields(),visible=false"
    >
      <el-form ref="add" :model="add" label-width="100px" :rules="rule">
        <div style="color:#ccc">
          <i style="margin-left: 5px; cursor: pointer" class="el-icon-warning-outline" />
          <span style="font-weight: normal;">如果给父级授权，后续父级下再新增子级会自动授权</span>
        </div>
        <el-form-item
          ref="userOrganIds"
          prop="userOrganIds"
          label="组织权限"
        >
          <el-tree
            v-if="options"
            ref="organTree"
            node-key="organUuid"
            :data="options"
            style="margin-top: 5px;"
            :props="{
              value: 'organUuid', label: 'organName', children: 'children',
              disabled: function(data, node) {
                return Boolean(data.readOnlyFlag)
              }
            }"
            check-on-click-node
            :check-strictly="true"
            show-checkbox
            :default-expanded-keys="defaultExpandedOrgan"
            @check="organCheck"
            @check-change="organCheckChange"
          />
          <div v-else>暂无数据</div>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="visible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  get_user_organ_auth_tree,
  get_user_add_organ_auth_tree,
  add_organ_auth_submit,
  remove_organ_auth_submit
} from '@/api/manage.js'
import { deepClone } from '@/utils'

export default {
  name: 'OrganizationInfo',
  props: {
    orgUserUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      console: window.console,
      selectId: 0,
      // orgUserUuid: _this.$route.query.orgUserUuid,
      searchForm: {
        check: true,
        organUuid: ''
      },
      props_: {
        disabled: function(data, node) {
          return Boolean(data.data.readOnlyFlag)
        }
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'organName',
        disabled: (data, node) => {
          return data.readOnlyFlag
        }
      },
      idArr: [],
      busOrganObjs: {}, // 当前项目架构
      visibleProject: false,
      list: [
        {
          name: '组织管理', id: 0
        },
        {
          name: '物业缴费', id: 1
        },
        {
          name: '工单管理', id: 2
        }
      ],
      visible: false,
      add: {
        userOrganIds: []
      },
      checkList: [
        { busCheckItemName: '1', busCheckItemId: 1 },
        { busCheckItemName: '2', busCheckItemId: 2 },
        { busCheckItemName: '3', busCheckItemId: 3 }
      ],
      remoteLoading: false,
      rule: {
        userOrganIds: [{ required: true, message: '请选择组织', trigger: 'change' }]
      },
      options: [], // 添加项目树
      defaultExpandedOrgan: []
    }
  },
  watch: {
  },
  created() {},
  mounted() {
    this.getTrees()
    // this.getOrgTree()
  },
  methods: {
    organCheckChange(data, checked) {
      console.log(data, checked)
      // 父选中 子节点 全部选中
      if (checked && data && data.children) {
        data.children.forEach(item => {
          this.$refs.organTree.setChecked(item.organUuid, true)
          if (item.children) {
            this.organCheckChange(item, true)
          }
        })
      }
      if (!checked && data && data.children.length > 0) {
        const arr = this.$refs.organTree.getCheckedKeys()
        console.log(arr)
        const tag = data.children.some(item => {
          return !(arr.includes(item.organUuid))
        })
        console.log(tag)
        if (!tag) {
          data.children.forEach(item => {
            this.$refs.organTree.setChecked(item.organUuid, false)
          })
        }
      }
      // 子取消 父节点也取消
      if (!checked) {
        // this.$refs.organTree.setChecked(data.parentId, false)
        if (data.organParentUuid) {
          this.$refs.organTree.setChecked(data.organParentUuid, false)
        }
      }
    },
    organCheck(data, { checkedKeys, checkedNodes, halfCheckedKeys, halfCheckedNodes }) {
      console.log(arguments, checkedKeys)
      this.add.userOrganIds = checkedKeys
      this.$refs.add.validate()
    },
    // 获取添加项目树
    getOrgTree(callback) {
      get_user_add_organ_auth_tree({
        orgUserUuid: this.orgUserUuid
      }).then(d => {
        this.options = d.data
        // this.defaultExpandedOrgan = d.data.map(item => item.organUuid)
        this.defaultExpandedOrgan = this.options.map(item => item.organUuid)
        callback && callback()
      })
    },
    // 项目树数据
    getTrees() {
      get_user_organ_auth_tree({ orgUserUuid: this.orgUserUuid }).then((d) => {
        this.treeData = d.data
        if (d.data.length > 0) {
          this.idArr = d.data.map(item => item.organId) // 默认展开第一级
          this.$nextTick(() => {
            console.log(typeof this.$refs.trees, d)
            this.$refs.trees && (this.$refs.trees.setCurrentNode(d.data[0]))
            // this.searchParam.orgOrganId = d.data[0].orgOrganId // 搜索条件
            this.busOrganObjs = d.data[0] // 当前选中对象
          })
        }
      })
    },

    // 项目选中当前节点
    currentTreeChanges(row, node) {
      console.log(row)
      // this.searchParam.orgOrganId = row.orgOrganId
      this.busOrganObjs = row
    },
    addVisible() {
      this.getOrgTree(() => {
        this.visible = true
      })
    },
    // 删除项目
    remove(node, data) {
      console.log(node, 'node-------------------', data)
      this.$confirm(`是否删除组织:${data.organName}?`, '提示', {
        type: 'warning'
      }).then(() => {
        console.log(this.orgUserUuid, 'this.orgUserUuid-----')
        const load = this.$load()
        remove_organ_auth_submit({
          orgUserUuid: this.orgUserUuid,
          organUuidList: [data.organUuid]
        }).then((d) => {
          this.$message.success('删除成功!')
          load.close()
          this.getTrees()
        }).catch((e) => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message.info('已取消删除!')
      })
    },

    selectIdClick(id) {
      this.selectId = id
    },
    submit() {
      this.$refs.add.validate((valid) => {
        if (valid) {
          add_organ_auth_submit({ organUuidList: this.add.userOrganIds, orgUserUuid: this.orgUserUuid }).then(res => {
            this.$message.success('操作成功')
            this.$refs.add.resetFields()
            this.visible = false
            this.getTrees()
          }).catch(e => {
            this.$errorHandle(e)
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
