<template>
  <div
    ref="wrapper"
    class="g-app-wrapper"
    :class="{
      'g-app-website':$isWebsite
    }"
  >
    <sidebar v-if="!$isWebsite" class="sidebar-container" />
    <div class="main-container">
      <div v-if="showTagsView" class="fixed-header hasTagsView">
        <tags-view v-if="!$isWebsite" />
        <div v-if="showMarket" v-permission="['1120203']" class="cloud-market" @click="goMarket">
          <div><svg-icon icon-class="cloudmarket" /></div>
          <div class="cloud-market-txt">云市场</div>
        </div>
      </div>
      <app-main />
    </div>
    <ImDialog />
    <ImVideo />
    <!--    用户审核弹窗-->
    <ApplyAction :pu-user-uuid="puUserUuid" :invitationaction.sync="applyVisible" @success="successApply" @changevisibleaction="applyClose" />

    <!--    用户待办跳转无权限弹窗-->
    <AppQrcode :isapp-uuid="isappUuid" :invitation.sync="invitation" @changevisible="changevisible" />
  </div>
</template>

<script>
import ApplyAction from '@/views/administration/applyAction'
import AppQrcode from '@/layout/appQrcode'
import { AppMain, Sidebar, TagsView } from './components'
import imSdk from '@um/im'
import { jumpUrl } from '@/utils/backlog'
import _ from 'lodash'
export default {
  name: 'Layout',
  components: {
    AppMain,
    Sidebar,
    TagsView,
    ApplyAction,
    AppQrcode
  },
  data() {
    return {
      applyVisible: false,
      puUserUuid: '',
      delCallback: null,
      jumpUrl,
      isappUuid: '',
      invitation: false,
      resizeObserver: null
    }
  },
  computed: {
    showTagsView() {
      return this.$route.name !== 'ImMessage'
    },
    showMarket() {
      return this.$route.path === '/dashboard/index'
    }
  },
  watch: {
  },
  created() {
    // const YX = imSdk.init(this.$store.state.user.imAccountId, this.$store.state.user.imAccountToken)
    // this.$store.dispatch('umIm/setState', { key: 'im', value: YX })
    imSdk.bus.on('message-click', this.messageHandler)
    imSdk.bus.on('apply-join', this.applyHandler)
  },
  mounted() {
    if (this.$isWebsite) {
      const resizeHandler = _.debounce(this.resize, 300)
      this.resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          resizeHandler({ width: entry.contentRect.width, height: entry.contentRect.height })
        }
      })
      this.resizeObserver.observe(this.$refs.wrapper)
    }
  },
  beforeDestroy() {
    if (this.$isWebsite) {
      this.resizeObserver.unobserve(this.$refs.wrapper)
    }
  },
  methods: {
    resize({ width, height }) {
      console.log({ width, height })
      window.parent.postMessage(JSON.stringify({ width, height }), '*')
    },
    successApply() {
      typeof this.delCallback === 'function' && this.delCallback()
      this.delCallback = null
    },
    applyClose() {
      this.puUserUuid = ''
    },
    applyHandler(data, message, delCallback) {
      const { auditUuid } = data || {}
      if (auditUuid) {
        this.puUserUuid = auditUuid
        this.applyVisible = true
        this.delCallback = delCallback
      }
    },
    goMarket() {
      this.$router.push({ path: '/cloudMarket' })
    },
    messageHandler(item) {
      // todo  地址处理
      console.log('--------')
      console.log(item)

      if (item.urlScope == 1 || (item.urlScope == 3 && !item.pcJumpUrl)) {
        this.invitation = true
        this.isappUuid = item.messageData.appUuid
      } else {
        this.jumpUrl(item.pcJumpUrl)
      }
    },
    changevisible(val) {
      if (!val) {
        this.invitation = false
      } else {
        this.invitation = true
      }
    }
  }
}
</script>

<style lang="scss" >
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";

  .g-app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    //overflow: hidden;
    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
    &.g-app-website{
      height: auto;
      //min-height: 100%;
      background: #F5F7FA;
      .app-main{
        position: relative;
        left: 0;
        top:0;
        overflow: initial;
      }
      .u-cart{
        display: none;
      }
      .m-shop_info_tool{
        display: none;
      }
      .solutionContent_btn{
        display: none;
      }
      .commodity_solution{
        min-height: auto;
      }
      .solutionContent_text_tool{
        display: none;
      }
      .m_commodity_service_ritem{
        display: none !important;
      }
      .m_commodity_service_tool{
        display: none;
      }
      .cardBox-minH{
        min-height: auto !important;
      }
      .cloudMarket_leftBox_menuss{
        height: auto !important;
        min-height: auto !important;
      }
    }
  }

  //.drawer-bg {
  //  background: #000;
  //  opacity: 0.3;
  //  width: 100%;
  //  top: 0;
  //  height: 100%;
  //  position: absolute;
  //  z-index: 999;
  //}

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
    .cloud-market{
      position: absolute;
      right: 20px;
      top: 18px;
      font-size: 14px;
      font-weight: 500;
      color: #31415F;
      //color: #FFFFFF;
      cursor: pointer;
      display: flex;
      align-items: center;
      .cloud-market-txt {
        margin-left: 8px;
      }
    }
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
