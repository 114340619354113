<template>
  <EnterpriseLayout>
    <div class="m_enterprise_address_book">
      <div class="m_address_book_org">
        <div class="m_org_title">
          <span>组织架构</span>
        </div>
        <div class="m_org_search_box">
          <el-input v-model="filterText" clearable placeholder="请输入组织名称搜索" maxlength="50">
            <i slot="prefix" class="gp-input__icon el-icon-search" />
          </el-input>
        </div>
        <div class="m_org_tree">
          <el-scrollbar style="height: 100%">
            <el-tree
              ref="tree"
              :data="data"
              :default-expanded-keys="defaultExpandedKeys"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              :load="loadNode"
              :props="defaultProps"
              class="filter-tree"
              highlight-current
              node-key="organUuid"
              draggable
              :allow-drop="allowDrop"
              :allow-drag="allowDrag"
              @current-change="currentTreeChange"
              @node-drag-start="handleDragStart"
              @node-drag-enter="handleDragEnter"
              @node-drag-leave="handleDragLeave"
              @node-drag-over="handleDragOver"
              @node-drag-end="handleDragEnd"
              @node-drop="handleDrop"
            >
              <span slot-scope="{ node, data }" class="m_org_tree_node" :class="{m_node_disabled:data.readOnlyFlag==1}">
                <div class="m_org_tree_label ">
                  <!--                  :style="{'width':(190-10*(node.level-1))+'px',}"-->
                  <div class="m_org_tree_name" :class="{m_org_tree_color1:node.level==1}" @mouseover="onMouseOver($event)">

                    <el-tooltip
                      effect="dark"
                      :disabled="!data.disabled"
                      :content="treenodeitem(data,data.organUuid)"
                      placement="top-start"
                    >
                      <!--                      <span>{{ data.organName }}</span>-->
                      <div class="remark">
                        <p :ref="'allText'+data.organUuid" class="allText">{{ treenodeitem(data,data.organUuid) }}</p>
                        <!--                        <p ref="allText" class="allText">{{ treenodeitem(data) }}</p>-->
                        <!--                        <p ref="remark" :class="{row_1 : !data.disabled }">{{ treenodeitem(data,data.organUuid) }}</p>-->
                        <p :ref="'remark'+data.organUuid" :class="{row_1 : data.disabled }">{{ treenodeitem(data,data.organUuid) }}</p>
                      </div>
                    </el-tooltip>
                    <!--                    <UmToolTip :key="data.organUuid" :content="data.organName" />-->
                  </div>
                  <div class="m_org_tree_treenode">
                    <span class="m_org_tree_counts" :class="{m_org_tree_color1:node.level==1}">({{ data.personCount || 0 }})</span>
                    <div v-if="data.organTypeCode==1001502" class="m_org_tree_tag">公司</div>
                    <div v-if="data.organTypeCode==1001503" class="m_org_tree_tag">部门</div>
                    <div v-if="data.organTypeCode==1001504" class="m_org_tree_tag">岗位</div>
                  </div>
                </div>
                <span
                  v-if="node.isCurrent&&data.readOnlyFlag==0"
                  v-permission="['112030201103','112030201104','112030201105']"
                  class="m_org_tree_dropdow_more"
                  @click.stop
                >
                  <i class="el-icon-rank" style="font-size: 12px;margin-top: 2px" />
                  <el-popover
                    popper-class="m_enterpris_popover"
                    placement="right-start"
                    width="112"
                    :visible-arrow="false"
                    trigger="click"
                  >
                    <svg-icon slot="reference" icon-class="ellipsis" class-name="custom-class" @click="chose(data)" />
                    <div class="m_enterpris_popover_edit-wrap">
                      <div v-permission="['112030201103']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => addsondepartment( data)">
                        <span class="m_enterpris_popover_add-icon" />
                        <span>新建子部门</span>
                      </div>
                      <div v-permission="['112030201104']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => editdepartment(data)">
                        <span class="m_enterpris_popover_edit-icon" />
                        <span>编辑</span>
                      </div>
                      <div v-permission="['112030201105']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => deletedepartment(node, data)">
                        <span class="m_enterpris_popover_del-icon" />
                        <span>删除</span>
                      </div>
                    </div>
                  </el-popover>
                </span>
              </span>
            </el-tree>
          </el-scrollbar>
        </div>
        <div class="m_org_dialog">
          <el-dialog
            :title="daligotxt"
            :visible.sync="addVisible"
            center
            :append-to-body="false"
            width="522px"
            @close="reset"
          >
            <span v-show="addoredit==1">
              <el-form ref="addForm" :model="addForm" label-position="left" :rules="addrules" class="demo-ruleForm" label-width="100px" @submit.native.prevent>
                <el-form-item label="属性" prop="organTypeCode">
                  <el-radio-group v-model="addForm.organTypeCode">
                    <el-radio v-if="organTypeCode == 1001501 || organTypeCode == 1001502" label="1001502">公司</el-radio>
                    <el-radio v-if="organTypeCode == 1001501 || organTypeCode == 1001502 || organTypeCode == 1001503" label="1001503">部门</el-radio>
                    <el-radio v-if="organTypeCode == 1001501 || organTypeCode == 1001502 || organTypeCode == 1001503 || organTypeCode == 1001504" label="1001504">岗位</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="组织名称" prop="organName">
                  <el-input v-model="addForm.organName" clearable placeholder="请输入组织名称" maxlength="30" />
                </el-form-item>
              </el-form>
            </span>
            <span v-show="addoredit==2">
              <el-form ref="editForm" :model="editForm" :rules="editrules" class="demo-ruleForm" label-width="110px" label-position="left" @submit.native.prevent>
                <el-form-item label="组织名称" prop="organName">
                  <el-input v-model="editForm.organName" :disabled="editDisible" clearable placeholder="请输入组织名称" maxlength="30" />
                </el-form-item>
                <el-form-item label="部门负责人" prop="departmentHeadUuidList" style="line-height: 32px;height: 32px">
                  <span style="display: flex;align-items: center;line-height: 1">
                    <UmBusUser
                      ref="UmBusUser"
                      v-model="editForm.departmentHeadUuidList"
                      :show-prefix-button="false"
                      :type="2"
                      title="选择部门负责人"
                    >
                      <template #user="{data}">
                        <span class="m_org_persons row_one flex-center">
                          <span v-if="data&&data.length">
                            {{ data.map(i=>i.name).join('/') }}
                          </span>
                          <span v-else>未设置</span>
                        </span>
                      </template>
                    </UmBusUser>
                    <span class="m_org_btn_shezhi" @click="editperson">设置</span>
                  </span>
                </el-form-item>
              </el-form>
            </span>
            <span v-if="addoredit==1" class="dialog-footer flex-center mt30">
              <span class="m_org_btn_quxiao"><el-button @click="quitadd">取 消</el-button></span>
              <span class="m_org_btn_queren"><el-button type="primary" @click="sureadd">确 定</el-button></span>
            </span>
            <span v-else class="dialog-footer flex-center mt30">
              <span class="m_org_btn_quxiao"><el-button @click="quitedit">取 消</el-button></span>
              <span class="m_org_btn_queren"><el-button type="primary" @click="sureedit">确 定</el-button></span>
            </span>
          </el-dialog>
        </div>
        <Qrcode :invitation.sync="invitation" @changevisible="changevisible" />
      </div>
      <div class="m_address_book_table">
        <div class="m_address_book_content">
          <div class="m_content_title">{{ toptitle }}</div>
          <!--          <el-row :gutter="30" type="flex">-->
          <!--            <el-row :span="10">-->
          <!--              <div v-show="readOnlyFlag==0" class="m_content_btn">-->
          <!--                <div class="m_content_btn_left">-->
          <!--                  <div v-permission="['112030201106']" class="m_content_btn_add">-->
          <!--                    <el-button type="primary" @click="addUser">-->
          <!--                      <span class="el-icon-circle-plus-outline" />-->
          <!--                      添加成员-->
          <!--                    </el-button>-->
          <!--                  </div>-->
          <!--                  &lt;!&ndash;              勿删&ndash;&gt;-->
          <!--                  &lt;!&ndash;              <div class="m_content_btn_inorout">&ndash;&gt;-->
          <!--                  &lt;!&ndash;                <el-button plain type="primary" @click="Importexport">&ndash;&gt;-->
          <!--                  &lt;!&ndash;                  <span class="m_btn_inout_icon" />&ndash;&gt;-->
          <!--                  &lt;!&ndash;                  批量导入/导出&ndash;&gt;-->
          <!--                  &lt;!&ndash;                </el-button>&ndash;&gt;-->
          <!--                  &lt;!&ndash;              </div>&ndash;&gt;-->
          <!--                  <div v-permission="['112030201107']" class="m_content_btn_delet">-->
          <!--                    <el-button type="danger" plain @click="rdelete">-->
          <!--                      删除-->
          <!--                    </el-button>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div v-permission="['112030201108']" class="m_content_btn_right">-->
          <!--                  <div class="m_content_btn_invitate">-->
          <!--                    <el-button type="primary" @click="invite">邀请用户</el-button>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </el-row>-->
          <!--            <el-col :span="10">-->
          <!--              <el-input v-model="keyword" prefix-icon="el-icon-search" placeholder="请输入人员名称搜索" maxlength="500" clearable @keyup.native.enter="getList" />-->
          <!--            </el-col>-->
          <!--          </el-row>-->
          <el-row :gutter="20" type="flex" justify="space-around">
            <el-col :span="17">
              <div v-show="readOnlyFlag==0">
                <el-button v-permission="['112030201106']" type="primary" @click="addUser">
                  <span class="el-icon-circle-plus-outline" />
                  添加成员
                </el-button>
                <el-button v-permission="['112030201108']" type="primary" @click="invite">邀请用户</el-button>
                <el-button v-permission="['112030201107']" type="danger" plain @click="rdelete">
                  删除
                </el-button>
              </div>
            </el-col>
            <el-col :span="7">
              <el-input v-model="keyword" prefix-icon="el-icon-search" placeholder="请输入人员名称搜索" maxlength="50" clearable @keyup.native.enter="getList" />
            </el-col>
          </el-row>
          <div v-if="unJoinCount" class="m_content_joinnum">
            <span class="um_iconfont um_icon-zhuyi" />
            <div class="m_content_joinnum_num">当前部门还有{{ unJoinCount }}人尚未加入</div>
          </div>
          <div class="mar_top10">
            <el-table
              :data="tableData"
              stripe
              height="calc(100vh - 320px)"
              style="width: 100%;"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="65"
              />
              <el-table-column
                label="序号"
                prop="xh"
                width="100"
              />
              <el-table-column
                label="姓名"
                prop="userName"
                width="200"
              >
                <template slot-scope="scope">
                  <div style="display: flex;align-items: center">
                    <span class="row_1" style="color: #3D80FC;cursor: pointer" @click="get_user_update_info(scope.row)">
                      {{ scope.row.userName||'-' }}
                      <!--                      <UmToolTip style="color: #3D80FC;cursor: pointer" :content="scope.row.userName" class="row_2" @click.native="get_user_update_info(scope.row)" />-->
                    </span>
                    <span v-if="scope.row.managerFlag==1" class="m_content_founder">负责人</span>
                    <span v-if="scope.row.joinStatus==0" class="m_content_founder m_content_founder_s1">
                      <span>待加入</span>
                      <!--                      <span v-if="scope.row.joinStatus==2">已退出</span>-->
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="职务"
                prop="userPosition"
                width="200"
              >
                <template slot-scope="scope">
                  <span class="row_2">{{ scope.row.userPosition||'-' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="部门"
                prop="userDepartment"
                min-width="200"
              >
                <template slot-scope="scope">
                  <span><UmToolTip :content="scope.row.userDepartment" class="row_2" /></span>
                </template>
              </el-table-column>
              <el-table-column
                label="手机号"
                prop="userMobile"
                width="200"
              >
                <template slot-scope="scope">
                  <span class="row_1">{{ scope.row.userMobile||'-' }}</span>
                </template>}
              </el-table-column>
            </el-table>
          </div>
          <div class="m_common_paginations">
            <pagination
              :background="false"
              :limit.sync="page.pageSize"
              :page.sync="page.pageNum"
              :total="tableTotal"
              @pagination="getList('page')"
            />
          </div>
        </div>
      </div>
    </div>
  </EnterpriseLayout>
</template>

<script>
import {
  get_organ_tree,
  get_organ_user_page,
  add_organ,
  delete_organ,
  update_organ,
  get_organ_update_info,
  get_Invite_users_qr_code,
  get_organ_tree_sort,
  delete_org_user
} from '@/api/enterprise'
import { log } from 'mathjs'
import Qrcode from './components/qrcode'
import { deepClone } from '@/utils'
export default {
  name: 'EnterpriseAddress',
  components: { Qrcode },
  data() {
    return {
      keyword: '',
      invitations: false,
      readOnlyFlag: 0,
      qrloading: false,
      organUuid: '', // 当前的组织
      // organUuid1: '',
      chosenode: {},
      data: [],
      dataclone: [],
      dataclone1: [],
      defaultExpandedKeys: [], // 默认展开数组
      tableTotal: 0,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      currentPage4: 4,
      // total: 100,
      filterText: '',
      toptitle: '-',
      // treeData: [],
      defaultProps: {
        children: 'children',
        label: 'organFullName',
        isLeaf: 'leafFlag'
      },
      tableData: [],
      unJoinCount: '',
      multipleSelection: [], // 选择的集合
      addVisible: false, // 创建组织
      addForm: {
        parentOrganUuid: '',
        organName: '',
        organTypeCode: '1001503'
      },
      addrules: {
        organName: [
          { required: true, message: '请输入组织名称', trigger: 'change' }
        ],
        organTypeCode: [
          { required: true, message: '请选择公司属性', trigger: 'change' }
        ]
      },
      editForm: {
        organUuid: '',
        organName: '',
        departmentHeadUuidList: []
      },
      editrules: {
        // departmentHeadUuidList: [
        //   { required: true, message: '选择部门负责人', trigger: 'change' }
        // ],
        organName: [
          { required: true, message: '请输入组织名称', trigger: 'change' }
        ]
      },
      addoredit: 1, // 1 添加 2 编辑
      organTypeCode: '',
      daligotxt: '创建组织',
      invitation: false, // 邀请
      qrCodeUrl: '', // 邀请二维码
      InviteUrl: '', // 邀请链接
      isDisabledTooltip: true, // 是否禁止悬浮框显示
      editDisible: false // 和后端保持一致，一级企业无法在此修改组织名称
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    tableData: {
      handler(val) {
        this.getwidth()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getTree()
    // this.getList()
    // }
  },
  activated() {
    // this.getList()
    // this.getTree()
  },
  mounted() {
    // const editback = this.$route.query.editback
    //
    // if (editback == 1) {
    //   this.getList()
    // } else {
    //   this.getTree()
    //   // this.getList()
    // }
  },
  methods: {
    getwidth() {
      const ww = document.getElementsByClassName('m_org_tree_name')
      // const ww=document.getElementsByClassName('m_org_tree_name').offsetWidth
    },
    changevisible(val) {
      if (!val) {
        this.invitation = false
      } else {
        this.invitation = true
      }
    },
    onMouseOver(event) {
      const ev = event.target
      const ev_weight = ev.scrollWidth // 文本的实际宽度
      // 判断是否禁用tooltip功能
      this.isDisabledTooltip = ev_weight < 196
    },
    // 筛选树
    filterNode(value, data) {
      //
      if (!value) return true
      return data.organFullName.indexOf(value) !== -1
    },
    // 根据类型过滤数组
    filterTable(table, type) {
      if (type == -1) {
        return table
      } else if (type == 1) {
        return table.filter(item => {
          return Object.keys(item).filter(key => key.includes('key')).some(item2 => item[item2].delay == 1)
        })
      } else if (type == 0) {
        return table.filter(item => {
          return Object.keys(item).filter(key => key.includes('key')).every(item2 => item[item2].delay == 0 || !item[item2].delay)
        })
      }
    },
    // 邀请用户
    invite() {
      this.invitation = true
      // this.qrloading = true
      // get_Invite_users_qr_code({
      //   organUuid: this.organUuid
      // }).then(d => {
      //   this.qrCodeUrl = d.data.qrCodeUrl || ''
      //   this.InviteUrl = d.data.inviteUrl || ''
      //   //
      // }).finally(_ => {
      //   this.qrloading = false
      // }).catch(e => {
      //   this.$errorHandle(e)
      // })
    },
    // 复制链接提示
    successFn() {
      this.$message.success('复制成功')
    },
    reset() {
      this.editDisible = false
      if (this.addoredit == 1) this.$refs.addForm.resetFields()
      else this.$refs.editForm.resetFields()
    },
    // 关闭邀请弹窗
    close() {
      this.invitation = false
      this.qrCodeUrl = ''
      this.InviteUrl = ''
    },
    getList(state) {
      this.tableData = []
      if (this.organUuid) {
        const load = this.$load()
        get_organ_user_page({
          keyword: this.keyword,
          page: this.page,
          organUuid: this.organUuid,
          unJoinCountFlag: true // 是否需要查询未加入人员统计
        }).then(res => {
          this.tableTotal = res.data.page.total
          this.tableData = res.data.list
          this.unJoinCount = res.data.unJoinCount
        }).catch(e => {
          this.$errorHandle(e)
        }).finally(_ => {
          load.close()
        })
      }
    },
    getTree(row) {
      get_organ_tree({
        stepFlag: 0,
        parentUuid: ''
      }).then(d => {
        this.data = d.data
        this.dataclone = deepClone(d.data)
        // this.dataclone1 = deepClone(d.data)
        this.defaultExpandedKeys = this.data.map(item => item.organUuid) // 默认展开第一级
        // 获取有权限的查看列表
        const currentData = this.findPermissionNode(this.data)
        if (currentData) {
          // this.currentData = currentData
          // this.$emit('currentChange', this.currentData)
          this.organUuid = currentData.organUuid
          this.toptitle = currentData.organName
          this.getList()
          this.$nextTick(_ => {
            this.$refs.tree.setCurrentNode(currentData)
          })
        }
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    findPermissionNode(tree) {
      let node = null
      function deep(arr) {
        try {
          arr.forEach(item => {
            if (item.readOnlyFlag != 1) {
              node = item
              throw new Error()
            }
            deep(item.children)
          })
        } catch (e) {

        }
      }
      deep(tree)
      return node
    },
    handleSizeChange(val) {

    },
    handleCurrentChange(val) {

    },
    // 添加成员
    addUser() {
      this.$router.push({ path: '/enterprise/address/add', query: { organUuid: this.organUuid }})
    },
    loadNode(node, resolve) {
      get_organ_tree({
        stepFlag: 0,
        parentUuid: ''
      }).then(res => {
        const data = res.data
        data.forEach(item => {
          item.leaf = Boolean(item.childFlag == 0)
        })
        resolve(data)
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    currentTreeChange(data, checked, indeterminate) {
      this.toptitle = data.organFullName || ''
      this.organUuid = data.organUuid
      console.log(data.readOnlyFlag)
      this.readOnlyFlag = data.readOnlyFlag
      if (data.readOnlyFlag != 1) {
        this.getList()
      }
      if (data.readOnlyFlag == 1) {
        this.tableData = []
        this.tableTotal = 0
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      // 拖拽时判定目标节点能否被放置。type 参数有三种情况：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
      if (type === 'inner') return false
      if (draggingNode.parent.key === dropNode.parent.key) {
        return true
      } else {
        return false
      }
    },
    allowDrag(draggingNode) {
      // return draggingNode.data.label.indexOf('三级 3-2-2') === -1
      return true // 判断节点能否被拖拽
    },
    handleDragStart(node, ev) {
      // console.log('drag start', node)
      this.dataclone1 = deepClone(this.data)
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      // console.log('tree drag enter: ', dropNode.label)
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      // console.log('tree drag leave: ', dropNode.label)
    },
    handleDragOver(draggingNode, dropNode, ev) {
      // console.log('tree drag over: ', dropNode.label)
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      // console.log('tree drag end: ', dropNode && dropNode.label, dropType)
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const load = this.$load()
      get_organ_tree_sort({
        opOrganUuid: draggingNode.key,
        toOrganUuid: dropNode.key,
        type: dropType === 'before' ? 1 : 2
      }).then(d => {
        load.close()
        this.$message.success('操作成功！')
        this.getTree()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
        this.data = deepClone(this.dataclone1)
      })
    },
    chose(val) {
      this.chosenode = val

      this.organUuid = val.organUuid
      this.addForm.parentOrganUuid = val.organUuid
    },
    // 新建子部门
    addsondepartment(val) {
      this.daligotxt = '创建组织'
      this.addoredit = 1
      this.organTypeCode = val.organTypeCode
      this.addForm.organTypeCode = val.organTypeCode + ''
      this.addVisible = true
    },
    // 确认添加子部门
    sureadd() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          add_organ({ ...this.addForm }).then(d => {
            // this.getTree()
            // if (this.addForm.organTypeCode == 1001502) {
            const data = d.data
            if (!data.children) {
              this.$set(data, 'children', [])
            }
            this.chosenode.children.push(data)
            this.addVisible = false
            // this.chosenode.children.push(data)
            // }
          }).catch(e => {
            this.$errorHandle(e)
          })
        } else {
          return false
        }
      })
    },
    // 取消添加子部门
    quitadd() {
      this.addVisible = false
    },
    // 编辑
    editdepartment(val) {
      this.editForm.orangename = val.organFullName
      this.daligotxt = '编辑组织'
      this.addVisible = true
      this.addoredit = 2

      get_organ_update_info({
        organUuid: this.organUuid
      }).then(d => {
        this.editForm.organUuid = d.data.organUuid
        this.editForm.organName = d.data.organName
        this.editForm.departmentHeadUuidList = d.data.departmentHeadUuidList
        this.editDisible = Boolean(d.data.organParentId == 0)
      }).finally(_ => {
        // this.organUuid = ''
      })
    },
    // 确认编辑
    sureedit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          update_organ({ ...this.editForm }).then(d => {
            Object.assign(this.chosenode, d.data)
            // this.getTree()
            this.$message({
              type: 'success',
              message: '保存成功!'
            })
            this.addVisible = false
          }).finally(_ => {
            this.getList()
          })
        } else {
          return false
        }
      })
    },
    // 取消编辑
    quitedit() {
      this.addVisible = false
    },
    // 左侧树删除
    deletedepartment(node, data) {
      this.$confirm(` ${data.organFullName} `, '是否确认删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
        center: true
      }).then(() => {
        delete_organ({
          organUuid: this.organUuid
        }).then(d => {
          // this.getTree()
          const parent = node.parent
          const children = parent.data.children || parent.data
          const index = children.findIndex(d => d.organUuid === data.organUuid)
          children.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }).finally(_ => {
          this.addVisible = false
          this.organUuid = ''
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        })
      })
    },
    // 右侧删除
    rdelete() {
      if (!this.multipleSelection.length) {
        this.$message({
          type: 'warning',
          message: '请选择需要删除的用户!'
        })
        return
      }
      const arrlist = this.multipleSelection.map(item => item.userUuid)
      const arrname = this.multipleSelection.map(item => item.userName)
      const h = this.$createElement
      this.$confirm('', {
        message: h('div', null, [h('p', { style: 'color:#31415F;fontSize: 16px;' }, '是否将成员从企业通讯录中删除，删除后将无法恢复？'),
          h('p', { style: 'color:#31415F;fontSize: 16px;fontWeight: 400;marginTop:10px' }, `${arrname}`)]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        customClass: 'message-dialog'
      }).then(() => {
        delete_org_user({
          orgUserUuidList: arrlist
        }).then(d => {
          this.getList()
          this.getTree()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }).finally(_ => {
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        })
      })
    },
    // 设置部门负责人
    editperson() {
      this.$refs.UmBusUser.openModal()
    },
    // 获取详情
    get_user_update_info(val) {
      this.$router.push({ path: '/enterprise/address/info', query: { isedit: 2, userUuid: val.userUuid }})
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 导入 导出
    Importexport() {
      this.$message({
        type: 'info',
        message: '功能暂未开放'
      })
      // this.$router.push('/enterprise/address/importExport')
    },
    treenodeitem(val, organUuid) {
      if (organUuid) {
        this.$nextTick(_ => {
          const refallText = this.$refs['allText' + organUuid]
          const refremark = this.$refs['remark' + organUuid]
          if (refallText && refremark) {
            const allWidth = refallText.offsetWidth
            const contentWidth = refremark.offsetWidth
            if (contentWidth < allWidth) {
              // val.disabled = true
              this.$set(val, 'disabled', true)
            } else {
              // val.disabled = false
              this.$set(val, 'disabled', false)
            }
          }
        })
      }
      return val.organName
    }
  }
}
</script>

<style lang="scss" scoped>
.remark {
  position: relative;
  overflow: hidden;
}
.allText {
  opacity: 0;
  position: absolute;
  z-index: -99;
  white-space: nowrap;
  // width: 100%;
}
.gp-button + .gp-button{
  margin-left: 10px;
}
</style>
