<template>
  <div class="um-upload_list">
    <div v-for="(file,i) in previewFileList" :key="i" class="um-upload_item">
      <a class="u-item_name" @click="downloadFile(file)">
        <i class="u-icon" :class="`u-icon_${filterType(file.fileExtension)}`" />
        <span class="u-name">{{ file.name || file.fileName }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UmPreviews',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    previewFileList:
    {
      type: Array,
      default: () => []
    }

  },
  data() {
    return {
      fileList: [],
      compressionType: ['zip', 'rar'],
      excelType: ['xls', 'xlsx'],
      imgType: ['jpg', 'png', 'jpeg', 'gif'],
      pdfType: ['pdf'],
      wordType: ['doc', 'docx'],
      pptType: ['ppt', 'pptx'],
      textType: ['txt', 'rtf'],
      htmlType: ['html'],
      wavType: ['wav', 'mp3', 'amr', 'wmv'],
      movType: ['avi', 'mp4', 'mov', 'rmvb', 'rm', '3gp', 'mpeg']

    }
  },
  methods: {
    // 返回文件的扩展名，主要用来做图标展示
    filterType(fileExtension) {
      if (this.compressionType.includes(fileExtension)) return 'zip'
      if (this.excelType.includes(fileExtension)) return 'xls'
      if (this.pdfType.includes(fileExtension)) return 'pdf'
      if (this.wordType.includes(fileExtension)) return 'doc'
      if (this.pptType.includes(fileExtension)) return 'ppt'
      if (this.imgType.includes(fileExtension)) return 'image'
      if (this.htmlType.includes(fileExtension)) return 'html'
      if (this.textType.includes(fileExtension)) return 'text'
      if (this.wavType.includes(fileExtension)) return 'audio'
      if (this.movType.includes(fileExtension)) return 'video'
      if (fileExtension) {
        return 'file'
      } else {
        return 'none'
      }
    },
    downloadFile(file) {
      var a = document.createElement('a') // 创建a标签
      a.style.display = 'none' // 隐藏a标签
      a.href = file.tmpFileHttpPath || file.fileHttpPath // 文件下载地址
      a.setAttribute('download', file.name || file.fileName) // 设置a为下载属性，并设置下载文件名
      document.body.appendChild(a) // 将a标签插入到页面中
      a.click() // 强制触发a便签的点击事件
      document.body.removeChild(a) // 移除a标签
    }

  }
}
</script>

<style lang="scss">

$list:audio,doc,file,html,image,none,pdf,ppt,text,video,xls,zip;
.um-upload_list{
  margin-top: 17px;
  .um-upload_item{
    position: relative;
    height: 38px;
    line-height: 36px;
    margin-bottom: 8px;
    padding-left: 8px;
    border: 1px solid #E4E8EB;
    border-radius: 4px;
    .u-item_name{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      &:hover {
        color: #527BF6;
      }
      .u-icon{
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        @each $i in $list {
          &.u-icon_#{$i}{
            background-image: url('./image/icon-#{$i}.svg');
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
      .u-name{
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 8px;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
