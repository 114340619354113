import request from '@/utils/request'

// 获取系统数据字典集合
export function get_list_dict(params) {
  return request({
    url: '/api/v1/auth/sysconfig/get_list_dict',
    method: 'get',
    params
  })
}

// 获取行业分类树
export function get_classify_tree(params) {
  return request({
    url: '/api/v1/operator/center/classify/get_classify_tree',
    method: 'get',
    params
  })
}

// 查询组织人员信息
export function get_organ_user_info(data) {
  return request({
    url: '/api/v1/organ/common/get_organ_user_info',
    method: 'post',
    data
  })
}

// Excel导出带进度条
export function exportExcel(data) {
  return request({
    url: '/api/v1/server-group/m/progress/exportExcel',
    method: 'post',
    data
  })
}

// Excel导入带进度条
export function importExcel(data) {
  return request({
    url: '/api/v1/server-group/g/progress/importExcel',
    method: 'post',
    data
  })
}
// 导出导入查询进度
export function getProgressInfo(params) {
  return request({
    url: '/api/v1/server-group/g/progress/getProgressInfo',
    method: 'get',
    params
  })
}

// 获取安装包跳转地址
export function getLastVersion() {
  return request({
    url: '/api/v1/common-app/open/app/version/getLastVersion/yky',
    // params,
    method: 'get'
  })
}

/// 单点登录逻辑，返回业务口code
export function create_authorize_code(params) {
  return request({
    url: '/api/v1/oauth2-auth/oauth/create_authorize_code/v2',
    params,
    method: 'get'
  })
}

// 购物车数量
export function getshoppingcartcount() {
  return request({
    url: '/api/v1/business/shopping/cart/get/count',
    // params,
    method: 'get'
  })
}
