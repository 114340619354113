<template>
  <div>
    <el-dialog
      :visible.sync="show.fileProgress"
      :title="title"
      :show-close="false"
      :append-to-body="true"
      width="500px"
    >
      <el-upload
        :action="uploadUrl"
        name="files"
        :accept="accept"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :multiple="multiple"
        :limit="limit"
        :on-exceed="handleExceed"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :headers="{
          Authorization: token,
        }"
      >
        <el-button type="primary">点击上传</el-button>
        <div slot="tip" class="gp-upload__tip">
          只能上传{{ accept }}文件，且不超过{{ size }}kb
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleFileCancel">取消</el-button>
        <el-button type="primary" @click="importExcel">导入</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="show.operateProgress"
      title="导入进度"
      :append-to-body="true"
      :show-close="false"
    >
      <el-progress :percentage="progressData.percent" :format="format" />
      <span>正在处理数据，已处理 {{ progressData.alreadyCount }} /共
        {{ progressData.count }}，请耐心等待</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleOperateCancel">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="show.operateResult"
      title="导入结果"
      :show-close="false"
      :append-to-body="true"
    >
      <div>
        <div>总记录：{{ progressData.resultData.count }}条</div>
        <div>
          导入成功：
          <span style="color: #00cc66">{{
            progressData.resultData.successCount
          }}</span>条
        </div>
        <div>
          导入失败：
          <span style="color: red">{{ progressData.resultData.failCount }}</span>条
        </div>
      </div>
      <div slot="footer">
        <el-button>
          <el-link
            :href="progressData.resultData.excelErrorPath"
            target="_blank"
          >
            下载错误数据
          </el-link>
        </el-button>
        <el-button @click="handleOperateResultCancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getProgressInfo, importExcel } from '@/api/common'
import { getToken } from '@/utils/auth'

export default {
  name: 'UImport',
  props: {},
  data() {
    return {
      title: '上传文件',
      token: getToken(),
      accept: '', // .xls,.xlsx
      fileList: [],
      files: [],
      limit: 3,
      size: 500,
      multiple: false,
      callback: () => {
        return new Promise((resolve, reject) => {
          resolve(null)
        })
      },
      show: {
        fileProgress: true,
        operateProgress: false,
        operateResult: false
      },
      operateProgressId: 0,
      excelPath: '',
      timer: null,
      progressData: {
        percent: 0,
        count: 0,
        alreadyCount: 0,
        resultData: {}
      },
      progressParamData: {},
      progressBusinessCode: null
    }
  },
  computed: {
    uploadUrl() {
      const uploadUrl = '/api/v1/file_manage/file/upload'
      return process.env.VUE_APP_BASE_API.replace(/\/$/, '') + uploadUrl
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleFileCancel() {
      this.show.fileProgress = false
      this.handleAfterLeave()
    },
    handleOperateResultCancel() {
      this.show.operateResult = false
      this.handleAfterLeave()
    },
    format(percentage) {
      return percentage === 100 ? '完成' : `${percentage}%`
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response, file, fileList) {
      console.log(fileList)
      if (response.code !== 3001) {
        this.$message.error('上传失败!')
        // 上传失败之后移除图片
        this.fileList.splice(this.fileList.indexOf(file), 1)
        return false
      }
      this.files = fileList
      // todo 需要根据字典表做错误判定
    },
    beforeUpload(file) {
      // const isExcel = file.type && file.type.indexOf('xls') !== -1
      // if (!isExcel) {
      //   this.$message.error('只能上传excel格式!')
      //   return false
      // }
      const isSize = file.size / 1024 < this.size
      if (!isSize) {
        this.$message.error(`上传的文件大小不能超过 ${this.size}kb!`)
      }
      return isSize
    },
    handleError(err, file, fileList) {
      console.log(err, file, fileList)
      this.$message.error('上传失败!')
    },
    handleAfterLeave() {
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el) // 可能移除的时候组件已经不存在了
    },
    importExcel() {
      if (this.files.length === 0) {
        return this.$message.warning('请先上传文件!')
      }
      const excelPath = this.files[0].response.data.filePath

      importExcel({
        progressParamData: this.progressParamData,
        progressBusinessCode: this.progressBusinessCode,
        excelPath
      }).then((d) => {
        this.operateProgressId = d.data.progressId
        this.startImportProgress()
      })
    },
    handleOperateCancel() {
      this.show.operateProgress = false
      // 清除定时
      this.clearTimer()
      this.handleAfterLeave()
    },
    startImportProgress() {
      this.progressData.percent = 0
      this.progressData.count = 0
      this.progressData.alreadyCount = 0
      this.progressData.resultData = {}
      this.show.operateProgress = true
      this.show.fileProgress = false // 隐藏文件上传
      this.startTimer()
    },
    endImportProgress() {
      this.clearTimer()

      setTimeout(() => {
        this.show.operateProgress = false
        if (this.progressData.resultData.failCount === 0) {
          // 全部成功
          this.$notify({
            title: '导入成功',
            message:
              '恭喜您导入成功，共导入' +
              this.progressData.resultData.successCount +
              '条记录！',
            type: 'success'
          })
        } else {
          // 部分成功
          this.show.operateResult = true
        }
      }, 2000)

      // this.show.operateProgress = false

      // this.$emit('importFinish')
    },
    startTimer() {
      this.queryProgress()
      // this.timer = setInterval(this.queryProgress, 1000 * 2)
    },
    clearTimer() {
      // clearInterval(this.timer)
      clearTimeout(this.timer)
    },
    queryProgress() {
      getProgressInfo({ progressId: this.operateProgressId })
        .then((d) => {
          const progress = d.data
          if (progress.progressStateCode === 1109501) {
            // 等待开始
            this.progressData.percent = 0
          }
          if (progress.progressStateCode === 1109502) {
            // 初始化完成
            this.progressData.percent = 0
            this.title = progress.progressTitle
            this.progressData.count = progress.progressCount
            this.progressData.alreadyCount = progress.progressCompleteCount
          }
          if (progress.progressStateCode === 1109503) {
            this.title = progress.progressTitle
            // 进行中
            this.progressData.percent = parseInt(
              (progress.progressCompleteCount / progress.progressCount) * 100
            )
            this.progressData.count = progress.progressCount
            this.progressData.alreadyCount = progress.progressCompleteCount
          }
          if (progress.progressStateCode === 1109504) {
            // 终止
            this.progressData.percent = 0
            this.$message.error(progress.progressMsg)
            this.clearTimer()
            this.show.operateProgress = false
            // this.endImportProgress()
            this.$emit('fail', progress)
            return false
          }
          if (progress.progressStateCode === 1109505) {
            // 正常结束
            this.progressData.percent = 100
            this.progressData.count = progress.progressCount
            this.progressData.alreadyCount = progress.progressCompleteCount
            // this.$message.success('导入成功')
            this.progressData.resultData = progress.progressResultData
            this.endImportProgress()
            this.$emit('success', progress)
            return false
          }
          // 请求结束之后
          this.timer = setTimeout(this.queryProgress, 1000 * 2)
        })
        .catch((data) => {
          // 请求结束之后
          this.timer = setTimeout(this.queryProgress, 1000 * 2)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
