
const state = {
  UmIM: null, // UmIMSDK实例对象
  mineInfo: {}, // 我的信息
  uploading: {}, // 正在发送的文件列表
  session: {}, // 当前会话
  sessionList: [], // 当前会话列表
  records: [], // 聊天记录
  hasVideo: false
}

const mutations = {
  SET_UMIM(state, im) {
    state.UmIM = im
  },
  SET_hasVideo(state, hasVideo) {
    state.hasVideo = hasVideo
  },
  SET_UPLOADING(state, payload) {
    Object.assign(state.uploading, payload)
  },
  REMOVE_UPLOADING(state, uid) {
    delete state.uploading[uid]
  },
  // 设置我的信息
  SET_MINEINFO(state, payload) {
    state.mineInfo = payload
  },
  // 设置当前会话
  SET_SESSION(state, payload) {
    state.session = payload
  },
  // 设置会话列表
  SET_SESSIONLIST(state, payload) {
    state.sessionList = payload
  },
  // 设置聊天记录
  SET_RECORDS(state, { msg, type = '' }) {
    if (type === 'push') {
      state.records.push(msg)
    } else {
      state.records = msg
    }
  }
}

const actions = {
  layout({ commit, state }) {
    state.UmIM && state.UmIM.logout()
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

//
// const state = {
//   selectPerson: {},
//   totalUnread: 0,
//   account: ''
// }
//
// const mutations = {
//   setSelect(state, selectPerson) {
//     state.selectPerson = selectPerson
//   },
//   setRed(state, totalUnread) {
//     state.totalUnread = totalUnread
//   },
//   setAccount(state, account) {
//     state.account = account
//   }
// }
//
// const actions = {
//
// }
//
// export default {
//   namespaced: true,
//   state,
//   mutations,
//   actions
// }
