<template>
  <div
    class="m-user-list"
  >
    <template v-if="userList&&userList.length">
      <div v-for="(item,index) in userList" :key="index" class="m-user-item">
        <div v-if="type == 1" class="m-left">
          <el-image
            style="width: 44px; height: 44px;border-radius: 4px"
            :src="item.userHeadImageUrl"
            fit="fit"
          >
            <div slot="error" class="f-avatar" />
          </el-image>
          <div class="m-base">
            <div class="m-line row_1">
              <span class="u-line-name row_one">{{ item.userName|| '-' }}</span>
              <span v-if="item.userPosition" class="u-position">{{ item.userPosition }}</span>
              <div v-if="item.managerFlag&&showStatus" class="u-status">负责人</div>
            </div>
            <div class="m-line">
              <div class="u-department row_1">{{ item.userDepartment | department_filter }}</div>
            </div>
          </div>
        </div>

        <div v-if="type == 2" class="m-left">
          <el-image
            style="width: 44px; height: 44px;border-radius: 4px"
            :src="item.userHeadImageUrl"
            fit="fit"
          >
            <div slot="error" class="f-avatar-group" />
          </el-image>
          <div class="m-base">
            <div class="m-group-name row_one">{{ item.groupName || '-' }}</div>
          </div>
        </div>
        <div class="m-right">
          <el-button size="mini" type="primary" @click="sendMessage(item)">发送消息</el-button>
        </div>
      </div>
    </template>
    <div v-else class="m-noData">
      暂无数据
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserList',
  filters: {
    department_filter(val) {
      return val ? val.replaceAll(',', '/') : '-'
    }
  },
  props: {
    userList: {
      type: Array,
      default: () => new Array(20).fill({})
    },
    type: {
      type: Number,
      default: 1, // 1 用户 2 群组
      validator(val) {
        return [1, 2].includes(val)
      }
    },
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    sendMessage(item) {
      this.$emit('send', item)
    }
  }
}
</script>

<style scoped lang="scss">
.m-user-list {
  padding: 0 20px;
  height: 100%;
  overflow: auto;
  position: relative;
  .m-noData {
    width: 210px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .m-user-item {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 80px;
    border-bottom: 1px dashed #F0F3F5;
    &:last-child {
      border-bottom: none;
    }
    .m-left {
      flex: 1;
      overflow: hidden;
      display: flex;
      align-items: center;
      .m-base {
        flex: 1;
        overflow: hidden;
        margin-left: 12px;
        .m-group-name {
          font-size: 18px;
          font-weight: 500;
          color: #31415F;
        }
        .m-line {
          display: flex;
          overflow: hidden;
          align-items: center;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .u-line-name {
          font-size: 18px;
          font-weight: 500;
          color: #31415F;
          line-height: 18px;
          overflow: hidden;
        }
        .u-position {
          font-size: 14px;
          font-weight: 400;
          color: #69748A;
          line-height: 14px;
          margin-left: 8px;
        }
        .u-status {
          width: 48px;
          height: 16px;
          background: rgba(76,100,254,0.1);
          border-radius: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #4C64FE;
          line-height: 16px;
          text-align: center;
          margin-left: 16px;
        }
        .u-department {
          font-size: 14px;
          font-weight: 400;
          color: #9CA8C3;
          line-height: 14px;
        }
      }
    }
    .m-right {
      flex-shrink: 0;
      margin-left: 20px;
    }
  }
}
</style>
