import request from '@/utils/request'
const baseURL = 'http://api.pm.youmatech.com/mock/797'

// 获取角色信息
export function get_org_role_by_org_business_id() {
  if (process.env.VUE_APP_NAME === 'yky_website') {
    return Promise.resolve({
      code: 3001,
      data: {
        list: []
      }
    })
  }
  return request({
    url: '/api/v1/organ/address_book/user/get_org_role_by_org_business_id',
    method: 'get'
    // baseURL,
    // params
  })
}

// (首页)获取当前登录人和业务对象基础信息
export function get_front_page_data() {
  return request({
    url: '/api/v1/organ/address_book/get_front_page_data',
    method: 'get'
    // baseURL,
    // params
  })
}

// (首页)人员加入企业审核)获取待审核人员列表
export function get_pending_audit_user_list() {
  return request({
    url: '/api/v1/enterprise/enterprise/person_join_audit/get_pending_audit_user_list',
    method: 'get'
    // baseURL,
    // params
  })
}

// (首页)人员加入企业审核)人员加入企业审核)获取待审核人员信息
export function get_pending_audit_user_info(params) {
  return request({
    url: '/api/v1/enterprise/enterprise/person_join_audit/get_pending_audit_user_info',
    method: 'get',
    // baseURL,
    params
  })
}
// (首页)人员加入企业审核)人员加入企业审核)获取待审核人员信息
export function audit_submit(data) {
  return request({
    url: '/api/v1/enterprise/enterprise/person_join_audit/audit_submit',
    method: 'post',
    // baseURL,
    data
  })
}

// 通讯录-成员管理-左侧组织树
export function get_organ_tree(data) {
  return request({
    url: '/api/v1/organ/address_book/get_organ_tree',
    method: 'post',
    // baseURL,
    data
  })
}

// 通讯录-成员管理-左侧组织树拖拽
export function get_organ_tree_sort(data) {
  return request({
    url: '/api/v1/organ/address_book/organ_tree/sort',
    method: 'post',
    // baseURL,
    data
  })
}

// 通讯录-成员管理-新增组织
export function add_organ(data) {
  return request({
    url: '/api/v1/organ/address_book/add_organ',
    method: 'post',
    // baseURL,
    data
  })
}

// 通讯录-成员管理-删除组织
export function delete_organ(params) {
  return request({
    url: '/api/v1/organ/address_book/delete_organ',
    method: 'get',
    // baseURL,
    params
  })
}

// 通讯录-成员管理-编辑组织-回显
export function get_organ_update_info(params) {
  return request({
    url: '/api/v1/organ/address_book/get_organ_update_info',
    method: 'get',
    // baseURL,
    params
  })
}

// 通讯录-成员管理-编辑组织-提交
export function update_organ(data) {
  return request({
    url: '/api/v1/organ/address_book/update_organ',
    method: 'post',
    // baseURL,
    data
  })
}

// 通讯录-成员管理-查询组织下的人员信息列表分页
export function get_organ_user_page(data) {
  return request({
    url: '/api/v1/organ/address_book/get_organ_user_page',
    method: 'post',
    // baseURL,
    data
  })
}

// 通讯录-成员管理-邀请用户
export function get_Invite_users_qr_code(params) {
  return request({
    url: '/api/v1/organ/address_book/user/get_invite_users_qr_code',
    method: 'get',
    // baseURL,
    params
  })
}

export function im_friends_list(params) {
  return request({
    url: '/api/v1/im/im/a/book/im_friends_list',
    method: 'get',
    // baseURL,
    params
  })
}
export function im_group_list(params) {
  return request({
    url: '/api/v1/im/im/a/book/im_group_list',
    method: 'get',
    // baseURL,
    params
  })
}

// 通讯录-成员管理-邀请用户
export function query_organ_user_info(params) {
  return request({
    url: '/api/v1/enterprise/enterprise/a/book/query_organ_user_info',
    method: 'get',
    // baseURL,
    params
  })
}

// 通讯录-成员管理--右侧-添加成员
export function add_user_submit(data) {
  return request({
    url: '/api/v1/organ/address_book/user/add_user_submit',
    method: 'post',
    // baseURL,
    data
  })
}

// 通讯录-成员管理--右侧-删除用户
export function delete_org_user(data) {
  return request({
    url: '/api/v1/organ/address_book/user/delete_org_user',
    method: 'post',
    // baseURL,
    data
  })
}

// 通讯录-成员管理--右侧-获取用户信息
export function get_user_update_info(params) {
  return request({
    url: '/api/v1/organ/address_book/user/get_user_update_info',
    method: 'get',
    // baseURL,
    params
  })
}

// 通讯录-成员管理--右侧-编辑提交用户信息
export function update_user_submit(data) {
  return request({
    url: '/api/v1/organ/address_book/user/update_user_submit',
    method: 'post',
    // baseURL,
    data
  })
}

/*****
 * 权限管理
 *******/
// 权限管理-查询角色组-左侧树
export function get_role_tree(data) {
  return request({
    url: '/api/v1/organ/purview/role/get_role_tree',
    method: 'post',
    // baseURL,
    data
  })
}

// 权限管理-查询角色组下的用户
export function get_role_user_page(data) {
  return request({
    url: '/api/v1/organ/purview/role/get_role_user_page',
    method: 'post',
    // baseURL,
    data
  })
}

// 权限管理-创建角色组
export function add_role(data) {
  return request({
    url: '/api/v1/organ/purview/role/add_role',
    method: 'post',
    // baseURL,
    data
  })
}

// 权限管理-编辑角色组
export function update_role(data) {
  return request({
    url: '/api/v1/organ/purview/role/update_role',
    method: 'post',
    // baseURL,
    data
  })
}

// 权限管理-删除角色组
export function delete_role(params) {
  return request({
    url: '/api/v1/organ/purview/role/delete_role',
    method: 'get',
    // baseURL,
    params
  })
}

// 权限管理-添加角色成员
export function add_role_user(data) {
  return request({
    url: '/api/v1/organ/purview/role/add_role_user',
    method: 'post',
    // baseURL,
    data
  })
}

// 权限管理-删除角色成员
export function delete_role_user(data) {
  return request({
    url: '/api/v1/organ/purview/role/delete_role_user',
    method: 'post',
    // baseURL,
    data
  })
}

// 权限管理-获取角色信息
export function get_role_update_auth_info(params) {
  return request({
    url: '/api/v1/organ/purview/role/get_role_update_auth_info',
    method: 'get',
    // baseURL,
    params
  })
}

// 权限管理-获取角色信息
export function update_role_auth_submit(data) {
  return request({
    url: '/api/v1/organ/purview/role/update_role_auth_submit',
    method: 'post',
    // baseURL,
    data
  })
}

/*****
 * 公告管理
 *******/

// 公告管理--公告列表
export function query_notice_list(data) {
  return request({
    url: '/api/v1/publish/notice/query_notice_list',
    method: 'post',
    // baseURL,
    data
  })
}

// 公告管理--新增公告
export function add_notice(data) {
  return request({
    url: '/api/v1/publish/notice/add_notice',
    method: 'post',
    // baseURL,
    data
  })
}

// 公告管理--删除公告
export function delete_notice(params) {
  return request({
    url: '/api/v1/publish/notice/delete_notice',
    method: 'get',
    // baseURL,
    params
  })
}

// 公告管理--编辑公告
export function update_notice(data) {
  return request({
    url: '/api/v1/publish/notice/update_notice',
    method: 'post',
    // baseURL,
    data
  })
}

// 公告管理--公告详情
export function query_notice_info(params) {
  return request({
    url: '/api/v1/publish/notice/query_notice_info',
    method: 'get',
    // baseURL,
    params
  })
}

// 企业设置--企业基础信息 查询
export function base_info(params) {
  return request({
    url: '/api/v1/enterprise/enterprise/base_info',
    method: 'get',
    // baseURL,
    params
  })
}

// 企业设置--企业基础信息--发送转让短信
export function send_make_over_message(data) {
  return request({
    url: '/api/v1/enterprise/enterprise/send_make_over_message',
    method: 'post',
    // baseURL,
    data
  })
}

// 企业设置--企业基础信息--效验转让短信
export function verify_make_over_phone(data) {
  return request({
    url: '/api/v1/enterprise/enterprise/verify_make_over_phone',
    method: 'post',
    // baseURL,
    data
  })
}

// 企业设置--企业基础信息--企业转让
export function make_over(params) {
  return request({
    url: '/api/v1/enterprise/enterprise/make_over',
    method: 'get',
    // baseURL,
    params
  })
}

// 企业设置--企业基础信息--企业更新
export function update_base_info(data) {
  return request({
    url: '/api/v1/enterprise/enterprise/update_base_info',
    method: 'post',
    // baseURL,
    data
  })
}

// 应用管理-应用列表
export function applicationEp_app_manageGet_manage_app_list(data) {
  return request({
    url: '/api/v1/application/ep_app_manage/get_manage_app_list',
    method: 'post',
    data
  })
}

//  应用管理-应用-审核应用头部详情
export function applicationEp_app_manageGet_app_info(params) {
  return request({
    url: '/api/v1/application/ep_app_manage/get_app_info',
    method: 'get',
    params
  })
}
//  应用管理-应用-审核应用头部详情-修改应用可见范围
export function applicationEp_app_manageUpdate_app_use_purview(data) {
  return request({
    url: '/api/v1/application/ep_app_manage/update_app_use_purview',
    method: 'post',
    data
  })
}
//  应用管理-应用-新增自建应用
export function applicationEp_app_manageAdd_self_built_app(data) {
  return request({
    url: '/api/v1/application/ep_app_manage/add_self_built_app',
    method: 'post',
    data
  })
}
//  应用管理-应用-编辑自建应用
export function applicationEp_app_manageUpdate_self_built_app(data) {
  return request({
    url: '/api/v1/application/ep_app_manage/update_self_built_app',
    method: 'post',
    data
  })
}
//  应用管理-应用-删除自建应用
export function applicationEp_app_manageDelete_self_built_app(params) {
  return request({
    url: '/api/v1/application/ep_app_manage/delete_self_built_app',
    method: 'post',
    params
  })
}
//  应用管理-应用-审核应用头部-修改应用启用状态
export function applicationEp_app_manageUpdate_disable_flag(data) {
  return request({
    url: '/api/v1/application/ep_app_manage/update_disable_flag',
    method: 'post',
    data
  })
}

//  应用管理-应用-审核应用列表
export function enterprisFlowGetAppFlow(params) {
  return request({
    url: '/api/v1/application/enterprise/flow/getAppFlow',
    method: 'get',
    params
  })
}
//  应用管理-应用-审核应用列表-启用/停用
export function enterprisFlowEnableOrDisable(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/enableOrDisable',
    method: 'post',
    data
  })
}

//  应用管理-应用-审核应用列表-提交复制流程
export function enterpriseFlowConfigCopyFlow(params) {
  return request({
    url: '/api/v1/application/enterprise/flowConfig/copyFlow',
    method: 'get',
    params
  })
}

//  应用管理-应用-审核应用列表-获取系统配置模板
export function flowConfigSystemGetSystemTem(params) {
  return request({
    url: '/api/v1/application/enterprise/flow/getSystemTem',
    method: 'get',
    params
  })
}

// 会议室预定列表
export function roomQuery_conference_room(data) {
  return request({
    url: '/api/v1/enterprise/room/room_page',
    method: 'post',
    data
  })
}
// 新增/修改会议室
export function getAddRoom(data) {
  return request({
    url: '/api/v1/enterprise/room/update_room',
    method: 'post',
    data
  })
}

// 会议室详情
export function noticeQuery_room_info(params) {
  return request({
    url: '/api/v1/enterprise/room/room_info',
    method: 'get',
    params
  })
}

// 删除会议室
export function getDelRoom(params) {
  return request({
    url: '/api/v1/enterprise/room/delete_room',
    method: 'get',
    params
  })
}

/***
 *
 * 企业活动
 */
// 活动列表
export function activityQuery_activity_list(data) {
  return request({
    url: '/api/v1/application/p/activity/query_activity_list',
    method: 'post',
    // baseURL,
    data
  })
}
// 新增活动
export function activityAdd_activity(data) {
  return request({
    url: '/api/v1/application/p/activity/add_activity',
    method: 'post',
    // baseURL,
    data
  })
}
// 修改活动
export function activityUpdate_activity(data) {
  return request({
    url: '/api/v1/application/p/activity/update_activity',
    method: 'post',
    // baseURL,
    data
  })
}
// 删除活动
export function activityDelete_activity(params) {
  return request({
    url: '/api/v1/application/p/activity/delete_activity',
    method: 'get',
    // baseURL,
    params
  })
}
// 活动详情
export function activityQuery_activity_info(params) {
  return request({
    url: '/api/v1/application/p/activity/query_activity_info',
    method: 'get',
    // baseURL,
    params
  })
}

// 已报名活动的人
export function activityQuery_sign_user(data) {
  return request({
    url: '/api/v1/application/p/activity/query_sign_user',
    method: 'post',
    // baseURL,
    data
  })
}

// 课程列表
export function curriculumQuery_curriculum_list(data) {
  return request({
    url: '/api/v1/application/curriculum/query_curriculum_list',
    method: 'post',
    // baseURL,
    data
  })
}

// 新增课程
export function curriculumAdd_curriculum(data) {
  return request({
    url: '/api/v1/application/curriculum/add_curriculum',
    method: 'post',
    // baseURL,
    data
  })
}
// 修改课程
export function curriculumUpdate_curriculum(data) {
  return request({
    url: '/api/v1/application/curriculum/update_curriculum',
    method: 'post',
    // baseURL,
    data
  })
}
// 课程详情
export function curriculumQuery_curriculum_info(params) {
  return request({
    url: '/api/v1/application/curriculum/query_curriculum_info',
    method: 'get',
    // baseURL,
    params
  })
}
// 课程获取已报名人数
// /api/v1/application/curriculum/get_signup_num
export function curriculumGet_signup_num(data) {
  return request({
    url: '/api/v1/application/curriculum/get_signup_num',
    method: 'post',
    // baseURL,
    data
  })
}

// 删除课程
// /api/v1/application/curriculum/get_signup_num
export function curriculumDelete_curriculum(params) {
  return request({
    url: '/api/v1/application/curriculum/delete_curriculum',
    method: 'get',
    // baseURL,
    params
  })
}

// 下载app二维码
// /api/v1/application/curriculum/get_signup_num
export function getDownloadQRCode() {
  return request({
    url: '/api/v1/common-app/open/app/version/getDownloadQRCode',
    method: 'get'
    // baseURL,
    // params
  })
}
