<template>
  <div class="m_enterprise_courseadd">
    <div class="go_back_common">
      <div class="go_back_title" @click="back">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="m_courseadd_formBody">
      <div class="title-dh mb20 mt20">基本信息</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" label-width="110px">
        <el-form-item ref="curriculumImageUrl" label="课程图片" prop="curriculumImageUrl" class="pic-item">
          <div class="m_courseadd_tips">
            <UmUploadImg v-model="ruleForm.curriculumImageUrl" :show-icon="false" custom-file :limit="1" is-cropper :cropper-options="cropperOptions">
              <template #extend>
                <div class="m_courseadd_tips2">
                  <div class="m_courseadd_imgicon" />
                </div>
              </template>
            </UmUploadImg>
          </div>
        </el-form-item>
        <el-form-item ref="curriculumName" label="课程名称" prop="curriculumName">
          <el-input v-model="ruleForm.curriculumName" style="width:40%" placeholder="请填写课程名称" maxlength="50" />
        </el-form-item>
        <el-form-item ref="curriculumSpeaker" label="主讲人" prop="curriculumSpeaker">
          <el-input v-model="ruleForm.curriculumSpeaker" style="width:40%" placeholder="请填写主讲人" maxlength="20" />
        </el-form-item>
        <el-form-item ref="curriculumAddress" label="课程地点" prop="curriculumAddress">
          <el-input v-model="ruleForm.curriculumAddress" style="width:40%" placeholder="请填写课程地点" maxlength="50" />
        </el-form-item>
        <el-form-item label="报名时间" prop="time2">
          <el-date-picker
            v-model="ruleForm.time2"
            type="datetimerange"
            style="width: 40%"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            @change="pickerChangeBm"
          />
        </el-form-item>
        <el-form-item label="课程时间" prop="time1">
          <el-date-picker
            v-model="ruleForm.time1"
            type="datetimerange"
            style="width: 40%"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            @change="pickerChangeKc"
          />
        </el-form-item>
        <el-form-item ref="signMaxNum" label="报名人数" prop="signMaxNum">
          <!--          <el-input-number v-model="ruleForm.signMaxNum" :min="1" :max="9999" size="mini" />-->
          <div class="m_courseadd_peopleNum">
            <span class="m_courseadd_add m_courseadd_ninus" :class="{add_disabled:ruleForm.signMaxNum<=1}" @click="minusNum">-</span>
            <div><el-input v-model="ruleForm.signMaxNum" style="width: 48px" :clearable="false" @change="resetnum" /></div>
            <span class="m_courseadd_add" :class="{add_disabled:ruleForm.signMaxNum>=9999}" @click="addNum">+</span>
          </div>
        </el-form-item>
        <el-form-item ref="curriculumDescribe" label="详细内容" prop="curriculumDescribe">
          <um-edit-pro
            v-model="ruleForm.curriculumDescribe"
            :url="ruleForm.curriculumDescUrl"
          />
          <!-- <Tinymce
            v-model="ruleForm.curriculumDescribe"
            :url="ruleForm.curriculumDescUrl"
            :height="200"
          /> -->
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" class="m_courseadd_btnsure" @click="submit">确认保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { curriculumQuery_curriculum_info, curriculumUpdate_curriculum } from '@/api/enterprise'
import moment from 'moment'
export default {
  name: 'EnterpriseApplicationCourseUpdate',
  props: {},
  data() {
    const validateTime = (rule, value, callback) => {
      console.log(value, 'value--------------')
      if ((!value) || (!value.length)) {
        callback(new Error('请选择报名时间段'))
      } else if (value && value.length) {
        const time1 = Date.parse(value[1])
        const time2 = Date.now()
        if (time1 < time2) {
          callback(new Error('报名结束时间不可早于当前时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateTime1 = (rule, value, callback) => {
      if ((!value) || (!value.length)) {
        callback(new Error('请选择课程时间段'))
      } else if (value && value.length) {
        const time1 = Date.parse(value[0])
        const time2 = Date.now()
        const time3 = Date.parse(value[1])
        const time4 = Date.parse(this.ruleForm.time2[1])
        if (time1 < time2) {
          callback(new Error('课程开始时间不可早于当前时间'))
        } else
        if (time3 < time4) {
          callback(new Error('课程结束时间不可早于报名结束时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      cropperOptions: {
        aspectRatio: 4 / 3
      },
      ruleForm: {
        curriculumImageUrl: '',
        curriculumName: '',
        curriculumAddress: '',
        curriculumSpeaker: '',
        applyStartTime: null,
        applyEndTime: null,
        curriculumStartTime: null,
        curriculumEndTime: null,
        time1: [],
        time2: [],
        curriculumDescribe: '',
        signMaxNum: 0
      },
      detail: '',
      pickerOptionsBm: {
        // 新增时报名结束结束时间不可早于当前时间，课程报名结束时间不可早于课程报名开始时间，课程开始时间不可早于当前时间，课程结束时间不可早已课程开始时间，课程结束时间不可早于课程报名结束时间
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      pickerOptionsKc: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      rules: {
        curriculumImageUrl: [{ required: true, message: '请选择课程图片', trigger: 'change' }],
        curriculumName: [
          { required: true, message: '请填写名称', trigger: 'blur' }
        ],
        curriculumSpeaker: [
          { required: true, message: '请填写主讲人', trigger: 'change' }
        ],
        curriculumAddress: [
          { required: true, message: '请填写课程地点', trigger: 'change' }
        ],
        time1: [
          { required: true, validator: validateTime1, trigger: 'change' }
        ],
        time2: [
          // { required: true, message: '请选择预定时间段', trigger: 'change' }
          { required: true, trigger: 'change', validator: validateTime }
        ],
        signMaxNum: [
          { required: true, message: '请选择人数', trigger: 'change' }
        ],
        curriculumDescribe: [
          { required: true, message: '请填写详细内容', trigger: 'change' }
        ]
      }
    }
  },
  watch: {},
  created() {
    this.curriculumUuid = this.$route.query.curriculumUuid
    if (this.curriculumUuid) {
      this.getInfo()
    }
  },
  mounted() {},
  methods: {
    getInfo() {
      curriculumQuery_curriculum_info({ curriculumUuid: this.curriculumUuid }).then(res => {
        // this.ruleForm = res.data
        // Object.assign(this.ruleForm, res.data)
        this.ruleForm.time2 = [res.data.applyStartTime, res.data.applyEndTime]
        this.ruleForm.time1 = [res.data.curriculumStartTime, res.data.curriculumEndTime]
        this.ruleForm.curriculumImageUrl = res.data.curriculumIconUrl
        this.ruleForm.curriculumName = res.data.curriculumName
        this.ruleForm.curriculumSpeaker = res.data.curriculumSpeaker
        this.ruleForm.curriculumDescribe = res.data.curriculumDescribe
        this.ruleForm.signMaxNum = res.data.signMaxNum
        this.ruleForm.curriculumAddress = res.data.curriculumAddress
        this.ruleForm.curriculumDescribe = res.data.curriculumDescribe
        this.ruleForm.curriculumDescUrl = res.data.curriculumDescUrl
      })
    },
    pickerChangeKc(data) { // 课程时间
      console.log(data, 'data')
      if (data && data.length) {
        this.ruleForm.curriculumStartTime = data[0]
        this.ruleForm.curriculumEndTime = data[1]
      } else {
        this.ruleForm.curriculumStartTime = null
        this.ruleForm.curriculumEndTime = null
      }
      console.log(this.ruleForm.curriculumStartTime)
    },

    pickerChangeBm(date) { // 报名时间
      if (date && date.length) {
        this.ruleForm.applyStartTime = date[0]
        this.ruleForm.applyEndTime = date[1]
      } else {
        this.ruleForm.applyStartTime = null
        this.ruleForm.applyEndTime = null
      }
    },
    back() {
      this.$router.replace({
        path: '/course',
        query: {
          appUuid: this.$route.query.appUuid
        }
      })
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.pickerChangeBm(this.ruleForm.time2)
          this.pickerChangeKc(this.ruleForm.time1)
          const load = this.$load()
          try {
            const param = {
              // ...this.ruleForm
              curriculumImageUrl: this.ruleForm.curriculumImageUrl,
              curriculumName: this.ruleForm.curriculumName,
              curriculumAddress: this.ruleForm.curriculumAddress,
              curriculumSpeaker: this.ruleForm.curriculumSpeaker,
              applyStartTime: this.ruleForm.applyStartTime,
              applyEndTime: this.ruleForm.applyEndTime,
              curriculumStartTime: this.ruleForm.curriculumStartTime,
              curriculumEndTime: this.ruleForm.curriculumEndTime,
              curriculumDescribe: this.ruleForm.curriculumDescribe,
              signMaxNum: this.ruleForm.signMaxNum
            }
            param.curriculumStartTime = moment(param.curriculumStartTime).format('YYYY-MM-DD HH:mm:ss')
            param.curriculumEndTime = moment(param.curriculumEndTime).format('YYYY-MM-DD HH:mm:ss')
            param.applyStartTime = moment(param.applyStartTime).format('YYYY-MM-DD HH:mm:ss')
            param.applyEndTime = moment(param.applyEndTime).format('YYYY-MM-DD HH:mm:ss')
            param.curriculumUuid = this.curriculumUuid
            console.log('🚀  - file: index.vue  - line 172  - param', param)
            await curriculumUpdate_curriculum({
              ...param
            })
            this.$alert('保存成功！', '提示', {
              type: 'success',
              showClose: false
            }).then(d => {
              this.$router.go(-1)
            })
          } catch (e) {
            this.$errorHandle(e)
          } finally {
            load.close()
          }
        }
      })
    },
    addNum() {
      if (this.ruleForm.signMaxNum < 9999) {
        this.ruleForm.signMaxNum = this.ruleForm.signMaxNum * 1 + 1
      }
    },
    resetnum(val) {
      console.log(val)
      if (val * 1 >= 9999) {
        this.ruleForm.signMaxNum = 9999
      }
      if (val * 1 <= 1) {
        this.ruleForm.signMaxNum = 1
      }
      if (!/^\d+$|^\d+[.]?\d+$/.test(val)) {
        this.ruleForm.signUserMaxNum = 1
      }
    },
    minusNum() {
      if (this.ruleForm.signMaxNum > 1) {
        this.ruleForm.signMaxNum = this.ruleForm.signMaxNum * 1 - 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
