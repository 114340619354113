<template>
  <div class="m_enterprise_manage_box">
    <div class="enterprise_manage_sidebarbox">
      <div class="m-enterprise_infobox">
        <div class="m-enterprise_info">
          <div class="m-enterprise_img">
            <el-image
              style="width: 100%; height: 100%;border-radius: 4px"
              :src="enterpriseLogoImg"
              fit="fit"
            >
              <div slot="error" class="image-slot">
                <div class="m-enterprise_img_nodata" />
              </div>
            </el-image>
          </div>
          <div class="m-enterprise_name row_2">{{ enterpriseName || '-' }}</div>
        </div>
      </div>
      <div class="m-enterprise_menu">
        <el-menu
          :default-active="activeMenu"
          class="gp-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
        >
          <template v-for="(item,idx) in menus_role">
            <el-submenu v-if="item.children.length" :key="idx" :index="item.path">
              <template slot="title">
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-for="(child,idx2) in item.children" :key="idx2" :index="child.path">
                  <svg-icon :icon-class="child.icon" />
                  {{ child.title }}
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-else :key="idx" :index="item.path" class="m-enterprise_comoncolor">
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>
    <div class="enterprise_manage_contentbox">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EnterpriseLayout',
  data() {
    return {
      menus: [
        {
          title: '首页',
          path: '/administration',
          children: [],
          role: '1120301'
        },
        // {
        //   title: '首页',
        //   path: '/enterprise/home',
        //   children: [],
        //   role: '1120301'
        // },
        {
          title: '通讯录',
          path: 'address',
          role: '1120302',
          children: [
            { title: '企业通讯录', icon: 'tongxunlu', path: '/enterprise/address', role: '112030201' }
          ]
        },
        {
          title: '工作台',
          path: 'application',
          role: '1120303',
          children: [
            { title: '应用管理', icon: 'yingyongguanli', path: '/enterprise/application', role: '112030301' },
            { title: '公告管理', icon: 'gongaoguanli', path: '/enterprise/notice', role: '112030302' }
          ]
        },
        {
          title: '企业设置',
          path: 'basic',
          role: '1120304',
          children: [
            { title: '企业基础信息', icon: 'qijichuxinxi', path: '/enterprise/basic', role: '112030401' },
            { title: '权限管理', icon: 'quanxianguanli', path: '/enterprise/role', role: '112030402' }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'name',
      'userPosition',
      'enterpriseUuid',
      'enterpriseName',
      'enterpriseLogoImg',
      'roles'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // // if set path, the sidebar will highlight the path you set
      // if (meta.activeMenu) {
      //   return meta.activeMenu
      // }
      return path.split('/').slice(0, 3).join('/')
    },
    key() {
      return this.$route.path
    },
    menus_role() {
      const list = []
      this.menus.forEach(item => {
        let newItem = {}
        if (this.roles.includes(item.role)) {
          newItem = { ...item }
          newItem.children = item.children.filter(i => this.roles.includes(i.role))
          list.push(newItem)
        }
      })
      return list
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      //
    },
    handleClose(key, keyPath) {
      //
    },
    handleSelect(commentname) {
      console.log('commentname', commentname)
      this.$router.push(commentname)
    }
  }
}
</script>
<style scoped lang="scss">
</style>
