<template>
  <!--  <iframe class="flow_main" :src="url" frameborder="0" width="100%" :height="(clientHeight || 400) + 'px'" />-->
  <div class="flow_main">
    <div class="top-back" @click="$router.back()">
      <div class="title-back w60">返回</div>
    </div>
    <div class="flow_content">
      <UmFlowBase :mode="1" :model-uuid="modelUuid" :flow-uuid="appUuid" :type-code="100101" :get-default-icon="getDefaultIcon" @submit="submit" />
    </div>
  </div>
</template>

<script>
import { getDefaultIcon } from '@/api/flow'
export default {
  name: 'FlowConfig',
  props: {},
  data() {
    return {
      // url: '',
      // clientHeight: 0
      mode: 1,
      modelUuid: '',
      appUuid: '',
      file: null
    }
  },
  created() {
    const { modelUuid = '', appUuid = '' } = this.$route.query

    this.modelUuid = modelUuid
    this.appUuid = appUuid

    // if (process.env.NODE_ENV === 'development') {
    //   this.url = 'http://localhost:1024/' + '?token=qifuyun-pc-token&mode=1&modelUuid=' + `${modelUuid}` + '&appUuid=' + `${appUuid}`
    // } else {
    //   this.url = '/qifuyun_flow/' + '?token=qifuyun-pc-token&mode=1&modelUuid=' + `${modelUuid}` + '&appUuid=' + `${appUuid}`
    // }
  },
  mounted() {
    // const clientHeight = this.$el.parentElement.clientHeight
    // this.clientHeight = clientHeight - 40
    // window.addEventListener('message', this.message)
  },
  beforeDestroy() {
    // window.removeEventListener('message', this.message)
  },
  methods: {
    submit() {
      this.$router.back()
    },
    message({ data }) {
      if (data && data.type) {
        if (data.type === 1) {
          this.$store.dispatch('tagsView/delView', this.$route)
          this.$router.back()
        }
      }
    },
    // 获取系统预设icon
    getDefaultIcon(params) {
      return getDefaultIcon({ ...params })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-back{
  margin-bottom: 20px;
}
.flow_main{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flow_content{
  flex:1;
  // overflow: hidden;
}
</style>
