
const state = {
  im: null,
  nickName: '', // 用户昵称
  avatar: '', // 用户头像
  totalUnread: 0// 用户头像
}

const mutations = {
  SET_UMIM(state, im) {
    state.im = im
  },
  SET_STATE(state, { key, value }) {
    state[key] = value
  }
}

const actions = {
  setState({ commit, state }, { key, value }) {
    commit('SET_STATE', { key, value })
  },
  layout({ commit, state }) {
    state.UmIM && state.UmIM.logout()
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
