const getters = {
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  userPosition: state => state.user.userPosition, // 职务
  imAccountId: state => state.user.imAccountId,
  enterpriseName: state => state.user.enterpriseName,
  enterpriseUuid: state => state.user.enterpriseUuid,
  enterpriseLogoImg: state => state.user.enterpriseLogoImg,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  userInfo: state => state.user.userInfo,
  // im相关
  UmIM: state => state.im.UmIM,
  session: state => state.im.session,
  sessionList: state => state.im.sessionList,
  records: state => state.im.records,
  mineInfo: state => state.im.mineInfo,
  uploading: state => state.im.uploading,
  // 新版IM
  im: state => state.umIm.im, // 当前IM实例
  imNickName: state => state.umIm.nickName, // 当前IM用户名称
  imAvatar: state => state.umIm.avatar, // 当前IM用户头像
  totalUnread: state => state.umIm.totalUnread, // 当前总未读数量

  appUuid: state => state.user.appUuid,
  deviceId: state => state.user.deviceId,
  pathRoute: (state) => (name) => {
    const path = state.app.pathRoute[name]
    state.app.pathRoute[name] = null
    return path
  } // 需要跳转的页面

}
export default getters
