<template>
  <div>
    <um-url :url="contentUrl" />
  </div>
</template>

<script>
export default {
  name: 'Jump',
  data() {
    return {
      title: '',
      contentUrl: ''
    }
  },
  created() {
    const { title, contentUrl } = this.$route.query
    this.title = title
    this.contentUrl = decodeURIComponent(contentUrl)

    // var sUserAgent = navigator.userAgent.toLowerCase()
    // var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad'
    // var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os'
    // var bIsMidp = sUserAgent.match(/midp/i) == 'midp'
    // var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4'
    // var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb'
    // var bIsAndroid = sUserAgent.match(/android/i) == 'android'
    // var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce'
    // var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile'
    // if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
    //   // 跳转移动端页面
    //   this.showHead = true
    // } else {
    //   // 跳转pc端页面
    //   this.showHead = false
    // }
  }
}
</script>

<style scoped>

</style>
