<template>
  <!-- 首页-为你推荐 -->
  <div class="m-recommendBox">
    <!-- 搜索框 -->
    <div class="flex mb20 recommendBox_searchBox">
      <div class="searchBox_inp">
        <div ref="searchBox" class="searchBox_inp-unCheck" :class="flag===true?'searchBox_inp-check':''">
          <el-input v-model.trim="keyWord" placeholder="请输入关键词搜索" class="keyWord" maxlength="50" clearable @keyup.enter.native="getSearch()" @focus="getFocus(true)" />
          <div v-if="flag ===true">
            <div class="searchBox-line" />
            <div class="searchBox-record">
              <div class="record-title">搜索记录</div>
              <div class="record-list  wrap">
                <div v-for="(item,index) in recommendSearchList" :key="index" class="record-item mb10" @click="words(item)">{{ item }}</div>
              </div>
            </div>
            <div class="searchBox-record">
              <div class="record-title mt20">热门搜索</div>
              <div class="record-list">
                <div v-for="(item,index) in hotList" :key="index" class="record-item" @click="words(item.labelName)">{{ item.labelName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-button icon="el-icon-search" type="primary" class="ml20 searchBox_btn" size="small" @click="getSearch()">搜索</el-button>
    </div>
    <!-- banner -->
    <banner :banner-list="bannerList" />
    <!-- 应用 -->
    <div class="cardBox">
      <div class="cardBox_title f-mg-b-26">精选应用</div>
      <el-row v-if="applicationList.length>0">
        <el-col v-for="(item,i) in applicationList" :key="i" :span="6">
          <application v-if="applicationList.length>0" :type="2" :form="item" :tab-type="1" />
        </el-col>
      </el-row>
      <noData v-else />
    </div>
    <div class="cardBox solutionBox">
      <div class="cardBox_title">
        解决方案精选
      </div>
      <div class="solutionBox_Cotent">
        <solution v-if="solutionList.length>0" :type="3" :solution-list="solutionList" :tab-type="1" :margin-style="3" />
        <noData v-else />
      </div>
    </div>
    <div class="cardBox">
      <div class="cardBox_title f-mg-b-26">精选服务</div>
      <el-row v-if="selectServiceList.length>0">
        <el-col v-for="(item,i) in selectServiceList" :key="i" :span="6">
          <application :type="4" :form="item" :tab-type="1" />
        </el-col>
      </el-row>
      <noData v-else />
    </div>
    <div class="cardBox ">
      <!--      <div class="cardBox_title mb20">智能硬件</div>-->
      <div class="cardBox_title mb20">商品</div>
      <el-row v-if="hardwareList.length>0" style="padding:0 20px">
        <el-col v-for="(item,i) in hardwareList" :key="i" :span="6">
          <hardwareList :type="5" :form="item" :tab-type="1" class="mb20 " />
          <!-- <application v-if="hardwareList.length>0" :type="5" :form="item" :tab-type="1" /> -->
        </el-col>
      </el-row>
      <noData v-else />
    </div>
  </div>
</template>

<script>
import Application from '../components/application.vue'
// import search from '../components/search.vue'
import solution from '../components/solution.vue'
import banner from '../components/banner.vue'
import noData from '../components/noData.vue'
import hardwareList from '../components/hardwareList.vue'
import { getBanner, getSelectedApplications, getSolution, getSelectService, selectedHardware, getHotSearch } from '@/api/cloudMarket'
export default {
  name: 'CloudMarketRecommend',
  components: { solution, Application, banner, noData, hardwareList },
  data() {
    return {
      flag: false,
      keyWord: '',
      bannerList: [],
      applicationList: [],
      solutionList: [],
      selectServiceList: [],
      hardwareList: [],
      type: null,
      recommendSearchList: [],
      hotList: []
    }
  },
  mounted() {
    this.getBanner()
    this.getApplication()
    this.getSolution()
    this.getSelectService()
    this.getSelectedHardware()
    this.getHotSearch()
    const key = 'recommendSearch_' + this.$store.getters.enterpriseUuid
    try {
      const redult = JSON.parse(window.localStorage.getItem(key))
      if (redult && Array.isArray(redult)) {
        this.recommendSearchList = redult
      } else {
        this.recommendSearchList = []
      }
    } catch {
      this.recommendSearchList = []
    }
  },
  created() {
    document.body.addEventListener('click', this.bodyClickhandler)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.bodyClickhandler)
  },
  methods: {
    bodyClickhandler(event) {
      const _target = event.target
      if (this.$refs.searchBox) {
        if (this.$refs.searchBox.contains(_target)) {
          //
        } else {
          this.flag = false
        }
      }
    },
    getFocus(flag) {
      this.flag = flag
    },
    // 热门搜索
    getHotSearch() {
      getHotSearch({ type: 20039904 }).then(res => {
        this.hotList = res.data.list
      }).catch(e => {

      })
    },
    words(val, hotList) {
      this.keyWord = val
      this.getSearch()
    },
    // 获取banner
    getBanner() {
      getBanner({ bannerNo: '200912001-P' }).then(res => {
        this.bannerList = res.data
      })
    },
    // 应用
    getApplication() {
      getSelectedApplications({ type: 4 }).then(res => {
        this.applicationList = res.data.list || []
      })
    },
    // 解决方案精选
    getSolution() {
      getSolution({}).then(res => {
        this.solutionList = res.data.list || []
      })
    },
    // 精选服务
    getSelectService() {
      getSelectService({ page: { pageNum: 1, pageSize: 10 }}).then(res => {
        res.data.list.forEach(item => {
          let info = {}
          info = {
            appUuid: item.uuid,
            appName: item.title,
            appIconUrl: item.iconUrl,
            appDescription: item.description,
            appPrice: item.price
          }

          this.selectServiceList.push(info)
        })
      })
    },
    // 智能硬件
    getSelectedHardware() {
      selectedHardware({ rankCode: 20039814 }).then(res => {
        res.data.list.forEach(item => {
          let info = {}
          info = {
            appUuid: item.spuUuid,
            appName: item.goodsTitle,
            appIconUrl: item.goodsCoverImg,
            appDescription: item.goodsDescription,
            appPrice: item.goodsPrice,
            saleNum: item.saleNum,
            visitNum: item.visitNum
          }
          this.hardwareList.push(info)
        })
      })
    },
    // 搜索
    getSearch() {
      if (this.keyWord) {
        // 存储
        if (this.keyWord) {
          const key = 'recommendSearch_' + this.$store.getters.enterpriseUuid
          this.recommendSearchList.unshift(this.keyWord)
          this.recommendSearchList = [...new Set(this.recommendSearchList)].splice(0, 5)
          window.localStorage.setItem(key, JSON.stringify(this.recommendSearchList))
        }
        console.log(this.hotList)
        this.$router.push({ path: '/cloudMarket/search', query: { keyWord: this.keyWord, hotList: this.hotList, tabType: 1 }})
        this.keyWord = ''
      } else {
        this.$message.error('请输入搜索关键词')
      }
    }

  }

}

</script>

