<template>
  <div class="m_maindata_home">
    <div class="m_maindata_home_title">建筑主数据</div>
    <div class="m_maindata_home_list">
      <div
        v-for="(item,index) in routerList"
        :key="index"
        v-permission="item.meta.roles"
        class="m_maindata_home_listitem"
        @click="$router.push(item.path)"
      >
        <svg-icon :icon-class="item.meta.icon" class-name="u_listitem_icon" />
        <div class="u_listitem_title row_one">{{ item.meta.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import mainDataRouter from '@/router/modules/mainData'
import { mapGetters } from 'vuex'
export default {
  name: 'MainDataHome',
  data() {
    return {
      mainDataRouter
    }
  },
  computed: {
    ...mapGetters(['permission_routes']),
    routerList() {
      const routes = this.permission_routes.find(item => item.path === '/mainData')
      console.log('routes', this.permission_routes)
      console.log('哈哈哈哈')
      if (routes) {
        return routes.children.filter((item, idx) => item.meta && item.meta.level === 2 && item.name !== 'MainDataHome')
      } else {
        return []
      }
    }
  },
  created() {
    console.log('routes', this.permission_routes)
  }
}
</script>

<style scoped lang="scss">
</style>
