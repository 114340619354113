import request from '@/utils/request'
const baseURL = 'http://api.pm.youmatech.com/mock/983'

// 部位公区树列表查询
export function building_part_tree_list(params) {
  return request({
    url: '/api/v1/data/bus_building_part/building_part_tree_list',
    method: 'get',
    // baseURL,
    params
  })
}

// 部位/公区新增
export function save_building_part(data) {
  return request({
    url: '/api/v1/data/bus_building_part/save_building_part',
    method: 'post',
    // baseURL,
    data
  })
}

// 部位/公区编辑
export function update_building_part(data) {
  return request({
    url: '/api/v1/data/bus_building_part/update_building_part',
    method: 'post',
    // baseURL,
    data
  })
}

// 部位/公区删除
export function delete_building_part(params) {
  return request({
    url: '/api/v1/data/bus_building_part/delete_building_part',
    method: 'get',
    // baseURL,
    params
  })
}

// 部位列表
export function building_part_page_list(data) {
  return request({
    url: '/api/v1/data/bus_building_part/building_part_page_list',
    method: 'post',
    // baseURL,
    data
  })
}
