<template>
  <div v-show="visible" class="m-room_add">
    <HotTable ref="hotRef" :settings="settings" language="zh-CN" style="width:100%;overflow: hidden;font-size: 12px;" />
  </div>
</template>

<script>
import { HotTable } from '@um/handsontable-vue'
import Handsontable from 'handsontable'
import 'handsontable/languages/zh-CN'
import { deepClone } from '@/utils'
// 楼层信息
class BuildingFloor {
  constructor(floorName = '无数据', busBuildingFloorId = null, floorRealSort = null, deleteFlag = 0) {
    this.busBuildingFloorId = busBuildingFloorId // 楼层id
    this.floorName = floorName // 楼层名称
    this.floorRealSort = floorRealSort // 楼层排序
    this.deleteFlag = deleteFlag // 删除标识 0未删除 1删除
  }
}
// 单元信息
class BuildingUnit {
  constructor(unitName = '无数据', busBuildingUnitId = null, unitRealSort = null, deleteFlag = 0) {
    this.busBuildingUnitId = busBuildingUnitId // 单元id
    this.unitName = unitName // 单元名称
    this.unitRealSort = unitRealSort // 单元排序
    this.deleteFlag = deleteFlag // 删除标识 0未删除 1删除
  }
}
// 房号信息
class BuildingDoor {
  constructor(doorName = '无数据', unitRealSort = null, busBuildingDoorId = null, doorRealSort = null, deleteFlag = 0) {
    this.busBuildingDoorId = busBuildingDoorId // 房号id
    this.doorName = doorName // 房号名称
    this.doorRealSort = doorRealSort // 房号排序
    this.unitRealSort = unitRealSort // 单元排序
    this.deleteFlag = deleteFlag // 删除标识 0未删除 1删除
  }
}
// 房间信息
class BuildingRoom {
  constructor(
    roomName = '无数据', busHouseTypeId = null, roomType = 20242022,
    deleteFlag = 0, busBuildingRoomId = null, roomMergeFlag = 0,
    roomMergeFloor = 1, roomMergeDoor = 1, floorRealSort = null, unitRealSort = null, roomRealSort = null) {
    this.roomName = roomName // 房间名称
    this.busHouseTypeId = busHouseTypeId // 户型id
    this.roomType = roomType // 房间类型
    this.deleteFlag = deleteFlag // 删除标识 0未删除 1删除
    this.busBuildingRoomId = busBuildingRoomId // 房间id
    this.roomMergeFlag = roomMergeFlag // 是否合并其他房间
    this.roomMergeFloor = roomMergeFloor // 合并楼层数量
    this.roomMergeDoor = roomMergeDoor // 合并同楼层房间的数量
    this.floorRealSort = floorRealSort // 真实楼层
    this.unitRealSort = unitRealSort // 真实单元序号
    this.roomRealSort = roomRealSort // 真实房间
  }
}
export default {
  name: 'Add',
  components: { HotTable },
  props: {
    visible: { type: Boolean, default: false },
    floor: { type: Number, default: 1 },
    unit: { type: Number, default: 1 },
    door: { type: Number, default: 1 },
    busBuildingUnit: { type: Array, default: () => [] },
    busBuildingRoom: { type: Array, default: () => [] },
    busBuildingFloor: { type: Array, default: () => [] },
    busBuildingDoor: { type: Array, default: () => [] }
  },
  data() {
    const _this = this
    return {
      floor_num: 10,
      unit_num: 3,
      door_num: 4,
      // busBuildingUnit: [],
      // busBuildingRoom: [],
      // busBuildingFloor: [],
      // busBuildingDoor: [],
      listData: [], // 初始化数据
      resultData: [], // 返回结果数据
      type: false,
      settings: {
        fillHandle: false,
        // height: window.innerHeight - 280,
        height: 380,
        readOnly: false,
        data: [],
        colHeaders: false,
        rowHeaders: false,
        // stretchH: 'all', // 撑开属性
        manualColumnFreeze: true, // 手动列冻结 开启
        fixedRowsTop: 2, // 浮动行  冻结行
        fixedColumnsLeft: 2, // 浮动列  冻结列
        colWidths: [50, 50, 100],
        cells: (row, column, prop) => {
          const columnMeta = {}
          // 左上角
          if (row <= 1 && column <= 1) {
            columnMeta.renderer = (hotInstance, td, row, col, prop, value, cellProperties) => {
              td.innerHTML = value
              td.className = 'htCenter htMiddle hTitle'
              td.style = 'padding:0;'
              return td
            }
            columnMeta.readOnly = true
          }
          // 楼层
          class CustomFloorName extends Handsontable.editors.TextEditor {
            prepare(row, col, prop, td, originalValue, cellProperties) {
              super.prepare(row, col, prop, td, originalValue, cellProperties)
            }

            setValue() {
              if (this.originalValue !== null) {
                this.TEXTAREA.value = this.originalValue.floorName
              }
            }

            getValue() {
              if (this.originalValue !== null) {
                this.originalValue.floorName = this.TEXTAREA.value
              }
              return this.originalValue
            }
          }
          if (row > 1 && column === 0) {
            columnMeta.renderer = (hotInstance, td, row, col, prop, value, cellProperties) => {
              td.innerHTML = value ? value.floorName : value
              td.className = 'htCenter htMiddle hTitle'
              return td
            }
            columnMeta.editor = CustomFloorName
          }
          // 单元
          class CustomUnitName extends Handsontable.editors.TextEditor {
            prepare(row, col, prop, td, originalValue, cellProperties) {
              super.prepare(row, col, prop, td, originalValue, cellProperties)
            }

            setValue() {
              if (this.originalValue !== null) {
                this.TEXTAREA.value = this.originalValue.unitName
              }
            }

            getValue() {
              if (this.originalValue !== null) {
                this.originalValue.unitName = this.TEXTAREA.value
              }
              return this.originalValue
            }
          }
          if (row === 0 && column > 1) {
            columnMeta.renderer = (hotInstance, td, row, col, prop, value, cellProperties) => {
              td.innerHTML = value ? value.unitName : value
              td.className = 'htCenter htMiddle hTitle'
              return td
            }
            columnMeta.editor = CustomUnitName
          }
          // 房号
          class CustomDoorName extends Handsontable.editors.TextEditor {
            prepare(row, col, prop, td, originalValue, cellProperties) {
              super.prepare(row, col, prop, td, originalValue, cellProperties)
            }

            setValue() {
              if (this.originalValue !== null) {
                this.TEXTAREA.value = this.originalValue.doorName
              }
            }

            getValue() {
              if (this.originalValue !== null) {
                this.originalValue.doorName = this.TEXTAREA.value
              }
              return this.originalValue
            }
          }
          if (row === 1 && column > 1) {
            columnMeta.renderer = (hotInstance, td, row, col, prop, value, cellProperties) => {
              td.innerHTML = value ? value.doorName : value
              td.className = 'htCenter htMiddle hTitle'
              return td
            }
            columnMeta.editor = CustomDoorName
          }
          // 房间
          class CustomRoomName extends Handsontable.editors.TextEditor {
            prepare(row, col, prop, td, originalValue, cellProperties) {
              super.prepare(row, col, prop, td, originalValue, cellProperties)
            }

            setValue() {
              if (this.originalValue !== null) {
                this.TEXTAREA.value = this.originalValue.roomName
              }
            }

            getValue() {
              if (this.originalValue !== null) {
                this.originalValue.roomName = this.TEXTAREA.value
              }
              return this.originalValue
            }
          }
          if (row > 1 && column > 1) {
            columnMeta.renderer = (hotInstance, td, row, col, prop, value, cellProperties) => {
              let html = ''
              if (value && value.roomType == 20242021) {
                html += '<span class="roomType">公</span>'
              } else if (value && value.roomType == 20242022) {
                html += '<span class="roomType">房</span>'
              } else if (value && value.roomType == 20242023) {
                html += '<span class="roomType">车</span>'
              } else if (value && value.roomType == 20242024) {
                html += '<span class="roomType">商</span>'
              } else if (value && value.roomType == 20242025) {
                html += '<span class="roomType">储</span>'
              }
              html += value ? value.roomName : value
              td.innerHTML = html
              td.className = 'htCenter htMiddle htRoom'

              if (value && value.deleteFlag == 1) {
                cellProperties.readOnly = true
                td.className += ' deleted'
                td.innerHTML = '---'
              }
              return td
            }
            columnMeta.editor = CustomRoomName
          }
          return columnMeta
        },
        afterChange: function(changes, type) {
          // if (type === 'UndoRedo.undo') {
          //   return false
          // }
          _this.resultData = [].concat(this.getSourceData())
          console.log(_this.resultData)
          if (changes !== null) {
            if (changes[0][0] > 1 && changes[0][1] <= 1) {
              // _this.changeData()
            }

            if (changes[0][0] <= 1 && changes[0][1] > 1) {
              // _this.changeData()
            }
          }
        },
        // 取消ctrl z撤销
        beforeUndo(a) {
          return false
        },
        afterOnCellMouseUp: (event, coords, TD) => {
          if (coords.row > 1 && coords.col <= 1) {
            this.hotRef.selectRows(coords.row)
          }
          if (coords.row < 1 && coords.col > 1) {
            this.hotRef.selectColumns(coords.col, coords.col + TD.colSpan - 1)
          }
        },
        afterSelection: (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
          // setting if prevent scrolling after selection
        },
        /*
         getSelectedLast  返回应用于表的最后一个坐标【实际结果返回的的是一个区间范围】
         */
        contextMenu: {
          callback: function(key, selection, clickEvent) {
            if (key === 'mergeCells') {
              const sel = this.getSelectedLast()
              if (sel) {
                const info = this.getPlugin('MergeCells').mergedCellsCollection.get(sel[0], sel[1])
                let mergeStatus = false
                if (info.row === sel[0] && info.col === sel[1] && info.row + info.rowspan - 1 === sel[2] && info.col + info.colspan - 1 === sel[3]) {
                  // 该单元格是合并状态  需要清空多余的属性
                  mergeStatus = true
                } else {
                  // 该单元格是取消合并状态，需要填充默认对象
                  mergeStatus = false
                }

                const list = this.getSourceData().map((item, index) => {
                  if (index >= sel[0] && index <= sel[2] && index > 1) {
                    return item.map((item2, index2) => {
                      if (index === sel[0] && index2 === sel[1]) {
                        if (item2 == null) item2 = new BuildingRoom('')
                        if (mergeStatus) {
                          item2.roomMergeFlag = 1
                          item2.roomMergeFloor = sel[2] - sel[0] + 1
                          item2.roomMergeDoor = sel[3] - sel[1] + 1
                        } else {
                          item2.roomMergeFlag = 0
                          item2.roomMergeFloor = 1
                          item2.roomMergeDoor = 1
                        }
                        return item2
                      }
                      if (index2 >= sel[1] && index2 <= sel[3] && index2 > 1) {
                        return new BuildingRoom('')
                      } else {
                        return item2
                      }
                    })
                  } else {
                    return item
                  }
                })
                this.loadData(list)
              }
            }
          },
          items: {
            'custom_room4': {
              name: '设置为商铺',
              disabled: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[2] < 2 || select[3] < 2) {
                  return true
                } else {
                  return false
                }
              },
              hidden: () => {
              },
              callback: function(key, selection, clickEvent) {
                const offset = selection[0]
                const list = this.getSourceData().map((item, index) => {
                  if (index >= offset.start.row && index <= offset.end.row && index > 1) {
                    return item.map((item2, index2) => {
                      if (index2 >= offset.start.col && index2 <= offset.end.col && index2 > 1) {
                        item2.roomType = 20242024
                        return item2
                      } else {
                        return item2
                      }
                    })
                  } else {
                    return item
                  }
                })
                this.loadData(list)
              }
            },
            'custom_room3': {
              name: '设置为车位',
              disabled: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[2] < 2 || select[3] < 2) {
                  return true
                } else {
                  return false
                }
              },
              hidden: () => {
              },
              callback: function(key, selection, clickEvent) {
                const offset = selection[0]
                const list = this.getSourceData().map((item, index) => {
                  if (index >= offset.start.row && index <= offset.end.row && index > 1) {
                    return item.map((item2, index2) => {
                      if (index2 >= offset.start.col && index2 <= offset.end.col && index2 > 1) {
                        item2.roomType = 20242023
                        return item2
                      } else {
                        return item2
                      }
                    })
                  } else {
                    return item
                  }
                })
                this.loadData(list)
              }
            },
            'custom_room2': {
              name: '设置为公区',
              disabled: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[2] < 2 || select[3] < 2) {
                  return true
                } else {
                  return false
                }
              },
              hidden: () => {
              },
              callback: function(key, selection, clickEvent) {
                const offset = selection[0]
                const list = this.getSourceData().map((item, index) => {
                  if (index >= offset.start.row && index <= offset.end.row && index > 1) {
                    return item.map((item2, index2) => {
                      if (index2 >= offset.start.col && index2 <= offset.end.col && index2 > 1) {
                        item2.roomType = 20242021
                        return item2
                      } else {
                        return item2
                      }
                    })
                  } else {
                    return item
                  }
                })
                this.loadData(list)
              }
            },
            'custom_room1': {
              name: '设置为住宅',
              disabled: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[2] < 2 || select[3] < 2) {
                  return true
                } else {
                  return false
                }
              },
              hidden: () => {
              },
              callback: function(key, selection, clickEvent) {
                const offset = selection[0]
                const list = this.getSourceData().map((item, index) => {
                  if (index >= offset.start.row && index <= offset.end.row && index > 1) {
                    return item.map((item2, index2) => {
                      if (index2 >= offset.start.col && index2 <= offset.end.col && index2 > 1) {
                        item2.roomType = 20242022
                        return item2
                      } else {
                        return item2
                      }
                    })
                  } else {
                    return item
                  }
                })
                this.loadData(list)
              }
            },
            // 新增储藏间
            'custom_room5': {
              name: '设置为储藏间',
              disabled: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[2] < 2 || select[3] < 2) {
                  return true
                } else {
                  return false
                }
              },
              hidden: () => {
              },
              callback: function(key, selection, clickEvent) {
                const offset = selection[0]
                const list = this.getSourceData().map((item, index) => {
                  if (index >= offset.start.row && index <= offset.end.row && index > 1) {
                    return item.map((item2, index2) => {
                      if (index2 >= offset.start.col && index2 <= offset.end.col && index2 > 1) {
                        item2.roomType = 20242025
                        return item2
                      } else {
                        return item2
                      }
                    })
                  } else {
                    return item
                  }
                })
                this.loadData(list)
              }
            },
            'mergeCells': {
              name: function name() {
                const sel = this.getSelectedLast()
                if (sel) {
                  const info = this.getPlugin('MergeCells').mergedCellsCollection.get(sel[0], sel[1])

                  if (info.row === sel[0] && info.col === sel[1] && info.row + info.rowspan - 1 === sel[2] && info.col + info.colspan - 1 === sel[3]) {
                    return '取消合并房间'
                  }
                }

                return '合并房间'
              },
              disabled: () => {},
              hidden: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[0] < 2 || select[1] < 2 || select[2] < 2 || select[3] < 2) {
                  return true
                } else {
                  return false
                }
              }
            },
            hsep1: '---------', // 分隔器
            'row_above': {
              name: '之前插入新楼层',
              hidden: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[0] < 2 || select[2] < 2) { // 判断行
                  return true
                } else {
                  return false
                }
              },
              callback: function callback(key, normalizedSelection) {
                const latestSelection = normalizedSelection[Math.max(normalizedSelection.length - 1, 0)]
                this.alter('insert_row', latestSelection.start.row, 1, 'ContextMenu.rowAbove')
                const list = this.getSourceData().map((item, index) => {
                  if (index === latestSelection.start.row) {
                    return item.map((item2, index2) => {
                      if (index2 === 0) {
                        item2 = new BuildingFloor('新楼层')
                      }
                      if (index2 > 1) {
                        item2 = new BuildingRoom('')
                      }
                      return item2
                    })
                  } else {
                    return item
                  }
                })

                this.loadData(list)
                this.getPlugin('MergeCells').merge(latestSelection.start.row, 0, latestSelection.start.row, 1)
              }
            },
            'row_below': {
              name: '之后插入新楼层',
              hidden: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[0] < 2 || select[2] < 2) { // 判断行
                  return true
                } else {
                  return false
                }
              },
              callback: function callback(key, normalizedSelection) {
                const latestSelection = normalizedSelection[Math.max(normalizedSelection.length - 1, 0)]
                this.alter('insert_row', latestSelection.end.row + 1, 1, 'ContextMenu.rowBelow')
                const list = this.getSourceData().map((item, index) => {
                  if (index === latestSelection.end.row + 1) {
                    return item.map((item2, index2) => {
                      if (index2 === 0) {
                        item2 = new BuildingFloor('新楼层')
                      }
                      if (index2 > 1) {
                        item2 = new BuildingRoom('')
                      }
                      return item2
                    })
                  } else {
                    return item
                  }
                })

                this.loadData(list)
                this.getPlugin('MergeCells').merge(latestSelection.end.row + 1, 0, latestSelection.end.row + 1, 1)
              }
            },
            'remove_row': {
              name: '删除整楼层',
              hidden: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[0] < 2 || select[2] < 2) { // 判断行
                  return true
                } else {
                  return false
                }
              }
            },
            hsep2: '---------', // 分隔器
            'col_left': {
              name: () => {
                const select = this.hotRef.getSelectedLast()
                // if (select[0] === 1 && select[2] === 1 && select[1] > 1 && select[3] > 1) { // 判断行
                //   return '之前插入新房号'
                // }
                // if (select[0] === 0 && select[2] === 0 && select[1] > 1 && select[3] > 1) { // 判断行
                //   return '之前插入新单元'
                // }

                return '之前插入列'
              },
              hidden: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[0] <= 1 && select[1] > 1) { // 判断行
                  return false
                } else {
                  return true
                }
              },
              callback: function callback(key, normalizedSelection) {
                const latestSelection = normalizedSelection[Math.max(normalizedSelection.length - 1, 0)]
                this.alter('insert_col', latestSelection.start.col, 1, 'ContextMenu.columnLeft')
                const list = this.getSourceData().map((item, index) => {
                  return item.map((item2, index2) => {
                    if (index2 === latestSelection.start.col && index === 0) {
                      return new BuildingUnit('新单元')
                    }
                    if (index2 === latestSelection.start.col && index === 1) {
                      return new BuildingDoor('新房号')
                    }
                    if (index2 === latestSelection.start.col) {
                      return new BuildingRoom('新房间')
                    }
                    return item2
                  })
                })

                this.loadData(list)
              }
            },
            'col_right': {
              name: () => {
                const select = this.hotRef.getSelectedLast()
                // if (select[0] === 1 && select[2] === 1 && select[1] > 1 && select[3] > 1) { // 判断行
                //   return '之后插入新房号'
                // }
                // if (select[0] === 0 && select[2] === 0 && select[1] > 1 && select[3] > 1) { // 判断行
                //   return '之后插入新单元'
                // }

                return '之后插入列'
              },
              hidden: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[0] <= 1 && select[1] > 1) { // 判断行
                  return false
                } else {
                  return true
                }
              },
              callback: function callback(key, normalizedSelection) {
                const latestSelection = normalizedSelection[Math.max(normalizedSelection.length - 1, 0)]
                this.alter('insert_col', latestSelection.end.col + 1, 1, 'ContextMenu.columnRight')
                const list = this.getSourceData().map((item, index) => {
                  return item.map((item2, index2) => {
                    if (index2 === latestSelection.end.col + 1 && index === 0) {
                      return new BuildingUnit('新单元')
                    }
                    if (index2 === latestSelection.end.col + 1 && index === 1) {
                      return new BuildingDoor('新房号')
                    }
                    if (index2 === latestSelection.end.col + 1) {
                      return new BuildingRoom('新房间')
                    }
                    return item2
                  })
                })

                this.loadData(list)
              }
            },
            'remove_col': {
              name: () => {
                // const select = this.hotRef.getSelectedLast()
                // if (select[0] === 1 && select[1] > 1 && select[3] > 1) { // 判断行
                //   return '删除整房号'
                // }
                // if (select[0] === 0 && select[1] > 1 && select[3] > 1) { // 判断行
                //   return '删除整单元'
                // }
                return '删除整列'
              },
              hidden: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[0] <= 1 && select[1] > 1 && select[3] > 1) { // 判断行
                  return false
                } else {
                  return true
                }
              }
            },
            hsep4: '---------', // 分隔器
            'removeCell': {
              name: '删除房间',
              disabled: () => {},
              hidden: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[0] < 2 || select[1] < 2) {
                  return true
                } else {
                  return false
                }
              },
              callback: function(key, selection, clickEvent) {
                const offset = selection[0]
                const list = this.getSourceData().map((item, index) => {
                  if (index >= offset.start.row && index <= offset.end.row) {
                    return item.map((item2, index2) => {
                      if (index2 >= offset.start.col && index2 <= offset.end.col && index2 > 1) {
                        item2.deleteFlag = 1
                        return item2
                      } else {
                        return item2
                      }
                    })
                  } else {
                    return item
                  }
                })
                this.loadData(list)
              }
            },
            'unRemoveCell': {
              name: '取消删除房间',
              disabled: () => {},
              hidden: () => {
                const select = this.hotRef.getSelectedLast()
                if (select[0] < 2 || select[1] < 2) {
                  return true
                } else {
                  return false
                }
              },
              callback: function(key, selection, clickEvent) {
                const offset = selection[0]
                const list = this.getSourceData().map((item, index) => {
                  if (index >= offset.start.row && index <= offset.end.row) {
                    return item.map((item2, index2) => {
                      if (index2 >= offset.start.col && index2 <= offset.end.col && index2 > 1) {
                        item2.deleteFlag = 0
                        return item2
                      } else {
                        return item2
                      }
                    })
                  } else {
                    return item
                  }
                })
                this.loadData(list)
              }
            }
          }
        },
        // before 不能修改数据  after 也不能修改数据，
        afterCreateRow: function(startIndex, amount, source) {
        },
        afterRemoveRow: function(index, amount, physicalRows, source) {
          _this.resultData = [].concat(this.getSourceData())
          console.log(_this.resultData)
        },
        afterRemoveCol: function(index, amount, physicalColumns, source) {
          _this.resultData = [].concat(this.getSourceData())
          console.log(_this.resultData)
        },
        beforeKeyDown: function(e) {
          console.log(e)
          // BACKSPACE or DELETE
          if (e.keyCode === 8 || e.keyCode === 46) {
            e.stopImmediatePropagation()
            // remove data at cell, shift up
            e.preventDefault()
          }
        }

      }
    }
  },
  watch: {
    busBuildingRoom() {
      // this.initData()
    },
    visible(val) {
      if (val && this.listData.length === 0) {
        this.$nextTick(() => {
          if (this.busBuildingRoom.length) {
            this.initData()
          } else {
            this.init(this.floor, this.unit, this.door)
          }
        })
      }
    },
    floor() {
      // this.init(this.floor, this.unit, this.door)
    }
  },
  created() {
    if (this.visible && this.listData.length === 0) {
      this.$nextTick(() => {
        if (this.busBuildingRoom.length) {
          this.initData()
        } else {
          this.init(this.floor, this.unit, this.door)
        }
      })
    }
  },
  mounted() {
    this.hotRef = this.$refs.hotRef.hotInstance
  },
  methods: {
    initData() {
      const busBuildingFloor = [null, null].concat(this.busBuildingFloor)
      const list = []
      let merges = []
      for (let i = 0; i < busBuildingFloor.length; i++) {
        let child = []
        const merge = []
        if (i === 0) {
          child.push('楼层')
          child.push('单元')
          merge.push({
            row: 0,
            col: 0,
            rowspan: 2,
            colspan: 1
          })
        }
        if (i === 1) {
          child.push(null)
          child.push('房号')
        }
        if (i > 1) {
          child.push(busBuildingFloor[i])
          child.push(null)
          merge.push({
            row: i,
            col: 0,
            rowspan: 1,
            colspan: 2
          })
        }

        let col_index = 2 // 列的开始索引
        for (let j = 0; j < this.busBuildingUnit.length; j++) {
          const doorList = this.busBuildingDoor.filter(item => item.busBuildingUnitId === this.busBuildingUnit[j].busBuildingUnitId)
          if (i === 0) {
            // 此处筛选的是房号 某个单元下的房号
            child.push(this.busBuildingUnit[j])
            if (doorList.length > 1) {
              child = child.concat(this.emptylist(doorList.length - 1)) // 多余位置置为空
            }
            merge.push({
              row: i,
              col: col_index,
              rowspan: 1,
              colspan: doorList.length
            })
          }
          if (i === 1) {
            child = child.concat(doorList) // 连接房号
          }
          if (i > 1) {
            const list = deepClone(this.busBuildingRoom)
            const roomList = list.filter(item => {
              return item.busBuildingFloorId === busBuildingFloor[i].busBuildingFloorId &&
                item.busBuildingUnitId === this.busBuildingUnit[j].busBuildingUnitId
            })
            doorList.forEach((itemDoor, index) => {
              if (roomList.some(item => item && item.roomRealSort === itemDoor.doorRealSort)) {
                //
              } else {
                roomList.splice(index, 0, new BuildingRoom(''))
              }
            })

            let room_col_index = col_index
            roomList.forEach((item, index) => {
              item.busHouseTypeId = item.busHouseTypeName // 利用指针修改对象属性
              item.deleteFlag = 0 // 返回的数据 没有这个字段
              if (item && item.busBuildingRoomId) {
                if (item.roomMergeFlag) {
                  merge.push({
                    row: i,
                    col: room_col_index,
                    rowspan: item.roomMergeFloor,
                    colspan: item.roomMergeDoor
                  })
                  room_col_index += item.roomMergeDoor
                } else {
                  room_col_index++
                }
              }
            })
            child = child.concat(roomList) // 连接房间
          }
          col_index += doorList.length
        }
        //
        list.push(child)
        merges = merges.concat(merge)
      }
      this.listData = list
      const colWidths = [40, 40]
      this.busBuildingDoor.forEach(item => {
        colWidths.push(160)
      })

      this.hotRef.loadData(list)
      this.hotRef.updateSettings({ mergeCells: merges, colWidths })
    },
    emptylist(length) {
      const list = []
      for (let i = 0; i < length; i++) {
        list.push(null)
      }
      return list
    },
    changeData() {
      // 序列化数据
      const list = this.resultData.map((item, index) => {
        if (index > 1) {
          return item.map((item2, index2) => {
            if (item2 && index2 > 1) {
              if (item2.roomName === '') return item2
              item2.roomName = ''
              if (item[0] && item[0].floorName) {
                item2.roomName += item[0].floorName
              }
              if (this.resultData[1][index2] && this.resultData[1][index2].doorName) {
                item2.roomName += this.resultData[1][index2].doorName
              }
              return item2
            } else {
              return item2
            }
          })
        } else {
          return item
        }
      })
      this.hotRef.loadData(list)
    },
    init(floor = 1, unit = 1, door = 1) {
      this.floor_num = floor
      this.unit_num = unit
      this.door_num = door
      /*
        * floor_num 楼层
        * unit_num 单元
        * door_num 房号
         */
      const list = []
      let merges = []
      for (let i = 0; i < this.floor_num + 2; i++) {
        const child = []
        const merge = []
        if (i === 0) {
          child.push('楼层')
          child.push('单元')
          merge.push({
            row: 0,
            col: 0,
            rowspan: 2,
            colspan: 1
          })
        }
        if (i === 1) {
          child.push(null)
          child.push('房号')
        }
        if (i > 1) {
          child.push(new BuildingFloor(this.floor_num - i + 2))
          child.push(null)
          merge.push({
            row: i,
            col: 0,
            rowspan: 1,
            colspan: 2
          })
        }

        let col_index = 2 // 列的开始索引
        for (let j = 0; j < this.unit_num; j++) {
          if (i === 0) {
            // 此处筛选的是房号 某个单元下的房号
            child.push(new BuildingUnit(j + 1))
            merge.push({
              row: i,
              col: col_index,
              rowspan: 1,
              colspan: this.door_num
            })
            for (let i = 0; i < this.door_num - 1; i++) {
              child.push(new BuildingUnit('请输入单元'))
            }
          }
          if (i === 1) {
            for (let i = 0; i < this.door_num; i++) {
              child.push(new BuildingDoor(((i + 1) / 100).toFixed(2).substr(2, 2)))
            }
          }
          if (i > 1) {
            for (let n = 0; n < this.door_num; n++) {
              child.push(new BuildingRoom((this.floor_num - i + 2) + ((n + 1) / 100).toFixed(2).substr(2, 2)))
            }
          }
          col_index += this.door_num
        }
        //
        list.push(child)
        merges = merges.concat(merge)
      }
      this.listData = list
      const colWidths = [40, 40]
      for (let i = 0; i < this.door_num * this.unit_num; i++) {
        colWidths.push(160)
      }
      this.hotRef.loadData(list)
      this.hotRef.updateSettings({ mergeCells: merges, colWidths })
    },
    submit() {
      const list = this.hotRef.getSourceData()
      // 先计算出房号 跟单元的关系
      const buildingFloorList = [] // 楼层信息
      const busBuildingUnitList = [] // 单元信息
      const busBuildingDoorList = [] // 房号信息
      const buildingRoomList = [] // 房间信息
      const unitList = [] // 临时单元列表
      list[0].forEach((item2, index2) => {
        if (index2 < 2) return false
        if (unitList.length === 0) {
          const child = deepClone(item2)
          child.unitRealSort = busBuildingUnitList.length + 1
          child.sort = 1
          unitList.push(child)
          busBuildingUnitList.push(child)
          return false
        }
        if (this.hotRef.getCell(0, index2) === this.hotRef.getCell(0, index2 - 1)) { // 判断是否相等
          const child = deepClone(unitList[unitList.length - 1])
          child.sort = unitList[unitList.length - 1].sort + 1
          unitList.push(child)
          return false
        }
        const child = deepClone(item2)
        child.unitRealSort = busBuildingUnitList.length + 1
        child.sort = 1
        unitList.push(child)
        busBuildingUnitList.push(child)
      })
      list[1].forEach((item2, index2) => {
        if (index2 < 2) return false
        const doorChild = deepClone(item2)
        doorChild.unitRealSort = unitList[index2 - 2].unitRealSort
        doorChild.doorRealSort = unitList[index2 - 2].sort
        busBuildingDoorList.push(doorChild)
      })
      list.forEach((item, index) => {
        if (index < 2) {
          return false
        }
        // 处理楼层
        const floorChild = deepClone(item[0])
        floorChild.floorRealSort = list.length - index
        buildingFloorList.push(floorChild)
        item.forEach((item2, index2) => {
          if (index2 < 2 || item2.deleteFlag === 1 || item2.roomName === '') return false
          const roomChild = deepClone(item2)
          roomChild.floorRealSort = buildingFloorList[index - 2].floorRealSort
          roomChild.unitRealSort = unitList[index2 - 2].unitRealSort
          roomChild.roomRealSort = busBuildingDoorList[index2 - 2].doorRealSort
          buildingRoomList.push(roomChild)
        })
      })
      console.log('buildingFloorList', buildingFloorList)
      console.log('busBuildingUnitList', busBuildingUnitList)
      console.log('busBuildingDoorList', busBuildingDoorList)
      console.log('buildingRoomList', buildingRoomList)
    }
  }
}
</script>

<style lang="scss" >

</style>
