// 页面跳转方法
import router from '@/router'

// 是否是http 全路径 否则的话直接用router跳转
// 若果是https 全路径，判断是否平台域名，如果是，截取之后使用router跳转
// 否则判断是否https https 的话 三方应用内嵌，否则开新页面
export function openUrl(url, query) {
  const isHttp = /^(http)|^(https)/.test(url)
  // const host = window.location.host
  let host = location.host
  if (process.env.NODE_ENV === 'development') {
    host = 'youmatech.com'
  }
  if (isHttp) {
    if (url.includes('yky.youmatech.com')) {
      // 网站内部地址
      const path = url.split(host)[1]
      if (path.includes('/application')) {
        router.push({ path }) // 不要参数，避免错误
      } else {
        router.push({ path, query })
      }
    } else {
      if (/^(https:)/.test(url)) {
        router.push({
          path: '/dashboard/web',
          query: {
            url
          }
        })
      }
      if (/^(http:)/.test(url)) {
        window.open(url)
      }
    }
  } else {
    router.push({ path: url, query })
  }
}

export function jumpPath({ uuid, dataType }) {
  console.log('跳转----')
  console.log(uuid, dataType)
  let tabType = 2
  switch (dataType) {
    case 10239001:
      tabType = 2
      break
    case 10239002:
      tabType = 4
      break
    case 10239003:
      tabType = 5
      break
    case 10239004:
      tabType = 3
      break
    default:
      tabType = 2
  }
  router.push({ path: '/cloudMarket/info', query: { uuid, tabType, dataType }})
}
