<template>
  <!-- 应用中心 -->
  <div class="g-cloudMarketBox">
    <div class="cloudMarket_leftBox">
      <div ref="searchBox" class="cloudMarket_leftBox_searchBox">
        <el-input v-model.trim="keyWord" maxlength="50" placeholder="搜索应用" @focus="flag = true" @keyup.enter.native="getSearch()">
          <i slot="suffix" class="gp-input__icon el-icon-search pointer" @click="getSearch()" />
        </el-input>
        <div v-if="flag ===true" class="cloudMarket_leftBox_searchBox_searchRecords">
          <search :search-type="20039905" :search-list="applicationSearchList" :tab-type="2" @words="words" />
        </div>
      </div>
      <div class="cloudMarket_leftBox_menuss">
        <el-menu
          :default-active="activeitem"
          class="gp-menu-vertical-demo"
          @select="handleSelect"
        >
          <div v-for="(item,i) in classificationList" :key="i">
            <el-menu-item :index="''+i" class="cloudMarket_leftBox_menuss_comoncolor">
              <span slot="title">
                <UmToolTip :content="item.classifyName" />
              </span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
    </div>
    <div class="cloudMarket_rightBox">
      <banner :banner-list="bannerList" />
      <div v-if="menuId==='0'" class="cardBox " :class="{copycontentbox:!applicationList.length}">
        <div class="cardBox_title f-mg-b-26">精选应用</div>
        <el-row v-if="applicationList.length>0">
          <el-col v-for="(item,i) in applicationList" :key="i" :span="8">
            <application :type="2" :form="item" :tab-type="2" />
          </el-col>
        </el-row>
        <no-data v-else />
      </div>
      <div v-if="menuId==='0'" style="display: flex" class="cardBox">
        <div style="width: 0;flex:1;" :class="{copycardBox:!freeApplicationList.length}">
          <div class="cardBox_title">免费应用排行</div>
          <el-row v-if="freeApplicationList.length>0" style="display: flex;flex-direction: column">
            <el-col v-for="(item,i) in freeApplicationList" :key="i" :span="24">
              <application-new :type="2" :index="i" :form="item" :tab-type="2" />
            </el-col>
          </el-row>
          <no-data v-else />
        </div>
        <div style="width: 0;flex:1;margin-left: 20px;" :class="{copycardBox:!paidAppsList.length}">
          <div class="cardBox_title">付费应用排行</div>
          <el-row v-if="paidAppsList.length>0" style="display: flex;flex-direction: column">
            <el-col v-for="(item,i) in paidAppsList" :key="i" :span="24">
              <application-new :type="2" :form="item" :index="i" :tab-type="2" />
            </el-col>
          </el-row>
          <no-data v-else />
        </div>
      </div>
      <div v-else class="cardBox_paging cardBox cardBox-minH">
        <el-row v-if="otherApplicationsList.length>0" class="f-mg-t-46">
          <el-col v-for="(item,i) in otherApplicationsList" :key="i" :span="8">
            <application :type="2" :form="item" :tab-type="2" />
          </el-col>
        </el-row>
        <no-data v-else class="cardBox_nodatas" />
        <div v-if="total>0">
          <div class="cloudMarket_rightBox_pages">
            <pagination
              :total="total"
              :page.sync="page.pageNum"
              :page-sizes="[30, 60, 90, 120]"
              :limit.sync="page.pageSize"
              @pagination="getApplicationClassification('page')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import applicationNew from '../components/applicationNew'
import application from '../components/application.vue'
import search from '../components/search.vue'
import banner from '../components/banner.vue'
import noData from '../components/noData.vue'
import { getBanner, getApplicationCenter, getApplicationMenu, getApplicationClassification } from '@/api/cloudMarket'
export default {
  name: 'CloudMarketApplicationCenter',
  components: { search, application, banner, noData, applicationNew },
  data() {
    return {
      activeitem: '0', // 侧边栏默认
      flag: false,
      bannerList: [],
      applicationList: [],
      freeApplicationList: [],
      paidAppsList: [],
      classificationList: [],
      page: {
        pageSize: 30,
        pageNum: 1
      },
      total: 0,
      typeId: null,
      menuId: '0',
      otherApplicationsList: [],
      keyWord: '',
      applicationSearchList: [],
      hotList: []
    }
  },
  mounted() {
    this.getBanner()
    this.getApplication(1)
    this.getApplication(2)
    this.getApplication(3)
    this.getApplicationMenu()
    const key = 'applicationSearch_' + this.$store.getters.enterpriseUuid
    try {
      const redult = JSON.parse(window.localStorage.getItem(key))
      if (redult && Array.isArray(redult)) {
        this.applicationSearchList = redult
      } else {
        this.applicationSearchList = []
      }
    } catch {
      this.applicationSearchList = []
    }
  },
  created() {
    document.body.addEventListener('click', this.bodyClickhandler)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.bodyClickhandler)
  },
  methods: {
    // 点击搜索框关闭搜索框
    bodyClickhandler(event) {
      const _target = event.target
      if (this.$refs.searchBox) {
        if (this.$refs.searchBox.contains(_target)) {
          //
        } else {
          this.flag = false
        }
      }
    },
    words(val, hotList) {
      this.keyWord = val
      this.hotList = hotList
      this.getSearch()
    },
    getBanner() {
      getBanner({ bannerNo: '200912002-P' }).then(res => {
        this.bannerList = res.data
      })
    },
    // 获取精选应用
    getApplication(id) {
      const load = this.$load()
      getApplicationCenter({ type: id }).then(res => {
        load.close()
        if (id === 1) {
          this.applicationList = res.data.list
        } else if (id === 2) {
          this.freeApplicationList = res.data.list
        } else if (id === 3) {
          this.paidAppsList = res.data.list
        }
      }).catch(e => {
        load.close()
      })
    },
    // 获取应用菜单分类
    getApplicationMenu() {
      getApplicationMenu({ classifyCode: 2003010101 }).then(res => {
        this.classificationList = res.data.list
        this.classificationList.unshift({
          classifyUuid: null,
          classifyName: '精选应用'
        })
      })
    },
    handleSelect(val) {
      this.menuId = val
      if (val === '0') {
        this.getApplication(1)
        this.getApplication(2)
        this.getApplication(3)
      } else {
        this.typeId = this.classificationList[val].classifyUuid

        this.getApplicationClassification()
      }
    },
    // 获取其他菜单应用
    getApplicationClassification(state = '') {
      if (state !== 'page') {
        this.page = {
          pageSize: 30,
          pageNum: 1
        }
      }
      const params = {
        classifyUuid: this.typeId,
        page: {
          pageSize: this.page.pageSize,
          pageNum: this.page.pageNum
        }
      }
      const load = this.$load()
      getApplicationClassification(params).then(res => {
        this.otherApplicationsList = res.data.list
        this.page = res.data.page
        this.total = res.data.page.total
        load.close()
      }).catch(e => {
        load.close()
      })
    },

    getSearch() {
      if (this.keyWord) {
        // 存储
        const key = 'applicationSearch_' + this.$store.getters.enterpriseUuid
        this.applicationSearchList.unshift(this.keyWord)
        this.applicationSearchList = [...new Set(this.applicationSearchList)].splice(0, 5)
        window.localStorage.setItem(key, JSON.stringify(this.applicationSearchList))
        this.$router.push({ path: '/cloudMarket/search', query: { keyWord: this.keyWord, hotList: this.hotList, tabType: 2 }})
        this.keyWord = ''
      } else {
        this.$message.error('请输入搜索关键词')
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
