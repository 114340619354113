import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'

const courseRouter = {
  path: '/course',
  component: Layout,
  meta: { title: '企业管理', icon: 'enterprise', iconActive: 'enterprise-active', level: 2, roles: ['11203'] },
  // redirect: '/enterprise/home',
  redirect: to => {
    for (let i = 0; i < courseRouter.children.length; i++) {
      const { path, meta } = courseRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  children: [
    // 课程
    {
      name: 'EnterpriseApplicationCourse',
      path: '/course',
      component: () => import('@/views/course'),
      meta: { title: '课程列表', group: 'enterpriseApp', level: 2, roles: ['1120303'], activeMenu: '/enterprise/application' }
    },
    {
      name: 'EnterpriseApplicationCourseAdd',
      path: '/course/add',
      component: () => import('@/views/course/add'),
      meta: { title: '课程新增', group: 'enterpriseApp', level: 3, roles: ['1120303'], activeMenu: '/enterprise/application' }
    },
    {
      name: 'EnterpriseApplicationCourseUpdate',
      path: '/course/update',
      component: () => import('@/views/course/update'),
      meta: { title: '课程编辑', group: 'enterpriseApp', level: 3, roles: ['1120303'], activeMenu: '/enterprise/application' }
    },
    {
      name: 'EnterpriseApplicationCourseInfo',
      path: '/course/info',
      component: () => import('@/views/course/info'),
      meta: { title: '课程详情', group: 'enterpriseApp', level: 3, roles: ['1120303'], activeMenu: '/enterprise/application' }
    }

  ]
}
export default courseRouter
