import request from '@/utils/request'

// 获取楼栋列表
export function getBuildingList(data) {
  return request({
    url: '/api/v1/data/p/building/query_building_page',
    method: 'post',
    data
  })
}

// 新增楼栋
export function addBuilding(data) {
  return request({
    url: '/api/v1/data/p/building/add_building',
    method: 'post',
    data
  })
}

// 编辑楼栋
export function editBuilding(data) {
  return request({
    url: '/api/v1/data/p/building/update_building',
    method: 'post',
    data
  })
}

//   删除楼栋
export function delBuilding(params) {
  return request({
    url: '/api/v1/data/p/building/delete_building',
    method: 'get',
    params
  })
}
