<template>
  <div class="login-container">
    <!--    <div class="login-head">-->
    <!--      <div class="logo-con" @click="$router.push('/')">-->
    <!--        <img src="@/assets/afterDesk/head/logo.png" alt="">-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="login-main">
      <div v-if="3>9" class="copyright">
        <div>Copyright © {{ location.origin || 'www.yuanzhizhiqi.com' }}, All Rights Reserved.</div>
        <div>助你快速打造开放性服务应用</div>
      </div>
      <div v-if="3>9" class="main-content">
        <div class="right">
          <div class="right-wrap">
            <div class="loginleft">
              <div class="tab">
                <div class="manage">优客云平台</div>
              </div>
              <div class="leftimg" />
            </div>
            <div class="loginline" />
            <div
              class="loginright"
              :class="{loginright1:!['login-tel', 'login-paw', 'login-code'].includes(status)}"
            >
              <template v-if="['login-tel', 'login-paw', 'login-code'].includes(status)">
                <div class="toptxt"><span>{{ status == 'login-code'?'账号登录':'扫码登录' }}</span></div>
                <div class="logintop">
                  <div
                    class="topbgc"
                    :class="{'topbgc1': status =='login-code'}"
                    @click="changeStatus"
                  />
                </div>
              </template>
              <!--              切换手机号和密码登录tab-->
              <div v-if="['login-tel', 'login-paw'].includes(status)" class="logintab">
                <div
                  v-for="(item,index) in LOGIN_CODE "
                  :key="index"
                  :class="{'on':status==item.value}"
                  @click="status=item.value"
                >
                  {{ item.name }}
                </div>
              </div>
              <!--              <transition-->
              <!--                name="gp-fade-in-linear"-->
              <!--                mode="out-in"-->
              <!--              >-->
              <loginPasw
                v-if="status === 'login-paw'"
                :public-key="publicKey"
                :msgcode="msgcode"
                @register="status = 'register'"
                @choseEnterprise="status = 'choseEnterprise'"
                @perfectInfo="perfectInfoHandler"
              />
              <loginTel
                v-if="status === 'login-tel'"
                :public-key="publicKey"
                :msgcode="msgcode"
                @perfectInfo="perfectInfoHandler"
                @choseEnterprise="status = 'choseEnterprise'"
              />
              <Register
                v-if="status === 'register'"
                ref="register"
                :register-form="loginForm"
                :public-key="publicKey"
                :msgcode="msgcode"
                @login="status = 'login-paw'"
                @registerSuccess="registerSuccess"
              />
              <LoginQrCode v-if="status === 'login-code'" @choseEnterprise="status = 'choseEnterprise'" @perfectInfo="perfectInfoHandler" />
              <LoginChoseTeam v-if="status === 'choseEnterprise'" @login="status = 'login-paw'" />
              <!--              </transition>-->
            </div>
          </div>
        </div>
      </div>
      <div class="m_new_login">
        <div class="m_new_login_lpic">
          <div class="m-inner">
            <div class="m-text">集办公、营销、物业、工程于一体的业务协同平台，打造地产行业全链路、全生态解决方案</div>
            <div class="m-icon">
              <img src="~@/assets/login/icon.png" alt="" width="382">
            </div>

            <div class="u-umLogo" />
          </div>
        </div>
        <div class="m_new_login_rcontent">
          <div class="u_login_mian">
            <div
              class="loginright"
              :class="{loginright1:!['login-tel', 'login-paw', 'login-code'].includes(status)}"
            >
              <template v-if="['login-tel', 'login-paw', 'login-code'].includes(status)">
                <div class="toptxt"><span>{{ status == 'login-code'?'账号登录':'扫码登录' }}</span></div>
                <div class="logintop">
                  <div
                    class="topbgc"
                    :class="{'topbgc1': status =='login-code'}"
                    @click="changeStatus"
                  />
                </div>
              </template>
              <!--              切换手机号和密码登录tab-->
              <div v-if="['login-tel', 'login-paw'].includes(status)" class="logintab" :class="['login-tel', 'login-paw'].includes(status)?'u_login_top':''">
                <div
                  v-for="(item,index) in LOGIN_CODE "
                  :key="index"
                  :class="{'on':status==item.value}"
                  @click="status=item.value"
                >
                  {{ item.name }}
                </div>
              </div>
              <!--              <transition-->
              <!--                name="gp-fade-in-linear"-->
              <!--                mode="out-in"-->
              <!--              >-->
              <loginPasw
                v-if="status === 'login-paw'"
                :public-key="publicKey"
                :msgcode="msgcode"
                @changemsgcode="changemsgcode"
                @register="status = 'register'"
                @choseEnterprise="status = 'choseEnterprise'"
                @perfectInfo="perfectInfoHandler"
              />
              <loginTel
                v-if="status === 'login-tel'"
                :public-key="publicKey"
                :msgcode="msgcode"
                @changemsgcode="changemsgcode"
                @perfectInfo="perfectInfoHandler"
                @choseEnterprise="status = 'choseEnterprise'"
              />
              <Register
                v-if="status === 'register'"
                ref="register"
                :register-form="loginForm"
                :public-key="publicKey"
                :msgcode="msgcode"
                @login="status = 'login-paw'"
                @registerSuccess="registerSuccess"
              />
              <LoginQrCode v-if="status === 'login-code'" class="u_login_top" @choseEnterprise="status = 'choseEnterprise'" @perfectInfo="perfectInfoHandler" />
              <LoginChoseTeam v-if="status === 'choseEnterprise'" @login="status = 'login-paw'" />
              <!--              </transition>-->
            </div>
          </div>

          <div class="m_new_login_copy">
            <div>© 2022 北京优码科技有限公司版权所有</div>
            <div>
              <a href="http://beian.miit.gov.cn" target="_blank">
                京ICP备17015700号-1
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginPasw from './components/login-pasw' // 密码登录
import loginTel from './components/login-tel' // 验证码登录
import LoginQrCode from './components/login-qrCode' // 二维码登录
import LoginChoseTeam from './components/login-choseTeam' // 选择企业
import Register from './components/login-newRegister' // 注册
import { getInfo, getPublicKey } from '@/api/user'
import { getfingerid } from '@/utils'
const LOGIN_CODE = [
  { name: '验证码登录', value: 'login-tel' },
  { name: '密码登录', value: 'login-paw' }
]
export default {
  name: 'LoginDev',
  components: { loginPasw, loginTel, LoginQrCode, LoginChoseTeam, Register },
  data() {
    return {
      LOGIN_CODE,
      status: 'login-tel',
      location: window.location,
      loginForm: {},
      publicKey: '',
      msgcode: ''
    }
  },
  created() {
    const { status } = this.$route.params

    if (status == 'register') { // 则代表需要完善信息
      this.perfectInfoHandler()
    }
    if (status == 'choseEnterprise') { // 则代表需要选择企业
      this.status = 'choseEnterprise'
    }
    console.log(getfingerid())
    this.getfingerids()
    // this.getPublicKey()
  },
  methods: {
    changemsgcode(val) {
      this.msgcode = val
    },
    logins(val) {
      this.isregister = val
    },
    // 获取公钥
    getPublicKey() {
      getPublicKey({}).then(res => {
        this.publicKey = res.data
        this.msgcode = res.code
      }
      ).finally(d => {
      }).catch(e => {
        if (e.code == 3020) {
          this.publicKey = e.data
        }
        this.msgcode = e.code
      })
    },
    getfingerids() {
      let deviceId = ''
      getfingerid().then(d => {
        deviceId = d
        this.$store.commit('user/SET_DEVICEID', deviceId)
      }).finally(_ => {
        this.getPublicKey()
      })
    },
    // 注册成功
    registerHandler() {
      this.isregister = false
      this.choseteam = true
    },
    changeStatus() {
      this.status = (this.status == 'login-tel' || this.status == 'login-paw') ? 'login-code' : 'login-tel'
    },
    async perfectInfoHandler(loginForm) {
      this.loginForm = loginForm || {}
      this.status = 'register'
      const res = await getInfo()
      this.$nextTick(_ => {
        setTimeout(_ => {
          if (res.data.password == null || res.data.password.length == 0) {
            this.$refs.register.step = 2
          } else {
            this.$refs.register.step = 3
          }
        })
      })
    },
    registerSuccess() {
      this.status = 'choseEnterprise'
    }

  }
}
</script>

<style scoped lang="scss">
.login-container {
  width: 100% ;
  height: 100%;
  .login-head {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    .logo-con {
      cursor: pointer;
      margin-left: 126px;
      font-size: 18px;
      color: #fff;
      width: 206px;
      height: 29px;
      > img {
        width: 100%;
        height: 100%;
        user-select: none;
      }
    }
  }
  .login-main {
    width: 100%;
    height: 100%;
    min-width: 1080px;
    min-height: 800px;
    position: relative;
    //background: url("~@/assets/login/login-bg1.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    .copyright{
      text-align: center;
      color: #31415F;
      font-size: 14px;
      //width: 476px;
      width: 100%;
      height: 48px;
      line-height: 24px;
      position: absolute;
      bottom: 40px;
      left: 0;
      //left: 50%;
      //margin-left: -238px;
    }
    .main-content {
      width: 1300px;
      margin: 0 auto;
      .right {
        .right-wrap {
          display: flex;
          position: relative;
          margin: 0 auto;
          //width: 430px;
          //height: 440px;
          width: 1130px;
          height: 580px;
          background: #FFFFFF;
          box-shadow: 0px 6px 14px 0px rgba(49, 65, 95, 0.14);
          border-radius: 20px;
          //padding: 48px 45px 25px;
          .loginleft{
            width: 489px;
            height: 580px;
            margin-left: 80px;
            //background-color: skyblue;
            .leftimg{
              margin-top: 33px;
              width: 489px;
              height: 362px;
              //background-color: blue;
              background: url("~@/assets/login/leftpic.png") no-repeat 0 0 / 498px 362px;
            }
          }
          .loginline{
            margin: 115px 70px;
            width: 1px;
            height: 350px;
            background: #D8D8D8;
          }
          .loginright{
            margin: auto 0;
            width: 360px;
            height: 336px;
            //background-color: pink;
          }
          .loginright1{
            height: 412px;
          }
          .tab {
            margin: 33px 0 0 158px;
            width: 280px;
            display: flex;
            align-items: center;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(108, 123, 150, 0.91);
            line-height: 30px;
            letter-spacing: 1px;
            //margin-bottom: 40px;
            .manage {
              //font-size: 24px;
              //font-family: PingFangSC-Medium, PingFang SC;
              //font-weight: 500;
              //color: #3D80FC;
              //line-height: 28px;
              //letter-spacing: 1px;
              //text-align: center;
              //margin: 0 auto;
              width: 280px;
              height: 36px;
              font-size: 36px;
              text-align: center;
              font-family: HelloFont-WenYiHei, HelloFont;
              font-weight: normal;
              color: #3D80FC;
              line-height: 36px;
              letter-spacing: 2px;
            }
          }
          .logintop{
            cursor: pointer;
            position: absolute;
            top: -8px;
            right: -8px;
            .topbgc{
              display: inline-block;
              width: 78px;
              height: 78px;
              background:url("~@/assets/login/saomadenglu.png") no-repeat 0 0/78px 78px ;
            }
            .topbgc1{
              background:url("~@/assets/login/zhanghaodenglu.png") no-repeat 0 0/78px 78px ;
            }
          }
          .toptxt{
            position: absolute;
            top: 10px;
            right: 74px;
            display: inline-block;
            font-size: 14px;
            color:#65DDBC ;
            font-weight: 500;
            height: 22px;
            text-align: center;
            background: url("~@/assets/login/zhanghaodenglukuang.png") no-repeat center;
            background-size: 100% 100%;
            span{
              display: inline-block;
              margin: 3px 15px 4px 7px;
            }
          }
          .logintab{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 40px;
            div{
              cursor: pointer;
              color: #6C7B96;
              font-size: 22px;
            }
            div + div {
              margin-left: 40px;
            }
            .on{
              color: #31415F;
              font-weight: 500;
            }
          }
        }
      }
    }
    .m_new_login{
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      .m_new_login_lpic{
        position: relative;
        flex-shrink: 0;
        height: 100%;
        width: 500px;
        background-color: #b6cbff;
        background-image: url("~@/assets/login/login_leftpic3.png");
        background-position: 0 center;
        background-size: 520px 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .m-inner{
          padding-left: 70px;
          .m-text{
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 48px;
            margin-bottom: 100px;
            margin-right: 40px;
          }
          .m-icon{

          }
        }

        .u-umLogo{
          position: absolute;
          top:50px;
          left:32px;
          width: 200px;
          height:58px;
          background: url("~@/assets/login/umLogo2.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .m_new_login_rcontent{
        height: 100%;
        flex: 1;
        position: relative;
        .u_login_mian{
          position: absolute;
          left: 50%;
          top: 50%;
          width: 480px;
          height: 568px;
          margin-left: -240px;
          margin-top: -320px;
          background: #FFFFFF;
          box-shadow: 0px 8px 19px 0px rgba(49,65,95,0.14);
          border-radius: 27px;
        }

        .loginright{
          margin: 30px  auto 0;
          width: 360px;
          //width: 504px;
          height: 500px;
        }
        .loginright1{
          height: 412px;
        }
        .toptxt{
          position: absolute;
          top: 10px;
          right: 74px;
          display: inline-block;
          font-size: 14px;
          color:#65DDBC ;
          font-weight: 500;
          height: 22px;
          text-align: center;
          background: url("~@/assets/login/zhanghaodenglukuang.png") no-repeat center;
          background-size: 100% 100%;
          span{
            display: inline-block;
            margin: 3px 15px 4px 7px;
          }
        }
        .logintop{
          cursor: pointer;
          position: absolute;
          top: -8px;
          right: -8px;
          .topbgc{
            display: inline-block;
            width: 78px;
            height: 78px;
            background:url("~@/assets/login/saomadenglu.png") no-repeat 0 0/78px 78px ;
          }
          .topbgc1{
            background:url("~@/assets/login/zhanghaodenglu.png") no-repeat 0 0/78px 78px ;
          }
        }
        .logintab{
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0 40px;
          div{
            cursor: pointer;
            color: #6C7B96;
            font-size: 22px;
          }
          div + div {
            margin-left: 40px;
          }
          .on{
            color: #31415F;
            font-weight: 500;
          }
        }
        .u_login_top{
          margin-top: 150px;
        }
        .m_new_login_copy{
          position: absolute;
          bottom: 30px;
          left: 50%;
          margin-left: -230px;
          width: 460px;
          //height: 20px;
          font-size: 13px;
          font-weight: 400;
          color:  rgba(23, 26, 29, 0.6);
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
