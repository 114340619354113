<template>
  <div class="m-contractorAdd infoData min_h_100">
    <div class="go_back_common">
      <!--      <div class="go_back_title" @click="$router.push('/mainData/contractor/index')">-->
      <div class="go_back_title" @click="$router.replace('/mainData/contractor')">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item
          ref="contractorFullName"
          label="承建商全称"
          prop="contractorFullName"
        >
          <el-input
            v-model="ruleForm.contractorFullName"
            style
            clearable
            :maxlength="50"
            placeholder="请输入承建商全称"
          />
        </el-form-item>
        <el-form-item
          ref="contractorName"
          label="承建商简称"
          prop="contractorName"
        >
          <el-input
            v-model="ruleForm.contractorName"
            style
            clearable
            :maxlength="50"
            placeholder="请输入承建商简称"
          />
        </el-form-item>
        <el-form-item
          ref="contractorTypeCode"
          label="承建商类别"
          prop="contractorTypeCode"
        >
          <el-select v-model="ruleForm.contractorTypeCode" style="width:100%" placeholder="请选择承建商类别">
            <el-option v-for="(item,i) in contractorList" :key="i" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item
          ref="contractorUnifiedNo"
          label="社会信用统一代码"
          prop="contractorUnifiedNo"
          :rules="[
            {
              min: 0,
              max: 50,
              message: '长度在 0 到 50 个字符',
              trigger: 'blur',
              required: false
            }
          ]"
        >
          <el-input
            v-model="ruleForm.contractorUnifiedNo"
            maxlength="50"
            clearable
            placeholder="请输入社会信用统一代码"
          />
        </el-form-item>
        <el-form-item
          ref="contractorRegistrationNo"
          label="工商注册号"
          prop="contractorRegistrationNo"
        >
          <el-input
            v-model="ruleForm.contractorRegistrationNo"
            style
            maxlength="50"
            clearable
            placeholder="请输入工商注册号"
          />
        </el-form-item>
        <el-form-item
          ref="contractorResponsiblePerson"
          label="负责人"
          prop="contractorResponsiblePerson"
        >
          <el-input
            v-model="ruleForm.contractorResponsiblePerson"
            style
            maxlength="20"
            clearable
            placeholder="请输入负责人"
          />
        </el-form-item>
        <el-form-item ref="contractorContact" label="联系人" prop="contractorContact">
          <el-input
            v-model="ruleForm.contractorContact"
            style
            maxlength="20"
            clearable
            placeholder="请输入联系人"
          />
        </el-form-item>
        <el-form-item
          ref="contractorMobile"
          label="联系电话"
          prop="contractorMobile"
        >
          <el-input
            v-model="ruleForm.contractorMobile"
            style
            maxlength="20"
            clearable
            placeholder="请输入联系电话"
          />
        </el-form-item>
        <el-form-item ref="contractorAddress" label="承建商地址" prop="contractorAddress">
          <el-input
            v-model="ruleForm.contractorAddress"
            style
            maxlength="50"
            clearable
            placeholder="请输入承建商地址"
          />
        </el-form-item>
        <el-form-item ref="contractorRemark" label="备注" prop="contractorRemark">
          <el-input
            v-model="ruleForm.contractorRemark"
            type="textarea"
            style
            maxlength="500"
            :rows="6"
            show-word-limit
            placeholder="请输入备注"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            确认保存
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { contractor_add } from '@/api/mainData/contractor'
export default {
  name: 'MainDataContractorAdd',
  components: {},
  props: {},
  data() {
    return {
      ruleForm: {
        contractorFullName: '',
        contractorName: '',
        contractorUnifiedNo: '',
        contractorRegistrationNo: '',
        contractorResponsiblePerson: '',
        contractorContact: '',
        contractorMobile: '',
        contractorAddress: '',
        contractorRemark: '',
        contractorTypeCode: ''
      },
      rules: {
        contractorFullName: [
          { required: true, message: '请输入承建商全称', trigger: 'blur' }
        ],
        contractorName: [
          { required: true, message: '请输入承建商简称', trigger: 'blur' }
        ],
        contractorTypeCode: [
          { required: true, message: '请选择承建商类别', trigger: 'change' }
        ]
      },
      contractorList: [
        { value: '1012501', label: '维保单位' },
        { value: '1012502', label: '第三方维保单位' }
      ]
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const load = this.$load()
          contractor_add({
            ...this.ruleForm
            // contractorTypeCode: '20252010'
          })
            .then((d) => {
              load.close()
              this.$alert('添加成功！', '提示', {
                type: 'success',
                showClose: false
              }).then((d) => {
                // this.$router.push('/mainData/contractor/index')
                this.$router.replace('/mainData/contractor')
              })
            })
            .catch((e) => {
              this.$errorHandle(e)
              load.close()
            })
        } else {
          this.$message.error('请填写正确的表单！')
          return false
        }
      })
    }
  }
}
</script>

