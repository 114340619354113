<template>
  <div class="tags-view-container" :class="{pr90: showMarket}">
    <scroll-pane ref="scrollPane" class="tags-view-wrapper" @scroll="handleScroll">
      <router-link
        v-for="(tag, i) in visitedViews"
        ref="tag"
        :key="tag.path"
        :class="isActive(tag)?'active':''"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        tag="span"
        class="tags-view-item"
        @click.middle.native="!isAffix(tag)?closeSelectedTag(tag):''"
        @contextmenu.prevent.native="openMenu(tag,$event)"
      >
        <span class="prev" />
        <span class="next" />
        <div class="tag-main">
          <div class="icon">
            <um-image :src="'/assets/icon/' + tag.group + '.png'" />
            <!--            <img :src="'/assets/icon/' + tag.group + '.png'" alt="">-->
          </div>
          <span class="row_1">{{ tag.title || '-' }}</span>
        </div>
        <span v-if="!isAffix(tag)" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" />
      </router-link>
    </scroll-pane>
    <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
      <!--      <li @click="refreshSelectedTag(selectedTag)">刷新</li>-->
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">关闭</li>
      <li @click="closeOthersTags">关闭其他</li>
      <li @click="closeAllTags(selectedTag)">关闭所有</li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from './ScrollPane'
import path from 'path'
import { unmountApp } from '@micro-zoe/micro-app'

export default {
  components: { ScrollPane },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
      list: new Array(20).fill({})
    }
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
    routes() {
      return this.$store.state.permission.routes
    },
    showMarket() {
      return this.$route.path === '/dashboard/index'
    }
  },
  watch: {
    $route() {
      this.addTags()
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    // this.initTags()
    this.addTags()
  },
  methods: {
    isActive(route) {
      return route.path === this.$route.path
    },
    isAffix(tag) {
      return tag.meta && tag.meta.affix
    },
    filterAffixTags(routes, basePath = '/') {
      let tags = []
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.resolve(basePath, route.path)
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: { ...route.meta }
          })
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path)
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags]
          }
        }
      })
      return tags
    },
    initTags() {
      const affixTags = this.affixTags = this.filterAffixTags(this.routes)
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch('tagsView/addVisitedView', tag)
        }
      }
    },
    addTags() {
      const { name, path } = this.$route
      if (name) {
        this.$store.dispatch('tagsView/addView', this.$route)
      }
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag)
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('tagsView/updateVisitedView', this.$route)
            }
            break
          }
        }
      })
    },
    refreshSelectedTag(view) {
      this.$store.dispatch('tagsView/delCachedView', view).then(() => {
        const { fullPath } = view
        this.$nextTick(() => {
          this.$router.replace({
            path: '/redirect' + fullPath
          })
        })
      })
    },
    closeSelectedTag(view) {
      this.$store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view)
        }
        setTimeout(_ => {
          // 当移除标签的时候，删除微前端
          unmountApp(view.name, { destroy: true, clearAliveState: true }).then(() => {
            console.log(`卸载子应用${view.name}成功`)
            localStorage.removeItem('busOrganObj')
          }).catch(e => {
            console.log('有报错', e)
          })
        }, 0)
      })
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag)
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    closeAllTags(view) {
      this.$store.dispatch('tagsView/delAllViews').then(({ visitedViews }) => {
        if (this.affixTags.some(tag => tag.path === view.path)) {
          return
        }
        this.toLastView(visitedViews, view)
      })
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'MessageIndex') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    openMenu(tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 15 // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }

      this.top = e.clientY
      this.visible = true
      this.selectedTag = tag
    },
    closeMenu() {
      this.visible = false
    },
    handleScroll() {
      this.closeMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
.tags-view-container {
  height: 50px;
  width: 100%;
  //background: #3D80FC;
  background:rgba(225, 228, 236, 1) ;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.5);
  &.pr90 {
    padding-right: 90px;
  }
  .tags-view-wrapper {
    .tags-view-item {
      display: inline-block;
      position: relative;
      z-index: 1;
      cursor: pointer;
      height: 44px;
      width: 180px;
      border-radius: 12px 12px 0 0;
      //background: rgba(232, 240, 255, 0.5);
      padding: 14px 24px 14px 12px;
      font-size: 16px;
      font-weight: 500;
      color: #31415F;
      line-height: 16px;
      //margin-left: 5px;
      margin-top: 6px;
      //transition: all 0.3s;
      &:first-of-type {
        margin-left: 20px;
      }
      &:last-of-type {
        margin-right: 20px;
      }
      &:hover {
        //border-radius: 12px;
        background: rgba(255, 255, 255, 0.4);
        //z-index:4;
        +.tags-view-item{
          .prev{
            //display: none;
          }
        }
        .prev,.next{
          opacity: 0.4;
        }
      }
      .prev,.next{
        position:absolute;
        width: 12px;
        height: 12px;
        bottom:0;
        right:-12px;
        overflow: hidden;
        opacity: 0;
        background: url("~@/assets/images/right_box.png") center no-repeat;
        background-size: contain;
      }
      .prev{
        left:-12px;
        background: url("~@/assets/images/left_box.png") center no-repeat;
        background-size: contain;
        &:after{
          right:0;
          left:auto;
        }
      }
      &.active {
        background-color: #fff;
        font-size: 16px;
        font-weight: 500;
        color: #31415F;
        z-index: 4;
        &:hover{
          z-index: 1;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        .prev,.next{
          opacity: 1;
        }
      }
      .tag-main {
        display: flex;
        align-items: center;
        .icon {
          width: 18px;
          height: 18px;
          margin-right: 4px;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      position: absolute;
      right: 8px;
      top: 50%;
      margin-top: -8px;
      width: 16px;
      height: 16px;
      font-size: 16px;
      font-weight: bold;
      //transition: all .3s cubic-bezier(.645, .045, .355, 1);
      z-index:3;
      //transform-origin: 50% 50%;
      &:before {
        display: inline-block;
      }
      &:hover {
        transform: scale(1.2);
        //color: #fff;
      }
    }
  }
}
</style>
