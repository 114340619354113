export const basicList = [
  {
    subTit: '企业通讯录',
    url: '/enterprise/address',
    code: '1120302',
    name: 'enterpriseAddress'
  },
  {
    subTit: '应用管理',
    url: '/enterprise/application',
    code: '112030301',
    // name: 'enterpriseApplicationConfig'
    name: 'dataPosition'
  },

  {
    subTit: '公告管理',
    url: '/enterprise/notice',
    code: '112030302',
    name: 'enterpriseNotice'
  },

  {
    subTit: '基础信息设置',
    url: '/enterprise/basic',
    code: '112030401',
    name: 'enterpriseBasic'
  },
  {
    subTit: '权限管理',
    url: '/enterprise/role',
    code: '112030402',
    name: 'enterpriseRole'
  }

]

// 数据管理
export const dataList = [
  {
    subTit: '项目管理',
    url: '/mainData/project',
    code: '1120403',
    name: 'dataProject'
  },

  {
    subTit: '楼栋管理',
    url: '/mainData/building',
    code: '1120404',
    name: 'dataBuilding'
  },
  {
    subTit: '标段管理',
    url: '/mainData/tender',
    code: '1120405',
    name: 'dataTender'
  },
  {
    subTit: '房间管理',
    url: '/mainData/room',
    code: '1120406',
    name: 'dataBuilding'
  },
  {
    subTit: '户型管理',
    url: '/mainData/doorModel',
    code: '1120407',
    name: 'dataDoorModel'
  },
  {
    subTit: '部位管理',
    url: '/mainData/position',
    code: '1120408',
    name: 'dataPosition'
  },
  {
    subTit: '承建商',
    url: '/mainData/contractor',
    code: '1120409',
    name: 'dataContractor'
  }
]

