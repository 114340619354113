<template>
  <div class="send-container">
    <!--    顶部控制按钮-->
    <div class="control">
      <div class="left">
        <div class="left-img" />
        <div class="left-file" />
        <div class="left-receipt" />
      </div>
      <div class="right">
        <div class="right-video" />
        <div class="right-call" />
      </div>
    </div>
    <!--    发送区域-->
    <div class="send-box">
      <el-input
        v-model="textarea"
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        show-word-limit
        clearable
        resize="none"
      />
    </div>

    <div class="submit">
      <el-button type="primary" size="mini" @click="sendHandle">发送</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Send',
  data() {
    return {
      textarea: ''
    }
  },
  methods: {
    /**
     * 触发发送消息
     */
    sendHandle() {
      this.$emit('send', this.textarea)
      this.textarea = ''
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  height: 80px;
  border: none;
  &:focus {
    outline: none;
    border:none
  }
}
</style>

<style scoped lang="scss">
.send-container {
  width: 100%;
  height: 100%;
  padding: 12px 12px 10px 24px;

  .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > div {
        margin-right: 10px;
        cursor: pointer;
      }
      .left-img {
        width: 30px;
        height: 30px;
        background: url("~@/assets/im/send-img.png") center no-repeat;
        background-size: cover;
      }
      .left-file {
        width: 30px;
        height: 30px;
        background: url("~@/assets/im/send-file.png") center no-repeat;
        background-size: cover;
      }
      .left-receipt {
        width: 30px;
        height: 30px;
        background: url("~@/assets/im/send-receipt.png") center no-repeat;
        background-size: cover;
      }
    }
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > div {
        margin-right: 10px;
        cursor: pointer;
      }
      .right-video {
        width: 30px;
        height: 30px;
        background: url("~@/assets/im/send-video.png") center no-repeat;
        background-size: cover;
      }
      .right-call {
        width: 30px;
        height: 30px;
        background: url("~@/assets/im/send-call.png") center no-repeat;
        background-size: cover;
      }
    }
  }

  .send-box {
    margin-top: 5px;
    width: 100%;
    height: 80px;
  }
  .submit {
    margin-top: 10px;
    text-align: right;
  }
}
</style>

