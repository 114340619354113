<template>
  <div class="m_qr_code">
    <el-dialog
      :visible.sync="invitations"
      center
      :append-to-body="false"
    >
      <div style="position: relative;">
        <span class="m_qr_code_img">
          <span class="m_qr_code_img_box m_qr_code_img_box_app">
            <span class="m_qr_code_img_box_body">
              <el-image
                v-loading="qrloading"
                :src="qrCodeUrl"
                fit="fit"
                style="width: 100%; height: 100%"
              >
                <div slot="error" class="image-slot el-icon-refresh-right" @click="getappcode" />
              </el-image>
            </span>
          </span>
          <span class="m_qr_code_url m_qr_code_url_text">
            <span>该应用只支持在APP端使用</span>
            <span class="u_code_app_title">请使用优客云APP扫码或直接在工作台中打开</span>
          </span>
        </span>
        <div class="m_qr_code_icon_close" @click="close" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get_appLink_qrCode } from '@/api/dashboard'
export default {
  name: 'AppQrCode',
  components: {},
  props: {
    invitation: {
      type: Boolean,
      default: false
    },
    isappUuid: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      qrloading: false,
      invitations: false,
      qrCodeUrl: '' // 二维码
    }
  },
  computed: {},
  watch: {
    invitation: {
      handler(val) {
        this.invitations = val
        this.qrCodeUrl = ''
        this.InviteUrl = ''
        if (val) {
          this.getappcode()
          // this.qrCodeUrl = require('@/assets/enterprise/download-app.png')
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    getappcode() {
      this.qrloading = true
      get_appLink_qrCode({
        appUuid: this.isappUuid
      }).then(d => {
        console.log(d)
        this.qrCodeUrl = d.data.qrHttPath || ''
      }).finally(_ => {
        this.qrloading = false
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    // 关闭邀请弹窗
    close() {
      this.invitations = false
      this.$emit('changevisible', false)
      this.qrCodeUrl = ''
      this.InviteUrl = ''
    }
  }
}
</script>

<style lang="scss" scoped></style>

