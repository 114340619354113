import request from '@/utils/request'
const baseURL = 'http://api.pm.youmatech.com/mock/983'
// 获取组织
export function getOrganizationList(data) {
  return request({
    url: '/api/v1/organ/address_book/get_organ_tree',
    method: 'post',
    data
  })
}

// 获取选择的组织的项目列表
export function getProjectList(data) {
  return request({
    url: '/api/v1/data/project/org/list',
    method: 'post',
    data
  })
}

// 项目管理添加
export function addProject(data) {
  return request({
    url: '/api/v1/data/project/add',
    method: 'post',
    data
  })
}

// 项目管理编辑
export function editProject(data) {
  return request({
    url: '/api/v1/data/project/edit',
    method: 'post',
    data
  })
}
// 项目管理新增分期
export function addProjectPhases(data) {
  return request({
    url: '/api/v1/data/project/add-branch',
    method: 'post',
    data
  })
}
// 项目管理新增分期
export function editProjectPhases(data) {
  return request({
    url: '/api/v1/data/project/edit-branch',
    method: 'post',
    data
  })
}

// 项目管理详情
export function getProjectDetail(params) {
  return request({
    url: '/api/v1/data/project/detail',
    method: 'get',
    params
  })
}
// 项目管理删除
export function delProject(params) {
  return request({
    url: '/api/v1/data/project/delete',
    method: 'get',
    params
  })
}

// 项目禁用启用
export function enableProject(params) {
  return request({
    url: '/api/v1/data/project/enable',
    method: 'get',
    params
  })
}
