<script>
import router from '@/router'

export default {
  created() {
    console.log(this.$route)
    const { params, query } = this.$route
    const { path } = params
    // 处理地址和参数问题
    // const url = '/appLibrary/pkg2/pg/propertyPayment/info?recordNo=SKBH202211170003700002&receiptRecordId=84547'
    // const url = '/appLibrary/h5?url=https://baidu.com'
    const appHeader = this.$route.fullPath.split('/')
    const code = appHeader[2]

    switch (code) {
      case 'h5':
        {
          const { url } = this.$route.query
          const href = decodeURIComponent(url)
          if (/^(https:)/.test(href)) {
            router.push({ path: '/dashboard/web', query: { pcUrl: href }})
          }
          if (/^(http:)/.test(href)) {
            window.open(href)
          }
        }
        break
      case 'main':
        this.$router.replace({ path: '/' + path.replace('main/', ''), query })
        break
      default:
        this.$store.dispatch('app/jumpAPP', {
          name: 'App' + code.substring(0, 1).toUpperCase() + code.substring(1),
          // isPath: true,
          path: this.$route.fullPath.replace('/appLibrary/' + code, '') // path + query  序列化
        })
        break
    }
  },
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
