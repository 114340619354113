<template>
  <div class="m-cloudMarkDetail">
    <div class="cloudMarkDetail_commodity commodity_service">
      <div class="go_back_common">
        <div class="go_back_title" @click="back">
          <span class="go_back_icon" />
          <span class="go_back_txt">返回云市场首页</span>
        </div>
        <div class="go_back_lines" />
      </div>

      <div class="commodity_content serviceInfo">
        <div class="commodity_content_pic">
          <div class="content_img img_service">
            <img :src="url" alt="" height="100%" width="100%">
          </div>
          <div class="little_img flex mt20 pointer">
            <div v-for="(item,i) in detailInfo.servicePictureUrls" :key="i" @click="getUrl(item)">
              <div :class="url===item?'blueBorder':''" class="mr-10 imgUrl">
                <img :src="item" alt="" height="100%" width="100%">
              </div>
            </div>
          </div>
        </div>
        <div class="ml30 commodity content_introduce m_commodity_service">
          <div>
            <div class="f-flex-jcsb col-top">
              <div style="margin-right: 30px">
                <UmToolTip :content="detailInfo.serviceTitle" class="title mw-560" />
                <p class="text mw-560 mt10">{{ detailInfo.serviceDescribe }}</p>
              </div>
              <div class="m_commodity_service_ritem" style="padding-top: 6px">
                <div class="nowrap"><UmCollection v-model="collectionStatus" @change="collectHandler" /></div>
                <div class="m_commodity_service_ritem_im" @click="concactCustomer">
                  <svg-icon class="mr-4 " icon-class="blueLijizixun" />
                  <span class="u_txt nowrap">立即咨询</span>
                </div>
              </div>
            </div>
            <div class="top-switch mt-20">
              <div class="redColor flex">
                ¥
                <div class="number">
                  {{ price }}
                </div>
                元
                <!--                <UmCollection v-model="collectionStatus" style="margin-left: 30px;" @change="collectHandler" />-->
              </div>
            </div>
            <div>
              <div class="u_tag-title">选择规格</div>
              <div class="text flex u_tag-wrap row_one  mb10  mr-10">
                <el-tag
                  v-for="(item,i) in detailInfo.specificationList"
                  :key="i"
                  :type="specificationUuid==detailInfo.specificationList[i].specificationUuid?'':'info'"
                  :class="active===i?'tag1 pointer':'tag pointer'"
                  size="mini"
                  class="row_one"
                  @click="getSpecifications(i)"
                >
                  {{ item.specificationName }}
                </el-tag>
              </div>
            </div>
          </div>
          <!-- 价格按钮 -->
          <div class="m_commodity_service_tool">
            <div class="flex">
              <el-button type="primary" @click="getBuyNow()">立即购买</el-button>
              <el-button class="btn" type="primary" plain @click="addShopCar">
                <svg-icon class="mr-4 " icon-class="shopCar" />
                加入购物车
              </el-button>
              <el-button class="btn " type="primary" plain @click="getBussinessMobile()">
                <svg-icon class="mr-4 " icon-class="bluePhone" />
                联系服务商
              </el-button>
              <!--              <el-button class=" btn " type="primary" plain @click="concactCustomer">-->
              <!--                <svg-icon class="mr-4 " icon-class="blueLijizixun" />-->
              <!--                立即咨询-->
              <!--              </el-button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cloudMarkDetail_product">
      <div class="flex-between mb20" style="border-bottom:1px dashed #E4E8EB">
        <div class="product-title margin">产品详情</div>
        <div class="flex-center ">
          <div class="font-14 mr-4">综合评价</div>
          <UmStar :star="detailInfo.evaluateScore" />
          <div class="yellowColor font-14">{{ detailInfo.evaluateScore }}分</div>
        </div>
      </div>

      <div class="flex-center mt-10">
        <UmEditInfo v-if="detailInfo.serviceContent" :url="detailInfo.serviceContent" @goInfo="jumpPath"  />
        <no-data v-else class="mt40" />
      </div>
    </div>
    <confirm-order-two
      ref="order"
      :order-info="detailInfo"
      :order-two-flag="orderTwoFlag"
      :platform-order-number="platformOrderNumber"
      :business-order-uuid="businessOrderUuid"
      :price="price"
      :specification-name="specificationName"
      :tab-type="4"
      @changeShow="changeShow"
    />
    <service-provider
      :bussiness-mobile="bussinessMobile"
      :contact-flag="contactFlag"
      @changeContactFlag="changeContactFlag"
    />
  </div>
</template>

<script>
import confirmOrderTwo from '../../components/confirmOrderTwo.vue'
import { getCloudMarketInfo, getSubmitOrder } from '@/api/cloudMarket'
import ServiceProvider from '../../components/serviceProvider.vue'
import noData from '../../components/noData.vue'
import { mapGetters } from 'vuex'
import imSdk from '@um/im'
import { addCollect, addShopping, cancelCollect, isCollect } from '@/api/dashboard'
import { jumpPath } from '@/utils/jump'

export default {
  name: 'CloudMarketServiceCentreDetails',
  components: { confirmOrderTwo, ServiceProvider, noData },
  data() {
    return {
      jumpPath,
      url: '',
      orderTwoFlag: false,
      detailInfo: {},
      price: 0,
      contactFlag: false,
      bussinessMobile: '',
      specificationName: '',
      specificationUuid: '',
      platformOrderNumber: '',
      collectionStatus: false,
      businessOrderUuid: '',
      active: 0
    }
  },
  mounted() {
    this.getDetail()
  },
  computed: {
    ...mapGetters(['session', 'sessionList'])
  },
  methods: {
    back() {
      if (this.$route.query.tabType != 0) {
        this.$router.push({ path: '/cloudMarket', query: { tabType: this.$route.query.tabType }})
      } else {
        this.$router.push({ path: '/cloudMarket/shoppingCart' })
      }
    },

    collectHandler(status) {
      if (status) {
        addCollect({
          businessType: 2003010102,
          businessUuid: this.$route.query.uuid,
          businessName: this.detailInfo.serviceTitle
        }).then(res => {
          this.$message.success('已收藏！')
        }).catch(e => {
          this.collectionStatus = !this.collectionStatus
        })
      } else {
        cancelCollect({
          businessType: 2003010102,
          businessUuid: this.$route.query.uuid,
          type: 1
        }).then(res => {
          this.$message.success('取消收藏！')
        }).catch(e => {
          this.collectionStatus = !this.collectionStatus
        })
      }
    },
    addShopCar() {
      const load = this.$load()
      addShopping({
        skuList: [
          {
            businessType: 1023902,
            skuUuid: this.specificationUuid
          }
        ]
      }).then(res => {
        this.$message.success('添加购物车成功！')
      }).finally(_ => {
        load.close()
      })
    },
    // 详情
    getDetail() {
      const load = this.$load()
      getCloudMarketInfo({ uuid: this.$route.query.uuid }).then(res => {
        this.detailInfo = res.data.serviceInfo
        this.url = res.data.serviceInfo.servicePictureUrls[0]
        this.bussinessMobile = res.data.serviceInfo.businessMobile
        this.price = res.data.serviceInfo.specificationList[0].specificationPrice
        this.specificationName = res.data.serviceInfo.specificationList[0].specificationName
        this.specificationUuid = res.data.serviceInfo.specificationList[0].specificationUuid
        this.collectionStatus = res.data.serviceInfo.collectFlag
        load.close()
      }).catch(e => {
        load.close()
      })
    },
    /**
     * 联系客户
     * @param item
     */
    concactCustomer() {
      const { businessImAccount: imAccount } = this.detailInfo
      if (!imSdk.YX) return this.$message.warning('聊天功能异常')
      if (!imAccount) return this.$message.warning('客户信息异常！')
      const goodsInfo = {
        shareTitle: this.detailInfo.serviceTitle,
        shareContent: this.detailInfo.serviceDescribe,
        shareImageUrl: this.detailInfo.servicePictureUrls[0] || '',
        shareType: 'pc',
        shareLink: `/cloudMarket/info?uuid=${this.$route.query.uuid}&dataType=${this.$route.query.dataType}`,
        price: this.detailInfo.servicePrice
      }
      imSdk.YX.concactCustomer(imAccount, 'p2p', goodsInfo).then(res => {
        this.$router.push('/im')
      }).catch(e => {
        this.$message.error('操作失败')
      })
    },
    // 选择规格
    getSpecifications(i) {
      this.active = i
      this.price = this.detailInfo.specificationList[i].specificationPrice
      this.specificationName = this.detailInfo.specificationList[i].specificationName
      this.specificationUuid = this.detailInfo.specificationList[i].specificationUuid
    },
    getBuyNow() {
      if (this.$store.getters.userInfo.adminFlag === true) {
        if (this.specificationName === '') {
          this.$message.error('请先选择规格')
        } else {
          this.orderTwoFlag = true
          this.$refs.order.handleChange()
        }
      } else {
        this.$message.error('非管理员暂时无法购买，请联系管理员购买！')
      }
    },
    changeShow(data) {
      if (data === 'false') {
        this.orderTwoFlag = false
      } else {
        this.orderTwoFlag = true
      }
    },
    getUrl(item) {
      this.url = item
    },
    // 联系服务商
    getBussinessMobile() {
      this.contactFlag = true
    },
    changeContactFlag(data) {
      if (data === 'false') {
        this.contactFlag = false
      } else {
        this.contactFlag = true
      }
    },
    // 提交订单信息
    async submitOrder(num) {
      const params = {
        serviceUuid: this.$route.query.uuid,
        specificationUuid: this.specificationUuid,
        organUuid: this.detailInfo.organUuid,
        goodsNum: num,
        goodsPrice: this.price
      }
      await getSubmitOrder(params).then(res => {
        this.platformOrderNumber = res.data.orderNo
        this.businessOrderUuid = res.data.businessOrderUuid
      })
    }
  }
}
</script>

