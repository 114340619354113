import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import Layout from '@/layout'

import messageRouter from '@/router/modules/message'
import dashboardRouter from '@/router/modules/dashboard'
import enterpriseRouter from '@/router/modules/enterprise'
import flowRouter from '@/router/modules/flow'
import mainDataRouter from '@/router/modules/mainData'
import Jump from '@/layout/jump'
import cloudMarketRouter from '@/router/modules/cloudMarket'
import meetingRouter from '@/router/modules/meeting'
import courseRouter from '@/router/modules/course'
import activityRouter from '@/router/modules/activity'
import noticeRouter from '@/router/modules/notice'
import applicationRouter from '@/router/modules/application'
import administrationRouter from '@/router/modules/administration'
import maillistRouter from '@/router/modules/maillist'
/* Router Modules */

Vue.use(Router)
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    cache: false                 设置为true，那么将缓存这个页面
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
    level: 1\2\3                   1 表示大模块  2 表示业务模块  3 表示业务模块内的新增、详情页面，此数值主要用来处理页面权限跳转问题，类似以前的children结构
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  // appLibrary  不能出现在 导航栏tag
  {
    path: '/appLibrary',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/appLibrary/:path(.*)',
        component: () => import('@/views/appLibrary/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/im'
  },
  {
    path: '/jump',
    component: Jump,
    meta: { title: 'jump' },
    hidden: true
  },
  {
    path: '/redirectLink',
    component: () => import('@/views/redirectLink/index'),
    meta: { title: 'redirectLink' },
    hidden: true
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  messageRouter,
  dashboardRouter,
  enterpriseRouter,
  flowRouter,
  mainDataRouter,
  cloudMarketRouter,
  meetingRouter,
  courseRouter,
  activityRouter,
  noticeRouter,
  applicationRouter,
  administrationRouter,
  maillistRouter,
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
  base: process.env.VUE_APP_BASE_URL
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
