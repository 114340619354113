<template>
  <div class="m-steps">
    <div v-for="(item,index) in list" :key="index" class="steps-item">
      <div class="item-serial" :class="{active: active_index >= index }">{{ index + 1 }}</div>
      <div class="item-name row_one">{{ item.label || '-' }}</div>
      <div v-if="index!==list.length - 1" class="s-line" :class="{active: active_index >= index }">
        <div class="s-ball" />
        <div class="s-dashed" />
        <div class="s-ball" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Steps',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active_index: 0
    }
  },
  watch: {
    value: {
      handler(val) {
        let value
        if (val < 0) value = 0
        else if (val > this.list.length - 1) value = this.list.length - 1
        else value = val
        this.active_index = value
        this.$emit('change', value)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
