<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="show.progress"
    :title="title"
    :show-close="false"
    @closed="handleCancel"
  >
    <el-progress
      :percentage="progressData.percent"
      :format="format"
    />
    <span>正在导出数据，请耐心等待</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getProgressInfo } from '@/api/common'
export default {
  name: 'UExport',
  props: {
    isPdf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: '正在导出',
      show: {
        progress: false
      },
      operateProgressId: 0,
      queryProgressTimer: 0,
      progressData: {
        percent: 0,
        resultData: {}
      },
      progressParamData: null, // 数据值
      progressBusinessCode: null // 业务code
    }
  },
  watch: {
    operateProgressId(val) {
      if (val) {
        console.log(val)
        this.startProgress()
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 下载方法
    download() {
      // if (this.isPdf) {
      //   exportPdf({
      //     progressParamData: this.progressParamData,
      //     progressBusinessCode: this.progressBusinessCode
      //   }).then(d => {
      //     this.operateProgressId = d.data.progressId
      //   })
      // } else {
      //   exportExcel({
      //     progressParamData: this.progressParamData,
      //     progressBusinessCode: this.progressBusinessCode
      //   }).then(d => {
      //     this.operateProgressId = d.data.progressId
      //   })
      // }
    },
    handleAfterLeave() {
      this.$emit('leave')
      // this.$destroy(true)
      // this.$el.parentNode.removeChild(this.$el)  // 可能移除的时候组件已经不存在了
    },
    format(percentage) {
      return percentage === 100 ? '完成' : `${percentage}%`
    },
    click() {
      this.$emit('click')
    },
    handleCancel() {
      this.show.progress = false
      // 清楚定时
      this.clearTimer()
      this.handleAfterLeave()
    },
    startProgress() {
      this.show.progress = true
      this.startTimer()
    },
    endProgress() {
      this.clearTimer()
      setTimeout(() => {
        this.show.progress = false
      }, 2000)
    },
    // 开始定时任务
    startTimer() {
      this.queryProgress()
      // this.timer = setInterval(this.queryProgress, 1000 * 2)
    },
    // 结束定时任务
    clearTimer() {
      // clearInterval(this.timer)
      clearTimeout(this.timer)
    },
    // 查询进度
    queryProgress() {
      getProgressInfo({ progressId: this.operateProgressId }).then(d => {
        const progress = d.data
        if (progress.progressStateCode === 1109501) {
          // 等待开始
          this.progressData.percent = 0
        }
        if (progress.progressStateCode === 1109502) {
          // 初始化完成
          this.progressData.percent = 0
          this.title = progress.progressTitle
        }
        if (progress.progressStateCode === 1109503) {
          this.title = progress.progressTitle
          // 进行中
          this.progressData.percent = parseInt(progress.progressCompleteCount / progress.progressCount * 100)
        }
        if (progress.progressStateCode === 1109504) {
          // 终止
          this.progressData.percent = 0
          this.$message.error(progress.progressMsg)
          this.endProgress()
          return false
        }
        if (progress.progressStateCode === 1109505) {
          // 正常结束
          this.progressData.percent = 100
          this.$message.success('导出成功')
          this.endProgress()
          this.progressData.resultData = progress.progressResultData

          const a = document.createElement('a')
          a.download = ''
          a.href = progress.progressResultData.excelHttpPath
          a.click()
          this.$emit('finishBack')
          return false
        }
        this.timer = setTimeout(this.queryProgress, 1000 * 2)
      }).catch(data => {
        this.timer = setTimeout(this.queryProgress, 1000 * 2)
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-button {
    height: 32px;
    font-size: 14px;
  }
}
</style>
