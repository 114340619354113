<template>
  <EnterpriseLayout>
    <div class="m_enterprise_announcementmanagement">
      <div class="m_announcementmanagement_content">
        <div class="m_announcementmanagement_top">
          <div class="m_announcementmanagement_input">
            <el-input
              v-model="search"
              placeholder="请输入关键词搜索"
              clearable
              maxlength="50"
            />
          </div>
          <div class="m_announcementmanagement_btn">
            <el-button type="primary" class="el-icon-search" @click="query_notice_lists"> 搜索</el-button>
            <el-button v-permission="['11203030203']" type="primary" plain @click="createannouncement">新建</el-button>
          </div>
        </div>
        <div class="m_announcementmanagement_contents">
          <el-scrollbar v-if="information.length" style="height: 90%">
            <div v-for="(item) in information" :key="item.noticeUuid" class="m_announcementmanagement_itembox">
              <el-row>
                <el-col :span="12">
                  <div v-if="item.topFlag==1" class="m_announcementmanagement_icons" />
                  <div class="m_announcementmanagement_common">
                    <div class="m_announcementmanagement_bgtitle row_1" @click="go_newsInfo(item.noticeUuid)">
                      <span v-if="item.readFlag!=1" class="m_read_icon" />
                      <span>{{ item. noticeTitle||'-' }}</span>
                    </div>
                    <div class="m_announcementmanagement_conmmen1 row_1">{{ item.describe||'-' }}</div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="m_announcementmanagement_common">
                    <div class="m_announcementmanagement_conmmen1">发布者</div>
                    <div class="m_announcementmanagement_conmmen2 row_1">{{ item.publishUserName||'-' }}</div>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="m_announcementmanagement_common">
                    <div class="m_announcementmanagement_conmmen1">发布时间</div>
                    <div class="m_announcementmanagement_conmmen2 row_1">{{ item.publishTime||'-' }}</div>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="m_announcementmanagement_common m_announcementmanagement_btns">
                    <div v-permission="['11203030204']" class="m_announcementmanagement_edit" @click="editinfo(item)">
                      <span class="m_announcementmanagement_editicon" />
                      编辑
                    </div>
                    <div v-permission="['11203030205']" class="m_announcementmanagement_delete" @click="delinfo(item)">
                      <span class="m_announcementmanagement_deleicon" />
                      删除
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-scrollbar>
          <div v-else class="m_announcementmanagement_nocomponets">暂无数据</div>
          <div>
            <pagination
              :background="false"
              :layout="'total, sizes, prev, pager, next, jumper'"
              :limit.sync="page.pageSize"
              :page.sync="page.pageNum"
              :total="total"
              @pagination="query_notice_lists('page')"
            />
          </div>
        </div>
      </div>
    </div>
  </EnterpriseLayout>
</template>

<script>
import {
  query_notice_list,
  delete_notice
} from '@/api/enterprise'
export default {
  name: 'EnterpriseNotice', // 公告管理
  components: {},
  props: {},
  data() {
    return {
      search: '',
      total: 0,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      information: []
    }
  },
  computed: {},
  watch: {},
  created() {
    this.query_notice_lists()
  },
  activated() {
    this.search = ''
    this.query_notice_lists()
  },
  mounted() {},
  methods: {
    // 获取列表
    query_notice_lists() {
      const load = this.$load()
      query_notice_list({
        search: this.search,
        page: this.page
      }).then(d => {
        this.information = d.data.list
        this.total = d.data.page.total
      }).finally(_ => {
        load.close()
      })
    },
    // 消息详情
    go_newsInfo(id) {
      this.$router.push({
        path: '/notice/info',
        query: {
          noticeUuid: id
        }
      })
    },
    // 编辑消息
    editinfo(val) {
      this.$router.push({ path: '/enterprise/notice/update', query: { noticeUuid: val.noticeUuid }})
    },
    // 删除消息
    delinfo(val) {
      this.$confirm(` ${val.noticeTitle} `, '是否确认删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
        center: true
      }).then(() => {
        const load = this.$load()
        delete_notice({
          noticeUuid: val.noticeUuid
        }).then(d => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.query_notice_lists()
        }).finally(_ => {
          load.close()
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        })
      })
    },
    // 创建公告
    createannouncement() {
      this.$router.push('/enterprise/notice/add')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
