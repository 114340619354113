
<template>
  <div class="custom">
    <div class="custom-upload">
      <el-upload
        class="upload-demo"
        drag
        name="files"
        :accept="accept"
        :multiple="multiple"
        :file-list="fileList"
        :limit="limit"
        :before-upload="beforeUpload"
        :on-success="handleSuccess"
        :on-error="handleError"
        :on-exceed="handleExceed"
        :on-remove="handleRemove"
        :action="action"
        :show-file-list="false"
        :headers="{
          Authorization:token
        }"
      >
        <i class="el-icon-upload" />
        <div class="gp-upload__text">点击或拖动文件上传</div>
      </el-upload>
    </div>
    <div v-for="(item,index) in fileList " :key="index" class="upload-item">
      <div class="upload-del" @click="handleRemove(item)" />
      <img v-if="item.response&&item.response.data" :src="item.response.data.fileFullPath" class="upload-img" alt="">
      <div class="upload-txt row_1">{{ item.name ||item.response.data.filename }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { getToken } from '@/utils/auth'
export default {
  name: 'UmCustomUploadImg',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 支持剪切
    isCropper: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 5000
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      token: getToken(),
      accept: '.png,.jpg,.jpeg,.gif', // .xls,.xlsx
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [], // 文件列表
      file: {},
      cropperVisible: false,
      cropper: null,
      source: null, // 取消接口方法
      cropperLoading: false // 裁剪上传loading
    }
  },
  computed: {
    action() {
      return '/api/v1/file_manage/file/upload'
    },
    autoUpload() {
      // 处理是否自动上传
      // 如果应用了裁剪属性，那就不会自动上传
      return !this.isCropper
    }
  },

  watch: {
    fileList: {
      handler(val) {
        if (Array.isArray(val)) {
          const urlList = val.filter(item => item.response && item.response.data).map(item2 => item2.response.data.fileFullPath)

          this.$emit('change', urlList)
        }
      },
      deep: true
    }
  },

  methods: {

    beforeUpload(file) {
      const isJPG = file.type && file.type.indexOf('image') !== -1
      if (!isJPG) {
        this.$message.error('只能上传图片格式!')
        return false
      }
      if (this.size === 0) {
        return true
      }
      const isSize = file.size / 1024 < this.size
      if (!isSize) {
        this.$message.error(`上传的文件大小不能超过 ${this.size}kb!`)
      }
      return isSize
    },
    handleSuccess(response, file, fileList) {
      if (response.code !== 3001) {
        this.$message.error('上传失败!')
        // 上传失败之后移除图片
        this.fileList.splice(this.fileList.indexOf(file), 1)
        return false
      }
      if (this.limit === 1) {
        this.fileList = [file] // 直接赋值
      } else {
        this.fileList = fileList // 直接赋值
      }

      // 抛出上传成功的列表以及文件

      this.$emit('success', file, fileList)
    },
    // eslint-disable-next-line node/handle-callback-err
    handleError(err, file, fileList) {
      this.$message.error('上传失败!')
    },
    handleExceed(files, fileList) {
      // fileList.splice(0, 1, files[0])
      this.$message.warning(
          `当前限制选择 ${this.limit} 个文件，本次选择了 ${
              files.length
          } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    handleRemove(file) {
      this.fileList.forEach((item, index) => {
        // if (item.url === file.url) {
        //   this.fileList.splice(index, 1) // 删除
        // }
        if (item.response.data.filePath === file.response.data.filePath) {
          this.fileList.splice(index, 1) // 删除
        }
      })
      // this.fileList_path.forEach((item, index) => {
      //   if (item === file.response.data.filePath) {
      //     this.fileList_path.splice(index, 1) // 删除
      //   }
      // })
      // this.$emit('change', this.fileList_path)
      //
    }
  }
}
</script>

<style scoped lang="scss">

.custom{
 padding-top: 10px;
  .upload-item{
    display: inline-block;
    margin-right: 16px;
    margin-top: 13px;
    width: 80px;
    height: 80px;
    text-align: center;
    cursor: pointer;
    position: relative;
    .upload-del{
      position: absolute;
      width: 14px;
      height: 14px;
      right: 8px;
      top:-4px;
      background: url("~@/assets/dashboard/icon-del.png") no-repeat;
      background-size: 100% 100%;
    }
    .upload-img{
      border-radius: 4px;
      width: 52px;
      height: 52px;
    }
    .upload-txt{
      font-size: 12px;
      color: #6C7B96;
      line-height: 1;

    }

  }
  .custom-upload{
    height: 150px;
    background: #FFFFFF;
    border-radius: 8px;
    .upload-demo{
      text-align: center;
      ::v-deep .el-upload-dragger{
        width: 150px;
        height: 150px;
        border: none;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          width: 98px;
          height: 98px;
          background: url("~@/assets/common/icon-upload.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .el-upload__text{
        font-weight: 500;
        font-size: 16px;
        color: #31415F;
      }
    }
  }
}
</style>
