<template>
  <div class="detailsBox m_enterprise_meetinglist">
    <UmApproveEle :app-uuid="appUuid" />
    <div class="meetingdetail_content cardBox">
      <el-button type="primary" @click="add"><span class="create-add-icon" /> 创建会议室</el-button>
      <div v-if="tableData.length>0" class="content_box">
        <div v-for="(item,index) in tableData" :key="index" class="box-it" style="" @click="toInfo(item)">
          <div class="content_box_item">
            <div class="item_del" @click.stop="del(item.roomUuid)" />
            <div class="item-img">
              <img :src="item.roomImageUrl" alt="">
            </div>
            <div class="item_text">
              <div class="item_text_title row_one">{{ item.roomName }}</div>
              <div class="item_text_position">
                <i class="el-icon-location-outline item_text_position_icon" />
                <div class="row_one">  地点：{{ item.roomAddress }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination
        v-if="tableData.length>0"
        class="content_paging"
        :background="false"
        style="margin-top: 0"
        :total="tableTotal"
        :page.sync="page.pageNum"
        :limit.sync="page.pageSize"
        :layout="'total, sizes, prev, pager, next, jumper'"
        @pagination="getList('page')"
      />
      <div v-else class="m-noData m_meetinglist_nodata">
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import {
  roomQuery_conference_room,
  getDelRoom
} from '@/api/enterprise'

export default {
  name: 'EnterpriseApplicationMeeting',
  data() {
    return {
      template: 1,
      visible: false,
      value: true,
      user: [],
      appUuid: '',
      tableTotal: 0,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      info: {},
      tableData: []
    }
  },
  created() {
    this.appUuid = this.$route.query.appUuid
  },
  mounted() {
    this.getList()
  },
  methods: {
    back() {
      this.$router.replace('/meeting')
    },
    getList(state = '') {
      if (state !== 'page') {
        this.page.pageNum = 1
      }
      const load = this.$load()
      const params = {
        page: {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize
        }
      }
      roomQuery_conference_room(params).then(res => {
        load.close()
        this.tableData = res.data.list
        this.tableTotal = res.data.page.total
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    add() {
      this.$router.push({ path: '/meeting/add', query: { type: 'add', appUuid: this.appUuid }})
    },
    toInfo(item) {
      this.$router.push({ path: '/meeting/info', query: { roomUuid: item.roomUuid, appUuid: this.appUuid }})
    },
    del(id) {
      this.$confirm('确认删除', '提示', {
        type: 'warning'
      }).then(() => {
        const load = this.$load()
        getDelRoom({ roomUuid: id }).then(res => {
          load.close()
          this.$message.success('删除成功')
          this.getList()
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
