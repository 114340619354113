import { render, staticRenderFns } from "./login-choseTeam.vue?vue&type=template&id=668a4954&scoped=true&"
import script from "./login-choseTeam.vue?vue&type=script&lang=js&"
export * from "./login-choseTeam.vue?vue&type=script&lang=js&"
import style0 from "./login-choseTeam.vue?vue&type=style&index=0&id=668a4954&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668a4954",
  null
  
)

export default component.exports