<template>
  <div class="g-workbenchSearch">
    <div class="m-top">
      <div class="go_back_common">
        <div class="go_back_title" @click="back">
          <span class="go_back_icon" />
          <span class="go_back_txt">返回</span>
        </div>
        <div class="go_back_lines" />
      </div>
    </div>
    <div class="m-content">
      <div class="flex mb10">
        <el-input v-model="searchParam" placeholder="请输入关键词搜索" maxlength="50" />
        <el-button icon="el-icon-search" type="primary" class="ml20 flex-center" style="height:32px" @click="getSearch(searchParam)">搜索</el-button>
      </div>
      <div v-if="dataList&&dataList.length" class="content-list">
        <div class="u-title">工作台</div>
        <div v-for="(item,index) in dataList" :key="index" class="item">
          <div
            class="u-img mr-4"
            :style="{
              backgroundImage: 'url(' + item.appIcon + ')'
            }"
          />
          <div>
            <div class="u-appName">{{ item.appName }}</div>
            <div class="max-128 row_2">{{ item.appCategoryName||'暂无描述' }}</div>
          </div>
          <div class="open-app" @click="openUrl(item.pcJumpLink)">打开</div>
        </div>
      </div>
      <div v-else class="empty-list">
        <div class="empty-icon um-icon um-icon_work-empty" />
        <div class="no-title">没有找到匹配的结果</div>
        <div>您可以试试下面的建议搜索</div>
        <div class="hot-record">
          <div class="hot-title">热门搜索</div>
          <div v-for="(item,index) in hotList" :key="index" class="record-item" @click="getSearch(item)">{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHotSearch, getSearchWork } from '@/api/workbench'
import { openUrl } from '@/utils/jump'
export default {
  name: 'DashboardWorkSearch',
  data() {
    return {
      searchParam: null,
      dataList: [],
      hotList: []
    }
  },
  mounted() {
    if (this.$route.query.keyWord) {
      this.searchParam = this.$route.query.keyWord
      this.getSearch(this.$route.query.keyWord)
    }
    this.getHotSearchList()
  },
  methods: {
    back() {
      // this.$router.push({ path: '/dashboard', query: {}})
      this.$router.replace('/dashboard/index')
    },
    // 獲取热门搜索
    getHotSearchList() {
      getHotSearch().then(res => {
        this.hotList = res.data
      })
    },
    getSearch(searchParam) {
      this.searchParam = searchParam
      getSearchWork({ searchParam }).then(res => {
        this.dataList = res.data
      })
    },
    openUrl(url) {
      openUrl(url, {})
      // if (/^(https:)/.test(url)) {
      //   this.$router.push({
      //     path: '/dashboard/web',
      //     query: {
      //       url
      //     }
      //   })
      //   return false
      // }
      // if (/^(http:)/.test(url)) {
      //   window.open(url)
      //   return false
      // }

      // this.$router.push({ path: url, query: {}})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
