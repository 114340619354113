<template>
  <div class="login_choseteam">
    <div v-if="!showjointem">
      <div v-if="joinList&&joinList.length" style="margin-bottom:30px">
        <div class="ctop">
          <div class="ctitle">待加入企业</div>
          <div />
        </div>
        <el-scrollbar
          v-if="joinList.length"
          style="height: 140px"
        >
          <div
            v-for="item in joinList"
            :key="item.id"
            v-loading="choseloading"
            class="ccontent"
            @click="showTips(item)"
          >
            <div class="cleft">
              <div class="cimg">
                <!--              <el-avatar-->
                <!--                shape="square"-->
                <!--                :size="32"-->
                <!--                :src="item.enterpriseIcon"-->
                <!--                icon="el-icon-picture-outline"-->
                <!--              />-->
                <el-image
                  style="width: 100%; height: 100%;border-radius: 3px"
                  :src="item.enterpriseIcon"
                  fit="fit"
                >
                  <div slot="error" class="image-slot">
                    <!--                      <i class="el-icon-user-solid" />-->
                    <div class="compoangnodata" />
                  </div>
                </el-image>
              </div>
              <div class="companyname verify row_1" :class="{joions:item.enterpriseAuthenticationCode != 1155524}">{{ item.enterpriseName || '-' }}</div>
              <div
                v-if="item.enterpriseAuthenticationCode == 1155524"
                class="cicon"
              />
            </div>
            <div v-if="item.joinTypeCode == 1 || item.joinTypeCode == 2" class="zhuangtai">
              <div
                :class="{daiyanz:item.joinTypeCode==1,daishenhe:item.joinTypeCode==2}"
                class="text"
              >
                {{ item.joinTypeCode == 1 ? '待验证' : '待审核' }}
              </div>
              <!--            <div class="righticon" />-->
            </div>
          </div>
        </el-scrollbar>
        <div
          v-else
          v-loading="choseloading"
          class="emptycontent"
        >
          <span>暂无数据</span>
        </div>
      </div>
      <div>
        <div class="ctop">
          <div class="ctitle">已加入企业</div>
          <div>
            <el-checkbox v-model="defaultFlag">设为默认企业</el-checkbox>
          </div>
        </div>
        <el-scrollbar
          v-if="myList.length"
          style="height: 140px"
        >
          <div
            v-for="item in myList"
            :key="item.id"
            v-loading="choseloading"
            class="ccontent"
            :class="[item.recentlyFlag?'ccontent-default':'']"
            @click="choseEnterprise(item)"
          >
            <div class="cleft">
              <div class="cimg">
                <el-image
                  style="width: 100%; height: 100%;border-radius: 3px"
                  :src="item.enterpriseIcon"
                  fit="fit"
                >
                  <div slot="error" class="image-slot">
                    <!--                      <i class="el-icon-user-solid" />-->
                    <div class="compoangnodata" />
                  </div>
                </el-image>
                <!--              <el-avatar-->
                <!--                shape="square"-->
                <!--                :size="32"-->
                <!--                :src="item.enterpriseIcon"-->
                <!--                icon="el-icon-picture-outline"-->
                <!--              />-->
              </div>
              <div class="companyname enterjoions row_1" :class="{nocodes:item.enterpriseAuthenticationCode != 1155524}">{{ item.enterpriseName || '-' }}</div>
              <div
                v-if="item.enterpriseAuthenticationCode == 1155524"
                class="cicon"
              />
            </div>
            <div class="righticon" />
            <div v-if="item.recentlyFlag" class="u-default">常用</div>
          </div>
        </el-scrollbar>
        <div
          v-else
          v-loading="choseloading"
          class="emptycontent"
        >
          <span>暂无数据</span>
        </div>
      </div>
      <div class="btn">
        <div class="shadow-but" @click="visible = true">
          <span class="flex-center">
<!--            <span class="btnoicn" />-->
            <span class="um_iconfont um_icon-add btnoicn"></span>
            创建企业/团队
          </span>
        </div>
      </div>
      <div class="m-logout" @click="layout">退出登录</div>
    </div>
    <div v-else>
      <loginJoinTeam :choseitem="choseitem" @changeshowjoin="changeshowjoin" />
    </div>
    <el-dialog :visible.sync="visible" title="创建企业" width="800px" center @close="$refs.form.resetFields()">
      <el-form ref="form" :model="formData" label-width="100px" :rules="rules">
        <el-form-item label="企业logo" prop="enterpriseIcon">
          <!--          <um-upload-img-->
          <!--            v-model="formData.enterpriseIcon"-->
          <!--            :is-cropper="true"-->
          <!--            :cropper-options="{-->
          <!--              aspectRatio: 1 / 1-->
          <!--            }"-->
          <!--            :limit="1"-->
          <!--            simple-->
          <!--          />-->
          <div class="tips">
            <UmUploadImg
              v-model="formData.enterpriseIcon"
              type="avatar"
              :show-icon="false"
              custom-file
              :limit="1"
              :is-cropper="true"
              :cropper-options="{
                aspectRatio: 1 / 1
              }"
            >
              <template #extend>
                <div class="tips2">
                  <div class="el-icon-plus" style="font-size: 28px;color: #8c939d" />
                </div>
              </template>
            </UmUploadImg>
          </div>
        </el-form-item>
        <el-form-item label="组织名称" prop="enterpriseName">
          <el-input v-model="formData.enterpriseName" placeholder="请输入组织名称" maxlength="50" />
        </el-form-item>
        <el-form-item label="所属行业" prop="enterpriseCategoryCode">
          <!--          <el-input v-model="formData.enterpriseCategoryCode" placeholder="请输入组织名称" />-->
          <el-scrollbar style="height: 80px">
            <UmIndustry v-model="formData.enterpriseCategoryCode" :list="industryList" />
          </el-scrollbar>
        </el-form-item>
        <el-form-item label="所在地区" prop="enterpriseAreaCode">
          <el-cascader
            v-model="formData.enterpriseAreaCode"
            placeholder="请选择所在地区"
            :options="allArea"
            :props="{
              value: 'areaCode',
              label: 'areaName',
              emitPath: false
            }"
            style="width: 100%"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button type="primary" @click="createEnterprise">立即创建</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { enterpriseList, create_enterprise, get_enterprise_category, getAllArea, intoEnterprise } from '@/api/user'
import UmIndustry from '@/views/login/components/UmIndustry'
import loginJoinTeam from '@/views/login/components/login-joinTeam'
export default {
  name: 'LoginChoseTeam',
  components: { UmIndustry, loginJoinTeam },
  data() {
    return {
      choseitem: {},
      showjointem: false,
      choseloading: true,
      defaultFlag: false,
      joinList: [],
      myList: [],
      allArea: [],
      visible: false,
      formData: {
        enterpriseIcon: '',
        enterpriseName: '',
        enterpriseCategoryCode: '',
        enterpriseAreaCode: ''
      },
      rules: {
        // enterpriseIcon: [{ required: true, trigger: 'change', message: '请输入必填项' }],
        enterpriseName: [{ required: true, trigger: 'change', message: '请输入必填项' }],
        enterpriseCategoryCode: [{ required: true, trigger: 'change', message: '请输入必填项' }],
        enterpriseAreaCode: [{ required: true, trigger: 'change', message: '请输入必填项' }]
      },
      industryList: []
    }
  },
  mounted() {
  },
  created() {
    this.getAllArea()
    this.getTeam()
    this.get_enterprise_category()
  },
  methods: {
    showTips(val) {
      if (val.joinTypeCode == 1) {
        this.showjointem = true
        // this.$message({
        //   type: 'info',
        //   message: '请使用APP验证并加入企业'
        // })
        this.choseitem = val
      } else {
        this.showjointem = false
        this.$message({
          type: 'info',
          message: '正在等待企业后台审核中'
        })
      }
    },
    /**
     * 选择加入企业
     * @param item
     */
    choseEnterprise(item) {
      const load = this.$load()
      intoEnterprise({
        enterpriseUuid: item.enterpriseUuid,
        defaultFlag: this.defaultFlag ? 1 : 0
      }).then(res => {
        this.$router.push('/')
      }).finally(_ => {
        load.close()
      })
    },
    async layout() {
      await this.$store.dispatch('user/logout')
      this.$emit('login')
    },
    getTeam() {
      this.choseloading = true
      enterpriseList().then(res => {
        this.joinList = res.data.joinList || []
        this.myList = res.data.myList || []
      }).finally(_ => {
        this.choseloading = false
      })
    },
    // 获取所有区域规划
    getAllArea() {
      getAllArea().then(res => {
        this.allArea = res.data
      })
    },
    // 创建企业
    createEnterprise() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const load = this.$load()
          create_enterprise({
            ...this.formData
          }).then(res => {
            this.visible = false
            this.getTeam()
          }).finally(_ => {
            load.close()
          })
        }
      })
    },
    // 获取行业列表
    get_enterprise_category() {
      get_enterprise_category().then(res => {
        this.industryList = res.data
      })
    },
    changeshowjoin(val) {
      this.getTeam()
      this.showjointem = val
    }
  }
}
</script>

<style lang="scss" scoped>
.m-logout{
  font-size: 18px;
  font-weight: 400;
  color: #69748A;
  line-height: 18px;
  margin-top: 30px;
  text-align: center;
  cursor:pointer;
}
.login_choseteam {
  height: 505px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .ctop {
    display: flex;
    // margin-bottom: 28px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    ::v-deep .el-checkbox__inner {
      border-radius: 50% !important;
    }

    ::v-deep .el-checkbox__input.is-checked {
      border-color: #3D80FC !important;
    }

    .ctitle {
      // font-size: 22px;
      font-size: 18px;
      font-weight: 500;
      color: #31415F;
    }
  }

  .ccontent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 340px;
    // height: 84px;
    width: 360px;
    height: 60px;
    padding-right: 10px;
    background: rgba(228, 232, 235, 0.4);
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    border: 1px solid transparent;;
    transition: all .2s;
    &:last-child{
      margin-bottom: 0;
    }
    &:hover {
      background: #E6E9FF;
      //box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
      border-radius: 8px;
      border: 1px solid #4C64FE;
    }
    .cleft {
      display: flex;
      align-items: center;

      .cimg {
        margin: 0 4px 0 20px;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
      }
      .compoangnodata{
        width: 32px;
        height: 32px;
        background: url("~@/assets/enterprise/companynodata.png") no-repeat center;
        background-size: 100% 100%;
      }
      .companyname {
        flex: 1;
        color: #31415F;
        max-width: 180px;
        font-size: 16px;
      }
      .joions{
        max-width: 240px!important;
      }
      .enterjoions{
        max-width: 250px;
      }
      .verify{
        max-width: 225px;
      }
      .nocodes{
        max-width: 260px;
      }
      .cicon {
        flex-shrink: 0;
        margin-left: 4px;
        width: 16px;
        height: 16px;
        //background: url("~@/assets/login/moren.png") no-repeat 0 0 / 16px 16px;
        background: url("~@/assets/login/moren-new.png") no-repeat 0 0 / 16px 16px;
      }
    }

    .zhuangtai {
      display: flex;
      align-items: center;

      .text {
        font-size: 14px;
      }
    }

    .daiyanz {
      color: #FF6161;
    }

    .daishenhe {
      color: #F69952;
    }

    .righticon {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background: url("~@/assets/login/more.png") no-repeat 0 0 / 20px 20px;;
    }
  }
  .ccontent-default{
    position: relative;
    background: #E6E9FF;
    //box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
    border-radius: 8px;
    .u-default{
      width: 36px;
      height: 18px;
      border-top-right-radius: 9px;
      border-bottom-left-radius: 9px;
      background-color: #4C64FE;
      position: absolute;
      right: -1px;
      top: -1px;
      color: #FFFFFF;
      font-size: 12px;
      text-align: center;
      line-height: 18px;
    }
  }

  .ccontent_checked {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #3D80FC;
  }

  .emptycontent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 340px;
    height: 300px;
  }

  ::v-deep .el-scrollbar .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  ::v-deep .emptycontent {
    height: 110px;
    color: #31415F;
  }

  .btn {
    margin-top: 20px;

    font-weight: 500;
    font-size: 18px;
    .shadow-but{
      width: 360px;
      height: 48px;
      line-height: 48px;
      background: #F7F8F9;
      border-radius: 8px;
      color: #4C64FE ;
    }

    .btnoicn {
      //display: inline-block;
      //vertical-align: sub;
      width: 22px;
      height: 22px;
      //background: url("~@/assets/login/add-round.png") no-repeat 0 0 / 22px 22px;
      margin-right: 4px;
      font-size: 22px;
      line-height: 25px;
    }
  }
}
::v-deep{
  .el-scrollbar__wrap
  {
    overflow-x: hidden;
  }
}
.tips{
  width: 90px;
  height: 90px;
  ::v-deep {
    .el-upload--picture-card, .el-upload, .custom_img {
      width: 90px;
      height: 90px;
      border-radius: 4px;
      background: #FFFFFF;
      border: 1px solid #D8DCE6;
    }
    //.custom_img {
    //  left: 70px;
    //}
  }
  .tips2{
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    //.imgicon{
    //  cursor: pointer;
    //  width: 90px;
    //  height: 90px;
    //  background: url("~@/assets/Developer/icon.png") no-repeat center/90px auto;
    //}
  }
}
</style>
