<template>
  <div class="chat-box">
    <!--    聊天区域头信息-->
    <div v-if="selectPerson&&Object.keys(selectPerson).length" class="main">
      <div class="chat-top row-one">
        {{ selectPerson.name }}
      </div>
      <!--      聊天消息区域-->
      <div class="chat-area">
        <el-scrollbar ref="scroll" style="height: 100%">
          <div ref="chat" class="chat-main">
            <!--            动态渲染消息类型（人聊天， 新闻， 公告）-->
            <component :is="selectPerson.messageType" />
          </div>
        </el-scrollbar>
      </div>
      <!--      消息发送区域-->
      <div class="chat-bottom">
        <send ref="send" @send="sendMessage" />
      </div>
    </div>
    <div v-else style="height: 100%;width: 100%">
      1
    </div>
  </div>
</template>

<script>
import chatPerson from '@/components/UmIM/chat/chatPerson'
import { mapState } from 'vuex'
import send from '@/components/UmIM/send'
export default {
  name: 'ChatMain',
  components: { send, chatPerson },
  computed: {
    ...mapState({
      selectPerson: state => state.im.selectPerson
    })
  },
  data() {
    return {
    }
  },
  methods: {
    /**
     * 触发消息发送
     */
    sendMessage() {
      this.scrollBottom()
    },
    /**
     * 让聊天区域滚动到最下面
     */
    scrollBottom() {
      //
      this.$refs.scroll.wrap.scrollTop = this.$refs.chat.clientHeight
    }
  }
}
</script>

<style scoped lang="scss">
.chat-box {
  width: 100%;
  height: 100%;
  .main {
    .chat-top {
      width: 798px;
      height: 68px;
      padding: 0 24px;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      color: #31415f;
      line-height: 68px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      //box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.1);
    }
    .chat-area {
      width: 798px;
      height: 487px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      .chat-main {
        width: 100%;
        height: 3000px;
      }
    }
    .chat-bottom {
      width: 798px;
      height: 173px;
    }
  }
}
</style>

<style scoped lang="scss">
::v-deep .el-scrollbar__wrap{
  overflow-x: hidden;
}
</style>
