<template>
  <div class="g-order">
    <el-drawer
      title="确认订单信息"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="order_centerBox">
        <div class="centerBox_label flex-between">
          <div class="label-title">应用名称</div>
          <div>{{ orderInfo.appName ||'-' }}</div>
        </div>
        <div class="centerBox_label flex-between">
          <div class="label-title">使用组织</div>
          <div>{{ $store.getters.enterpriseName }}</div>
        </div>
        <div class="centerBox_label  flex-between mb20">
          <div class="label-title">可见范围</div>
          <div class="flex ">
            <UmBusUser ref="chosepart" v-model="department" :default-root="true" :type="1" title="选择可见范围" :show-prefix-button="false" @change="change">
              <template #user="{labelCustom}">
                <span>  {{ organScopeUuids.length>0?labelCustom:'请选择可见范围' }}</span>
              </template>
            </UmBusUser>
            <div class="pointer ml-10 flex-center" @click="getRange">
              <img src="@/assets/dashboard/visibleRange.png" alt="">
            </div>
          </div>
        </div>
        <div class="flex-column-center mb10">
          <div class="flex-column-center"><img src="@/assets/dashboard/paymentMethod.png" alt=""></div>
          <div class="u-permission">获取权限</div>
        </div>
        <p class="u-jurisdiction">
          获取通讯录信息 <br>
          获取通讯录内用户信息 <br>
          获取个人姓名、头像信息 <br>
        </p>
      </div>
      <div class="order_bottomBox">
        <div class="bottomBox_agreement">
          <el-checkbox v-model="flag" :label="true" class="radio" @click="getCheck()">  <div class="ml10 s-grayColor">同意<a class="s-blueColor" @click="dialogFlag=true">优客云用户使用协议、隐私协议</a></div></el-checkbox>
        </div>
        <div class="bottomBox_placeOrder flex">
          <div class="flex-center leftBox">
            总计
            <span class="u-money">{{ price }}</span>
            元
          </div>
          <div class="u-btn flex-center pointer" @click="submit">
            提交订单
          </div>
        </div>
      </div>
    </el-drawer>
    <to-be-paid ref="payment" :order-info="orderInfo" :drawer-flag="drawerFlag" :money="price" :tab-type="2" :platform-order-number="platformOrderNumber" :business-order-uuid="businessOrderUuid" @changeShow="changeShow" />
    <agreement :dialog-flag="dialogFlag" @agreementFlag="agreementFlag" />
  </div>
</template>
<script>
import { getConfirmOrder, getPlaceOrder, getImmediatePayment } from '@/api/cloudMarket'
import ToBePaid from './toBePaid.vue'
import agreement from './agreement.vue'
export default {
  components: { ToBePaid, agreement },
  props: {
    orderOneFlag: {
      type: Boolean,
      default: false
    },
    appUuids: {
      type: [Number, String],
      default: ''
    },
    price: {
      type: [Number, String],
      default: 0
    },
    // 规格id
    specificationsId: {
      type: [Number, String],
      default: null
    }

  },
  data() {
    return {
      drawer: false,
      department: [],
      flag: false,
      orderInfo: {},
      drawerFlag: false,
      platformOrderNumber: null,
      ewmUrl: '',
      organScopeUuids: [],
      dialogFlag: false,
      businessOrderUuid: ''
    }
  },
  watch: {
    orderOneFlag: {
      handler(val) {
        console.log(val)
        this.drawer = val
      },
      immediate: true
    },
    platformOrderNumber: {
      handler(val) {
        // eslint-disable-next-line vue/no-mutating-props
        if (val) this.platformOrderNumber = val
      },
      immediate: true
    }

  },
  mounted() {
    // this.getConfirmOrder()
  },
  methods: {
    change(val) {
      this.organScopeUuids = val
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.$emit('changeOrderOneFlag', 'false')
          this.flag = false
          this.organScopeUuids = []
          this.department = []
        })
        .catch(_ => {})
    },
    getRange() {
      this.$refs.chosepart.openModal()
    },
    getCheck() {
      this.flag = !this.flag
    },
    // 获取确认订单信息
    getConfirmOrder() {
      getConfirmOrder({ appUuids: [this.appUuids] }).then(res => {
        this.orderInfo = res.data.orders[0] || {}
      })
    },
    // 提交订单
    async  submit() {
      if (this.flag === false) {
        this.$message.error('请先勾选协议')
      } else {
        const load = this.$load()
        if (this.organScopeUuids.length > 0) {
          const params = {
            orders: [{
              appUuid: this.appUuids,
              epEnterpriseUuid: this.orderInfo.epEnterpriseName ? this.orderInfo.epEnterpriseName : '',
              appScope: null,
              specifications: this.specificationsId,
              permissions: this.orderInfo.permissions ? this.orderInfo.permissions.map(item => { return item.code }) : [],
              organScopeUuids: this.organScopeUuids
            }]
          }
          // 应用中心-提交订单
          try {
            await getPlaceOrder(params).then(res => {
              this.platformOrderNumber = res.data.orderUuid
              this.businessOrderUuid = res.data.businessOrderUuid
            })
          } catch {
            load.close()
            return false
          }

          this.$refs.payment.getQueryPaymentMethod()
          this.$refs.payment.getPayOrderInfo()
          this.drawerFlag = true
          load.close()
        } else {
          this.$message.error('请选择可见范围')
          load.close()
        }
      }
    },
    changeShow(data) {
      if (data === 'false') {
        this.drawerFlag = false
      } else {
        this.drawerFlag = true
      }
    },
    // 关闭协议
    agreementFlag(data) {
      if (data === 'false') {
        this.dialogFlag = false
      } else {
        this.dialogFlag = true
      }
    },
    getClose() {
      console.log('关闭')
      this.$emit('changeOrderOneFlag', 'false')
      this.flag = false
      this.organScopeUuids = []
      this.department = []
      this.$parent.getDetail()
    }
  }
}
</script>

