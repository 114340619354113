import request from '@/utils/request'
const baseURL = 'http://api.pm.youmatech.com/mock/797'

// 1【组织授权】获取人员组织权限树（被授权人权限树）
export function get_user_organ_auth_tree(params) {
  return request({
    url: '/api/v1/organ/user/permission/organ/get_user_organ_auth_tree',
    method: 'get',
    // baseURL,
    params
  })
}

// 2【组织授权】获取授权组织权限树（授权人拥有，被授权人无的组织权限
export function get_user_add_organ_auth_tree(params) {
  return request({
    url: '/api/v1/organ/user/permission/organ/get_user_add_organ_auth_tree',
    method: 'get',
    // baseURL,
    params
  })
}

// 3【组织授权】新增组织授权
export function add_organ_auth_submit(data) {
  return request({
    url: '/api/v1/organ/user/permission/organ/add_organ_auth_submit',
    method: 'post',
    // baseURL,
    data
  })
}

// 4【组织授权】去除组织权限
export function remove_organ_auth_submit(data) {
  return request({
    url: '/api/v1/organ/user/permission/organ/remove_organ_auth_submit',
    method: 'post',
    // baseURL,
    data
  })
}

// 1【应用授权】获取人员应用权限列表
export function get_user_app_manage_auth(params) {
  return request({
    url: '/api/v1/organ/user/permission/app_manage/get_user_app_manage_auth',
    method: 'get',
    // baseURL,
    params
  })
}

// 2【应用授权】保存人员应用授权
export function update_user_app_manage_auth(data) {
  return request({
    url: '/api/v1/organ/user/permission/app_manage/update_user_app_manage_auth',
    method: 'post',
    // baseURL,
    data
  })
}

// 1【项目授权】获取被授权人已经拥有项目权限反查组织树
export function get_user_project_auth_organ_tree(params) {
  return request({
    url: '/api/v1/organ/user/permission/project/get_user_project_auth_organ_tree',
    method: 'get',
    // baseURL,
    params
  })
}

// 2【项目授权】获取被授权人在组织下的已经拥有的项目权限树
export function get_user_project_tree_by_organ(params) {
  return request({
    url: '/api/v1/organ/user/permission/project/get_user_project_tree_by_organ',
    method: 'get',
    // baseURL,
    params
  })
}

// 3【项目授权】添加项目权限 左侧组织树（授权人比被授权人多的项目权限反查的组
export function get_user_project_auth_organ_trees(params) {
  return request({
    url: '/api/v1/organ/user/permission/project/add/get_user_project_auth_organ_tree',
    method: 'get',
    // baseURL,
    params
  })
}

// 4【项目授权】添加项目权限 右侧项目树
export function get_user_project_tree_by_organs(params) {
  return request({
    url: '/api/v1/organ/user/permission/project/add/get_user_project_tree_by_organ',
    method: 'get',
    // baseURL,
    params
  })
}

// 5【项目授权】添加人员项目权限提交
export function add_user_project_auth(data) {
  return request({
    url: '/api/v1/organ/user/permission/project/add/add_user_project_auth',
    method: 'post',
    // baseURL,
    data
  })
}

// 6【项目授权】删除项目权限
export function remove_user_project_auth(data) {
  return request({
    url: '/api/v1/organ/user/permission/project/remove/remove_user_project_auth',
    method: 'post',
    // baseURL,
    data
  })
}

// 1【功能点权限】查询个人用户功能点权限树
export function sysmodule_checked_tree(params) {
  return request({
    url: '/api/v1/organ/user/sysModule/checked/tree',
    method: 'get',
    // baseURL,
    params
  })
}

// 2【功能点权限】查询个人用户功能点权限树
export function sysmodule_update_submit(data) {
  return request({
    url: '/api/v1/organ/user/sysModule/update/submit',
    method: 'post',
    // baseURL,
    data
  })
}
