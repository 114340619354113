<template>
  <div class="m_enterprise_course">
    <UmApproveEle :app-uuid="appUuid" />
    <div class="m_course_content">
      <el-button type="primary" @click="add"><span class="m_course_content_icon" /> 创建课程</el-button>
      <!-- 创建一个包含课程名称（curriculumName）、主讲人（curriculumSpeaker）、课程时间（curriculumStartTime~curriculumEndTime）、报名人数（signUpNum/signMaxNum)、状态（curriculumStatus）、操作的el-table -->
      <div class="m_course_table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%;"
        >
          <el-table-column label="课程名称" prop="curriculumName" width="370px">
            <template slot-scope="scope">
              <div class="m_course_type">
                <img class="m_course_icon-img" :src="scope.row.curriculumImageUrl" alt="">
                <div class="m_course_title row_2">{{ scope.row.curriculumName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="主讲人" prop="curriculumSpeaker" />
          <el-table-column label="课程时间">
            <template slot-scope="scope">
              {{ scope.row.curriculumStartTime|parseTime('{m}-{d} {h}:{i}') }}
              ~
              {{ scope.row.curriculumEndTime|parseTime('{m}-{d} {h}:{i}') }}
            </template>
          </el-table-column>
          <el-table-column label="报名人数" width="150px">
            <template slot-scope="scope">
              {{ scope.row.signUpNum||0 }}
              /
              {{ scope.row.signMaxNum||0 }}
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120px">
            <template slot-scope="scope">
              <span v-if="scope.row.curriculumStatus == 0" class="tag" type="success">未开始</span>
              <span v-if="scope.row.curriculumStatus == 1" class="tag tag-blue" type="warning">进行中</span>
              <span v-if="scope.row.curriculumStatus == 2" class="tag tag-gray" type="danger">已结束</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <el-button type="primary" class="m_course_btn" @click="toInfo(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="m_common_paginations">
        <pagination
          :background="false"
          style="margin-top: 20px"
          :total="tableTotal"
          :page.sync="page.pageNum"
          :limit.sync="page.pageSize"
          :layout="'total, sizes, prev, pager, next, jumper'"
          @pagination="getList('page')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  enterprisFlowEnableOrDisable,
  curriculumQuery_curriculum_list,
  flowConfigSystemGetSystemTem
} from '@/api/enterprise'
import moment from 'moment'

export default {
  name: 'EnterpriseApplicationCourse',
  filters: {
    formatDate(time) {
      if (time) return moment(time).format('MM-DD HH:mm')
      return ''
    }
  },
  props: {},
  data() {
    return {
      tableData: [],
      tableTotal: 0,
      appUuid: '',
      page: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.appUuid = this.$route.query.appUuid

    // if (this.appUuid) {
    //   this.getList()
    // }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      const load = this.$load()
      const params = {
        // page: {
        //   pageNum: 1,
        //   pageSize: 10
        // }
        page: this.page
      }
      curriculumQuery_curriculum_list(params).then(res => {
        load.close()
        this.tableData = res.data.list
        this.tableTotal = res.data.page.total
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    add() {
      this.$router.push({ path: '/course/add', query: { appUuid: this.appUuid }})
    },

    toInfo(item) {
      this.$router.push({ path: '/course/info', query: { curriculumUuid: item.curriculumUuid, appUuid: this.appUuid }})
    },
    del(item) {
      const h = this.$createElement
      this.$confirm('', {
        message: h('div', null, [
          h('p', { style: 'color:#31415F;fontSize: 16px;fontWeight: 500;' }, '是否确定删除？')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        customClass: 'message-dialog'

      }).then(() => {
        const load = this.$load()
        enterprisFlowEnableOrDisable({ appUuid: this.appUuid, statusCode: item.statusCode }).then(res => {
          this.$message({ type: 'success', message: '删除成功!' })
          load.close()
          this.getList()
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message({ type: 'info', message: '操作失败' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>
