  <template>
  <div :class="{fullscreen:fullscreen}" :style="{width:containerWidth}" class="tinymce-container">
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <!-- <div class="editor-custom-btn-container">
      <editorImage color="#1890ff" class="editor-upload-btn" @successCBK="imageSu   ccessCBK" />
    </div>-->
  </div>
</template>

<script>
import emitter from '@um/element-ui/src/mixins/emitter'
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
// import editorImage from './components/EditorImage'
import plugins from './plugins'
import toolbar from './toolbar'
import load from './dynamicLoadScript'
import axios from 'axios'
// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
import request from '@/utils/request'

export default {
  name: 'Tinymce',
  // components: { editorImage },
  mixins: [emitter],
  props: {
    id: {
      type: String,
      default: function() {
        return (
          'vue-tinymce-' +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + '')
        )
      }
    },
    // 是否只读
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'edit insert view format table'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      hasInitUrl: false, // 根据URL只会初始化一次
      source: null,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        en: 'en',
        zh: 'zh_CN',
        es: 'es_MX',
        ja: 'ja'
      }
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value(val) {
      if (!val) {
        this.$nextTick(() => {
          this.setContent('')
        })
      }
      // if (!this.hasChange && this.hasInit) {
      //   this.$nextTick(() =>
      //     window.tinymce.get(this.tinymceId).setContent(val || ''))
      // }
    },
    url(val) {
      if (val) {
        if (this.hasInit) {
          // 如果编辑器加载完毕就执行
          this.initEdit(val)
        }
      } else {
        this.$nextTick(() => {
          this.setContent('')
        })
      }
    },
    readonly(val) {
      this.destroyTinymce()
      this.init()
    }
  },
  mounted() {
    this.init()

    if (this.url && this.hasInit) {
      this.initEdit(this.url)
    }
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    initEdit(url) {
      // var config = {
      //   method: 'get',
      //   url: 'http://121.89.199.211/resources/business_76/2020-06-23/375235d7-d4c2-4081-a6aa-ec59d74e7d23/f55cd170-dcb3-4b69-9983-fa6a56066269.html',
      //   headers: { }
      // }
      //
      // axios(config)
      //   .then(function(response) {
      //
      //   })
      //   .catch(function(error) {
      //
      //   })
      // if (this.hasInitUrl) return false
      // this.hasInitUrl = !this.hasInitUrl
      // axios 方法

      this.source && this.source.cancel()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.source = source

      const request = axios.create()
      request({
        url: url,
        type: 'GET',
        cancelToken: source.token,
        responseType: 'text'
      })
        .then((d) => {
          if (d.status === 200) {
            this.content = d.data
            // setTimeout(()=>{
            this.setContent(d.data)
            // },1000)
          }
        })
        .catch((e) => {
          this.setContent('')
        })
    },
    init() {
      this.initTinymce()
      // dynamic load tinymce from cdn
      // load(tinymceCDN, (err) => {
      //   if (err) {
      //     this.$message.error(err.message)
      //     return
      //   }
      //   this.initTinymce()
      // })
    },
    initTinymce() {
      const _this = this
      window.tinymce.init({
        elementpath: false, // add 元素路径不显示
        selector: `#${this.tinymceId}`,
        branding: false,
        automatic_uploads: false,
        // images_upload_url: '/system/upload/resource/v2',
        language: this.languageTypeList['zh'],
        height: this.height,
        readonly: _this.readonly,
        statusbar: true,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        menubar: this.menubar,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        // imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: (editor) => {
          // if (_this.value) {
          //   editor.setContent(_this.value)
          // } else
          if (_this.url) {
            _this.initEdit(_this.url)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
            this.dispatch('ElFormItem', 'el.form.change', editor.getContent())
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
        },
        // it will try to keep these URLs intact
        // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
        // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
        convert_urls: false,
        // 整合七牛上传
        // images_dataimg_filter(img) {
        //   setTimeout(() => {
        //     const $image = $(img);
        //     $image.removeAttr('width');
        //     $image.removeAttr('height');
        //     if ($image[0].height && $image[0].width) {
        //       $image.attr('data-wscntype', 'image');
        //       $image.attr('data-wscnh', $image[0].height);
        //       $image.attr('data-wscnw', $image[0].width);
        //       $image.addClass('wscnph');
        //     }
        //   }, 0);
        //   return img
        // },
        images_upload_handler(blobInfo, success, failure, progress) {
          progress(0)
          // const token = _this.$store.getters.token
          const formData = new FormData()
          formData.append('files', blobInfo.blob())
          request({
            // url: '/pzhgc-p/system/upload/resource/v2',
            url: '/api/v1/file_manage/file/upload',
            method: 'post',
            data: formData
          })
            .then((res) => {
              progress(100)
              // if (process.env.NODE_ENV === 'development') {
              //   success(res.data.fileFullPath) // todo 上线之后需要修改为绝对路径
              // } else {
              success(res.data.fileFullPath) // todo 上线之后需要修改为绝对路径
              // }
            })
            .catch((e) => {
              failure('出现未知问题，刷新页面，或者联系程序员')
            })
          // getToken(token).then(response => {
          //   const url = response.data.qiniu_url
          //   const formData = new FormData()
          //   formData.append('token', response.data.qiniu_token)
          //   formData.append('key', response.data.qiniu_key)
          //   formData.append('file', blobInfo.blob(), url)
          //   upload(formData).then(() => {
          //     success(url)
          //     progress(100)
          //   })
          // }).catch(err => {
          //   failure('出现未知问题，刷新页面，或者联系程序员')
          //
          // })
        }
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      const _this = this
      arr.forEach((v) => {
        window.tinymce
          .get(_this.tinymceId)
          .insertContent(`<img class="wscnph" src="${v.url}" >`)
      })
    }
  }
}
</script>

<style scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}

.tinymce-container >>> .mce-fullscreen {
  z-index: 10000;
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
</style>
