<template>
  <div class="m_enterprise_courseadd">
    <div class="go_back_common">
      <div class="go_back_title" @click="back">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="m_courseadd_formBody">
      <div class="title-dh mb20 mt20">基本信息</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" label-width="110px">
        <el-form-item ref="curriculumImageUrl" label="课程图片" prop="curriculumImageUrl">
          <div class="m_courseadd_tips">
            <UmUploadImg v-model="ruleForm.curriculumImageUrl" :show-icon="false" custom-file :limit="1" is-cropper :cropper-options="cropperOptions">
              <template #extend>
                <div class="m_courseadd_tips2">
                  <div class="m_courseadd_imgicon" />
                </div>
              </template>
            </UmUploadImg>
          </div>
        </el-form-item>
        <el-form-item ref="curriculumName" label="课程名称" prop="curriculumName">
          <el-input v-model="ruleForm.curriculumName" style="width:40%" placeholder="请填写课程名称" maxlength="50" />
        </el-form-item>
        <el-form-item ref="curriculumSpeaker" label="主讲人" prop="curriculumSpeaker">
          <el-input v-model="ruleForm.curriculumSpeaker" style="width:40%" placeholder="请填写主讲人" maxlength="50" />
        </el-form-item>
        <el-form-item ref="curriculumAddress" label="课程地点" prop="curriculumAddress">
          <el-input v-model="ruleForm.curriculumAddress" style="width:40%" placeholder="请填写课程地点" maxlength="50" />
        </el-form-item>
        <el-form-item label="报名时间" prop="time2">
          <el-date-picker
            v-model="ruleForm.time2"
            type="datetimerange"
            style="width: 40%"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            @change="pickerChangeBm"
          />
        </el-form-item>
        <el-form-item label="课程时间" prop="time1">
          <el-date-picker
            v-model="ruleForm.time1"
            type="datetimerange"
            style="width: 40%"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            @change="pickerChangeKc"
          />
        </el-form-item>
        <el-form-item ref="signMaxNum" label="人数限制" prop="signMaxNum">
          <div class="m_courseadd_peopleNum">
            <span class="m_courseadd_add m_courseadd_ninus" :class="{add_disabled:ruleForm.signMaxNum<=1}" @click="minusNum">-</span>
            <div><el-input v-model="ruleForm.signMaxNum" style="width: 48px" :clearable="false" @change="resetnum" /></div>
            <span class="m_courseadd_add" :class="{add_disabled:ruleForm.signMaxNum>=9999}" @click="addNum">+</span>
          </div>
          <!--            <el-input-number v-model="ruleForm.signMaxNum" :min="1" :max="9999" size="mini" />-->
        </el-form-item>
        <el-form-item ref="curriculumDescribe" label="详细内容" prop="curriculumDescribe">
           <um-edit-pro v-model="ruleForm.curriculumDescribe"  />
          <!-- <Tinymce
            v-model="ruleForm.curriculumDescribe"
            :height="200"
            :width="'98%'"
          /> -->
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" class="m_courseadd_btnsure" @click="submit">确认保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { curriculumAdd_curriculum } from '@/api/enterprise'
import moment from 'moment'

export default {
  name: 'EnterpriseApplicationCourseAdd',
  props: {},
  data() {
    const validateTime = (rule, value, callback) => {
      console.log(value, 'value--------------')
      if ((!value) || (!value.length)) {
        callback(new Error('请选择报名时间段'))
      } else if (value && value.length) {
        const time1 = Date.parse(value[1])
        const time2 = Date.now()
        if (time1 < time2) {
          callback(new Error('报名结束时间不可早于当前时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateTime1 = (rule, value, callback) => {
      if ((!value) || (!value.length)) {
        callback(new Error('请选择课程时间段'))
      } else if (value && value.length) {
        const time1 = Date.parse(value[0])
        const time2 = Date.now()
        const time3 = Date.parse(value[1])
        const time4 = Date.parse(this.ruleForm.time2[1])
        if (time1 < time2) {
          callback(new Error('课程开始时间不可早于当前时间'))
        } else
        if (time3 < time4) {
          callback(new Error('课程结束时间不可早于报名结束时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        curriculumImageUrl: '',
        curriculumName: '',
        curriculumAddress: '',
        roomOpenDay: [],
        applyStartTime: null,
        applyEndTime: null,
        curriculumStartTime: null,
        curriculumEndTime: null,
        time1: [],
        time2: [],
        curriculumDescribe: null,
        signMaxNum: 1
      },
      pickerOptionsBm: {
        // 新增时报名结束结束时间不可早于当前时间，课程报名结束时间不可早于课程报名开始时间，课程开始时间不可早于当前时间，课程结束时间不可早已课程开始时间，课程结束时间不可早于课程报名结束时间
        disabledDate(time) {
          // return time.getTime() < Date.now()
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      pickerOptionsKc: {
        disabledDate(time) {
          // return time.getTime() < Date.now()
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      rules: {
        curriculumDescribe: [{ required: true, message: '请填写详细内容', trigger: 'change' }],
        curriculumImageUrl: [{ required: true, message: '请选择课程图片', trigger: 'change' }],
        curriculumName: [
          { required: true, message: '请填写名称', trigger: 'blur' }
        ],
        curriculumSpeaker: [
          { required: true, message: '请填写主讲人', trigger: 'change' }
        ],
        curriculumAddress: [
          { required: true, message: '请填写地址', trigger: 'change' }
        ],
        roomOpenDay: [
          { required: true, message: '请选择开放时间', trigger: 'change' }
        ],
        time1: [
          // { required: true, message: '请选择预定时间段', trigger: 'change' }
          { required: true, validator: validateTime1, trigger: 'change' }
        ],
        time2: [
          // { required: true, message: '请选择预定时间段', trigger: 'change' }
          { required: true, trigger: 'change', validator: validateTime }
        ],
        signMaxNum: [
          { required: true, message: '请选择人数', trigger: 'change' }
        ]
      },
      cropperOptions: {
        aspectRatio: 4 / 3
      }
    }
  },
  watch: {
    // 'ruleForm.time2': {
    //   immediate: true,
    //   handler(val) {
    //     if (!val) {
    //       this.pickerOptionsKc = {
    //         // 今天前的时间不可选中
    //         disabledDate(time) {
    //           return time.getTime() < Date.now() - 8.64e7
    //         }
    //       }
    //     }
    //     if (val && val.length) {
    //       console.log(val)
    //       // this.pickerOptions = {}
    //       // const start1 = val[0].slice(0, 10)
    //       const start1 = val[0]
    //       // const end1 = val[1].slice(0, 10)
    //       const end1 = val[1]
    //       this.pickerOptionsKc = {
    //         // 今天前的时间不可选中
    //         disabledDate(time) {
    //           return (
    //             time.getTime() < Date.parse(start1) ||
    //             time.getTime() < Date.parse(end1)
    //           )
    //         }
    //       }
    //       // this.key1++
    //     }
    //   },
    //   deep: true
    // }
  },
  created() {},
  mounted() {},
  methods: {
    pickerChangeKc(data) {
      console.log(data, 'data')
      if (data && data.length) {
        this.ruleForm.curriculumStartTime = data[0]
        this.ruleForm.curriculumEndTime = data[1]
      } else {
        this.ruleForm.curriculumStartTime = null
        this.ruleForm.curriculumEndTime = null
      }
      console.log(this.ruleForm.curriculumStartTime)
    },

    pickerChangeBm(date) {
      if (date && date.length) {
        this.ruleForm.applyStartTime = date[0]
        this.ruleForm.applyEndTime = date[1]
      } else {
        this.ruleForm.applyStartTime = null
        this.ruleForm.applyEndTime = null
      }
    },

    changeDate(value) {
      const checkedCount = value.length

      this.checkAll = checkedCount === this.dateValue.length
      console.log(this.checkAll)
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.dateValue.length
      console.log(this.isIndeterminate)
    },
    handleCheckAllChange(val) {
      this.ruleForm.roomOpenDay = val ? this.dateValue : []
      this.isIndeterminate = false
    },
    back() {
      console.log(this.$route.appUuid)
      this.$router.replace({
        path: '/course',
        query: {
          appUuid: this.$route.query.appUuid
        }
      })
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const load = this.$load()
          try {
            const params = {
              ...this.ruleForm
            }
            params.curriculumStartTime = moment(params.curriculumStartTime).format('YYYY-MM-DD HH:mm:ss')
            params.curriculumEndTime = moment(params.curriculumEndTime).format('YYYY-MM-DD HH:mm:ss')
            params.applyStartTime = moment(params.applyStartTime).format('YYYY-MM-DD HH:mm:ss')
            params.applyEndTime = moment(params.applyEndTime).format('YYYY-MM-DD HH:mm:ss')
            await curriculumAdd_curriculum({
              ...params
            })

            this.$alert('保存成功！', '提示', {
              type: 'success',
              showClose: false
            }).then(d => {
              this.back()
            })
          } catch (e) {
            this.$errorHandle(e)
          } finally {
            load.close()
          }
        }
      })
    },
    addNum() {
      if (this.ruleForm.signMaxNum < 9999) {
        this.ruleForm.signMaxNum = this.ruleForm.signMaxNum * 1 + 1
      }
    },
    resetnum(val) {
      console.log(val)
      if (val * 1 >= 9999) {
        this.ruleForm.signMaxNum = 9999
      }
      if (val * 1 <= 1) {
        this.ruleForm.signMaxNum = 1
      }
      if (!/^\d+$|^\d+[.]?\d+$/.test(val)) {
        this.ruleForm.signUserMaxNum = 1
      }
    },
    minusNum() {
      if (this.ruleForm.signMaxNum > 1) {
        this.ruleForm.signMaxNum = this.ruleForm.signMaxNum * 1 - 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
