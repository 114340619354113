import Layout from '@/layout'
const flowRouter = {
  path: '/flow',
  component: Layout,
  name: 'Flow',
  redirect: '/flow/config',
  meta: { level: 1 },
  children: [
    {
      path: '/flow/config',
      component: () => import('@/views/flow/config'),
      name: 'FlowConfig',
      meta: { title: '审批流配置', cache: false, activeMenu: '/dashboard', group: 'Flow', level: 2 }
    }
  ]
}
export default flowRouter
