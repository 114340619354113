/**
 * @Description:
 * @author xutao
 * @date $
*/
<template>
  <div>
    <el-input
      v-model="position"
      :placeholder="placeholder"
      type="text"
      style="width: 100%;"
      :maxlength="maxlength"
      :disabled="disabled"
      :readonly="readonly"
    >
      <template slot="suffix">
        <span
          style="margin-right: 10px;color:#3D80FC;font-size: 14px;cursor: pointer"
          @click="getLocation"
        >获取</span>
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'UmPosition',
  model: {
    event: 'change',
    prop: 'value_'
  },
  props: {
    value_: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 50
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {

    }
  },
  computed: {
    position() {
      return this.value_
    }
  },
  methods: {
    getLocation() {
      const self = this

      // eslint-disable-next-line no-undef
      AMap.plugin('AMap.Geolocation', function() {
        // eslint-disable-next-line no-undef
        const geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 5000
        })

        geolocation.getCurrentPosition(function(status, result) {
          if (status === 'complete') {
            onComplete(result)
          } else {
            onError(result)
          }
        })
        function onComplete(data) {

        }
        function onError(data) {
          // 定位出错
          self.getLngLatLocation()
        }
      })
    },
    getLngLatLocation() {
      const self = this
      // eslint-disable-next-line no-undef
      AMap.plugin('AMap.CitySearch', function() {
        // eslint-disable-next-line no-undef
        const citySearch = new AMap.CitySearch()
        citySearch.getLocalCity(function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // 查询成功，result即为当前所在城市信息

            // 逆向地理编码
            // eslint-disable-next-line no-undef
            AMap.plugin('AMap.Geocoder', function() {
              // eslint-disable-next-line no-undef
              const geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode
              })

              const lnglat = result.rectangle.split(';')[0].split(',')
              geocoder.getAddress(lnglat, function(status, data) {
                if (status === 'complete' && data.info === 'OK') {
                  // result为对应的地理位置详细信息

                  self.$emit('change', data.regeocode.formattedAddress)
                }
              })
            })
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
