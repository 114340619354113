<template>
  <div class="slideBar-con">
    <!--    顶部搜索-->
    <div class="top-search">
      <el-autocomplete
        v-model="searchName"
        class="search"
        :fetch-suggestions="querySearchAsync"
        placeholder="搜索"
        prefix-icon="el-icon-search"
        @select="handleSelect"
      />
      <div class="add" @click="visible=true" />
    </div>

    <!--    消息列表-->
    <div class="message-list">
      <el-scrollbar style="height: 100%">
        <div class="message-main">
          <messageList />
        </div>
      </el-scrollbar>
    </div>

    <!--    创建聊天-->
    <createChat :visible.sync="visible" />
  </div>
</template>

<script>
import createChat from './createChat'
import messageList from '@/components/UmIM/messageList'
export default {
  name: 'SlideBar',
  components: { messageList, createChat },
  data() {
    return {
      searchName: '',
      visible: false
    }
  },
  methods: {
    /**
     * 搜索远程
     * @param queryString
     * @param cb
     */
    querySearchAsync(queryString, cb) {

    },
    /**
     * 远程搜索单项点击
     * @param item
     */
    handleSelect(item) {
    }
  }
}
</script>

<style scoped lang="scss">
.slideBar-con {
  width: 100%;
  .top-search {
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    .search {
      width: 228px;
    }
    .add {
      cursor: pointer;
      margin-left: 12px;
      width: 36px;
      height: 36px;
      background: url("~@/assets/im/add.png") center no-repeat;
      background-size: 100% 100%;
    }
  }
  .message-list {
    width: 100%;
    height: 660px;
    .message-main {
      width: 100%;
      height: 3000px;
    }
  }
}

</style>

<style scoped lang="scss">
::v-deep .el-scrollbar__wrap{
  overflow-x: hidden;
}
::v-deep .el-input__inner {
  border: none;
  width: 100%;
  height: 36px;
  background: #f6f7fa;
  border-radius: 6px;
  &::-webkit-input-placeholder{
    color:#919EB6;
    font-size:12px;
  }
}
::v-deep .el-input__prefix {
  .el-input__icon {
    color:#919EB6;
    font-size:12px;
    //margin-top: 2px;
    &:after {
      vertical-align: bottom;
    }
  }
}
</style>
