<template>
  <EnterpriseLayout>
    <div class="m_enterprise_addperson">
      <div class="m_addperson_head" @click="back">
        <span class="m_addperson_headicon" />
        <span class="m_addperson_headtxt">返回</span>
      </div>
      <div class="m_addperson_lines" />
      <div class="m_addperson_formbody">
        <el-scrollbar style="height: 100%">
          <div style="margin-left: 10px">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="110px" class="demo-ruleForm" label-position="left">
              <div class="headimg">
                <el-form-item label="用户头像" prop="userHeadImageUrl" style="height: 90px;">
                  <div class="m_addperson_edittop">
                    <div class="m_addperson_avatarup">
                      <div class="m_addperson_tips">
                        <UmUploadImg v-model="ruleForm.userHeadImageUrl" :show-icon="false" custom-file :limit="1" :simple="true" type="avatar">
                          <template #extend>
                            <div class="m_addperson_tips2">
                              <div class="m_addperson_imgicon" />
                            </div>
                          </template>
                        </UmUploadImg>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div class="l-form">
                <el-form-item label="用户姓名" prop="userName">
                  <el-input v-model="ruleForm.userName" placeholder="请填写姓名" maxlength="10" clearable />
                </el-form-item>
                <el-form-item label="用户别名" prop="userAlias">
                  <el-input v-model="ruleForm.userAlias" placeholder="请填写别名" clearable maxlength="10" />
                </el-form-item>
                <el-form-item label="用户账号" prop="userLoginName">
                  <el-input v-model="ruleForm.userLoginName" placeholder="请填写账号" clearable maxlength="50" />
                </el-form-item>
                <el-form-item label="用户性别" prop="userSexCode">
                  <el-radio-group v-model="ruleForm.userSexCode">
                    <el-radio label="1040531">男</el-radio>
                    <el-radio label="1040532">女</el-radio>>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="m_addperson_form">
                <el-form-item label="手机号码" prop="userMobile">
                  <el-input v-model="ruleForm.userMobile" placeholder="请填写手机号" maxlength="11" clearable />
                </el-form-item>
                <el-form-item label="邮箱账号" prop="userEmail">
                  <el-input v-model="ruleForm.userEmail" placeholder="请填写邮箱" clearable maxlength="50" />
                </el-form-item>
                <el-form-item label="用户角色" prop="orgRoleId">
                  <el-cascader
                    v-model="ruleForm.orgRoleId"
                    placeholder="请选择角色"
                    :options="classifyList"
                    filterable
                    :show-all-levels="false"
                    :props="{
                      children: 'roleList',
                      label: 'roleName',
                      value: 'orgRoleId',
                      emitPath: false
                    }"
                  />
                </el-form-item>
                <el-form-item label="所属部门" prop="department" style="height: auto;">
                  <span class="m_addperson_parment">
                    <UmBusUser ref="chosepart" v-model="ruleForm.department" :type="1" :auth-type="1" title="选择成员所在部门" :show-prefix-button="false" @result="resultChange">
                      <template #user="{data}">
                        <span v-if="data&&data.length" class="m_addperson_itemcontent">
                          <span v-for="item in data" :key="item.id" class="m_addperson_texts" @mouseover="mouseover(item.id)" @mouseout="mouseout">
                            <div class="m_addperson_mains" :class="{m_addperson_itemactive:item.id==maincode,m_addperson_mainss:item.id==choseid}" @click="setmain(item)" />
                            {{ item.name }}
                            <span class="m_addperson_delicon" :class="{m_addperson_delicons:item.id==choseid}" @click="delmain(item)" />
                          </span>
                          <span class="m_addperson_xiugai" @click="changpart">修改</span>
                        </span>
                        <span v-else>
                          <span class="m_addperson_weishezhi">未设置</span>
                          <span class="m_addperson_xiugai" @click="changpart">修改</span>
                        </span>

                      </template>
                    </UmBusUser>
                  </span>
                </el-form-item>
                <el-form-item label="部门负责人" prop="departmentHead" style="height: auto">
                  <span v-if="departmentHead.length">
                    <el-checkbox-group v-model="ruleForm.departmentHead">
                      <el-checkbox v-for="item in departmentHead" :key="item.id" :label="item.id">
                        {{ item.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </span>
                  <span v-else class="m_addperson_weishezhi">未设置</span>
                </el-form-item>
                <el-form-item label="用户职务" prop="userPosition">
                  <el-input v-model="ruleForm.userPosition" placeholder="请填写职务" clearable maxlength="10" />
                </el-form-item>
                <el-form-item label="直属上级" prop="directSuperior">
                  <span class="m_address_umbususer">
                    <UmBusUser
                      ref="UmBusUser"
                      v-model="ruleForm.directSuperior"
                      :type="2"
                      search-tip="请输入成员姓名"
                      title="选择成员直属上级"
                      :show-prefix-button="false"
                    >
                      <template #user="{data}">
                        <span class="m_addperson_upname">
                          <span v-if="data&&data.length">
                            <span v-for="(item,index) in data" :key="item.id">
                              {{ item.name }}
                              <span v-if="index!=data.length-1">/</span>
                            </span>
                          </span>
                          <span v-else>未设置</span>
                        </span>
                      </template>
                    </UmBusUser>
                    <div class="m_addperson_uset" @click="changperson">设置</div>
                  </span>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm">保存并继续添加</el-button>
                  <el-button type="primary" @click="saveForm">保存</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </EnterpriseLayout>
</template>

<script>
import { add_user_submit, get_org_role_by_org_business_id } from '@/api/enterprise'
import { deepClone } from '@/utils'
import { validEmail } from '@/utils/validate'
export default {
  name: 'EnterpriseAddressAdd',
  components: {},
  props: {},
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value) {
        if (!validEmail(value)) {
          callback(new Error('请输入正确的邮箱号'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写手机号'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    const validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写姓名'))
      } else if (!/^[\u4E00-\u9FA5A-Za-z0-9·]+$/.test(value)) {
        callback(new Error('姓名包含了·以外的特殊字符!'))
      } else {
        callback()
      }
    }
    return {
      orgUserUuid: '',
      departmentHead: [], // 部门负责人
      maincode: '', // 主部门
      clonedepartmentList: [],
      choseid: null,
      people: null,
      part: null,
      imgurl: '',
      choseVisible1: false,
      classifyList: [], // 角色列表
      ruleForm: {
        userHeadImageUrl: '', // 用户头像
        userName: '', // 用户姓名
        userAlias: '', // 用户别名
        userLoginName: '', // 用户账号
        userSexCode: '1040531', // 1040531男 1040532女
        userEmail: '', // 用户邮箱
        orgRoleId: '', // 用户角色
        userMobile: '', // 用户手机号
        departmentHead: [],
        department: [],
        userPosition: '', // 用户职务
        directSuperior: []
      },
      rules: {
        userName: [
          { required: true, trigger: 'blur', validator: validateName }
        ],
        userMobile: [
          { required: true, trigger: 'blur', validator: validatePhone }
        ],
        department: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ],
        orgRoleId: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        userEmail: [
          { required: false, trigger: 'blur', validator: validateEmail }
        ]
      }
    }
  },
  computed: {},
  watch: {
    'ruleForm.department': {
      handler(val) {
        if (val.length) {
          this.maincode = val[0]
        }
      },
      deep: true
    }
  },
  created() {
    this.get_classify_tree()
    if (this.$route.query) {
      const { organUuid } = this.$route.query
      this.ruleForm.department.push(organUuid)
    }
  },
  mounted() {},
  methods: {
    // 获取角色树
    get_classify_tree() {
      get_org_role_by_org_business_id().then(res => {
        this.classifyList = res.data.list
      })
    },
    resultChange(val) {
      this.departmentHead = val
      if (val) {
        if (this.ruleForm.departmentHead.length) {
          this.ruleForm.departmentHead.forEach(item => {
            const index = this.ruleForm.department.findIndex(ites => ites == item)
            if (index == -1) {
              const delindex = this.ruleForm.departmentHead.findIndex(ite => ite == item)
              this.ruleForm.departmentHead.splice(delindex, 1)
            }
          })
        }
      }
    },
    back() {
      this.orgUserUuid = ''
      this.$refs.ruleForm.resetFields()
      this.$router.replace('/enterprise/address')
    },
    mouseover(id) {
      this.choseid = id
    },
    mouseout() {
      this.choseid = null
    },
    // 设置主部门
    setmain(val) {
      this.maincode = val.id
    },
    delmain(val) {
      const index = this.ruleForm.department.findIndex(item => item == val.id)
      if (index >= 0) {
        this.ruleForm.department.splice(index, 1)
      }
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.maincode) {
            this.$message({
              type: 'warning',
              message: '请选择所属主部门!'
            })
            return
          }
          const arr = {
            organUuidList: this.ruleForm.department,
            primaryOrganUuid: this.maincode
          }
          const form = deepClone(this.ruleForm)
          form.department = arr
          const load = this.$load()
          add_user_submit({ ...form }).then(d => {
            this.$message({
              type: 'success',
              message: '创建成功!'
            })
            if (this.$route.query) {
              const { organUuid } = this.$route.query
              this.ruleForm.department.push(organUuid)
            }
            this.$refs.ruleForm.resetFields()
          }).finally(_ => {
            load.close()
          }).catch(e => {
            load.close()
            this.$errorHandle(e)
          })
        }
      })
    },
    saveForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.maincode) {
            this.$message({
              type: 'warning',
              message: '请选择所属主部门!'
            })
            return
          }
          const arr = {
            organUuidList: this.ruleForm.department,
            primaryOrganUuid: this.maincode
          }
          const form = deepClone(this.ruleForm)
          form.department = arr

          const load = this.$load()
          add_user_submit({ ...form }).then(d => {
            this.$message({
              type: 'success',
              message: '创建成功!'
            })
            this.back()
          }).finally(_ => {
            load.close()
            // this.$refs.ruleForm.resetFields()
          }).catch(e => {
            load.close()
            this.$errorHandle(e)
          })
        }
      })
    },
    changpart() {
      this.$refs.chosepart.openModal()
      this.$refs.chosepart.getList()
    },
    changperson() {
      this.$refs.UmBusUser.openModal()
      this.$refs.UmBusUser.getList()
    }
  }
}
</script>

