<template>
  <section
    class="app-main"
    :style="{
      height:$isWebsite?'auto':'100vh',
      marginTop:$isWebsite?'-50px':0
    }"
  >
    <keep-alive :include="cachedViews">
      <router-view :key="key" />
    </keep-alive>
  </section>
</template>

<script>
import microApp from '@micro-zoe/micro-app'
export default {
  name: 'AppMain',
  data() {
    return {
      microApp
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/variables";
//.app-main {
//  /* 50= navbar  50  */
//  background: #F5F7FA;
//  min-height: calc(100vh - 50px);
//  width: 100%;
//  min-width: 1260px;
//  //position: relative;
//  //overflow: hidden;
//  padding: 0;
//}
//
//.hasTagsView+.app-main {
//  padding: 20px;
//  min-height: calc(100vh - 50px);
//  //padding-top: 70px;
//  margin-top: 50px;
//}
.app-main {
  background: #F5F7FA;
  //position: absolute;
  left: $sideBarWidth;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  padding-left: 66px;
  & div:first-child {
    min-height: 100%;
  }
}
.hasTagsView+.app-main {
  //top: 50px;
  padding: 70px 20px 20px 86px;
  //overflow: auto;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
