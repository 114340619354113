<template>
  <div>
    <div v-if="!invitation" class="m-progressBar">
      <div class="progressBar_closrImg pointer">
        <img :src="require('@/assets/dashboard/icon-drawer-close.png')" alt="" width="100%" height="100%" @click="getWorkbench()">
      </div>
      <div class="progressBar_successImg flex-center">
        <img :src="require('@/assets/dashboard/success1.png')" alt="" width="140px" height="140px">
      </div>
      <div v-if="tabType==4||tabType==5" class="u-title">{{ appText }}</div>
      <div v-if="tabType==2||tabType==3" class="u-title">{{ applicationText }}</div>
      <div v-if="ordtype==1" class="m-progressBar-openbtn" @click="openapp">立即打开</div>
    </div>
    <AppQrCode :isapp-uuid="isappUuid" :invitation.sync="invitation" @changevisible="changevisible" />
  </div>
</template>
<script>
import { getPayOrderInfo } from '@/api/cloudMarket'
import { openUrl } from '@/utils/jump'
import AppQrCode from '@/views/dashboard/components/appQrcode'
export default {
  components: { AppQrCode },
  props: {
    tabType: {
      type: [String, Number],
      default: 1
    },
    // 是否显示 按钮（解决方案/购物车提交的不展示）
    ordtype: {
      type: [String, Number],
      default: 1
    },
    businessOrderUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      appUuid: '',
      appScope: '',
      appurl: '',
      invitation: false,
      isappUuid: '',
      appText: '支付成功，请到APP查看订单详情',
      applicationText: '应用安装成功，立即体验吧！'
    }
  },
  watch: {
    ordtype: {
      handler(val) {
        if (val == 1) {
          if (this.businessOrderUuid) {
            this.getPayOrderInfo()
          }
        }
      },
      immediate: true
    },
    invitation: {
      handler(val) {
        if (!val) {
          this.$parent.getClose()
        }
      }
    }
  },
  created() {
  },
  methods: {
    changevisible(val) {
      if (!val) {
        this.invitation = false
      } else {
        this.invitation = true
      }
    },
    async getWorkbench() {
      // this.$message.success('购买成功')
      const load = this.$payLoad()
      try {
        this.$parent.getClose()
        load.close()
      } catch {
        load.close()
        return false
      }

      // this.$router.go(0)

      // this.$router.push({ path: '/dashboard' })
    },
    // 查询订单信息
    getPayOrderInfo() {
      getPayOrderInfo({ businessOrderUuid: this.businessOrderUuid }).then(res => {
        this.appUuid = res.data.appUuid
        this.appScope = res.data.appScope
        this.appurl = res.data.pcJumpLink
      }).finally(_ => {
      })
    },
    openapp() {
      this.openUrl(this.appurl, this.appUuid, this.appScope)
    },
    openUrl(url, appUuid, appScope) {
      if (appScope == 1) {
        this.isappUuid = appUuid
        this.invitation = true
      } else {
        openUrl(url, { appUuid })
      }
    }
  }
}
</script>
