<template>
  <div v-if="installFlag" class="m-install flex-center">
    <div v-if="percentage<100" class="install_progressBar">
      <div class="bar_closeImg pointer" @click="getClose" />
      <div class="bar_progressImg">
        <el-progress type="dashboard" :percentage="percentage" color="#3D80FC" class="u-progress" />
        <div class="u-progressImg" />
      </div>
      <div class="bar_title">应用安装中</div>
      <div v-if="orderInfo&&orderInfo.appName" class="flex-center bar_tips">
        <div class="mr-10">正在安装</div>
        <div>{{ orderInfo.appName }}</div>
      </div>
    </div>
    <div v-else>
      <success :business-order-uuid="businessOrderUuid" :ordtype="ordtype" :tab-type="tabType" />
    </div>
  </div>
</template>
<script>
import Success from './success.vue'
export default {
  components: {
    Success
  },
  props: {
    installVisible: {
      type: Boolean,
      default: false
    },
    tabType: {
      type: [String, Number],
      default: 1
    },
    orderInfo: {
      type: Object,
      default: () => {}
    },
    ordtype: {
      type: [String, Number],
      default: 1
    },
    businessOrderUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      percentage: 0,
      installFlag: false,
      timer: null
    }
  },
  computed: {

  },
  watch: {
    percentage(val) {
      if (val >= 100) {
        this.clear()
      }
    },
    installVisible: {
      handler(val) {
        this.installFlag = val
      },
      immediate: true
    }

  },
  mounted() {
  },
  methods: {
    getStar() {
      this.timer = setInterval(() => { this.percentage += 20 }, 1000)
    },
    clear() {
      clearInterval(this.timer)
      this.timer = null
    },
    getClose() {
      this.$emit('flagChange', 'false')
      this.installFlag = false
      this.$parent.getClose()
    }
  }
}
</script>

