import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'
const dashboardRouter = {
  path: '/dashboard',
  component: Layout,
  name: 'Dashboard',
  // redirect: '/dashboard/index',
  redirect: to => {
    for (let i = 0; i < dashboardRouter.children.length; i++) {
      const { path, meta } = dashboardRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  meta: { level: 1, roles: ['11202'] },
  children: [
    {
      path: '/dashboard/index',
      component: () => import('@/views/dashboard'),
      name: 'DashboardIndex',
      meta: { title: '工作台', cache: true, group: 'dashboard', level: 2, roles: ['1120201'] }
    },
    {
      path: '/dashboard/workSearch',
      component: () => import('@/views/dashboard/workSearch'),
      name: 'DashboardWorkSearch',
      meta: { title: '工作台搜索', cache: false, group: 'dashboard', level: 3, roles: ['1120201'] }
    },
    {
      path: '/dashboard/web',
      component: () => import('@/views/dashboard/web'),
      name: 'DashboardWeb',
      meta: { title: '第三方地址', cache: true, group: 'web', level: 3, roles: ['1120201'] }
    },
    {
      path: '/dashboard/web2',
      component: () => import('@/views/dashboard/web'),
      name: 'DashboardWeb',
      meta: { title: '智慧工单', cache: true, group: 'web2', level: 3, roles: ['1120201'] }
    },
    {
      path: '/dashboard/approve',
      name: 'Approve',
      component: () => import('@/views/dashboard/approve'),
      meta: { title: '审批', cache: false, group: 'approve', level: 2, roles: ['1120201'] }
    },
    {
      path: '/dashboard/approve/add',
      component: () => import('@/views/dashboard/approve/add'),
      name: 'ApproveAdd',
      meta: { title: '发起审批', cache: false, group: 'approve', level: 3, roles: ['1120201'] }
    },
    {
      path: '/dashboard/approve/info',
      component: () => import('@/views/dashboard/approve/info'),
      name: 'ApprovalInfo',
      meta: { title: '审批详情', cache: false, group: 'approve', level: 3, roles: ['1120201'] }
    },
    {
      path: '/dashboard/myToDo',
      name: 'MyToDo',
      component: () => import('@/views/dashboard/myToDo'),
      meta: { title: '我的待办', group: 'myToDo', level: 2, roles: ['1120201'] }
    }
    //  {
    //   path: '/dashboard/newsBull',
    //   name: 'NewsBull',
    //   component: () => import('@/views/dashboard/newsBull'),
    //   meta: { title: '公告通知', group: 'newsBull', level: 2, roles: ['1120201'] }
    // },
    // {
    //   path: '/dashboard/newsBull/info',
    //   component: () => import('@/views/dashboard/newsBull/info'),
    //   name: 'NewsBullInfo',
    //   meta: { title: '公告详情', group: 'newsBull', level: 3, roles: ['1120201'] }
    // }
  ]
}
export default dashboardRouter
