<template>
  <EnterpriseLayout>
    <div class="m_enterprise_applicationmanage">
      <el-scrollbar style="height: 100%">
        <div v-for="(item,index) in list" :key="index" class="mb20">
          <div class="title-dh mb20">{{ item.typeName }}</div>
          <div class="m_applicationmanage_box">
            <div v-for="(it,ix) in item.appList" :key="ix" class="m_applicationmanage_fit" @click="add(it.sysFunctionCode,it.appUuid)">
              <div class="m_applicationmanage_item">
                <div class="m_applicationmanage_img border-r-6">
                  <img :src="it.appIconUrl" alt="">
                </div>
                <div class="m_applicationmanage_name">{{ it.appName }}</div>
              </div>
            </div>
            <div v-if="item.typeCode===2" v-permission="['11203030105']" class="m_applicationmanage_fit" @click="create">
              <div class="m_applicationmanage_item">
                <div class="m_applicationmanage_create" />
                <div class="m_applicationmanage_name">创建应用</div>
              </div>
            </div>
            <div v-if="item.typeCode!==2&&item.appList.length===0" class="m-noData">
              暂无数据
            </div>
          </div>
        </div>
      </el-scrollbar>
      <el-dialog
        title="自建应用"
        :visible.sync="innerVisible"
        width="522px"
        center
        :append-to-body="false"
      >
        <el-form
          ref="addForm"
          class="addForm"
          :model="addForm"
          label-position="left"
          label-width="120px"
          :rules="rules"
          @submit.native.prevent
        >
          <el-form-item ref="appName" label="应用名称" prop="appName">
            <el-input v-model="addForm.appName" placeholder="请输入应用名称" maxlength="50" />
          </el-form-item>
          <el-form-item ref="appIconUrl" label="应用图标" prop="appIconUrl">
            <div class="m_applicationmanage_tips">
              <um-upload-img width="88" height="88" v-model="addForm.appIconUrl" :show-icon="false" custom-file :limit="1" :simple="true" type="avatar">
                <template #extend>
                  <div class="m_applicationmanage_tips2">
                    <div class="m_tips2_imgicon" />
                  </div>
                </template>
              </um-upload-img>
            </div>
          </el-form-item>
          <el-form-item ref="appJumpLink" label="APP端H5地址" prop="appJumpLink">
            <el-input v-model="addForm.appJumpLink" :max-length="255" placeholder="请输入APP端H5地址" />
          </el-form-item>
          <el-form-item ref="pcJumpLink" label="PC端H5地址" prop="pcJumpLink">
            <el-input v-model="addForm.pcJumpLink" :max-length="255" placeholder="请输入PC端H5地址" />
          </el-form-item>
          <el-form-item ref="appUsePurview" label="应用可见范围" prop="appUsePurview" style="cursor: pointer">
            <um-bus-user ref="user" v-model="addForm.appUsePurview" :show-tab="false" :show-prefix-button="false" :type="3">
              <template #user="{data}">
                <div class="m_applicationmanage_userbox" @click="$refs.user.openModal()">
                  <div class="m_userbox_item">
                    <span v-for="(item,index) in data" :key="index" class="m_userbox_item_has">{{ item.name }}<span v-if="index!==data.length-1">/</span></span>
                    <span v-if="data.length===0" class="m_userbox_item_range">请选择应用可见范围</span>
                  </div>
                </div>
              </template>
            </um-bus-user>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer text-center">
          <el-button @click="innerVisible = false,$refs.addForm.resetFields(),$refs.addForm.clearValidate()">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </EnterpriseLayout>
</template>

<script>
import { applicationEp_app_manageGet_manage_app_list, applicationEp_app_manageAdd_self_built_app } from '@/api/enterprise'
export default {
  name: 'EnterpriseApplication',
  data() {
    return {
      list: [],
      addForm: {
        appName: '',
        appIconUrl: '',
        appJumpLink: '',
        pcJumpLink: '',
        appUsePurview: []
      },
      innerVisible: false,
      rules: {
        appName: [
          { required: true, message: '请输入应用名称', trigger: 'blur' },
          { min: 0, max: 20, message: '长度在 0 到 20 个字符', trigger: 'blur' }
        ],
        appIconUrl: [
          { required: true, message: '请选择应用图标', trigger: 'change' }
        ],
        appUsePurview: [
          { required: true, message: '请选择应用可见范围', trigger: 'change' }
        ],
        appJumpLink: [
          { required: true, message: '请输入APP端H5地址', trigger: 'change' }
        ],
        pcJumpLink: [
          { required: true, message: '请输入PC端H5地址', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      applicationEp_app_manageGet_manage_app_list().then(res => {
        this.list = res.data
      })
    },
    // todo
    add(value, appUuid) {
      switch (value) {
        case '111':
          // 审批
          this.$router.push({ path: '/enterprise/application/config', query: { appUuid: appUuid }})
          break
        case '222':
          // 会议室
          this.$router.push({ path: '/meeting', query: { appUuid: appUuid }})
          break
        case '333':
          this.$router.push({ path: '/course', query: { appUuid: appUuid }})
          break
        case '444':
          // 活动
          this.$router.push({ path: '/activity', query: { appUuid: appUuid }})
          break
        default:
          this.$router.push({ path: '/enterprise/application/configOther', query: { appUuid: appUuid }})
          break
      }
    },
    addThree() {
      this.$router.push('/enterprise/application/configOther')
    },
    create() {
      this.innerVisible = true
    },
    submit() {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const load = this.$load()
          try {
            const params = {
              ...this.addForm
            }
            await applicationEp_app_manageAdd_self_built_app({
              ...params
            })
            this.$message({ type: 'success', message: '操作成功!' })
            this.innerVisible = false
            this.$refs.addForm.resetFields()
            this.$refs.addForm.clearValidate()
            this.getList()
          } catch (e) {
            this.$errorHandle(e)
          } finally {
            load.close()
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
