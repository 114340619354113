<template>
  <EnterpriseLayout>
    <div class="m_enterprise_announcement">
      <div class="m_enterprise_head" @click="back">
        <span class="m_enterprise_headicon" />
        <span class="m_enterprise_headtxt">返回</span>
      </div>
      <div class="m_enterprise_lines" />
      <div class="m_enterprise_formbody">
        <el-scrollbar style="height:100%;width: calc(100% - 10px)">
          <div style="margin-left: 10px">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="92px" class="demo-ruleForm">
              <!--        <div style="display: flex;width: 100%">-->
              <div class="m_enterprise_form">
                <el-form-item label="标题" prop="noticeTitle">
                  <el-input v-model="ruleForm.noticeTitle" placeholder="请填写标题" clearable maxlength="50" />
                </el-form-item>
                <el-form-item label="描述" prop="describe">
                  <el-input v-model="ruleForm.describe" placeholder="请填写描述" clearable maxlength="500" />
                </el-form-item>
                <el-form-item label="发布范围" prop="publishScopeList" style="height: auto;">
                  <span class="m_enterprise_parment">
                    <UmBusUser ref="chosepart" v-model="ruleForm.publishScopeList" :scope="1" :type="3" title="选择发布范围" :show-prefix-button="false" @result="resultChange">
                      <template #user="{data}">
                        <span v-if="data&&data.length" class="m_enterprise_itemcontent">
                          <span v-for="item in data" :key="item.id" class="m_enterprise_texts">
                            {{ item.name }}
                          </span>
                          <span class="m_enterprise_xiugai" @click="changpart">修改</span>
                        </span>
                        <span v-else>
                          <span style="border-radius: 4px;border: 1px solid #E4E8EB;color: #31415F;padding: 7px 10px">未设置</span>
                          <span class="m_enterprise_xiugai" @click="changpart">修改</span>
                        </span>
                      </template>
                    </UmBusUser>
                  </span>
                </el-form-item>
                <el-form-item label="推广" prop="topFlag">
                  <div class="m_enterprise_promote">
                    <div class="m_enterprise_promote_top">
                      <span>置顶</span><el-switch v-model="ruleForm.topFlag" :active-value="1" :inactive-value="0" />
                    </div>
                    <div class="m-push-msg">
                      <span>推送至消息</span><el-switch v-model="ruleForm.recommendFlag" :active-value="1" :inactive-value="0" />
                    </div>
                  </div>
                </el-form-item>
                <div class="m_enterprise_textareas">
                  <el-form-item label="内容" prop="noticeInfo" style="height: auto;">
                    <um-edit-pro v-model="ruleForm.noticeInfo" :url="classifyDescription" />
                    <!-- <tinymce
                      v-model="ruleForm.noticeInfo"
                      style="width: calc(100% - 2px)"
                      :url="classifyDescription"
                      :height="200"
                      :is-type="true"
                      maxlength="255"
                    /> -->
                  </el-form-item>
                </div>
                <el-form-item>
                  <div class="m_enterprise_btn">
                    <el-button type="primary" @click="submitForm">确认发布</el-button>
                  </div>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </EnterpriseLayout>
</template>

<script>
import { add_notice } from '@/api/enterprise'
export default {
  name: 'EnterpriseNoticeAdd',
  components: {},
  props: {},
  data() {
    return {
      ruleForm: {
        recommendFlag: 0,
        noticeTitle: '', // 公告标题
        // topFlag: '1', // 是否置顶  0 : 未置顶. 1 已置顶
        topFlag: 1,
        noticeInfo: '', // 公告主体
        describe: '', // 公告描述
        publishScopeList: [] // 发布范围
      },
      classifyDescription: '', // url 地址用来回显
      rules: {
        noticeTitle: [
          { required: true, message: '请填写标题', trigger: 'change' }
        ],
        noticeInfo: [
          { required: true, message: '请输入内容', trigger: 'change' }
        ],
        publishScopeList: [
          { required: true, message: '请选择发布范围', trigger: 'change' }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    resultChange(val) {
      this.publishScopeList = val
    },
    changpart() {
      this.$refs.chosepart.openModal()
    },
    // 返回
    back() {
      this.$router.back()
      this.$refs.ruleForm.resetFields()
    },
    // 确认发布
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const load = this.$load()
          add_notice({ ...this.ruleForm }).then(d => {
            this.$message({
              type: 'success',
              message: '发布成功!'
            })
            this.back()
          }).finally(_ => {
            // this.$refs.ruleForm.resetFields()
            load.close()
          }).catch(e => {
            load.close()
            this.$errorHandle(e)
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
