<template>
  <div class="m_enterprise_meetinginfo detailsBox">
    <div class="go_back_common">
      <div class="go_back_title" @click="back">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="m_meetinginfo_formBody">
      <div class="formBody_top flex-between">
        <div class="title-dh mb20 mt20">基本信息</div>
        <div class="formBody_top_btn flex-between">
          <div class="formBody_top_btn_del pointer" @click="del()">
            <svg-icon icon-class="del" class="mr-4" />
            删除会议室
          </div>
          <el-button type="primary" class="formBody_top_btn_edit" @click="edit">编辑</el-button>
        </div>
      </div>
      <div class="m_meetinginfo_info">
        <div class="m_meetinginfo_infoitem">
          <div class="u_infoitem_label">会议室图片：</div>
          <div class="u_infoitem_value">
            <el-image
              :key="info.roomImageUrl"
              style="width: 120px; height: 90px;"
              :src="info.roomImageUrl"
              fit="fit"
              :preview-src-list="[info.roomImageUrl]"
            />
          </div>
        </div>
        <div class="m_meetinginfo_infoitem">
          <div class="u_infoitem_label">会议室名称：</div>
          <div class="u_infoitem_value">{{ info.roomName }}</div>
        </div>
        <div class="m_meetinginfo_infoitem">
          <div class="u_infoitem_label">会议室地址：</div>
          <div class="u_infoitem_value">{{ info.roomAddress }}</div>
        </div>
        <div class="m_meetinginfo_infoitem">
          <div class="u_infoitem_label">可见范围：</div>
          <UmBusUser ref="chosepart" v-model="info.publishScopeList" :type="3" search-tip="请输入" title="选择可见范围" :show-prefix-button="false">
            <template #user="{data}">
              <span class="m_meetinginfo_itemcontent">
                <div v-for="(item,i) in data" :key="i" class="u_infoitem_value m_meetinginfo_openDay">{{ item.name }}</div>
              </span>
            </template>
          </UmBusUser>
        </div>
        <div class="m_meetinginfo_infoitem">
          <div class="u_infoitem_label">开放时间：</div>
          <div v-for="(item,i) in info.roomOpenDayList" :key="i" class="u_infoitem_value m_meetinginfo_openDay">{{ OpenDayList[item] }}</div>
        </div>
        <div class="m_meetinginfo_infoitem">
          <div class="u_infoitem_label">预定时间段：</div>
          <div class="u_infoitem_value">{{ info.roomOpenStartTime }} ~ {{ info.roomOpenEndTime }}</div>
        </div>
        <div class="m_meetinginfo_infoitem">
          <div class="u_infoitem_label mb20">场所设备：</div>
          <div v-for="(item,i) in info.roomEquipmentList" :key="i" class="u_infoitem_value m_meetinginfo_openDay mb20">{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { noticeQuery_room_info, getDelRoom } from '@/api/enterprise'
export default {
  name: 'EnterpriseApplicationMeetingInfo',
  props: {},
  data() {
    return {
      roomUuid: '',
      info: {},
      OpenDayList: {
        1: '周一',
        2: '周二',
        3: '周三',
        4: '周四',
        5: '周五',
        6: '周六',
        7: '周日'
      }

    }
  },
  created() {
    this.roomUuid = this.$route.query.roomUuid
    if (this.roomUuid) {
      this.getInfo()
    }
  },
  mounted() {},
  methods: {
    getInfo() {
      noticeQuery_room_info({ roomUuid: this.roomUuid }).then(res => {
        this.info = res.data
        this.info.roomOpenStartTime = this.info.roomOpenTimeList[0].substr(0, 5)
        this.info.roomOpenEndTime = this.info.roomOpenTimeList[0].substr(6, 5)
      })
    },
    back() {
      this.$router.replace({ path: '/meeting', query: { appUuid: this.$route.query.appUuid }})
    },
    edit() {
      this.$router.push({ path: '/meeting/update', query: { roomUuid: this.roomUuid, type: 'update', appUuid: this.$route.query.appUuid }})
    },
    del() {
      this.$confirm('确认删除', '提示', {
        type: 'warning'
      }).then(() => {
        const load = this.$load()
        getDelRoom({ roomUuid: this.info.roomUuid }).then(res => {
          this.$message.success('删除成功')
          load.close()
          // this.$router.push('/enterprise/application/meeting')
          this.$router.push({ path: '/meeting', query: { appUuid: this.$route.query.appUuid }})
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      })
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
