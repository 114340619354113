import request from '@/utils/request'
const baseURL = 'http://api.pm.youmatech.com/mock/983'

// 承建商列表
export function contractor_list(data) {
  return request({
    url: '/api/v1/data/contractor/list',
    method: 'post',
    // baseURL,
    data
  })
}

// 承建商添加
export function contractor_add(data) {
  return request({
    url: '/api/v1/data/contractor/add',
    method: 'post',
    // baseURL,
    data
  })
}

// 承建商删除
export function contractor_delete(params) {
  return request({
    url: '/api/v1/data/contractor/delete',
    method: 'get',
    // baseURL,
    params
  })
}

// 承建商修改
export function contractor_update(data) {
  return request({
    url: '/api/v1/data/contractor/update',
    method: 'post',
    // baseURL,
    data
  })
}

// 承建商详情
export function contractor_info(params) {
  return request({
    url: '/api/v1/data/contractor/info',
    method: 'get',
    // baseURL,
    params
  })
}
