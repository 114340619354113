<template>
  <div class="g-myToDo">
    <div class="myToDo-menus">
      <div class="myToDo-title">
        <div class="title-icon" />
        待办
      </div>
      <div v-if="menuList&&menuList.length>0">
        <div
          v-for="(item, i) in menuList"
          :key="i"
          class="menus-item"
          :class="appUuid == item.appUuid ? 'checkItem' : ''"
          @click="appUuid = item.appUuid"
        >
          <div class="item-icon">
            <img v-if="item.appIconUrl" :src="item.appIconUrl" alt="">
            <img v-else src="~@/assets/myToDo/allToDo@2x.png" alt="">
          </div>

          <UmToolTip :content="item.appName" class="row_1 item-name" />
          <div class="item-num">
            {{ item.count >99?'99+':item.count }}
          </div>
        </div>
      </div>
      <div v-else class="m-noData centerData ">
        暂无数据
      </div>
    </div>

    <el-card class="myToDo-event">
      <div class="myToDo-title f-p0">
        <div class="title-icon" />
        待办事项
      </div>
      <div class="event-top">
        <el-radio-group v-model="readState" class="top-radio">
          <el-radio-button label="0">待办({{ stateCount.todoCount }})</el-radio-button>
          <el-radio-button label="2">已逾期({{ stateCount.overdueCount }})</el-radio-button>
          <el-radio-button label="1">已办({{ stateCount.haveDoneCount }})</el-radio-button>
        </el-radio-group>
        <el-popover
          v-if="readState != 1"
          placement="bottom-end"
          width="108"
          trigger="click"
          :visible-arrow="false"
          class="top-sort"
          popper-class="m_enterpris_popover"
        >
          <div class="sort-check">
            <div
              class="check-item"
              :class="sortType == 1 ? 'check' : ''"
              @click="sortType = 1"
            >
              按最近创建时间
            </div>
            <div
              class="check-item"
              :class="sortType == 2 ? 'check' : ''"
              @click="sortType = 2"
            >
              按最近截止时间
            </div>
          </div>
          <div slot="reference" class="sort-btn f-flex-ac">
            <div class="btn-icon" />
            排序
          </div>
        </el-popover>
      </div>
      <div v-if="toDoList&&toDoList.length>0" class="event-content">
        <div v-for="(item, i) in toDoList" :key="i" class="content-item">
          <div class="item-title f-flex-ac">
            <div class="title-icon">
              <img :src="item.appIconUrl" alt="">
            </div>
            {{ item.appName||'--' }}
          </div>
          <div class="item-information f-flex-acjcsb">
            <div class="information-content">
              <UmToolTip :content="item.title" class="row_1" />
            </div>
            <div class="information-time">{{ item.createTime }}</div>
          </div>
          <div class="item-remark" :class="readState == 1?'f-pd-b16':''">
            <UmToolTip :content="item.content" class="row_1 " />
          </div>
          <div v-if="readState != 1" class="item-bottom f-flex-acjcsb">
            <div class="bottom-time f-flex-ac">
              <div v-if="item.overTime">截止时间：{{ item.overTime||'--' }}</div>
              <div v-if="item.isOver" class="time-overdue">逾期{{ getOverTime(item.overTime) }}</div>
            </div>
            <div class="bottom-see f-flex-ac" @click="goUrl(item)">查看详情<div class="see-icon" /> </div>
          </div>
        </div>
        <div>
          <pagination
            :background="false"
            :limit.sync="page.pageSize"
            :page.sync="page.pageNum"
            :total="tableTotal"
            @pagination="getList('page')"
          />
        </div>
      </div>
      <div v-else class="m-noData centerData">
        暂无数据
      </div>
    </el-card>
    <AppQrcode :isapp-uuid="isappUuid" :invitation.sync="invitation" @changevisible="changevisible" />
  </div>
</template>
<script>
import { jumpUrl } from '@/utils/backlog'
import { getList, getGroupList } from '@/api/myToDo'
import router from '@/router'
import AppQrcode from '@/layout/appQrcode'
export default {
  name: 'MyToDo',
  components: { AppQrcode },
  data() {
    return {
      jumpUrl,
      appUuid: '',
      menuList: [],
      readState: '0',
      sortType: 1,
      toDoList: [],
      tableTotal: 0,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      stateCount: {
        todoCount: 0,
        haveDoneCount: 0,
        overdueCount: 0
      },
      isappUuid: '',
      invitation: false
    }
  },
  watch: {
    readState(val) {
      if (val) {
        this.getList()
      }
    },
    sortType(val) {
      if (val) {
        this.getList()
      }
    },
    appUuid() {
      this.getList()
    }

  },
  created() {
    this.getGroup()
    this.getList()
  },
  methods: {
    // 列表
    getList(state = '') {
      if (state != 'page') {
        this.page.pageNum = 1
      }
      console.log(this.$route.query.busUserUuid)
      const params = {
        page: this.page,
        readState: this.readState,
        busUserUuid: this.$route.query.busUserUuid,
        appUuid: this.appUuid,
        sortType: this.sortType,
        appInfoFlag: true,
        needStatusCount: true
      }
      const load = this.$loading()
      getList(params).then(res => {
        let arr = []
        res.data.list.forEach(item => {
          arr = arr.concat(item.todoList)
        })
        this.toDoList = arr
        this.tableTotal = res.data.page.total
        if (res.data.stateCount) {
          this.stateCount = res.data.stateCount
        }
        load.close()
      }).catch(() => {
        load.close()
      })
    },
    // app分组
    getGroup() {
      const params = {
        appInfoFlag: true,
        sumFlag: true,
        sortFlag: true
      }
      getGroupList(params).then(res => {
        this.menuList = res.data
      })
    },
    getOverTime(time) {
      const nowTime = new Date().getTime()
      const overTime = new Date(time).getTime()
      let num = null
      if (nowTime < overTime) {
        console.log('没逾期')
      } else {
        num = parseInt((nowTime - overTime) / 1000 / 60 / 60)
        if (num > 24) {
          return parseInt((num / 24)) + '天'
        } else {
          return num + '小时'
        }
      }
    },
    goUrl(item) {
      if (item.urlScope == 1 || (item.urlScope == 3 && !item.pcRedirectUrl)) {
        this.invitation = true
        this.isappUuid = item.appUuid
      } else {
        this.jumpUrl(item.pcRedirectUrl)
      }
    },
    changevisible(val) {
      if (!val) {
        this.invitation = false
      } else {
        this.invitation = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.m_sort_popover {
  padding: 0;
  width: 108px;
}
.sort-check {
  text-align: center;
}
.check-item {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  color: #6c7b96;
  background: #ffffff;
  cursor: pointer;

  &.check {
    background: rgba(76, 100, 254, 0.1);
    color: #4c64fe;
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
}
</style>
