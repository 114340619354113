var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_enterprise_projetcinfo"},[_c('el-row',{directives:[{name:"permission",rawName:"v-permission",value:(['112040913']),expression:"['112040913']"}],staticStyle:{"margin-bottom":"20px"},attrs:{"type":"flex","justify":"end"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("添加")])],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":12}},[_c('el-card',{staticClass:"m_projetcinfo_card",staticStyle:{"height":"480px"},attrs:{"shadow":"never","body-style":{
          height: '100%',
          overflow: 'auto',
        }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("组织架构")])]),_c('el-tree',{ref:"tree",staticClass:"filter-tree",staticStyle:{"margin-top":"10px","margin-bottom":"55px"},attrs:{"data":_vm.treeData,"props":_vm.defaultProps,"node-key":"organUuid","highlight-current":"","default-expanded-keys":_vm.idArr},on:{"current-change":_vm.currentTreeChange},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"m_projetcinfo_treenode"},[_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-tag',{style:({
                  color: '#333',
                  background: '#EDEDED',
                  borderColor: '#EDEDED',
                }),attrs:{"size":"mini"}},[(data.organTypeCode==1001502)?_c('span',[_vm._v("公司")]):_vm._e(),(data.organTypeCode==1001503)?_c('span',[_vm._v("部门")]):_vm._e(),(data.organTypeCode==1001504)?_c('span',[_vm._v("岗位")]):_vm._e()]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":data.organName,"placement":"top"}},[_c('span',{staticStyle:{"font-size":"14px","margin-left":"10px"}},[_vm._v(_vm._s(data.organName))])])],1)])}}])})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"m_projetcinfo_card",staticStyle:{"height":"480px"},attrs:{"shadow":"never","body-style":{
          height: '100%',
          overflow: 'auto',
          padding: '20px 20px 55px',
        }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("项目")])]),_c('el-tree',{ref:"treeProject",staticClass:"filter-tree",staticStyle:{"margin-top":"10px","margin-bottom":"55px"},attrs:{"data":_vm.treeDataProject,"props":_vm.defaultPropsProject,"node-key":"uuid","highlight-current":"","default-expanded-keys":_vm.idArrProject},on:{"current-change":_vm.currentTreeChangeProject},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"m_projetcinfo_treenode",class:{m_projetcinfo_isOnly:data.readOnlyFlag}},[_c('span',{staticClass:"m_parttree_item"},[_c('el-tag',{class:{m_projetcinfo_tagisOnly:data.readOnlyFlag},style:({
                  color: '#333',
                  background: '#EDEDED',
                  borderColor: '#EDEDED',
                }),attrs:{"size":"mini"}},[_vm._v(_vm._s(_vm.PROJECT_TYPE[data.typeCode]))]),_c('span',{staticClass:"dib",staticStyle:{"font-size":"14px","margin-left":"10px","width":"300px"}},[_c('UmToolTip',{staticClass:"row_1",attrs:{"content":node.label}})],1)],1),_c('span',{staticClass:"m_projetcinfo_edit",class:{ on:node.isCurrent&&data.readOnlyFlag!=1}},[_c('el-tooltip',{attrs:{"effect":"dark","content":"删除","placement":"top"}},[_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){$event.stopPropagation();return (() => _vm.remove(node, data)).apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-delete"})])],1)],1)])}}])})],1)],1)],1),_c('selectProject',{attrs:{"visible":_vm.visibleProject,"org-user-uuid":_vm.orgUserUuid},on:{"update:visible":function($event){_vm.visibleProject=$event},"change":_vm.changeProject}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }