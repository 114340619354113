<template>
  <div class="message-box">
    <div
      v-for="(item,index) in list"
      :key="index"
      class="message-item"
    >
      <div
        class="item-wrapper"
        :class="{ active: selectPerson.id == item.id }"
        @click="selectHandle(item)"
      >
        <!--        头像-->
        <div class="left">
          <el-badge
            :hidden="!item.noread"
            :value="item.noread"
            :max="99"
            class="item"
            :class="{max9: item.noread > 9&&item.noread < 99, max99: item.noread > 99}"
          >
            <el-avatar shape="square" :size="40" src="" style="border-radius: 6px">
              <img style="user-select: none" :src="author">
            </el-avatar>
          </el-badge>
        </div>
        <!--        姓名消息-->
        <div class="right">
          <div class="top">
            <div class="name row-one">{{ item.name }}</div>
            <div class="time">{{ item.time }}</div>
          </div>
          <div class="bottom row-one">
            {{ item.message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
const author = require('@/assets/im/author.png')
export default {
  name: 'MessageList',
  model: {
    prop: 'value', // 当面选中的聊天对象
    event: 'change'
  },
  props: {
    // 消息对象列表
    list: {
      type: Array,
      default: () => [
        { id: 1, messageType: 'chatPerson', name: '智睿', time: '06:00', noread: 200, message: '今天天启真的但是范德萨发生大范德萨范德萨发不过呵呵哈哈哈' },
        { id: 2, messageType: 'chatPerson', name: '哈哈', time: '06:00', noread: 0, message: '今天天启真的但是范德萨发生大范德萨范德萨发不过呵呵哈哈哈' },
        { id: 3, messageType: 'chatPerson', name: '朝阳', time: '06:00', noread: 7, message: '今天天启真的但是范德萨发生大范德萨范德萨发不过呵呵哈哈哈' },
        { id: 4, messageType: 'chatPerson', name: '志明', time: '06:00', noread: 10, message: '今天天启真的但是范德萨发生大范德萨范德萨发不过呵呵哈哈哈' }
      ]
    }
  },
  data() {
    return {
      author,
      selectPerson: {} // 选中的聊天对象
    }
  },
  watch: {
    // 向外抛出聊天对象
    selectPerson: {
      handler(val) {
        // 向vuex存入选中联系人
        this.$emit('change', this.selectPerson)
        this.$store.commit('im/setSelect', this.selectPerson)
      },
      deep: true
    }
  },
  methods: {
    selectHandle(person) {
      this.selectPerson = person
    }
  }
}
</script>

<style scoped lang="scss">
.message-box {
  width: 100%;
  height: 100%;
  .message-item {
    width: 300px;
    height: 70px;
    .active {
      background: rgba(144,157,182,0.14)
    }
    .item-wrapper {
      width: 100%;
      height: 100%;
      padding: 15px 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: rgba(144,157,182,0.08)
      }
      .left {
        margin-right: 12px;
      }
      .right {
        width: 224px;
        .top {
          margin-bottom: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            max-width: 130px;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #31415f;
            line-height: 20px;
          }
          .time {
            font-size: 10px;
            font-weight: 400;
            text-align: right;
            color: #909db6;
            line-height: 20px;
          }
        }
        .bottom {
          font-size: 10px;
          font-weight: 400;
          text-align: left;
          color: #909db6;
          line-height: 20px;
          max-width: 100%;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.max9 {
  ::v-deep .el-badge__content.is-fixed {
    right: 16px;
  }
}
.max99 {
  ::v-deep .el-badge__content.is-fixed {
    right: 23px;
  }
}
</style>
