<template>
  <div class="m-addressCheck">
    <div class="addressCheck_info flex-column-between pointer" @click="checkAddress()">
      <div v-if=" Object.values(useAddressInfo).length>0" class="flex-between">
        <div class="u-telephone flex-between">
          <div>{{ useAddressInfo.name||'-' }}</div>
          <div class="ml-10">{{ useAddressInfo.receivePhone ||'-' }}</div>
        </div>
        <div class=" pointer" @click="checkAddress()">
          <img alt="" src="@/assets/dashboard/rightArrow.png" style="width:10px;height:10px">
        </div>
      </div>
      <div v-if=" Object.values(useAddressInfo).length>0" class="s-darkGreyColor">
        <i class="el-icon-location-outline" />{{ useAddressInfo.address||'-' }}
      </div>
      <div v-else class="flex-between">
        <div class="s-darkGreyColor">请选择收货地址</div>
        <div class=" pointer" @click="checkAddress()">
          <img alt="" src="@/assets/dashboard/rightArrow.png" style="width:10px;height:10px">
        </div>
      </div>
    </div>
    <el-dialog
      title="选择地址"
      :visible.sync="addressVisible"
      width="522px"
      :before-close="getClose"
    >
      <div v-if="addressList.length>0">
        <div v-for="(item,i) in addressList" :key="i" class="flex-between addressCheck_list font-14 mb20">
          <div class="flex-column-between" style="height:100%">
            <div class="flex"><div>{{ item.name }}</div><div class="ml-10">{{ item.receivePhone }}</div></div>
            <div class="s-darkGreyColor">
              <i class="el-icon-location-outline" />{{ item.address }}
            </div>
          </div>
          <div><el-button type="primary" plain @click="getApply(item)">使用</el-button></div>
        </div>
      </div>
      <div v-else class="addressCheck_list  flex-center s-darkGreyColor">
        当前无收货地址，请到APP端添加地址
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAddressManagement
} from '@/api/cloudMarket'
export default {
  name: 'AddressCheck',
  props: {
    useAddressInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      addressVisible: false,
      addressList: []
    }
  },
  methods: {
    // 确认订单选择地址
    checkAddress() {
      getAddressManagement({}).then(res => {
        this.addressList = res.data
        this.addressVisible = true
      })
    },
    // 关闭选择地址
    getClose(done) {
      done()
      this.addressVisible = false
    },
    // 使用
    getApply(item) {
      this.$emit('getApply', item)
      this.addressVisible = false
    }
  }
}
</script>
