<template>
  <div class="m_enterprise_selectproject">
    <el-dialog
      title="项目授权"
      :visible.sync="visibleInner"
      width="950px"
      class="custom-dialog"
    >
      <el-row type="flex" justify="start" align="top">
        <el-col :span="12">
          <el-card
            header="组织"
            shadow="never"
            style="margin-top: 0;"
            :body-style="{ height: '400px', overflow: 'auto', padding: '10px' }"
          >
            <el-input
              v-model="filterText"
              placeholder="请输入关键字进行过滤"
              clearable
            />
            <el-tree
              ref="tree"
              style="margin-top: 10px"
              highlight-current
              node-key="organUuid"
              :data="organTreeData"
              :props="{ children: 'children', label: 'organName' }"
              :default-expanded-keys="defaultShowNodes"
              :filter-node-method="filterNode"
              @node-click="selectOrganCallback"
            >
              <span slot-scope="{ node, data }" class="custom-tree-node m_selectproject_zuzi">
                <el-tag
                  size="mini"
                  :style="{
                    color: '#333',
                    background: '#EDEDED',
                    borderColor: '#EDEDED',
                  }"
                >

                  <span v-if="data.organTypeCode==1001502">公司</span>
                  <span v-if="data.organTypeCode==1001503">部门</span>
                  <span v-if="data.organTypeCode==1001504">岗位</span>

                </el-tag>
                <div class="m_selectproject_treeitem">
                  <UmToolTip :content="node.label" class="row_1" />
                </div>
              </span>
            </el-tree>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card
            v-loading="loading"
            header
            style="margin-top: 0;"
            shadow="never"
            :body-style="{ height: '400px', overflow: 'auto', padding: '10px' }"
          >
            <div slot="header" class="clearfix">
              <span>项目<i style="margin-left: 5px;color:#ccc; cursor: pointer" class="el-icon-warning-outline" /> <span style="font-weight: normal;color:#ccc">如果给父级授权，后续父级下再新增子级会自动授权</span></span>
            </div>
            <template>
              <el-row>
                <el-col :span="24">
                  <el-input
                    v-model="filterProject"
                    placeholder="请输入关键字进行过滤"
                    clearable
                    size="small"
                  />
                </el-col>
                <el-col v-show="projectTreeData.length" :span="24" style="margin-top: 10px;">
                  <el-tooltip
                    :content="`自动授权“${busOrganObj.organName}”下新增的项目/分期/楼栋`"
                    placement="top"
                  >
                    <div class="m_auto_btn">
                      <el-switch
                        v-model="autoAuth"
                      />
                      <div class="m_auto_authorize">自动授权</div>
                    </div>
                  </el-tooltip>
                </el-col>
              </el-row>
              <el-tree
                ref="treeProject"
                style="margin-top: 10px"
                node-key="uuid"
                :data="projectTreeData"
                :props="defaultProps"
                :filter-node-method="filterProjectNode"
                :default-expanded-keys="defaultProject"
                check-on-click-node
                :check-strictly="true"
                :show-checkbox="true"
                @check-change="checkChange"
              >
                <span slot-scope="{ node, data }" class="custom-tree-node m_project_treeitem">
                  <el-tag
                    size="mini"
                    :style="{
                      color: '#333',
                      background: '#EDEDED',
                      borderColor: '#EDEDED',
                    }"
                  >{{ PROJECT_TYPE[data.typeCode] }}</el-tag>
                  <div class="m_selectproject_treeitem">
                    <UmToolTip :content="node.label" class="row_1" />
                  </div>
                </span>
              </el-tree>
            </template>
          </el-card>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start" align="top">
        <el-col :span="24">
          <el-card
            shadow="never"
            style="margin-top: 0;"
            :body-style="{ height: '100px', overflow: 'auto', padding: '10px' }"
          >
            <el-tag v-for=" (item,index) in checkedList " :key="index" style="margin-right: 5px;margin-bottom: 5px" closable @close="handleClose(item,index)">{{ item.name }}</el-tag>
          </el-card>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="visibleInner = false,checkedList=[]">取 消</el-button>
        <el-button type="primary" @click="submit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_user_project_auth_organ_trees,
  get_user_project_tree_by_organs
} from '@/api/manage.js'
export default {
  name: 'SelectProject',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orgUserUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchForm: {
        organUuid: ''
      },
      ORGAN_TYPE: {
        '1': '集团',
        '2': '公司',
        '3': '部门',
        '4': '岗位'
      },
      PROJECT_TYPE: {
        '1': '项目',
        '2': '分期',
        '3': '楼栋',
        '1005501': '公区',
        '1005502': '非工区'
      },
      filterText: '',
      organTreeData: [],
      filterProject: '',
      projectTreeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        disabled: (data, node) => {
          return data.readOnlyFlag
        }
      },
      autoAuth: true,
      loading: false,
      busOrganObj: {},
      checkedList: [],
      defaultShowNodes: [],
      defaultProject: []
    }
  },
  computed: {
    visibleInner: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
        if (!val) {
          this.checkedList = []
        }
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    filterProject(val) {
      this.$refs.treeProject.filter(val)
    },
    visible(val) {
      if (val) {
        this.checkedList = []
        this.selectOrganTree()
      }
    },
    organTreeData: {
      handler() {
        this.organTreeData.forEach((item) => {
          this.defaultShowNodes.push(item.organUuid)
        })
      },
      deep: true
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    handleAfterLeave() {
      this.$destroy(true)
      // this.$el.parentNode.removeChild(this.$el) // 可能移除的时候组件已经不存在了
    },
    filterNode(value, data) {
      if (!value) return true
      return data.organName.indexOf(value) !== -1
    },
    filterProjectNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    // 获取组织架构
    selectOrganTree() {
      get_user_project_auth_organ_trees({ orgUserUuid: this.orgUserUuid }).then((d) => {
        console.log(d)
        this.organTreeData = d.data
        if (d.data.length === 0) return false
        this.$nextTick(() => {
          this.$refs.tree.setCurrentNode(d.data[0])
          this.searchForm.organUuid = d.data[0].organUuid
          // this.$refs.tree.setCurrentKey(this.organTreeData[0].organUuid, true) // 默认选中第一个节点
        })
        this.selectOrganCallback(this.organTreeData[0])
      })
    },
    // 选中的组织架构节点
    selectOrganCallback(data, node, component) {
      // 切换左侧 回显右侧数据
      const ids = this.checkedList.map(i => i.uuid)
      this.$refs.treeProject.setCheckedKeys(ids)

      this.searchForm.organUuid = data.organUuid
      this.busOrganObj = data
      this.searchProject()
    },
    // 获取项目树数据
    searchProject() {
      this.loading = true
      get_user_project_tree_by_organs({ orgOrganUuid: this.searchForm.organUuid, orgUserUuid: this.orgUserUuid }).then((d) => {
        // console.log(d)
        this.loading = false
        this.projectTreeData = d.data.projectBuildingTree
        this.autoAuth = d.data.authAuth
        if (d.data.projectBuildingTree.length === 0) return false
        this.projectTreeData.forEach((item) => {
          this.defaultProject.push(item.uuid)
        })
        this.$nextTick(() => {
          this.$refs.treeProject.setCurrentNode(d.data.projectBuildingTree[0])
        })
      }).finally(_ => {
        this.loading = false
      })
        .catch((e) => {
          this.loading = false
          this.$errorHandle(e)
        })
    },
    check(checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys) {
      console.log(checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys)
    },
    checkChange(data, checked, indeterminate) {
      // console.log(data, checked)
      // 父选中 子节点全部选中
      if (checked && data && data.children) {
        data.children.forEach(item => {
          this.$refs.treeProject.setChecked(item.uuid, true)
        })
      }
      if (!checked && data && data.children > 0) {
        const arr = this.$refs.treeProject.getCheckedKeys()
        console.log(arr)
        const tag = data.children.some(item => {
          return !(arr.includes(item.uuid))
          // this.$refs.treeProject.setChecked(item.uuid, false)
        })
        console.log(tag)
        if (!tag) {
          data.children.forEach(item => {
            this.$refs.treeProject.setChecked(item.uuid, false)
          })
        }
      }
      // // 子取消 父节点也取消
      if (!checked) {
        console.log(data)
        if (data.parentUuid) {
          this.$refs.treeProject.setChecked(data.parentUuid, false)
        }
      }
      if (checked) {
        this.checkedList.push(data)
      } else {
        let idx = 0
        this.checkedList.forEach((i, index) => {
          if (i.uuid === data.uuid) {
            idx = index
          }
        })
        this.checkedList.splice(idx, 1)
      }
    },
    // findchildren(current) {
    //   this.$refs.treeProject.setChecked(current.uuid, true)
    //   const d = current.children
    //   if (d.length > 0) {
    //     d.forEach(a => {
    //       this.findchildren(a)
    //     })
    //   }
    // },
    handleClose(it, idx) {
      // 当前树选中的
      const ids = this.$refs['treeProject'].getCheckedKeys()
      if (ids.indexOf(it.uuid) !== -1) {
        const id = it.uuid
        this.$refs.treeProject.setChecked(id, false, false)
      } else {
        this.checkedList.splice(idx, 1)
      }
    },
    submit() {
      const list = this.checkedList.map(i => i.uuid)
      // list = list.concat(this.$refs['treeProject'].getCheckedKeys())
      // console.log(list)
      // list = list.concat(this.$refs['treeProject'].getHalfCheckedKeys())
      console.log(list)
      if (list.length === 0) {
        return this.$message.error('请选择项目')
      }
      this.$emit('change', {
        organUuid: this.busOrganObj.organUuid,
        autoAuth: this.autoAuth,
        ids: list
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
