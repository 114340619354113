const state = {
  visitedViews: getTags(), // 标签栏
  cachedViews: [] // 缓存页面
}

/**
 * 保存当前页面的标签
 * @param visitedViews
 */
export function saveTags(visitedViews) {
  const list = visitedViews.map(item => {
    return {
      name: item.name,
      title: item.title,
      group: item.group,
      path: item.path,
      query: item.query,
      fullPath: item.fullPath,
      meta: item.meta
    }
  })
  localStorage.setItem('visitedViews', JSON.stringify(list))
}

/**
 * 获取当前缓存保留的页签
 * @returns {any|*[]}
 */
export function getTags() {
  const visitedViews = localStorage.getItem('visitedViews')
  if (visitedViews) {
    return JSON.parse(visitedViews)
  } else {
    return []
  }
}

/**
 * 移除所有标签
 */
export function removeTags() {
  localStorage.removeItem('visitedViews')
}
const mutations = {
  // 变更为根据group来处理
  ADD_VISITED_VIEW: (state, view) => {
    // if (state.visitedViews.some(v => v.path === view.path)) return
    if (view.name === 'ImMessage') return // IM 不添加到标签页
    if (!view.meta) return
    const index = state.visitedViews.findIndex(v => v.group === view.meta.group)
    if (index !== -1) {
      // 如果存在的话 那就更新
      Object.assign(state.visitedViews[index], view, {
        title: view.meta.title || 'no-name', // 标题
        group: view.meta.group || 'dandan' // 分组名
      })
    } else {
      // 如果不存在的话 那就添加
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name', // 标题
          group: view.meta.group || 'dandan' // 分组名
        })
      )
    }
    saveTags(state.visitedViews)
  },
  // 修改变量名为 cache
  ADD_CACHED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name) || view.meta.cache === undefined) return
    if (view.meta.cache) {
      state.cachedViews.push(view.name)
    }
  },

  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.group === view.group) {
        state.visitedViews.splice(i, 1)
        break
      }
    }
    saveTags(state.visitedViews)
  },
  DEL_CACHED_VIEW: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    index > -1 && state.cachedViews.splice(index, 1)
  },

  DEL_OTHERS_VISITED_VIEWS: (state, view) => {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.meta.affix || v.group === view.group
    })
    saveTags(state.visitedViews)
  },
  DEL_OTHERS_CACHED_VIEWS: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1)
    } else {
      // if index = -1, there is no cached tags
      state.cachedViews = []
    }
  },

  DEL_ALL_VISITED_VIEWS: state => {
    // keep affix tags
    const affixTags = state.visitedViews.filter(tag => tag.meta.affix)
    state.visitedViews = affixTags
    saveTags(state.visitedViews)
  },
  DEL_ALL_CACHED_VIEWS: state => {
    state.cachedViews = []
  },

  UPDATE_VISITED_VIEW: (state, view) => {
    for (let v of state.visitedViews) {
      if (v.group === view.group) {
        v = Object.assign(v, view)
        break
      }
    }
    saveTags(state.visitedViews)
  }
}

const actions = {
  addView({ dispatch }, view) {
    dispatch('addVisitedView', view) // 页签
    dispatch('addCachedView', view) // 页面缓存
  },
  addVisitedView({ commit }, view) {
    commit('ADD_VISITED_VIEW', view)
  },
  addCachedView({ commit }, view) {
    commit('ADD_CACHED_VIEW', view)
  },

  delView({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delVisitedView', view)
      dispatch('delCachedView', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEW', view)
      resolve([...state.visitedViews])
    })
  },
  delCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_CACHED_VIEW', view)
      resolve([...state.cachedViews])
    })
  },

  delOthersViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delOthersVisitedViews', view)
      dispatch('delOthersCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delOthersVisitedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_VISITED_VIEWS', view)
      resolve([...state.visitedViews])
    })
  },
  delOthersCachedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_CACHED_VIEWS', view)
      resolve([...state.cachedViews])
    })
  },

  delAllViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delAllVisitedViews', view)
      dispatch('delAllCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delAllVisitedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_VISITED_VIEWS')
      resolve([...state.visitedViews])
    })
  },
  delAllCachedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_CACHED_VIEWS')
      resolve([...state.cachedViews])
    })
  },

  updateVisitedView({ commit }, view) {
    commit('UPDATE_VISITED_VIEW', view)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
