import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'
const noticeRouter = {
  path: '/notice',
  component: Layout,
  name: 'Notice',
  // redirect: '/dashboard/index',
  redirect: to => {
    for (let i = 0; i < noticeRouter.children.length; i++) {
      const { path, meta } = noticeRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  meta: { level: 2, roles: ['11202'] },
  children: [
    {
      path: '/notice',
      name: 'Notice',
      component: () => import('@/views/notice'),
      meta: { title: '公告通知', group: 'newsBull', level: 2, roles: ['1120201'], activeMenu: '/dashboard' }
    },
    {
      path: '/notice/info',
      component: () => import('@/views/notice/info'),
      name: 'NoticeInfo',
      meta: { title: '公告详情', group: 'newsBull', level: 3, roles: ['1120201'], activeMenu: '/dashboard' }
    }
  ]
}
export default noticeRouter
