<template>
  <div class="m_enterprise_activity">
    <UmApproveEle :app-uuid="appUuid" />
    <div class="m_activity_content">
      <el-button type="primary" @click="add"><span class="m_activity_addicon" /> 创建活动</el-button>
      <div class="m_activity_table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%;"
        >
          <el-table-column
            label="活动名称"
            prop="activityName"
            width="370px"
          >
            <template slot-scope="scope">
              <div class="m_activity_type">
                <img class="m_activity_img" :src="scope.row.activityImageUrl" alt="">
                <div class="m_activity_title row_2">{{ scope.row.activityName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="活动时间"
            prop="activityStartTimeStr"
          >
            <template slot-scope="scope">
              {{ scope.row.activityStartTime|parseTime('{m}-{d} {h}:{i}') }} ~ {{ scope.row.activityEndTime|parseTime('{m}-{d} {h}:{i}') }}
            </template>
          </el-table-column>
          <el-table-column
            label="活动地点"
            prop="activityAddress"
          >
            <template slot-scope="scope">
              <!--                  <span class="row_2">{{ scope.row.userDepartment||'-' }}</span>-->
              <span class="row_2">{{ scope.row.activityAddress||'-' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="报名人数"
            width="120px"
            prop="activitySignNum"
          >
            <template slot-scope="scope">
              <span class="row_1">{{ scope.row.signUserNum||'0' }}/{{ scope.row.signUserMaxNum||'0' }}</span>
            </template>}
          </el-table-column>
          <el-table-column
            label="状态"
            prop="activityStatus"
            width="120px"
          >
            <template slot-scope="scope">
              <div :class="'m_activity_status'+scope.row.activityStatus" />
            </template>}
          </el-table-column>
          <el-table-column
            label="操作"
            prop="activityStatus"
            width="120px"
          >
            <template slot-scope="scope">
              <div class="m_activity_edit" @click="toInfo(scope.row)">详情</div>
            </template>}
          </el-table-column>
        </el-table>
      </div>
      <div class="m_activity_fenye">
        <pagination
          :background="false"
          :limit.sync="page.pageSize"
          :page.sync="page.pageNum"
          :total="tableTotal"
          @pagination="getList('page')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { enterprisFlowEnableOrDisable, activityQuery_activity_list } from '@/api/enterprise'

export default {
  name: 'EnterpriseApplicationActivity',
  props: {},
  data() {
    return {
      tableData: [],
      tableTotal: 0,
      appUuid: '',
      page: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.appUuid = this.$route.query.appUuid

    // if (this.appUuid) {
    //   this.getList()
    // }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      const load = this.$load()
      const params = {
        page: this.page
      }
      activityQuery_activity_list(params).then(res => {
        load.close()
        this.tableData = res.data.list
        this.tableTotal = res.data.page.total
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    add() {
      this.$router.push({ path: '/activity/add', query: { appUuid: this.appUuid }})
    },

    toInfo(item) {
      this.$router.push({ path: '/activity/info', query: { activityUuid: item.activityUuid, appUuid: this.appUuid }})
    },
    del(item) {
      const h = this.$createElement
      this.$confirm('', {
        message: h('div', null, [
          h('p', { style: 'color:#31415F;fontSize: 16px;fontWeight: 500;' }, '是否确定删除？')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
        // customClass: 'message-dialog'
      }).then(() => {
        const load = this.$load()
        enterprisFlowEnableOrDisable({ appUuid: this.appUuid, activityUuid: item.activityUuid }).then(res => {
          this.$message({ type: 'success', message: '删除成功!' })
          load.close()
          this.getList()
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message({ type: 'info', message: '操作失败' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
