import request from '@/utils/request'

// 获取组织项目人员树，平级结构
export function getOrganUserTree(data) {
  return request({
    url: '/api/v1/organ/common/get_organ_user_tree',
    method: 'post',
    data
  })
}
// 查询组织人员信息
export function getOrganUserInfo(data) {
  return request({
    url: '/api/v1/organ/common/get_organ_user_info',
    method: 'post',
    data
  })
}
// 查询组织根信息

export function getRootOrganInfo(data) {
  return request({
    url: '/api/v1/organ/common/get_root_organ_info',
    method: 'post',
    data
  })
}
// 获取系统预设图标
export function getDefaultIcon(params) {
  return request({
    url: '/api/v1/application/enterprise/flowConfig/getDefaultIcon',
    method: 'get',
    params
  })
}
// 【选择角色】获取角色树
export function getRoleTree(data) {
  return request({
    url: '/api/v1/application/flowConfig/enterprise/choseRole/getRoleTree',
    method: 'post',
    data
  })
}
// 【选择角色】查询角色
export function getListRoleByUuid(data) {
  return request({
    url: '/api/v1/application/flowConfig/enterprise/choseRole/getListRoleByUuid',
    method: 'post',
    data
  })
}

// 审批流相关接口

// 【配置】获取系统流程模板详情

export function getSysTemTemInfo(params) {
  return request({
    url: '/api/v1/application/enterprise/flow/getSysTemTemInfo',
    method: 'get',
    params
  })
}
// 【配置】获取系统默认字段
export function getFlowInfo(params) {
  return request({
    url: '/api/v1/application/enterprise/flow/getListModelCategory',
    method: 'get',
    params
  })
}

// 【配置】获取流程模型详情
export function getModelInfo(params) {
  return request({
    url: '/api/v1/application/enterprise/flow/update/getFlowInfo',
    method: 'get',
    params
  })
}
// 【配置】保存流程模型
export function saveModelInfo(data) {
  return request({
    url: '/api/v1/application/enterprise/flowConfig/saveModelInfo',
    method: 'post',
    data
  })
}

// <------------------------------运营端---------------------------------->
// 【详情】详情
export function operatorFlowConfigSystemGetGetOne(params) {
  return request({
    url: '/api/v1/operator/flowConfig/system/get/getOne',
    method: 'get',
    params
  })
}
// 【新增/编辑】保存模型配置
export function flowConfigSystemAddUpdateSaveTemplate(data) {
  return request({
    url: '/api/v1/operator/flowConfig/system/addUpdate/saveTemplate',
    method: 'post',
    data
  })
}

// 【发起审批】获取应用流程信息
export function enterpriseFlowCreateGetFlowInfo(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/create/getFlowInfo',
    method: 'post',
    data
  })
}
// 【发起审批】提交审批
export function enterpriseFlowCreateComplete(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/create/complete',
    method: 'post',
    data
  })
}
// 【发起审批-条件】获取条件流程信息
export function getConditionFlow(data) {
  return request({
    url: '/api/v1/application/enterprise/flow/create/getConditionFlow',
    method: 'post',
    data
  })
}
