<template>
  <iframe :src="url" width="100%" height="100%" frameborder="0" />
</template>

<script>
const URL_MAP = {
  'dev-yky.youmatech.com': 'https://dev-zkypro.youmatech.com/auth.html',
  'test-yky.youmatech.com': 'https://test-zkypro.youmatech.com/auth.html',
  'yky.youmatech.com': 'https://zkypro.youmatech.com/auth.html'
}
export default {
  name: 'ApplicationZky',
  props: {},
  data() {
    return {
      url: '',
      clientHeight: 800
    }
  },
  created() {
    const env = window.location.host
    if (process.env.NODE_ENV === 'development') {
      this.url = 'https://dev-zkypro.youmatech.com'
    } else {
      this.url = URL_MAP[env]
    }
  },
  mounted() {
    const clientHeight = this.$el.parentElement.clientHeight
    this.clientHeight = clientHeight - 110
    window.alert('请不要使用该模块～')
  },
  methods: {}
}
</script>

