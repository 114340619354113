<template>
  <div class="g-newsInfo">
    <el-card class="newsInfo_card" shadow="never">
      <div class="go_back_common">
        <div class="go_back_title" @click="back">
          <span class="go_back_icon" />
          <span class="go_back_txt">返回</span>
        </div>
        <div class="go_back_lines" />
      </div>

      <h4 class="head-title">{{ infoData.noticeTitle }}</h4>
      <div class="flex-center ">
        <div class="flex-center mr-20 news-date">
          <div class="um-icon um_iconfont um_icon-time mr8 date-label" />
          {{ infoData.publishTime }}
        </div>
        <div class="flex-center mr-20 news-date">
          <div class="um-icon um_iconfont um_icon-xianshi mr8 date-label" />
          {{ infoData.views }}
        </div>
        <div class="flex-center news-date">
          <div class="date-label">发布企业：</div>
          {{ infoData.enterpriseName }}
        </div>
      </div>
      <div class="html-content">
        <umUrl :url="infoData.noticeInfoUrl" />
      </div>
    </el-card>
  </div>
</template>

<script>
import { query_notice_info } from '@/api/enterprise'
export default {
  name: 'NoticeInfo',
  data() {
    return {
      infoData: {
        noticeTitle: '',
        publishTime: '',
        views: '',
        noticeInfoUrl: '',
        enterpriseName: ''
      }
    }
  },
  created() {
    this.query_notice_info()
  },
  methods: {
    // 获取公告详情
    query_notice_info() {
      const load = this.$load()
      query_notice_info({
        noticeUuid: this.$route.query.noticeUuid
      }).then(d => {
        this.infoData = d.data
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    // 返回
    back() {
      // this.$router.push({ path: '/dashboard' })
      // this.$router.replace('/dashboard/notice')
      this.$router.back()
    }
  }
}
</script>

