<template>
  <div class="m-waitingPayment">
    <el-drawer
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="waitingPaymentBox">
        <div class="flex-column-align-center paymentText">
          <div class="u-paymentTitle mb20">待支付</div>
          <div class="u-paymentMoney flex-column-center">¥<div class="s-redColor">{{ money }}</div>元</div>
        </div>
        <div class="paymentContent">
          <div class="flex-column-center mb16">
            <div class="flex-column-center"><img src="@/assets/dashboard/paymentMethod.png" alt=""></div>
            <div class="s-paymentMethod">支付方式</div>
          </div>
          <el-radio-group v-for="item in paymentList" :key="item.id" v-model="payment" style="width:100%">
            <el-radio :label="item.payChannelCode" class="flex-between" style="flex-direction: row-reverse">
              <div class="flex-column-center">
                <img :src="item.payChannelLogoUrl" alt="" width="16px" height="16px">
                <div class="s-name">{{ item.payChannelName }}</div>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
        <el-button type="primary" class="paymentBtn" @click="zhifu">立即支付</el-button>
      </div>
    </el-drawer>
    <scanCode ref="scanCode" :close-visible="closeVisible" :ewm-url="ewmUrl" :payment="payment" :business-order-uuid="businessOrderUuid" @changeQrcode="changeQrcode" @changeShow="changeShow" />
    <install v-bind="$attrs" ref="install" :business-order-uuid="businessOrderUuid" :ordtype="ordtype" :install-visible="installVisible" :tab-type="tabType" @flagChange="flagChange" />
    <div v-if="successFlag" class="u-install flex-center">
      <success :business-order-uuid="businessOrderUuid" :tab-type="tabType" :ordtype="ordtype" />
    </div>
  </div>
</template>
<script>
import scanCode from './scanCode.vue'
import success from '../components/success.vue'
import { getQueryPaymentMethod, getPayOrderInfo } from '@/api/cloudMarket'
import Install from './install.vue'
export default {
  components: {
    scanCode,
    Install,
    success
  },
  props: {
    drawerFlag: {
      type: Boolean,
      default: false
    },
    businessOrderUuid: {
      type: String,
      default: ''
    },
    platformOrderNumber: {
      type: String,
      default: ''
    },
    tabType: {
      type: [String, Number],
      default: 1
    },
    ordtype: {
      type: [String, Number],
      default: 1
    },
    ispay: {
      type: [String, Number],
      default: 1
    }
  },
  data() {
    return {
      drawer: false,
      paymentList: [],
      payment: null,
      closeVisible: false,
      ewmUrl: '',
      expirationTime: '',
      money: 0,
      installVisible: false,
      timer: null,
      orderStatusCode: '',
      successFlag: false
    }
  },
  watch: {
    drawerFlag: {
      handler(val) {
        this.drawer = val
      },
      immediate: true
    },
    platformOrderNumber: {
      handler(val) {
        // eslint-disable-next-line vue/no-mutating-props
        if (val) this.platformOrderNumber = val
      },
      immediate: true
    }
  },
  mounted() {

  },
  created() {
  },
  methods: {
    // 立即支付
    zhifu() {
      if (this.payment) {
        // 支付成功调应用安装界面
        if (this.orderStatusCode == '1206502') {
          this.$message.success('购买成功')
          if (this.tabType == 2 || this.tabType == 3) {
            this.installVisible = true // 应用安装页面
            this.$refs.install.getStar()
            this.closeVisible = false
          } else if (this.tabType == 4 || this.tabType == 5) {
            this.closeVisible = false
            this.successFlag = true
          }
        } else {
          // 订单状态为支付中或者待支付展示二维码，调用定时器刷新订单状态，调用刷新二维码的遮罩层
          if (this.money > 0) {
            this.closeVisible = true
            this.$refs.scanCode.getPayQrCode() // 调用二维码
            this.timer = setInterval(() => {
              this.getPayOrderInfo()
            }, 1000)
          }
          if (this.money == 0) {
            this.$refs.scanCode.getPayQrCode()
          }
        }
      } else {
        this.$message.error('请先选择支付方式')
      }
    },
    changeShow() {
      this.closeVisible = false
      clearInterval(this.timer)
      this.timer = null
    },
    changeQrcode(val) {
      if (this.payment) {
        // 支付成功调应用安装界面
        if (val == '1206502') {
          this.$message.success('购买成功')
          if (this.tabType == 2 || this.tabType == 3) {
            this.installVisible = true // 应用安装页面
            this.$refs.install.getStar()
            this.closeVisible = false
          } else if (this.tabType == 4 || this.tabType == 5) {
            this.closeVisible = false
            this.successFlag = true
          }
        }
      } else {
        this.$message.error('请先选择支付方式')
      }
    },
    flagChange() {
      this.installVisible = false
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.$emit('changeShow', 'false')
          this.getClose()
        })
        .catch(_ => {})
    },
    getCheck(id) {
      console.log(this.payment)
      this.payment = id
      console.log(this.payment)
    },
    // 查询订单信息
    getPayOrderInfo() {
      getPayOrderInfo({ businessOrderUuid: this.businessOrderUuid }).then(res => {
        this.money = res.data.orderAmount
        this.orderStatusCode = res.data.orderStatusCode
        if (this.orderStatusCode === '1206502') {
          clearInterval(this.timer)
          this.timer = null
          this.zhifu()
        } else if (this.orderStatusCode === '1206507') {
          clearInterval(this.timer)
          this.timer = null
          if (this.ispay == 2) {
            this.$message.success('已在app端支付')
            this.closeVisible = false
            this.$parent.getClose()
            this.$emit('changeShow', 'false')
          }
          if (this.ispay == 3) {
            this.$message.success('已在app端支付')
            this.closeVisible = false
            this.$parent.getClose()
            this.$emit('changeShow', 'false')
          }
        }
      }).catch(e => {
        if (e.code == 4099) {
          this.$refs.scanCode.getClose()
          this.getClose()
        }
      })
    },
    // 支付方式
    getQueryPaymentMethod() {
      // const load = this.$load()
      const params = {
        platformOrderNumber: this.platformOrderNumber,
        clientType: 1
      }
      getQueryPaymentMethod(params).then(res => {
        this.paymentList = res.data
        this.payment = res.data[0].payChannelCode
        // load.close()
      }).catch(e => {
        // load.close()
      })
    },
    getClose() {
      if (this.tabType == 3) {
        this.$parent.getClose()
        this.$emit('changeShow', 'false')
      } else {
        this.$parent.getClose()
        this.successFlag = false
        this.$emit('changeShow', 'false')
      }
    }

  }
}
</script>

