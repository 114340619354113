<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <!--        <search id="header-search" class="right-menu-item" />-->

        <!--        <error-log class="errLog-container right-menu-item hover-effect" />-->

        <!--        <screenfull id="screenfull" class="right-menu-item hover-effect" />-->

        <!--        <el-tooltip content="Global Size" effect="dark" placement="bottom">-->
        <!--          <size-select id="size-select" class="right-menu-item hover-effect" />-->
        <!--        </el-tooltip>-->
      </template>

      <div class="avatar-container right-menu-item hover-effect">
        <div class="avatar-wrapper">
          <el-avatar size="medium" icon="el-icon-user-solid" :src="avatar" />
          <div class="name">{{ name || '管理人员' }}</div>
          <div class="layout-wrapper" @click="logout">
            <i class="el-icon-switch-button" />
          </div>
        </div>
      </div>
      <!--      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="hover">-->
      <!--        <div class="avatar-wrapper">-->
      <!--          <el-avatar size="medium" icon="el-icon-user-solid" src="https://up.enterdesk.com/edpic/e8/ea/6b/e8ea6b847337f93065011c9b7c84ac03.jpg"></el-avatar>-->
      <!--          <i class="el-icon-caret-bottom" />-->
      <!--        </div>-->
      <!--        <el-dropdown-menu slot="dropdown">-->
      <!--          <el-dropdown-item divided @click.native="logout">-->
      <!--            <span style="display:block;">退出登录</span>-->
      <!--          </el-dropdown-item>-->
      <!--        </el-dropdown-menu>-->
      <!--      </el-dropdown>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Hamburger from '@/components/Hamburger'

export default {
  components: {
    Hamburger
  },
  computed: {
    ...mapGetters([
      'avatar',
      'device',
      'name'
    ])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      .avatar-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
        .name {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 25px;
          margin-left: 10px;
        }
        .layout-wrapper {
          margin-left: 20px;
          font-size: 20px;
          color: #333;
          transition: all 0.4s ease-in-out;
          &:hover {
            transform: scale(1.2);
          }
        }
        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }

    }
  }
}
</style>
