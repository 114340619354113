<template>
  <el-image class="um-image" v-bind="$attrs">
    <div slot="error" class="image-slot">
      <i class="el-icon-picture" />
    </div>
  </el-image>
</template>

<script>
export default {
  name: 'UmImage'
}
</script>

<style lang="scss">
.um-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .image-slot {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
