import { get_list_dict } from '@/api/common'
import { ENUM_CODE, ENUM_PARAMS_CODE } from '@/enum'
import jsonp from 'jsonp'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

/**
 * 根据父级code获取条件列表
 * @param code
 * @returns {*|*[]}
 */
export function getParamsCode(code) {
  return ENUM_PARAMS_CODE.get(code) || []
}

/**
 *
 * @param list1 比较的第一个数组
 * @param list2 比较的第二个数组
 * @returns {boolean}
 */

export function sameList(list1, list2) {
  if (!Array.isArray(list1) || !Array.isArray(list2)) {
    return false
  }
  return list1.some(item => list2.includes(item))
}

/**
 * 获取全量字典表
 * @returns {Promise<any>}
 */
export async function getListDict() {
  const res = await get_list_dict()
  ENUM_CODE.clear() // 清除枚举key
  ENUM_PARAMS_CODE.clear() // 清除枚举列表

  res.data.forEach(item => {
    ENUM_CODE.set(item.dictCode, item.dictName)
    if (ENUM_PARAMS_CODE.get(item.parentCode)) {
      ENUM_PARAMS_CODE.get(item.parentCode).push(item)
    } else {
      ENUM_PARAMS_CODE.set(item.parentCode, [item])
    }
  })
  return ENUM_CODE
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        // eslint-disable-next-line prefer-regex-literals
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

// 错误处理
export function errorHandle(d) {
  //
  // if (d.name == 'TypeError') {
  //   const url = 'https://error.singforyou.cn/jsonp?error=' + d
  //   jsonp(url, null, (err, data) => {
  //     if (err) {
  //
  //     } else {
  //
  //     }
  //   })
  //   return
  // }
  if (d.name == 'TypeError') {
    return
  }
  if (typeof this.$refs !== 'object') return false
  // if (d.state === 'logout') {
  //
  //   this.$message.error('登录失效', 1)
  //   return false
  // }
  // if (d.state === 'noAuthorize') {
  //
  //   this.$message.error('没有权限', 1)
  //   return false
  // }
  if (d.error && typeof d.error === 'object') {
    for (const key in d.error) {
      if (this.$refs[key] !== undefined) {
        if (this.$refs[key] instanceof Array) {
          this.$refs[key][0].validateState = 'error'
          this.$refs[key][0].validateMessage = d.error[key]
        } else {
          this.$refs[key].validateState = 'error'
          this.$refs[key].validateMessage = d.error[key]
        }
      } else {
        // 去除日期格式的Begin 和 End 然后再做匹配
        const key_replace = key.replace(/Begin|End/g, '')
        if (this.$refs[key_replace] !== undefined) {
          if (this.$refs[key_replace] instanceof Array) {
            this.$refs[key_replace][0].validateState = 'error'
            this.$refs[key_replace][0].validateMessage = d.error[key]
          } else {
            this.$refs[key_replace].validateState = 'error'
            this.$refs[key_replace].validateMessage = d.error[key]
          }
        } else {
          // this.$message.error(JSON.stringify(d.error) || '操作失败', 10) // todo
        }
      }
    }
    // this.$message.error(d.msg || '操作失败', 1)
    return false
  }
  // if (d.msg !== '') {
  //   this.$message.error(d.msg, 1)
  //   return false
  // }
  // this.$message.error('操作失败', 1)
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}
export function digitUppercase(n = 0) {
  const fraction = ['角', '分']
  const digit = [
    '零', '壹', '贰', '叁', '肆',
    '伍', '陆', '柒', '捌', '玖'
  ]
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ]
  const head = n < 0 ? '欠' : ''
  n = Math.abs(n)
  let s = ''
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '')
  }
  s = s || '整'
  n = Math.floor(n)
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = ''
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p
      n = Math.floor(n / 10)
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
  }
  return head + s.replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整')
}
/*
* 批量处理分页 序号问题
 */
export function handlePage(res) {
  if (res.data && res.data.list && res.data.page && res.data.page.total) {
    // const total = res.data.page.total
    const pageSize = res.data.page.pageSize
    const pageNum = res.data.page.pageNum
    res.data.list.forEach((item, index) => {
      const num = index + pageSize * (pageNum - 1) + 1
      item.xh = num > 9 ? num : '0' + num
    })
  }
  return res
}
// 下载文件
export function createLink(response) {
  const data = response.data
  const blob = new Blob([data], { type: response.headers['content-type'] })
  const blobUrl = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.download = decodeURI(response.headers['filename']) || '文件.xls'
  a.href = blobUrl
  a.click()
}

/**
 * 地理逆编码
 * @param lng
 * @param lat
 * @returns {Promise<unknown>}
 */
export function reverseGeocoding(lng, lat) {
  return new Promise((resolve, reject) => {
    const url = 'https://api.map.baidu.com/reverse_geocoding/v3/?output=json&coordtype=wgs84ll&location=' + lat + ',' + lng + '&from=1&to=5&ak=7VmSeDf2DLt6uAssxna6s0VN5SP3fKIb'
    jsonp(url, null, (err, data) => {
      if (err) {
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
}

/**
 * 获取本机标识
 */
export async function getfingerid() {
  const fp = await FingerprintJS.load({
    monitoring: false // 关闭向 FingerprintJS 服务器发送 AJAX 请求以收集使用情况统计信息
  })
  const result = await fp.get()
  console.log(result.visitorId, '本机标识ID------------')
  return result.visitorId
}
