import request from '@/utils/request'

// 分页获取户型列表
export function house_type_list(data) {
  return request({
    url: '/api/v1/data/house_type/page/list',
    method: 'post',
    data
  })
}
// 新增户型
export function house_type_insert(data) {
  return request({
    url: '/api/v1/data/house_type/insert',
    method: 'post',
    data
  })
}

// 编辑户型
export function house_type_update(data) {
  return request({
    url: '/api/v1/data/house_type/update',
    method: 'post',
    data
  })
}

// 删除户型
export function house_type_del(params) {
  return request({
    url: '/api/v1/data/house_type/delete',
    method: 'get',
    params
  })
}

// 户型详情
export function house_type_detail(params) {
  return request({
    url: '/api/v1/data/house_type/detail',
    method: 'get',
    params
  })
}

// 房间部位列表
export function building_part_list(params) {
  return request({
    url: '/api/v1/data/bus_building_part/building_part_list',
    method: 'get',
    params
  })
}
