<template>
  <div class="industry-wrap">
    <template v-if="list&&list.length">
      <div
        v-for="(item,index) in list"
        :key="index"
        class="m-industry"
        :class="{ active: categoryCode == item.categoryCode}"
        @click="choseHanlder(item)"
      >
        {{ item.categoryName }}
      </div>
    </template>
    <div v-else style="margin-left: 10px;font-size: 14px;color: #c3c5cb">
      暂无行业
    </div>
  </div>
</template>

<script>
import emitter from '@um/element-ui/lib/mixins/emitter'
export default {
  name: 'UmIndustry',
  mixins: [emitter],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      categoryCode: ''
    }
  },
  watch: {
    list: {
      handler(val) {
        if (val && val.length) {
          //
        }
      },
      deep: true
    },
    value: {
      handler(val) {
        this.categoryCode = val
      }
    }
  },
  methods: {
    choseHanlder(item) {
      this.categoryCode = item.categoryCode
      this.$emit('change', item.categoryCode)
      this.dispatch('ElFormItem', 'el.form.change', item.categoryCode)
    }
  }
}
</script>

<style scoped lang="scss">
.industry-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  transform: translateX(-10px);
  @at-root .m-industry {
    //& + & {
      margin-left: 20px;
    //}
    //width: 82px;
      margin: 0 10px;
    //height: 32px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #E4E8EB;
    padding: 0 13px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #6C7B96;
    cursor: pointer;
    &.active {
      background: #F7F9FF;
      border-radius: 4px;
      border: 1px solid #3D80FC;
      color: #3D80FC;
    }
  }
}
</style>
