<template>
  <div class="infoData">
    <div class="go_back_common">
      <div class="go_back_title" @click="$router.replace('/mainData/contractor')">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <el-radio-group v-model="tab" class="mb20">
      <el-radio-button :label="1">基础信息</el-radio-button>
      <el-radio-button :label="2">成员管理</el-radio-button>
    </el-radio-group>
    <div v-if="tab === 1">
      <div v-show="isedit === 2">
        <el-form
          ref="infoForm"
          :model="infoForm"
          label-width="140px"
        >
          <el-form-item
            ref="contractorFullName"
            label="承建商全称"
            prop="contractorFullName"
            style="width: auto"
          >
            <div>
              <span>{{ infoForm.contractorFullName||'-' }}</span>
              <span v-permission="['112040906']" style="float: right"><el-button type="primary" @click="editpart">编辑</el-button></span>
            </div>
          </el-form-item>
          <el-form-item
            ref="contractorName"
            label="承建商简称"
            prop="contractorName"
          >
            {{ infoForm.contractorName||'-' }}
          </el-form-item>
          <el-form-item
            ref="contractorTypeCode"
            label="承建商类别"
            prop="contractorTypeCode"
          >
            {{ contractorInfo[infoForm.contractorTypeCode]||'-' }}
          </el-form-item>
          <el-form-item
            ref="contractorUnifiedNo"
            label="社会信用统一代码"
            prop="contractorUnifiedNo"
          >
            {{ infoForm.contractorUnifiedNo||'-' }}
          </el-form-item>
          <el-form-item
            ref="contractorRegistrationNo"
            label="工商注册号"
            prop="contractorRegistrationNo"
          >
            {{ infoForm.contractorRegistrationNo||'-' }}
          </el-form-item>
          <el-form-item
            ref="contractorResponsiblePerson"
            label="负责人"
            prop="contractorResponsiblePerson"
          >
            {{ infoForm.contractorResponsiblePerson||'-' }}
          </el-form-item>
          <el-form-item ref="contractorContact" label="联系人" prop="contractorContact">
            {{ infoForm.contractorContact||'-' }}
          </el-form-item>
          <el-form-item
            ref="contractorMobile"
            label="联系电话"
            prop="contractorMobile"
          >
            {{ infoForm.contractorMobile||'-' }}
          </el-form-item>
          <el-form-item ref="contractorAddress" label="承建商地址" prop="contractorAddress">
            {{ infoForm.contractorAddress||'-' }}
          </el-form-item>
          <el-form-item ref="contractorRemark" label="备注" prop="contractorRemark">
            <span>{{ infoForm.contractorRemark||'-' }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="isedit === 1">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="140px"
        >
          <el-form-item
            ref="contractorFullName"
            label="承建商全称1"
            prop="contractorFullName"
          >
            <el-input
              v-model="ruleForm.contractorFullName"
              style
              clearable
              :maxlength="50"
              placeholder="请输入承建商全称"
            />
          </el-form-item>
          <el-form-item
            ref="contractorName"
            label="承建商简称"
            prop="contractorName"
          >
            <el-input
              v-model="ruleForm.contractorName"
              style
              clearable
              :maxlength="50"
              placeholder="请输入承建商简称"
            />
          </el-form-item>
          <el-form-item
            ref="contractorTypeCode"
            label="承建商类别"
            prop="contractorTypeCode"
          >
            <el-select v-model="ruleForm.contractorTypeCode" style="width:100%" placeholder="请选择承建商类别">
              <el-option v-for="(item,i) in contractorList" :key="i" :value="item.value" :label="item.label" />
            </el-select>
          </el-form-item>
          <el-form-item
            ref="contractorUnifiedNo"
            label="社会信用统一代码"
            prop="contractorUnifiedNo"
            :rules="[
              {
                min: 0,
                max: 50,
                message: '长度在 0 到 50 个字符',
                trigger: 'blur',
                required: false
              }
            ]"
          >
            <el-input
              v-model="ruleForm.contractorUnifiedNo"
              maxlength="50"
              clearable
              placeholder="请输入社会信用统一代码"
            />
          </el-form-item>
          <el-form-item
            ref="contractorRegistrationNo"
            label="工商注册号"
            prop="contractorRegistrationNo"
          >
            <el-input
              v-model="ruleForm.contractorRegistrationNo"
              style
              clearable
              maxlength="50"
              placeholder="请输入工商注册号"
            />
          </el-form-item>
          <el-form-item
            ref="contractorResponsiblePerson"
            label="负责人"
            prop="contractorResponsiblePerson"
          >
            <el-input
              v-model="ruleForm.contractorResponsiblePerson"
              style
              maxlength="20"
              clearable
              placeholder="请输入负责人"
            />
          </el-form-item>
          <el-form-item ref="contractorContact" label="联系人" prop="contractorContact">
            <el-input
              v-model="ruleForm.contractorContact"
              style
              maxlength="20"
              clearable
              placeholder="请输入联系人"
            />
          </el-form-item>
          <el-form-item
            ref="contractorMobile"
            label="联系电话"
            prop="contractorMobile"
          >
            <el-input
              v-model="ruleForm.contractorMobile"
              style
              clearable
              maxlength="20"
              placeholder="请输入联系电话"
            />
          </el-form-item>
          <el-form-item ref="contractorAddress" label="承建商地址" prop="contractorAddress">
            <el-input
              v-model="ruleForm.contractorAddress"
              style
              clearable
              maxlength="50"
              placeholder="请输入承建商地址"
            />
          </el-form-item>
          <el-form-item ref="contractorRemark" label="备注" prop="contractorRemark">
            <el-input
              v-model="ruleForm.contractorRemark"
              type="textarea"
              style
              maxlength="500"
              :rows="6"
              show-word-limit
              placeholder="请输入备注"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
            >
              确认保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <membermanagement v-if="tab === 2" :contractor-uuid="contractorUuid" />
  </div>
</template>

<script>
import { contractor_info, contractor_update } from '@/api/mainData/contractor'
import membermanagement from '@/views/mainData/contractor/info/components/membermanagement.vue'
export default {
  name: 'MainDataContractorInfo',
  components: { membermanagement },
  props: {},
  data() {
    return {
      tab: 1,
      isedit: 2, // 1 表示编辑 2 详情
      infoForm: {}, // 详情
      contractorUuid: '',
      ruleForm: {
        contractorFullName: '',
        contractorName: '',
        contractorUnifiedNo: '',
        contractorRegistrationNo: '',
        contractorResponsiblePerson: '',
        contractorContact: '',
        contractorMobile: '',
        contractorAddress: '',
        contractorRemark: '',
        contractorTypeCode: ''
      },
      rules: {
        contractorFullName: [
          { required: true, message: '请输入承建商全称', trigger: 'change' }
        ],
        contractorName: [
          { required: true, message: '请输入承建商简称', trigger: 'change' }
        ],
        contractorTypeCode: [
          { required: true, message: '请选择承建商类别', trigger: 'change' }
        ]
      },
      contractorInfo: {
        '1012501': '维保单位',
        '1012502': '第三方维保单位'
      },
      contractorList: [
        { value: 1012501, label: '维保单位' },
        { value: 1012502, label: '第三方维保单位' }
      ]

    }
  },
  computed: {},
  watch: {
    tab: {
      handler(val) {
        if (val == 1) {
          this.isedit = 2
          this.getInfo()
        }
      }
    }
  },
  created() {
    if (this.$route.query.contractorUuid) {
      this.contractorUuid = this.$route.query.contractorUuid
      this.getInfo()
    }
  },
  mounted() {},
  methods: {
    editpart() {
      this.editinfo()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const load = this.$load()
          contractor_update({
            ...this.ruleForm,
            // contractorTypeCode: '20252010',
            contractorUuid: this.contractorUuid
          })
            .then((d) => {
              load.close()
              this.$alert('修改成功！', '提示', {
                type: 'success',
                showClose: false
              }).then((d) => {
                // this.$router.push('/mainData/contractor/index')
                this.$router.replace('/mainData/contractor')
              })
            })
            .catch((e) => {
              this.$errorHandle(e)
              load.close()
            })
        } else {
          this.$message.error('请填写正确的表单！')
          return false
        }
      })
    },
    getInfo() {
      const load = this.$load()
      contractor_info({ contractorUuid: this.contractorUuid }).then(d => {
        this.infoForm = d.data
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    editinfo() {
      const load = this.$load()
      contractor_info({ contractorUuid: this.contractorUuid }).then(d => {
        this.infoForm = d.data
        Object.assign(this.ruleForm, d.data)
        // this.ruleForm.contractorFullName = d.data.contractorFullName
        // this.ruleForm.contractorName = d.data.contractorName
        // this.ruleForm.contractorTypeCode = d.data.contractorTypeCode
        // this.ruleForm.contractorUnifiedNo = d.data.contractorUnifiedNo
        // this.ruleForm.contractorRegistrationNo = d.data.contractorRegistrationNo
        // this.ruleForm.contractorResponsiblePerson = d.data.contractorResponsiblePerson
        // this.ruleForm.contractorContact = d.data.contractorContact
        // this.ruleForm.contractorMobile = d.data.contractorMobile
        // this.ruleForm.contractorAddress = d.data.contractorAddress
        // this.ruleForm.contractorRemark = d.data.contractorRemark
      }).finally(_ => {
        load.close()
        this.isedit = 1
      }).catch(e => {
        load.close()
        this.isedit = 1
        this.$errorHandle(e)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
