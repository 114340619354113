<template>
  <div class="login_qr">
    <!-- <div class="qrline" /> -->
    <div class="qrtext">请使用<span class="qfycolor">优客云APP</span>扫描二维码</div>
    <div class="qrcodebox">
      <div class="qrcode">
        <el-image
          v-loading="imgloading"
          :src="url"
          fit="fill"
          style="width: 100%; height: 100%;padding:20px"
          @click="getCode"
        />
        <div
          v-if="overtime"
          :class="{disones:overtime,disone:!overtime}"
          class="over-wrap"
          @click="getCode"
        >
          <span class="rqimg" />
        </div>
        <div
          v-if="overtime"
          class="suretext"
        >
          二维码已过期，点击重新获取
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { intoEnterprise, login_qrcode, scan_qrcode } from '@/api/user'

export default {
  name: 'LoginQrCode',
  data() {
    return {
      imgloading: false,
      overtime: false,
      url: '',
      qrUuid: '',
      timeTag: null,
      timeCount: 0
    }
  },
  mounted() {
  },
  created() {
    this.getCode()
  },
  beforeDestroy() {
    if (this.timeTag) window.clearTimeout(this.timeTag)
  },
  methods: {
    getCode() {
      this.timeCount = 0
      this.imgloading = true
      this.overtime = false
      login_qrcode().then(res => {
        if (this.timeTag) window.clearTimeout(this.timeTag)
        const { expiration, qrHttPath, qrUuid } = res.data
        this.url = qrHttPath
        this.qrUuid = qrUuid
        this.getLoginStatus()
      }).finally(_ => {
        this.imgloading = false
      })
    },
    /**
     * 轮询查询二维码扫码状态
     */
    getLoginStatus() {
      this.timeCount += 2

      if (this.timeCount >= 5 * 24) {
        this.overtime = true
        this.timeCount = 0
        if (this.timeTag) window.clearTimeout(this.timeTag)
        return
      }
      this.timeTag = window.setTimeout(() => {
        scan_qrcode({
          qrUuid: this.qrUuid
        }).then(res => {
          const { statusCode, token } = res.data
          if (statusCode == 0) {
            this.getLoginStatus()
          }
          if (statusCode == 1) {
            this.overtime = true
          }
          if (statusCode == 2 && token) {
            this.$message.success('已在手机端确认登录')
            this.$store.commit('user/SET_TOKEN', token)
            this.$store.dispatch('user/getStatus').then(res => {
              if (!res.accelerated) { // 未完善个人信息
                this.$emit('perfectInfo')
                return
              }
              // 注释此逻辑，扫码登录时，app已登录，所以直接进入当前企业
              // if (!res.enterpriseDefaultFlag) { // 未选择默认企业
              //   this.$emit('choseEnterprise')
              //   return
              // }
              if (!res.enterpriseUuid) { // 没有当前企业
                this.$emit('choseEnterprise')
                return
              }
              const load = this.$load()
              intoEnterprise({
                enterpriseUuid: res.enterpriseUuid,
                defaultFlag: 1
              }).then(res => {
                this.$router.push({ path: this.redirect || '/im', query: this.otherQuery })
              }).catch(_ => {
                this.$store.dispatch('user/resetToken')
              }).finally(_ => {
                load.close()
              })
            })
          }
        })
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
.login_qr {
  .qrline {
    width: 370px;
    height: 2px;
    margin: 20px auto 0;
    background: linear-gradient(90deg, rgba(247, 248, 251, 0) 0%, #ECEEF5 47%, rgba(236, 238, 245, 0) 100%);
  }

  .qrcodebox {
    width: 240px;
    height: 240px;
    margin: 0 auto;
    box-shadow: 0px 6px 14px 0px rgba(49, 65, 95, 0.14);
    border-radius: 4px;

    .qrcode {
      position: relative;
      width: 240px;
      height: 240px;
      // padding: 20px;
      // background-color: #8c939d;
      .over-wrap {
        position: absolute;
        top: 0;
        right: 0;
        width: 240px;
        height: 240px;
        text-align: center;
        line-height: 240px;
        background: rgba(49, 65, 95, 0.7);
        backdrop-filter: blur(2px);

        .rqimg {
          cursor: pointer;
          display: inline-block;
          margin: 94px;
          width: 52px;
          height: 52px;
          // background-color: red;
          background: url("~@/assets/login/reloading.png") no-repeat 0 0 / 52px 52px;

        }
      }

      .suretext {
        // position: absolute;
        // top: 0;
        // right: 0;
        // width: 240px;
        // height: 240px;
        // text-align: center;
        // line-height: 240px;
        width: 100%;
        height: 16px;
        margin: 20px auto 0;
        text-align: center;
        font-size: 16px;
        color: #FF6161;
        // background: rgba(49, 65, 95, 0.7);
      }

      .disone {
        display: none;
      }

      .disones {
        display: block;
      }

      ::v-deep .el-image .errortext {
        position: absolute;
        top: 0;
        right: 0;
        width: 240px;
        height: 240px;
        cursor: pointer;
        color: #fff;
        font-size: 52px;
        text-align: center;
        line-height: 240px;
        background: rgba(49, 65, 95, 0.5);
        backdrop-filter: blur(1px);
      }
    }
  }

  .qrtext {
    text-align: center;
    margin: 30px auto 20px;
    font-size: 14px;
    width: 196px;
    font-weight: 500;
    color: #31415F;

    .qfycolor {
      color: #3D80FC;
    }
  }
}
</style>
