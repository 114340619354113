
<template>
  <div class="um-step">
    <div class="title">{{ title }}</div>
    <ul v-if="!isCheck" class="um-step-ul">
      <li v-for="(item,index) in list" :key="index" class="um-step-li">
        <div class="line" />
        <div
          :class="item.nodeTypeCode==='approver'&&(item.nodeStatusCode===31||item.nodeStatusCode===32)
            ?'roundSpTh':item.nodeTypeCode==='approver'
              ?'roundSp':item.nodeTypeCode==='notifier'?'roundCs':''"
        />
        <div class="content">
          <div v-if="item.nodeSignTypeCode===0" class="person">{{ item.nodeName }}</div>
          <div v-else class="person">加签审批人 </div>
          <div v-for="(it,ix) in item.approvalUserList" :key="ix" class="list">
            <div class="list-item ">
              <div class="list-l">
                <echo-user
                  :value="[it.approvalUserUuid]"
                  :show-prefix-button="false"
                  :type="2"
                  :show-tab="false"
                />
              </div>
              <div class="list-r">
                <span
                  v-if="item.nodeTypeCode==='approver'"
                  :class="it.approvalStatusCode===50||it.approvalStatusCode===51?'list-r-th':it.approvalStatusCode===40?'list-r-ty':it.approvalStatusCode===30?'list-r-sp':''"
                  class="mr8"
                >{{ it.approvalStatusCode| statusCode }}</span>
                <span :class="it.approvalStatusCode===50||it.approvalStatusCode===51?'list-r-th':it.approvalStatusCode===40?'list-r-ty':it.approvalStatusCode===30?'list-r-sp':''">{{ it.approvalTime| parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
              </div>
            </div>
            <div v-if="it.approvalStatusCode===40||it.approvalStatusCode===50" class="list-yijian">审批意见：{{ it.approvalRemark }}</div>
            <um-previews :preview-file-list="it.files" />
          </div>
        </div>
      </li>
    </ul>
    <el-radio-group v-else v-model="check" style="display: block;margin-top: 16px" @change="onChange">
      <ul class="um-step-ul">
        <li v-for="(item,index) in list" :key="index" class="um-step-li">
          <div class="line" />
          <div
            :class="item.nodeTypeCode==='approver'&&(item.nodeStatusCode===31||item.nodeStatusCode===32)
              ?'roundSpTh':item.nodeTypeCode==='approver'
                ?'roundSp':item.nodeTypeCode==='notifier'?'roundCs':''"
          />
          <div class="content">
            <div class="person">
              {{ item.nodeName }}
              <el-radio style="" :label="item.wfProcinstNodeUuid">
                <span style="display: none" />
              </el-radio>
            </div>
            <div v-for="(it,ix) in item.approvalUserList" :key="ix" class="list">
              <div class="list-l">
                <echo-user
                  :value="[it.approvalUserUuid]"
                  :show-prefix-button="false"
                  :type="2"
                  :show-tab="false"
                />
              </div>
              <div class="list-r">
                <span v-if="item.nodeTypeCode==='approver'" :class="it.approvalStatusCode===50||it.approvalStatusCode===51?'list-r-th':it.approvalStatusCode===40?'list-r-ty':it.approvalStatusCode===30?'list-r-sp':''" class="mr8">{{ it.approvalStatusCode| statusCode }}</span>
                <span :class="it.approvalStatusCode===50||it.approvalStatusCode===51?'list-r-th':it.approvalStatusCode===40?'list-r-ty':it.approvalStatusCode===30?'list-r-sp':''">{{ it.approvalTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                <!--                <div :class="it.approvalStatusCode===50?'list-r-th':it.approvalStatusCode===40?'list-r-ty':''">-->
                <!--                  {{ it.approvalStatusCode| statusCode }}&nbsp;&nbsp;{{ it.approvalTime }}-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </li>
      </ul>
    </el-radio-group>
  </div>
</template>

<script>
import EchoUser from './echoUser'
import umPreviews from '../UmPreviews'
export default {
  name: 'UmStep',
  components: { EchoUser, umPreviews },
  filters: {
    statusCode(val) {
      let name = ''
      switch (val) {
        case 10:
          name = '无需审批'
          break
        case 20:
          name = '待开始'
          break
        case 30:
          name = '待审批'
          break
        case 40:
          name = '审批通过'
          break
        case 50:
          name = '审批拒绝'
          break
        case 51:
          name = '审批退回'
          break
        case 60:
          name = '已跳过'
          break
      }
      return name
    }
  },
  props: {
    title: {
      type: String,
      default: '审批流程'
    },
    isCheck: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }

  },
  data() {
    return {
      check: ''
    }
  },
  methods: {
    onChange(value) {
      this.$emit('changeRadio', value)
    }
  }
}
</script>

<style scoped lang="scss">
.um-step{
  max-width: 400px;
  padding-right: 20px;
  .title{
    font-weight: 600;
    font-size: 14px;
    color: #31415F;
  }
    .um-step-ul{
      padding-left: 0;
      .um-step-li{
        position: relative;
        display: flex;
        .line{
          position: absolute;
          left: 10px;
          top: 30px;
          bottom: 10px;
          border-right: 1px dashed #E4E8EB;
        }
        &:last-child .line{
          display: none;
        }
        .roundSp{
          width: 20px;
          height: 20px;
          background: url("~@/assets/common/icon-sp.png") no-repeat;
          background-size: 100% 100%;
          z-index: 9;
          flex-shrink: 0;
        }
        .roundCs{
          width: 20px;
          height: 20px;
          background: url("~@/assets/common/icon-cs.png") no-repeat;
          background-size: 100% 100%;
          z-index: 9;
        }
        .roundSpTh{
          width: 20px;
          height: 20px;
          background: url("~@/assets/common/icon-spth.png") no-repeat;
          background-size: 100% 100%;
          z-index: 9;
          flex-shrink: 0;
        }
        .content{
          margin-left: 10px;
          flex-grow:1;
          overflow: hidden;
          .person{
            font-size: 14px;
            line-height: 20px;
            color: #31415F;
            position:relative;
            ::v-deep .el-radio{
              position: absolute;
              right: 0;
              top: 0;
            }
          }
          .list{
            padding: 13px 0 0;
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            .list-item{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .list-l{
              display: flex;
              align-items: center;
            }
            .list-r{
              font-size: 12px;
              .list-r-ty{
                color: #36C35A;
              }
              .list-r-th{
                color: #FF6161;
              }
              .list-r-sp{
                color: #f69952;
              }
            }

            &:last-child{
              padding-bottom: 33px;
            }
            .list-yijian{
              font-size: 14px;
              font-weight: 600;
              color: #31415F;
              margin-top: 12px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
}
</style>
