<template>
  <div>
    <div class="approval_back title-back" @click="getBack()">返回</div>
    <UmFlowStart
      ref="flowStart"
      :app-icon="appIcon"
      :app-name="appName"
      class="mt20"
      :flow-info-api="flowInfoApi"
      :create-complete-api="createCompleteApi"
      :get-condition-flow-api="getConditionFlowApi"
      @submit="getBack"
    />
  </div>
</template>

<script>
import {
  enterpriseFlowCreateGetFlowInfo,
  enterpriseFlowCreateComplete,
  getConditionFlow
} from '@/api/flow'

export default {
  name: 'ApproveAdd',
  data() {
    return {
      appUuid: '',
      appName: '',
      appIcon: ''
    }
  },
  watch: {
  },
  created() {
    this.appUuid = this.$route.query.appUuid
    this.appName = this.$route.query.appName
    this.appIcon = this.$route.query.appIcon
  },
  mounted() {
    this.$refs.flowStart.init()
  },
  methods: {
    flowInfoApi() {
      return enterpriseFlowCreateGetFlowInfo({ appUuid: this.appUuid })
    },
    createCompleteApi(params) {
      params.appUuid = this.appUuid
      return enterpriseFlowCreateComplete(params)
    },
    getConditionFlowApi(params) {
      return getConditionFlow(params)
    },
    getBack() {
      this.$router.replace('/dashboard/approve')
    }
  }

}
</script>

