<template>
  <div class="m_applylist">
    <el-dialog
      :visible.sync="invitations"
      center
      title="用户申请"
      width="528px"
      :append-to-body="false"
      @close="close"
    >
      <div v-if="applylist&&applylist.length" class="m_applylist_content">
        <el-scrollbar style="height: 100%">
          <div v-for="item in applylist" :key="item.userUuid" class="m_applylist_item">
            <div class="m_item_lpart">
              <div class="u_item_pic">
                <el-image
                  style="width: 100%; height: 100%;border-radius: 4px"
                  :src="item.userImageUrl"
                  fit="fit"
                >
                  <div slot="error" class="image-slot">
                    <!--                      <i class="el-icon-user-solid" />-->
                    <div class="compoangnodata" />
                  </div>
                </el-image>
              </div>
              <div class="m_item_txt">
                <div class="row_1">{{ item.nickName }}</div>
                <div class="u_item_telnum row_1">{{ item. userMobile }}</div>
              </div>
            </div>
            <div class="m_item_rpart">
              <div>{{ item.createTime }}</div>
              <div class="u_item_state" @click="getapplydetail(item)">查看详情</div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div v-else class="m-noData m_applylist_content">
        <div>暂无数据</div>
      </div>
      <div v-if="3>9" class="m_applylist_btn">
        <div><el-button type="primary" plain>取消</el-button></div>
        <div class="u_btn_su"><el-button type="primary">确定</el-button></div>
      </div>
    </el-dialog>
    <ApplyAction :pu-user-uuid="puUserUuid" :invitationaction.sync="invitationaction" @changevisibleaction="changevisibleaction" />
  </div>
</template>

<script>
import { get_pending_audit_user_list } from '@/api/enterprise'
import ApplyAction from '@/views/administration/applyAction'
export default {
  name: 'ApplyList',
  components: { ApplyAction },
  props: {
    invitationlist: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      invitations: false,
      puUserUuid: '',
      invitationaction: false,
      applylist: []
    }
  },
  computed: {},
  watch: {
    invitationlist: {
      handler(val) {
        this.invitations = val
        if (val) {
          this.getapplylist()
        }
      },
      immediate: true
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 关闭弹窗
    close() {
      this.invitations = false
      this.$emit('changevisiblelist', false)
    },
    getapplylist() {
      get_pending_audit_user_list().then(d => {
        this.applylist = d.data
      }).finally(_ => {

      }).catch(e => {

      })
    },
    getapplydetail(val) {
      this.invitationaction = true
      this.puUserUuid = val.userUuid
    },
    changevisibleaction(val) {
      if (!val) {
        this.invitationaction = false
      } else {
        this.invitationaction = true
      }
      this.getapplylist()
    }
  }
}
</script>

<style scoped lang="scss">
.m_applylist{
  .m_applylist_content{
    height: 249px;
    .m_applylist_item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 83px;
      .m_item_lpart{
        display: flex;
        align-items: center;
        .u_item_pic{
          flex-shrink: 0;
          width: 44px;
          height: 44px;
          border-radius: 4px;
        }
        .m_item_txt{
          max-width: 250px;
          margin-left: 12px;
          font-size: 18px;
          font-weight: 500;
          color: #31415F;
          .u_item_telnum{
            margin-top: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #9CA8C3;
          }
        }
      }
      .m_item_rpart{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex-shrink: 0;
        font-size: 14px;
        font-weight: 400;
        color: #69748A;
        .u_item_state{
          margin-top: 12px;
          cursor: pointer;
          width: 72px;
          height: 20px;
          text-align: center;
          line-height: 18px;
          border-radius: 4px;
          font-size: 12px;
          color:#4C64FE ;
          border: 1px solid #4C64FE;
        }
      }
    }
    .m_applylist_item + .m_applylist_item{
      border-top: 1px dashed #F0F3F5;
    }
    ::v-deep{
      .el-scrollbar__wrap{
        overflow-x: hidden;
      }
    }
  }
  .m_applylist_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .u_btn_su{
      margin-left: 20px;
    }
  }
}
</style>
