<template>
  <div class="login_jointeam">
    <div class="login_jointeam_back" @click="goback">
      <span class="um_iconfont um_icon-back" /> 返回
    </div>
    <div class="login_jointeam_content">
      <div class="login_jointeam_pic">
        <el-image
          style="width: 100%; height: 100%;border-radius: 20px"
          :src="choseitem.enterpriseIcon"
          fit="fit"
        >
          <div slot="error" class="image-slot">
            <!--                      <i class="el-icon-user-solid" />-->
            <div class="compoangnodata" />
          </div>
        </el-image>
      </div>
      <div class="u_jointeam_name">{{ choseitem.enterpriseName }}</div>
      <div class="u_jointeam_txt">邀请您加入企业</div>
    </div>
    <div class="u_jointeam_inname">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="0" class="demo-ruleForm" label-position="left">
        <el-form-item label="" prop="userName">
          <el-input
            v-model="ruleForm.userName"
            maxlength="10"
            placeholder="请输入真实姓名"
            clearable
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="u_jointeam_btn">
      <el-button :disabled="disabled" class="shadow-button" type="primary" @click="joinitem">
        <span class="u_jointeam_btn_txt">
          确认加入
        </span>
      </el-button>
    </div>
  </div>
</template>

<script>
import { enterpriseTeam } from '@/api/user'
export default {
  name: 'LoginJoinTeam',
  components: {},
  props: {
    choseitem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入真实姓名'))
      } else if (!/^[\u4E00-\u9FA5A-Za-z0-9·]+$/.test(value)) {
        callback(new Error('姓名包含了·以外的特殊字符!'))
      } else {
        callback()
      }
    }
    return {
      disabled: true,
      ruleForm: {
        enterpriseUuid: this.choseitem.enterpriseUuid,
        userName: ''// 用户姓名
      },
      rules: {
        userName: [
          { required: true, trigger: 'blur', validator: validateName }
        ]
      }
    }
  },
  computed: {},
  watch: {
    'ruleForm.userName': {
      handler(val) {
        if (val && val.length) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    goback() {
      this.$emit('changeshowjoin', false)
    },
    joinitem() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
          enterpriseTeam({
            ...this.ruleForm
          }).then(d => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.goback()
          }).finally(_ => {

          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login_jointeam{
  .login_jointeam_back{
    cursor: pointer;
    color: #4C64FE;
    font-size: 14px;
  }
  .login_jointeam_content{
    margin: 45px auto 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .login_jointeam_pic{
      width: 80px;
      height: 80px;
      border-radius: 20px;
    }
    .u_jointeam_name{
      text-align: center;
      margin-top: 16px;
      font-size: 20px;
      font-weight: 400;
      color: #31415F;
    }
    .u_jointeam_txt{
      margin-top: 12px;
      font-size: 18px;
      font-weight: 600;
      color: #31415F;
    }
  }
  .u_jointeam_inname{
    ::v-deep{
      .gp-input__inner{
        height: 50px;
      }
    }
  }
  .u_jointeam_btn {
    margin-top: 30px;
    .u_jointeam_btn_txt{
      font-size: 20px;
    }
  }
}
</style>
