import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'

const enterpriseRouter = {
  path: '/enterprise',
  component: Layout,
  meta: { title: '企业管理', icon: 'enterprise', iconActive: 'enterprise-active', level: 1, roles: ['11203'] },
  // redirect: '/enterprise/home',
  redirect: to => {
    for (let i = 0; i < enterpriseRouter.children.length; i++) {
      const { path, meta } = enterpriseRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  children: [
    {
      path: '/enterprise/home',
      name: 'EnterpriseHome',
      component: () => import('@/views/enterprise/home'),
      meta: { title: '企业管理', cache: false, group: 'administration', level: 2, roles: ['1120301'], activeMenu: '/administration' }
    },

    {
      name: 'EnterpriseAddress',
      path: '/enterprise/address',
      component: () => import('@/views/enterprise/address'),
      meta: { title: '通讯录', group: 'administration', cache: false, level: 2, roles: ['1120302'], activeMenu: '/administration' }
    },
    {
      name: 'EnterpriseAddressAdd',
      path: '/enterprise/address/add',
      component: () => import('@/views/enterprise/address/add'),
      meta: { title: '通讯录新增', group: 'administration', level: 3, roles: ['1120302'], activeMenu: '/administration' }
    },
    {
      name: 'EnterpriseAddressUpdate',
      path: '/enterprise/address/info',
      component: () => import('@/views/enterprise/address/update'),
      meta: { title: '通讯录详情', group: 'administration', level: 3, roles: ['1120302'], activeMenu: '/administration' }
    },
    {
      name: 'EnterpriseAddressUpdate',
      path: '/enterprise/address/update',
      component: () => import('@/views/enterprise/address/update'),
      meta: { title: '通讯录编辑', group: 'administration', level: 3, roles: ['1120302'], activeMenu: '/administration' }
    },
    {
      name: 'EnterpriseAddressImportExport',
      path: '/enterprise/address/importExport',
      component: () => import('@/views/enterprise/address/importExport'),
      meta: { title: '通讯录导入导出', group: 'administration', level: 3, roles: ['1120302'], activeMenu: '/administration' }
    },

    {
      name: 'EnterpriseApplication',
      path: '/enterprise/application',
      component: () => import('@/views/enterprise/application'),
      meta: { title: '应用管理', group: 'administration', level: 2, roles: ['112030301'], activeMenu: '/administration' }
    },
    // 自建 应用详情和配置单独拿出来
    {
      name: 'EnterpriseApplicationConfig',
      path: '/enterprise/application/config',
      component: () => import('@/views/enterprise/application/config'),
      meta: { title: '自建应用', group: 'enterpriseApp', level: 3, roles: ['112030301'], activeMenu: '/administration' }
    },
    // 三方 应用详情
    {
      name: 'EnterpriseApplicationConfigOther',
      path: '/enterprise/application/configOther',
      component: () => import('@/views/enterprise/application/configOther'),
      meta: { title: '应用详情', group: 'enterpriseApp', level: 3, roles: ['112030301'], activeMenu: '/administration' }
    },
    // 公告管理
    {
      name: 'EnterpriseNotice',
      path: '/enterprise/notice',
      component: () => import('@/views/enterprise/notice'),
      meta: { title: '公告管理', group: 'administration', level: 2, roles: ['112030302'], activeMenu: '/administration' }
    },
    {
      name: 'EnterpriseNoticeAdd',
      path: '/enterprise/notice/add',
      component: () => import('@/views/enterprise/notice/add'),
      meta: { title: '公告新增', group: 'administration', level: 3, roles: ['112030302'], activeMenu: '/administration' }
    },
    {
      name: 'EnterpriseNoticeUpdate',
      path: '/enterprise/notice/update',
      component: () => import('@/views/enterprise/notice/update'),
      meta: { title: '公告编辑', group: 'administration', level: 3, roles: ['112030302'], activeMenu: '/administration' }
    },
    // 权限管理
    { name: 'EnterpriseRole', path: '/enterprise/role', component: () => import('@/views/enterprise/role'), meta: { title: '权限管理', group: 'administration', level: 2, roles: ['112030402'], activeMenu: '/administration' }},
    // 企业设置
    { name: 'EnterpriseBasic', path: '/enterprise/basic', component: () => import('@/views/enterprise/basic'), meta: { title: '企业基础信息', group: 'administration', level: 2, roles: ['112030401'], activeMenu: '/administration' }}
  ]
}
export default enterpriseRouter
