import axios from 'axios'
import { MessageBox, Message } from '@um/element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router'
import { handlePage, createLink } from '@/utils/index'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = store.getters.token
    }
    if (store.getters.deviceId) {
      config.headers['deviceId'] = store.getters.deviceId
    }
    return config
  },
  error => {
    // do something with request error
    // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async(response) => {
    let res = response.data
    if (typeof res === 'object' && res instanceof Blob) {
      if (res.type === 'application/json') {
        await new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.addEventListener('loadend', function(e) {
            res = JSON.parse(e.target.result)
            resolve()
          })
          reader.readAsText(res)
        })
      } else {
        createLink(response)
        return false
      }
    }
    /**
     * 本地开发环境mock，直接返回
     */
    if (process.env.NODE_ENV == 'development' && response.config.baseURL.includes('api.pm.youmatech.com/mock')) {
      return res
    }

    if (res.code === 200 || res.code === 3001 || res.state === 'success') {
      // 成功
      return handlePage(res)
    }

    if (res.code === 3002) {
      // 失败
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(res)
    }
    if (res.code === 403) {
      // 无权限
      Message({
        message: '登录无效或已过期',
        type: 'error',
        duration: 5 * 1000,
        onClose: () => {
          store.dispatch('user/resetToken').then(_ => {
            router.push('/login')
          })
        }
      })
      return Promise.reject(res)
    }
    if (res.code === 401) {
      // 无权限
      Message({
        message: '未授权',
        type: 'error',
        duration: 5 * 1000,
        onClose: function() {
          // location.reload()
        }
      })
      return Promise.reject(res)
    }
    if (res.msg) {
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(res)
  },
  (error) => {
    // for debug
    Message({
      // message: error.message,
      message: error.msg || '网络错误',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
