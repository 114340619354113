<template>
  <div class="m-departhome">
    <div v-if="tableData.length>0">
      <el-table
        :data="tableData"
        style="width: 100%;margin-top:10px;"
      >
        <el-table-column
          prop="xh"
          label="序号"
        />
        <el-table-column
          prop="partName"
          label="房间部位名称"
        />

        <el-table-column
          label="操作"
          width="120px"
        >
          <template slot-scope="scope">
            <el-button v-permission="['112040810']" type="text" @click="update(scope.row)">编辑</el-button>
            <el-button v-permission="['112040811']" type="text" class="s-redColor" @click="remove(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :background="false"
        :total="searchTotal"
        :page.sync="searchForm.page.pageNum"
        :limit.sync="searchForm.page.pageSize"
        :layout="'total, sizes, prev, pager, next, jumper'"
        @pagination="getList('page')"
      />
    </div>
    <div v-if="tableData.length===0&&flag" class="text-center">
      <no-data />
    </div>
  </div>
</template>

<script>
import { building_part_page_list, save_building_part, update_building_part, delete_building_part } from '@/api/mainData/position.js'
import noData from '@/views/cloudMarket/components/noData.vue'
export default {
  name: 'Home',
  components: { noData },
  props: {
    busProjectUuid: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      searchTotal: 0,
      projectId: null,
      tableData: [],
      searchForm: {
        page: {
          pageNum: 1,
          pageSize: 10
        }
      },
      flag: true
    }
  },
  watch: {
    busProjectUuid(val) {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    changeList() {
      this.getList()
    },
    getList(state = '') {
      if (state !== 'page') {
        this.searchForm.page.pageNum = 1
        this.searchForm.page.pageSize = 10
      }
      const load = this.$load()
      building_part_page_list({
        busProjectUuid: this.busProjectUuid,
        ...this.searchForm
      }).then(d => {
        this.tableData = d.data.list
        this.searchTotal = d.data.page.total
        this.flag = true
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
        this.flag = true
      })
    },
    // 新增部位
    append(row) {
      this.$prompt('', '新增房间部位', {
        inputErrorMessage: '长度在 1 到 20 个字符',
        inputPlaceholder: '请输入房间部位名称（最多20字）',
        closeOnClickModal: false,
        inputValidator: function(val) {
          return Boolean(val) && val.length <= 20
        }
      }).then(({ value }) => {
        console.log(value)
        const load = this.$load()
        save_building_part({
          partName: value,
          busProjectUuid: this.busProjectUuid,
          partTypeCode: 20242022// 公区：20242021  房间：20242022
        }).then(d => {
          load.close()
          this.$message.success('新增成功！')
          this.getList()
        }).finally(_ => {
          load.close()
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message.info('取消新增!')
      })
    },
    // 编辑
    update(row) {
      this.$prompt('', '修改房间部位', {
        inputErrorMessage: '长度在 1 到 20 个字符',
        inputPlaceholder: '请输入房间部位名称（最多20字）',
        inputValue: row.partName,
        inputValidator: function(val) {
          return Boolean(val) && val.length <= 20
        }
      }).then(({ value }) => {
        console.log(value)
        const load = this.$load()
        update_building_part({
          partUuid: row.partUuid,
          partName: value,
          busProjectUuid: this.busProjectUuid
        }).then(d => {
          load.close()
          this.$message.success('修改成功！')
          row.partName = value
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message.info('取消修改!')
      })
    },
    // 删除
    remove(row) {
      console.log(row)
      this.$confirm(`是否删除${row.partName}?`, '提示', {
        type: 'warning'
      }).then(() => {
        // 缺少接口
        const load = this.$load()
        delete_building_part({
          partUuid: row.partUuid
        }).then(d => {
          load.close()
          this.$message.success('删除成功！')
          this.getList()
        }).catch(e => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message.info('已取消删除！')
      })
    }

  }
}
</script>

<style scoped  lang="scss">

</style>
