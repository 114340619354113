import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'
const cloudMarketRouter = {
  path: '/cloudMarket',
  component: Layout,
  name: 'cloudMarket',
  // redirect: ' /index',
  redirect: to => {
    for (let i = 0; i < cloudMarketRouter.children.length; i++) {
      const { path, meta } = cloudMarketRouter.children[i]
      if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
    }
  },
  meta: { level: 2, roles: ['11202'] },
  children: [
    {
      path: '/cloudMarket',
      component: () => import('@/views/cloudMarket'),
      name: 'CloudMarket',
      meta: { title: '云市场', cache: true, group: 'cloudMarket', level: 2, roles: ['1120201'], activeMenu: '/dashboard' }
    },
    {
      path: '/cloudMarket/search',
      component: () => import('@/views/cloudMarket/search'),
      name: 'CloudMarketSearch',
      meta: { title: '云市场-搜索', group: 'cloudMarket', level: 3, roles: ['1120201'], activeMenu: '/dashboard' }
    },
    {
      path: '/cloudMarket/info',
      component: () => import('@/views/cloudMarket/info'),
      name: 'CloudMarketInfo',
      meta: { title: '云市场-详情', group: 'cloudMarket', level: 3, roles: ['1120201'], activeMenu: '/dashboard' }
    },
    {
      path: '/cloudMarket/shoppingCart',
      component: () => import('@/views/cloudMarket/shoppingCart'),
      name: 'ShoppingCart',
      meta: { title: '云市场-购物车', group: 'cloudMarket', level: 3, roles: ['1120201'], activeMenu: '/dashboard' }
    }
  ]
}
export default cloudMarketRouter
