<template>
  <span>
    <span>{{ sum1 }}</span>
    <span style="font-size: 12px;">{{ sum2 }}</span>
  </span>
</template>

<script>
export default {
  name: 'UmMoney',
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    sum1() {
      const val = this.value + ''
      return val.split('.')[0]
    },
    sum2() {
      const val = this.value + ''
      const list = val.split('.')
      return list.length === 2 ? '.' + list[1] : ''
    }
  }
}
</script>
