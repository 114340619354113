<template>
  <div>
    <div class="g-imContainer">
      <div class="m-left">
        <slideBar />
      </div>
      <div class="m-right">
        <chatMain ref="main" />
      </div>
    </div>
  </div>
</template>

<script>
import slideBar from '@/components/UmIM/slideBar'
import chatMain from '@/components/UmIM/chatMain'
export default {
  name: 'UmIM',
  components: { slideBar, chatMain },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.g-imContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #ffffff;
  display: flex;
  //border: 1px solid #909db6;
  //box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.2);
  //border-radius: 14px;
  .m-left {
    width: 336px;
    height: 100%;
    float: left;
    z-index: 10;
    border-right: 2px solid rgba(0, 0, 0, 0.1);
    //box-shadow: 2px 0 1px 0px rgba(0, 0, 0, 0.1);
  }
  .m-right {
    float: right;
    width: calc(100% - 336px);
    height: 100%;
    background: #f6f7fa;;
  }
}
</style>

