// 父应用监听方法
import microApp from '@micro-zoe/micro-app'
import { create_authorize_code } from '@/api/common'

import router from './router'
import store from '@/store'

// microApp.setData('AppMini', { type: '新的数据' })

const APP_LIST = [
  'AppMini',
  'AppSale',
  'AppComm',
  'AppPkg1',
  'AppPkg2',
  'AppPkg3',
  'AppPkg4',
  'AppPkg5',
  'AppPkg6',
  'AppPkg7',
  'AppPkg8',
  'AppPkg9',
  'AppPkg10',
  'AppPkg11',
  'AppPkg12',
  'AppPkg13',
  'AppPkg14',
  'AppPkg15',
  'AppPkg16',
  'AppPkg17',
  'AppPkg18',
  'AppPkg19',
  'AppPkg20',
  'AppPkg21',
  'AppPkg22',
  'AppPkg23',
  'AppPkg24',
  'AppPkg25',
  'AppPkg26',
  'AppPkg27',
  'AppPkg28',
  'AppPkg29',
  'AppPkg30',
  'AppPkg31',
  'AppPkg32',
  'AppPkg33',
  'AppPkg34',
  'AppPkg35',
  'AppPkg36',
  'AppPkg37',
  'AppPkg38',
  'AppPkg39',
  'AppPkg40',
  'AppPkg41',
  'AppPkg42',
  'AppPkg43',
  'AppPkg44',
  'AppPkg45',
  'AppPkg46'
]
APP_LIST.forEach(item => {
// 报事报修应用
  microApp.addDataListener(item, async({ methods, params, success, fail }) => {
    // 登录
    if (methods === 'login') {
      const res = await login(params)
      success(res.data)
    }
    // 路由跳转
    if (methods === 'route') {
      routerHandler(params)
    }
    // 子应用路由变化
    if (methods === 'routeChange') {
      console.log('routeChange', params)
      // 重置页面滚动条
      setTimeout(_ => {
        document.querySelector('.app-main').scrollTop = 0
      }, 0)
    }
  }, true)
})

/**
 * 登录方法
 * @param params {签名等参数}
 * @returns {AxiosPromise<any>}
 */
async function login(params) {
  return create_authorize_code(params)
}

/**
 * 跳转方法
 * @param to 'main' 主平台 'mini'
 * @param path 路由
 */
function routerHandler({ to, path }) {
  // 跳转路由
  store.dispatch('app/jumpAPP', {
    name: to,
    path
  })
}
