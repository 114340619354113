<template>
  <div class="m-room-init">
    <div class="initBox flex-between">
      <div class="init-label">编辑房间</div>
      <div class="init-buttons">
        <el-button v-if="steps > 0" type="primary" @click="prevStep">上一步</el-button>
        <el-button v-if="steps < 1" type="primary" @click="nextStep">下一步</el-button>
        <el-button v-if="steps === 1" type="primary" @click="saveHandler">保存</el-button>
        <el-button type="primary" @click="close">取消</el-button>
      </div>
    </div>
    <steps v-model="steps" :list="stepsList" />
    <div class="initForm">
      <add
        ref="edit"
        :visible="steps == 0"
        :bus-building-unit="busBuildingUnit"
        :bus-building-room="busBuildingRoom"
        :bus-building-floor="busBuildingFloor"
        :bus-building-door="busBuildingDoor"
        style="margin-top: 20px"
      />
      <house
        v-if="steps == 1"
        ref="editHouse"
        style="margin-top: 20px"
        :house-type="houseType"
        :source-data="sourceData"
        :merges="merges"
        :col-widths="colWidths"
      />
    </div>
  </div>
</template>

<script>
import add from './add'
import house from './house'
import steps from './steps'
import { house_type_list, save_room_submit } from '@/api/mainData/room'
export default {
  name: 'InitRoom',
  components: { steps, house, add },
  props: {
    busProjectId: {
      type: String,
      default: ''
    },
    busBuildingId: {
      type: [String, Number],
      default: ''
    },
    busBuildingRoom: {
      type: Array,
      default: () => []
    },
    busBuildingUnit: {
      type: Array,
      default: () => []
    },
    busBuildingFloor: {
      type: Array,
      default: () => []
    },
    busBuildingDoor: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      steps: 0,
      stepsList: [
        { label: '设置房间名称', value: 1 },
        { label: '设置户型', value: 2 }
      ],
      form: {
        unit: null,
        door: null,
        floor: null
      },
      houseType: [],
      houseTypeList: [],
      sourceData: [],
      merges: [],
      colWidths: []
    }
  },
  watch: {
    busProjectId: {
      handler() {
        this.getHouseType()
      },
      immediate: true
    }
  },
  methods: {
    nextStep() {
      if (this.steps === 0) {
        this.editHouse()
      }
    },
    prevStep() {
      this.steps--
    },
    getHouseType() {
      if (!this.busProjectId) return false
      house_type_list({
        busProjectUuid: this.busProjectId
      })
        .then(d => {
          const list = d.data?.list || []
          this.houseType = list.map(item => item.houseTypeName)
          this.houseTypeList = list
        })
        .catch(e => {
          this.houseType = []
          this.houseTypeList = []
          this.$errorHandle(e)
        })
    },
    editHouse() {
      this.sourceData = this.$refs.edit.hotRef.getSourceData()
      this.merges = this.$refs.edit.hotRef.getPlugin(
        'mergeCells'
      ).mergedCellsCollection.mergedCells
      this.colWidths = [40, 40]
      for (let i = 0; i < this.sourceData[0].length; i++) {
        this.colWidths.push(160)
      }
      // todo 缺少户型ID
      const load = this.$load()
      setTimeout(() => {
        load.close()
        this.steps++
      }, 200)
    },
    close() {
      this.steps = 0
      this.$emit('cancel')
    },
    saveHandler() {
      const load = this.$load()
      this.$refs.editHouse.submit().then(d => {
        console.log(d)
        const buildingFloorList = d.buildingFloorList
        const busBuildingUnitList = d.busBuildingUnitList.map(item => {
          delete item.sort
          return item
        })
        const busBuildingDoorList = d.busBuildingDoorList
        const buildingRoomList = d.buildingRoomList.map(item => {
          const list = this.houseTypeList.filter(
            item2 => item2.houseTypeName === item.busHouseTypeId
          )
          if (item !== null) {
            delete item.busHouseTypeName
          }
          if (list.length > 0) {
            item.busHouseTypeId = list[0].busHouseTypeId
          } else {
            item.busHouseTypeId = null
          }

          return item
        })

        for (let i = 0; i < this.busBuildingFloor.length; i++) {
          if (
            buildingFloorList.some(
              item =>
                this.busBuildingFloor[i] &&
                item.busBuildingFloorId ===
                this.busBuildingFloor[i].busBuildingFloorId
            )
          ) {
            // 如果有的话，以后面数据为主，之前数据直接置为null
            this.busBuildingFloor[i] = null
          } else {
            if (this.busBuildingFloor[i] !== null) {
              this.busBuildingFloor[i].deleteFlag = 1
            } else {
              this.busBuildingFloor[i].deleteFlag = null
            }
            // this.busBuildingFloor[i] !== null
            //   ? (this.busBuildingFloor[i].deleteFlag = 1)
            //   : null
          }
        }
        for (let i = 0; i < this.busBuildingUnit.length; i++) {
          if (
            busBuildingUnitList.some(
              item =>
                this.busBuildingUnit[i] &&
                item.busBuildingUnitId ===
                this.busBuildingUnit[i].busBuildingUnitId
            )
          ) {
            // 如果有的话，以后面数据为主，之前数据直接置为null
            this.busBuildingUnit[i] = null
          } else {
            if (this.busBuildingUnit[i] != null) {
              this.busBuildingUnit[i].deleteFlag = 1
            } else {
              this.busBuildingUnit[i].deleteFlag = null
            }
            // this.busBuildingUnit[i] != null
            //   ? (this.busBuildingUnit[i].deleteFlag = 1)
            //   : null
          }
        }

        for (let i = 0; i < this.busBuildingDoor.length; i++) {
          if (
            busBuildingDoorList.some(
              item =>
                this.busBuildingDoor[i] &&
                item.busBuildingDoorId ===
                this.busBuildingDoor[i].busBuildingDoorId
            )
          ) {
            // 如果有的话，以后面数据为主，之前数据直接置为null
            this.busBuildingDoor[i] = null
          } else {
            if (this.busBuildingDoor[i] != null) {
              this.busBuildingDoor[i].deleteFlag = 1
            } else {
              this.busBuildingDoor[i].deleteFlag = null
            }
            // this.busBuildingDoor[i] != null
            //   ? (this.busBuildingDoor[i].deleteFlag = 1)
            //   : null
          }
        }

        for (let i = 0; i < this.busBuildingRoom.length; i++) {
          if (this.busBuildingRoom[i] !== null) {
            delete this.busBuildingRoom[i].busHouseTypeName
          }
          if (
            buildingRoomList.some(
              item =>
                this.busBuildingRoom[i] &&
                item.busBuildingRoomId ===
                this.busBuildingRoom[i].busBuildingRoomId
            )
          ) {
            // 如果有的话，以后面数据为主，之前数据直接置为null
            this.busBuildingRoom[i] = null
          } else {
            if (this.busBuildingRoom[i] != null) {
              this.busBuildingRoom[i].deleteFlag = 1
            } else {
              this.busBuildingRoom[i].deleteFlag = null
            }
            // this.busBuildingRoom[i] != null
            //   ? (this.busBuildingRoom[i].deleteFlag = 1)
            //   : null
          }
        }

        save_room_submit({
          busBuildingDoorList: []
            .concat(this.busBuildingDoor.filter(item => item !== null))
            .concat(busBuildingDoorList),
          buildingFloorList: []
            .concat(this.busBuildingFloor.filter(item => item !== null))
            .concat(buildingFloorList),
          busBuildingUnitList: []
            .concat(this.busBuildingUnit.filter(item => item !== null))
            .concat(busBuildingUnitList),
          buildingRoomList: []
            .concat(this.busBuildingRoom.filter(item => item !== null))
            .concat(buildingRoomList),
          isRebuild: 0,
          busBuildingId: this.busBuildingId
        })
          .then(d => {
            load.close()
            this.$alert('修改成功！', '提示', {
              type: 'success',
              showClose: false
            }).then(d => {
              this.close()
            })
          })
          .catch(e => {
            load.close()
            this.$errorHandle(e)
          })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
