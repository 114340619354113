<template>
  <!-- 解决方案 -->
  <div class="g-cloudMarketBox">
    <div class="cloudMarket_leftBox">
      <div ref="searchBox" class="cloudMarket_leftBox_searchBox">
        <el-input v-model.trim="keyWord" placeholder="搜索关键词" maxlength="50" @focus="flag = true" @keyup.enter.native="getSearch()">
          <i slot="suffix" class="gp-input__icon el-icon-search pointer" @click="getSearch()" />
        </el-input>
        <div v-if="flag ===true" class="cloudMarket_leftBox_searchBox_searchRecords">
          <search :search-type="20039908" :search-list="solutionSearchList" :tab-type="3" @words="words" />
        </div>
      </div>
      <!-- 左侧菜单 -->
      <div class="cloudMarket_leftBox_menuss">
        <el-menu
          :default-active="activeitem"
          class="gp-menu-vertical-demo"
          @select="handleSelect"
        >
          <div v-for="(item,i) in classificationList" :key="i">
            <el-menu-item :index="''+i" class="cloudMarket_leftBox_menuss_comoncolor">
              <span slot="title">
                <UmToolTip :content="item.classifyName" />
              </span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
    </div>
    <div class="cloudMarket_rightBox">
      <banner :banner-list="bannerList" />
      <div v-if="menuId==='0'" class="cardBox cardBox-minH" :class="solutionList.length===0?'flex-center':'cardBox-solution'">
        <solution v-if="solutionList.length>0" :type="3" :solution-list="solutionList" :tab-type="3" :margin-style="2" />
        <no-data v-else />
      </div>

      <div v-else class="cardBox cardBox_paging cardBox-minH" :class="OtherSolutionsList.length===0?'flex-center':'cardBox-solution'">
        <solution v-if="OtherSolutionsList.length>0" :type="3" :solution-list="OtherSolutionsList" :tab-type="3" :margin-style="2" />
        <no-data v-else />
        <div v-if="total>0">
          <div class="cloudMarket_rightBox_pages">
            <pagination
              :total="total"
              :page.sync="page.pageNum"
              :limit.sync="page.pageSize"
              @pagination="getOtherSolutions('page')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import search from '../components/search.vue'
import solution from '../components/solution.vue'
import banner from '../components/banner.vue'
import {
  getBanner, getApplicationMenu, getSelectedSolutions,
  getOtherSolutions
} from '@/api/cloudMarket'
import noData from '../components/noData.vue'
export default {
  name: 'CloudMarketSolution',
  components: { search, solution, banner, noData },
  data() {
    return {
      activeitem: '0', // 侧边栏默认
      flag: false,
      solutionList: [],
      bannerList: [],
      classificationList: [],
      page: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0,
      menuId: '0',
      typeId: null,
      OtherSolutionsList: [],
      keyWord: '',
      solutionSearchList: [],
      hotList: []

    }
  },
  mounted() {
    this.getBanner()
    this.getSolution()
    this.getApplicationMenu()
    const key = 'solutionSearch_' + this.$store.getters.enterpriseUuid
    try {
      const redult = JSON.parse(window.localStorage.getItem(key))
      if (redult && Array.isArray(redult)) {
        this.solutionSearchList = redult
      } else {
        this.solutionSearchList = []
      }
    } catch {
      this.solutionSearchList = []
    }
  },
  created() {
    document.body.addEventListener('click', this.bodyClickhandler)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.bodyClickhandler)
  },
  methods: {
    // 点击搜索框关闭搜索框
    bodyClickhandler(event) {
      const _target = event.target
      if (this.$refs.searchBox) {
        if (this.$refs.searchBox.contains(_target)) {
          //
        } else {
          this.flag = false
        }
      }
    },
    words(val, hotList) {
      this.keyWord = val
      this.hotList = hotList
      this.getSearch()
    },
    // 获取banner
    getBanner() {
      getBanner({ bannerNo: '200912003-P' }).then(res => {
        this.bannerList = res.data
      })
    },
    // 获取应用菜单分类
    getApplicationMenu() {
      getApplicationMenu({ classifyCode: 2003010103 }).then(res => {
        this.classificationList = res.data.list
        this.classificationList.unshift({
          classifyUuid: null,
          classifyName: '精选'
        })
      })
    },
    // 解决方案精选
    getSolution() {
      const load = this.$load()
      getSelectedSolutions({}).then(res => {
        load.close()
        this.solutionList = res.data.list
      }).catch(e => {
        load.close()
      })
    },
    // 其他分类解决方案
    getOtherSolutions(state = '') {
      if (state !== 'page') {
        this.page = {
          pageSize: 10,
          pageNum: 1
        }
      }
      const params = {
        classifyUuid: this.typeId,
        page: {
          pageSize: this.page.pageSize,
          pageNum: this.page.pageNum
        }
      }
      const load = this.$load()
      getOtherSolutions(params).then(res => {
        this.OtherSolutionsList = res.data.list
        this.page = res.data.page
        this.total = res.data.page.total
        load.close()
      }).catch(e => {
        load.close()
      })
    },
    // 选择分类
    handleSelect(val) {
      this.menuId = val
      if (val === '0') {
        this.getSolution()
      } else {
        this.typeId = this.classificationList[val].classifyUuid
        this.getOtherSolutions()
      }
    },
    // 搜索
    getSearch() {
      if (this.keyWord) {
        // 存储
        const key = 'solutionSearch_' + this.$store.getters.enterpriseUuid
        this.solutionSearchList.unshift(this.keyWord)
        this.solutionSearchList = [...new Set(this.solutionSearchList)].splice(0, 5)
        window.localStorage.setItem(key, JSON.stringify(this.solutionSearchList))
        this.$router.push({ path: '/cloudMarket/search', query: { keyWord: this.keyWord, hotList: this.hotList, tabType: 3 }})
        this.keyWord = ''
      } else {
        this.$message.error('请输入搜索关键词')
      }
    }
  }
}
</script>

