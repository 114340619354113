<template>
  <!--  <div>-->
  <!--    <um-bread-crumb />-->
  <!--    <el-card shadow="never">-->

  <!--    </el-card>-->
  <el-card shadow="never" class="min_h_100">
    <div class="go_back_common">
      <div class="go_back_title" @click="goback">
        <span class="go_back_icon" />
        <span class="go_back_txt">标段管理</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="m_tender_config">
      <div class="gp-radio-group">
        <div class="gp-radio-button el-radio-button--medium" @click="setType(1)">
          <input v-model="type" type="radio" class="gp-radio-button__orig-radio" value="1">
          <span class="gp-radio-button__inner">现场检查</span>
        </div>
        <div class="gp-radio-button el-radio-button--medium" @click="setType(2)">
          <input v-model="type" type="radio" class="gp-radio-button__orig-radio" value="2">
          <span class="gp-radio-button__inner">工程验收</span>
        </div>
        <div class="gp-radio-button el-radio-button--medium" @click="setType(3)">
          <input v-model="type" type="radio" class="gp-radio-button__orig-radio" value="3">
          <span class="gp-radio-button__inner">实测实量</span>
        </div>
      </div>
      <div class="m_default_appset">
        <el-tooltip popper-class="w300" effect="dark" content="打开后，app在现场检查登记问题时时可以修改这里设置的默认人员和单位，反之则不可以修改这里设置的默认值" placement="top">
          <i class="el-icon-warning-outline" style="font-size: 14px;color:rgb(43, 111, 239);display:inline-block;vertical-align:middle;margin-right:8px;" />
        </el-tooltip>
        <span class="m_default_se_title">是否允许APP修改默认设置</span>
        <el-switch
          v-model="isAllowModify"
          :active-value="1"
          :inactive-value="0"
        />
      </div>
    </div>
    <component :is="`code${type}`" ref="component" :bus-project-para-id="busProjectParaId" :is-allow-modify="isAllowModify" />
  </el-card>
<!--  </div>-->
</template>

<script>
import code1 from './code1'
import code2 from './code2'
import code3 from './code3'
export default {
  name: 'MainDataTenderConfig',
  components: { code1, code2, code3 },
  props: {},
  data() {
    return {
      isAllowModify: 0,
      busProjectParaId: '',
      type: 1
    }
  },
  created() {
    if (!this.$route.query.busProjectParaId) {
      this.$router.go(-1)
      return false
    }
    this.busProjectParaId = this.$route.query.busProjectParaId
  },
  mounted() {},
  methods: {
    setType(val) {
      const checked =
        this.$refs.component &&
        this.$refs.component.check &&
        this.$refs.component.check()
      if (checked) {
        this.type = val
        this.isAllowModify = 0
      } else {
        this.$confirm('您当前进行的操作还没有保存, 是否继续?', '提示', {
          type: 'warning'
        })
          .then(() => {
            this.type = val
          })
          .catch(() => {})
      }
    },
    goback() {
      this.$router.replace('/mainData/tender')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
