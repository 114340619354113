
// import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import UmDraw from '@/components/UmDraw'
import UmIM from './UmIM/index.js'
import svgIcon from '@/components/SvgIcon'
import Tinymce from '@/components/Tinymce'
import UmStep from '@/components/UmStep'
import UmPosition from './UmPosition/index'
// import UmFreeForm from '@/components/UmFreeForm'
// import UmCreateApprove from '@/components/UmCreateApprove'
import UmCustomerUploadImg from '@/components/UmCustomUploadImg'
import UmApproveEle from '@/components/UmApproveEle'
import UmDetailForm from '@/components/UmDetailForm'
import UmStar from '@/components/UmStar'
import UmUrl from '@/components/UmUrl'
import UExport from '@/components/UExport'
import UImport from '@/components/UImport'
import UmCollection from '@/components/UmCollection'
import UmLike from '@/components/UmLike'
import ShopCarMarket from '@/components/ShopCarMarket'
// 企业管理的布局组件
import EnterpriseLayout from '@/views/enterprise/layout'
import UmImage from '@/components/UmImage'

// 金钱格式化组件
import UmMoney from '@/components/UmMoney'
const Components = [
  svgIcon,
  Tinymce,
  UmStep,
  UmPosition,
  // UmFreeForm,
  // UmCreateApprove,
  UmCustomerUploadImg,
  UmApproveEle,
  UmDetailForm,
  UmStar,
  UmUrl,
  EnterpriseLayout,
  ShopCarMarket,
  UmMoney
]

// 定义 install 方法
const install = function(Vue) {
  if (install.installed) return
  install.installed = true

  // 遍历并注册全局组件
  Components.forEach(component => {
    Vue.component(component.name, component)
  })
  Vue.use(UmImage)
  // APi 方法注册
  Vue.use(UmIM)
  Vue.use(UmDraw)
  Vue.use(UImport)
  Vue.use(UExport)
  Vue.use(UmCollection)
  Vue.use(UmLike)
}
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}
export default {
  install, // 组件列表
  ...Components
}

