<template>
  <div style="height: 100%">
    <!--    <div style="padding: 20px">-->
    <!--      <el-input v-model="acctId" placeholder="im账号" />-->
    <!--      <el-input v-model="token" placeholder="im密码token" />-->
    <!--      <el-button @click="initIm">登录IM</el-button>-->
    <!--      <div style="color: #d9d9d9">-->
    <!--        <p>accId : 169de99e0e0243728047c384d23fc781    token :   f18e13e31610e044cea0f7d93da90c9c</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <UmNIM />
  </div>
</template>

<script>
export default {
  name: 'ImMessage',
  data() {
    return {

    }
  },
  created() {
    // this.initIm()
  },
  methods: {
    // initIm() {
    //   // if (this.UmIM) return false
    //   const imInstance = new UmImSDK({
    //     acctId: this.$store.state.user.imAccountId,
    //     token: this.$store.state.user.imAccountToken,
    //     // acctId: '916101e1a9a84c9097f827ec7ec9af8f',
    //     // token: 'e879b13b15d89a1736520eea59459498',
    //     callback: () => {
    //       bus.$emit('imInit')
    //     }
    //   })
    //
    //   this.$store.commit('im/SET_UMIM', imInstance)
    // }
  }
}
</script>

<style scoped>

</style>
