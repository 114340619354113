import request from '@/utils/request'
// 列表（常用应用）应用列表
export function getUseList(params = {}) {
  return request({
    url: '/api/v1/application/work_table/app_library/favorite_list',
    method: 'get',
    params: {
      scope: 2,
      ...params
    }
  })
}

// 全部應用全部列表
export function getAllList(params = {}) {
  return request({
    url: '/api/v1/application/work_table/app_library/list',
    method: 'get',
    params: {
      scope: 2,
      ...params
    }
  })
}
// 工作台下拉框搜索
export function getSearchWork(params = {}) {
  return request({
    url: '/api/v1/application/work_table/app_library/search',
    method: 'get',
    params: {
      scope: 2,
      ...params
    }
  })
}

// 热门搜索
export function getHotSearch(params) {
  return request({
    url: '/api/v1/application/work_table/app_library/query_search_param',
    method: 'get',
    params
  })
}
// 工作台常用应用保存
export function saveFavoriteList(data) {
  return request({
    url: '/api/v1/application/work_table/app_library/save_favorite_list',
    method: 'post',
    data
  })
}
// 公告通知
export function getNotice(params) {
  return request({
    url: '/api/v1/publish/notice/query_workbench_notice',
    method: 'get',
    params
  })
}

// 待办已办
export function todoPageList(data) {
  return request({
    url: '/api/v1/server-group/message/pc/todoPageList',
    method: 'post',
    data
  })
}

// 分组列表
export function get_group_list(params) {
  return request({
    url: '/api/v1/application/work_table/app_library/group_list',
    method: 'get',
    params
  })
}
// 分组排序
export function set_group_sort(data) {
  return request({
    url: '/api/v1/application/work_table/app_library/group_sort',
    method: 'post',
    data
  })
}
// 新建分组
export function create_group_app(params) {
  return request({
    url: '/api/v1/application/work_table/app_library/create_group_app',
    method: 'get',
    params
  })
}
// 删除分组
export function delete_app_group(params) {
  return request({
    url: '/api/v1/application/work_table/app_library/delete_app_group',
    method: 'get',
    params
  })
}
// 查询本组分组应用信息
export function query_self_group_app(params) {
  return request({
    url: '/api/v1/application/work_table/app_library/query_self_group_app',
    method: 'get',
    params
  })
}
// 删除应用
export function app_remove(data) {
  return request({
    url: '/api/v1/application/work_table/app_library/app_remove',
    method: 'post',
    data
  })
}
// 删除应用
export function set_app_sort(data) {
  return request({
    url: '/api/v1/application/work_table/app_library/app_sort',
    method: 'post',
    data
  })
}
// 应用详情页面编辑后保存
export function save_detail_app_group(data) {
  return request({
    url: '/api/v1/application/work_table/app_library/save_detail_app_group',
    method: 'post',
    data
  })
}
// 获取应用详情
export function get_detail_app_group(params) {
  return request({
    url: '/api/v1/application/work_table/app_library/detail_app_group',
    method: 'get',
    params
  })
}
// 获取非本组应用信息
export function get_query_other_group_app(params) {
  return request({
    url: '/api/v1/application/work_table/app_library/query_other_group_app',
    method: 'get',
    params
  })
}
// 本组应用添加其它组的应用保存
export function add_self_app(data) {
  return request({
    url: '/api/v1/application/work_table/app_library/add_self_app',
    method: 'post',
    data
  })
}
