<template>
  <iframe :src="url" width="100%" height="100%" frameborder="0" />
</template>

<script>
export default {
  name: 'DashboardWeb',
  props: {},
  data() {
    return {
      url: '',
      clientHeight: 800
    }
  },
  created() {
    console.log(window.location.origin)
    console.log(process.env.VUE_APP_BASE_API)
    const { url } = this.$route.query
    this.url = url
  },
  mounted() {
    const clientHeight = this.$el.parentElement.clientHeight
    this.clientHeight = clientHeight - 110
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
