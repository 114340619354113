import Cookies from 'js-cookie'
export const TokenKey = 'qifuyun-pc-token'

export function getToken() {
  if (process.env.VUE_APP_NAME === 'yky_website') return '' // 官网版本，token从VUEX读取
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  // let params
  // if (JSON.parse(sessionStorage.getItem('setNoLogin'))) {
  //   console.log('7天有效期')
  //   params = { expires: 7 }
  // } else {
  //   params = { expires: null }
  // }
  // return Cookies.set(TokenKey, token, params)
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
