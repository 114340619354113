<template>
  <div class="list" style="text-align:center">
    <div v-if="busBuildingUnit==0&&busBuildingRoom==0" class="f-noData">暂无数据</div>
    <HotTable
      v-show="busBuildingUnit!=0&&busBuildingRoom!=0"
      ref="hotRef"
      :settings="settings"
      language="zh-CN"
      style="overflow: hidden;font-size: 12px;margin-top:10px;"
    />
  </div>
</template>

<script>
import { HotTable } from '@um/handsontable-vue'
import Handsontable from 'handsontable'
import 'handsontable/languages/zh-CN'
export default {
  name: 'List',
  components: { HotTable },
  props: {
    busBuildingUnit: {
      type: Array,
      default: () => []
    },
    busBuildingRoom: {
      type: Array,
      default: () => []
    },
    busBuildingFloor: {
      type: Array,
      default: () => []
    },
    busBuildingDoor: {
      type: Array,
      default: () => []
    },
    type: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      listData: [],
      settings: {
        // height: window.innerHeight - 280,
        height: 380,
        readOnly: true,
        data: [],
        colHeaders: false,
        rowHeaders: false,
        // stretchH: 'all', // 撑开属性
        manualColumnFreeze: true, // 手动列冻结 开启
        fixedRowsTop: 2, // 浮动行  冻结行
        fixedColumnsLeft: 2, // 浮动列  冻结列
        fillHandle: false,
        colWidths: [50, 50, 100],
        cells: (row, column, prop) => {
          const columnMeta = {}
          // 左上角
          if (row <= 1 && column <= 1) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              td.innerHTML = value
              td.className = 'htCenter htMiddle hTitle'
              td.style = 'padding:0;'
              return td
            }
          }
          // 楼层
          if (row > 1 && column === 0) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              td.innerHTML = value ? value.floorName : value
              td.className = 'htCenter htMiddle hTitle'
              return td
            }
          }
          // 单元
          if (row === 0 && column > 1) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              td.innerHTML = value ? value.unitName : value
              td.className = 'htCenter htMiddle hTitle'
              return td
            }
          }
          // 房号
          if (row === 1 && column > 1) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              td.innerHTML = value ? value.doorName : value
              td.className = 'htCenter htMiddle hTitle'
              return td
            }
          }
          // 房间
          if (row > 1 && column > 1) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              let html = ''
              if (value && value.roomType == 20242021) {
                html += '<span class="roomType">公</span>'
              } else if (value && value.roomType == 20242022) {
                html += '<span class="roomType">房</span>'
              } else if (value && value.roomType == 20242023) {
                html += '<span class="roomType">车</span>'
              } else if (value && value.roomType == 20242024) {
                html += '<span class="roomType">商</span>'
              } else if (value && value.roomType == 20242025) {
                html += '<span class="roomType">储</span>'
              }
              console.log(this.type, value)
              if (this.type) {
                html = value ? value.busHouseTypeName : (value || '---')
              } else {
                html += value ? value.roomName : (value || '---')
              }

              td.innerHTML = html

              td.className = 'htCenter htMiddle htRoom'
              return td
            }
          }
          return columnMeta
        }
      }
    }
  },
  watch: {
    busBuildingRoom(val) {
      this.$nextTick(_ => {
        this.init()
      })
    },
    type(val) {
      this.hotRef.updateSettings({
        fillHandle: false,
        cells: this.settings.cells
      })
    }
  },
  created() {},
  mounted() {
    this.hotRef = this.$refs.hotRef.hotInstance
    this.init()
  },
  methods: {
    emptylist(length) {
      const list = []
      for (let i = 0; i < length; i++) {
        list.push(null)
      }
      return list
    },
    init() {
      if (
        !this.busBuildingUnit.length ||
        !this.busBuildingRoom.length ||
        !this.busBuildingFloor.length ||
        !this.busBuildingDoor.length
      ) {
        this.hotRef.loadData([])
        return false
      }
      /*
       * busBuildingFloor 楼层
       * busBuildingUnit 单元
       * busBuildingDoor 房号
       * busBuildingRoom 房间
       */
      // 二维数组的前两行两列被占用
      // todo 单元跟楼层有没有关系
      // eslint-disable-next-line vue/no-mutating-props
      this.busBuildingFloor.unshift(null) // 给楼层数组增加两行
      // eslint-disable-next-line vue/no-mutating-props
      this.busBuildingFloor.unshift(null)
      const list = []
      let merges = []
      for (let i = 0; i < this.busBuildingFloor.length; i++) {
        let child = []
        const merge = []
        if (i === 0) {
          child.push('楼层')
          child.push('单元')
          merge.push({
            row: 0,
            col: 0,
            rowspan: 2,
            colspan: 1
          })
        }
        if (i === 1) {
          child.push(null)
          child.push('房号')
        }
        if (i > 1) {
          child.push(this.busBuildingFloor[i])
          child.push(null)
          merge.push({
            row: i,
            col: 0,
            rowspan: 1,
            colspan: 2
          })
        }

        let col_index = 2 // 列的开始索引
        for (let j = 0; j < this.busBuildingUnit.length; j++) {
          const doorList = this.busBuildingDoor.filter(
            (item) =>
              item.busBuildingUnitId ===
              this.busBuildingUnit[j].busBuildingUnitId
          )
          if (i === 0) {
            // 此处筛选的是房号 某个单元下的房号
            child.push(this.busBuildingUnit[j])
            if (doorList.length > 1) {
              child = child.concat(this.emptylist(doorList.length - 1)) // 多余位置置为空
            }
            merge.push({
              row: i,
              col: col_index,
              rowspan: 1,
              colspan: doorList.length
            })
          }
          if (i === 1) {
            child = child.concat(doorList) // 连接房号
          }
          if (i > 1) {
            const roomList = this.busBuildingRoom.filter((item) => {
              return (
                item.busBuildingFloorId ===
                  this.busBuildingFloor[i].busBuildingFloorId &&
                item.busBuildingUnitId ===
                  this.busBuildingUnit[j].busBuildingUnitId
              )
            })
            doorList.forEach((itemDoor, index) => {
              if (
                roomList.some(
                  (item) => item && item.roomRealSort === itemDoor.doorRealSort
                )
              ) {
                //
              } else {
                roomList.splice(index, 0, null)
              }
            })

            let room_col_index = col_index
            roomList.forEach((item, index) => {
              if (item && item.busBuildingRoomId) {
                if (item.roomMergeFlag) {
                  merge.push({
                    row: i,
                    col: room_col_index,
                    rowspan: item.roomMergeFloor,
                    colspan: item.roomMergeDoor
                  })
                  room_col_index += item.roomMergeDoor
                } else {
                  room_col_index++
                }
              }
            })
            child = child.concat(roomList) // 连接房间
          }
          col_index += doorList.length
        }
        //
        list.push(child)
        merges = merges.concat(merge)
      }
      this.listData = list
      const colWidths = [40, 40]
      this.busBuildingDoor.forEach((item) => {
        colWidths.push(160)
      })

      this.hotRef.loadData(list)
      this.hotRef.updateSettings({ mergeCells: merges, colWidths })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
