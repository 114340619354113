<template>
  <canvas ref="canvas" style="max-width: 100%" />
</template>

<script>
export default {
  name: 'UmPreviewImg',
  props: {
    url: { // 图片地址
      type: String,
      default: ''
    },
    point: { // 坐标点
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      img: null,
      naturalWidth: 0,
      naturalHeight: 0
    }
  },
  watch: {
    url: {
      handler(val) {
        if (val) {
          this.$nextTick(_ => {
            this.init(val)
          })
        }
      },
      immediate: true
    },
    point: {
      handler(val) {
        this.$nextTick(_ => {
          this.init(this.url)
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    init(url) {
      this.img = null
      this.img = new Image()
      this.img.onload = this.onload
      this.img.src = url
      const canvas = this.$refs.canvas
      canvas.width = 0
      canvas.height = 0
    },
    onload() {
      const naturalWidth = this.img.naturalWidth
      const naturalHeight = this.img.naturalHeight
      this.naturalWidth = naturalWidth
      this.naturalHeight = naturalHeight
      this.draw(naturalWidth, naturalHeight)
    },
    draw(naturalWidth, naturalHeight) {
      const canvas = this.$refs.canvas
      // 修改画布的CSS大小和图片保持一致
      // canvas.style.width = naturalWidth + 'px'
      // canvas.style.height = naturalHeight + 'px'
      canvas.width = naturalWidth
      canvas.height = naturalHeight

      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, naturalWidth, naturalHeight)
      // 绘制图片
      ctx.drawImage(this.img, 0, 0, naturalWidth, naturalHeight)
      // 绘制折线
      const point = this.point
      point.forEach(item => {
        this.drawPoint(item, ctx)
      })
    },
    drawPoint({ anchorPoint, partName }, ctx) {
      const pointList = anchorPoint.split(';')
      const list = []
      for (let i = 0; i < pointList.length; i++) {
        list.push(pointList[i].split(','))
      }
      ctx.fillStyle = 'rgba(76,100,254,.4)'
      ctx.strokeStyle = 'rgba(76,100,254,1)'
      ctx.lineWidth = 2
      ctx.setLineDash([4, 2])

      ctx.beginPath()
      list.forEach((item, idx) => {
        if (idx === 0) {
          ctx.moveTo(item[0], item[1])
        } else {
          ctx.lineTo(item[0], item[1])
        }
      })
      ctx.closePath()
      ctx.fill()
      ctx.stroke()
      const widthSum = list.map(item => item[0]).reduce((sum, item) => { return item * 1 + sum * 1 })
      const heightSum = list.map(item => item[1]).reduce((sum, item) => { return item * 1 + sum * 1 })
      ctx.font = '30px Microsoft YaHei'
      ctx.fillStyle = '#fff'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(partName, widthSum / list.length, heightSum / list.length)
    }
  }
}
</script>

