<template>
  <div class="g-approvalInfo detailsBox">
    <div class="go_back_common">
      <div class="go_back_title" @click="getBack()">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>

    <UmFlowInfo
      :enterprise-flow-get-approval-detail="enterpriseFlowGetApprovalDetail"
      :enterprise-flow-get-approval-nodes="enterpriseFlowGetApprovalNodes"
      :enterprise-flow-overrule="enterpriseFlowOverrule"
      :enterprise-flow-agree="enterpriseFlowAgree"
      :enterprise-flow-withdraw="enterpriseFlowWithdraw"
      :enterprise-flow-get-return-nodes="enterpriseFlowGetReturnNodes"
      :enterprise-flow-before-add-sign-task="enterpriseFlowBeforeAddSignTask"
      :enterprise-flow-return-node="enterpriseFlowReturnNode"
      :span="8"
      :app-flow-record-uuid="appFlowRecordUuid"
      :more="false"
    />
  </div>
</template>
<script>
import {
  enterpriseFlowGetApprovalDetail, // 获取审批记录信息
  enterpriseFlowGetApprovalNodes, // 获取审批流程节点集合
  enterpriseFlowOverrule, // 【操作】驳回
  enterpriseFlowAgree, // 【操作】同意
  enterpriseFlowWithdraw, // 【操作】撤回审批
  enterpriseFlowGetReturnNodes, // 【操作】获取可退回节点
  enterpriseFlowBeforeAddSignTask, // 加签
  enterpriseFlowReturnNode // 退回
}
from '@/api/dashboard'

export default {
  name: 'ApprovalInfo',
  components: {
  },
  data() {
    return {
      appFlowRecordUuid: this.$route.query.appFlowRecordUuid
    }
  },
  mounted() {
  },
  methods: {
    getBack() {
      // this.$router.go(-1)
      this.$router.replace('/dashboard/approve')
    },
    // 加签
    enterpriseFlowBeforeAddSignTask(params) {
      return enterpriseFlowBeforeAddSignTask({ ...params }).then(d => {
        return Promise.resolve(d) // 给内部组件使用
      }).catch(e => {
        this.$errorHandle(e)
        return Promise.reject(e) // 给内部组件使用
      })
    },
    enterpriseFlowGetReturnNodes(params) { // 【操作】获取可退回节点
      return enterpriseFlowGetReturnNodes({ ...params }).catch(e => {
        this.$errorHandle(e)
        return Promise.reject(e) // 给内部组件使用
      })
    },
    enterpriseFlowReturnNode(params) { // 退回
      return enterpriseFlowReturnNode({ ...params }).then(d => {
        return Promise.resolve(d) // 给内部组件使用
      }).catch(e => {
        this.$errorHandle(e)
        return Promise.reject(e) // 给内部组件使用
      })
    },
    // 获取审批详情
    enterpriseFlowGetApprovalDetail(params) {
      return enterpriseFlowGetApprovalDetail({ ...params })
    },
    // 获取审批流程节点数据
    enterpriseFlowGetApprovalNodes() {
      return enterpriseFlowGetApprovalNodes({ appFlowRecordUuid: this.appFlowRecordUuid })
    },
    enterpriseFlowWithdraw(params) { // 撤回申请
      return enterpriseFlowWithdraw({ ...params })
    },
    enterpriseFlowOverrule(params) { // 驳回
      return enterpriseFlowOverrule({ ...params })
    },
    enterpriseFlowAgree(params) { // 同意
      return enterpriseFlowAgree({ ...params })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
