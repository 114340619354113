<template>
  <EnterpriseLayout>
    <div class="m_enterprise_enterprisebasic">
      <div v-if="isedit==1" class="m_enterprisebasic_head" @click="back1">
        <span class="m_enterprisebasic_headicon" />
        <span class="m_enterprisebasic_headtxt">返回</span>
      </div>
      <div v-if="isedit==1" class="m_enterprisebasic_lines" />
      <div class="m_enterprisebasic_formbody">
        <div class="edittop" />
        <el-form v-if="isedit==1" ref="ruleForm" :key="1" :model="ruleForm" :rules="rules" label-width="102px" label-position="left" class="demo-ruleForm">
          <el-form-item label="企业名称" prop="enterpriseName">
            <el-input v-model="ruleForm.enterpriseName" placeholder="请填写企业名称" maxlength="50" clearable />
          </el-form-item>
          <div class="m_enterprisebasic_headimg">
            <el-form-item label="企业logo" prop="enterpriseIconUrl" class="nones " style="height: 90px;">
              <div class="edittop">
                <div class="m_enterprisebasic_avatarup">
                  <div class="tipss">
                    <UmUploadImg
                      v-model="ruleForm.enterpriseIconUrl"
                      :show-icon="false"
                      custom-file
                      :limit="1"
                      :simple="true"
                      is-cropper
                      :cropper-options="{aspectRatio:1/1}"
                      :construciton-tip="false"
                    >
                      <template #extend>
                        <div class="tips2">
                          <div class="imgicon" />
                        </div>
                      </template>
                    </UmUploadImg>
                  </div>
                  <span class="toptitle">建议上传图片尺寸为640*640 <br> 大小不超过10M</span>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="l-form">
            <el-form-item label="企业地址" class="nones" prop="enterpriseAddress">
              <el-input v-model="ruleForm.enterpriseAddress" placeholder="请填写企业地址" clearable maxlength="50" />
            </el-form-item>
            <el-form-item label="联系电话" class="nones" prop="enterpriseMobile">
              <el-input v-model="ruleForm.enterpriseMobile" placeholder="请填联系电话" maxlength="20" clearable />
            </el-form-item>
          </div>
          <div class="r-form">
            <el-form-item label="所属行业" prop="department" style="height: auto">
              <span class="m_enterprisebasic_parment" style="display: flex">
                <el-cascader
                  v-model="ruleForm.enterpriseIndustryCode"
                  placeholder="请选择行业"
                  :options="classifyList"
                  filterable
                  :show-all-levels="false"
                  :props="{
                    children: 'childData',
                    label: 'classifyName',
                    value: 'classifyUuid',
                    emitPath: false
                  }"
                  @change="handleChange"
                />
                <!--                选择行业弹窗-->
                <!--                <span @click="centerDialogVisible=true"> 设置</span>-->
              </span>
            </el-form-item>
            <el-form-item label="员工规模" class="nones" prop="staffSize">
              <!--              <el-input v-model="ruleForm.staffSize" placeholder="请填写员工规模" clearable />-->
              <el-select v-model="ruleForm.staffSize" clearable placeholder="请选择员工规模" style="width: 100%">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="editsaveForm">保存</el-button>
            </el-form-item>
          </div>
        </el-form>
        <el-form v-else ref="infoForm" :model="ruleForm" label-width="95px" class="demo-ruleForm" label-position="left">
          <el-form-item label="企业名称">
            {{ infoForm.enterpriseName||'-' }}
          </el-form-item>
          <div class="m_enterprisebasic_headimg">
            <div class="m_enterprisebasic_editimg">
              <div class="m_enterprisebasic_infoedit">
                <span class="m_enterprisebasic_htxt">企业logo：</span>
                <el-image
                  style="width: 90px; height: 90px;border-radius: 3px"
                  :src="infoForm.enterpriseIconUrl"
                  fit="fit"
                >
                  <div slot="error" class="image-slot">
                    <div class="m_enterprisebasic_compoangnodata" />
                  </div>
                </el-image>
              </div>
              <div v-if="isedit!=1" v-permission="['11203040103']" class="m_enterprisebasic_editbtn">
                <el-button type="primary" @click="edit">编辑</el-button>
              </div>
            </div>
          </div>
          <el-form-item label="企业地址">
            {{ infoForm.enterpriseAddress||'-' }}
          </el-form-item>
          <el-form-item label="联系电话">
            {{ infoForm.enterpriseMobile||'-' }}
          </el-form-item>
          <div class="m_enterprisebasic_form">
            <el-form-item label="所属行业">
              <span class="m_enterprisebasic_parment">
                {{ infoForm.enterpriseIndustryName||'-' }}
              </span>
            </el-form-item>
            <el-form-item label="员工规模">
              {{ infoForm.staffSize||'0' }} 人
            </el-form-item>
            <el-form-item label="企业创建人">
              {{ infoForm.createUserName||'-' }} <span v-permission="['11203040104']" class="m_enterprisebasic_zhuanrang" @click="transfer">转让</span>
            </el-form-item>
            <el-form-item label="创建时间">
              {{ infoForm.createTime||'-' }}
            </el-form-item>
            <el-form-item label="公司ID">
              {{ infoForm.enterpriseUuid||'-' }}
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="m_enterprisebasic_dialog">
        <div class="m_enterprisebasic_transfer" :class="{m_enterprisebasic_transfer1:!step}">
          <el-dialog
            title="转让创建人"
            :visible.sync="pretendvisible"
            width="522px"
            :append-to-body="false"
            center
            @close="cancelsms"
          >
            <div v-if="step" class="m_enterprisebasic_tips1">
              <div class="m_enterprisebasic_msgtitle">验证码已发送至：</div>
              <div class="m_enterprisebasic_msgtel">+86 {{ userMobile }}</div>
              <div class="m_enterprisebasic_msgcode">
                <el-form ref="smsForm" :model="smsForm" :rules="smsFormRules" autocomplete="on" label-position="left">
                  <el-form-item v-if="msgcodes == 3020" prop="imgCode">
                    <el-input
                      ref="imgCode"
                      v-model.trim="smsForm.imgCode"
                      placeholder="请输入图形验证码"
                      name="imgCode"
                      tabindex="2"
                      :clearable="false"
                      maxlength="4"
                      autocomplete="off"
                    >
                      <template slot="suffix">
                        <div class="m_enterprisebasic_imgstyle">
                          <img
                            :src="imgCode"
                            width="110px"
                            height="25px"
                            alt=""
                            @click="getLoginValiteImg"
                          >
                        </div>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="smsCode">
                    <el-input
                      key="passwordType"
                      ref="smsCode"
                      v-model="smsForm.smsCode"
                      type="text"
                      placeholder="请输入短信验证码"
                      name="smsCode"
                      tabindex="2"
                      :clearable="false"
                      autocomplete="off"
                      maxlength="6"
                      @keyup.enter.native="nextskip"
                    />
                    <div class="verificationcode">
                      <!--                        <span>|</span>-->
                      <el-button type="text" :disabled="flagd" style="width: 75px" @click="getCaptchad">{{ contentd }}</el-button>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div v-else class="m_enterprisebasic_tips">
              <um-bus-user ref="bususer" v-model="orgUserUuid" search-tip="请输入成员姓名" :type="2" :limit="1" title="选择转让人" :show-prefix-button="false" @result="resultChange">
                <template #user="{data}">
                  <span v-if="data&&data.length">
                    <span v-for="item in data" :key="item.id">
                      <span class="m_enterprisebasic_pimgs">
                        <el-image
                          style="width: 90px; height: 90px;border-radius: 4px;cursor: pointer"
                          :src="item.icon"
                          fit="fit"
                          @click="changepeople"
                        />
                        <span class="m_enterprisebasic_uname row_1">{{ item.name }}</span>
                        <span class="m_enterprisebasic_topicon" @click="delpeople(item.id)" />
                      </span>
                    </span>
                  </span>
                  <!--                <span v-else style="border-radius: 4px;border: 1px solid #E4E8EB;color: #31415F;padding: 7px 10px">未设置</span>-->
                  <div v-else class="m_enterprisebasic_tips2" @click="changepeople" />
                </template>
              </um-bus-user>
            </div>
            <span slot="footer" class="dialog-footer flex-center">
              <span @click="cancelsms"><el-button>取消</el-button></span>
              <span v-if="step"><el-button type="primary" @click="nextskip">下一步</el-button></span>
              <span v-else><el-button type="primary" @click="suretransfer">确认转让</el-button></span>
            </span>
          </el-dialog>
        </div>
        <div>
          <el-dialog
            title="所属行业"
            :visible.sync="centerDialogVisible"
            width="560px"
            center
          >
            <span>
              <el-input
                v-model="input"
                placeholder="请根据行业关键词搜索"
                clearable
              />
              <el-button type="primary" class="el-icon-search searchbtn" @click="searchs"> 搜索</el-button>
            </span>
            <span>
              <span class="chosetitle">
                <span class="titleone">选择一级分类</span>
                <span class="titletwo">选择二级分类</span>
              </span>
              <el-cascader-panel
                ref="chosexm"
                v-model="ruleForm.enterpriseIndustryCode"
                :show-all-levels="false"
                :props="{
                  children: 'childData',
                  label: 'classifyName',
                  value: 'classifyUuid',
                  emitPath: false
                }"
                :options="classifyList"
                @change="choseitem"
              />
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="centerDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
  </EnterpriseLayout>
</template>

<script>
import { base_info, send_make_over_message, verify_make_over_phone, make_over, update_base_info } from '@/api/enterprise'
import { deepClone } from '@/utils'
import { getPublicKey, identify_code } from '@/api/user'
import { mapGetters } from 'vuex'
import { get_classify_tree } from '@/api/common'
import { validEmail } from '@/utils/validate'
import JSEncrypt from 'jsencrypt'
export default {
  name: 'EnterpriseBasic',
  props: {},
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('请输入短信验证码'))
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      // const RegExp = /^((0\d{2,3}-\d{7,8})|(^1\d{10}))$/
      const RegExp = /^((400|800)[0-9]{7})|((0\d{2,3}\-)?([2-9]\d{6,7})+(\-\d{1,6})?)|((1)\d{10})$/
      if (value) {
        if (RegExp.test(value) == false) {
          callback(new Error('请正确输入联系电话'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      msgcodes: '',
      publicKey: '',
      encryptor: null,
      input: '',
      enterpriseIndustryCode: '', // 行业编码
      centerDialogVisible: false, // 选择行业弹窗
      orgUserUuid: [],
      isedit: 2, // 2编辑 1详情
      contentd: '发送验证码',
      pretendvisible: false, // 转让创建人
      step: true, //  是否第一步
      smsForm: { smsCode: '', imgCode: '' }, // 短信验证码
      imgCode: '',
      imgUuid: '',
      flagd: false,
      userMobile: '',
      uuid: '',
      totalTimed: 60,
      smsFormRules: {
        smsCode: [{ required: true, trigger: 'change', validator: validatePassword }],
        imgCode: [{ required: true, trigger: 'change', message: '请输入图形验证码' }]
      },
      editdepartmentHead: [], // 详情部门负责人
      departmentHead: [], // 部门负责人
      clonedepartmentList: [],
      people: null,
      part: null,
      imgurl: '',
      choseVisible: false,
      choseVisible1: false,
      ruleForm: {
        enterpriseName: '',
        enterpriseIconUrl: '',
        enterpriseAddress: '',
        enterpriseMobile: '',
        enterpriseIndustryCode: '',
        staffSize: ''
      },
      infoForm: {},
      rules: {
        enterpriseName: [
          { required: true, message: '请输入姓名', trigger: 'change' }
        ],
        enterpriseMobile: [
          { required: false, trigger: 'blur', validator: validatePhone }
        ]
      },
      classifyList: [],
      options: [{
        value: '1-50',
        label: '1-50'
      }, {
        value: '51-100',
        label: '51-100'
      }, {
        value: '101-200',
        label: '101-200'
      }, {
        value: '201-500',
        label: '201-500'
      }, {
        value: '501-1000',
        label: '501-1000'
      }, {
        value: '1001-2000',
        label: '1001-2000'
      }, {
        value: '2001以上',
        label: '2001以上'
      }]
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  watch: {},
  created() {
    this.get_classify_tree()
    this.base_infos()
    this.userMobile = this.userInfo.userMobile
    this.uuid = this.userInfo.userUuid
  },
  beforeDestroy() {
  },
  mounted() {
    // 获取图形验证码
    // this.getLoginValiteImg()
  },
  methods: {
    // 获取公钥
    getPublicKey() {
      getPublicKey({}).then(res => {
        this.publicKey = res.data
        this.msgcodes = res.code
      }
      ).finally(d => {
      }).catch(e => {
        if (e.code == 3020) {
          this.publicKey = e.data
        }
        this.msgcodes = e.code
      })
    },
    // 搜索行业
    searchs() {
      this.classifyList.forEach(item => {
        this.filternode(item)
      })
    },
    filternode(val) {
      if (val.chlidData.length) {
        val.chlidData.forEach(item => {
          if (item.classifyName.includes(this.input)) {
            return item
          }
        })
      }
    },
    // 选择行业
    choseitem(val) {
      const downLoadLvl = this.$refs.chosexm.getCheckedNodes()
      downLoadLvl.forEach(item => {

      })
    },
    // 获取行业树
    get_classify_tree() {
      get_classify_tree({
        // classifyCode: 2003010103
        classifyCode: 2003010102
      }).then(res => {
        this.classifyList = res.data
      })
    },
    /**
     * 获取图形验证码
     */
    getLoginValiteImg() {
      identify_code().then(res => {
        const { imgCode, imgUuid } = res.data
        this.imgCode = imgCode
        // this.loginForm.imgUuid = imgUuid
        this.imgUuid = imgUuid
      })
    },
    // 转让
    transfer() {
      this.getPublicKey()
      this.contentd = '发送验证码'
      this.pretendvisible = true
      this.getLoginValiteImg()
    },
    // 取消
    cancelsms() {
      // this.$refs.smsForm.resetFields()
      this.pretendvisible = false
      this.smsForm.smsCode = ''
      this.smsForm.imgCode = ''
      this.step = true
      this.totalTimed = 0
    },
    // 下一步
    nextskip() {
      this.$refs.smsForm.validate(valid => {
        if (valid) {
          verify_make_over_phone({
            userMobile: this.userMobile,
            smsCode: this.smsForm.smsCode
          }).then(d => {
            this.step = false // 第二步
          }).finally(_ => {

          }).catch(e => {
            if (e.code == 3020) {
              this.msgcodes = e.code
            }
          })
        } else {
          return false
        }
      })
    },
    changepeople() {
      this.$refs.bususer.openModal()
    },
    delpeople(val) {
      //
      const index = this.orgUserUuid.findIndex(item => item == val)
      this.orgUserUuid.splice(index, 1)
    },
    // 确认转让
    suretransfer() {
      if (!this.orgUserUuid.length) {
        return this.$message.warning('请选择需要转让的人！')
      }
      const load = this.$load()
      make_over({
        orgUserUuid: this.orgUserUuid[0]
      }).then(d => {
        this.$message.success('操作成功！')
        this.base_infos()
        this.orgUserUuid = []
      }).finally(_ => {
        // this.cancelsms()
        load.close()
        this.pretendvisible = false
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    // 获取短信验证码
    getCaptchad() {
      if (!this.smsForm.imgCode && (this.msgcodes == 3020)) return this.$message.warning('请输入图形验证码')
      let userMobile = deepClone(this.userMobile)
      if (this.publicKey && this.publicKey != '') {
        this.encryptor = new JSEncrypt()
        this.encryptor.setPublicKey('-----BEGIN PUBLIC KEY-----' + this.publicKey + '-----END PUBLIC KEY-----')
        userMobile = this.encryptor.encrypt(this.userMobile)
      }
      this.flagd = true
      // this.contentd = this.totalTimed + 's后重新发送'
      this.contentd = this.totalTimed + 's'
      const clock = window.setInterval(() => {
        this.totalTimed--
        // this.contentd = this.totalTimed + 's后重新发送'
        this.contentd = this.totalTimed + 's'
        if (this.totalTimed < 0) {
          window.clearInterval(clock)
          // this.contentd = '重新发送验证码'
          this.contentd = '重新发送验证码'
          this.totalTimed = 60
          this.flagd = false
        }
      }, 1000)
      let imgUuids = ''
      let imgCodes = ''
      if (this.msgcodes != 3020) {
        imgUuids = ''
        imgCodes = ''
      } else {
        imgUuids = this.imgUuid
        imgCodes = this.smsForm.imgCode
      }
      send_make_over_message({
        // userMobile: this.userMobile,
        userMobile: userMobile,
        imgUuid: imgUuids,
        imgCode: imgCodes
      }).then(res => {
        this.$message.success('短信验证码获取成功，请注意查收！')
      }).catch(e => {
        this.$errorHandle(e)
        this.getLoginValiteImg()
        window.clearInterval(clock)
        // this.contentd = '重新发送验证码'
        this.contentd = '重新发送验证码'
        this.totalTimed = 60
        this.flagd = false
        if (e.code == 3020) {
          this.msgcodes = e.code
        }
      })
    },
    // 编辑
    edit() {
      this.ruleForm.enterpriseName = this.infoForm.enterpriseName || ''
      this.ruleForm.enterpriseIconUrl = this.infoForm.enterpriseIconUrl || ''
      this.ruleForm.enterpriseAddress = this.infoForm.enterpriseAddress || ''
      this.ruleForm.enterpriseMobile = this.infoForm.enterpriseMobile || ''
      this.ruleForm.enterpriseIndustryCode = this.infoForm.enterpriseIndustryCode || ''
      this.ruleForm.staffSize = this.infoForm.staffSize || ''
      this.isedit = 1
      // this.ruleForm = this.infoForm
      this.departmentHead = this.editdepartmentHead
      // this.$refs.editpart.openModal()
      // this.infoForm.userSexCode = this.ruleForm.userSexCode
    },
    base_infos() {
      const load = this.$load()
      base_info({
        enterpriseUuid: this.userInfo.enterpriseUuid
      }).then(d => {
        this.infoForm = d.data
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    resultChange(val) {
      this.departmentHead = val
    },
    back1() {
      // this.orgUserUuid = ''
      this.isedit = 2
      this.base_infos()
      this.$refs.ruleForm.resetFields()
    },
    editsaveForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const load = this.$load()
          update_base_info({ ...this.ruleForm }).then(d => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            // this.$refs.ruleForm.resetFields()
            this.back1()
            this.$store.dispatch('user/getInfo')
          }).finally(_ =>
            load.close()
          ).catch(e => {
            load.close()
            this.$errorHandle(e)
          })
        }
      })
    },
    changpart() {
      this.$refs.chosepart.openModal()
    },
    changperson() {
      this.$refs.UmBusUser.openModal()
    },
    changpartuser() {
      this.$refs.partuser.openModal()
    },
    handleChange(val) {

    }
    // resetsteps() {
    //   this.step = true
    //   console.log('关闭了')
    // }
  }
}
</script>

<style lang="scss" scoped>
</style>
