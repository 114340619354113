import request from '@/utils/request'
const baseURL = 'https://api.com'
/**
 * 密码登陆
 * @param data
 * @returns {AxiosPromise}
 */
export function login(data) {
  return request({
    url: '/api/v1/auth/auth/login_mobile_pc_password',
    method: 'post',
    data
  })
  // return Promise.resolve({
  //   code: 3001,
  //   data: {
  //     token: '23232'
  //   }
  // })
}

export function login_code(params) {
  return Promise.resolve({
    code: 3001,
    data: {
      token: '23232'
    }
  })
}

/**
 * 获取图形验证码
 * @param params
 * @returns {AxiosPromise}
 */
export function identify_code(params) {
  return request({
    url: '/api/v1/auth/auth/identify_code',
    method: 'get',
    params
  })
}

/**
 * 获取个人信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getInfo(params) {
  return request({
    url: '/api/v1/auth/users/get_current_user',
    method: 'get',
    params
  })
  // return Promise.resolve({
  //   code: 3001,
  //   data: {
  //     roles: ['admin']
  //   }
  // })
}

/**
 * 获取登录二维码
 * @param params
 * @returns {AxiosPromise}
 */
export function login_qrcode(params) {
  return request({
    url: '/api/v1/auth/auth/login_qrcode',
    method: 'get',
    params
  })
}

export function logout() {
  return request({
    url: '/api/v1/auth/auth/logout',
    method: 'delete'
  })
}

/**
 * 手机号登录
 * @param data
 * @returns {AxiosPromise}
 */
export function sms_login(data) {
  return request({
    url: '/api/v1/auth/auth/login_mobile_code',
    method: 'post',
    data
  })
  // return Promise.resolve({
  //   code: 3001,
  //   data: {
  //     token: '23232'
  //   }
  // })
}

/**
 * 获取短信验证码
 * @param data
 * @returns {AxiosPromise}
 */
export function send_login_sms_code(data) {
  return request({
    url: '/api/v1/auth/auth/login_message_code',
    method: 'post',
    data
  })
  // return Promise.resolve({
  //   code: 3001,
  //   data: {
  //     token: '23232'
  //   }
  // })
}

/**
 * 获取登录短信验证码
 * @param data
 * @returns {AxiosPromise}
 */
export function login_message_code(data) {
  return request({
    url: '/api/v1/auth/auth/login_message_code',
    method: 'post',
    data
  })
}

/**
 * 获取注册短信验证码
 * @param data
 * @returns {AxiosPromise}
 */
export function register_message_code(data) {
  return request({
    url: '/api/v1/auth/auth/register_message_code',
    method: 'post',
    data
  })
}

/**
 * 提交注册
 * @param data
 * @returns {AxiosPromise}
 */
export function register_submit(data) {
  return request({
    url: '/api/v1/auth/auth/register_submit',
    method: 'post',
    data
  })
}

/**
 * 【完善信息】提交完善个人信息
 * @param data
 * @returns {AxiosPromise}
 */
export function completeInfo(data) {
  return request({
    url: '/api/v1/auth/users/completeInfo',
    method: 'post',
    data
  })
}

/**
 * 【创建企业】提交创建企业
 * @param data
 * @returns {AxiosPromise}
 */
export function create_enterprise(data) {
  return request({
    url: '/api/v1/enterprise/enterprise/create_enterprise',
    method: 'post',
    data
  })
}

/**
 * 【进入企业】设置默认企业
 * @param data
 * @returns {AxiosPromise}
 */
export function enterpriseDefaultFlag(data) {
  return request({
    url: '/api/v1/enterprise/enterprise/defaultFlag',
    method: 'post',
    data
  })
}

/**
 * 【进入企业】提交进入企业
 * @param data
 * @returns {AxiosPromise}
 */
export function intoEnterprise(data) {
  return request({
    url: '/api/v1/enterprise/enterprise/into',
    method: 'post',
    data
  })
}

/**
 * 【切换企业】切换企业
 * @param data
 * @returns {AxiosPromise}
 */
export function switchEnterprise(params) {
  return request({
    url: '/api/v1/enterprise/enterprise/switch',
    method: 'get',
    params
  })
}
/**
 * 【登录】扫描二维码
 * @param data
 * @returns {AxiosPromise}
 */
export function scan_qrcode(params) {
  return request({
    url: '/api/v1/auth/auth/scan_qrcode',
    method: 'get',
    params
  })
}

/**
 * 【创建企业】获取企业的行业类型
 * @param data
 * @returns {AxiosPromise}
 */
export function get_enterprise_category(params) {
  return request({
    url: '/api/v1/enterprise/enterprise/get_enterprise_category',
    method: 'get',
    params
  })
}

/**
 获取全量系统区域信息
 */
export function getAllArea(params) {
  return request({
    url: '/api/v1/auth/sysconfig/get_system_area',
    // url: '/open_platform_api/v1/system_config/sysconfig/get_system_area',
    method: 'get',
    params
  })
}

/**
 * 【企业列表】获取个人企业列表（包含未验证）
 * @param data
 * @returns {AxiosPromise}
 */
export function enterpriseList(params) {
  return request({
    url: '/api/v1/enterprise/enterprise/list',
    method: 'get',
    params
  })
}

/**
 * 获取im未读消息数量
 * @param data
 * @returns {AxiosPromise}
 */
export function message_unread_num(data) {
  return request({
    url: '/api/v1/im/im/p/query/message_unread_num',
    method: 'post',
    data
  })
}

// 注册页面提交注册
export function submitRegister(data) {
  return request({
    url: '/api/v1/auth/auth/register_submit_pc',
    method: 'post',
    data
  })
}

// 注册设置密码
export function setPassword(data) {
  return request({
    url: '/api/v1/auth/users/update_password',
    method: 'post',
    data
  })
}

// 【登录/注册（通用）】获得公钥，用于加密
export function getPublicKey(params) {
  return request({
    url: '/api/v1/auth/auth/public_key',
    method: 'get',
    params
  })
}

// 【登录/注册（通用）】获得公钥，用于加密
export function enterpriseTeam(data) {
  return request({
    url: '/api/v1/enterprise/enterprise/join',
    method: 'post',
    data
  })
}
// [修改密码]修改密码发送短信验证码
export function change_password(data) {
  return request({
    url: '/api/v1/auth/auth/change_password_message_code',
    method: 'post',
    data
  })
}
// [修改密码]校验短信验证码
export function verify_change_password(data) {
  return request({
    url: '/api/v1/auth/auth/verify_change_password_message_code',
    method: 'post',
    data
  })
}
// [修改密码]修改密码提交
export function change_password_submit(data) {
  return request({
    url: '/api/v1/auth/auth/change_password',
    method: 'post',
    data
  })
}
