<template>
  <div class="m_applyaction">
    <el-dialog
      :visible.sync="invitations"
      center
      title="加入企业申请"
      width="528px"
      :append-to-body="false"
      destroy-on-close
      @close="close"
    >
      <div>
        <div>
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px">
            <el-form-item label="姓名" prop="userName">
              <el-input v-model="ruleForm.nickName" disabled />
            </el-form-item>
            <el-form-item label="手机号" prop="userMobile">
              <el-input v-model="ruleForm.userMobile" disabled />
            </el-form-item>
            <el-form-item label="所属部门" prop="departmentList">
              <span class="m_enterpriser_parment">
                <UmBusUser ref="chosepart" v-model="ruleForm.departmentList" :type="1" title="选择成员所在部门" :show-prefix-button="false" @result="resultChange">
                  <template #user="{data}">
                    <span v-if="data&&data.length" class="itemcontent">
                      <span v-for="item in data" :key="item.id" class="m_enterpriser_texts" @mouseover="mouseover(item.id)" @mouseout="mouseout">
                        <div class="m_enterpriser_mains" :class="{m_enterpriser_itemactive:item.id==maincode,m_enterpriser_mainss:item.id==choseid}" @click="setmain(item)" />
                        {{ item.name }}
                        <span class="m_enterpriser_delicon" :class="{m_enterpriser_delicons:item.id==choseid}" @click="delmain(item)" />
                      </span>
                      <span class="m_enterpriser_xiugai" @click="changpart">修改</span>
                    </span>
                    <span v-else>
                      <span class="m_enterpriser_weishezhi">未设置</span>
                      <span class="m_enterpriser_xiugai" @click="changpart">修改</span>
                    </span>

                  </template>
                </UmBusUser>
              </span>
            </el-form-item>
            <el-form-item label="负责部门" prop="departmentHead">
              <span v-if="departmentHead.length">
                <el-checkbox-group v-model="ruleForm.departmentHead">
                  <el-checkbox v-for="item in departmentHead" :key="item.id" :label="item.id">
                    {{ item.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </span>
              <span v-else style="border-radius: 4px;border: 1px solid #E4E8EB;color: #31415F;padding: 7px 10px">未设置</span>
            </el-form-item>
            <el-form-item label="职务" prop="userPosition">
              <el-input v-model="ruleForm.userPosition" placeholder="请填写职务" maxlength="10" />
            </el-form-item>
            <el-form-item label="权限" prop="orgRoleId">
              <el-cascader
                v-model="ruleForm.orgRoleId"
                style="width: 368px"
                placeholder="请选择权限"
                :options="classifyList"
                filterable
                :show-all-levels="false"
                :props="{
                  children: 'roleList',
                  label: 'roleName',
                  value: 'orgRoleId',
                  emitPath: false
                }"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="m_applylist_btn">
        <div><el-button type="primary" plain @click="submitForm">拒绝申请</el-button></div>
        <div class="u_btn_su"><el-button type="primary" @click="saveForm">确定添加</el-button></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { audit_submit, get_org_role_by_org_business_id, get_pending_audit_user_info } from '@/api/enterprise'

export default {
  name: 'ApplyAction',
  components: {},
  props: {
    invitationaction: {
      type: Boolean,
      default: false
    },
    puUserUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      invitations: false,
      departmentHead: [],
      maincode: '', // 主部门
      choseid: null,
      ruleForm: {
        nickName: '', // 用户姓名
        userMobile: '', // 用户手机号
        departmentHead: [], // 部门负责人
        departmentList: [], // 所属组织
        orgRoleId: '', // 用户角色
        userPosition: '' // 用户职务
      },
      rules: {
        orgRoleId: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        departmentList: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ]
      },
      classifyList: []// 角色列表
    }
  },
  computed: {},
  watch: {
    invitationaction: {
      handler(val) {
        this.invitations = val
        if (val) {
          this.get_pending_audit_user_infos()
        }
      },
      immediate: true
    },
    'ruleForm.departmentList': {
      handler(val) {
        if (val.length) {
          this.maincode = val[0]
        }
      },
      deep: true
    }
  },
  created() {
    this.get_classify_tree()
  },
  mounted() {},
  methods: {
    // 获取申请详情
    get_pending_audit_user_infos() {
      get_pending_audit_user_info({
        puUserUuid: this.puUserUuid
      }).then(d => {
        this.ruleForm.nickName = d.data.nickName
        this.ruleForm.userMobile = d.data.userMobile
      }).finally(_ => {
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    // 拒绝申请
    submitForm() {
      audit_submit({
        auditFlag: false,
        puUserUuid: this.puUserUuid
      }).then(d => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).finally(_ => {
        this.close()
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    // 确认添加
    saveForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.drawerloading = true
          audit_submit({
            auditFlag: true,
            puUserUuid: this.puUserUuid,
            userPosition: this.ruleForm.userPosition,
            departmentList: this.ruleForm.departmentList,
            departmentHead: this.ruleForm.departmentHead,
            orgRoleId: this.ruleForm.orgRoleId,
            primaryOrganUuid: this.maincode
          }).then(d => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.$emit('success')
          }).finally(_ => {
            this.close()
          }).catch(e => {
            this.$errorHandle(e)
          })
        }
      })
    },
    // 关闭弹窗
    close() {
      this.$refs.ruleForm.resetFields()
      this.invitations = false
      this.$emit('update:invitationaction', false)
      this.$emit('changevisibleaction', false)
    },
    // 获取角色树
    get_classify_tree() {
      get_org_role_by_org_business_id().then(res => {
        this.classifyList = res.data.list
      })
    },
    resultChange(val) {
      this.departmentHead = val
    },
    mouseover(id) {
      this.choseid = id
    },
    mouseout() {
      this.choseid = null
    },
    // 设置主部门
    setmain(val) {
      this.maincode = val.id
    },
    changpart() {
      this.$refs.chosepart.openModal()
    },
    delmain(val) {
      // if (this.maincode == val.id) {
      const index = this.ruleForm.departmentList.findIndex(item => item == val.id)
      if (index >= 0) {
        this.ruleForm.departmentList.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.m_applyaction{
  .m_enterpriser_parment {
    display: flex;
    .itemcontent {
      display: flex;
      flex-wrap: wrap;
    }
    .m_enterpriser_texts {
      max-width: 350px;
      position: relative;
      text-align: center;
      padding: 0px 34px 0px 24px;
      margin-right: 20px;
      margin-bottom: 10px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid #e4e8eb;
      font-weight: 400;
      font-size: 14px;
      color: #31415f;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      .m_enterpriser_mains {
        position: absolute;
        top: 9px;
        left: -1px;
        display: none;
        cursor: pointer;
        width: 20px;
        height: 14px;
        background: url("~@/assets/enterprise/zhu2.png") no-repeat 0 0 /20px
        14px;
      }
      .m_enterpriser_mainss {
        display: block;
      }
      .m_enterpriser_itemactive {
        position: absolute;
        top: 9px;
        left: -1px;
        cursor: pointer;
        display: block;
        width: 20px;
        height: 14px;
        background: url("~@/assets/enterprise/zhu1.png") no-repeat 0 0 /20px
        14px;
      }
      .m_enterpriser_delicon {
        cursor: pointer;
        position: absolute;
        display: none;
        top: 12px;
        right: 7px;
        width: 10px;
        height: 10px;
        background: url("~@/assets/enterprise/close1.png") no-repeat 0 0 /10px
        10px;
      }
      .m_enterpriser_delicons {
        display: block;
      }
    }
    .m_enterpriser_xiugai {
      position: relative;
      cursor: pointer;
      margin-left: 20px;
      display: inline-block;
      color: #3d80fc;
      font-size: 14px;
    }
  }
  .m_enterpriser_weishezhi {
    border-radius: 4px;
    border: 1px solid #e4e8eb;
    color: #31415f;
    padding: 7px 10px;
  }
  .m_applylist_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .u_btn_su{
      margin-left: 20px;
    }
  }
}

</style>
