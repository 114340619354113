<template>
  <div class="house">
    <HotTable
      ref="hotRef"
      :settings="settings"
      language="zh-CN"
      style="width:100%;overflow: hidden;font-size: 12px;"
    />
    <el-dialog
      title="选择户型"
      :visible.sync="visible"
      width="400px"
      append-to-body
    >
      <el-form
        ref="addForm"
        :model="addForm"
        size="mini"
        @submit.native.prevent
      >
        <el-form-item
          label="户型名称"
          prop="name"
        >
          <el-select
            v-model="addForm.name"
            placeholder="请选择"
          >
            <el-option
              v-for="(item,index) in houseType"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer text-center"
      >
        <el-button @click="visible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="sethuxing"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { HotTable } from '@um/handsontable-vue'
import Handsontable from 'handsontable'
import 'handsontable/languages/zh-CN'
import { deepClone } from '@/utils'
// 楼层信息
class BuildingFloor {
  constructor(
    floorName = '无数据',
    busBuildingFloorId = null,
    floorRealSort = null,
    deleteFlag = 0
  ) {
    this.busBuildingFloorId = busBuildingFloorId // 楼层id
    this.floorName = floorName // 楼层名称
    this.floorRealSort = floorRealSort // 楼层排序
    this.deleteFlag = deleteFlag // 删除标识 0未删除 1删除
  }
}
// 单元信息
class BuildingUnit {
  constructor(
    unitName = '无数据',
    busBuildingUnitId = null,
    unitRealSort = null,
    deleteFlag = 0
  ) {
    this.busBuildingUnitId = busBuildingUnitId // 单元id
    this.unitName = unitName // 单元名称
    this.unitRealSort = unitRealSort // 单元排序
    this.deleteFlag = deleteFlag // 删除标识 0未删除 1删除
  }
}
// 房号信息
class BuildingDoor {
  constructor(
    doorName = '无数据',
    unitRealSort = null,
    busBuildingDoorId = null,
    doorRealSort = null,
    deleteFlag = 0
  ) {
    this.busBuildingDoorId = busBuildingDoorId // 房号id
    this.doorName = doorName // 房号名称
    this.doorRealSort = doorRealSort // 房号排序
    this.unitRealSort = unitRealSort // 单元排序
    this.deleteFlag = deleteFlag // 删除标识 0未删除 1删除
  }
}
// 房间信息
class BuildingRoom {
  constructor(
    roomName = '无数据',
    busHouseTypeId = '',
    roomType = 20242022,
    deleteFlag = 0,
    busBuildingRoomId = null,
    roomMergeFlag = 0,
    roomMergeFloor = 1,
    roomMergeDoor = 1,
    floorRealSort = null,
    unitRealSort = null,
    roomRealSort = null
  ) {
    this.roomName = roomName // 房间名称
    this.busHouseTypeId = busHouseTypeId // 户型id
    this.roomType = roomType // 房间类型
    this.deleteFlag = deleteFlag // 删除标识 0未删除 1删除
    this.busBuildingRoomId = busBuildingRoomId // 房间id
    this.roomMergeFlag = roomMergeFlag // 是否合并其他房间
    this.roomMergeFloor = roomMergeFloor // 合并楼层数量
    this.roomMergeDoor = roomMergeDoor // 合并同楼层房间的数量
    this.floorRealSort = floorRealSort // 真实楼层
    this.unitRealSort = unitRealSort // 真实单元序号
    this.roomRealSort = roomRealSort // 真实房间
  }
}
export default {
  name: 'House',
  components: { HotTable },
  props: {
    sourceData: { type: Array, default: () => [] },
    merges: { type: Array, default: () => [] },
    colWidths: { type: Array, default: () => [] },
    houseType: { type: Array, default: () => [] }
  },
  data() {
    const _this = this
    return {
      visible: false,
      huxing_list: ['户型一', '户型二', '户型三'],
      addForm: {
        selection: null,
        name: ''
      },
      floor_num: 10,
      unit_num: 3,
      door_num: 4,
      busBuildingUnit: [],
      busBuildingRoom: [],
      busBuildingFloor: [],
      busBuildingDoor: [],
      listData: [], // 初始化数据
      resultData: [], // 返回结果数据
      type: false,
      settings: {
        fillHandle: false,
        // height: window.innerHeight - 280,
        height: 380,
        readOnly: false,
        data: [],
        colHeaders: false,
        rowHeaders: false,
        // stretchH: 'all', // 撑开属性
        manualColumnFreeze: true, // 手动列冻结 开启
        fixedRowsTop: 2, // 浮动行  冻结行
        fixedColumnsLeft: 2, // 浮动列  冻结列
        colWidths: [50, 50, 100],
        cells: (row, column, prop) => {
          var columnMeta = {}
          // 左上角
          if (row <= 1 && column <= 1) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              td.innerHTML = value
              td.className = 'htCenter htMiddle hTitle'
              td.style = 'padding:0;'
              return td
            }
            columnMeta.readOnly = true
          }
          // 楼层
          class CustomFloorName extends Handsontable.editors.TextEditor {
            prepare(row, col, prop, td, originalValue, cellProperties) {
              super.prepare(row, col, prop, td, originalValue, cellProperties)
            }

            setValue() {
              if (this.originalValue !== null) {
                this.TEXTAREA.value = this.originalValue.floorName
              }
            }

            getValue() {
              if (this.originalValue !== null) {
                this.originalValue.floorName = this.TEXTAREA.value
              }
              return this.originalValue
            }
          }
          if (row > 1 && column === 0) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              td.innerHTML = value ? value.floorName : value
              td.className = 'htCenter htMiddle hTitle'
              return td
            }
            columnMeta.editor = CustomFloorName
            columnMeta.readOnly = true
          }
          // 单元
          class CustomUnitName extends Handsontable.editors.TextEditor {
            prepare(row, col, prop, td, originalValue, cellProperties) {
              super.prepare(row, col, prop, td, originalValue, cellProperties)
            }

            setValue() {
              if (this.originalValue !== null) {
                this.TEXTAREA.value = this.originalValue.unitName
              }
            }

            getValue() {
              if (this.originalValue !== null) {
                this.originalValue.unitName = this.TEXTAREA.value
              }
              return this.originalValue
            }
          }
          if (row === 0 && column > 1) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              td.innerHTML = value ? value.unitName : value
              td.className = 'htCenter htMiddle hTitle'
              return td
            }
            columnMeta.editor = CustomUnitName
            columnMeta.readOnly = true
          }
          // 房号
          class CustomDoorName extends Handsontable.editors.TextEditor {
            prepare(row, col, prop, td, originalValue, cellProperties) {
              super.prepare(row, col, prop, td, originalValue, cellProperties)
            }

            setValue() {
              if (this.originalValue !== null) {
                this.TEXTAREA.value = this.originalValue.doorName
              }
            }

            getValue() {
              if (this.originalValue !== null) {
                this.originalValue.doorName = this.TEXTAREA.value
              }
              return this.originalValue
            }
          }
          if (row === 1 && column > 1) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              td.innerHTML = value ? value.doorName : value
              td.className = 'htCenter htMiddle hTitle'
              return td
            }
            columnMeta.editor = CustomDoorName
            columnMeta.readOnly = true
          }
          // 房间
          class CustomRoomName extends Handsontable.editors.AutocompleteEditor {
            prepare(row, col, prop, td, originalValue, cellProperties) {
              super.prepare(row, col, prop, td, originalValue, cellProperties)
              this.cellProperties.filter = false
              this.cellProperties.strict = true
            }

            setValue() {
              if (this.originalValue !== null) {
                this.TEXTAREA.value = this.originalValue.busHouseTypeId
              }
            }

            getValue() {
              if (this.originalValue !== null) {
                this.originalValue.busHouseTypeId = this.htEditor.getValue()
                  ? this.htEditor.getValue()
                  : '' // 多选给值
              }
              return this.originalValue
            }
          }
          if (row > 1 && column > 1) {
            columnMeta.renderer = (
              hotInstance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              let html = ''
              if (value && value.roomType == 20242021) {
                // html += '<span class="roomType">公</span>'
              }
              if (value) {
                // html += value.busHouseTypeId
                // html += value.busHouseTypeId ? value.busHouseTypeId : '请选择'
                html += value.busHouseTypeId ? value.busHouseTypeId : '请选择'
              }
              td.innerHTML = html
              td.className = 'htCenter htMiddle htRoom'

              if (value && value.deleteFlag == 1) {
                cellProperties.readOnly = true
                td.className += ' deleted'
                td.innerHTML = '---'
              }
              // 当插入的楼层房间为空时 房间的户型不可以修改（必须房间有信息才可选择房间的户型）
              if (value && value.roomName == '') {
                cellProperties.readOnly = true
                td.className += ' deleted'
                td.innerHTML = '---'
              }
              return td
            }
            columnMeta.editor = CustomRoomName
            // columnMeta.type = 'dropdown'
            columnMeta.source = _this.houseType
          }
          return columnMeta
        },
        afterChange: function(changes) {
          // console.log('0000000000000000', changes)
          // _this.resultData = [].concat(this.getSourceData())
          // console.log(_this.resultData)
          // if (changes !== null) {
          //   if (changes[0][0] > 1 && changes[0][1] <= 1) {
          //     _this.changeData()
          //   }
          //
          //   if (changes[0][0] <= 1 && changes[0][1] > 1) {
          //     _this.changeData()
          //   }
          // }
        },
        afterOnCellMouseUp: (event, coords, TD) => {
          if (coords.row > 1 && coords.col <= 1) {
            this.hotRef.selectRows(coords.row)
          }
          if (coords.row <= 1 && coords.col > 1) {
            this.hotRef.selectColumns(coords.col, coords.col + TD.colSpan - 1)
          }
        },
        // isRedoAvailable(a) {
        //   console.log('=============', a)
        // },
        // afterUndoStackChange(a) {
        //   console.log('进来了a', a)
        // },
        // beforeRedoStackChange(b) {
        //   console.log('进来了b', b)
        // },
        afterSelection: (
          row,
          column,
          row2,
          column2,
          preventScrolling,
          selectionLayerLevel
        ) => {
          // setting if prevent scrolling after selection
        },
        /*
           getSelectedLast  返回应用于表的最后一个坐标【实际结果返回的的是一个区间范围】
           */
        contextMenu: {
          callback: function(key, selection, clickEvent) {},
          items: {
            custom_house: {
              name: '批量设置户型',
              disabled: () => {
                const select = this.hotRef.getSelectedLast()
                const getSourceData = this.hotRef.getSourceData()
                console.log('右击选择户型', select, getSourceData)
                // console.log(getSourceData.flat())
                // const arr = getSourceData.flat()
                // const flag = arr.map(item => {
                //   if (item && typeof item === 'object') {
                //     if (item.roomName) {
                //       return item.roomName !== ''
                //     }
                //   }
                // })
                // console.log(flag)

                if (
                  select[0] < 2 ||
                  select[1] < 2 ||
                  select[2] < 2 ||
                  select[3] < 2
                ) {
                  return true
                } else {
                  return false
                }
              },
              hidden: () => {},
              callback: function(key, selection, clickEvent) {
                _this.visible = true
                _this.addForm.selection = selection
              }
            }
          }
        },
        // before 不能修改数据  after 也不能修改数据，
        afterCreateRow: function(startIndex, amount, source) {},
        afterRemoveRow: function(index, amount, physicalRows, source) {
          _this.resultData = [].concat(this.getSourceData())
          console.log(_this.resultData)
        },
        afterRemoveCol: function(index, amount, physicalColumns, source) {
          _this.resultData = [].concat(this.getSourceData())
          console.log(_this.resultData)
        },
        beforeKeyDown: function(e) {
          console.log(e)
          if (e.keyCode === 8 || e.keyCode === 46) {
            e.stopImmediatePropagation()
            // remove data at cell, shift up
            e.preventDefault()
          }
        }
      }
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.hotRef = this.$refs.hotRef.hotInstance
    this.createTable()
  },
  methods: {
    createTable() {
      this.hotRef.loadData(this.sourceData)
      this.hotRef.updateSettings({
        mergeCells: this.merges,
        colWidths: this.colWidths
      })
    },
    emptylist(length) {
      const list = []
      for (let i = 0; i < length; i++) {
        list.push(null)
      }
      return list
    },
    changeData() {
      // 序列化数据
      const list = this.resultData.map((item, index) => {
        if (index > 1) {
          return item.map((item2, index2) => {
            if (item2 && index2 > 1) {
              item2.roomName = ''
              if (item[0] && item[0].floorName) {
                item2.roomName += item[0].floorName
              }
              if (
                this.resultData[1][index2] &&
                this.resultData[1][index2].doorName
              ) {
                item2.roomName += this.resultData[1][index2].doorName
              }
              return item2
            } else {
              return item2
            }
          })
        } else {
          return item
        }
      })
      this.hotRef.loadData(list)
    },
    init() {
      /*
          * floor_num 楼层
          * unit_num 单元
          * door_num 房号
           */
      const list = []
      let merges = []
      for (let i = 0; i < this.floor_num + 2; i++) {
        const child = []
        const merge = []
        if (i === 0) {
          child.push('楼层')
          child.push('单元')
          merge.push({
            row: 0,
            col: 0,
            rowspan: 2,
            colspan: 1
          })
        }
        if (i === 1) {
          child.push(null)
          child.push('房号')
        }
        if (i > 1) {
          child.push(new BuildingFloor(this.floor_num - i + 2))
          child.push(null)
          merge.push({
            row: i,
            col: 0,
            rowspan: 1,
            colspan: 2
          })
        }

        let col_index = 2 // 列的开始索引
        for (let j = 0; j < this.unit_num; j++) {
          if (i === 0) {
            // 此处筛选的是房号 某个单元下的房号
            child.push(new BuildingUnit(j + 1))
            merge.push({
              row: i,
              col: col_index,
              rowspan: 1,
              colspan: this.door_num
            })
            for (let i = 0; i < this.door_num - 1; i++) {
              child.push(new BuildingUnit('请输入单元'))
            }
          }
          if (i === 1) {
            for (let i = 0; i < this.door_num; i++) {
              child.push(
                new BuildingDoor(((i + 1) / 100).toFixed(2).substr(2, 2))
              )
            }
          }
          if (i > 1) {
            for (let n = 0; n < this.door_num; n++) {
              child.push(
                new BuildingRoom(
                  this.floor_num -
                    i +
                    2 +
                    ((n + 1) / 100).toFixed(2).substr(2, 2)
                )
              )
            }
          }
          col_index += this.door_num
        }
        //
        list.push(child)
        merges = merges.concat(merge)
      }
      this.listData = list
      const colWidths = [40, 40]
      for (let i = 0; i < this.door_num * this.unit_num; i++) {
        colWidths.push(160)
      }
      this.hotRef.loadData(list)
      this.hotRef.updateSettings({ mergeCells: merges, colWidths })
    },
    submit() {
      return new Promise((resolve, reject) => {
        const list = this.hotRef.getSourceData()
        // 先计算出房号 跟单元的关系
        const buildingFloorList = [] // 楼层信息
        const busBuildingUnitList = [] // 单元信息
        const busBuildingDoorList = [] // 房号信息
        const buildingRoomList = [] // 房间信息
        const unitList = [] // 临时单元列表
        list[0].forEach((item2, index2) => {
          if (index2 < 2) return false
          if (unitList.length === 0) {
            const child = deepClone(item2)
            child.unitRealSort = busBuildingUnitList.length + 1
            child.sort = 1
            unitList.push(child)
            busBuildingUnitList.push(child)
            return false
          }
          // bug 不在视图区域的不显示
          // if (this.hotRef.getCell(0, index2) === this.hotRef.getCell(0, index2 - 1)) { // 判断是否相等
          //   const child = deepClone(unitList[unitList.length - 1])
          //   child.sort = unitList[unitList.length - 1].sort + 1
          //   unitList.push(child)
          //   return false
          // }
          let merges = this.hotRef.getPlugin('mergeCells').mergedCellsCollection
            .mergedCells
          merges = merges.filter(item => item.row === 0 && item.rowspan === 1)
          for (let i = 0; i < merges.length; i++) {
            if (
              index2 >= merges[i].col &&
              index2 < merges[i].col + merges[i].colspan
            ) {
              if (index2 === merges[i].col) {
                const child = deepClone(item2)
                child.unitRealSort = busBuildingUnitList.length + 1
                child.sort = 1
                unitList.push(child)
                busBuildingUnitList.push(child)
                return false
              } else {
                const child = deepClone(unitList[unitList.length - 1])
                child.sort = unitList[unitList.length - 1].sort + 1
                unitList.push(child)
                return false
              }
            }
          }
          const child = deepClone(item2)
          child.unitRealSort = busBuildingUnitList.length + 1
          child.sort = 1
          unitList.push(child)
          busBuildingUnitList.push(child)
        })
        list[1].forEach((item2, index2) => {
          if (index2 < 2) return false
          const doorChild = deepClone(item2)
          doorChild.unitRealSort = unitList[index2 - 2].unitRealSort
          doorChild.doorRealSort = unitList[index2 - 2].sort
          busBuildingDoorList.push(doorChild)
        })
        list.forEach((item, index) => {
          if (index < 2) {
            return false
          }
          // 处理楼层
          const floorChild = deepClone(item[0])
          floorChild.floorRealSort = list.length - index
          buildingFloorList.push(floorChild)
          item.forEach((item2, index2) => {
            if (index2 < 2 || item2.deleteFlag === 1 || item2.roomName === '') {
              return false
            }
            const roomChild = deepClone(item2)
            roomChild.floorRealSort =
              buildingFloorList[index - 2].floorRealSort
            roomChild.unitRealSort = unitList[index2 - 2].unitRealSort
            roomChild.roomRealSort =
              busBuildingDoorList[index2 - 2].doorRealSort
            buildingRoomList.push(roomChild)
          })
        })
        console.log('buildingFloorList', buildingFloorList)
        console.log('busBuildingUnitList', busBuildingUnitList)
        console.log('busBuildingDoorList', busBuildingDoorList)
        console.log('buildingRoomList', buildingRoomList)
        resolve({
          buildingFloorList,
          busBuildingUnitList,
          busBuildingDoorList,
          buildingRoomList
        })
      })
    },
    sethuxing() {
      if (this.addForm.name === '') {
        this.$message.error('请选择户型！')
        return false
      }
      const name = this.addForm.name
      const selection = this.addForm.selection
      const list = this.hotRef.getSourceData().map((item, index) => {
        if (
          index >= selection[0].start.row &&
          index <= selection[0].end.row &&
          index > 1
        ) {
          return item.map((item2, index2) => {
            if (
              index2 >= selection[0].start.col &&
              index2 <= selection[0].end.col &&
              index2 > 1
            ) {
              item2 && (item2.busHouseTypeId = name)
              return item2
            } else {
              return item2
            }
          })
        } else {
          return item
        }
      })
      this.hotRef.loadData(list)
      this.visible = false
      this.$refs.addForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
