<template>
  <div class="detailsBox m_maindata_building">
    <div class="go_back_common">
      <div class="go_back_title" @click="goback">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div>
      <div class="m-form">
        <el-form
          :model="searchParam"
          inline
        >
          <el-form-item label="项目名称">
            <um-bus-project
              ref="umbuspro"
              v-model="searchParam.busProjectUuid"
              style="width: 100%"
              placeholder="请选择项目"
              @change="getList()"
            />
          </el-form-item>

          <el-form-item class="fr">
            <div class="flex-column-center">
              <!--              <div v-if="$refs.umbuspro&&!$refs.umbuspro.busProjectTreeData.length" class="f-room-button" @click="$router.push('/mainData/project')">-->
              <!--                <svg-icon icon-class="add_all" />-->
              <!--                <span>新增项目</span>-->
              <!--              </div>-->
              <div
                v-permission="['112040405']"
                class="f-room-button"
                @click="getAdd()"
              >
                <!--              <svg-icon icon-class="loudong" />-->
                <svg-icon icon-class="add_all" />
                <span>新增楼栋</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <NoProject v-if="$refs.umbuspro&&!$refs.umbuspro.busProjectTreeData.length" />
      <div v-else>
        <el-table
          :data="buildingData"
          row-key="xh"
          style="width: 100%"
          class="m_maindata_buildingtable"
        >
          <!--          <el-table-column width="20px" />-->
          <el-table-column label="楼栋名称" prop="buildingName" />
          <el-table-column label="业态属性" prop="buildingFormatTypeCode">
            <template slot-scope="scope">
              {{ TypeCode[scope.row.buildingFormatTypeCode] }}
            </template>
          </el-table-column>
          <el-table-column label="楼栋类型" prop="buildingTypeCode">
            <template slot-scope="scope">
              {{ buildTypeCode[scope.row.buildingTypeCode] }}
            </template>
          </el-table-column>
          <el-table-column label="排序" prop="buildingSort" />
          <el-table-column label="操作" width="200px">
            <template slot-scope="scope">
              <el-button v-permission="['112040404']" type="text" @click="getEdit(scope.row)">编辑</el-button>
              <el-button v-permission="['112040403']" type="text" style="color: #FF6161;" @click="getDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :total="searchTotal"
          :page.sync="searchParam.page.pageNum"
          :limit.sync="searchParam.page.pageSize"
          @pagination="getList('page')"
        />
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="addVisible"
      width="518px"
      @close="$refs.addForm.resetFields()"
    >
      <el-form ref="addForm" :model="addForm" label-width="90px" label-position="left" :rules="addRules">
        <el-form-item label="楼栋名称" prop="buildingName">
          <el-input v-model="addForm.buildingName" placeholder="请输入楼栋名称，最多20字" style="width:100%" maxlength="20" />
        </el-form-item>
        <el-form-item label="所属项目" prop="busProjectUuid">
          <um-bus-project
            v-model="addForm.busProjectUuid"
            style="width: 100%"
            placeholder="请选择项目"
            :show-all-levels="true"
          />
        </el-form-item>
        <el-form-item label="业态属性" prop="buildingFormatTypeCode">
          <el-select v-model="addForm.buildingFormatTypeCode" placeholder="请选择业态属性" style="width: 100%">
            <el-option
              v-for="item in TypeCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="楼栋类型" prop="buildingTypeCode">
          <el-select v-model="addForm.buildingTypeCode" placeholder="请选择楼栋类型" style="width: 100%">
            <el-option
              v-for="item in buildingTypeCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="buildingSort">
          <el-input v-model.number="addForm.buildingSort" maxlength="4" placeholder="输入1~1000的整数，数字越大越靠前" style="width:100%" />
        </el-form-item>
      </el-form>
      <div class="m_umBtn flex-center">
        <div class="u_umBtn_cancel pointer" @click="addVisible=false">取消</div>
        <div class="u_umBtn_save pointer" @click="getSave()">保存</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addBuilding, getBuildingList, editBuilding, delBuilding } from '@/api/mainData/building'
import NoProject from '@/views/mainData/components/noProject'
export default {
  name: 'MainDataBuilding',
  components: { NoProject },
  data() {
    const validateSort = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        callback(new Error('请输入正整数'))
      } else {
        if (value > 1000 || value < 1) {
          callback(new Error('请输入1~1000的正整数'))
        } else {
          callback()
        }
      }
    }

    return {
      searchParam: {
        busProjectUuid: '',
        page: {
          pageNum: 1,
          pageSize: 10
        }
      },
      searchTotal: 0,
      buildingData: [],
      addVisible: false,
      title: '',
      addForm: {
        buildingTypeCode: '', // 楼栋类型
        buildingName: '',
        busProjectUuid: '',
        buildingUuid: '',
        buildingSort: null,
        buildingFormatTypeCode: null
      },
      type: null,
      addRules: {
        buildingName: [
          { required: true, message: '必填项不能为空', trigger: 'blur' },
          { max: 20, message: '最多20个字符', trigger: 'blur' }
        ],
        buildingSort: [
          { required: true, message: '必填项不能为空', trigger: 'blur' },
          { validator: validateSort, trigger: 'blur' }
        ],
        buildingTypeCode: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ]
      },
      TypeCode: {
        20242041: '洋房',
        20242042: '小高层',
        20242043: '高层',
        20242044: '别墅',
        20242045: '商住',
        20242046: '其他'
      },
      TypeCodeList: [
        { value: 20242041, label: '洋房' },
        { value: 20242042, label: '小高层' },
        { value: 20242043, label: '高层' },
        { value: 20242044, label: '别墅' },
        { value: 20242045, label: '商住' },
        { value: 20242046, label: '其他' }
      ],
      buildTypeCode: {
        1005501: '公区',
        1005502: '住宅',
        1005503: '车位',
        1005504: '储藏间',
        1005505: '资源'
      },
      buildingTypeCodeList: [
        { value: 1005502, label: '住宅' },
        { value: 1005503, label: '车位' },
        { value: 1005504, label: '储藏间' },
        { value: 1005505, label: '资源' },
        { value: 1005501, label: '公区' },
        { value: 1005506, label: '商铺' }
      ]

    }
  },
  mounted() {
    // this.getList()
  },

  methods: {
    // 获取列表
    getList(state = '') {
      if (!this.searchParam.busProjectUuid) return
      if (state !== 'page') {
        this.searchParam.page.pageNum = 1
        this.searchParam.page.pageSize = 10
      }
      const load = this.$load()
      getBuildingList(this.searchParam).then(res => {
        this.buildingData = res.data.list
        this.searchTotal = res.data.page.total
        load.close()
      }).catch(e => {
        load.close()
      })
    },
    goback() {
      this.$router.replace('/administration')
    },
    getAdd() {
      this.type = 1
      this.title = '新增楼栋'
      this.addForm.busProjectUuid = this.searchParam.busProjectUuid
      this.addVisible = true
      console.log(this.addForm)
    },
    // 保存
    getSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const load = this.$load()
          if (this.type === 1) {
            addBuilding(this.addForm).then(res => {
              this.$message.success('新增成功')
              this.addVisible = false
              load.close()
              this.getList()
            }).catch(e => {
              this.$errorHandle(e)
              load.close()
            })
          } else {
            editBuilding(this.addForm).then(res => {
              this.$message.success('编辑成功')
              this.addVisible = false
              load.close()
              this.getList()
            }).catch(e => {
              this.$errorHandle(e)
              load.close()
            })
          }
        }
      })
    },
    // 编辑
    getEdit(row) {
      this.title = '编辑楼栋'
      this.addVisible = true
      this.$nextTick(() => {
        this.addForm = {
          busProjectUuid: this.searchParam.busProjectUuid,
          buildingName: row.buildingName,
          buildingUuid: row.buildingUuid,
          buildingSort: row.buildingSort,
          buildingFormatTypeCode: row.buildingFormatTypeCode,
          buildingTypeCode: row.buildingTypeCode
        }
      })
      this.type = 2
    },
    getDel(row) {
      this.$confirm(`是否删除：${row.buildingName}?`, '提示', {
        type: 'warning'
      })
        .then(() => {
          delBuilding({ buildingUuid: row.buildingUuid }).then(res => {
            this.$message.success('删除成功')
            this.getList()
          }).catch(e => {
            this.$errorHandle(e)
          })
        }).catch(() => {

        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
