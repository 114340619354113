<template>
  <div class="login_tel">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <el-form-item prop="userMobile">
        <div class="logintel">
          <el-input
            ref="userMobile"
            v-model.trim="loginForm.userMobile"
            placeholder="请输入您注册的手机号"
            name="userMobile"
            type="text"
            tabindex="1"
            autocomplete="off"
            clearable
            maxlength="50"
          />
        </div>
      </el-form-item>

      <el-tooltip
        v-model="capsTooltip"
        content="大写已开启"
        placement="right"
        manual
      >
        <el-form-item prop="password">
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            autocomplete="new-password"
            maxlength="50"
            :clearable="false"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <span
            class="show-pwd"
            @click="showPwd"
          >
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span>
        </el-form-item>
      </el-tooltip>
      <div v-show="msgcodes == 3020" style="margin: 10px 0">
        <el-form-item prop="code">
          <el-input
            ref="password"
            v-model.trim="loginForm.code"
            placeholder="请输入验证码"
            name="password"
            tabindex="2"
            maxlength="4"
            autocomplete="off"
            :clearable="false"
            @keyup.enter.native="handleLogin"
          >
            <template slot="suffix">
              <img
                :src="imgCode"
                width="110"
                height="42"
                style="cursor: pointer;vertical-align: bottom;background: rgba(228, 232, 235, 0.5);border-radius: 4px;"
                alt=""
                @click="getLoginValiteImg"
              >
            </template>
          </el-input>
        </el-form-item>
      </div>
      <div class="shadow">
        <el-button
          :loading="loading"
          type="primary"
          :disabled="disabled"
          style="width:100%;margin-top: 10px;"
          @click.native.prevent="handleLogin"
        >
          登录
        </el-button>
      </div>

      <!--      <div class="bottom_info" style="text-align: right;margin-top: 20px;">-->
      <!--        <el-button type="text" @click="$router.push('/findPassWord')">忘记密码</el-button> <span style="color: #1890ff">| </span>-->
      <!--        <el-button type="text" @click="$router.push('/register')">用户注册</el-button>-->
      <!--      </div>-->
    </el-form>
    <div
      class="zhice"
      @click="register"
    >
      <span>立即注册</span><span class="goicon" />
    </div>
    <!--    <div class="ctop">-->
    <!--      <el-checkbox v-model="defaultFlag">七天免登陆</el-checkbox>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { identify_code, intoEnterprise, login, getPublicKey } from '@/api/user'
import store from '@/store'
import JSEncrypt from 'jsencrypt'
import { deepClone } from '@/utils'
// import { deepClone } from 'qifuyun-pc/lib/utils/utils'
export default {
  name: 'LoginTel',
  props: {
    publicKey: {
      type: String,
      default: ''
    },
    msgcode: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入您注册的手机号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码不小于6位数'))
      } else {
        callback()
      }
    }
    return {
      // defaultFlag: true,
      msgcodes: '',
      loginForm: {
        userMobile: '',
        password: '',
        code: '',
        imgUuid: ''
      },
      loginRules: {
        userMobile: [{ required: true, trigger: 'change', validator: validateUsername }],
        password: [{ required: true, trigger: 'change', validator: validatePassword }],
        code: [{ required: false, trigger: 'change', message: '请输入验证码' }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      otherQuery: {},
      imgCode: null, // 验证码
      encryptor: null
    }
  },
  computed: {
    disabled() {
      if (this.msgcodes == 3020) {
        return !(this.loginForm.userMobile && this.loginForm.password && this.loginForm.code)
      } else {
        return !(this.loginForm.userMobile && this.loginForm.password)
      }
    }
  },
  watch: {
    msgcode: {
      handler(val) {
        this.msgcodes = val
      },
      immediate: true
    }
    // defaultFlag: {
    //   handler(val) {
    //     sessionStorage.setItem('setNoLogin', val)
    //   },
    //   immediate: true
    // }
  },
  created() {
    // this.msgcodes = this.msgcode
    if (process.env.NODE_ENV === 'development') {
      this.loginForm.userMobile = '13716519874'
      this.loginForm.password = '123456'
    }
  },
  mounted() {
    if (this.loginForm.userMobile === '') {
      this.$refs.userMobile.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
    // 获取验证码
    this.getLoginValiteImg()
    // 获取公钥
    // this.getPublicKey()
    // this.msgcodes = this.msgcode
  },
  methods: {
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 注册
    register() {
      this.$emit('register')
    },
    // // 获取公钥
    // getPublicKey() {
    //   getPublicKey({}).then(res => {
    //     this.publicKey = res.data
    //     this.msgcode = res.code
    //   }
    //   ).finally(d => {
    //   }).catch(e => {
    //     this.msgcode = e.code
    //   })
    // },

    /**
     * 获取验证码
     */
    getLoginValiteImg() {
      identify_code().then(res => {
        const { imgCode, imgUuid } = res.data
        this.imgCode = imgCode
        this.loginForm.imgUuid = imgUuid
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const loginForm = deepClone(this.loginForm)
          if (this.publicKey && this.publicKey != '') {
            this.encryptor = new JSEncrypt()
            this.encryptor.setPublicKey('-----BEGIN PUBLIC KEY-----' + this.publicKey + '-----END PUBLIC KEY-----')
            loginForm.userMobile = this.encryptor.encrypt(this.loginForm.userMobile)
            loginForm.password = this.encryptor.encrypt(this.loginForm.password)
          } else {
            this.$message.error('获取验证失败，请刷新页面重试')
            return false
          }

          this.loading = true

          this.$store.dispatch('user/login', { userInfo: loginForm, type: 'pasw', method: login })
            .then(() => {
              this.loading = false
              this.$store.dispatch('user/getStatus').then(res => {
                if (!res.accelerated) { // 未完善个人信息
                  this.$emit('perfectInfo')
                  return
                }
                if (!res.enterpriseDefaultFlag) { // 未选择默认企业
                  this.$emit('choseEnterprise')
                  return
                }
                const load = this.$load()
                intoEnterprise({
                  enterpriseUuid: res.defaultEnterpriseUuid,
                  defaultFlag: 1
                }).then(res => {
                  this.$router.push({ path: this.redirect || '/im', query: this.otherQuery })
                }).catch(_ => {
                  this.$store.dispatch('user/resetToken')
                }).finally(_ => {
                  load.close()
                })
              })
            })
            .catch(e => {
              if (e.code == 3020) {
                this.msgcodes = e.code
                this.$emit('changemsgcode', e.code)
              }
              this.getLoginValiteImg()
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style scoped lang="scss">
$bg:#fff;
$dark_gray:#889aa4;
$light_gray:#000;
::v-deep .el-input__inner {
  height: 50px;
  line-height: 50px;
}
::v-deep .el-input__suffix-inner {
  display: inline-block;
  margin: 4px 0;
}
::v-deep .el-button--primary {
  height: 46px;
  background: linear-gradient(45deg, #4069F4 0%, #5F86F8 100%);
  border-radius: 8px;
  &:hover {
    opacity: 0.9;
  }
  &.is-disabled{
    opacity: 0.5;
  }
}
.show-pwd {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
.shadow{
  ::v-deep .el-button{
    box-shadow: 0px 5px 10px 2px #B1D2FF;
    border-radius: 6px;
  }
}
::v-deep .el-form-item{
  margin-bottom: 20px;
}
.zhice{
  cursor: pointer;
  margin: 21px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3D80FC;
  font-size: 14px;
  height: 16px;
  .goicon{
    margin-left: 1px;
    width: 16px;
    height: 16px;
    background: url("~@/assets/login/next.png") no-repeat 0 0 / 16px 16px;
  }
}
.ctop {
  margin-top:20px;
  ::v-deep .el-checkbox__inner {
    border-radius: 50% !important;
  }
  ::v-deep .el-checkbox__input.is-checked {
    border-color: #3D80FC !important;
  }
}
</style>
