<template>
  <span>
    <span
      v-for="(item,idx) in 5"
      :key="idx"
      class="star"
    >
      <i
        v-if="list[idx]"
        :style="{
          width:list[idx] * 100+'%'
        }"
      />
    </span>
  </span>
</template>

<script>
export default {
  name: 'UmStar',
  props: {
    star: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      list: []
    }
  },
  watch: {
    star: {
      handler(val) {
        this.list = []
        this.deepStar(val / 2)
      },
      immediate: true
    }
  },
  methods: {
    deepStar(val) {
      if (val > 0) {
        this.list.push(val > 1 ? 1 : val)
        val--
        this.deepStar(val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.star{
  position: relative;
  width: 22px;
  height: 21px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  overflow: hidden;
  background: url("~@/assets/icon/star_off.png") 0 0 no-repeat;
  background-size: 22px 21px;
  >i{
    display: inline-block;
    width: 100%;
    height: 100%;
    background: url("~@/assets/icon/star_on.png") 0 0 no-repeat;
    background-size: 22px 21px;
  }
}
</style>
