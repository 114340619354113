<template>
  <div class="m_enterprise_application">
    <div class="m_application_formbody">
      <el-scrollbar style="height: 90%">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm" label-position="left">
          <div class="m_application_yingyongquanxian">
            <div class="m_application_checkbox">
              <el-checkbox v-model="checkAllFlag" :disabled="checkAllReadOnly?true:false">全部 <span style="color: #909DB6">(选择全部后，新添加应用也将自动授予管理权限)</span></el-checkbox>
              <el-checkbox-group
                v-model="checkeds"
              >
                <el-checkbox v-for="item in appList" :key="item.appUuid" :label="item.appUuid" :disabled="item.readOnly?true:false||checkAllFlag">{{ item.appName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div v-permission="['112030201109']" class="m_application_bottombtn">
            <div class="m_application_btn">
              <el-button type="primary" @click="submitForm">保存</el-button>
            </div>
          </div>
        </el-form>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import {
  get_user_app_manage_auth,
  update_user_app_manage_auth
} from '@/api/manage.js'
export default {
  name: 'Application',
  components: {},
  props: {
    orgUserUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ruleForm: {},
      rules: {},
      checkAllFlag: false,
      checkAllReadOnly: 0,
      checkeds: [], // 选择的应用权限
      appList: []// 应用权限列表
    }
  },
  computed: {},
  watch: {},
  created() {
    this.gettree()
  },
  mounted() {},
  methods: {
    gettree() {
      const load = this.$load()
      get_user_app_manage_auth({ orgUserUuid: this.orgUserUuid }).then(d => {
        console.log(d)
        this.appList = d.data.appList
        const flag = d.data.checkAllFlag
        this.checkAllReadOnly = d.data.checkAllReadOnly
        // if (flag == 1) {
        //   this.checkAllFlag = true
        // } else {
        //   this.checkAllFlag = false
        // }
        this.checkAllFlag = flag
        const arr = []
        d.data.appList.forEach(item => {
          if (item.checked == 1) {
            arr.push(item.appUuid)
          }
        })
        this.checkeds = arr
      }).finally(_ => {
        load.close()
      }).catch(e => {
        this.$errorHandle(e)
        load.close()
      })
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const load = this.$load()
          update_user_app_manage_auth({
            orgUserUuid: this.orgUserUuid,
            checkAllFlag: this.checkAllFlag ? 1 : 0,
            appUuidList: this.checkeds
          }).then(d => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          }).finally(_ => {
            load.close()
          }).catch(e => {
            this.$errorHandle(e)
            load.close()
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
