<template>
  <div class="m_contractor_person">
    <div class="m_contractor_persontree">
      <div class="u_persontree_title">
        <span>组织架构</span>
      </div>
      <div class="u_persontree_tree">
        <el-scrollbar style="height: 100%">
          <el-tree
            ref="tree"
            :data="data"
            :default-expanded-keys="defaultExpandedKeys"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            :load="loadNode"
            :props="defaultProps"
            class="filter-tree"
            highlight-current
            node-key="organUuid"
            @current-change="currentTreeChange"
          >
            <span slot-scope="{ node, data }" class="u_persontree_treenode" :class="{m_node_disabled:data.readOnlyFlag==1}">
              <div class="u_treenode_label ">
                <div class="u_treenode_labelname" :class="{u_treenode_color1:node.level==1}" @mouseover="onMouseOver($event)">
                  <UmToolTip :content="data.organName" class="row_1" />
                </div>
                <div class="u_treenode_item">
                  <span class="u_treenode_counts" :class="{u_treenode_color1:node.level==1}">({{ data.personCount || 0 }})</span>
                  <div v-if="data.organTypeCode==1001502" class="u_treenode_tag">公司</div>
                  <div v-if="data.organTypeCode==1001503" class="u_treenode_tag">部门</div>
                  <div v-if="data.organTypeCode==1001504" class="u_treenode_tag">岗位</div>
                </div>
              </div>

              <span v-if="node.isCurrent" class="m_org_tree_dropdow_more flex-center" @click.stop>
                <el-popover
                  popper-class="m_enterpris_popover"
                  placement="right-start"
                  width="112"
                  :visible-arrow="false"
                  trigger="click"
                >
                  <svg-icon slot="reference" icon-class="ellipsis" class-name="custom-class" @click="chose(data)" />
                  <div>
                    <div class="m_enterpris_popover_edit-wrap">
                      <div v-permission="['112040907']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => addsondepartment( data)">
                        <span class="m_enterpris_popover_add-icon" />
                        <span>新建子部门</span>
                      </div>
                      <div v-permission="['112040908']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => editdepartment(data)">
                        <span class="m_enterpris_popover_edit-icon" />
                        <span>编辑</span>
                      </div>
                      <div v-permission="['112040909']" class="m_enterpris_popover_edit-wrap_item" @click.stop="() => deletedepartment(node, data)">
                        <span class="m_enterpris_popover_del-icon" />
                        <span>删除</span>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </span>
            </span>
          </el-tree>
        </el-scrollbar>
      </div>
    </div>
    <div class="m_contractor_table">
      <div class="m_contractor_table_rcontent">
        <div class="u_table_rcontent_rtitle">{{ toptitle }}</div>
        <div class="u_table_rcontent_rbutton">
          <el-input
            v-model="keyword"
            placeholder="用户名/手机号"
            clearable
            prefix-icon="el-icon-search"
            style="margin-right: 10px;"
          />
          <el-button v-permission="['112040910']" type="primary" @click="addnewpeople">新 增</el-button>
          <el-button type="primary" icon="el-icon-search" @click="getList">搜 索</el-button>
        </div>
        <div class="mar_top20">
          <el-table
            :data="tableData"
            stripe
            height="calc(100vh - 445px)"
            style="width: 100%;"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="75"
            />
            <el-table-column
              label="序号"
              prop="xh"
              width="70"
            />
            <el-table-column
              label="姓名"
              prop="userName"
            >
              <template slot-scope="scope">
                <div style="display: flex;align-items: center">
                  <UmToolTip :content="scope.row.userName" class="row_2" />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="职务"
              prop="userPosition"
            >
              <template slot-scope="scope">
                <span class="row_2">{{ scope.row.userPosition||'-' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="部门"
              prop="userDepartment"
              min-width="180"
            >
              <template slot-scope="scope">
                <span><UmToolTip :content="scope.row.userDepartment" class="row_2" /></span>
              </template>
            </el-table-column>
            <el-table-column
              label="手机号"
              prop="userMobile"
              width="150px"
            >
              <template slot-scope="scope">
                <span><span><UmToolTip :content="scope.row.userMobile" class="row_1" /></span></span>
              </template>}
            </el-table-column>
            <el-table-column
              label="操作"
              prop="userPosition"
              width="170px"
            >
              <template slot-scope="scope">
                <el-button v-permission="['112040913']" type="text" class="u_color_bianji" @click="edititem(scope.row)">编辑</el-button>
                <el-button v-permission="['112040911']" type="text" class="u_color_shanchu" @click="delitem(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="u_table_rcontent_paginations">
          <pagination
            :background="false"
            :layout="'total, sizes, prev, pager, next, jumper'"
            :limit.sync="page.pageSize"
            :page.sync="page.pageNum"
            :total="tableTotal"
            @pagination="getList('page')"
          />
        </div>
      </div>
    </div>
    <div class="m_contractor_dialog">
      <el-dialog
        :title="daligotxt"
        :visible.sync="addVisible"
        center
        :append-to-body="false"
        width="522px"
        @close="reset"
      >
        <span v-show="addoredit==1">
          <el-form ref="addForm" :model="addForm" label-position="left" :rules="addrules" class="demo-ruleForm" label-width="100px" @submit.native.prevent>
            <el-form-item label="属性" prop="organTypeCode">
              <el-radio-group v-model="addForm.organTypeCode">
                <el-radio v-if="organTypeCode == 1001501 || organTypeCode == 1001502" label="1001502">公司</el-radio>
                <el-radio v-if="organTypeCode == 1001501 || organTypeCode == 1001502 || organTypeCode == 1001503" label="1001503">部门</el-radio>
                <el-radio v-if="organTypeCode == 1001501 || organTypeCode == 1001502 || organTypeCode == 1001503 || organTypeCode == 1001504" label="1001504">岗位</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="组织名称" prop="organName">
              <el-input v-model="addForm.organName" clearable placeholder="请输入组织名称" maxlength="30" />
            </el-form-item>
          </el-form>
        </span>
        <span v-show="addoredit==2">
          <el-form ref="editForm" :model="editForm" :rules="editrules" class="demo-ruleForm" label-width="110px" label-position="left" @submit.native.prevent>
            <el-form-item label="组织名称" prop="organName">
              <el-input v-model="editForm.organName" :disabled="editDisible" clearable placeholder="请输入组织名称" maxlength="30" />
            </el-form-item>
            <el-form-item label="部门负责人" prop="departmentHeadUuidList" style="line-height: 32px;height: 32px">
              <span style="display: flex;align-items: center;line-height: 1">
                <UmBusUser
                  ref="UmBusUser"
                  v-model="editForm.departmentHeadUuidList"
                  :show-prefix-button="false"
                  :type="2"
                  :contractor-uuid="contractorUuid"
                  :scope="3"
                  title="选择部门负责人"
                >
                  <template #user="{data}">
                    <span class="m_contractor_persons row_one flex-center">
                      <span v-if="data&&data.length">
                        {{ data.map(i=>i.name).join('/') }}
                      </span>
                      <span v-else>未设置</span>
                    </span>
                  </template>
                </UmBusUser>
                <span class="m_contractor_shezhi" @click="editperson">设置</span>
              </span>
            </el-form-item>
          </el-form>
        </span>
        <span v-if="addoredit==1" class="dialog-footer flex-center mt30">
          <span class="m_contractor_quxiao"><el-button @click="quitadd">取 消</el-button></span>
          <span class="m_contractor_queren"><el-button type="primary" @click="sureadd">确 定</el-button></span>
        </span>
        <span v-else class="dialog-footer flex-center mt30">
          <span class="m_contractor_quxiao"><el-button @click="quitedit">取 消</el-button></span>
          <span class="m_contractor_queren"><el-button type="primary" @click="sureedit">确 定</el-button></span>
        </span>
      </el-dialog>
      <memberadd
        ref="memberadd"
        :org-user-uuid="orgUserUuid"
        :addtype="addtype"
        :contractor-uuid="contractorUuid"
        :addpeopletext="addpeopletext"
        :invitation.sync="invitation"
        @changevisible="changevisible"
      />
    </div>
  </div>
</template>

<script>
import {
  get_organ_tree,
  get_organ_user_page,
  add_organ,
  delete_organ,
  update_organ,
  get_organ_update_info,
  delete_org_user, get_org_role_by_org_business_id
} from '@/api/enterprise'
import memberadd from '@/views/mainData/contractor/info/components/add'
export default {
  name: 'Membermanagement',
  components: { memberadd },
  props: {
    contractorUuid: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写手机号'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      orgUserUuid: '',
      addtype: 1, // 添加1 详情2
      classifyList: [], // 角色列表
      keyword: '',
      organUuid: '', // 当前的组织
      chosenode: {},
      data: [],
      dataclone: [],
      defaultExpandedKeys: [], // 默认展开数组
      tableTotal: 0,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      toptitle: '-',
      defaultProps: {
        children: 'children',
        label: 'organFullName',
        isLeaf: 'leafFlag'
      },
      tableData: [],
      multipleSelection: [], // 选择的集合
      addVisible: false, // 创建组织
      addForm: {
        parentOrganUuid: '',
        organName: '',
        organTypeCode: '1001503'
      },
      addrules: {
        organName: [
          { required: true, message: '请输入组织名称', trigger: 'change' }
        ],
        organTypeCode: [
          { required: true, message: '请选择公司属性', trigger: 'change' }
        ]
      },
      editForm: {
        organUuid: '',
        organName: '',
        departmentHeadUuidList: []
      },
      editrules: {
        organName: [
          { required: true, message: '请输入组织名称', trigger: 'change' }
        ]
      },
      addoredit: 1, // 1 添加 2 编辑
      organTypeCode: '',
      daligotxt: '创建组织',
      addpeopletext: '新增用户',
      invitation: false,
      // busContractorId: '',
      isDisabledTooltip: true, // 是否禁止悬浮框显示
      editDisible: false // 和后端保持一致，一级企业无法在此修改组织名称
    }
  },
  watch: {},
  created() {
    this.getTree()
    this.get_classify_tree()
  },
  activated() {},
  mounted() {},
  methods: {
    // 获取角色树
    get_classify_tree() {
      get_org_role_by_org_business_id().then(res => {
        this.classifyList = res.data.list
      })
    },
    onMouseOver(event) {
      const ev = event.target
      const ev_weight = ev.scrollWidth // 文本的实际宽度
      // 判断是否禁用tooltip功能
      this.isDisabledTooltip = ev_weight < 196
    },
    // 筛选树
    filterNode(value, data) {
      //
      if (!value) return true
      return data.organFullName.indexOf(value) !== -1
    },
    reset() {
      this.editDisible = false
      if (this.addoredit == 1) {
        this.$refs.addForm.resetFields()
      } else {
        this.$refs.editForm.resetFields()
      }
    },
    getList(state) {
      this.tableData = []
      if (this.organUuid) {
        const load = this.$load()
        get_organ_user_page({
          page: this.page,
          keyword: this.keyword,
          organUuid: this.organUuid
        }).then(res => {
          this.tableTotal = res.data.page.total
          this.tableData = res.data.list
        }).catch(e => {
          this.$errorHandle(e)
        }).finally(_ => {
          load.close()
        })
      }
    },
    getTree(row) {
      get_organ_tree({
        stepFlag: 0,
        parentUuid: '',
        contractorUuid: this.contractorUuid
      }).then(d => {
        this.data = d.data
        this.dataclone = d.data
        this.defaultExpandedKeys = this.data.map(item => item.organUuid) // 默认展开第一级
        // 获取有权限的查看列表
        const currentData = this.findPermissionNode(this.data)
        if (currentData) {
          // this.currentData = currentData
          // this.$emit('currentChange', this.currentData)
          this.organUuid = currentData.organUuid
          this.toptitle = currentData.organName
          this.getList()
          this.$nextTick(_ => {
            this.$refs.tree.setCurrentNode(currentData)
          })
        }
        // if (this.defaultExpandedKeys) {
        //   this.organUuid = this.dataclone[0].organUuid
        //   this.toptitle = this.dataclone[0].organName
        //   this.getList()
        // }
        // this.$nextTick(() => {
        //   if (row) {
        //     this.$refs.tree.setCurrentNode(row)
        //   } else {
        //     this.$refs.tree.setCurrentNode(d.data[0])
        //   }
        // })
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    findPermissionNode(tree) {
      let node = null
      function deep(arr) {
        try {
          arr.forEach(item => {
            if (item.readOnlyFlag != 1) {
              node = item
              throw new Error()
            }
            deep(item.children)
          })
        } catch (e) {

        }
      }
      deep(tree)
      return node
    },
    loadNode(node, resolve) {
      get_organ_tree({
        stepFlag: 0,
        parentUuid: ''
      }).then(res => {
        const data = res.data
        data.forEach(item => {
          item.leaf = Boolean(item.childFlag == 0)
        })
        resolve(data)
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    currentTreeChange(data, checked, indeterminate) {
      this.toptitle = data.organFullName || ''
      this.organUuid = data.organUuid
      // this.getList()
      if (data.readOnlyFlag != 1) {
        this.getList()
      }
      if (data.readOnlyFlag == 1) {
        this.tableData = []
        this.tableTotal = 0
      }
    },
    chose(val) {
      this.chosenode = val

      this.organUuid = val.organUuid
      this.addForm.parentOrganUuid = val.organUuid
    },
    // 新建子部门
    addsondepartment(val) {
      this.daligotxt = '创建组织'
      this.addoredit = 1
      this.organTypeCode = val.organTypeCode
      this.addForm.organTypeCode = val.organTypeCode + ''
      this.addVisible = true
    },
    // 确认添加子部门
    sureadd() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          add_organ({ ...this.addForm }).then(d => {
            // this.getTree()
            // if (this.addForm.organTypeCode == 1001502) {
            const data = d.data
            if (!data.children) {
              this.$set(data, 'children', [])
            }
            this.chosenode.children.push(data)

            // this.chosenode.children.push(data)
            // }
          }).finally(_ => {
            this.addVisible = false
          }).catch(e => {
            this.$errorHandle(e)
          })
        } else {
          return false
        }
      })
    },
    // 取消添加子部门
    quitadd() {
      this.addVisible = false
    },
    // 编辑
    editdepartment(val) {
      this.editForm.orangename = val.organFullName
      this.daligotxt = '编辑组织'
      this.addVisible = true
      this.addoredit = 2

      get_organ_update_info({
        organUuid: this.organUuid
      }).then(d => {
        this.editForm.organUuid = d.data.organUuid
        this.editForm.organName = d.data.organName
        this.editForm.departmentHeadUuidList = d.data.departmentHeadUuidList
        this.editDisible = Boolean(d.data.organParentId == 0)
      }).finally(_ => {
        this.organUuid = ''
      })
    },
    // 确认编辑
    sureedit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          update_organ({ ...this.editForm }).then(d => {
            Object.assign(this.chosenode, d.data)
            // this.getTree()
            this.$message({
              type: 'success',
              message: '保存成功!'
            })
          }).finally(_ => {
            this.addVisible = false
          })
        } else {
          return false
        }
      })
    },
    // 取消编辑
    quitedit() {
      this.addVisible = false
    },
    // 左侧树删除
    deletedepartment(node, data) {
      this.$confirm(` ${data.organFullName} `, '是否确认删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
        center: true
      }).then(() => {
        delete_organ({
          organUuid: this.organUuid
        }).then(d => {
          // this.getTree()
          const parent = node.parent
          const children = parent.data.children || parent.data
          const index = children.findIndex(d => d.organUuid === data.organUuid)
          children.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }).finally(_ => {
          this.addVisible = false
          this.organUuid = ''
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        })
      })
    },
    // 单个编辑
    edititem(item) {
      console.log(item)
      this.addpeopletext = '编辑用户'
      this.invitation = true
      this.addtype = 2
      this.orgUserUuid = item.userUuid
      this.$nextTick(_ => {
        this.$refs.memberadd.getuserinfo()
      })
    },
    // 单个删除
    delitem(item) {
      console.log(item)
      this.$confirm(` ${item.userName} `, '是否确认删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        delete_org_user({
          orgUserUuidList: [item.userUuid]
        }).then(d => {
          // this.getList()
          this.getTree()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }).finally(_ => {
        })
        // this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        })
      })
    },
    // 新增
    addnewpeople() {
      this.orgUserUuid = ''
      this.addpeopletext = '新增用户'
      this.invitation = true
      this.addtype = 1
    },
    // 设置部门负责人
    editperson() {
      // this.$refs.UmBusUser.visible = true
      this.$refs.UmBusUser.openModal()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    changevisible(val) {
      if (!val) {
        this.invitation = false
      } else {
        this.invitation = true
      }
      this.getTree()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
