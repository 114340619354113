<template>
  <div class="g-administrationRouter">
    <div class="m-content">
      <div class="m_content_info">
        <div class="info-leftBox">
          <div class="info-leftBox_img">
            <el-image
              style="width: 100%; height: 100%; border-radius: 3px"
              :src="avatar"
              fit="fit"
            >
              <div slot="error" class="image-slot">
                <div class="img-nodata" />
              </div>
            </el-image>
          </div>
          <div class="info-leftBox_duty">
            <div class="duty-name row_1">{{ name || "-" }}，你好！</div>
            <div
              v-if="personinfo.adminFlag || personinfo.founderFlag"
              class="u_dutys row_1"
            >
              你是企业的 <span v-if="personinfo.adminFlag">管理者</span>
              <span
                v-if="personinfo.founderFlag"
              ><span v-if="personinfo.adminFlag">/</span>创建者</span>
            </div>
          </div>
          <div class="info-leftBox_num">
            <div class="num-numbers">
              <span class="numbers_digital">{{
                personinfo.orgUserNumber || "0"
              }}</span>
              <span class="numbers_title">企业人数</span>
            </div>
            <div class="num-separation" />
            <div class="num-numbers">
              <span class="numbers_digital">{{
                personinfo.orgOrganNumber || "0"
              }}</span>
              <span class="numbers_title">组织数</span>
            </div>
          </div>
        </div>
        <div class="info-rightBox">
          <div class="info-rightBox_step">
            <el-steps :active="4" align-center>
              <el-step title="下载优客云APP" icon="el-icon-edit">
                <template #icon>
                  <div class="step-icons">
                    1
                  </div>
                </template>
                <template #title>
                  <div class="step-text">下载优客云APP</div>
                </template>
                <template #description>
                  <div class="step-description">
                    <span class="stepstexts" @click="downapp">立即下载</span>
                  </div>
                </template>
              </el-step>
              <el-step title="完善通讯录" icon="el-icon-edit">
                <template #icon>
                  <div class="step-icons">
                    2
                  </div>
                </template>
                <template #title>
                  <div class="step-text">完善通讯录</div>
                </template>
                <template #description>
                  <div class="step-description">
                    <span
                      class="stepstexts"
                      @click="
                        () => {
                          $router.push('/enterprise/address');
                        }
                      "
                    >立即去完善</span>
                  </div>
                </template>
              </el-step>
              <el-step title="邀请用户加入" icon="el-icon-edit">
                <template #icon>
                  <div class="step-icons">
                    3
                  </div>
                </template>
                <template #title>
                  <div class="step-text">邀请用户加入</div>
                </template>
                <template #description>
                  <div class="step-description">
                    <span
                      class="stepstexts"
                      @click="invitations"
                    >立即邀请</span>
                  </div>
                </template>
              </el-step>
              <el-step title="使用企业应用" icon="el-icon-edit">
                <template #icon>
                  <div class="step-icons">
                    4
                  </div>
                </template>
                <template #title>
                  <div class="step-text">使用企业应用</div>
                </template>
                <template #description>
                  <div class="step-description">
                    <span
                      class="stepstexts"
                      @click="
                        () => {
                          $router.push('/cloudMarket');
                        }
                      "
                    >进入应用市场</span>
                  </div>
                </template>
              </el-step>
            </el-steps>
          </div>
        </div>
      </div>
    </div>
    <div v-if="infolist&&infolist.length">
      <div class="m_notification_infobody" @click="getjoinlist">
        <div class="m_notification_infotxt">
          <div class="m_notification_pic" />
          <div>有新用户申请加入企业，赶快去处理吧！</div>
        </div>
        <div class="m_notification_infobtn" />
      </div>
    </div>
    <div class="m-content">
      <div class="m_content_centop">
        <span class="m_content_topicon" />
        <span class="m_content_toptitle">基础管理</span>
      </div>
      <div class="m_content_data">
        <div
          v-for="(item, i) in basicList"
          :key="i"
          v-permission="[item.code]"
          class="data_item"
          @click="getJump(item)"
        >
          <div :class="`icon-${item.name}`" class="item-icon" />
          <div class="item-subTit">{{ item.subTit }}</div>
        </div>
      </div>
    </div>
    <div class="m-content">
      <div class="m_content_centop">
        <span class="m_content_topicon" />
        <span class="m_content_toptitle">数据管理</span>
      </div>
      <div class="m_content_data">
        <div
          v-for="(item, i) in dataList"
          :key="i"
          v-permission="[item.code]"
          class="data_item"
          @click="getJump(item)"
        >
          <div :class="`icon-${item.name}`" class="item-icon" />
          <div class="item-subTit">{{ item.subTit }}</div>
        </div>
      </div>
    </div>
    <ApplyList :invitationlist.sync="invitationlist" @changevisiblelist="changevisiblelist" />
    <Qrcode :invitation.sync="invitation" :title="title" :type="type" @changevisible="changevisible" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { dataList, basicList } from './menu.js'
import { get_front_page_data, get_pending_audit_user_list } from '@/api/enterprise'
import Qrcode from '../enterprise/address/components/qrcode.vue'
import ApplyList from '@/views/administration/applyList'
export default {
  name: 'Administration',
  components: { Qrcode, ApplyList },
  data() {
    return {
      dataList,
      basicList,
      infolist: [], // 左侧消息列表
      personinfo: {
        adminFlag: false, // 是否管理员
        founderFlag: false, // 是否创建者
        orgUserNumber: '',
        orgOrganNumber: ''
      },
      invitation: false,
      invitationlist: false,
      type: 1,
      title: '扫码快速加入企业'
    }
  },
  computed: {
    ...mapGetters([
      'permission_routes',
      'avatar',
      'name',
      'userPosition',
      'enterpriseUuid',
      'enterpriseName',
      'enterpriseLogoImg'
    ])
  },
  created() {
    this.get_front_page_datas()
    this.get_pending_audit_user_lists()
  },
  methods: {
    getJump(item) {
      this.$router.push({ path: item.url })
    },
    // 获取信息
    get_front_page_datas() {
      const load = this.$load()
      get_front_page_data()
        .then((d) => {
          this.personinfo = d.data
        })
        .finally((_) => {
          load.close()
        })
        .catch((e) => {
          load.close()
          this.$errorHandle(e)
        })
    },
    changevisible(val) {
      if (!val) {
        this.invitation = false
      } else {
        this.invitation = true
      }
    },
    changevisiblelist(val) {
      if (!val) {
        this.invitationlist = false
      } else {
        this.invitationlist = true
      }
      this.get_pending_audit_user_lists()
    },
    // 下载
    downapp() {
      this.type = 2
      this.title = '手机扫码下载APP'
      this.invitation = true
    },
    invitations() {
      this.type = 1
      this.title = '扫码快速加入企业'
      this.invitation = true
    },
    getjoinlist() {
      console.log('点击了获取列表')
      this.invitationlist = true
    },
    // 获取消息通知列表
    get_pending_audit_user_lists() {
      get_pending_audit_user_list().then(d => {
        this.infolist = d.data
      }).finally(_ => {

      }).catch(e => {
        this.$errorHandle(e)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.g-administrationRouter {
  width: 1260px;
  margin: auto;
  .m-content {
    width: 100%;
    background: #fff;
    box-shadow: 0px 3px 5px 1px rgba(229, 229, 229, 0.43);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    .m_content_info {
      display: flex;
      .info-leftBox {
        display: flex;
        align-items: center;
        .info-leftBox_img {
          width: 56px;
          height: 56px;
          border-radius: 3px;
          .img-nodata {
            width: 56px;
            height: 56px;
            border-radius: 3px;
            background: url("~@/assets/enterprise/peoplenodata.png") no-repeat
              center;
            background-size: 100% 100%;
          }
        }

        .info-leftBox_duty {
          margin-left: 14px;
          width: 210px;
          .duty-name {
            font-weight: 600;
            color: #31415f;
            font-size: 16px;
            margin-bottom: 16px;
          }
          .u_dutys {
            font-weight: 400;
            color: #909db6;
            font-size: 14px;
          }
        }
        .info-leftBox_num {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 270px;
          height: 76px;
          background: #f5f7fa;
          border-radius: 6px;
          padding: 12px 0;
          margin-right: 30px;
          .num-numbers {
            display: flex;
            flex-direction: column;
            align-items: center;
            .numbers_digital {
              text-align: center;
              color: #31415f;
              font-size: 28px;
              font-weight: bold;
            }
            .numbers_title {
              font-size: 14px;
              color: #69748a;
              margin-top: 10px;
            }
          }
          .num-separation {
            width: 1px;
            height: 20px;
            background: #9ca8c3;
          }
        }
      }
      .info-rightBox {
        flex: 1;
        .info-rightBox_step {
          margin-top: 20px;
          .step-icons {
            width: 30px;
            height: 30px;
            background: #4c64fe;
            border-radius: 50%;
            color: #fff;
            line-height: 30px;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
          }
          .step-text {
            margin: 12px 0;
            font-weight: 600;
            color: #31415f;
            height: 14px;
            font-size: 14px;
            line-height: 14px;
          }
          .step-description {
            margin-top: 13px;
            height: 20px;
            .stepstexts {
              cursor: pointer;
              width: 90px;
              height: 20px;
              display: inline-block;
              border-radius: 4px;
              border: 1px solid #4c64fe;
              font-size: 12px;
              text-align: center;
              color: #4C64FE;
              line-height: 18px;
            }
          }
          ::v-deep .el-step__line{
            height: 8px;
              background: url("~@/assets/images/administration/point_bg.png") no-repeat 50% 50% / 38px 8px;
            .el-step__line-inner{
              display: none;
            }

          }
        }
      }
    }
    .m_content_centop {
      display: flex;
      align-items: center;
      .m_content_topicon {
        width: 14px;
        height: 14px;
        background: url("~@/assets/enterprise/icon-dh.png") no-repeat 0 0 / 14px
          14px;
      }
      .m_content_toptitle {
        font-weight: 600;
        font-size: 16px;
        color: #31415f;
      }
    }
    .m_content_data {
      display: flex;
      margin-top: 20px;
      // padding: 0 14px;
      .data_item {
        font-size: 14px;
        color: #31415f;
        width: 86px;
        text-align: center;
        margin-right: 58px;
        cursor: pointer;
        &:first-child {
          margin-left: -6px;
        }
        .item-icon {
          width: 58px;
          height: 58px;
          margin-left: 14px;
          margin-bottom: 12px;
          $list: dataProject, dataBuilding, dataTender, dataDoorModel,
            dataPosition, dataContractor, enterpriseAddress, enterpriseNotice,
            enterpriseBasic, enterpriseRole;
          @each $item in $list {
            &.icon-#{$item} {
              background-image: url("~@/assets/images/administration/icon_#{$item}.png");
              background-size: 100% 100%;
            }
          }
        }
        .item-subTit {
          font-size: 14px;
        }
      }
    }
  }
  .m_notification_infobody {
    cursor: pointer;
    margin: 19px 0;
    display: flex;
    align-items: center;
    .m_notification_infotxt {
      display: flex;
      align-items: center;
      color: rgba(253, 80, 80, 1);
      font-size: 14px;
      .m_notification_pic{
        margin-right: 8px;
        width: 20px;
        height: 20px;
        background-color: #00cc66;
        background: url("~@/assets/common/injoin_pic.png") no-repeat center / 100% 100%;
      }
    }
    .m_notification_infobtn {
      width: 16px;
      height: 16px;
      background: url("~@/assets/common/icon-go.png") no-repeat 0 0 / 16px 16px;
    }
  }
}
</style>
