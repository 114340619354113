import request from '@/utils/request'
// 热门搜索
export function getHotSearch(params) {
  return request({
    url: '/api/v1/application/cloud_market/hot_search',
    method: 'get',
    params
  })
}

// 搜索页面
export function getSearch(data) {
  return request({
    url: '/api/v1/application/cloud_market/index/search',
    method: 'post',
    data
  })
}
// banner
export function getBanner(params) {
  return request({
    url: '/api/v1/operator/business/banner/detail_by_no',
    method: 'get',
    params
  })
}
// 首页 为你推荐-精选应用
export function getSelectedApplications(params) {
  return request({
    url: '/api/v1/application/cloud_market/index/get_hot_app',
    method: 'get',
    params
  })
}

// 首页-解决方案
export function getSolution(params) {
  return request({
    url: '/api/v1/application/cloud_market/index/get_hot_plan',
    method: 'get',
    params
  })
}
// 首页-精选服务
export function getSelectService(data) {
  return request({
    url: '/api/v1/application/cloud_market/index/get_hot_service',
    method: 'post',
    data
  })
}

// 首页-应用详情
export function getApplicationDetails(params) {
  return request({
    url: '/api/v1/application/cloud_market/index/pc_detail',
    method: 'get',
    params
  })
}

// 首页/硬件中心-智能硬件
export function selectedHardware(data) {
  return request({
    url: '/api/v1/business/app/work_table/cloud_market/intelligent_hardware/get_choiceness_hardware_list',
    method: 'post',
    data
  })
}
// 获取应用菜单分类
export function getApplicationMenu(params) {
  return request({
    url: '/api/v1/application/cloud_market/index/get_app_classify',
    method: 'get',
    params
  })
}
// 应用中心-精选应用
export function getApplicationCenter(params) {
  return request({
    url: '/api/v1/application/cloud_market/index/get_app',
    method: 'get',
    params
  })
}

// 应用中心 -获取应用分类
export function getApplicationClassification(data) {
  return request({
    url: '/api/v1/application/cloud_market/index/get_app_list',
    method: 'post',
    data
  })
}
// 应用中心-获取确认订单信息
export function getConfirmOrder(data) {
  return request({
    url: '/api/v1/application/cloud_market/index/pc_order',
    method: 'post',
    data
  })
}
// 应用详情-确认订单信息
export function confirmOrderInformation(data) {
  return request({
    url: '/api/v1/application/cloud_market/index/pc_order',
    method: 'post',
    data
  })
}
// 应用详情-提交订单
export function getPlaceOrder(data) {
  return request({
    url: '/api/v1/order/cloud_market/index/ordering',
    method: 'post',
    data
  })
}
// 解决方案-精选方案
export function getSelectedSolutions(params) {
  return request({
    url: '/api/v1/work_table/cloud_market/plan_center/get_hot_plan',
    method: 'get',
    params
  })
}
// 其他分类解决方案
export function getOtherSolutions(data) {
  return request({
    url: '/api/v1/application/cloud_market/index/get_plan_list',
    method: 'post',
    data
  })
}
// 解决方案详情
export function getSolutionsDetail(params) {
  return request({
    url: '/api/v1/work_table/cloud_market/plan_center/get_plan_detail',
    method: 'get',
    params
  })
}

// 解决方案-确认订单信息
export function getSolutionOrder(params) {
  return request({
    url: '/api/v1/application/cloud_market/plan/order',
    method: 'get',
    params
  })
}
// 解决方案-一键购买
export function getPurchaseSolutionr(data) {
  return request({
    url: '/api/v1/order/cloud_market/plan/ordered',
    method: 'post',
    data
  })
}

// 服务中心-精选服务
export function getSelectServices(data) {
  return request({
    url: '/api/v1/business/work_table/cloud_market/service_center/get_choiceness_list',
    method: 'post',
    data
  })
}

// 服务中心-其他服务
export function getOtherServices(data) {
  return request({
    url: '/api/v1/business/work_table/cloud_market/service_center/get_list',
    method: 'post',
    data
  })
}

// 服务中心详情
export function getServiceDetail(params) {
  return request({
    url: '/api/v1/business/work_table/cloud_market/service_center/get_info',
    method: 'get',
    params
  })
}

// 服务中心-提交服务订单
export function getSubmitOrder(data) {
  return request({
    url: '/api/v1/business/work_table/cloud_market/service_center/submit_service_order',
    method: 'post',
    data
  })
}

// 硬件-其他硬件
export function getOtherHardware(data) {
  return request({
    url: '/api/v1/business/app/work_table/cloud_market/intelligent_hardware/get_hardware_list',
    method: 'post',
    data
  })
}

// 硬件-硬件分类

export function getHardwareClassification(data) {
  return request({
    url: '/api/v1/operator/center/classify/list_primary_classify',
    method: 'post',
    data
  })
}

// 硬件-硬件详情
export function getHardwareDetail(params) {
  return request({
    url: '/api/v1/business/app/work_table/cloud_market/intelligent_hardware/get_hardware_detail',
    method: 'get',
    params
  })
}

// 硬件-硬件下单
export function getSubmitHardwareOrder(data) {
  return request({
    url: '/api/v1/order/hardware/place_order',
    method: 'post',
    data
  })
}

// 地址管理列表
export function getAddressManagement(params) {
  return request({
    url: '/api/v1/application/app/address/manage/getMyAddressList',
    method: 'get',
    params
  })
}

// 我的默认地址
export function getDefaultAddress(data) {
  return request({
    url: '/api/v1/application/app/address/manage/getMyDefaultAddress',
    method: 'post',
    data
  })
}

// 查询支付方式
export function getQueryPaymentMethod(data) {
  return request({
    url: '/api/pay/pay/queryPayChannel',
    method: 'post',
    data
  })
}

// 获取支付二维码
export function getPayQrCode(data) {
  return request({
    url: '/api/v1/order/p/cashierDesk/queryPayQrCode',
    method: 'post',
    data
  })
}

// 查询支付订单信息
export function getPayOrderInfo(data) {
  return request({
    url: '/api/v1/order/common/cashierDesk/queryPayOrderInfo',
    method: 'post',
    data

  })
}

// 产品详情
export function getCloudMarketInfo(params) {
  return request({
    url: '/api/v1/business/cloud_market/common/get_info',
    method: 'get',
    params
  })
}
