<script>
export default {
  name: 'RedirectLink',
  created() {
    const { query } = this.$route
    console.log(this.$route)
    // debugger
    const { url } = query
    const index = url.indexOf('=')
    const dataIndex = url.indexOf('dataType=')
    const dataType = url.substr(dataIndex + 9)
    const uuid = url.substring(index + 1, dataIndex - 1)
    if (url.indexOf('ykyapp.youmatech.com') !== -1) {
      // 内部网址 做下处理
      let path = ''
      const query = { uuid, dataType }
      if (url.indexOf('cloudMarket/info') !== -1) {
        path = '/cloudMarket/info'
        this.$router.replace({ path, query })
      } else {
        window.open(url)
      }
    } else {
      window.open(url)
    }
  },
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
