<template>
  <div class="m_enterprise_meetingroomadd detailsBox">
    <div class="go_back_common">
      <div class="go_back_title" @click="back">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="formBody">
      <div class="title-dh mb20 mt20">基本信息</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" label-width="90px">
        <el-form-item ref="roomImageUrl" label="会议室图片" prop="roomImageUrl">
          <div class="m_meetingroomadd_tips">
            <UmUploadImg v-model="ruleForm.roomImageUrl" :show-icon="false" custom-file :limit="1" :simple="true" is-cropper :cropper-options="cropperOptions">
              <template #extend>
                <div class="m_meetingroomadd_tips2">
                  <div class="m_meetingroomadd_imgicon" />
                </div>
              </template>
            </UmUploadImg>
          </div>
        </el-form-item>
        <el-form-item ref="roomName" label="会议室名称" prop="roomName">
          <el-input v-model="ruleForm.roomName" style="width:100%" placeholder="请填写名称" maxlength="20" />
        </el-form-item>
        <el-form-item ref="roomAddress" label="会议室地址" prop="roomAddress">
          <el-input v-model="ruleForm.roomAddress" style="width:100%" placeholder="请填写地址" maxlength="50" />
        </el-form-item>
        <el-form-item ref="publishScopeList" label="可见范围" prop="publishScopeList">
          <span class="m_meetingroomadd_parment">
            <UmBusUser ref="chosepart" v-model="ruleForm.publishScopeList" :type="3" search-tip="请输入" title="选择可见范围" :show-prefix-button="false">
              <template #user="{data}">
                <span class="m_meetingroomadd_itemcontent">
                  <div v-for="item in data" :key="item.id" class="m_meetingroomadd_texts">
                    {{ item.name }}
                    <div class="delicon" @click="delmain(item)" />
                  </div>
                  <span class="m_meetingroomadd_xiugai" @click="changpart">修改</span>
                </span>
              </template>
            </UmBusUser>
          </span>
        </el-form-item>
        <el-form-item ref="roomOpenDayList" label="开放时间" prop="roomOpenDayList">
          <el-checkbox-group v-model="ruleForm.roomOpenDayList" style="display: inline-block" @change="changeDate">
            <el-checkbox
              v-for="(it,ix) in date"
              :key="ix"
              :label="it.value"
            >
              {{ it.name }}
            </el-checkbox>
          </el-checkbox-group>
          <el-checkbox
            v-model="checkAll"
            :label="0"
            style="margin-left: 30px"
            @change="handleCheckAllChange"
          >
            全部
          </el-checkbox>
        </el-form-item>
        <el-form-item label="预定时间段" prop="roomOpenTimeList">
          <div class="m_meetingroomadd_timeselect">
            <el-time-select
              v-model="ruleForm.roomOpenStartTime"
              style="width:136px"
              placeholder="开始时间"
              value-format="HH:mm"
              format="HH:mm"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              @change="change"
            />

            至
            <el-time-select
              v-model="ruleForm.roomOpenEndTime"
              style="width:136px"
              placeholder="结束时间"
              value-format="HH:mm"
              format="HH:mm"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
            />
          </div>
        </el-form-item>
        <el-form-item ref="roomSiteEquipment" label="场所设备" prop="roomSiteEquipment">
          <div class="m_meetingroomadd_siteEquipment">
            <el-tag v-for="(item,i) in ruleForm.roomEquipmentList" :key="i" size="small" closable style="margin-bottom:5px;margin-right:10px;font-size:14px;background:#D8E6FE" @close="handleClose(i)">
              {{ item }}
            </el-tag>
            <el-input v-model.trim="ruleForm.roomSiteEquipment" style="width: 30%" placeholder="请填写场所设备，回车键确定" maxlength="20" :disabled="flag" :clearable="false" @keyup.enter.native="addList(ruleForm.roomSiteEquipment)" />
          </div>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="m_meetingroomadd_btnsure" @click="submit">确认保存</el-button>
    </div>
  </div>
</template>

<script>
import { getAddRoom, noticeQuery_room_info } from '@/api/enterprise'
import { log } from 'mathjs'
export default {
  name: 'EnterpriseApplicationMeetingAdd',
  data() {
    var checkTime = (rule, value, callback) => {
      const startTime = this.ruleForm.roomOpenStartTime
      const endTime = this.ruleForm.roomOpenEndTime
      if (startTime === null) {
        callback(new Error('请选择开始时间'))
      } else if (endTime === null) {
        callback(new Error('请选择结束时间'))
      } else if (startTime > endTime || startTime == endTime) {
        if (startTime !== '00:00' && endTime !== '00:00') {
          callback(new Error('结束时间不得早于或等于开始时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      list: [],
      date: [
        { name: '周一', value: 1 },
        { name: '周二', value: 2 },
        { name: '周三', value: 3 },
        { name: '周四', value: 4 },
        { name: '周五', value: 5 },
        { name: '周六', value: 6 },
        { name: '周日', value: 7 }
      ],
      checkAll: false,
      dateValue: [1, 2, 3, 4, 5, 6, 7],
      ruleForm: {
        roomImageUrl: '',
        roomName: '',
        roomAddress: '',
        roomOpenDayList: [],
        roomOpenStartTime: null,
        roomOpenEndTime: null,
        roomEquipmentList: [],
        roomSiteEquipment: '',
        roomOpenTimeList: [],
        publishScopeList: []
      },
      rules: {
        roomImageUrl: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
        roomName: [
          { required: true, message: '请填写名称', trigger: 'blur' }
        ],
        roomAddress: [
          { required: false, message: '请填写地址', trigger: 'change' }
        ],
        publishScopeList: [
          { required: true, message: '请选择可见范围', trigger: 'blur' }
        ],
        roomOpenDayList: [
          { required: true, message: '请选择开放时间', trigger: 'change' }
        ],
        roomOpenTimeList: [
          { required: true, validator: checkTime, trigger: 'change' }
        ]
      },
      flag: false,
      cropperOptions: {
        aspectRatio: 4 / 3
      }

    }
  },
  computed: {},
  mounted() {
    console.log(this.$route.query.type)
    if (this.$route.query.type === 'update') {
      this.getInfo()
    }
  },
  methods: {
    delmain(val) {
      const index = this.ruleForm.publishScopeList.findIndex(item => item == val.id)
      if (index >= 0) {
        this.ruleForm.publishScopeList.splice(index, 1)
      }
    },
    changpart() {
      this.$refs.chosepart.openModal()
    },

    change() {
      // console.log(this.ruleForm.roomOpenStartTime === this.ruleForm.roomOpenTimeList[0].substr(0, 5))
    },
    // 编辑回显
    getInfo() {
      console.log(this.$route.query.type)
      noticeQuery_room_info({ roomUuid: this.$route.query.roomUuid }).then(res => {
        Object.assign(this.ruleForm, res.data)
        this.ruleForm.roomEquipmentList = res.data.roomEquipmentList || []
        this.ruleForm.roomOpenStartTime = res.data.roomOpenTimeList[0].substr(0, 5)
        this.ruleForm.roomOpenEndTime = res.data.roomOpenTimeList[0].substr(6, 5)
        console.log(this.ruleForm.roomOpenStartTime)
        this.checkAll = res.data.roomOpenDayList === this.dateValue
      })
    },
    addList(val) {
      let str = ''
      if (val) {
        if (this.ruleForm.roomEquipmentList.length == 0) {
          this.ruleForm.roomEquipmentList.push(val)
        } else {
          this.ruleForm.roomEquipmentList.forEach(item => {
            str += item
          })
          if (200 - str.length < val.length) {
            this.$message.error('场所设备最多输入两百字')
          } else {
            this.ruleForm.roomEquipmentList.push(val)
          }
        }
      }
      str = ''
      this.ruleForm.roomEquipmentList.forEach(item => {
        str += item
      })
      if (str.length >= 200) {
        this.flag = true
      } else {
        this.flag = false
      }
      this.ruleForm.roomSiteEquipment = ''
    },
    // 删除tag
    handleClose(i) {
      this.ruleForm.roomEquipmentList.splice(i, 1)
      let str = ''
      this.ruleForm.roomEquipmentList.forEach(item => {
        str += item
      })
      if (str.length >= 200) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    changeDate(value) {
      console.log(value)
      const checkedCount = value.length
      this.checkAll = checkedCount === this.dateValue.length
    },
    // 全部开放时间
    handleCheckAllChange(val) {
      console.log(this.dateValue)
      this.ruleForm.roomOpenDayList = val ? this.dateValue : []
    },
    back() {
      this.$router.replace({ path: '/meeting', query: { appUuid: this.$route.query.appUuid }})
    },
    // 确认保存
    submit() {
      if (this.$route.query.type === 'update') {
        this.ruleForm.roomUuid = this.$route.query.roomUuid
      }
      const time = this.ruleForm.roomOpenStartTime + '-' + this.ruleForm.roomOpenEndTime
      this.ruleForm.roomOpenTimeList = []
      this.ruleForm.roomOpenTimeList.push(time)
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const load = this.$load()
          getAddRoom(this.ruleForm).then(res => {
            this.$refs.ruleForm.resetFields()
            this.ruleForm.roomEquipmentList = []
            if (this.$route.query.type === 'update') {
              this.$message.success('编辑成功')
            } else {
              this.$message.success('新增成功')
            }
            load.close()
            this.$router.go(-1)
          }).catch(e => {
            this.$errorHandle(e)
            load.close()
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>

