
<script>
export default {
  name: 'UmCollection',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler(val) {
        this.status = val
      }
    }
  },
  data() {
    return {
      status: false
    }
  },
  methods: {
    changeStatus() {
      this.status = !this.status
      this.$emit('change', this.status)
    }
  },
  render(h) {
    const className = this.status ? 'c-collection active' : 'c-collection'
    return (
      <div class={className} onClick={this.changeStatus}>
        <div class="m-main">
          <div class="m-heart"/>
          <div class="m-label">{this.status ? '取消收藏' : '收藏'}</div>
        </div>
      </div>
    )
  }
}
</script>

<style scoped lang="scss">
$size: 50px;
$color: transparent;
.c-collection {
  display: inline-block;
  cursor: pointer;
  &.active {
    .m-label {
      color: #FD5050;
    }
    .m-heart {
      background: url("~@/assets/dashboard/nav_over_collect.png") no-repeat center;
      //background: url("~@/assets/dashboard/heart-active.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
  .m-main {
    display: flex;
    align-items: center;
  }
  .m-label {
    font-size: 16px;
    font-weight: 400;
    color: #69748A;
    line-height: 14px;
    margin-left: 8px;
    user-select: none;
  }
  .m-heart {
    width: 16px;
    height: 16px;
    background: url("~@/assets/dashboard/heart.png") no-repeat center;
    background-size: 100% 100%;
    margin-top: 1px;
  }
}
</style>
