<template>
  <div class="m-publichome">
    <el-tree
      v-if="treeData.length>0"
      ref="tree"
      class="custom-tree"
      :data="treeData"
      :props="defaultProps"
      :node-key="defaultProps.id"
      :default-expanded-keys="expandedList"
      highlight-current
      @current-change="currentTreeChange"
    >
      <span slot-scope="{ node, data }" class="custom-tree-node">
        <span>
          <span style="font-size:14px;">{{ node.label }}</span>
          <span v-if="data.publicTypeCode" class="u-depart">
            {{ data.publicTypeCode==20242031?'地上':data.publicTypeCode==20242032?"地下":data.publicTypeCode==20242033?"基础层":'' }}
          </span>
        </span>
        <span
          class="edit"
          :class="{on:node.isCurrent}"
        >
          <el-tooltip
            v-if="node.level==1"
            effect="dark"
            content="添加"
            placement="top"
          >
            <el-button
              v-permission="['112040804']"
              type="text"
              size="mini"
              @click="() => append(node, data)"
            >
              <span class="add-icon" />
            </el-button>
          </el-tooltip>
          <el-tooltip effect="dark" content="编辑" placement="top">
            <el-button
              v-permission="['112040805']"
              type="text"
              size="mini"
              @click="() => update(node, data)"
            >
              <span class="edit-icon" />
            </el-button>
          </el-tooltip>
          <el-tooltip effect="dark" content="删除" placement="top">
            <el-button
              v-permission="['112040806']"
              type="text"
              size="mini"
              @click="() => remove(node, data)"
            >
              <span class="del-icon" />
            </el-button>
          </el-tooltip>
        </span>
      </span>
    </el-tree>
    <div v-if="treeData.length===0" class="text-center">
      <no-data />
    </div>
    <el-dialog
      title="新增二级公区"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="父级">
          <div>
            {{ form.parentName }}
          </div>
        </el-form-item>
        <el-form-item label="二级公区标题">
          <el-input v-model="form.partName" maxlength="20" placeholder="请输入，最多20字" style="width:300px;" />
        </el-form-item>
        <el-form-item label="公区属性">
          <el-select v-model="form.publicTypeCode" placeholder="请选择" clearable style="width:300px;">
            <el-option label="地上" :value="20242031" />
            <el-option label="地下" :value="20242032" />
            <el-option label="基础层" :value="20242033" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer flex-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="appendSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改二级公区"
      :visible.sync="resetVisible"
      width="500px"
    >
      <el-form ref="form" :model="updateForm" label-width="120px">
        <el-form-item label="二级公区标题">
          <el-input v-model="updateForm.partName" maxlength="20" placeholder="请输入，最多20字" style="width:300px;" />
        </el-form-item>
        <el-form-item label="公区属性">
          <el-select v-model="updateForm.publicTypeCode" placeholder="请选择" clearable style="width:300px;">
            <el-option label="地上" :value="20242031" />
            <el-option label="地下" :value="20242032" />
            <el-option label="基础层" :value="20242033" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer flex-center">
        <el-button @click="resetVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增自定义公区"
      :visible.sync="newpartVisible"
      width="500px"
      @close="$refs.rulerForm.resetFields()"
    >
      <el-form ref="rulerForm" :model="rulerForm" label-width="120px" @submit.native.prevent>
        <el-form-item label="自定义公区标题" prop="partName" :rules="[{ required: true, message: '请输入自定义公区标题'}]">
          <el-input v-model="rulerForm.partName" maxlength="20" clearable placeholder="请输入自定义公区标题（最多20字）" style="width:300px;" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer flex-center">
        <el-button @click="newpartVisible = false">取 消</el-button>
        <el-button type="primary" @click="newpartSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { building_part_tree_list, save_building_part, update_building_part, delete_building_part } from '@/api/mainData/position.js'
import noData from '@/views/cloudMarket/components/noData.vue'
export default {
  name: 'PublicHome',
  components: { noData },
  props: {
    busProjectUuid: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      resetVisible: false,
      newpartVisible: false,
      form: {
        publicTypeCode: null, // 公区属性  地上：20242031  地下：20242032   普通层：20242033
        partParentUuid: null,
        partName: '', // 部位名称
        busProjectUuid: null
      },
      updateForm: {
        partName: '',
        publicTypeCode: null
      },
      rulerForm: {
        partName: ''
      },
      treeData: [],
      expandedList: [],
      defaultProps: {
        id: 'partUuid',
        children: 'children',
        label: 'partName'
      },
      currentNode: null,
      flag: true
    }
  },
  watch: {
    busProjectUuid(val) {
      this.getTree()
    }
  },
  created() {
    this.getTree()
  },
  methods: {
    changeList() {
      this.getTree()
    },
    getTree() {
      if (!this.busProjectUuid) return false
      const load = this.$load()
      building_part_tree_list({
        busProjectUuid: this.busProjectUuid
      }).then(d => {
        const data = d.data
        // const data = this.treeData
        this.treeData = data
        this.expandedList = data.map(item => item.partUuid)
        if (this.expandedList.length == 0) {
          this.currentNode = null
        } else {
          this.$nextTick(() => {
            this.$refs.tree.setCurrentNode(data[0])
            this.currentNode = data[0]
          })
        }
      }).finally(_ => {
        load.close()
      }).catch(e => {
        this.$errorHandle(e)
      })
    },
    currentTreeChange(row, node) {
      this.currentNode = row
    },
    append(node, data) {
      this.dialogVisible = true
      console.log(data)
      this.form.parentName = node.label
      this.form.partParentUuid = data.partUuid
      this.form.busProjectUuid = this.busProjectUuid
      this.form.partName = null
      this.form.publicTypeCode = null
    },
    // 新增二级公区
    appendSubmit() {
      const load = this.$load()
      save_building_part({
        ...this.form,
        partTypeCode: 20242021 // 公区：20242021  房间：20242022
      })
        .then(d => {
          this.$message.success('新增成功！')
          this.dialogVisible = false
          load.close()
          this.getTree()
        })
        .finally(e => {
          load.close()
        })
    },
    // 新增自定义公区
    appendParent() {
      this.newpartVisible = true
    },
    newpartSubmit() {
      const load = this.$load()
      save_building_part({
        busProjectUuid: this.busProjectUuid,
        ...this.rulerForm,
        partTypeCode: 20242021 // 部位类型  公区：20242021  房间：20242022
      }).then(d => {
        this.$message.success('新增成功！')
        this.getTree()
      }).finally(_ => {
        load.close()
        this.newpartVisible = false
      }).catch(e => {
        load.close()
      })
    },
    update(node, data) {
      if (node.level === 1) {
        this.$prompt('', '修改自定义公区', {
          inputErrorMessage: '请输入二级公区标题（最多20字）',
          inputPlaceholder: '请输入二级公区标题（最多20字）',
          inputValue: node.label,
          inputValidator: function(val) {
            return Boolean(val) && val.length <= 20
          }
        }).then(({ value }) => {
          console.log(value)
          const load = this.$load()
          update_building_part({
            busProjectUuid: this.busProjectUuid,
            partName: value,
            partUuid: data.partUuid
          }).then(d => {
            load.close()
            this.$message.success('修改成功')
            data.partName = value
          }).catch(e => {
            load.close()
          })
        }).catch(() => {
          this.$message.info('取消修改!')
        })
      } else {
        this.resetVisible = true
        this.updateForm.partName = data.partName
        this.updateForm.publicTypeCode = data.publicTypeCode
        this.updateForm.partUuid = data.partUuid
        this.updateForm.busProjectUuid = this.busProjectUuid
      }
    },
    updateSubmit() {
      const load = this.$load()
      update_building_part(this.updateForm).then(d => {
        this.$message.success('修改成功')
        this.resetVisible = false
        load.close()
        this.deepClone(this.treeData, this.updateForm.partUuid, this.updateForm.partName, this.updateForm.publicTypeCode)
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
      })
    },
    deepClone(data, id, name, code) {
      var item
      console.log(code)
      for (const i in data) {
        console.log(1)
        item = data[i]
        if (item.partUuid == id) {
          item.partName = name
          item.publicTypeCode = code
          return true
        }
        if (this.deepClone(item.children, id, name, code)) return true
        if (item.children && item.children.length) {
          this.deepClone(item.children, id, name, code)
        }
      }
    },
    remove(node, data) {
      this.$confirm(`是否删除${node.label}?`, '提示', {
        type: 'warning'
      }).then(() => {
        // 缺少接口
        const load = this.$load()
        delete_building_part({
          partUuid: data.partUuid
        }).then(d => {
          load.close()
          this.getTree()
          this.$message.success('删除成功！')
        }).finally(_ => {
          load.close()
        }).catch(e => {
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message.info('已取消删除！')
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
