import store from '@/store'
function checkRule(el, binding) {
  const { value } = binding
  const roles = store.getters && store.getters.roles
  if (value && value instanceof Array && value.length > 0) {
    const permissionRoles = value
    const hasPermission = roles.some(role => {
      return permissionRoles.includes(role)
    })
    if (!hasPermission) {
      el.setAttribute('data-permission', 'false')
    } else {
      el.setAttribute('data-permission', 'true')
    }
  } else {
    throw new Error('need roles! Like v-permission="[\'admin\',\'editor\']"')
  }
}
export default {
  inserted(el, binding) { checkRule(el, binding) },
  update(el, binding) { checkRule(el, binding) }
}
