<template>
  <div class="m-solutionBox">
    <div v-for="(item,i) in solutionList" :key="i" class="solutionBox_content pointer mt20" :class="marginStyle===2?'content_twoBox':'content_threeBox'" @click="getInfo(item)">
      <div class="solutionBox_content_imgBox" :class="marginStyle === 2?'imgBox_twoHeight':'imgBox_threeHeight'">
        <div :class="marginStyle === 2?'imgBox_twoHeight':'imgBox_threeHeight'"><img :src="item.iconUrl" alt="" style="height:100%;width:100%"></div>

        <div class="imgBox_tags flex-column-center ">
          <div v-for="(ite,j) in item.labels" :key="j" size="mini" class="mr-10 tags-item">{{ ite }}</div>
        </div>
      </div>
      <div class="solutionBox_content_titleBox">
        <div class="title_name">
          <UmToolTip :content="item.title" class="row_1" />
        </div>
        <div class="title_num">{{ item.visitNum||0 }} 浏览</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    solutionList: {
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      default: null
    },
    tabType: {
      type: [String, Number],
      default: 1
    },
    marginStyle: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    getInfo(item) {
      if (this.type === 3) {
        this.$router.push({ path: '/cloudMarket/info', query: { uuid: item.uuid, tabType: this.tabType, dataType: 10239004 }})
      }
    }
  }
}
</script>

