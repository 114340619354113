
<template>
  <div class="top">
    <div class="top-back">
      <div class="title-back w60" @click="back">返回</div>
    </div>
    <div class="top-content">
      <!--        <div class="icon-img" >-->
      <img class="icon-img" :src="info.appIconUrl" alt="">
      <!--        </div>-->
      <div class="flex-column ml20">
        <div class="title">{{ info.appName }}</div>
        <div class="text">{{ info.appDescription||info.developerName }}</div>
      </div>
      <div class="top-switch">
        <div class="title">{{ disabledFlag?'启用':'禁用' }}</div>
        <el-switch
          v-model="disabledFlag"
          @change="editSwitch"
        />
      </div>
    </div>
    <div class="item">
      <div class="label">可见范围：</div>
      <um-bus-user ref="user" :scope="1" v-model="info.appUsePurview.values" :show-tab="false" :show-prefix-button="false" :type="3" @change="change">
        <template #user="{data}">
          <div class="user-box">
            <div v-if="data.length" class="user-item">
              <span v-for="(item,index) in data" :key="index" class="user-item-has">
                {{ item.name }}
                <span class="del-user" @click="$refs.user.removeItem(index)" />
              </span>
              <span class="config" @click="$refs.user.openModal()">
                {{ data.length===0?'设置':'修改' }}
              </span>
            </div>
            <div v-else class="config" @click="$refs.user.openModal()">
              {{ data.length===0?'设置':'修改' }}
            </div>
          </div>
        </template>
      </um-bus-user>
    </div>
    <div class="item">
      <div class="label">管理员：</div>
      <um-bus-user v-if="info.appManager" ref="userManager" v-model="info.appManager" :show-tab="false" :show-prefix-button="false" :type="2">
        <template #user="{data}">
          <div class="user-box">
            <div class="user-item">
              <!--              <span class="user-item-has">-->
              <!--                {{ data.map(item=>item.name).join(' / ') }}-->
              <!--              </span>-->
              <span v-for="(item,index) in data" :key="index" class="user-item-has">
                {{ item.name }}
              </span>
            </div>
          </div>
        </template>
      </um-bus-user>
      <!--        <div class="value">{{ info.appManagerUuidList }}</div>-->
    </div>
  </div>
</template>

<script>
import {
  applicationEp_app_manageGet_app_info,
  applicationEp_app_manageUpdate_disable_flag,
  applicationEp_app_manageUpdate_app_use_purview
} from '@/api/enterprise'

export default {
  name: 'UmApproveEle',
  props: {
    appUuid: {
      type: [Number, String],
      default: ''
    }

  },
  data() {
    return {
      disabledFlag: false,
      info: {
        appUsePurview: {
          checkAll: 0,
          values: []
        }
      }
    }
  },
  mounted() {
    if (this.appUuid || this.$route.query.appUuid) {
      this.getInfo()
    }
  },
  methods: {
    removeItem(index) {
      this.info.appUsePurview.values.splice(index, 1)
      this.change()
    },
    getInfo() {
      const appUuid = this.appUuid || this.$route.query.appUuid
      applicationEp_app_manageGet_app_info({ appUuid: appUuid }).then(res => {
        this.disabledFlag = !res.data.disabledFlag
        this.info = res.data
      })
    },
    back() {
      this.$router.replace('/enterprise/application')
    },
    change() {
      const load = this.$load()
      applicationEp_app_manageUpdate_app_use_purview({ appUsePurview: this.info.appUsePurview.values, appUuid: this.appUuid }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      }).catch(e => {
        this.getInfo()
        this.$errorHandle(e)
      }).finally(e => {
        load.close()
      })
    },
    editSwitch(value) {
      const disabledFlag = value ? 0 : 1
      // this.info.disabledFlag =
      applicationEp_app_manageUpdate_disable_flag({ disabledFlag: disabledFlag, appUuid: this.appUuid }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
        // this.getInfo()
      }).catch(e => {
        this.$errorHandle(e)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.top{
  min-height: 253px;
  width: 1260px;
  background:url("~@/assets/enterprise/backgroundImg.png") no-repeat ;
  background-color: #ffffff;
  padding:20px 20px 0px;
  border-radius: 12px;
  overflow: hidden;
.top-back{
  padding-bottom: 20px;
  border-bottom: 1px dashed #ADB3BF;
}
.top-content{
  display: flex;
  position: relative;
  margin-bottom: 24px;
  margin-top: 17px;
.icon-img{
  width: 60px;
  height: 60px;
//background: #3D80FC;
border-radius: 10px;
}
.title{
  font-size: 18px;
  font-weight: 500;
  color: #31415F;
}
.text{
  font-size: 12px;
  color: #69748A;
}
.top-switch{
  display: flex;
  align-items: center;
  position: absolute;
  //right: 25%;
  right: 250px;
  top: 10px;
.title{
  margin-right: 10px;
  color: #31415F;
  font-size: 14px;
}
}
}
.item{
  margin-bottom: 10px;
  display: flex;
  // align-items: center;
.label{
  min-width: 70px;
  font-size: 14px;
  color: #31415F;
  margin-bottom: 10px;
}
.user-box{
  display: flex;
  align-items: center;
  margin-top: -9px;
  padding-bottom: 8px;
.user-item{
  max-width: 1200px;
  .user-item-has{
    display: inline-block;
    min-height: 32px;
    line-height: 32px;
    border-radius: 4px;
    border: 1px solid #E4E8EB;
    padding: 0 12px;
    font-size: 14px;
    color: #31415F;
    margin-right: 16px;
    cursor: pointer;
    margin-bottom: 12px;
    vertical-align: middle;
   .del-user{
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url("~@/assets/enterprise/icon-del-user.png") no-repeat;
    background-size: 100% 100%;
    margin-left: 8px;

  }
}

.user-item-no{
  font-size: 14px;
  color: #909DB6;
}
}
.config{
  display: inline-block;
  width: 80px;
  cursor: pointer;
  //margin-left: 10px;
  font-size: 14px;
  color: #3D80FC;
  margin-bottom: 10px;
}
}
.value{
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #E4E8EB;
  border-radius: 4px;
  font-size: 14px;
  color: #31415F;
  padding: 0 36px;

}

}
}
</style>
