
<script>
import { like_num, isLike, collectLike, cancelCollect } from '@/api/dashboard'

export default {
  name: 'UmLike',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    businessType: {
      type: [String, Number],
      default: ''
    },
    businessUuid: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      status: false,
      likeNum: 0
    }
  },
  watch: {
    value: {
      handler(val) {
        this.status = val
      }
    },
    businessUuid: {
      handler(val) {
        this.getLikNum()
        this.getLikeStatus()
      }
    }
  },
  methods: {
    getLikeStatus() {
      isLike({
        businessType: this.businessType,
        businessUuid: this.businessUuid
      }).then(res => {
        this.status = res.data
      })
    },
    getLikNum() {
      like_num({
        businessType: this.businessType,
        businessUuid: this.businessUuid,
        type: 2
      }).then(res => {
        this.likeNum = res.data || 0
      })
    },
    likeHandler() {
      if (this.status) {
        cancelCollect({
          businessType: this.businessType,
          businessUuid: this.businessUuid,
          type: 2
        }).then(res => {
          this.changeStatus()
        }).finally(_ => {
          this.getLikNum()
        })
      } else {
        collectLike({
          businessType: this.businessType,
          businessUuid: this.businessUuid
        }).then(res => {
          this.changeStatus()
        }).finally(_ => {
          this.getLikNum()
        })
      }
    },
    changeStatus(status) {
      this.status = typeof status === 'boolean' ? status : !this.status
      this.$emit('change', this.status)
    }
  },
  render(h) {
    const className = this.status ? 'c-like active' : 'c-like'
    return (
      <div class={className} onClick={this.likeHandler}>
        <div class="m-main">
          <div class="m-heart"/>
          <div class="m-label">{this.status ? '已点赞' : '点赞'}（{this.likeNum}）</div>
        </div>
      </div>
    )
  }
}
</script>

<style scoped lang="scss">
$size: 50px;
$color: transparent;
.c-like {
  display: inline-block;
  cursor: pointer;
  &.active {
    .m-label {
      color: #4C64FE;
    }
    .m-heart {
      background: url("~@/assets/dashboard/good-active.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
  .m-main {
    display: flex;
    align-items: center;
  }
  .m-label {
    font-size: 16px;
    font-weight: 400;
    color: #69748A;
    line-height: 14px;
    margin-left: 8px;
    user-select: none;
  }
  .m-heart {
    width: 16px;
    height: 16px;
    background: url("~@/assets/dashboard/like.png") no-repeat center;
    background-size: 100% 100%;
    margin-top: 1px;
  }
}
</style>
