<template>
  <div class="detailsBox searchBox">
    <div class="go_back_common">
      <div class="go_back_title" @click="back">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="searchBox_content">
      <div class="flex mb10">
        <el-input v-model="words" placeholder="请输入关键词搜索" maxlength="50" @keyup.enter.native="getSearch(words)" />
        <el-button icon="el-icon-search" type="primary" class="ml20 flex-center " style="height:32px" @click="getSearch(words)">搜索</el-button>
      </div>
      <div v-if="dataList.length>0">
        <div v-for="(item,i) in dataList" :key="i" class="content_box">
          <div class="u-title mt10" :class="item.type===3?'mb20':''">{{ titleType[item.type] }}</div>
          <div v-if="item.items&&item.items.length===0" class="mt10">
            <no-data />
          </div>
          <div v-if="item.type!=3&&item.type!=5" class="contentbox_item">
            <el-row :gutter="20">
              <el-col v-for="(ite,j) in item.items" :key="j" :span="6">
                <application :form="ite" :type="tabType[item.type]" :tab-type="$route.query.tabType" :margin-style="4" />
              </el-col>
            </el-row>
          </div>
          <div v-if="item.type===3" class="contentbox_item">
            <el-row :gutter="24">
              <el-col v-for="(ite,j) in item.items" :key="j" :span="6">
                <hardwareList :type="5" :form="ite" :tab-type="5" class="mb20" />
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div v-else class="content_empty">
        <div class="empty-icon um-icon um-icon_work-empty" />
        <div class="no-title">没有找到匹配的结果</div>
        <div>您可以试试下面的建议搜索</div>
        <div v-if="hotList&&hotList.length>0" class="hot-record">
          <div class="record-title">热门搜索</div>
          <div v-for="(item,index) in hotList" :key="index" class="record-item" @click="getSearch(item.labelName)">{{ item.labelName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSearch } from '@/api/cloudMarket'
import application from '../components/application.vue'
import noData from '../components/noData.vue'
import hardwareList from '../components/hardwareList.vue'
export default {
  name: 'CloudMarketSearch',
  components: { application, noData, hardwareList },
  data() {
    return {
      words: '',
      dataList: [],
      titleType: {
        '1': '应用',
        '2': '解决方案',
        // '3': '智能硬件',
        '3': '商品',
        '4': '服务'
      },
      tabType: {
        '1': 2,
        '2': 3,
        '3': 5,
        '4': 4
      },
      hotList: []

    }
  },
  mounted() {
    this.getSearch(this.$route.query.keyWord)
    this.hotList = this.$route.query.hotList
    this.words = this.$route.query.keyWord
  },
  methods: {
    // 返回
    back() {
      this.$router.push({ path: '/cloudMarket', query: { tabType: this.$route.query.tabType }})
    },
    // 搜索
    getSearch(keyWord) {
      if (keyWord) {
        const load = this.$load()
        getSearch({ keywords: keyWord }).then(res => {
          load.close()
          this.dataList = res.data.list || []
          if (this.dataList.length > 0) {
            this.dataList.forEach(item => {
              item.items.forEach(ite => {
                ite.appUuid = ite.uuid
                ite.appName = ite.title
                ite.appIconUrl = ite.iconUrl || ''
                ite.appDescription = ite.content
                ite.appPrice = ite.price
              })
            })
          }
        }).catch(e => {
          load.close()
        })
      } else {
        this.$message.error('请输入搜索关键词')
      }
    }

  }
}
</script>
