<template>
  <div class="chatPerson-container">
    <div class="msg-list">
      <div
        v-for="(item,index) in list"
        :key="index"
        class="msg-item"
        :class="{left: item.type == 1,
                 right: item.type ==2
        }"
      >
        <div v-if="item.type== 1" class="author">
          <img src="@/assets/im/author.png" alt="">
        </div>
        <div class="msg-box">
          <div class="text">
            {{ item.msg }}
          </div>
        </div>
        <div v-if="item.type== 2" class="author">
          <img src="@/assets/im/author.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatPerson',
  props: {
    list: { // 消息
      type: Array,
      default: () => [
        { type: 1, msg: '你好' },
        { type: 2, msg: '我不好' },
        { type: 1, msg: '那可真好' },
        { type: 1, msg: '那可是的非官方的时光的双方各得十分广泛的水果放到三个都是法国当时法国的双方各真好' },
        { type: 1, msg: '那可真好' },
        { type: 2, msg: '那可真好俺的沙发沙发士大夫撒发士大夫撒旦法师打发的撒发生到底发生发士大夫撒打发第三方' },
        { type: 1, msg: '那可真好' }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.chatPerson-container {
  width: 100%;
  padding: 0 24px;
  .msg-list {
    width: 100%;
    .left {
      justify-content: flex-start;
      .author {
        margin-right: 10px;
      }
      .msg-box {
        background: #fff;
        color: #31415f;
      }
    }
    .right {
      justify-content: flex-end;
      .author {
        margin-left: 10px;
      }
      .msg-box {
        background: #4f85fb;
        color: #fff !important;
      }
    }
    .msg-item {
      margin: 20px 0;
      display: flex;
      .author {
        width: 30px;
        height: 30px;
        //margin-right: 10px;
        > img {
          width: 100%;
          height: 100%;
          user-select: none;
        }
      }
      .msg-box {
        max-width: 60%;
        padding: 8px 10px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #31415f;
        line-height: 17px;
      }
    }
  }
}
</style>
