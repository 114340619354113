<template>
  <div><svg-icon icon-class="shoppingCart" style="font-size:16px;margin-right:2px" />购物车({{ count }})</div>
</template>

<script>
import { getshoppingcartcount } from '@/api/common'
export default {
  name: 'ShopCarMarket',
  components: {},
  props: {},
  data() {
    return {
      count: 0
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getshopcarnum()
  },
  mounted() {},
  beforeDestroy() {
    if (this.timeTag) window.clearTimeout(this.timeTag)
  },
  methods: {
    getshopcarnum() {
      if (this.$isWebsite) return false
      getshoppingcartcount().then(d => {
        this.count = d.data.count || 0
        this.timeTag = setTimeout(() => {
          this.getshopcarnum()
        }, 5000)
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
