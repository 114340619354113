<template>
  <div class="m_enterprise_applicationconfig">
    <div class="m_applicationconfig_top">
      <div class="m_applicationconfig_topback">
        <div class="title-back w60" @click="back">返回</div>
        <div v-if="info.appSourceCode==2" class="m_applicationconfig_topdelete" @click="deleteApp">
          <svg-icon icon-class="icon-delete" />
          删除应用
        </div>
      </div>
      <div class="m_applicationconfig_topcontent">
        <img class="m_applicationconfig_img" :src="info.appIconUrl" alt="">
        <div class="flex-column ml20">
          <div class="m_applicationconfig_title">
            {{ info.appName }}
            <span v-if="info.appSourceCode==2" class="m_applicationconfig_edit" @click="editApp" />
          </div>
          <div class="m_applicationconfig_text">{{ info.appDescription||info.developerName }}</div>
        </div>
        <div class="m_applicationconfig_switch">
          <div class="u_switch_title">{{ disabledFlag?'启用':'禁用' }}</div>
          <el-switch
            v-model="disabledFlag"
            @change="editSwitch"
          />
        </div>
      </div>
      <div class="m_applicationconfig_item">
        <div class="m_applicationconfig_label">可见范围：</div>
        <um-bus-user ref="user" :scope="1" v-model="info.appUsePurview.values" :show-tab="false" :show-prefix-button="false" :type="3" @change="change">
          <template #user="{data}">
            <div class="m_applicationconfig_box">
              <div v-if="data.length" class="m_applicationconfig_useritem">
                <span v-for="(item,index) in data" :key="index" class="m_applicationconfig_has">
                  {{ item.name }}
                  <span class="m_applicationconfig_del" @click="$refs.user.removeItem(index)" />
                </span>
                <span class="m_applicationconfig_config" @click="$refs.user.openModal()">
                  {{ data.length===0?'设置':'修改' }}
                </span>
              </div>
              <div v-else class="m_applicationconfig_config" @click="$refs.user.openModal()">
                {{ data.length===0?'设置':'修改' }}
              </div>
            </div>
          </template>
        </um-bus-user>
      </div>
      <div class="m_applicationconfig_item">
        <div class="m_applicationconfig_label">管理员：</div>
        <um-bus-user v-if="info.appManager" ref="userManager" v-model="info.appManager" :show-tab="false" :show-prefix-button="false" :type="2">
          <template #user="{data}">
            <div class="m_applicationconfig_box">
              <div class="m_applicationconfig_useritem">
                <span v-for="(item,index) in data" :key="index" class="m_applicationconfig_has">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </template>
        </um-bus-user>
      </div>
    </div>
    <el-dialog title="编辑应用" :visible.sync="innerVisible" width="522px" center :append-to-body="false">
      <el-form
        ref="addForm"
        class="addForm"
        :model="addForm"
        label-position="left"
        label-width="120px"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item ref="appName" label="应用名称" prop="appName">
          <el-input v-model="addForm.appName" placeholder="请输入应用名称" maxlength="50" />
        </el-form-item>
        <el-form-item ref="appIconUrl" label="应用图标" prop="appIconUrl">
          <div class="m_applicationconfig_tips">
            <um-upload-img width="88" height="88" v-model="addForm.appIconUrl" :show-icon="false" custom-file :limit="1" :simple="true" type="avatar">
              <template #extend>
                <div class="m_applicationconfig_tips2">
                  <div class="m_applicationconfig_imgicon" />
                </div>
              </template>
            </um-upload-img>
          </div>
        </el-form-item>
        <el-form-item ref="appJumpLink" label="APP端H5地址" prop="appJumpLink">
          <el-input v-model="addForm.appJumpLink" :max-length="255" placeholder="请输入APP端H5地址" />
        </el-form-item>
        <el-form-item ref="pcJumpLink" label="PC端H5地址" prop="pcJumpLink">
          <el-input v-model="addForm.pcJumpLink" :max-length="255" placeholder="请输入PC端H5地址" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="innerVisible = false,$refs.addForm.resetFields(),$refs.addForm.clearValidate()">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  applicationEp_app_manageGet_app_info,
  applicationEp_app_manageUpdate_self_built_app,
  applicationEp_app_manageUpdate_disable_flag,
  applicationEp_app_manageDelete_self_built_app,
  applicationEp_app_manageUpdate_app_use_purview, applicationEp_app_manageAdd_self_built_app
} from '@/api/enterprise'
export default {
  name: 'EnterpriseApplicationConfigOther',
  data() {
    return {
      value: true,
      appUuid: '',
      user: [],
      disabledFlag: false,
      info: {
        appUsePurview: {
          checkAll: 0,
          values: []
        }
      },
      addForm: {
        appName: '',
        appIconUrl: '',
        appJumpLink: '',
        pcJumpLink: '',
        appUuid: ''
      },
      innerVisible: false,
      rules: {
        appName: [
          { required: true, message: '请输入应用名称', trigger: 'change' },
          { min: 0, max: 20, message: '长度在 0 到 20 个字符', trigger: 'change' }
        ],
        appIconUrl: [
          { required: true, message: '请选择应用图标', trigger: 'change' }
        ],
        appUsePurview: [
          { required: true, message: '请选择应用可见范围', trigger: 'change' }
        ],
        appJumpLink: [
          { required: true, message: '请输入APP端H5地址', trigger: 'change' }
        ],
        pcJumpLink: [
          { required: true, message: '请输入PC端H5地址', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.appUuid = this.$route.query.appUuid
    if (this.appUuid) {
      this.getInfo()
    }
  },
  methods: {
    removeItem(index) {
      this.info.appUsePurview.values.splice(index, 1)
      this.change()
    },
    getInfo() {
      applicationEp_app_manageGet_app_info({ appUuid: this.appUuid }).then(res => {
        console.log(res)
        this.disabledFlag = !res.data.disabledFlag
        this.info = res.data
        console.log(this.disabledFlag)
      })
    },
    back() {
      this.$router.replace('/enterprise/application')
    },
    editApp() {
      console.log('编辑app')
      this.innerVisible = true
      applicationEp_app_manageGet_app_info({ appUuid: this.appUuid }).then(res => {
        this.addForm.appName = res.data.appName
        this.addForm.appIconUrl = res.data.appIconUrl
        this.addForm.appJumpLink = res.data.appJumpLink
        this.addForm.pcJumpLink = res.data.pcJumpLink
        this.addForm.appUuid = res.data.appUuid
      })
    },
    submit() {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          try {
            const params = {
              ...this.addForm
            }
            await applicationEp_app_manageUpdate_self_built_app({
              ...params
            })
            this.$message({ type: 'success', message: '操作成功!' })
            this.innerVisible = false
            this.$refs.addForm.resetFields()
            this.$refs.addForm.clearValidate()
            this.getInfo()
          } catch (e) {
            this.$errorHandle(e)
          }
        }
      })
    },
    deleteApp() {
      console.log('删除应用')
      this.$confirm('删除应用后企业用户将无法访问该应用' + '\n' + '请问是否删除？', '删除应用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        applicationEp_app_manageDelete_self_built_app({ appUuid: this.appUuid }).then(d => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.back()
        }).catch(e => {
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    change() {
      const load = this.$load()
      applicationEp_app_manageUpdate_app_use_purview({ appUsePurview: this.info.appUsePurview.values, appUuid: this.appUuid }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      }).catch(e => {
        this.getInfo()
        this.$errorHandle(e)
      }).finally(e => {
        load.close()
      })
    },
    editSwitch(value) {
      console.log(value)
      const disabledFlag = value ? 0 : 1
      applicationEp_app_manageUpdate_disable_flag({ disabledFlag: disabledFlag, appUuid: this.appUuid }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      }).catch(e => {
        this.$errorHandle(e)
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
