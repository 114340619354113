<template>
  <div>
    <el-form v-if="!readonly">
      <el-form-item
        label="地点"
      >
        <el-input
          id="baiduMapSearchContent"
          v-model="key"
          placeholder="请输入地点进行搜索"
          clearable
          style="width:70%"
          @change="change"
        />
        <el-button
          type="primary"
          icon="search"
          style="margin-left:10px;"
          @click="setPlace"
        >
          搜索
        </el-button>
        <div
          id="searchResultPanel"
          style="display:none"
        />
      </el-form-item>
    </el-form>
    <div
      id="allmap"
      style="width:800px;height:400px"
    />
  </div>
</template>

<script>
let map = {}
let marker = {}
export default {
  name: 'UmMap',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myValue: '',
      map: null,
      ac: null,
      key: ''
    }
  },
  created() {
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      var customPosition = false
      var position = [116.400244, 39.92556]
      // 初始化
      if (this.value.lng && this.value.lat) {
        position = [this.value.lng, this.value.lat]
        customPosition = true
      }
      /* eslint-disable no-undef */
      map = new BMap.Map('allmap')
      var point = new BMap.Point(position[0], position[1])
      var top_left_control = new BMap.ScaleControl({ anchor: BMAP_ANCHOR_TOP_LEFT })// 左上⻆，添加比例尺
      var top_left_navigation = new BMap.NavigationControl() // 左上⻆，添加默认缩放平移控件
      var top_right_navigation = new BMap.NavigationControl({ anchor: BMAP_ANCHOR_TOP_RIGHT, type: BMAP_NAVIGATION_CONTROL_SMALL }) // 右上⻆，仅包含平移和缩放按钮
      map.centerAndZoom(point, 12)
      map.addControl(top_left_control); map.addControl(top_left_navigation)
      map.addControl(top_right_navigation); map.enableScrollWheelZoom() // 启用滚轮放大缩小，默认禁用
      map.enableContinuousZoom() // 启用地图惯性拖拽，默认禁用
      marker = new BMap.Marker(point)// 创建标注
      map.addOverlay(marker) // 将标注添加到地图中 //单击获取点击的经纬度
      if (this.readonly) return false
      map.addEventListener('click', e => {
        marker.remove()
        marker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat))
        map.addOverlay(marker)
        this.$emit('change', e.point)
      })
      function myFun(result) {
        var cityName = result.name
        map.setCenter(cityName)
      }
      if (!customPosition) {
        var myCity = new BMap.LocalCity()
        myCity.get(myFun)
      }
      // var this.myValue;
      function G(id) {
        return document.getElementById(id)
      }
      this.ac = new BMap.Autocomplete( // 建立一个自动完成的对象
        {
          input: 'baiduMapSearchContent',
          onSearchComplete: this.onSearchComplete,
          location: map
        }
      )
      // console.log(ac)
      this.ac.addEventListener('onhighlight', function(e) { // 鼠标放在下拉列表后的事件
        console.log(e)
        var str = ''
        var _value = e.fromitem.value
        var value = ''
        if (e.fromitem.index > -1) {
          value = _value.province + _value.city + _value.district + _value.street + _value.business
        }
        str = 'FromItem<br />index = ' + e.fromitem.index + '<br/>value = ' + value
        value = ''
        if (e.toitem.index > -1) {
          _value = e.toitem.value
          value = _value.province + _value.city + _value.district + _value.street + _value.business
        }
        str += '<br />ToItem<br />index = ' + e.toitem.index + '<br />value = ' + value

        G('searchResultPanel').innerHTML = str
      })
      this.ac.addEventListener('onconfirm', e => {
        // console.log(e)
        var _value = e.item.value
        // 鼠标点击下
        this.myValue = _value.province + _value.city + _value.district + _value.street + _value.business
        this.key = this.myValue // 做下数据同步
        G('searchResultPanel').innerHTML = 'onconfirm<br />index= ' + e.item.index + '<br />this.myValue = ' + this.myValue
        this.setPlace()
      })
    },
    setPlace() {
      map.clearOverlays() // 清除地图上所有覆盖物
      var local = new BMap.LocalSearch(map, { // 智能搜索
        onSearchComplete: () => {
          console.log(arguments)
          var pp = local.getResults().getPoi(0).point // 获取第一个智能搜索的结果
          map.centerAndZoom(pp, 18)
          marker = new BMap.Marker(pp)
          map.addOverlay(marker) // 添加标注

          this.$emit('change', pp)
        }
      })
      if (!this.key) return false
      local.search(this.myValue)
      // local.search(this.key)
    },
    change() {
      this.myValue = this.key
      // console.log(this.ac.getResults())
    },
    onSearchComplete(e) {
      // console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
<style>
.tangram-suggestion-main{
  z-index:9999
}
</style>
