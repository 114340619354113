<template>
  <div class="m-searchRecords">
    <div class="u-searchTitle">搜索记录</div>
    <div v-if="searchList.length>0" class="flex  search_Records">
      <div v-for="(item,index) in searchList" :key="index">
        <div v-if="item" class="search_popular" @click="getSearch(item)">{{ item }}</div>
      </div>
    </div>
    <div v-else class="mb20" />
    <div class="u-searchTitle">热门搜索</div>
    <div v-if="hotList.length>0" class="flex">
      <div v-for="(ite,i) in hotList" :key="i">
        <div v-if="ite" class="search_popular" @click="getSearch(ite.labelName )"> {{ ite.labelName }}</div>
      </div>
    </div>
    <div class="mb20" />
  </div>
</template>
<script>
import { getHotSearch } from '@/api/cloudMarket'
export default {
  props: {
    searchType: {
      type: [Number, String],
      default: null
    },
    searchList: {
      type: Array,
      default: () => []
    },
    // 返回回到对应tab
    tabType: {
      type: [Number, String],
      default: 1
    }
  },
  data() {
    return {
      hotList: []
      // words: ''
    }
  },
  mounted() {
    this.getHotSearch()
  },
  methods: {
    getSearch(item) {
      this.$emit('words', item, this.hotList)
    },
    // 热门搜索
    getHotSearch() {
      getHotSearch({ type: this.searchType }).then(res => {
        this.hotList = res.data.list
      }).catch(e => {

      })
    }
  }
}
</script>

