<template>
  <div>
    <CloudMarketAppInfo v-if="$route.query.dataType==10239001" />
    <CloudMarketSolutionDetails v-if="$route.query.dataType==10239004" />
    <CloudMarketServiceCentreDetails v-if="$route.query.dataType==10239002" />
    <CloudMarketHardwareMallDetails v-if="$route.query.dataType==10239003" />
  </div>
</template>
<script>
import CloudMarketAppInfo from './appInfo/index.vue'
import CloudMarketSolutionDetails from './solution/info/index.vue'
import CloudMarketServiceCentreDetails from './serviceCentre/info/index.vue'
import CloudMarketHardwareMallDetails from './hardwareMall/info/index.vue'
export default {
  name: 'CloudMarketInfo',
  components: {
    CloudMarketAppInfo, CloudMarketSolutionDetails, CloudMarketServiceCentreDetails, CloudMarketHardwareMallDetails
  },
  data() {
    return {

    }
  }
}
</script>
