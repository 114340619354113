<template>
  <!--  <div class="min_h_100">-->
  <!--    <um-bread-crumb />-->
  <el-card shadow="never" class="min_h_100 m_tender_addedit mar_auto_w1260">
    <div class="go_back_common">
      <!--      <div class="go_back_title" @click="$router.push('/mainData/tender/index')">-->
      <div class="go_back_title" @click="$router.replace('/mainData/tender')">
        <span class="go_back_icon" />
        <span class="go_back_txt">标段管理</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <el-form
      ref="addForm"
      :model="addForm"
      :rules="rules"
      class="demo-ruleForm"
      @submit.native.prevent
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item
            ref="busProjectUuid"
            label="项目名称"
            prop="busProjectUuid"
          >
            {{ buildingForm.projectName||'-' }}
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item ref="projectParaName" label="标段名称" prop="projectParaName">
            <el-input
              v-model="addForm.projectParaName"
              style="width:388px"
              clearable
              maxlength="50"
              placeholder="请输入标段名称，最多50字"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" class="tender_scope">
          <el-form-item ref="busBuildingIdList" label="楼栋范围" prop="busBuildingIdList">
            <div class="m_tender_building">
              <div class="tender_scope_left" @click="showItem">请选择</div>
              <div v-if="tagList.length" class="m_tender_scopebgc">
                <span v-for="(item,index) in tagList" :key="index" class="m_tender_scope_item">
                  <span class="m_tender_scope_itemname">{{ item.buildingName }}</span>
                  <span class="m_tender_scope_del el-icon-close" @click="closeTag(item.busBuildingId)" />
                </span>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <div class="m_tender_scope_btn">
          <el-button type="primary" @click="submitForm('addForm')">保 存</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      title="选择公区/楼栋"
      :visible.sync="visible"
      width="1040px"
      class="m_tender_dialog"
      :append-to-body="false"
      @close="$refs.buildingForm.resetFields()"
    >
      <el-form ref="buildingForm" :model="buildingForm" label-width="120px">
        <el-form-item label="项目名称" prop="busProjectUuid">{{ buildingForm.projectName||'-' }}</el-form-item>
        <el-form-item label="可选公区/楼栋" prop="list">
          <div v-if="busBuildingList&&busBuildingList.length">
            <el-checkbox
              v-model="buildingForm.checkAll"
              :indeterminate="isIndeterminate"
              @change="handleCheckAllChange"
            >
              全选
            </el-checkbox>
          </div>
          <div v-if="busBuildingList&&busBuildingList.length" class="u_checkbox_bg">
            <el-checkbox-group
              v-model="buildingForm.list"
              @change="handleCheckedChange"
            >
              <el-checkbox
                v-for="(item,index) in busBuildingList"
                :key="index"
                :label="item.busBuildingId"
              >
                {{ item.buildingName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <span v-else>暂无数据</span>
        </el-form-item>
        <el-form-item label="其他标段占用">
          <el-checkbox-group disabled>
            <el-checkbox
              v-for="(item,index) in busBuildingListOff"
              :key="index"
              :label="item.busBuildingId"
            >
              {{ item.buildingName }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" plain @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="addBuilding">保 存</el-button>
      </div>
    </el-dialog>
  </el-card>
<!--  </div>-->
</template>

<script>
// import tree from './tree'
import { project_para_add_update, project_para_building, project_para_info } from '@/api/mainData/tender.js'
export default {
  name: 'MainDataTenderAdd',
  // components: { tree },
  props: {},
  data() {
    return {
      // busProjectUuid: '',
      busProjectUuid: 1,
      addForm: {
        projectParaName: '',
        busBuildingIdList: [],
        busCheckItemList: []
      },
      rules: {
        projectParaName: [
          { required: true, message: '请输入标段名称', trigger: 'change' },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'change'
          }
        ],
        busBuildingIdList: [
          { required: true, message: '请选择楼栋范围', trigger: 'change' }
        ]
      },
      visible: false,
      buildingForm: {
        busProjectUuid: '',
        projectName: '',
        checkAll: false,
        list: []
      },
      busBuildingList: [],
      busBuildingListOff: [],
      isIndeterminate: false
    }
  },
  computed: {
    tagList() {
      if (this.busBuildingList) {
        return this.busBuildingList.filter((item) => {
          return this.addForm.busBuildingIdList.some(
            (item2) => item2 === item.busBuildingId
          )
        })
      } else {
        return []
      }
    }
  },
  watch: {
    'addForm.busBuildingIdList': {
      handler(val, old) {
        if (val) {
          this.$refs.addForm.validateField('busBuildingIdList')
        }
      },
      deep: true
    }
  },
  created() {
    this.busProjectUuid = this.$route.query.busProjectUuid
    this.getBuilding()
  },
  mounted() {},
  methods: {
    // 获取楼栋信息
    getBuilding() {
      if (!this.busProjectUuid) {
        console.log('---------------')
        return false
      }
      project_para_building({ busProjectUuid: this.busProjectUuid }).then((d) => {
        this.busBuildingList = d.data.busBuildingList === null ? null : d.data.busBuildingList.filter((item) => !item.readOnlyFlag)
        this.busBuildingListOff = d.data.busBuildingList === null ? null : d.data.busBuildingList.filter((item) => item.readOnlyFlag)
        this.buildingForm.busProjectUuid = d.data.busProjectUuid
        this.buildingForm.projectName = d.data.projectName
      })
    },
    submitForm(formName) {
      // if (this.addForm.busBuildingIdList.length == 0) {
      //   this.$message.error('楼栋范围不能为空！')
      //   return false
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const load = this.$load()
          project_para_add_update({
            busProjectUuid: this.busProjectUuid,
            ...this.addForm
          }).then((d) => {
            load.close()
            this.$message.success('添加成功')
            this.$router.push({ path: '/mainData/tender' })
          }).catch((e) => {
            this.$errorHandle(e)
            load.close()
          })
        } else {
          this.$message.error('请填写正确的表单！')
          return false
        }
      })
    },
    addBuilding() {
      this.addForm.busBuildingIdList = this.buildingForm.list
      this.visible = false
    },
    showItem() {
      this.buildingForm.list = this.addForm.busBuildingIdList
      this.visible = true
    },
    handleCheckAllChange(val) {
      this.buildingForm.list = val
        ? this.busBuildingList.map((item) => item.busBuildingId)
        : []
      this.isIndeterminate = false
    },
    handleCheckedChange(value) {
      const checkedCount = value.length
      this.buildingForm.checkAll = (checkedCount === this.busBuildingList.length)
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.busBuildingList.length
    },
    closeTag(busBuildingId) {
      this.addForm.busBuildingIdList = this.addForm.busBuildingIdList.filter(
        (item) => {
          return item !== busBuildingId
        }
      )
      this.handleCheckedChange(this.addForm.busBuildingIdList)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
