<template>
  <div class="m-shoppingCart">
    <div class="cart_operation">
      <div class="u-label">
        <div class="go_back_common">
          <div class="go_back_title" @click="goback">
            <span class="go_back_icon" />
            <span class="go_back_txt">返回</span>
          </div>
        </div>
        <div class="s-labelCard">  购物车({{ tableData.length }})</div>
      </div>

      <div class="operation_content">
        <div class="u-yixuan">已选<span class="u-num">{{ checkedList.length }}</span>件</div>
        <div class="u-zongji">总计<span class="s-money">¥{{ totalPrice }}</span>元</div>
        <div class="u-btn pointer">
          <el-button type="primary" :disabled="!checkedList.length>0" @click="getOrder">去下单</el-button>
        </div>
      </div>
    </div>
    <div class="cart_content">
      <div class="content_head">
        <div class="head_check pointer" :class="!tableData.length?'checkforbit':''" @click="handleCheckAllChange()">
          <div v-if="checkAll" class="u-check" />
          <svg-icon v-else icon-class="check-square" />
          <div class="u-checkAll">全选</div>
        </div>
        <div style="margin-left:60px">
          商品信息
        </div>
        <div style="margin-left:446px">
          规格
        </div>
        <div style="margin-left:308px">
          单价
        </div>
        <div style="margin-left:120px">
          操作
        </div>
      </div>

      <div v-for="(item,i) in tableData" :key="i" class="content_item">
        <div class="u-supplierName">{{ item.supplierName }}</div>
        <div class="itemBox pointer" @click="getInfo(item)">
          <div
            v-if="item.invalidFlag===false&&item.outOfStockFlag===false"
            class="item_check pointer"
            @click.stop="getCheck(item,i)"
          >
            <div v-if="!item.check" class="u-check" />
            <svg-icon v-else icon-class="check-square" />
          </div>
          <div v-else class="item_shixiao" />
          <div class="item_img">
            <el-image
              style="width: 100%; height:100%"
              :src="item.goodsCoverImg"
              fit="fit"
            />
          </div>
          <div class="item_title">
            <UmToolTip :content="item.goodsTitle" class="row_2" />
          </div>
          <div
            class="item_guige flex-culumn-align pointer"
            @click.stop="item.invalidFlag===false&&item.outOfStockFlag===false?getSpecification(item):''"
          >
            <div class="flex-between">
              <div class="u-guige">规格</div>
              <div class="flex">
                <div class="font-12" :class="item.skuName!==''?'s-grayColor':''">{{ item.skuName || '请选择' }}</div>
                <i
                  v-if="item.flag===false"
                  class="el-icon-arrow-down pointer ml-10"
                />
                <i v-else class="el-icon-arrow-up ml-10" />
              </div>
            </div>
            <div v-if="item.flag===true" class="item_guige_skuList">
              <div v-for="(ite,j) in item.skuList" :key="j" :span="8">
                <div
                  class="u-skuName"
                  :class="item.skuUuid===ite.skuUuid?'s_blueColor':''"
                  @click.stop="getTags(i,ite,item)"
                >
                  <UmToolTip :content="ite.skuName" class="row_1" />
                </div>
              </div>
            </div>
          </div>
          <div class="item_price">
            ¥{{ item.skuPrice }}
          </div>
          <div class="item_operation">
            <div v-if="item.collectionFlag" class="pointer s-blue" @click.stop="getCollection(item,i)">
              <svg-icon icon-class="unCollection" style="font-size:16px" />
              取消收藏
            </div>
            <div v-else class="pointer" @click.s.stop="getCollection(item,i)">
              <svg-icon icon-class="collection" style="font-size:16px" />
              收藏
            </div>
            <div class="pointer u-del" @click.stop="getDel(item,i)">
              <svg-icon icon-class="delCart" style="font-size:16px" />
              删除
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cart_footer">
      <div v-if="imgFlag" ref="imgList" class="footer_carousel">
        <div class="imgList">
          <svg-icon icon-class="leftArrow" class="u-arrow left" @click="getLeft()" />
          <div v-for="(item,i) in imgList" :key="i" class="img-item">
            <div class="u-img">
              <el-image :src="item.goodsCoverImg" style="width:72px;height:72px" />
            </div>
            <div class="row_1 u-goodsTitle">{{ item.goodsTitle }}</div>
          </div>
          <svg-icon icon-class="rightArrow" class="u-arrow right" @click="getRight()" />
        </div>
      </div>
      <div class="footer_check pointer" :class="!tableData.length?'checkforbit':''" @click="handleCheckAllChange()">
        <div v-if="checkAll" class="u-check" />
        <svg-icon v-else icon-class="check-square" />
        <div class="u-checkAll">全选</div>
      </div>
      <div class="footer_operation">
        <div class="operation-del pointer" @click="getBatchDelCart(1)"><div class="delCart" />删除</div>
        <div class="operation-add pointer" @click="getbBatchCollection()"> <div class="collection" />加入收藏</div>
        <div class="operation-eliminate pointer" @click="getBatchDelCart(2)">清除失效产品</div>
      </div>
      <div class="operation_content">
        <div class="u-yixuan ">
          已选<span class="u-num">{{ checkedList.length }}</span>件
          <svg-icon v-if="imgFlag" icon-class="topArrow" class="pointer" @click="imgFlag=false" />
          <svg-icon v-else icon-class="bottomArrow" class="pointer" @click.stop="imgFlag=true" />
        </div>
        <div class="u-zongji">总计<span class="s-money">¥{{ totalPrice }}</span>元</div>
        <!--        <div class="u-btn pointer" @click="getOrder">-->
        <!--          去下单-->
        <!--        </div>-->
        <div class="u-btn pointer">
          <el-button type="primary" :disabled="!checkedList.length>0" @click="getOrder">去下单</el-button>
        </div>
      </div>
    </div>
    <order :order-flag="orderFlag" :order-list="orderList" @changeShow="changeShow" />
  </div>
</template>
<script>
import { shoppingCartList, collection, cancelFavorites, delCartItem, confirmOrder, updateShopping, batchCollection, batchDelCart } from '@/api/shoppingCart/index'
import { math } from '@/utils/math.js'
import order from './component/order.vue'
export default {
  name: 'ShoppingCart',
  components: { order },
  data() {
    return {
      money: 0,
      tableData: [],
      checkAll: true,
      isIndeterminate: true,
      checkedList: [],
      typeList: {
        1023901: 2003010101,
        1023902: 2003010102,
        1023903: 2003010103
      },
      orderFlag: false,
      orderList: [],
      imgFlag: false,
      step: 1,
      imgList: []
    }
  },
  computed: {
    // 计算总价
    totalPrice() {
      let sum = 0
      this.checkedList.forEach(item => {
        const a = Number(sum)
        const b = Number(item.skuPrice)
        sum = math.add(a, b)
      })
      return sum || 0
    }
  },
  created() {
    this.getList()
    document.body.addEventListener('click', this.bodyClickhandler)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.bodyClickhandler)
  },
  methods: {
    bodyClickhandler(event) {
      const _target = event.target
      if (this.$refs.imgList) {
        if (this.$refs.imgList.contains(_target)) {
          //
        } else {
          this.imgFlag = false
          this.step = 1
        }
      }
    },
    // 跳转详情
    getInfo(item) {
      if (item.businessType === 1023901) {
        this.$router.push({ path: '/cloudMarket/info', query: { uuid: item.spuUuid, tabType: 0, dataType: 10239001 }})
      } else if (item.businessType == 1023902) {
        this.$router.push({ path: '/cloudMarket/info', query: { uuid: item.spuUuid, tabType: 0, dataType: 10239002 }})
      } else if (item.businessType == 1023903) {
        this.$router.push({ path: '/cloudMarket/info', query: { uuid: item.spuUuid, tabType: 0, dataType: 10239003 }})
      }
    },
    // 获取列表
    getList() {
      shoppingCartList().then(res => {
        res.data.list.forEach(item => {
          item.check = false
          item.flag = false
        })
        this.tableData = res.data.list
        if (this.checkAll === false) {
          if (this.tableData.length > 0) {
            this.tableData.forEach(item => { item.check = true })
            this.checkedList = this.tableData.filter(item => { return item.invalidFlag === false && item.outOfStockFlag === false })
            this.imgList = this.checkedList.filter((item, i) => {
              return i >= 0 && i < 13
            })
          } else {
            this.checkedList = []
            this.imgList = []
            this.checkAll = true
          }
        } else {
          this.tableData.forEach((item, i) => {
            this.checkedList.forEach(ite => {
              if (item.spuUuid.indexOf(ite.spuUuid) != -1) {
                item.check = true
              }
            })
          })
          this.checkedList = this.tableData.filter(item => { return item.invalidFlag === false && item.outOfStockFlag === false && item.check == true })
        }
      })
    },
    goback() {
      this.$router.replace('/cloudMarket')
    },
    // 全选
    handleCheckAllChange() {
      if (!this.tableData.length) return
      this.checkAll = !this.checkAll
      this.tableData.forEach(item => {
        if (this.checkAll === false) {
          item.check = true
        } else {
          item.check = false
        }
      })
      if (this.checkAll === false) {
        this.checkedList = this.tableData.filter(item => {
          return item.invalidFlag === false && item.outOfStockFlag === false
        })
      } else {
        this.checkedList = []
      }
      if (this.checkedList.length == 0) {
        this.step = 1
      }
      this.imgList = this.checkedList.filter((item, i) => {
        return i >= 0 && i < 13
      })
    },
    // 选择商品
    getCheck(item, i) {
      if (this.checkAll === false) {
        this.checkAll = true
        item.check = !(item.check)
        const arr = this.tableData.filter(item => {
          return item.check === true && item.invalidFlag === false && item.outOfStockFlag === false
        })
        this.checkedList = arr
      } else {
        item.check = !(item.check)
        const arr = this.tableData.filter(item => {
          return item.invalidFlag === false && item.outOfStockFlag === false
        })
        this.checkedList = this.tableData.filter(item => {
          return item.check === true && item.invalidFlag === false && item.outOfStockFlag === false
        })
        if (this.checkedList.length === arr.length) {
          this.checkAll = false
        }
      }
      this.imgList = this.checkedList.filter((item, i) => {
        return i >= 0 && i < 13
      })
    },
    getSpecification(item) {
      if (!item.flag) {
        this.tableData.forEach(items => {
          items.flag = false
        })
      }
      item.flag = !(item.flag)
    },
    // 选择规格
    getTags(index, item, val) {
      val.flag = false
      if (item.feesType === 3) {
        this.$message.warning('当前规格为价格面议，不支持在线购买，请咨询销售人员！')
        return false
      }
      if (item.skuUuid == val.skuUuid) return
      updateShopping({ oldSkuUuid: this.tableData[index].skuUuid, newSkuUuid: item.skuUuid }).then(res => {
        this.$message.success('修改成功')
      })
      this.tableData[index].skuUuid = item.skuUuid
      this.tableData[index].skuName = item.skuName
      this.tableData[index].skuPrice = item.skuPrice
    },
    // 收藏
    getCollection(item, i) {
      const businessType = this.typeList[item.businessType]
      if (item.collectionFlag === false) {
        collection({
          businessType: businessType,
          businessUuid: item.spuUuid,
          businessName: item.goodsTitle
        }).then(res => {
          item.collectionFlag = true
        })
      } else if (item.collectionFlag === true) {
        cancelFavorites({ businessType: businessType, businessUuid: item.spuUuid, type: '1' }).then(res => {
          item.collectionFlag = false
        }).catch(e => {
          this.$errorHandle(e)
        })
      }
    },
    // 删除购物车商品
    getDel(item, index) {
      this.$confirm('是否确认删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        delCartItem({ skuUuid: item.skuUuid }).then(res => {
          this.$message.success('删除成功！')
          this.getList()
        }).catch(e => {
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message('取消删除！')
      })
    },
    // 下单
    getOrder() {
      if (this.checkedList.length <= 0) {
        this.$message.error('您还没有选择商品')
      } else {
        const arr = this.checkedList.map(item => {
          return {
            skuUuid: item.skuUuid,
            businessType: item.businessType
          }
        })
        confirmOrder({ skuList: arr }).then(res => {
          res.data.orders.forEach((item) => {
            item.flag = false
            item.nums = 1
            item.organScopeUuids = []
          })
          this.orderFlag = true
          this.orderList = res.data.orders.sort((a, b) => {
            return (a.businessType - b.businessType)
          })
        })
      }
    },
    changeShow(data) {
      if (data === 'false') {
        this.orderFlag = false
        // this.getList()
        // this.checkAll = true
        // this.checkedList = []
      } else {
        this.orderFlag = true
      }
    },
    // 批量收藏
    getbBatchCollection() {
      if (this.checkedList.length <= 0) {
        this.$message.error('请至少选择一项商品')
      } else {
        const arr = this.checkedList.map(item => {
          return {
            businessType: this.typeList[item.businessType],
            businessUuid: item.spuUuid,
            businessName: item.goodsTitle
          }
        })
        batchCollection({ list: arr }).then(res => {
          this.$message.success('收藏成功')
          this.getList()
          this.checkAll = true
        }).catch(e => {
          this.$errorHandle(e)
        })
      }
    },
    // 批量删除
    getBatchDelCart(type) {
      if (this.checkedList.length <= 0 && type === 1) {
        this.$message.error('请至少选择一项商品')
      } else {
        let arr = []
        if (type === 1) {
          arr = this.checkedList.map(item => {
            return item.skuUuid
          })
        } else if (type === 2) {
          // 失效商品
          arr = this.tableData.filter(item => {
            return item.invalidFlag === true || item.outOfStockFlag === true
          })
          arr = arr.map(item => {
            return item.skuUuid
          })
        }
        let title = ''
        if (type === 1) {
          title = '是否确认删除?'
        } else if (type === 2) {
          title = '是否确认清除失效产品?'
        }
        if (type === 2 && arr.length == 0) {
          this.$message.error('暂无失效商品')
        } else {
          this.$confirm(title, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            center: true
          }).then(() => {
            batchDelCart({ skuList: arr }).then(res => {
              if (type === 1) {
                this.checkAll = true
                this.checkedList = []
              }
              this.$message.success('删除成功')
              this.getList()
            }).catch(e => {
              this.$errorHandle(e)
            })
          })
        }
      }
    },
    getRight() {
      const num = Math.ceil(this.checkedList.length / 13)
      if (this.step < num) {
        this.step += 1
        this.imgList = this.checkedList.filter((item, i) => {
          return ((this.step - 1) * 13) < (i + 1) && (i + 1) <= this.step * 13
        })
      }
    },
    getLeft() {
      if (this.step > 1) {
        this.step -= 1
        this.imgList = this.checkedList.filter((item, i) => {
          return ((this.step - 1) * 13) < (i + 1) && (i + 1) <= this.step * 13
        })
      }
    }
  }
}
</script>

