<template>
  <div
    class="m-applicationBox pointer"
    @click="getInfo(form)"
  >
    <div class="applicationBox_leftBox">
      <el-image
        class="f-bord-rad15"
        style="width: 100%; height: 100%"
        :src="form.appIconUrl"
        fit="fit"
      />
    </div>
    <div class="applicationBox_rightBox">
      <div>
        <div class="flex-between">
          <UmToolTip :content="form.appName" class="applicationBox_rightBox_hname" />
          <div class="applicationBox_rightBox_install">{{ form.installNum ||0 }}安装</div>
        </div>

        <UmToolTip :content="form.appDescription" class="applicationBox_rightBox_descrips" />
      </div>
      <div class="flex-column-center">
        <span v-if="form.appPrice&&form.appPrice>0" class="applicationBox_rightBox_money">
<!--          <span class="applicationBox_rightBox_money_Symbol">-->
<!--            ¥-->
<!--          </span>-->
<!--          <um-money :value="form.appPrice" />-->
          <!--          <span v-if="form.specificationsCount>1" style="font-size: 12px;margin-top: 5px;">起</span>-->
          <!--          <span v-if="form.guidePrice!==null && form.guidePrice!=='0'" class="guidePrice">-->
          <!--            <span class="applicationBox_rightBox_money_Symbol">￥</span>{{ form.guidePrice }}</span>-->
        </span>

        <span  class="applicationBox_rightBox_money applicationBox_rightBox_money_font">
          <!--          <span v-if="form.specificationsCount>1">0元<span style="font-size: 12px;margin-top: 5px; margin-right: 5px">起</span></span>-->

          <!--          <span v-if="form.appPrice==='0.00'|| form.appPrice===null">{{ form.saleText }}</span>-->
          <!--          <span v-if="form.saleText ===null || form.saleText ==='' ">价格面议</span>-->
          <!--免费-->
          <span v-if="form.feesType===1">{{ form.saleText||'免费' }}</span>
          <!-- 收费-->
          <span v-if="form.feesType===2" class="row_one mw-96">{{ form.saleText|| '¥'+form.appPrice }}</span>
          <!-- 价格面议-->
          <span v-if="form.feesType===3">{{ form.saleText||'价格面议' }}</span>

          <!--          <span v-if="form.guidePrice!==null && form.guidePrice!=='0'" class="guidePrice">-->
          <!--            <span class="applicationBox_rightBox_money_Symbol">￥</span>{{ form.guidePrice }}</span>-->
        </span>
        <div class="flex-column-center flex-shrink-0 applicationBox_rightBox_other">
          <div v-if="form.activityTag" class="m_other_lpic">
            {{ form.activityTag }}
          </div>
          <div v-if="form.guidePrice>0" class="m_other_delprice" :class="[3>5?'g-text-line-through':'']">
            <span class="u_content f-ml-2">标准价</span><span class="u_delete">¥{{ form.guidePrice }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: Number,
      default: null
    },
    tabType: {
      type: [String, Number],
      default: 1
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataType:
        {
          2: 10239001,
          3: 10239004,
          4: 10239002,
          5: 10239003
        }

    }
  },
  methods: {
    getInfo(item) {
      this.$router.push({ path: '/cloudMarket/info', query: { uuid: item.appUuid, tabType: this.tabType, dataType: this.dataType[this.type] }})
      // if (this.type === 2) {
      //   this.$router.push({ path: '/dashboard/cloudMarket/appInfo', query: { appUuid: item.appUuid, tabType: this.tabType }})
      // } else if (this.type == 3) {
      //   this.$router.push({ path: '/dashboard/cloudMarket/solution/info', query: { appUuid: item.appUuid, tabType: this.tabType }})
      // } else if (this.type == 4) {
      //   this.$router.push({ path: '/dashboard/cloudMarket/serviceCentre/info', query: { appUuid: item.appUuid, tabType: this.tabType }})
      // } else if (this.type == 5) {
      //   this.$router.push({ path: '/dashboard/cloudMarket/hardwareMall/info', query: { appUuid: item.appUuid, tabType: this.tabType }})
      // }
    }
  }
}
</script>

