import { render, staticRenderFns } from "./organizationInfo.vue?vue&type=template&id=3a370b16&scoped=true&"
import script from "./organizationInfo.vue?vue&type=script&lang=js&"
export * from "./organizationInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a370b16",
  null
  
)

export default component.exports