<template>
  <EnterpriseLayout>
    <div class="m_enterprise_importexport">
      <div class="m_importexport_head" @click="back">
        <span class="m_importexport_headicon" />
        <span class="m_importexport_headtxt">返回</span>
      </div>
      <div class="m_importexport_lines" />
      <div class="m_importexport_inhead">
        <div class="m_importexport_inhead-l" :class="{m_importexport_focuss:check}" @click="check=true">批量导入</div>
        <div class="m_importexport_inhead-r" :class="{m_importexport_focuss:!check}" @click="check=false">批量导出</div>
      </div>
      <div class="m_importexport_contentbody" :class="{m_importexport_contentbodyl:!check}">
        <div v-if="check" class="m_importexport_daoru">
          <div class="m_importexport_top">
            <div class="m_importexport_ttext">点击下载模板通讯录模版，填写成员信息～</div>
            <div class="m_importexport_downmoban" @click="downtemplate">
              <span class="m_importexport_downicon" />
              <span class="m_importexport_doptxt">下载模板</span>
            </div>
          </div>
          <div class="m_importexport_uoload">
            <div class="m_importexport_img" />
            <div class="m_importexport_thead">点击选择文件或将文件拖拽到这里上传</div>
            <div class="m_importexport_tcontent">文件后缀名必须为xls或xlsx (即Excel格式),文件大小不得大于10M</div>
          </div>
        </div>
        <div v-else class="m_importexport_daochu">
          <el-button type="primary" @click="exportexcel">确认导出</el-button>
        </div>
      </div>
      <div class="m_importexport_btn">
        <el-button v-if="check" type="primary" @click="importexcel">确认导入</el-button>
      </div>
    </div>
  </EnterpriseLayout>
</template>

<script>
import { add_role_user } from '@/api/enterprise'

export default {
  name: 'EnterpriseAddressImportExport',
  components: {},
  props: {},
  data() {
    return {
      check: true
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 返回
    back() {
      this.$router.replace('/enterprise/address')
    },
    downtemplate() {
      console.log('下载模板')
    },
    importexcel() {
      console.log('导入数据')
      this.$importFile({
        limit: 1, // 上传限制
        size: 2000, // 文件大小 kb
        checkBtnVisible: false, // 显示选择导入类型
        progressParamData: {
          modelId: this.modelId
        },
        progressBusinessCode: 1025401,
        accept: '.xls,.xlsx'
      }).then(() => {
      }).catch((e) => {
        this.$errorHandle(e)
      })
    },
    exportexcel() {
      console.log('导出数据')
      this.$selectUser({
        searchTip: '请输入导出范围',
        title: '选择导出范围',
        value: [], // 用来回显使用
        limit: 1,
        type: 1,
        authType: 0,
        userCountFlag: 0,
        changeHandler: (result, showList) => {
          console.log('resultresult', result) // key 值
          console.log('showListshowList', showList) // 回显 的对象
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

