<template>
  <div>
    <um-bus-user
      ref="user"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #user="{data}">
        <div class="user-box">
          <div v-for="(item,idx) in data" :key="idx" class="user-item">
            <img v-if="item.icon" class="avatar" :src="item.icon" alt="">
            <div v-else class="avatar_tmp" />
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </template>
    </um-bus-user>
  </div>
</template>

<script>
export default {
  name: 'EchoUser',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.user-box{
  display: flex;
  .user-item{
    margin-right: 40px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    .avatar{
      border-radius: 4px;
      width: 52px;
      height: 52px;
    }
    .avatar_tmp{
      width: 52px;
      height: 52px;
      background: #E4E8EB;
      border-radius: 4px;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 24px;
        height: 24px;
        background: url("~@/assets/dashboard/icon-user-default.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .name{
      max-width: 4em;
      margin-left: 14px;
      font-size: 12px;
      color: #6C7B96;
    }
  }
}
</style>
