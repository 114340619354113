<template>
  <div v-if="url" class="um-url">
    <div v-html="content " />
  </div>
  <div v-else class="um-url">
    暂无内容
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'UmUrl',
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      source: null,
      content: ''
    }
  },
  watch: {
    url: {
      handler(val) {
        if (val) {
          this.getUrl(val)
        }
      },
      immediate: true
    }
  },
  created() {

  },
  methods: {
    getUrl(url) {
      this.source && this.source.cancel()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.source = source
      const request = axios.create()
      request({
        url: url,
        type: 'GET',
        cancelToken: source.token,
        responseType: 'text'
      })
        .then((d) => {
          if (d.status === 200) {
            this.content = d.data
          }
        })
        .catch((e) => {
          this.content = '加载失败'
        })
    }
  }
}
</script>
<style scoped lang="scss">
.um-url{
  line-height: 1.6; //add 增加默认行高
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  text-align: left;
  color: #666;
  min-height: 50px;
  word-break: break-all;
  img{
    width: auto !important;
    height: auto !important;
    max-width: 100%;
  }
  p{
    margin-bottom: 2em;
  }
  p,div{
    width: auto !important;
    height: auto !important;
  }
}
</style>
