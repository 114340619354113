// 页面跳转方法
import router from '@/router'

// 待办查看详情跳转 方法
export function jumpUrl(pcUrl) {
  console.log(process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : location.origin + '-----')
  const isMyself = pcUrl.includes(process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : location.origin) // 是否是三方
  if (isMyself) {
    const jumpUrl = pcUrl.split(process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : location.origin)
    console.log(jumpUrl)
    this.$router.push(jumpUrl[1])
  } else {
    // 1、https 是需要在自己的窗口打开
    // 2、http  window.open
    // 白名单的功能  todo 待定

    if (/^(https:)/.test(pcUrl)) {
      router.push({
        path: '/dashboard/web',
        query: {
          pcUrl
        }
      })
    }
    if (/^(http:)/.test(pcUrl)) {
      window.open(pcUrl)
    }
  }
}

