<template>
  <!--    <el-scrollbar ref="scrollContainer" :vertical="false" class="scroll-container" @wheel.native.prevent="handleScroll">-->
  <!--      <slot />-->
  <!--    </el-scrollbar>-->
  <div class="m-scroll-pane">
    <i v-if="isOver" class="el-icon-caret-left m-pane-btn" @click="btnClick('left')" />
    <div ref="wrap" class="m-scroll-pane-wrap">
      <div ref="view" class="m-scroll-pane-view">
        <slot />
      </div>
    </div>
    <i v-if="isOver" class="el-icon-caret-right m-pane-btn" @click="btnClick('right')" />
  </div>
</template>

<script>
const tagAndTagSpacing = 4 // tagAndTagSpacing

export default {
  name: 'ScrollPane',
  data() {
    return {
      left: 0,
      // 内容是否超出宽度
      isOver: false
    }
  },
  computed: {
    scrollWrapper() {
      return this.$refs.wrap
    }
  },
  mounted() {
    this.getOverStatus()
    this.scrollWrapper.addEventListener('scroll', this.emitScroll, true)
  },
  updated() {
    this.getOverStatus()
  },
  beforeDestroy() {
    this.scrollWrapper.removeEventListener('scroll', this.emitScroll)
  },
  methods: {
    btnClick(type) {
      const left = this.scrollWrapper.scrollLeft
      // this.scrollWrapper.scrollLeft = type == 'left' ? (left - 180) : (left + 180)
      this.scrollWrapper.scrollTo({
        left: type == 'left' ? (left - 180) : (left + 180),
        behavior: 'smooth'
      })
    },
    handleScroll(e) {
      const eventDelta = e.wheelDelta || -e.deltaY * 40
      const $scrollWrapper = this.scrollWrapper
      $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4
    },
    emitScroll() {
      this.$emit('scroll')
    },
    getOverStatus() {
      this.isOver = this.$refs.view.scrollWidth > this.scrollWrapper.clientWidth
    },
    moveToTarget(currentTag) {
      const $container = this.scrollWrapper
      const $containerWidth = $container.offsetWidth
      const $scrollWrapper = this.$refs.view
      const tagList = this.$parent.$refs.tag

      let firstTag = null
      let lastTag = null

      // find first tag and last tag
      if (tagList.length > 0) {
        firstTag = tagList[0]
        lastTag = tagList[tagList.length - 1]
      }

      if (firstTag === currentTag) {
        $scrollWrapper.scrollLeft = 0
      } else if (lastTag === currentTag) {
        $scrollWrapper.scrollLeft = $scrollWrapper.scrollWidth - $containerWidth
      } else {
        // find preTag and nextTag
        const currentIndex = tagList.findIndex(item => item === currentTag)
        const prevTag = tagList[currentIndex - 1]
        const nextTag = tagList[currentIndex + 1]

        // the tag's offsetLeft after of nextTag
        const afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagAndTagSpacing

        // the tag's offsetLeft before of prevTag
        const beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagAndTagSpacing

        if (afterNextTagOffsetLeft > $scrollWrapper.scrollLeft + $containerWidth) {
          $scrollWrapper.scrollLeft = afterNextTagOffsetLeft - $containerWidth
        } else if (beforePrevTagOffsetLeft < $scrollWrapper.scrollLeft) {
          $scrollWrapper.scrollLeft = beforePrevTagOffsetLeft
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.m-scroll-pane {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .m-pane-btn {
    flex-shrink: 0;
    font-size: 26px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #8e8e8e;
    &:hover {
      transform: scale(1.2);
      color: #333;
    }
  }
  .m-scroll-pane-wrap {
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.scroll-container {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  width: 100%;
  ::v-deep {
    .el-scrollbar__bar {
      bottom: 0px;
    }
    .el-scrollbar__wrap {
      overflow-y: hidden !important;
      overflow-x: scroll !important;
      //height: 49px;
    }
  }
}
</style>
