<template>
  <div class="m-contractordata min_h_100">
    <div class="go_back_common">
      <div class="go_back_title" @click="$router.replace('/administration')">
        <span class="go_back_icon" />
        <span class="go_back_txt">返回</span>
      </div>
      <div class="go_back_lines" />
    </div>
    <div class="contractor_seachHead">
      <div class="seachHead_items">
        <div class="items_title">承建商全称/简称：</div>
        <div>
          <el-input
            v-model="searchParam.contractorName"
            placeholder="请输入承建商全称/简称"
            style="width: 100%"
            clearable
          />
        </div>
      </div>
      <div class="seachHead_items">
        <div class="items_title">负责人/联系人：</div>
        <div>
          <el-input
            v-model="searchParam.responsibleContactName"
            placeholder="请输入姓名"
            style="width: 100%"
            clearable
          />
        </div>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-search" @click="getList">搜 索</el-button>
        <el-button
          v-permission="['112040903']"
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="$router.push('/mainData/contractor/add')"
        >
          新增承建商
        </el-button>
      </div>
    </div>
    <div v-loading="tableLoading" class="contractorTable mar_top20">
      <el-table :data="searchData" row-key="xh" style="width: 100%">
        <el-table-column prop="xh" label="序号" width="80px" />
        <el-table-column
          prop="contractorFullName"
          label="承建商全称/简称"
        >
          <template slot-scope="scope">
            <div>
              <UmToolTip :content="scope.row.contractorFullName" class="row_2" />
            </div>
            <div style="color: #999; fon-size: 12px">
              <UmToolTip :content="scope.row.contractorName" class="row_2" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contractorResponsiblePerson" label="负责人">
          <template slot-scope="scope">
            <span>
              <UmToolTip :content="scope.row.contractorResponsiblePerson" class="row_2" />
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="contractorContact" label="联系人/联系电话">
          <template slot-scope="scope">
            <div v-if="!scope.row.contractorMobile && !scope.row.contractorContact "> -</div>
            <template v-else>
              <div>
                <UmToolTip :content="scope.row.contractorContact" class="row_2" />
              </div>
              <div style="color: #999; fon-size: 12px">
                {{ scope.row.contractorMobile }}
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-button v-permission="['112040905']" type="text" @click="showItem(scope.row)">详情</el-button>
            <el-button v-permission="['112040904']" type="text" class="s-redColor" @click="deleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="u-paginations">
      <pagination
        :background="false"
        :total="searchTotal"
        :page.sync="searchParam.page.pageNum"
        :limit.sync="searchParam.page.pageSize"
        :layout="'total, sizes, prev, pager, next, jumper'"
        @pagination="getList('page')"
      />
    </div>
  </div>
</template>

<script>
import { contractor_list, contractor_delete } from '@/api/mainData/contractor'
export default {
  name: 'MainDataContractor',
  components: {},
  props: {},
  data() {
    return {
      searchParam: {
        // contractorTypeCode: '20252010',
        contractorName: '',
        responsibleContactName: '',
        page: {
          pageNum: 1,
          pageSize: 10
        }
      },
      searchTotal: 0,
      searchData: [],
      tableLoading: false,
      typeList: [{
        contractorTypeName: '维保单位',
        contractorTypeCode: '20252010'
      },
      {
        contractorTypeName: '第三方维保单位',
        contractorTypeCode: '20252011'
      }
      ] // 承建商类别
    }
  },
  computed: {},
  watch: {},
  created() {
    // this.getParam()
    this.getList()
  },
  mounted() {},
  activated() {
    console.log('激活了~~')
    this.getList('page')
  },
  deactivated() {
    // 不是子路由的话做初始化
    if (
      this.$route.path.indexOf('/mainData/contractor') === -1
    ) {
      this.$store.dispatch('tagsView/delView', {
        name: 'MainDataContractor',
        path: '/mainData/contractor'
      })
    }
  },
  methods: {
    // getParam() {
    //   busContractorParam().then((d) => {
    //     this.typeList = d.data
    //   })
    // },
    getList(state = '') {
      if (state !== 'page') {
        this.searchParam.page.pageNum = 1
        // this.searchParam.page.pageSize = 10
      }
      this.tableLoading = true
      contractor_list({
        ...this.searchParam
      })
        .then((d) => {
          this.searchData = d.data.list
          this.searchTotal = d.data.page.total
          this.tableLoading = false
        })
        .catch((e) => {
          this.searchData = []
          this.tableLoading = false
          this.$errorHandle(e)
        })
    },
    deleteItem(row) {
      this.$confirm(`是否删除${row.contractorFullName}？`, '提示', {
        type: 'warning'
      }).then(() => {
        // 缺少接口
        const load = this.$load()
        contractor_delete({
          contractorUuid: row.contractorUuid
        }).then((d) => {
          this.$message.success('删除成功！')
          load.close()
          this.getList()
        }).catch((e) => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message.info('已取消删除！')
      })
    },
    showItem(row) {
      this.$router.push({
        path: '/mainData/contractor/info',
        query: { contractorUuid: row.contractorUuid }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
