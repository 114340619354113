import request from '@/utils/request'

// 获取楼栋列表(下拉框)
export function query_building_list(data) {
  return request({
    url: '/api/v1/data/p/building/query_building_list',
    method: 'post',
    data
  })
}

// 【爬楼表】查询房间列表
export function get_room_list(params) {
  return request({
    url: '/api/v1/data/room/get_room_list',
    method: 'get',
    params
  })
}

// 【爬楼表】查询楼层属性
export function get_floor_type(params) {
  return request({
    url: '/api/v1/data/room/query/floor_type',
    method: 'get',
    params
  })
}

// 【爬楼表】保存房间信息
export function save_room_submit(data) {
  return request({
    url: '/api/v1/data/room/save_room_submit',
    method: 'post',
    data
  })
}

// 获取户型列表
export function house_type_list(data) {
  return request({
    url: '/api/v1/data/house_type/list',
    method: 'post',
    data
  })
}

// 【爬楼表】保存楼层属性
export function submit_house_type(data) {
  return request({
    url: '/api/v1/data/room/save/floor_type/submit',
    method: 'post',
    data
  })
}

