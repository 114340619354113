<template>
  <div
    class="applicationNew pointer"
    @click="getInfo(form)"
  >
    <div class="applicationNew_leftBox">
      <el-image
        class="f-bord-rad15"
        style="width: 100%; height: 100%"
        :src="form.appIconUrl"
        fit="fit"
      />
    </div>
    <div class="applicationNew_rightBox">
      <div class="applicationNew_rightBoxTop">
        <img v-if="index===0" src="~@/assets/images/huangguan.png" class="rightBoxTop">
        <img v-if="index===1" src="~@/assets/images/ying.png" class="rightBoxTop">
        <img v-if="index===2" src="~@/assets/images/tong.png" class="rightBoxTop">
        <div v-if="index>=3" class="rightBoxTop">
          <span>{{ index + 1 }}</span>
        </div>
        <UmToolTip class="applicationNew_rightBox_hname" :content="form.appName" />
        <div class="flex-column-center" style="flex:1;justify-content: end;">
          <div class="flex-column-center flex-shrink-0 applicationBox_rightBox_other1">
            <div v-if="form.guidePrice>0" class="m_other_delprice" :class="[3>5?'g-text-line-through':'']">
              <span class="u_content">标准价</span>
              <span class="u_delete">¥{{ form.guidePrice }}</span>
            </div>
            <div v-if="form.activityTag" class="m_other_lpic">
              {{ form.activityTag }}
            </div>
          </div>
          <span v-if="form.appPrice&&form.appPrice>0" class="applicationNew_rightBoxTop_money flex-shrink-0">
            <span class="applicationNew_rightBoxTop_Symbol">
              ¥
            </span>
            <um-money :value="form.appPrice" />
            <!--            <span v-if="form.specificationsCount>1" style="font-size: 12px;margin-top: 5px">起</span>-->
          </span>
          <span v-else class="applicationNew_rightBoxTop_money flex-shrink-0  applicationNew_rightBoxTop_money_font">
            <!--            <span v-if="form.specificationsCount>1">0元 <span style="font-size: 12px;margin-top: 5px">起</span></span>-->
            <!--            <span v-if="form.guidePrice!==null" class="guidePrice">￥{{ form.guidePrice }}</span>-->

            <!--            <span v-if="form.saleText ===null || form.saleText ==='' ">-->
            <!--              价格面议-->
            <!--            </span>-->
            <!--            <span v-if="form.appPrice==='0.00'||form.appPrice===null">{{ form.saleText }}</span>-->

            <!--免费-->
            <span v-if="form.feesType===1">{{ form.saleText||'免费' }}</span>
            <!-- 收费-->
            <span v-if="form.feesType===2">{{ form.saleText|| '¥'+form.appPrice }}</span>
            <!-- 价格面议-->
            <span v-if="form.feesType===3">{{ form.saleText||'价格面议' }}</span>
          </span>
        </div>
      </div>
      <div class="applicationNew_rightBoxBtm">
        <UmToolTip :content="form.appDescription" class="applicationNew_rightBoxBtm_dis" />
        <div class="text">
          <!--          <span>{{ form.saleNum }}  购买</span>-->
          <span>{{ form.installNum ||0 }} 安装</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: Number,
      default: null
    },
    tabType: {
      type: [String, Number],
      default: 1
    },
    form: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dataType:
        {
          2: 10239001,
          3: 10239004,
          4: 10239002,
          5: 10239003
        }

    }
  },
  methods: {
    getInfo(item) {
      this.$router.push({ path: '/cloudMarket/info', query: { uuid: item.appUuid, tabType: this.tabType, dataType: this.dataType[this.type] }})
    }
  }
}
</script>

