<template>
  <div class="m_enterprise_siteinfo" style="height:480px">
    <!--    <el-radio-group v-model="tree_id">-->
    <!--      <el-radio-button v-for="(item,index) in treeData" :key="index" :label="index">{{ item.sysModuleName }}</el-radio-button>-->
    <!--    </el-radio-group>-->
    <!--    :data="[item]" 从父级开始 上面的 radio button 显示父级名称-->
    <!--    :data="item.children" 从孩子开始-->
    <el-scrollbar style="height: 100%">
      <el-tree
        v-for="(item,index) in treeData"
        :ref="`tree_${index}`"
        :key="index"
        :data="[item]"
        show-checkbox
        node-key="sysModuleCode"
        :props="defaultProps"
        :default-checked-keys="checkList[index]"
        style="margin-top:10px"
        check-on-click-node
      />
      <el-row>
        <el-col :span="24" class="m_enterprise_button">
          <el-button v-permission="['112040913']" type="primary" @click="submit">保 存</el-button>
        </el-col>
      </el-row>
    </el-scrollbar>
  </div>
</template>
<script>
import {
  sysmodule_checked_tree,
  sysmodule_update_submit
} from '@/api/manage.js'
export default {
  name: 'SiteInfo',
  props: {
    orgUserUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    // const _this = this
    return {
      // orgUserId: _this.$route.query.orgUserId,
      tree_id: 0,
      treeData: [],
      checkList: [],
      defaultProps: {
        children: 'children',
        label: 'sysModuleName',
        disabled: (data, node) => {
          return data.readOnly
        }
      }
    }
  },
  computed: {
    treeDataFilter() {
      if (this.treeData.length > 0) {
        return this.treeData[this.tree_id].children
      } else {
        return []
      }
    }
  },
  watch: {
  },
  created() {
    this.getTree()
  },
  methods: {
    init() {
      const list = []
      this.checkList = []
      function deep(list, newList) {
        list.forEach(item => {
          if (item.children && item.children.length > 0) {
            deep(item.children, newList)
          } else {
            if (item.checked) {
              newList.push(item.sysModuleCode)
            }
          }
        })
      }
      this.treeData.forEach(item => {
        const child = []
        if (item.children === undefined) {
          //
        } else {
          deep(item.children, child)
        }
        list.push(child)
      })
      this.checkList = list
      console.log(list)
    },
    getTree() {
      const load = this.$load()
      sysmodule_checked_tree({
        checkedUserUuid: this.orgUserUuid
      }).then(d => {
        this.treeData = d.data
        this.init()
        load.close()
      }).catch(e => {
        this.treeData = []
        this.checkList = []
        this.$errorHandle(e)
        load.close()
      })
    },
    submit() {
      const length = this.treeData.length
      const list = []
      for (let i = 0; i < length; i++) {
        let child = []
        child = child.concat(this.$refs[`tree_${i}`][0].getCheckedNodes().filter(item => {
          return !item.readOnly
        }))

        child = child.concat(this.$refs[`tree_${i}`][0].getHalfCheckedNodes().filter(item => {
          return !item.readOnly
        }))
        child = child.map(item => item.sysModuleCode)
        console.log(child)
        if (child.length > 0 && !this.treeData[i].readOnly) {
          child.push(this.treeData[i].sysModuleCode)
        }
        list.push(...child)
      }

      const load = this.$load()
      sysmodule_update_submit({
        checkedUserUuid: this.orgUserUuid,
        sysModuleCodeList: list
      }).then(d => {
        load.close()
        this.$message.success(d.msg)
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
