import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken, TokenKey } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import { unmountAllApps } from '@micro-zoe/micro-app'
import imSdk from '@um/im'
const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  userInfo: null,
  userPosition: {},
  imAccountId: '',
  imAccountToken: '',
  enterpriseName: {},
  enterpriseUuid: {},
  enterpriseLogoImg: {},
  appUuid: '',
  deviceId: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    setToken(token)
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USERPOSITION: (state, userPosition) => {
    state.userPosition = userPosition
  },
  SET_imAccountId: (state, imAccountId) => {
    state.imAccountId = imAccountId
  },
  SET_imAccountToken: (state, imAccountToken) => {
    state.imAccountToken = imAccountToken
  },
  SET_enterpriseName: (state, enterpriseName) => {
    state.enterpriseName = enterpriseName
  },
  SET_enterpriseUuid: (state, enterpriseUuid) => {
    state.enterpriseUuid = enterpriseUuid
  },
  SET_enterpriseLogoImg: (state, enterpriseLogoImg) => {
    state.enterpriseLogoImg = enterpriseLogoImg
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_APPID(state, appUuid) {
    state.appUuid = appUuid
  },
  SET_DEVICEID(state, deviceId) {
    state.deviceId = deviceId
  }
}

const actions = {
  // 清空所有业务数据缓存
  removeAllCache({ dispatch }) {
    // 当用户退出登录，或者切换企业的时候 清除项目所有缓存 但是不包含自身的token
    // 清除localStorage
    localStorage.clear()
    // 清除cookie
    const keys = document.cookie.match(/[^ =;]+(?=\=)/g)
    keys && keys.forEach(item => {
      if (item !== TokenKey) {
        document.cookie = item + '=0;expires=' + new Date(0).toUTCString()
      }
    })
    // 重置缓存
    dispatch('tagsView/delAllViews', null, { root: true })
    console.log('清除系统数据缓存')
  },
  /**
   * 登录
   * @param commit
   * @param userInfo 登录表单信息
   * @param type tel 手机号登录  pasw 账号密码登录
   * @param method 登录接口函数
   * @returns {Promise<unknown>}
   */
  login({ commit, dispatch }, { userInfo, type, method }) {
    // eslint-disable-next-line no-unreachable
    return new Promise((resolve, reject) => {
      let params
      if (type == 'pasw') {
        const { userName, password } = userInfo
        params = {
          ...userInfo
        }
      }
      if (type == 'tel') {
        const { userMobile, smsCode } = userInfo
        params = {
          userMobile: userMobile,
          smsCode: smsCode
        }
      }
      method({
        ...params
      }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        // 清除之前用户的业务数据
        dispatch('removeAllCache')
        resolve(data.token)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unreachable
      getInfo().then(response => {
        const { data } = response
        if (!data) {
          reject('个人信息获取异常！')
        }
        let { moduleCode: roles, nickName, imAccountToken, userHeadImg, userPosition, imAccountId, enterpriseName, enterpriseUuid, enterpriseLogoImg } = data
        /**
         * 产品默认每个人必有im权限，由前端默认设置im权限code为888，并默认添加
         */
        Array.isArray(roles) ? roles.push('888') : (roles = ['888'])
        if (!roles || !roles.length) {
          reject(enterpriseName ? `当前用户在${enterpriseName}下没有任何权限` : '这个用户什么权限都没有')
        }
        const context = data.orgUserInfo || {} // 选择企业后的当前用户信息
        commit('SET_USERINFO', Object.assign({}, data, context))

        // 权限code
        commit('SET_ROLES', roles)

        commit('SET_NAME', context.userName || nickName)
        commit('SET_AVATAR', context.userHeadImageUrl || userHeadImg)
        // 修改为外部优先
        commit('SET_USERPOSITION', context.userPosition || userPosition)
        commit('SET_imAccountId', imAccountId)
        commit('SET_imAccountToken', imAccountToken)
        commit('SET_enterpriseName', enterpriseName)
        commit('SET_enterpriseUuid', enterpriseUuid)
        commit('SET_enterpriseLogoImg', enterpriseLogoImg)
        resolve({
          ...data,
          roles: ['admin']
        })
      }).catch(error => {
        reject(error)
      })
    })
  },

  getStatus({ commit, state }) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unreachable
      getInfo().then(response => {
        const { data } = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      const hasToken = getToken()
      if (!state.token || !hasToken) {
        resolve()
        return
      }
      // 卸载所有子应用
      unmountAllApps({ destroy: true, clearAliveState: true }).then(() => console.log('卸载成功'))
      // 移除登录信息
      logout(state.token).then(() => {
        resetRouter()
        dispatch('resetToken')
        // reset visited views and cached views
        dispatch('tagsView/delAllViews', null, { root: true })
        imSdk?.destroy()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit, dispatch }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
