
<template>
  <div class="m_enterprise_projetcinfo">
    <el-row v-permission="['112040913']" type="flex" justify="end" style="margin-bottom: 20px;">
      <el-button type="primary" @click="add">添加</el-button>
    </el-row>
    <el-row type="flex">
      <el-col :span="12">
        <el-card
          class="m_projetcinfo_card"
          style="height:480px"
          shadow="never"
          :body-style="{
            height: '100%',
            overflow: 'auto',
          }"
        >
          <div slot="header" class="clearfix">
            <span>组织架构</span>
          </div>
          <el-tree
            ref="tree"
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            node-key="organUuid"
            highlight-current
            style="margin-top: 10px; margin-bottom: 55px"
            :default-expanded-keys="idArr"
            @current-change="currentTreeChange"
          >
            <span slot-scope="{ node, data }" class="m_projetcinfo_treenode">
              <span style="display: flex;align-items: center">
                <el-tag
                  size="mini"
                  :style="{
                    color: '#333',
                    background: '#EDEDED',
                    borderColor: '#EDEDED',
                  }"
                >
                  <span v-if="data.organTypeCode==1001502">公司</span>
                  <span v-if="data.organTypeCode==1001503">部门</span>
                  <span v-if="data.organTypeCode==1001504">岗位</span>
                </el-tag>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="data.organName"
                  placement="top"
                >
                  <span style="font-size: 14px;margin-left: 10px">{{ data.organName }}</span>
                </el-tooltip>
              </span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card
          v-loading="loading"
          class="m_projetcinfo_card"
          style="height:480px"
          shadow="never"
          :body-style="{
            height: '100%',
            overflow: 'auto',
            padding: '20px 20px 55px',
          }"
        >
          <div slot="header" class="clearfix">
            <span>项目</span>
          </div>
          <el-tree
            ref="treeProject"
            class="filter-tree"
            :data="treeDataProject"
            :props="defaultPropsProject"
            node-key="uuid"
            highlight-current
            style="margin-top: 10px;margin-bottom: 55px;"
            :default-expanded-keys="idArrProject"
            @current-change="currentTreeChangeProject"
          >
            <span slot-scope="{ node, data }" class="m_projetcinfo_treenode" :class="{m_projetcinfo_isOnly:data.readOnlyFlag}">
              <span class="m_parttree_item">
                <el-tag
                  size="mini"
                  :style="{
                    color: '#333',
                    background: '#EDEDED',
                    borderColor: '#EDEDED',
                  }"
                  :class="{m_projetcinfo_tagisOnly:data.readOnlyFlag}"
                >{{ PROJECT_TYPE[data.typeCode] }}</el-tag>
                <span class="dib" style="font-size: 14px;margin-left: 10px;width: 300px">
                  <UmToolTip :content="node.label" class="row_1" />
                </span>
              </span>
              <span class="m_projetcinfo_edit" :class="{ on:node.isCurrent&&data.readOnlyFlag!=1}">
                <el-tooltip
                  effect="dark"
                  content="删除"
                  placement="top"
                >
                  <el-button type="text" size="mini" @click.stop="() => remove(node, data)">
                    <i class="el-icon-delete" />
                  </el-button>
                </el-tooltip>
              </span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
    </el-row>
    <selectProject
      :visible.sync="visibleProject"
      :org-user-uuid="orgUserUuid"
      @change="changeProject"
    />
  </div>
</template>
<script>
import {
  get_user_project_auth_organ_tree,
  get_user_project_tree_by_organ,
  add_user_project_auth,
  remove_user_project_auth
} from '@/api/manage.js'
import selectProject from './selectProject'
export default {
  name: 'ProjetcInfo',
  components: { selectProject },
  props: {
    orgUserUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    // const _this = this
    return {
      PROJECT_TYPE: {
        '1': '项目',
        '2': '分期',
        '3': '楼栋',
        '1005501': '公区',
        '1005502': '非工区'
      },
      // orgUserId: _this.$route.query.orgUserId,
      searchForm: {
        organUuid: ''
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'organName'
      },
      idArr: [],
      busOrganObj: {}, // 当前组织架构

      treeDataProject: [],
      defaultPropsProject: {
        children: 'children',
        label: 'name',
        disabled: (data, node) => {
          return data.readOnlyFlag
        }
      },
      idArrProject: [],
      busOrganObjProject: {}, // 当前项目架构
      visibleProject: false,
      loading: false
    }
  },
  watch: {
  },
  created() {},
  mounted() {
    this.getTree()
  },
  methods: {
    add() {
      this.visibleProject = true
    },
    // 组织树数据
    getTree() {
      get_user_project_auth_organ_tree({ orgUserUuid: this.orgUserUuid }).then((d) => {
        this.treeData = d.data
        if (d.data.length === 0) return false
        console.log(d.data)
        this.treeData.forEach((item) => {
          this.idArr.push(item.organUuid)
        })
        this.$nextTick(() => {
          if (d.data[0].children.length) {
            this.$refs.tree.setCurrentNode(d.data[0].children[0]) // 默认第一个公司
            this.searchForm.organUuid = d.data[0].children[0].organUuid // 默认第一个公司
            this.currentTreeChange(d.data[0].children[0])// 默认第一个公司
          } else {
            this.$refs.tree.setCurrentNode(d.data[0]) // 默认第一个公司
            this.searchForm.organUuid = d.data[0].organUuid // 默认第一个公司
            this.currentTreeChange(d.data[0])// 默认第一个公司
          }
        // this.busOrganObj.label = d.data[0].organFullName
        })
        // this.getTreeProject()
        // this.currentTreeChange(d.data[0].children[0])// 默认第一个公司
      })
    },
    // 组织选中当前节点
    currentTreeChange(row, node) {
      this.searchForm.organUuid = row.organUuid
      this.busOrganObj = row
      this.getTreeProject()
    },
    // 项目树数据
    getTreeProject() {
      this.loading = true
      get_user_project_tree_by_organ({ orgOrganUuid: this.searchForm.organUuid, orgUserUuid: this.orgUserUuid }).then((d) => {
        this.loading = false
        this.treeDataProject = d.data
        if (d.data.length === 0) return false
        this.treeDataProject.forEach((item) => {
          this.idArrProject.push(item.uuid)
        })
        this.$nextTick(() => {
          this.$refs.treeProject.setCurrentNode(d.data[0])
        })
      })
    },

    // 项目选中当前节点
    currentTreeChangeProject(row, node) {
      // console.log(node)
      // this.searchParam.organUuid = row.organUuid
      // this.busOrganObjProject = row
    },
    // 删除项目
    remove(node, data) {
      console.log(data, 'data-------------')
      this.$confirm(`是否删除项目:${data.name}?`, '提示', {
        type: 'warning'
      }).then(() => {
        const load = this.$load()
        remove_user_project_auth({
          projectBuildingUuid: data.uuid,
          orgUserUuid: this.orgUserUuid
        }).then((d) => {
          this.$message.success('删除成功!')
          load.close()
          this.getTreeProject()
          this.getTree()
        }).catch((e) => {
          load.close()
          this.$errorHandle(e)
        })
      }).catch(() => {
        this.$message.info('已取消删除!')
      })
    },

    changeProject(val) {
      console.log(val)
      this.$confirm('是否保存项目授权', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          console.log(val, 'val---------------')
          const load = this.$load()
          add_user_project_auth({
            orgUserUuid: this.orgUserUuid,
            autoAuth: !!val.autoAuth,
            orgOrganUuid: val.organUuid,
            projectBuildingUuidList: val.ids
          })
            .then((d) => {
              load.close()
              this.$message.success('修改成功！')
              this.getTree()
              this.visibleProject = false
            })
            .catch((e) => {
              load.close()
              this.$errorHandle(e)
            })
        })
        .catch(() => {
          this.$message.info('取消修改！')
        })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
