<template>
  <div class="m-cloudMarkDetail">
    <div class="cloudMarkDetail_commodity">
      <div class="go_back_common">
        <div class="go_back_title" @click="back">
          <span class="go_back_icon" />
          <span class="go_back_txt">返回应用市场</span>
        </div>
        <div class="go_back_lines" />
      </div>
      <div class="commodity_content appInfo">
        <div class="content_img">
          <img class="f-bord-rad52" :src="detailInfo.appIconUrl" alt="" height="100%" width="100%">
        </div>
        <div class="content_introduce ml30 position_r">
          <div class="flex" style="margin-bottom:6px">
            <div class="title">{{ detailInfo.appName }}</div>
            <div class="text flex  ml10 mr-20">
              <template v-if="(detailInfo.scope==2)||(detailInfo.scope==3)">
                <svg-icon class="mr-4 " icon-class="computer" />
                pc端
              </template>
              <template v-if="(detailInfo.scope==1)||(detailInfo.scope==3)">
                <svg-icon class="mr-4 " icon-class="mobile" />
                移动端
              </template>
            </div>
          </div>
          <div class="text mt10">{{ detailInfo.epEnterpriseName }}</div>
          <div>
            <div class="text mt10">
              <el-tag v-for="(item,i) in detailInfo.labels" :key="i" class="mr-10 tags row_one  ">
                {{ item }}
              </el-tag>
            </div>
          </div>
          <div class="top-switch mt15">
            <div class="redColor flex">
              <!--免费-->
              <span v-if="feesType===1">{{ saleText||'免费' }}</span>
              <!-- 收费-->
              <span v-if="feesType===2">{{ saleText|| '¥'+price }}</span>
              <!-- 价格面议-->
              <span v-if="feesType===3">{{ saleText||'价格面议' }}</span>
              <!--              ¥-->
              <!--              <div class="number">-->
              <!--                {{ price || 0 }}-->
              <!--              </div>-->
              <!--                <UmCollection v-model="collectionStatus" style="margin-left: 30px;" @change="collectHandler" />-->
            </div>
            <div class="salePrice">
              <!--              <span v-if="price===0" class="saleText">{{ saleText }}</span>-->
              <!--              <span v-if="saleText===null && price===0" class="saleText">免费</span>-->
              <span v-if="activityTag" class="icon-flag">{{ activityTag }}</span>
              <!--              <div v-if="form.activityTag" class="m_other_lpic">-->
              <!--                {{ form.activityTag }}-->
              <!--              </div>-->
              <span v-if="guidePrice>0" class="guidePrice">
                <span class="u-small">标准价 ¥ </span>
                <span class="u-num">{{ guidePrice || '' }}</span>
              </span>
            </div>
          </div>

          <div class="u_tag-title">
            选择规格
          </div>
          <div class="u_tag-wrap mb10 row_one">
            <el-tag
              v-for="(item,i) in detailInfo.specifSications"
              :key="i"
              :type="specificationsId==detailInfo.specifSications[i].uuid?'':'info'"
              :class="active===i?'tag1 pointer':'tag pointer'"
              size="mini"
              class="row_one"
              @click="getSpecifications(i)"
            >
              {{ item.name }}
            </el-tag>
          </div>
          <div class="m-shop_info_tool flex" style="margin-top: 20px;">
            <el-button v-if="feesType!==3" type="primary" @click="getBuyNow()">立即购买</el-button>
            <el-button v-if="feesType!==3" class=" btn" type="primary" plain @click="addShopCar">
              <svg-icon class="mr-4 " icon-class="shopCar" />
              加入购物车
            </el-button>

            <div class="m-tool">
              <div><UmCollection v-model="collectionStatus" @change="collectHandler" /></div>
              <div class="ml30" @click="concactCustomer">
                <svg-icon class="mr-4 " icon-class="blueLijizixun" />
                <span class="u_txt" style="cursor: pointer; color: #4C64FE;">立即咨询</span>
              </div>
            </div>

            <!--            <el-button class=" btn" type="primary" plain @click="concactCustomer">-->
            <!--              <svg-icon class="mr-4 " icon-class="blueLijizixun" />-->
            <!--              立即咨询-->
            <!--            </el-button>-->
          </div>
        </div>
      </div>
    </div>

    <div class="cloudMarkDetail_product">
      <div class="product-title text-center">产品详情</div>
      <div class="flex-center mt-10">
        <UmEditInfo v-if="detailInfo.content" :url="detailInfo.content" @goInfo="jumpPath" />
        <no-data v-else class="mt40" style="width:100%" />
      </div>
    </div>

    <confirm-order-one
      ref="order"
      :app-uuids="$route.query.uuid"
      :order-one-flag="orderOneFlag"
      :price="price"
      :specifications-id="specificationsId"
      @changeOrderOneFlag="changeOrderOneFlag"
    />
    <service-provider
      :bussiness-mobile="bussinessMobile"
      :contact-flag="contactFlag"
      @changeContactFlag="changeContactFlag"
    />
  </div>
</template>

<script>
import ConfirmOrderOne from '../components/confirmOrderOne.vue'
import toBePaid from '../components/toBePaid.vue'
import { getCloudMarketInfo } from '@/api/cloudMarket'
import ServiceProvider from '../components/serviceProvider.vue'
import noData from '../components/noData.vue'
import imSdk from '@um/im'
import { addCollect, addShopping, cancelCollect, isCollect } from '@/api/dashboard'
import { jumpPath } from '@/utils/jump'

export default {
  name: 'CloudMarketAppInfo',
  components: { ConfirmOrderOne, ServiceProvider, noData },
  data() {
    return {
      jumpPath,
      orderOneFlag: false,
      detailInfo: {},
      price: 0,
      guidePrice: '', // 标准价格
      saleText: '', // 推广文案
      feesType: '', // 收费标准
      specificationsId: '',
      contactFlag: false,
      bussinessMobile: '',
      collectionStatus: false,
      businessOrderUuid: '',
      active: '',
      // appPrice: '',
      activityTag: ''
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    collectHandler(status) {
      if (status) {
        addCollect({
          businessType: 2003010101,
          businessUuid: this.$route.query.uuid,
          businessName: this.detailInfo.appName
        }).then(res => {
          this.$message.success('已收藏！')
        }).catch(e => {
          this.collectionStatus = !this.collectionStatus
        })
      } else {
        cancelCollect({
          businessType: 2003010101,
          businessUuid: this.$route.query.uuid,
          type: 1
        }).then(res => {
          this.$message.success('取消收藏！')
        }).catch(e => {
          this.collectionStatus = !this.collectionStatus
        })
      }
    },
    addShopCar() {
      const load = this.$load()
      addShopping({
        skuList: [
          {
            businessType: 1023901,
            skuUuid: this.specificationsId
          }
        ]
      }).then(res => {
        this.$message.success('添加购物车成功！')
      }).finally(_ => {
        load.close()
      })
    },
    back() {
      if (this.$route.query.tabType != 0) {
        this.$router.push({ path: '/cloudMarket', query: { tabType: this.$route.query.tabType }})
      } else {
        this.$router.push({ path: '/cloudMarket/shoppingCart' })
      }
    },
    getBuyNow() {
      if (this.$store.getters.userInfo.adminFlag === true) {
        if (!this.specificationsId) {
          this.$message.error('请先选择规格')
        } else {
          this.orderOneFlag = true
          this.$refs.order.getConfirmOrder()
        }
      } else {
        this.$message.error('非管理员暂时无法购买，请联系管理员购买！')
      }
    },
    changeOrderOneFlag(data) {
      if (data === 'false') {
        this.orderOneFlag = false
      } else {
        this.orderOneFlag = true
      }
    },

    // 详情
    getDetail() {
      const load = this.$loading()
      getCloudMarketInfo({ uuid: this.$route.query.uuid }).then(res => {
        this.detailInfo = res.data.appInfo || {}
        this.bussinessMobile = res.data.appInfo.serviceTel
        this.price = res.data.appInfo.specifSications[0].price
        this.specificationsId = res.data.appInfo.specifSications[0].uuid
        this.collectionStatus = res.data.appInfo.collectFlag
        this.getSpecifications(0)
      }).finally(_ => {
        load.close()
      })
    },
    /**
     * 联系客户
     * @param item
     */
    concactCustomer() {
      const { imAccount } = this.detailInfo
      if (!imSdk.YX) return this.$message.warning('聊天功能异常')
      if (!imAccount) return this.$message.warning('客户信息异常！')
      const goodsInfo = {
        shareTitle: this.detailInfo.appName,
        shareContent: this.detailInfo.desc,
        shareImageUrl: this.detailInfo.appIconUrl,
        shareType: 'pc',
        shareLink: `/cloudMarket/info?uuid=${this.$route.query.uuid}&dataType=${this.$route.query.dataType}`,
        price: this.detailInfo.price
      }
      imSdk.YX.concactCustomer(imAccount, 'p2p', goodsInfo).then(res => {
        this.$router.push('/im')
      }).catch(e => {
        this.$message.error('操作失败')
      })
    },
    // 选择规格
    getSpecifications(i) {
      this.active = i
      this.price = this.detailInfo.specifSications[i].price
      // this.appPrice = this.detailInfo.specifSications[i].appPrice
      this.specificationsId = this.detailInfo.specifSications[i].uuid
      this.guidePrice = this.detailInfo.specifSications[i].guidePrice
      this.saleText = this.detailInfo.specifSications[i].saleText
      this.feesType = this.detailInfo.specifSications[i].feesType
      this.activityTag = this.detailInfo.specifSications[i].activityTag
    },
    getBussinessMobile() {
      this.contactFlag = true
    },
    changeContactFlag(data) {
      if (data === 'false') {
        this.contactFlag = false
      } else {
        this.contactFlag = true
      }
    },
    getConsultImmediately() {
      this.$router.push({ path: '/im', query: {}})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
