<template>
  <div class="m-shoppingOrder">
    <el-drawer
      :before-close="handleClose"
      :direction="direction"
      :visible.sync="drawer"
      title="确认订单信息"
    >
      <div class="orderBox">
        <addressCheck v-if="orderList.some(ite => ite.businessType===1023903)" :use-address-info="useAddressInfo" @getApply="getApply" />
        <div class="orderBox_form flex-column-between">
          <div style="display: flex">
            <div class="label">
              组织：
            </div>
            <div class="rightcontent">{{ $store.getters.enterpriseName || '-' }}</div>
          </div>
          <div style="display: flex">
            <div class="label">
              商品数：
            </div>
            <div class="rightcontent">{{ quantity }}</div>
          </div>
        </div>
        <div class="flex-between mt10 orderBox_totalPrice">
          <div class="grayColor">总价</div>
          <div class="flex-column-center ">
            ¥
            <div class="money">{{ money || 0 }}</div>
            元
          </div>
        </div>
        <!-- 应用 -->
        <div v-for="(item,i) in orderList" :key="i" class="orderBox_service mb20">
          <div v-if="i==orderList.findIndex(ite=> ite.businessType===1023901)" class="service_title">{{ orderType[item.businessType] }}</div>
          <div v-if="i==orderList.findIndex(ite=> ite.businessType===1023902)" class="service_title">{{ orderType[item.businessType] }}</div>
          <div v-if="i==orderList.findIndex(ite=> ite.businessType===1023903)" class="service_title">{{ orderType[item.businessType] }}</div>
          <div class="service_Box">
            <div v-if="item.businessType!=1023901?item.skuUuid&&item.nums>0:item.skuUuid" class="service_Box_check" />
            <div class="flex service_Box_topTitle">
              <div class="topTitle_Img">
                <img :src="item.goodsCoverImg" alt="" height="100%" width="100%">
              </div>
              <div class="topTitle_rightBox flex-column-between">
                <div class="flex rightBox_Top">
                  <UmToolTip :content="item.goodsTitle " class="row_one" style="width:150px" />
                  <div class="s-rightBoxMoney">¥{{ item.skuPrice }}</div>
                </div>
                <div class="flex-between ">
                  <div class="rightBox_Bottom">
                    <UmToolTip :content="item.categoryName" class="row_one" :style="item.businessType!=1023901?'width:120px':'width:240px'" />
                  </div>
                  <div v-if="item.businessType!=1023901">
                    <el-input-number
                      v-model="item.nums"
                      :max="item.skuStock||999"
                      :precision="0"
                      :min="0"
                      class="num"
                      size="small"
                      style="
                      width:82px"
                      @change="handleChange(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="service_Box_bottomBox">
              <div v-if="item.businessType==1023901" class="bottomBox_specifications flex-between pointer mb8" style="padding:0px 12px" @click="getRange(i)">
                <div>可见范围</div>
                <div class="flex-column-center">
                  <UmBusUser
                    ref="chosepart"
                    v-model="item.department"
                    :default-root="true"
                    :show-prefix-button="false"
                    :type="1"
                    title="选择可见范围"
                    @change="change($event, i)"
                  >
                    <template #user="{labelCustom}">
                      <span>  {{ item.organScopeUuids.length > 0 ? labelCustom : '请选择可见范围' }}</span>
                    </template>
                  </UmBusUser>
                  <div class="pointer ml-10 flex-center" @click="getRange(i)">
                    <i class="el-icon-arrow-down pointer" />
                  </div>
                </div>
              </div>
              <div class="bottomBox_specifications flex-culumn-align pointer" @click="getSpecification(item)">
                <div class="flex-between" style="height:20px;padding:0px 5px">
                  <div>规格</div>
                  <div class="flex">
                    <div class="s-font_12">{{ item.skuName || '请选择' }}</div>
                    <i
                      v-if="item.flag===false"
                      class="el-icon-arrow-down pointer ml-10"
                    />
                    <i v-else class="el-icon-arrow-up ml-10" />
                  </div>
                </div>
                <div v-if="item.flag===true">
                  <el-row :gutter="7">
                    <el-col v-for="(ite,j) in item.specifications" :key="j" :span="8" class="">
                      <div
                        :class="item.skuUuid===ite.skuUuid?'unCheckTag':'checkTag'"
                        class="u-tags flex-center mb10 pointer"
                        @click="getTags(item,j,ite)"
                      >
                        <UmToolTip :content="ite.skuName" class="row_1" style="text-align: center;line-height: 100%;padding: 0 5px" />
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="bottomBox_agreement_shopcar">
          <el-checkbox v-model="flag" :label="true" class="radio" @click="getCheck">  <div class="s-grayColor">同意<a class="s-blueColor" @click="dialogFlag=true">优客云用户使用协议、隐私协议</a></div></el-checkbox>
        </div>
        <div class="orderBtn">
          <el-button class="u-purchaseBtn" type="primary" @click="getPurchase()">提交订单</el-button>
        </div>
      </div>
    </el-drawer>
    <to-be-paid
      ref="payment"
      :ispay="3"
      :ordtype="2"
      :drawer-flag="drawerFlag"
      :money="money"
      :business-order-uuid="businessOrderUuid"
      :platform-order-number="platformOrderNumber"
      :tab-type="tabType"
      @changeShow="changeShow"
    />
    <agreement :dialog-flag="dialogFlag" @agreementFlag="agreementFlag" />
  </div>
</template>
<script>
import {
  getAddressManagement,
  getDefaultAddress
} from '@/api/cloudMarket'
import { submitOrder } from '@/api/shoppingCart/index'
import toBePaid from '../../components/toBePaid.vue'
import { math } from '@/utils/math.js'
import { deepClone } from '@/utils'
import addressCheck from '../../components/address.vue'
import agreement from '@/views/cloudMarket/components/agreement'
export default {
  name: 'Order',
  components: { toBePaid, addressCheck, agreement },
  props: {
    orderFlag: {
      type: Boolean,
      default: false
    },
    orderList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      dialogFlag: false,
      department: [],
      flag: false,
      drawerFlag: false,
      detailInfo: {},
      addressList: [],
      useAddressInfo: {},
      //   orderList: [{}],
      orderType: {
        '1023901': '应用',
        '1023902': '服务',
        // '1023903': '智能硬件'
        '1023903': '商品'
      },
      organScopeUuids: [],
      platformOrderNumber: null,
      quantity: 0,
      tabType: 3,
      businessOrderUuid: ''

    }
  },
  computed: {
    money() {
      let sum = 0
      this.orderList.forEach(item => {
        const a = Number(sum)
        const b = Number(item.skuPrice * item.nums)
        sum = math.add(a, b)
      })
      return sum
    }

  },
  watch: {
    orderFlag: {
      handler(val) {
        console.log(val)
        this.drawer = val
        this.quantity = this.orderList.length
        if (val === true) {
          const flag = this.orderList.some(item => {
            return item.businessType === 1023903
          })
          if (flag) {
            this.getDefaultAddress()
          }
        }
      },
      immediate: true
    }
  },
  mounted() {

  },
  methods: {
    getCheck() {
      this.flag = !this.flag
    },
    // 关闭协议
    agreementFlag(data) {
      if (data === 'false') {
        this.dialogFlag = false
      } else {
        this.dialogFlag = true
      }
    },
    // 关闭订单信息
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$emit('changeShow', 'false')
          done()
        })
        .catch(_ => {
        })
    },
    // 选择查看范围
    getRange(i) {
      this.$refs.chosepart[i].openModal()
    },

    getSpecification(item) {
      item.flag = !(item.flag)
    },
    // 右边栏服务规格
    getService() {
      this.serviceFlag = !(this.serviceFlag)
    },
    // 选中的规格
    getTags(item, j, ite) {
      console.log(ite)
      if (ite.feesType === 3) {
        this.$message.warning('当前规格为价格面议，不支持在线购买，请咨询销售人员！')
        return false
      }
      if (item.businessType === 1023901 || item.businessType === 1023902 || (item.businessType === 1023903 && ite.stock > 0)) {
        console.log(item.businessType === 1023903 && ite.stock > 0)
        item.flag = false
        // item.tagId = j
        item.skuUuid = ite.skuUuid
        item.skuName = ite.skuName
        item.skuPrice = ite.skuPrice
        item.money = ite.skuPrice
        this.handleChange(item)
      } else if (item.businessType === 1023903 && ite.skuStock <= 0) {
        this.$message.error('该规格已售空，请选择其他规格')
      }
    },
    // 默认地址
    getDefaultAddress() {
      getDefaultAddress().then(res => {
        this.useAddressInfo = res.data || {}
        this.orderList.forEach(ite => {
          ite.addressUuid = res.data.addressUuid || ''
        })
      })
    },
    // 使用地址
    getApply(item) {
      this.useAddressInfo = item
      this.orderList.forEach(ite => {
        ite.addressUuid = item.addressUuid
      })
    },
    changeShow(data) {
      if (data === 'false') {
        this.drawerFlag = false
        this.drawer = false
        this.$emit('changeShow', 'false')
        this.$parent.getList()
        this.$parent.checkAll = true
        this.$parent.checkedList = []
      } else {
        this.drawerFlag = true
      }
    },
    // 一键购买
    async getPurchase() {
      if (this.flag === false) {
        this.$message.error('请先勾选协议')
      } else {
        const arr = []
        const orderList = deepClone(this.orderList)
        console.log(orderList)
        orderList.forEach(item => {
          // item.specifications = item.skuUuid
          if (item.businessType == 1023901 || item.nums > 0) {
            arr.push(item)
          }
        })
        console.log(arr)
        if (arr.length === 0) {
          this.$message.error('请至少选择一项规格')
          // load.close()
        } else {
          // 判断付款的订单中是否有应用
          const typeFlag = arr.some(item => {
            return item.businessType === 1023901
          })
          if (typeFlag == true) {
            this.tabType = 3
          } else {
            this.tabType = 4
          }
          // 判断为应用且选择了可见范围
          const flag = arr.some(item => {
            return (item.businessType === 1023901 && item.organScopeUuids.length === 0)
          })

          if (flag === false) {
            //   const addressFlag = arr.every(item => {
            //     return item.addressUuid
            //   })
            // 是否有订单地址
            console.log(this.useAddressInfo)
            const addressFlag = arr.some(item => {
              return item.businessType === 1023903
            })
            if ((this.useAddressInfo.addressUuid && this.useAddressInfo.addressUuid !== '') || !addressFlag) {
              const load = this.$loading()
              try {
                await submitOrder({ orders: arr, adressUuid: this.useAddressInfo.addressUuid }).then(res => {
                  this.platformOrderNumber = res.data.orderUuid
                  this.businessOrderUuid = res.data.businessOrderUuid
                })
              } catch {
                load.close()
                return false
              }

              this.drawerFlag = true
              this.$refs.payment.getQueryPaymentMethod()
              this.$refs.payment.getPayOrderInfo()
              load.close()
            } else {
              this.$message.error('请选择收货地址')
            }
          } else {
            this.$message.error('请选择可见范围')
          }
        }
      }
    },
    change(val, i) {
      // eslint-disable-next-line vue/no-mutating-props
      this.orderList[i].organScopeUuids = val
    },

    // 数量变化
    handleChange(item) {
      this.quantity = 0
      this.orderList.forEach(item => {
        this.quantity = this.quantity + item.nums
      })
    },
    getClose() {
      this.$emit('changeShow', 'false')
      this.$parent.getList()
    }
  }
}
</script>
