
<template>
  <EnterpriseLayout>
    <div class="m_enterprise_addperson">
      <div v-if="isedit==1" class="m_addperson_head" @click="back1">
        <span class="m_addperson_headicon" />
        <span class="m_addperson_headtxt">返回</span>
      </div>
      <div v-else class="m_addperson_head" @click="back">
        <span class="m_addperson_headicon" />
        <span class="m_addperson_headtxt">返回</span>
      </div>
      <div class="m_addperson_lines" />
      <div class="m_radio_group">
        <el-radio-group v-model="tab">
          <el-radio-button :label="1">基础信息</el-radio-button>
          <el-radio-button label="siteInfo">功能点权限</el-radio-button>
          <el-radio-button label="organizationInfo">组织权限</el-radio-button>
          <el-radio-button label="application">应用权限</el-radio-button>
          <el-radio-button label="projetcInfo">项目权限</el-radio-button>
        </el-radio-group>
      </div>
      <div v-if="tab==1" class="m_addperson_formbody m_address_edit_heigh">
        <el-scrollbar style="height: 100%">
          <div class="m_addperson_edittop" />
          <div v-if="isedit==1" style="margin-left: 10px">
            <el-form ref="ruleForm" :key="1" :model="ruleForm" :rules="rules" label-width="110px" class="demo-ruleForm">
              <div class="headimg">
                <el-form-item label="用户头像" prop="userHeadImageUrl" style="height: 90px;margin-top: 20px">
                  <div class="m_addperson_edittop">
                    <div class="m_addperson_avatarup">
                      <div class="m_addperson_tips">
                        <UmUploadImg v-model="ruleForm.userHeadImageUrl" :show-icon="false" custom-file :limit="1" :simple="true" type="avatar">
                          <template #extend>
                            <div class="m_addperson_tips2">
                              <div class="m_addperson_imgicon" />
                            </div>
                          </template>
                        </UmUploadImg>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div class="l-form">
                <el-form-item label="用户姓名" prop="userName">
                  <el-input v-model="ruleForm.userName" placeholder="请填写姓名" maxlength="10" clearable />
                </el-form-item>
                <el-form-item label="用户别名" prop="userAlias">
                  <el-input v-model="ruleForm.userAlias" placeholder="请填写别名" clearable maxlength="10" />
                </el-form-item>
                <el-form-item label="用户账号" prop="userLoginName">
                  <el-input v-model="ruleForm.userLoginName" placeholder="请填账号" clearable maxlength="50" />
                </el-form-item>
                <el-form-item label="用户性别" prop="userSexCode">
                  <el-radio-group v-model="ruleForm.userSexCode">
                    <el-radio :label="1040531">男</el-radio>
                    <el-radio :label="1040532">女</el-radio>>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="m_addperson_form m_address_padd_bottom30">
                <el-form-item label="手机号码" prop="userMobile">
                  <el-input v-model="ruleForm.userMobile" placeholder="请填写手机号" maxlength="11" clearable :disabled="!ruleForm.mobileEditFlag" />
                </el-form-item>
                <el-form-item label="邮箱账号" prop="userEmail">
                  <el-input v-model="ruleForm.userEmail" placeholder="请填写邮箱" clearable maxlength="50" />
                </el-form-item>
                <el-form-item label="用户角色" prop="orgRoleId">
                  <span v-for="(item,index) in infoForm.orgUserRoleNameList" :key="index" class="m_people_role">{{ item }}</span>
                </el-form-item>
                <el-form-item label="所属部门" prop="department" style="height: auto;">
                  <span class="m_addperson_parment" style="display: flex;">
                    <UmBusUser ref="chosepart" v-model="ruleForm.department" :type="1" title="选择成员所在部门" :show-prefix-button="false" :auth-type="1" @result="resultChange">
                      <template #user="{data}">
                        <span v-if="data&&data.length" class="m_addperson_itemcontent">
                          <span v-for="item in data" :key="item.id" class="m_addperson_texts" @mouseover="mouseover(item.id)" @mouseout="mouseout">
                            <div class="m_addperson_mains" :class="{m_addperson_itemactive:item.id==maincode,m_addperson_mainss:item.id==choseid}" @click="setmain(item)" />
                            {{ item.name }}
                            <span v-if="item.disable===0" class="m_addperson_delicon" :class="{m_addperson_delicons:item.id==choseid}" @click="delmain(item)" />
                          </span>
                          <span class="m_addperson_xiugai" @click="changpart">修改</span>
                        </span>
                        <span v-else>
                          <span class="m_addperson_weishezhi">未设置</span>
                          <span class="m_addperson_xiugai" @click="changpart">修改</span>
                        </span>
                      </template>
                    </UmBusUser>
                  </span>
                </el-form-item>
                <el-form-item label="部门负责人" prop="departmentHead" style="height: auto;">
                  <span v-if="departmentHeads&&departmentHeads.length">
                    <el-checkbox-group v-model="ruleForm.departmentHead">
                      <el-checkbox v-for="item in departmentHeads" :key="item.id" :label="item.id">
                        {{ item.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </span>
                  <span v-else class="m_addperson_weishezhi">未设置</span>
                </el-form-item>
                <el-form-item label="用户职务" prop="userPosition">
                  <el-input v-model="ruleForm.userPosition" placeholder="请填写职务" clearable maxlength="10" />
                </el-form-item>
                <el-form-item label="直属上级" prop="directSuperior">
                  <span class="m_address_umbususer">
                    <UmBusUser ref="UmBusUser" v-model="ruleForm.directSuperior" search-tip="请输入成员姓名" :type="2" title="选择成员直属上级" :show-prefix-button="false">
                      <template #user="{data}">
                        <span class="m_addperson_upname">
                          <span v-if="data&&data.length">
                            <span v-for="(item,index) in data" :key="item.id">
                              {{ item.name }}
                              <span v-if="index!=data.length-1">/</span>
                            </span>
                          </span>
                          <span v-else>未设置</span>
                        </span>
                      </template>
                    </UmBusUser>
                    <div class="m_addperson_uset" @click="changperson">设置</div>
                  </span>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="editsaveForm">保存</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <el-form v-else ref="infoForm" :model="ruleForm" :rules="rules" label-width="110px" class="demo-ruleForm">
            <el-form-item style="width: auto;margin-top: 20px">
              <span slot="label" style="line-height: 90px">用户头像：</span>
              <div class="m_address_itemheadimg">
                <div class="m_address_editimg">
                  <div class="m_address_infoedit">
                    <el-image
                      style="width: 90px; height: 90px;border-radius: 3px;"
                      :src="infoForm.userHeadImageUrl"
                      fit="fit"
                    >
                      <div slot="error" class="image-slot">
                        <div class="m_address_peoplenodata" />
                      </div>
                    </el-image>
                  </div>
                  <div v-if="isedit!=1" v-permission="['112030201109']" class="m_address_editbtn">
                    <el-button type="primary" @click="edit">编辑</el-button>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="用户姓名">{{ infoForm.userName||'-' }}</el-form-item>
            <el-form-item label="用户别名">{{ infoForm.userAlias||'-' }}</el-form-item>
            <el-form-item label="用户账号">{{ infoForm.userLoginName||'-' }}</el-form-item>
            <el-form-item label="用户性别">
              <span v-if="infoForm.userSexCode">{{ infoForm.userSexCode==1040531?'男':'女' }}</span>
              <span v-else>-</span>
            </el-form-item>
            <div class="m_addperson_form m_address_padd_bottom30">
              <el-form-item label="手机号码">{{ infoForm.userMobile||'-' }}</el-form-item>
              <el-form-item label="邮箱账号">{{ infoForm.userEmail||'-' }}</el-form-item>
              <el-form-item label="用户角色">
                <span v-for="(item,index) in infoForm.orgUserRoleNameList" :key="index" class="m_people_role">{{ item }}</span>
              </el-form-item>
              <el-form-item label="所属部门" style="height: auto">
                <span class="m_addperson_parment">
                  <UmBusUser v-model="infoForm.department" :type="1" title="选择成员所在部门" :show-prefix-button="false" :show-tab="false" :auth-type="1">
                    <template #user="{data}">
                      <span v-if="data&&data.length">
                        <span v-for="(item,index) in data" :key="item.id" class="m_addperson_editext">
                          <span :class="{m_addperson_itemactives:item.id==maincode}" />
                          <span class="m_addperson_edittitle">{{ item.name }}</span>
                          <span v-if="index!=data.length-1" class="m_addperson_shuxian" />
                        </span>
                      </span>
                      <span v-else style="border-radius: 4px;color: #31415F;padding: 7px 10px">-</span>
                    </template>
                  </UmBusUser>
                </span>
              </el-form-item>
              <el-form-item label="部门负责人" style="height: auto;">
                <UmBusUser ref="editpart" v-model="infoForm.departmentHead" :type="1" title="选择成员所在部门" :show-prefix-button="false" :show-tab="false" @result="edits">
                  <template #user="{data}">
                    <span v-if="data&&data.length">
                      <span v-for="item in data" :key="item.id" class="m_addperson_texts">
                        <span class="m_addperson_edittxt">
                          {{ item.name }}
                        </span>
                      </span>
                    </span>
                    <span v-else>-</span>
                  </template>
                </UmBusUser>
              </el-form-item>
              <el-form-item label="用户职务">{{ infoForm.userPosition||'-' }}</el-form-item>
              <el-form-item label="直属上级">
                <span class="m_address_umbususer">
                  <UmBusUser ref="UmBusUser" v-model="infoForm.directSuperior" :type="2" title="选择成员所在部门" :show-prefix-button="false">
                    <template #user="{data}">
                      <span v-if="data&&data.length">
                        <span v-for="(item,index) in data" :key="item.id">
                          {{ item.name }}
                          <span v-if="index!=data.length-1">/</span>
                        </span>
                      </span>
                      <span v-else>-</span>
                    </template>
                  </UmBusUser>
                </span>
              </el-form-item>
            </div>
          </el-form>
        </el-scrollbar>
      </div>
      <div v-else class="m_components">
        <component :is="tab" :org-user-uuid="orgUserUuid" />
      </div>
    </div>
  </EnterpriseLayout>
</template>

<script>
import { get_user_update_info, update_user_submit } from '@/api/enterprise'
import { deepClone } from '@/utils'
import { validEmail } from '@/utils/validate'
import { mapGetters } from 'vuex'
import siteInfo from './siteInfo'
import projetcInfo from './projetcInfo'
import organizationInfo from './organizationInfo'
import application from './application'
export default {
  name: 'EnterpriseAddressUpdate',
  components: { siteInfo, organizationInfo, projetcInfo, application },
  props: {},
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value) {
        if (!validEmail(value)) {
          callback(new Error('请输入正确的邮箱号'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写手机号'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    const validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写姓名'))
      } else if (!/^[\u4E00-\u9FA5A-Za-z0-9·]+$/.test(value)) {
        callback(new Error('姓名包含了·以外的特殊字符!'))
      } else {
        callback()
      }
    }
    return {
      tab: 1, // 小tab
      isedit: 1, // 2编辑 1详情
      orgUserUuid: '',
      userUuid: '', // 编辑用户uuid
      editdepartmentHead: [], // 详情部门负责人
      departmentHeads: [], // 部门负责人
      maincode: '', // 主部门
      clonedepartmentList: [],
      choseid: null,
      ruleForm: {
        userHeadImageUrl: '', // 用户头像
        userName: '', // 用户姓名
        userAlias: '', // 用户别名
        userLoginName: '', // 用户账号
        userSexCode: '1040531', // 1040531男 1040532女
        userEmail: '', // 用户邮箱
        userMobile: '', // 用户手机号
        departmentHead: [],
        department: [],
        userPosition: '', // 用户职务
        directSuperior: []
      },
      infoForm: {},
      rules: {
        userName: [
          { required: true, trigger: 'blur', validator: validateName }
        ],
        userMobile: [
          { required: true, trigger: 'blur', validator: validatePhone }
        ],
        department: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ],
        userEmail: [
          { required: false, message: '请输入正确的邮箱', trigger: 'blur', validator: validateEmail }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    'ruleForm.department': {
      handler(val) {
        if (val.length) {
          if (!this.maincode) {
            this.maincode = val[0]
          }
          if (this.maincode) {
            const istrue = val.some((value, index, arr) => {
              console.log(value)
              return value == this.maincode
            })
            if (!istrue) this.maincode = val[0]
          }
        } else {
          this.maincode = ''
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.$route.query) {
      const { isedit, userUuid } = this.$route.query

      if (isedit == 2) {
        this.isedit = isedit
        this.orgUserUuid = userUuid
        this.get_user_update_infos()
      }
    }
  },
  beforeDestroy() {
  },
  mounted() {},
  methods: {
    // 编辑
    edit(val) {
      // this.$router.push({ path: '/enterprise/address/update', query: { isedit: 2, userUuid: this.$route.query.userUuid }})
      this.isedit = 1
      const load = this.$load()
      get_user_update_info({
        orgUserUuid: this.orgUserUuid
      }).then(d => {
        this.maincode = d.data.department.primaryOrganUuid
        this.ruleForm = d.data
        this.ruleForm.department = d.data.department.organUuidList
        this.userUuid = d.data.userUuid
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    // 数据回显用
    edits(val) {
      this.editdepartmentHead = val
    },
    get_user_update_infos() {
      const load = this.$load()
      get_user_update_info({
        orgUserUuid: this.orgUserUuid
      }).then(d => {
        this.maincode = d.data.department.primaryOrganUuid
        this.infoForm = d.data
        this.infoForm.department = d.data.department.organUuidList
      }).finally(_ => {
        load.close()
      }).catch(e => {
        load.close()
        this.$errorHandle(e)
      })
    },
    resultChange(val) {
      this.departmentHeads = val

      if (val) {
        if (this.ruleForm.departmentHead.length) {
          this.ruleForm.departmentHead.forEach(item => {
            const index = this.ruleForm.department.findIndex(ites => ites == item)
            if (index == -1) {
              const delindex = this.ruleForm.departmentHead.findIndex(ite => ite == item)
              this.ruleForm.departmentHead.splice(delindex, 1)
            }
          })
        }
      }
    },
    back() {
      this.$router.replace('/enterprise/address')
      this.orgUserUuid = ''
    },
    back1() {
      // this.orgUserUuid = ''
      this.isedit = 2
    },
    mouseover(id) {
      //
      this.choseid = id
    },
    mouseout() {
      this.choseid = null
    },
    // 设置主部门
    setmain(val) {
      this.maincode = val.id
    },
    delmain(val) {
      const index = this.ruleForm.department.findIndex(item => item == val.id)
      if (index >= 0) {
        this.ruleForm.department.splice(index, 1)
      }
    },
    editsaveForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.maincode) {
            this.$message({
              type: 'warning',
              message: '请选择所属主部门!'
            })
            return
          }
          const arr = {
            organUuidList: this.ruleForm.department,
            primaryOrganUuid: this.maincode
          }
          const form = deepClone(this.ruleForm)
          form.department = arr

          const load = this.$load()
          update_user_submit({ ...form, userUuid: this.userUuid }).then(d => {
            this.$message({
              type: 'success',
              message: '保存成功!'
            })
            if (this.userInfo && (this.userInfo.userUuid === this.orgUserUuid)) {
              this.$store.dispatch('user/getInfo')
            }
            this.back()
          }).finally(_ => {
            load.close()
            // this.$refs.ruleForm.resetFields()
          }).catch(e => {
            load.close()
            this.$errorHandle(e)
          })
        }
      })
    },
    changpart() {
      this.$refs.chosepart.openModal()
    },
    changperson() {
      this.$refs.UmBusUser.openModal()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
