<template>
  <!--  <iframe :src="url" width="100%" :height="clientHeight + 'px'" frameborder="0" />-->
  <micro-app
    name="AppPkg13"
    :url="url"
    keep-alive
    :default-page="defaultPage"
    @created="created"
    @beforemount="beforemount"
    @mounted="mounted"
    @afterhidden="afterhidden"
    @beforeshow="beforeshow"
    @aftershow="aftershow"
    @error="error"
  />
</template>

<script>
import microApp, { unmountApp } from '@micro-zoe/micro-app'
const URL_MAP = {
  'dev-yky.youmatech.com': 'https://dev-zkypro.youmatech.com',
  'test-yky.youmatech.com': 'https://test-zkypro.youmatech.com',
  'yky.youmatech.com': 'https://zkypro.youmatech.com'
}
export default {
  name: 'AppPkg13',
  props: {},
  data() {
    return {
      microApp,
      url: '',
      load: null,
      defaultPage: '',
      path: ''
    }
  },
  created() {
    this.path = this.$store.getters.pathRoute('AppPkg13')
    if (this.path) {
      this.defaultPage = '/login?redirect=' + this.path
    } else {
      this.defaultPage = '/login'
    }

    const env = window.location.host
    if (process.env.NODE_ENV === 'development') {
      this.url = 'http://localhost:7013/packages/pkg13/'
    } else {
      this.url = URL_MAP[env] + '/packages/pkg13/'
    }
    this.load = this.$load()
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    created() {
      console.log('micro-app元素被创建')
    },
    beforemount() {
      console.log('即将被渲染，只在初始化时执行一次')
    },
    mounted() {
      console.log('已经渲染完成，只在初始化时执行一次')
      this.load && this.load.close()
    },
    afterhidden() {
      console.log('已推入后台')
    },
    beforeshow() {
      console.log('即将推入前台，初始化时不执行')
    },
    aftershow() {
      console.log('已经推入前台，初始化时不执行')
      this.load && this.load.close()
      if (this.path) {
        microApp.router.push({ name: 'AppPkg13', path: '/redirect' + this.path })
      }
    },
    error() {
      console.log('渲染出错')
    }
  }
}
</script>
