<template>
  <div>
    <div class="m-hardwareListBox pointer" @click="getInfo">
      <div class="hardwareListBox_top">
        <el-image
          style="width: 100%; height: 100%"
          :src="form.appIconUrl"
          fit="fit"
        />
      </div>
      <div class="hardwareListBox_content">
        <div class="content_appName row_one">{{ form.appName }}</div>
        <div class="content_appDescription row_one">{{ form.appDescription }}</div>

        <div v-if=" form.appPrice&&form.appPrice>0" class="content_appPrice">
          {{ form.appPrice }}
          <span v-if="form.specificationsCount>1" style="font-size: 12px;margin-top: 5px">起</span>
        </div>
        <div v-else class="content_appPrice appPrice_free">
          <span v-if="form.specificationsCount>1">0元 <span style="font-size: 12px;margin-top: 5px">起</span></span>
          <span v-else>免费</span>
        </div>
        <div class="content_bottom flex-between">
          <div class="content_bottom_saleNum">{{ form.saleNum||0 }}购买</div>
          <div class="content_bottom_saleNum">{{ form.visitNum||0 }}浏览</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: Number,
      default: null
    },
    tabType: {
      type: [String, Number],
      default: 1
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  methods: {
    getInfo() {
      this.$router.push({ path: '/cloudMarket/info', query: { uuid: this.form.appUuid, tabType: this.tabType, dataType: 10239003 }})
    }
  }
}
</script>

