import Layout from '@/layout'

const messageRouter = {
  path: '/im',
  name: 'Im',
  component: Layout,
  redirect: '/im/message',
  meta: { level: 1, cache: true, roles: ['888'] },
  children: [
    {
      path: '/im/message',
      name: 'ImMessage',
      component: () => import('@/views/message'),
      meta: { title: '消息', roles: ['888'], icon: 'msg', iconActive: 'msg-active', cache: true, group: 'im' }
    }
  ]
}

export default messageRouter
